import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import CoursesFilter from "./../../components/Ecom/CoursesFilter";
import AllCourses from "./../../components/Ecom/AllCourses";
import CourseFilterBottom from "../../components/Ecom/CourseFilterBottom";
// import { environment } from "../../Services/HttpData";
import { useLocation } from "react-router-dom";

import { TailSpin } from "react-loader-spinner";
import CouresListFAQ from "../CouresListFAQ";
import { useHistory } from "react-router-dom";
import OltsPageSlider from "../OltsPageSlide";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)

const Courses = () => {
  const history = useHistory()
  const location = useLocation();
  const pathname = location.pathname;
  const [loading, setLoading] = useState(false);

  const [product, setProduct] = useState([]);
  const [filterProduct, setFilterProduct] = useState([]);
  const [filters, setFilters] = useState([]);
  const auth_token = localStorage.getItem("apiAccessToken")
    ? localStorage.getItem("apiAccessToken")
    : "";
  const wid_data = localStorage.getItem("userWID")
    ? JSON.parse(localStorage.getItem("userWID"))
    : null;
  let wid = null;
  if (wid_data) {
    if (wid_data.results) {
      if (wid_data.results.length) {
        if (wid_data.results[0].wid) {
          wid = wid_data.results[0].wid;
        }
      }
    }
  }

  // fetch url for products/courses listing
  useEffect(() => {
    productList("");
  }, []);

  // fetch url to filter courses
  useEffect(() => {
    fetch(environment.ecomApiUrl + "filters/list").then((resp) => {
      // fetching api url to filter courses
      resp.json().then((result1) => {
        setFilterProduct(result1.data); // set result in state this will set current/updated value of setFilterProduct to filterproduct
        ////console.log(filterProduct);
      });
    });
  }, []);

  /* Product List / Search Course */
  const productList = (courseName) => {
    let apiData;
    let myfilters = [];
    if (pathname.indexOf("live".toLowerCase()) > -1) {
      myfilters = [["5ad74ce7763691d66641a9f89a1aa0b2"]];
    } else if (pathname.indexOf("recorded".toLowerCase()) > -1) {
      myfilters = [["63fb995b8ac35fa1668c0525697b96d9"]];
    } else {
      if (courseName === "") {
        let data = document.location.search.substr(1).split("=")[1];
        if (data && !window.location.href.includes("utm_source")) {
          let filterData = data
            .replaceAll("--a/a--a/a--", "{")
            .replaceAll("/b-b/b-b/", "}")
            .replaceAll("//c---c//", "[")
            .replaceAll("///d-d///", "]")
            .replaceAll("/e-e////e--e////e-e/", '"');
          // setFilterProduct(data.split(","))
          apiData = JSON.parse(filterData);
          const setshortingData = () => {
            if (document.querySelector(".filters-clear-btn")) {
              document.querySelector(".filters-clear-btn").value = apiData?.sort
            } else {
              setTimeout(() => {
                setshortingData()
              }, 3000);
            }

          }
          setshortingData()
          // console.log(apiData)
        } else {
          if (pathname.indexOf("online-test-series".toLowerCase()) > -1) {
            myfilters = [["cfa4920e8d54af8d443e1e0adedab0da"]];
          }
          apiData = {
            filters: myfilters,
            sort: "",
            search: courseName,
          };
        }
      } else {
        apiData = {
          filters: myfilters,
          sort: "",
          search: courseName,
        };
      }
      setFilterByFilters(apiData);
    }
    let requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(apiData),
    };

    fetch(environment.ecomApiUrl + "product/list", requestOptions).then(
      (resp) => {
        // fetching api url for courses listing
        resp.json().then((result) => {
          setProduct(result.data); // set result in state this will set current/updated value of setProduct to product
          setLoading(true);
          ////console.log(product);
        });
      }
    );
  };
  const [filterByFilters, setFilterByFilters] = useState([]);
  const onChangeEvent = (filterId) => {
    setFilterByFilters(filterId);
    // console.log(filterId,filterProduct)
    let tmp_filter = [],
      child_filter = [];
    if (filterId.filters) {
      if (filterId.filters.length) {
        for (let i in filterProduct) {
          child_filter = [];
          for (let j in filterProduct[i].filters) {
            if (filterId.filters.indexOf(filterProduct[i].filters[j].id) > -1) {
              child_filter.push(filterProduct[i].filters[j].id);
            }
          }
          if (child_filter.length) {
            tmp_filter.push(child_filter);
          }
        }
        filterId.filters = tmp_filter;
        // console.log(tmp_filter)
      }
    }
    if (tmp_filter?.length > 0 || filterId?.filters?.filters?.length > 0) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?qTjSkUwI=" +
        window.JSON.stringify(filterId)
          .replaceAll("{", "--a/a--a/a--")
          .replaceAll("}", "/b-b/b-b/")
          .replaceAll("[", "//c---c//")
          .replaceAll("]", "///d-d///")
          .replaceAll('"', "/e-e////e--e////e-e/");
      window.history.pushState({ path: newurl }, "", newurl);

    } else {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
      window.history.pushState({ path: newurl }, "", newurl);
    }

    setTimeout(() => {
      const data = document.location.href;
      if (data.search("online-test-series") === -1) {
        if (data.search("cfa4920e8d54af8d443e1e0adedab0da") !== -1) {
          history.push("/online-test-series/?qTjSkUwI=" + document.location.search.substr(1).split("=")[1])
        } else {
          let url = document.location.search.substr(1).split("=")[1] ? "/courses?qTjSkUwI=" + document.location.search.substr(1).split("=")[1] : "/courses"
          history.push(url)
        }
      } else {
        if (data.search("courses") === -1) {
          if (data.search("cfa4920e8d54af8d443e1e0adedab0da") === -1) {
            let url = document.location.search.substr(1).split("=")[1] ? "/courses?qTjSkUwI=" + document.location.search.substr(1).split("=")[1] : "/courses"
            history.push(url)
          }
        }
      }
    }, 1000);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(filterId),
    };
    fetch(environment.ecomApiUrl + "product/list", requestOptions).then(
      (resp) => {
        // fetching api url for courses listing
        resp.json().then((result) => {
          setProduct(result.data); // set result in state this will set current/updated value of setProduct to product
          setFilters(filterId);
          ////console.log(product);
        });
      }
    );
    //console.log(filterId);
  };

  return (
    <>


      {window.location.href.includes("online-test-series") ? <Helmet>
        {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
        {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
        <title>ALLEN Digital Online Test Series: Classes 6 to 10, NEET & JEE</title>
        <meta name='description' content="Online Test Series by ALLEN Digital is designed for students to practise and nurture their preparation for their upcoming Classes 6 to 10, JEE and NEET-UG exams." />
        <meta property="og:title" content="Online Courses for JEE, NEET-UG and Class 6th to 10th" />
        <meta property="og:site_name" content="ALLEN Digital" />
        <meta property="og:url" content="https://digital.allen.ac.in/online-test-series" />
        <meta property="og:description" content="ALLEN Digital offers online courses for JEE, NEET-UG and Class 6th to 10th to suit different learning objectives and educational levels." />
        <meta property="og:type" content="product.group" />
        <meta property="og:image" content="https://digital.allen.ac.in/static/media/logo-new-r.4a9d1fde3fd2a6868a621b27352bda3a.svg" />

      </Helmet> : <Helmet>
        {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
        {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
        <title>Online Courses for JEE, NEET-UG and Class 6th to 10th</title>
        <meta name='description' content="ALLEN Digital offers online courses for JEE, NEET-UG and Class 6th to 10th to suit different learning objectives and educational levels." />
        <meta property="og:title" content="Online Courses for JEE, NEET-UG and Class 6th to 10th" />
        <meta property="og:site_name" content="ALLEN Digital" />
        <meta property="og:url" content="https://digital.allen.ac.in/courses" />
        <meta property="og:description" content="ALLEN Digital offers online courses for JEE, NEET-UG and Class 6th to 10th to suit different learning objectives and educational levels." />
        <meta property="og:type" content="product.group" />
      </Helmet>}
      <OltsPageSlider />
      <div className="container">
        <div className="row">
          {/* component  for filtering courses*/}
          <CoursesFilter
            handelEvent={filterProduct}
            clearAll={() => setFilterProduct(filterProduct)}
            key={filterProduct.id}
            onChange={onChangeEvent.bind()}
            filters={filterByFilters}
          /* onChange={handleChildClick.bind(this)} */
          />

          {/* component  for listing courses*/}
          {loading ? (
            <AllCourses
              handelEvent={{
                filtersUsed: filterProduct,
                data: product,
              }}
              onSearch={productList}
              onChange={onChangeEvent.bind()}
              key={product.id}
              filters={filterByFilters}
            />
          ) : (
            <div style={{ marginLeft: "40%", marginTop: "15%" }}>
              <TailSpin color="rgb(12, 112, 63)" height={80} width={80} />
            </div>
          )}

          {/*  <button >Prev</button>
            <button onClick={()=>onChangeEvent()}>Next</button> */}
        </div>

      </div>
      <CourseFilterBottom
        handelEvent={filterProduct}
        clearAll={() => setFilterProduct(filterProduct)}
        key={filterProduct.id}
        onChange={onChangeEvent.bind()}
        filters={filterByFilters}
      />
      {/* <CouresListFAQ/> */}
    </>
  );
};

export default Courses;
