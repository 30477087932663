import React from 'react'
import videoicon from "../../images/video.png";
import documenticon from "../../images/document.png";
import clockicon from "../../images/clock.png";
import certificateicon from "../../images/certificate.png";
import charticon from "../../images/chart.png";
import documentblankicon from "../../images/document-blank.png";
import languageicon from "../../images/language.png";
import bookicon from "../../images/book.png";
import { convertSecondsToReadableString } from '../../Services/courseService';
import { data } from 'jquery';
const EcomRightSidenav = (props) => {
   /*  componentWillReceiveProps(props) {
      this.setState({ detailsData: props.handelEvent });
    } */
    return (
        <>
            <nav className="col-md-3 d-none d-md-block sidebar ">
                        <div className="sidebar-sticky">
                            <ul className="nav flex-column p-2">
                                {/* prerequisite data */}
                                {
                                    props.data.prerequisite_knowledge ?
                                    <li className="nav-item prerequisite-knowledge">
                                        <div className="card p-3 course-card">
                                            <h5 className="font-weight-bold">Prerequisite Knowledge</h5>
                                            <div className="content" dangerouslySetInnerHTML={{__html: props.data.prerequisite_knowledge}}></div>
                                        </div>
                                    </li>:
                                    null
                                }
                                {/* at a glance data */}
                                {
                                    props.data.at_a_glance ?
                                    <li className="nav-item mb-3">
                                    <div className="card p-3 course-card">
                                        <h5 className="font-weight-bold">At a Glance</h5>
                                        <div className="content" dangerouslySetInnerHTML={{__html: props.data.at_a_glance}}></div>
                                    </div>
                                    </li>:
                                    null
                                }
                                {/* starting soon data */}
                                {
                                    (props.data.starting_soon?props.data.starting_soon.length:false)  ?
                                    <li className="nav-item mb-3 glance-section ">
                                        <div className="card p-3 course-card">
                                            <h5 className="font-weight-bold">Courses Starting Soon</h5>
                                            <ul className="nav flex-column p-2 ">
                                                {
                                                    (props.data.starting_soon?props.data.starting_soon:[]).map((itm,i) => {
                                                        return(
                                                            <>
                                                            <li className="nav-item mb-3"><i  className="fas fa-circle"></i><a href={"ecom/courses-details/"+itm.slug}>{itm.title}</a></li>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </li>:
                                    null
                                }
                            </ul>
                        </div>
                    </nav>
        </>
    );
}
export default EcomRightSidenav;