import React, {useState, useEffect} from "react";
import { toast } from "react-toastify";
// import { environment } from "../../Services/HttpData";

import { Oval } from  'react-loader-spinner'
import "../../css/ecom/ecomStyle.css";
import { Link } from "react-router-dom";
import MixpanelInit from "../Mixpanel/MixpanelInit";

const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
const CartDetails = (props1) => {
  const productDetails = props1?.productDetails;
  const props = props1 ? props1:[];
 // //console.log("props",props);
  const auth_token =  localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : '';
  let wid =   window.localStorage.getItem("IS_DASHBOARD_ICON")
  ? window.localStorage.getItem("IS_DASHBOARD_ICON")
  : false;
  
  let textInput = React.createRef();

  const [inputCoupon,setInputCoupon] = useState(true);
  const [haveCoupon,setHaveCoupon] = useState(true);
  const [couponMessage,setCouponMessage] = useState();
  const [couponTitle,setcouponTitle] = useState(props.coupon_title);
  const [cartDiscount,setCartDiscount] = useState(props.cart_discount_amount);
  const [loading,setLoading] = useState(true);
  const [showCoupon,setShowCoupon] = useState(false);
  const [couponList,setCouponList] = useState([]);
  const [text,setText] = useState();
  const [suggestion, setSuggestion] = useState([]);
  const [coupon_id_applied, set_coupon_id_applied] = useState("");

//console.log("couponcart",couponTitle);
  /* Price calculation */
  let [cartTotal,setcartTotal]= useState(props.product_total_amount);
  let [cartPayAmount,setCartPayAmount] = useState(props.cart_pay_amount);
  const [gstPrice,setGstPrice] = useState(props.gst_amount);
  let [cartFinalPayAmount,setCartFinalPayAmount] = useState();
  let finalAmountCart = props.final_pay_amount;
//console.log("cartTotal",cartTotal)
  const toggleCoupon = () =>{
    setShowCoupon(!showCoupon);
  }

  
  useEffect(()=>{
    
    const requestOptionsRaCart = {
      method: "GET",
      headers: {
        Authorization: "Basic " + auth_token,
      },
    };
    fetch(environment.ecomApiUrl + "cart/list", requestOptionsRaCart).then(
      (resp) => {
        // fetching api url for courses listing
        resp.json().then((result) => {
          if (result.status == "success") {
            set_coupon_id_applied(result?.data?.cart?.cart_coupon_id);
          }
        });
      }
    );
 },[]);

 const showSuggestion = () =>{
  setSuggestion(couponList);
 }

 const suggestionHandler = (text) =>{
  setText(text);
  setSuggestion([]);
 }

 const onChangeCoupon = (text) =>{
   let matches=[];
   if(text.length > 0){
    // //console.log(text)
     matches = couponList.filter(cpn=>{
       const regex = new RegExp(`${text}`,"gi");
       //console.log(cpn.title.match(regex))
       return cpn.code.match(regex)
     })
   }
   //console.log("matches",matches)
   setSuggestion(matches)
   setText(text)
   //console.log("cpn",suggestion)
 }
 
  const applyCoupon = () => {
    const coupon = textInput.current.value;
    //alert(coupon);
    const requestOptions = {
        method: 'POST',
        headers : {
          'Content-Type': 'application/json',
          'Authorization': 'Basic '+auth_token
        },
        body: JSON.stringify({
          "coupon_code" : coupon,
          "cart_item_id": props.cart_item_id
      })
      };
      fetch(environment.ecomApiUrl + 'coupon/apply',requestOptions).then((resp)=>{ // fetching api url for courses listing
          resp.json().then((result)=>{
             if(result.status === "success"){
              const requestOptionsRaCart = {
                method: 'GET',
                headers : {
                  'Authorization': 'Basic '+auth_token
                }
              };
              fetch(environment.ecomApiUrl + 'cart/list',requestOptionsRaCart).then((resp)=>{ // fetching api url for courses listing
                  resp.json().then((result1)=>{
                    set_coupon_id_applied(result1.data.cart.cart_coupon_id);

                    //setcartTotal(result1.data.cart.cart_amount);
                    //cartPayAmount = props.cart_pay_amount;
                    cartFinalPayAmount = props.final_pay_amount;
                    setCartDiscount(result1.data.cart.cart_discount_amount);
                    setCartPayAmount(result1.data.cart.cart_pay_amount);
                    setGstPrice(result1.data.cart.gst_amount);
                    setCartFinalPayAmount(result1.data.cart.final_pay_amount);
                    setCouponMessage(<button style={{border: "2px solid #046635", borderRadius: "8px", backgroundColor:"#046635" ,color:"#fff"}}>{result.message}</button>);
                    setcouponTitle(coupon);
                    //setCartTotal(result.data.net_amount);
                   // //console.log(result.message);
                    setInputCoupon(false);
                    setHaveCoupon(false);
                    props.onChange(result1.data.cart.cart_amount);
                    toast.success(result.message);
                  })
                })
               }else{
                toast.error(result.message);
                /* setcouponTitle(<span style={{color: "red"}}>Not A Valid Coupon</span>); */
              }
              
          })
      })
  };

  const removeCartCoupon = (cart_id) => {
   // let newauth_token = JSON.parse(auth_token);
    let config = {
      headers: {
        Authorization:
          "Bearer " + auth_token,
        "Content-Type": "application/json",
      },
    };
    fetch(
      environment.ecomApiUrl + "coupon/view-coupon/" + coupon_id_applied,
      config
    ).then((resp) => {
      let couponType = true;
      resp.json().then((result3) => {
        if (result3?.data.coupon_type == "Product") {
          couponType = false;
        }
        if (couponType) {
          const requestOptionsRPC = {
            method: "DELETE",
            headers: {
              Authorization: "Basic " + auth_token,
            },
          };
          fetch(
            environment.ecomApiUrl + "coupon/cart/remove/" + cart_id,
            requestOptionsRPC
          ).then((resp) => {
            // fetching api url for courses listing
            resp.json().then((result2) => {
              if (result2.status == "success") {
                const requestOptionsRaCartCoupon = {
                  method: "GET",
                  headers: {
                    Authorization: "Basic " + auth_token,
                  },
                };
                fetch(
                  environment.ecomApiUrl + "cart/list",
                  requestOptionsRaCartCoupon
                ).then((resp) => {
                  // fetching api url for courses listing
                  resp.json().then((result3) => {
                    //setEmiDetails(result3.data.items[0].emi);
                    setcouponTitle("");
                    setCartDiscount(result3.data.cart.cart_discount_amount);
                    setCartPayAmount(result3.data.cart.cart_pay_amount);
                    setGstPrice(result3.data.cart.gst_amount);
                    setCartFinalPayAmount(result3.data.cart.final_pay_amount);
                    setHaveCoupon(true);
                    setShowCoupon(false);
                    setInputCoupon(true);
                    setCouponMessage("");
                    props.onChange(result3.data.cart.cart_amount);
                    toast.success(result2.message);
                  });
                });
                //setRemoveLoadingButton(true);
              } else {
                //toast.error(result.message);
              }
            });
          });
          setText();
          setSuggestion([]);
          setCartDiscount(0);
          setHaveCoupon(true);
          setShowCoupon(false);
          setInputCoupon(true);
          setCouponMessage("");
          setcouponTitle("");
        } else {
          const requestOptionsRPC = {
            method: "DELETE",
            headers: {
              Authorization: "Basic " + auth_token,
            },
          };
          fetch(
            environment.ecomApiUrl +
              "coupon/product/remove/" +
              props.cart_item_id,
            requestOptionsRPC
          ).then((resp) => {
            // fetching api url for courses listing
            resp.json().then((result2) => {
              if (result2.status == "success") {
                const requestOptionsRaCartCoupon = {
                  method: "GET",
                  headers: {
                    Authorization: "Basic " + auth_token,
                  },
                };
                fetch(
                  environment.ecomApiUrl + "cart/list",
                  requestOptionsRaCartCoupon
                ).then((resp) => {
                  // fetching api url for courses listing
                  resp.json().then((result3) => {
                    //setEmiDetails(result3.data.items[0].emi);
                    setcouponTitle("");
                    setCartDiscount(result3.data.cart.cart_discount_amount);
                    setCartPayAmount(result3.data.cart.cart_pay_amount);
                    setGstPrice(result3.data.cart.gst_amount);
                    setCartFinalPayAmount(result3.data.cart.final_pay_amount);
                    setHaveCoupon(true);
                    setShowCoupon(false);
                    setInputCoupon(true);
                    setCouponMessage("");
                    props.onChange(result3.data.cart.cart_amount);
                    toast.success(result2.message);
                  });
                });
                //setRemoveLoadingButton(true);
              } else {
                //toast.error(result.message);
              }
            });
          });
      setText();
    setSuggestion([])
    setCartDiscount(0);
    setHaveCoupon(true);
    setShowCoupon(false);
    setInputCoupon(true);
    setCouponMessage("");
    setcouponTitle("");
        }
      });
    });
  };
  // const removeCartCoupon = (cart_id) => {
  //   const requestOptionsRPC = {
  //     method: 'DELETE',
  //     headers : {
  //       'Authorization': 'Basic '+auth_token
  //     }
  //   };
  //   fetch(environment.ecomApiUrl + 'coupon/cart/remove/'+cart_id,requestOptionsRPC).then((resp)=>{ // fetching api url for courses listing
  //       resp.json().then((result2)=>{
  //         if (result2.status == "success") {
           
  //           const requestOptionsRaCartCoupon = {
  //             method: 'GET',
  //             headers : {
  //               'Authorization': 'Basic '+auth_token
  //             }
  //           };
  //           fetch(environment.ecomApiUrl + 'cart/list',requestOptionsRaCartCoupon).then((resp)=>{ // fetching api url for courses listing
  //               resp.json().then((result3)=>{
  //                 //setEmiDetails(result3.data.items[0].emi);
  //                // //console.log(emiDetails);
  //                 setcouponTitle("");
  //                 setCartDiscount(result3.data.cart.cart_discount_amount);
  //                 setCartPayAmount(result3.data.cart.cart_pay_amount);
  //                 setGstPrice(result3.data.cart.gst_amount);
  //                 setCartFinalPayAmount(result3.data.cart.final_pay_amount);
  //                 setHaveCoupon(true);
  //                 setShowCoupon(false);
  //                 setInputCoupon(true);
  //                 setCouponMessage("");
  //                 props.onChange(result3.data.cart.cart_amount);
  //                 toast.error(result2.message);
  //               })
  //           })
  //           ////console.log(result2.status);
  //           //setRemoveLoadingButton(true);
            
  //         }else{
  //           //toast.error(result.message);
  //         }
          
  //       })
  //   })
  //   setText();
  //   setSuggestion([])
  //   setCartDiscount(0);
  //   setHaveCoupon(true);
  //   setShowCoupon(false);
  //   setInputCoupon(true);
  //   setCouponMessage("");
  //   setcouponTitle("");
  // }

  const mixpanelEventCall = () => {
    MixpanelInit("Track", "checkout_started", {
      course_id:productDetails?.course_id,
      course_title:productDetails?.title,
      academic_session:productDetails?.academic_session,
      commencement_date:productDetails?.commencement_date ,
      duration:productDetails?.duration,
      study_mode: 'Online (Live)',
      study_medium:productDetails?.medium_name,
      course_fee:productDetails?.display_price,
      refund_last_day: productDetails?.refund_last_day,
      discount: props?.cart_discount_amount,
      total_price:props?.cart_amount,
      'coupon_applied?': props?.cart_coupon_id !== null ? "Y":"N",
      coupon_code:props?.coupon_title

    })
  }

  const proceedCheckOut = (cartId) => {
    
    if (localStorage.getItem("userWID")) {
      const auth_token =  localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : '';
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Basic '+auth_token
         },
        body: JSON.stringify({
          "cart_id" : cartId
      })
      };
     
      fetch(environment.ecomApiUrl + 'order/checkout',requestOptions).then((resp)=>{ // fetching api url for courses listing
        resp.json().then((result) => {
         
             // setOrderId(result.data.order_id);  // set result in state this will set current/updated value of setProduct to product
              const orderId = result.data.order_id
             // //console.log(orderId);
            // //console.log("proceed succesfully");
             window.location = "/ecom/checkout/";
          })
      })
    }else{
      // doLogin();
    }
    
}
const proceedToCheckout = () => {
  // window.location = '/ecom/checkout'
  // return
  mixpanelEventCall();
    const auth_token =  localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : '';

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + auth_token,
      }
    }
    fetch(environment.ecomApiUrl + "order/check-active-course", requestOptions).then(
      (res) => {
        res.json().then((result) => {
          if (result?.data?.course_is_active == '1' || result?.data?.course_is_active == 1) {
            let id = 't101';
            toast.warning(result.message, {
              toastId: id,
            });
          }
          else {
            window.location = '/ecom/checkout';
          }
        })
      }
    );
  

}

/*   handleOTPInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({ [name]: value });
  }; */
 
    return (
      <>
      {
        
        <div className="col-12 col-md-3 p-4 order-summary pt-5">
          <h6 className="font-weight-bold cart-summery">Order Summary</h6>
          {
              loading?
              <div className="row m-0">
            <p className="cart-label">Product Total</p>
            <p className="font-weight-bold ml-auto cart-price">
              {"₹ "}
              {cartTotal
                ? cartTotal
                : props.cart_amount}
            </p>
          </div>:
          <Oval color="#00BFFF" height={80} width={80} />
          }
            {/* Cart Discount */}
          {
            cartDiscount === 0 ? null:
            <div className="row m-0">
            <p className="cart-label">Discount</p>
            <p className="font-weight-bold ml-auto cart-price">
              { "₹ "}{" "}
              {cartDiscount
                ? cartDiscount
                : props.cart_discount_amount}
            </p>
          </div>
          }
            {/* Price After Discount - cartPriceAfterDiscount*/  }
          {
            cartDiscount === 0 ? null:
            <div className="row m-0">
            <p className="cart-label">Net Price</p>
            <p className="font-weight-bold ml-auto cart-price">
              { "₹"}{" "}
              {cartPayAmount
                ? cartPayAmount
                : props.cart_pay_amount}
            </p>
          </div>
          }
            
          {/* GST */}
          {     
            props.gst_rate === 0 ? null:
            <div className="row m-0">
            <p className="cart-label">GST ({props.gst_rate+"%"})</p>
            <p className="font-weight-bold ml-auto cart-price">
              { "₹"
                }{" "}
              {gstPrice 
                ? gstPrice
                :props.gst_amount}
            </p>
          </div>
          }
          
          <hr />
          <div className="row m-0">
            <p className="cart-label">Total</p>
            <p className="font-weight-bold ml-auto cart-price">
              { "₹ "}
                
              {cartFinalPayAmount?cartFinalPayAmount:finalAmountCart}
            </p>
          </div>
          <div className="row m-0">
            <form className="w-100">
           {/*  <label className="cart-label" onClick={toggleCoupon}>Have coupon?</label> */}
            {haveCoupon ? (couponTitle ? null:<label className="cart-label" onClick={toggleCoupon}>Have a Coupon?</label>):null}
            {
                showCoupon ?
                (
                <>
                <div className="form-group">
                  {
                    inputCoupon ?
                      <div className="input-group cart-coupon-box">
                        <input
                          type="text"
                          className="form-control"
                          name="couponCode1"
                          value={text}
                          placeholder="Coupon code"
                          autoComplete={"off"}
                          ref={textInput}
                          onChange={e=>onChangeCoupon(e.target.value)}
                          onClick={showSuggestion}
                        />
                        <span className="d-flex">
                        <button  
                          type="button"
                          className="btn btn-apply coupon cross-btn2"
                          onClick={removeCartCoupon}
                          style={{color:"#dc3545,"}}
                        >
                        X
                        </button>
                      </span>
                        <span className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-apply coupon"
                            //disabled={!props.cart_coupon_id}
                            onClick={applyCoupon}
                          >
                            Apply
                          </button>
                        </span>
                      </div>
                    :
                    null
                  }
                  
                </div>
                {
                      suggestion && suggestion.map((sg,ig)=>{
                        return(
                          <>
                              
                                <div className="coupon-list" onClick={()=>suggestionHandler(sg.code)}>
                                  <span className="coupon-design2">{sg.code}</span> <span className="" style={{paddingLeft: "35%",paddingTop: "2%"}}>{sg.title} </span>
                                </div>
                              
                          </>
                        )
                      })
                    }
                </>)
                :
                null
              }
              {
                couponTitle ?
                (
                  <>
                      <div className="coupon-applied-bg">
                        <span className="coupon-design2 ">
                          {couponTitle}
                            <button
                              type="button"
                              className="btn btn-apply remove-cart-coupon"
                              onClick={()=>removeCartCoupon(props.cart_id)}
                              style={{color:"#dc3545"}}
                            >
                              Remove
                            </button>
                        </span>
                      </div>
                  </>
                ):null
              }
            
              {
                wid ? 
                <a className="btn btn-checkout mt-4"
                style={{color: "white"}} 
                 onClick={() => proceedToCheckout(props.cart_id)} 
                  // to={"/ecom/checkout"} 
                  >Proceed to Checkout</a>
                :
                <a className="btn btn-checkout mt-4" href={"javascript:;"} onClick={() => proceedCheckOut(props.cart_id)}>Login To Proceed to Checkout</a>
              }
              
            </form>
          </div>
       </div>
      }
        
      </>
    );
  }


export default CartDetails;
