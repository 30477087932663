import React from 'react';
import playStoreGreen from "../images/play-store-green.png"
import appStoreGreen from "../images/app-store-green.png"
/* import formManImg from "../images/form-man.png"
import appMobile from "../images/app-mobile.png"
import featureImg1 from "../images/feature-1.png"
import featureImg2 from "../images/feature-2.png"
import featureImg3 from "../images/feature-3.png"
import featureImg4 from "../images/feature-4.png"
import mobileAppListItem1 from "../images/list-item-1.png"
import mobileAppListItem2 from "../images/list-item-2.png"
import mobileAppListItem3 from "../images/list-item-3.png"
import mobileAppListItem4 from "../images/list-item-4.png"
import mobileAppListItem5 from "../images/list-item-5.png"
import bannerLearningApp from "../images/banner-form-img.png" */
import HomeProgrammes from '../components/home/HomeProgrammes';
// import { environment } from '../Services/HttpData';
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class LearningApp extends React.Component {
    constructor(props) {
        super(props);
        // //console.log(props);
        this.state = {
            PageContent: {},
            examsList: [],
        };
    }
    async componentDidMount() {
        // POST request using fetch with async/await
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ "keyword": '' })
        // };
        // fetch(environment.baseUrl + 'view_list_all', requestOptions)
        //     .then(async response => {
        //         // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        //         const data = /* isJson && */ await response.json();
        //         // check for error response
        //         // //console.log(response);
        //         if (!response.ok) {
        //             // get error message from body or default to response status
        //             const error = (data && data.message) || response.status;
        //             return Promise.reject(error);
        //         }
        //         let allData = data.data;
        //         let sections = {};
        //         let index = allData.findIndex((item) => item.slug === "learning-app");
        //         allData[index]?.sections.map((item) => {
        //             switch (item.id) {
        //                 case 137:
        //                     sections.banner = item;
        //                     break;
        //                 default:
        //                     sections[item.slug] = item;
        //                     break;
        //             }
        //         });
        //         //console.log(sections);
        //         this.setState({ PageContent: sections });
        //         this.fetchExams();
        //         // //console.log(this.state.PageContent);
        //     })
        //     .catch(error => {
        //         this.setState({ errorMessage: error.toString() });
        //         console.error('There was an error!', error);
        //     });
        const getViewListAll = () => {
            if (!window?.AllenDigitalFEBSite) {
                setTimeout(() => {
                getViewListAll();
              }, 50);
              return false
            }
             let allData = window?.AllenDigitalFEBSite.data;
                  let sections = {};
                  let index = allData.findIndex((item) => item.slug === "learning-app");
                  allData[index]?.sections.map((item) => {
                      switch (item.id) {
                          case 137:
                              sections.banner = item;
                              break;
                          default:
                              sections[item.slug] = item;
                              break;
                      }
                  });
                  //console.log(sections);
                  this.setState({ PageContent: sections });
                  this.fetchExams();
          };
          getViewListAll();
        /* const response = await fetch(environment.baseUrl+'user_blog_category_list', requestOptions);
        const data = await response.json();
        if(data.replyCode==="success"){
            //console.log(data.data);
            this.setState(data.data);
        } */
    }
    fetchExams() {
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ "featured": '1', 'isAdmin': '0' })
        // };
        // fetch(environment.baseUrl + 'exam_list', requestOptions)
        //     .then(async response => {
        //         // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        //         const data1 = /* isJson && */ await response.json();
        //         // check for error response
        //         // //console.log(response);
        //         if (!response.ok) {
        //             // get error message from body or default to response status
        //             const error = (data1 && data1.message) || response.status;
        //             return Promise.reject(error);
        //         }
        //         // //console.log(data.data[index]);
        //         // let sections = {};   
        //         // //console.log(data1.data,this.state);
        //         this.setState({ examsList: data1.data });
        //         // //console.log(this.state);
        //     })
        //     .catch(error => {
        //         this.setState({ errorMessage: error.toString() });
        //         console.error('There was an error!', error);
        //     });
        const getExamList = () => {
            if (!window?.AllenDigitalFEBSiteExamList) {
              setTimeout(() => {
                getExamList();
              }, 50);
              return false
            }
          const data = window?.AllenDigitalFEBSiteExamList;
          let examsList = data.data
          this.setState({ examsList:data.data});
          };
          getExamList();
    }
    render() {
        return <>
            <div className="container-fluid">
                <div className="banner-form">
                    <div className="container">
                        <div className="row mx-0 px-2 ">
                            <div className="col-12 col-lg-7 d-none d-lg-block px-5 pt-5 pb-0">
                                {this.state.PageContent?.banner?.admin_image && (<img src={environment.baseUrlImg +
                                    this.state.PageContent?.banner?.admin_image} alt="" width="100%" className="p-5" />)}
                            </div>
                            <div className="col-12 col-lg-5 form-background px-5 pt-5 pb-0">
                                <h3 className="font-weight-bold text-center text-green mb-4">{this.state.PageContent?.banner?.title}</h3>
                                <form>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col">
                                                <input type="text" className="form-control  px-2 mb-1" placeholder="First name" />
                                            </div>
                                            <div className="col">
                                                <input type="text" className="form-control  px-2 mb-1" placeholder="Last name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="tel" className="form-control  px-2 mb-1" id="mobile" placeholder="Mobile No." />
                                    </div>
                                    <div className="form-group">
                                        <input id="email" type="email" className="form-control  px-2 mb-1" placeholder="Email" />
                                    </div>
                                    <div className="form-group">
                                        <select id="State" className="form-control px-2 mb-1">
                                            <option>Rajasthan</option>
                                        </select>
                                    </div>
                                    <div className="row m-0 align-items-center custom-checkbox">
                                        <input className="filter-check" type="checkbox"
                                            aria-label="Checkbox for following text input" />
                                        <p className="mb-0 ml-2">{this.state.PageContent?.banner?.meta_description}</p>
                                    </div>
                                    <button type="submit" className="btn btn-green-profile w-100 my-3">Send Link</button>
                                </form>
                                <div className="row mx-0 my-3 justify-content-center">
                                    <img src={playStoreGreen} alt="" width="27%" className="mr-3" />
                                    <img src={appStoreGreen} alt="" width="27%" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="section-title">
                    <h3 className="text-center font-weight-bold">{this.state.PageContent?.amazing_features?.title}</h3>
                    <p className="text-center" dangerouslySetInnerHTML={{ __html: this.state.PageContent?.amazing_features?.meta_description }}></p>
                </div>

                <div className="row">
                    {this.state.PageContent?.amazing_features?.content?.map((item) => {
                        return (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={item.id}>
                                <div className="card text-center course-card">
                                    {/* <img src={item?.image} alt="" width="50px" className="mb-3 mx-auto" /> */}
                                    {item?.image && (<img src={environment.baseUrlImg +
                                        item?.image} alt={item?.title} width="50px" className="mb-3 mx-auto" />)}
                                    <h4 className="font-weight-bold">{item?.title}</h4>
                                    <p className=" mb-1" dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                                </div>
                            </div>)
                    })}

                    {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <div className="card text-center course-card">
                    <img src={featureImg2} alt="" width="50px" className="mb-3 mx-auto"/>
                    <h4 className="font-weight-bold">Feature 1</h4>
                    <p className=" mb-1">Lorem ipsum dolor sit amet, 
                        consectetur adipiscing elit. </p>
                </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <div className="card text-center course-card">
                    <img src={featureImg3} alt="" width="50px" className="mb-3 mx-auto"/>
                    <h4 className="font-weight-bold">Feature 1</h4>
                    <p className=" mb-1">Lorem ipsum dolor sit amet, 
                        consectetur adipiscing elit. </p>
                </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <div className="card text-center course-card">
                    <img src={featureImg4} alt="" width="40px" className="mb-3 mx-auto align-content-center"/>
                    <h4 className="font-weight-bold">Feature 1</h4>
                    <p className=" mb-1">Lorem ipsum dolor sit amet, 
                        consectetur adipiscing elit. </p>
                </div>
            </div> */}
                </div>
            </div>

            <div className="container-fluid mobile-app mt-5">
                <div className="container p-5">
                    <div className="row mx-0 justify-content-center">
                        <div className="col-12 col-xl-6">
                                {this.state.PageContent?.how_to_use?.admin_image && (<img src={environment.baseUrlImg +
                                    this.state.PageContent?.how_to_use?.admin_image} alt={this.state.PageContent?.how_to_use?.title} width="100%" className="p-5" />)}
                            {/* <img src={appMobile} alt="" width="100%" /> */}
                        </div>
                        <div className="col-12 col-xl-6 py-4 mt-2">
                            <h4 className="font-weight-bold mb-4">{this.state.PageContent?.how_to_use?.title}</h4>
                            {this.state.PageContent?.how_to_use?.content?.map((item) => {
                        return (
                            <div className="row mx-0 align-items-center list-item-row" key={item.id}>
                            {item?.image && (<img src={environment.baseUrlImg +
                                item?.image} alt={item?.title} width="40px" className="mr-3 mb-2"/>)}
                                {/* <img src={mobileAppListItem1} alt="" width="40px" className="mr-3 mb-2" /> */}
                                <div>
                                    <h6 className="font-weight-bold mb-1">{item?.title}</h6>
                                    <p className="mb-0" dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                                </div>
                            </div>)}
                            )}
                            {/* <div className="row mx-0 align-items-center list-item-row">
                                <img src={mobileAppListItem2} alt="" width="40px" className="mr-3 mb-2" />
                                <div>
                                    <h6 className="font-weight-bold mb-1">Explore Courses</h6>
                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                </div>
                            </div>
                            <div className="row mx-0 align-items-center list-item-row">
                                <img src={mobileAppListItem3} alt="" width="40px" className="mr-3 mb-2" />
                                <div>
                                    <h6 className="font-weight-bold mb-1">Purchase</h6>
                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                </div>
                            </div>
                            <div className="row mx-0 align-items-center list-item-row">
                                <img src={mobileAppListItem4} alt="" width="40px" className="mr-3 mb-2" />
                                <div>
                                    <h6 className="font-weight-bold mb-1">Learn, Watch, Read, Test, Ask Doubts</h6>
                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                </div>
                            </div>
                            <div className="row mx-0 align-items-center list-item-row">
                                <img src={mobileAppListItem5} alt="" width="40px" className="mr-3 mb-2" />
                                <div>
                                    <h6 className="font-weight-bold mb-1">Analyse & Practice</h6>
                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <HomeProgrammes handelEvent={this.state.PageContent?.allen_digital_programs} exams={this.state.examsList} />

            {/* <div className="container ">
        <div className="sub-heading my-5">
            <h3 className="text-center font-weight-bold">ALLEN DIGITAL PROGRAMS</h3>
        </div>
        <div className="d-sm-flex flex-sm-row flex-sm-wrap justify-content-md-center justify-content-lg-between mt-4">
            
            <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img src="images/neet.png" alt="" className="mb-4" width="50px"/>
                <p className="font-weight-bold mb-4">Class 9, 10</p>
                <a href="exam.php"><button type="button" className="btn btn-primary nav-btn font-weight-bold">Explore Now</button></a>
            </div>
            <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img src="images/nta.png" alt="" className="mb-4" width="50px"/>
                <p className="font-weight-bold mb-4">JEE</p>
                <a href="exam.php"><button type="button" className="btn btn-primary nav-btn font-weight-bold">Explore Now</button></a>
            </div>
            <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img src="images/nta.png" alt="" className="mb-4" width="50px"/>
                <p className="font-weight-bold mb-4">NEET</p>
                <a href="exam.php"><button type="button" className="btn btn-primary nav-btn font-weight-bold">Explore Now</button></a>
            </div>
            <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img src="images/cafinal.png" alt="" className="mb-4" width="50px"/>
                <p className="font-weight-bold mb-4">CA Foundation</p>
                <a href="exam.php"><button type="button" className="btn btn-primary nav-btn font-weight-bold">Explore Now</button></a>
            </div>
            <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img src="images/CMA.png" alt="" className="mb-4" width="50px"/>
                <p className="font-weight-bold mb-4">CMA</p>
                <a href="exam.php"><button type="button" className="btn btn-primary nav-btn font-weight-bold">Explore Now</button></a>
            </div>
            <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img src="images/rasfinal.png" alt="" className="mb-4" width="50px"/>
                <p className="font-weight-bold mb-4">RAS</p>
                <a href="exam.php"><button type="button" className="btn btn-primary nav-btn font-weight-bold">Explore Now</button></a>
            </div>
            
        </div>
    </div>  */}

            <div className="container mb-0 mt-5 bg-light p-0">
                <div className="row mx-0">
                    <div className="col-12 col-md-5 form-background p-5">
                        <h3 className="font-weight-bold text-center text-green mb-4">{this.state.PageContent?.allen_digital_programs?.subtitle}</h3>
                        <form>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col">
                                        <input type="text" className="form-control  px-2 mb-1" placeholder="First name" />
                                    </div>
                                    <div className="col">
                                        <input type="text" className="form-control  px-2 mb-1" placeholder="Last name" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="tel" className="form-control  px-2 mb-1" id="mobile" placeholder="Mobile No." />
                            </div>
                            <div className="form-group">
                                <input id="email" type="email" className="form-control  px-2 mb-1" placeholder="Email" />
                            </div>
                            <div className="form-group">
                                <select id="State" className="form-control px-2 mb-1">
                                    <option>Rajasthan</option>
                                </select>
                            </div>
                            <div className="row m-0 align-items-center custom-checkbox">
                                <input className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input" />
                                <p className="mb-0 ml-2">Lorem Ipsum is simply dummy text of the printing</p>
                            </div>
                            <button type="submit" className="btn btn-green-profile w-100 my-3">Send Link</button>
                        </form>
                        <div className="row mx-0 my-3 justify-content-center">
                            <img src={playStoreGreen} alt="" width="27%" className="mr-3" />
                            <img src={appStoreGreen} alt="" width="27%" />
                        </div>
                    </div>
                    <div className="col-12 col-md-7 d-none d-md-block">
                        {/* <img src={formManImg} alt="" width="100%" /> */}
                        {this.state.PageContent?.allen_digital_programs?.admin_image && (<img src={environment.baseUrlImg +
                                this.state.PageContent?.allen_digital_programs?.admin_image} alt={this.state.PageContent?.allen_digital_programs?.title} width="100%" className="mr-3 mb-2"/>)}
                    </div>
                </div>
            </div>
        </>;
    }
}

export default LearningApp;