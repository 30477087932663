import React from "react";
import TestimonialsBanner from "../components/Testimonials/TestimonialsBanner";
import VIdeoTestimonials from "../components/Testimonials/VIdeoTestimonials";
import testimonials_list from "../js/testimonials_list"
// import { environment } from '../Services/HttpData';
import { Helmet } from "react-helmet";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);
class Testimonials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PageContent: {},
      testimonials: [],
    };
  }
  async fetchTestimonials() {
    // POST request using fetch with async/await
    this.setState({ testimonials: testimonials_list?.data });
  }
  async componentDidMount() {
    // POST request using fetch with async/await
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ "keyword": '' })
    // };
    // fetch(environment.baseUrl + 'view_list_all', requestOptions)
    //   .then(async response => {
    //     // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
    //     const data = /* isJson && */ await response.json();
    //     // check for error response
    //     // //console.log('view-lists',data);
    //     if (!response.ok) {
    //       // get error message from body or default to response status
    //       const error = (data && data.message) || response.status;
    //       return Promise.reject(error);
    //     }
    //     let index = data.data.findIndex((item) => item.slug === "testimonials");
    //     // //console.log(data.data[index]);
    //     let sections = {};
    //     data.data[index]?.sections.map((item) => {
    //       // //console.log(item)
    //       switch (item.id) {
    //         case 136:
    //           sections.banner = item;
    //           break;
    //       }
    //     });
    //     this.setState({ PageContent: sections });
    //     this.fetchTestimonials();
    //     // //console.log('jobs',this.state.PageContent);
    //   })
    //   .catch(error => {
    //     this.setState({ errorMessage: error.toString() });
    //     console.error('There was an error!', error);
    //   });
    const getViewListAll = () => {
      if (!window?.AllenDigitalFEBSite) {
        setTimeout(() => {
          getViewListAll();
        }, 50);
        return false;
      }
      const data = window?.AllenDigitalFEBSite;

      let index = data.data.findIndex((item) => item.slug === "testimonials");
      // //console.log(data.data[index]);
      let sections = {};
      data.data[index]?.sections.map((item) => {
        // //console.log(item)
        switch (item.id) {
          case 136:
            sections.banner = item;
            break;
        }
      });
      this.setState({ PageContent: sections });
      this.fetchTestimonials();
    };
    getViewListAll();
  }
  render() {
    return (
      <>
        <Helmet>
          {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
          <title>Best online coaching classes for NEET | Allen Digital</title>
          <meta
            name="description"
            content="Allen Digital's experience & pedagogy makes it the best online classes for NEET exams. Enroll today to join our online coaching classes for NEET!"
          />
          <meta
            name="Keywords"
            content="best online classes for neet, online coaching classes for neet, best online classes for
neet, best online classes for neet preparation, best online coaching centre for neet, best online
coaching classes for neet"
          />
          {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
        </Helmet>
        <TestimonialsBanner handelEvent={this.state.PageContent?.banner} />
        <VIdeoTestimonials handelEvent={this.state.testimonials} />
      </>
    );
  }
}

export default Testimonials;
