import React from 'react';
import learningcycleicon from "../../images/learning-cycle.png";
class LearningCycle extends React.Component {
    render() {
        return <>
            <div className="container mt-4 mt-md-5">
                <div className='row'>
                    <div className='col-12'>
                        <h2 className="font-weight-bold text-center section-headline">THE LEARNING CYCLE</h2>
                        {/*  <p className="text-muted text-center mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec est dui, laoreet id maximus eget, tincidunt ut felis. Sed lectus turpis, cursus sit amet tincidunt et, convallis id orci. Sed a felis vel sapien egestas feugiat eget et mauris. Aenean eget massa velit.</p> */}
                        <div>
                            <img src={learningcycleicon} alt="" className='img-fluid d-block mx-auto' />
                        </div>
                    </div>
                </div>
            </div>


        </>;
    }
}

export default LearningCycle;