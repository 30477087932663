import React from "react";

export default function TabContainer(props) {
  return (
    <ul className="container nav nav-pills mt-4 mt-md-4">
      {props?.contant.map((val, ind) => {
        if (!val.dropdown) {
          return (
            <li
              onClick={props?.tabChange}
              className={`nav-item mr-4 h5 ${
                val.active ? `border-bottom-green my-order-text` : ``
              }`}
              role="button"
            >
              <span className="border-bottom-green ">
                <b>{val.title}</b>
              </span>
            </li>
          );
        } else {
          return (
            <li
              onClick={props?.tabChange}
              className={`nav-item mr-4 h5 ${
                val.active ? `border-bottom-green my-order-text` : ``
              }`}
              role="button"
            >
              <span className="border-bottom-green ">
                <b>{val.title}</b>
              </span>
            </li>
          );
        }
      })}
    </ul>
  );
}
