import React from "react";
import AboutUsBanner from "../components/AboutUs/AoutusBanner";
const PypjeeMain2024 = () => {
 
  return (
    <>
      <AboutUsBanner handelEvent={() => { }} slug="JEE Main January 2024" />
      {
   <>
   <div className="tab-content" id="pills-tabContent">
      <div className=" app-features">
         <div className="container py-5">
            <div className="">
               <h2 className="font-weight-bold text-center mb-3"></h2>
               <div className="card py-5 course-card">
                  <div className="row">
                     <div className="col-12 col-lg-12">
                        <section class="bg-1">
                           <h2>
                              JEE Main January 2024 Question Papers with Solutions PDF
                              (English)
                           </h2>
                           <div className="table-responsive">
                              <table class="table  ace-table" width="100%">
                                 <tr class="table-first-heading">
                                    <th rowspan="2" style={{verticalAlign:"middle"}}>Date</th>
                                    <th rowspan="2" style={{verticalAlign:"middle"}}>Shift</th>
                                    <th rowspan="2" style={{verticalAlign:"middle", textAlign:"center"}}>Without Solution</th>
                                    <th colspan="3" className="text-center">With Solution</th>
                                 </tr>
                                 <tr class="table-first-heading text-center">
                                    <th>Physics</th>
                                    <th>Chemistry</th>
                                    <th>Maths</th>
                                 </tr>
                                 <tr>
                                    <td rowspan="2" className="text-left">27 January 2024</td>
                                    <td className="text-left">Shift 1 (9:00 AM - 12:00 PM)</td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/27-01-2024/morning/without-solution/JEE_Main.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/27-01-2024/morning/with-solution/physics.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/27-01-2024/morning/with-solution/chemistry.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/27-01-2024/morning/with-solution/mathematics.pdf">Download</a></td>
                                 </tr>
                                 <tr>
                                    <td className="text-left">Shift 2 (3:00 PM - 06:00 PM)</td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/27-01-2024/evening/without-solution/JEE_Main.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/27-01-2024/evening/with-solution/physics.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/27-01-2024/evening/with-solution/chemistry.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/27-01-2024/evening/with-solution/mathematics.pdf">Download</a></td>
                                 </tr>
                                 <tr>
                                    <td rowspan="2" className="text-left">29 January 2024</td>
                                    <td className="text-left">Shift 1 (9:00 AM - 12:00 PM)</td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/29-01-2024/morning/without-solution/JEE_Main.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/29-01-2024/morning/with-solution/physics.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/29-01-2024/morning/with-solution/chemistry.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/29-01-2024/morning/with-solution/mathematics.pdf">Download</a></td>
                                 </tr>
                                 <tr>
                                    <td className="text-left">Shift 2 (3:00 PM - 06:00 PM)</td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/29-01-2024/evening/without-solution/JEE_Main.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/29-01-2024/evening/with-solution/physics.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/29-01-2024/evening/with-solution/chemistry.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/29-01-2024/evening/with-solution/mathematics.pdf">Download</a></td>
                                 </tr>
                                 <tr>
                                    <td rowspan="2" className="text-left">30 January 2024</td>
                                    <td className="text-left">Shift 1 (9:00 AM - 12:00 PM)</td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/30-01-2024/morning/without-solution/JEE_Main.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/30-01-2024/morning/with-solution/physics.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/30-01-2024/morning/with-solution/chemistry.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/30-01-2024/morning/with-solution/mathematics.pdf">Download</a></td>
                                 </tr>
                                 <tr>
                                    <td className="text-left">Shift 2 (3:00 PM - 06:00 PM)</td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/30-01-2024/evening/without-solution/JEE_Main.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/30-01-2024/evening/with-solution/physics.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/30-01-2024/evening/with-solution/chemistry.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/30-01-2024/evening/with-solution/mathematics.pdf">Download</a></td>
                                 </tr>

                                 <tr>
                                    <td rowspan="2" className="text-left">31 January 2024</td>
                                    <td className="text-left">Shift 1 (9:00 AM - 12:00 PM)</td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/31-01-2024/morning/without-solution/JEE_Main.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/31-01-2024/morning/with-solution/physics.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/31-01-2024/morning/with-solution/chemistry.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/31-01-2024/morning/with-solution/mathematics.pdf">Download</a></td>
                                 </tr>
                                 <tr>
                                    <td className="text-left">Shift 2 (3:00 PM - 06:00 PM)</td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/31-01-2024/evening/without-solution/JEE_Main.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/31-01-2024/evening/with-solution/physics.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/31-01-2024/evening/with-solution/chemistry.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/31-01-2024/evening/with-solution/mathematics.pdf">Download</a></td>
                                 </tr>

                                 <tr>
                                    <td rowspan="2" className="text-left">01 February 2024</td>
                                    <td className="text-left">Shift 1 (9:00 AM - 12:00 PM)</td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/01-02-2024/morning/without-solution/JEE_Main.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/01-02-2024/morning/with-solution/physics.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/01-02-2024/morning/with-solution/chemistry.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/01-02-2024/morning/with-solution/mathematics.pdf">Download</a></td>
                                 </tr>
                                 <tr>
                                    <td className="text-left">Shift 2 (3:00 PM - 06:00 PM)</td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/01-02-2024/evening/without-solution/JEE_Main.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/01-02-2024/evening/with-solution/physics.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/01-02-2024/evening/with-solution/chemistry.pdf">Download</a></td>
                                    <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/jee_main_january_2024_ADPL/01-02-2024/evening/with-solution/mathematics.pdf">Download</a></td>
                                 </tr>
                                
                              </table>
                           </div>
                        </section>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   </>
      }
    </>
  );
};

export default PypjeeMain2024;
