import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import highlighticon from '../../images/highlighticon.png'

const ImoResult2023 = () => {
    const [resultData, set_ResultData] = useState([])
   // const [resultDataLevel2, set_ResultDataLevel2] = useState([])
    function getResultData() {
        if (window?.IMO2023) {
            // set data
            set_ResultData(window?.IMO2023)
           // set_ResultDataLevel2(window?.IEOLEVEL2)
        } else {
            setTimeout(() => {
                getResultData();
            }, 100)
        }
    }
    useEffect(() => {
        getResultData()
    }, [])

    const sharetab = (e) => {
        document.querySelector("#root > div > div > div:nth-child(3) > div > div.share-wrapper > i").classList.toggle("active")
        document.querySelector("#root > div > div > div:nth-child(3) > div > div.share-wrapper > ul").classList.toggle("active")
    }

    return (
        <div>
            <div className="share-wrapper">
                <i className="fa fa-share-alt share" onClick={sharetab}></i>
                <ul className="social">
                    <li id="desktopwhatsappicon"><a href={`https://web.whatsapp.com/send?text=${window.location.href}`} target="_blank" title="whatsapp"><i className="fa fa-whatsapp whatsapp"></i></a></li>
                    <li id="mobilewhatsappicon"><a href={`https://web.whatsapp.com/send?text=${window.location.href}`} target="_blank" title="whatsapp"><i className="fa fa-whatsapp whatsapp"></i></a></li>
                    <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" title="facebook"><i className="fa fa-facebook-official facebook"></i></a></li>
                    <li><a href={`https://twitter.com/intent/tweet?url=${window.location.href}`} target="_blank" title="twitter"><i className="fa fa-twitter twitter"></i></a></li>
                    <li><a href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`} target="_blank" title="linkedin"><i className="fa fa-linkedin linkedin"></i></a></li>
                    {/* <li><a href="#" target="_blank" title="google"><i className="fa fa-google-plus google"></i></a></li> */}
                    {/* <li><a href="#" target="_blank" title="instagram"><i className="fa fa-instagram instagram"></i></a></li> */}

                </ul>
            </div>
            <Helmet>
                {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
                <title>International Mathematics Olympiad (IMO) Conducted by Science Olympiad Foundation | ALLEN Digital</title>
                <meta name="description" content="International Mathematics Olympiad (IMO) Conducted by Science Olympiad Foundation | ALLEN Digital" />
                <meta property="og:title" content="International Mathematics Olympiad (IMO) Conducted by Science Olympiad Foundation | ALLEN Digital" />
                <meta property="og:site_name" content="ALLEN Digital" />
                <meta property="og:url" content="https://digital.allen.ac.in/results/olympiad-2023/imo" />
                <meta property="og:description" content="International Mathematics Olympiad (IMO) Conducted by Science Olympiad Foundation | ALLEN Digital" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://digital.allen.ac.in/static/media/logo-new-r.4a9d1fde3fd2a6868a621b27352bda3a.svg" />
                {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
            </Helmet>
            <div className='container'>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "4%" }}>
                    <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/resultheading12.png" className="resultheadingimg" style={{ margin: "auto", width: "35%" }} alt="resultHeading"></img>
                </div>
                {/* <h2 className="text-center font-weight-bold mb-4 resultmaintextcentered">Extra ordinary performance by ALLEN Digital-PNCF Students</h2> */}
                <div className="text-center result-tabs">
                    <ul class="nav nav-tabs my-4 d-inline-flex" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="ieoone-tab2023" data-toggle="tab" data-target="#ieoone" type="button" role="tab" aria-controls="ieoone" aria-selected="true">Level-1</button>
                        </li>
                        {/* <li class="nav-item" role="presentation">
                            <button class="nav-link" id="ieotwo-tab2023" data-toggle="tab" data-target="#ieotwo" type="button" role="tab" aria-controls="ieotwo" aria-selected="false">Level-2</button>
                        </li> */}

                    </ul>
                </div>
                <div class="tab-content" id="myTabContent" style={{ background: "transparent", padding: "0px", boxShadow: "none" }}>
                    <div class="tab-pane fade show active" id="ieoone2023" role="tabpanel" aria-labelledby="ieoone-tab">
                        <div className='highlightstabular'>
                            <div className='highlightheading'>

                                <span style={{ color: "#f5eca3" }}>International Mathematics Olympiad (IMO) Conducted by Science Olympiad Foundation</span>

                            </div>
                            <div className='highlightcontent'>

                            
                                <div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'>15 TOP 10 RANKS</span></div>     
                                <div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'>35 TOP 50 RANKS
</span></div>     
                                <div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'>63 TOP 100 RANKS
</span></div>     
                                <div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'>43 students awarded with Medal of Distinction
</span></div>         
<div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'>164 students awarded with Gold Medal of Excellence
</span></div>      
<div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'>244 Students Qualified for Level-2</span></div>                   

                            </div>
                        </div>
                        <div className='card-main'>
                            {
                                resultData.map((data) => {
                                    return (
                                        <>
                                            <div className='result-card'>

                                                <img src={`https://cdn.digital.allen.ac.in/fe-bsite/results/olympiad/2023-24/imo/${data.Form_no}.png`} alt='temp' className='resultimages-radius'></img>
                                                <div>
                                                    <span className='percentage'>IR {data.IR}</span><br />
                                                    <span className='resstudentname'>{data.Student_Name}</span><br />
                                                    <span className='adfno'>(Class {data.Class})</span>
                                                </div>
                                            </div>

                                        </>
                                    )
                                })
                            }


                        </div>
                    </div>
                    {/* <div class="tab-pane fade" id="ieotwo2023" role="tabpanel" aria-labelledby="ieotwo-tab">
                        <div className='highlightstabular'>
                            <div className='highlightheading'>

                                <span style={{ color: "#f5eca3" }}>IEO (International English Olympiad) Level-2 Conducted by SOF </span>

                            </div>
                            <div className='highlightcontent'>

                                <div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'>5 Top 100 International Ranks</span></div>
                            </div>
                        </div>
                        <div className='card-main'>
                            {
                                resultDataLevel2.map((data) => {
                                    return (
                                        <>
                                            <div className='result-card'>

                                                <img src={`https://cdn.digital.allen.ac.in/fe-bsite/results/olympiad/ieolevel2/${data.Form_no}.png`} alt='temp' className='resultimages' style={{ borderRadius: "20px" }}></img>
                                                <div>
                                                    <span className='percentage'>IR {data.Ir}</span><br />
                                                    <span className='resstudentname'>{data.Student_Name}</span><br />
                                                    <span className='adfno'>(Class {data.Class})</span>
                                                </div>
                                            </div>

                                        </>
                                    )
                                })
                            }


                        </div>
                    </div> */}
                </div>


                {/* <img className='mainresultimagebig' src={gg}></img> */}
                {/* <div>
                    <img className='jeemainresultfooter' src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/desktop_result_banner.png" style={{width:"100%", marginTop:"50px"}}></img>
                </div> */}

   
            </div>
        </div>
    );
};

export default ImoResult2023;