import React from "react";
import { TailSpin } from "react-loader-spinner";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { validationFunction } from "../Ecom/ValidationHelper";
import MixpanelInit from "../Mixpanel/MixpanelInit";
import Loader from "../loader/Loader";

// import { environment } from "../../Services/HttpData";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);
const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "";

const EcomCheckoutPayment = props => {
	const [checkoutDetails, setcheckoutDetails] = useState([]);

	const billing = {
		student_address_details_id: "",
		student_id: "",
		address_line1: "",
		address_line2: "",
		address_line3: "",
		state_name: "",
		state_id: "",
		city_id: "",
		city_name: "",
		district: "",
		pincode: "",
		address_status: 101,
		address_type: 241,
	};

	const shipping = {
		student_address_details_id: "",
		student_id: "",
		address_line1: "",
		address_line2: "",
		address_line3: "",
		state_name: "",
		state_id: "",
		city_id: "",
		city_name: "",
		district: "",
		pincode: "",
		address_status: 101,
		address_type: 242,
	};

	const [text, setText] = useState({
		student_address_details_id: "",
		address_line1: "",
		address_line2: "",
		address_line3: "",
		city_id: "",
		pincode: "",
		country: "690352c39f603fa69683af93d18a690d",
		state: "",
		district: "",
		address_type: "",
	});
	const [state, setState] = useState();
	const [cities, setCities] = useState();
	const [cities_1, setCities_1] = useState();
	const [loader, setLoader] = useState(false);
	const [checkValue, setcheckValue] = useState(false);
	const [getBilling, setBilling] = useState(billing);
	const [getShipping, setShipping] = useState(shipping);
	//const [isloader, setloader] = useState(false)

	function scroll(id) {
		let e = document.getElementById(id);
		e.scrollIntoView({
			block: "start",
			behavior: "smooth",
			inline: "start",
		});
	}

	const handleBilling = e => {
		const { name, value } = e.target;

		if (name === "state_id") {
			let state_name = "";
			state?.map(function (val, i) {
				if (val.state_id === value) {
					state_name = val?.state_name;
				}
			});
			// setBilling({...getBilling,[name]:value})
			setBilling({ ...getBilling, [name]: value, ["state_name"]: state_name, ["city_name"]: "", ["city_id"]: "" });
			getCities("", value, "", "", "", "");
			if (checkValue) {
				setShipping({ ...getShipping, [name]: value, ["state_name"]: state_name, ["city_name"]: "", ["city_id"]: "" });
				getCities1("", value, "", "", "", "");
			}
		} else if (
			(name === "pincode" &&
				/^[0-9]*$/.test(value) &&
				!value.includes("e") &&
				!value.includes(".") &&
				!value.includes("-") &&
				!value.includes("+"))
		) {
			if (value.length == 6) {
				setBilling({ ...getBilling, [name]: value });
				if (checkValue) {
					setShipping({ ...getShipping, [name]: value });
				}
				addressByPincode("billing", value);
			} else {
				setBilling({
					...getBilling,
					[name]: value,
					["state_name"]: "",
					["state_id"]: "",
					["city_id"]: "",
					["city_name"]: "",
					["district"]: "",
				});
				if (checkValue) {
					setShipping({
						...getShipping,
						[name]: value,
						["state_name"]: "",
						["state_id"]: "",
						["city_id"]: "",
						["city_name"]: "",
						["district"]: "",
					});
					setCities_1([]);
				}
				setCities([]);
			}
		} else if (name === "city_id") {
			let city_name = "";
			cities?.map(function (val, i) {
				if (val?.city_id === value) {
					city_name = val?.city_name;
				}
			});
			setBilling({ ...getBilling, [name]: value, ["city_name"]: city_name });

			if (checkValue) {
				setShipping({ ...getShipping, [name]: value, ["city_name"]: city_name });
			}
		} else if (!(name === "state_id" || name === "pincode")) {
			if (validationFunction?.validateUserFields("spaceonly", value)) {
				setBilling({ ...getBilling, [name]: value.trimLeft() });
			}
			if (checkValue) {
				setShipping({ ...getShipping, [name]: value.trimLeft() });
			}
		}

		//console.log(getBilling)
	};

	const handleShipping = e => {
		const { name, value } = e.target;
		if (name === "state_id") {
			let state_name = "";
			state?.map(function (val, i) {
				if (val.state_id === value) {
					state_name = val?.state_name;
				}
			});
			//setShipping({...getShipping,[name]:value})
			setShipping({ ...getShipping, [name]: value, ["state_name"]: state_name, ["city_name"]: "", ["city_id"]: "" });
			getCities1("", value, "", "", "", "");
		} else if (
			(name === "pincode" &&
				/^[0-9]*$/.test(value) &&
				!value.includes("e") &&
				!value.includes(".") &&
				!value.includes("-") &&
				!value.includes("+"))
		) {
			setShipping({ ...getShipping, [name]: value });
			if (value.length == 6) {
				addressByPincode("shipping", value);
			} else {
				setShipping({
					...getShipping,
					[name]: value,
					["state_name"]: "",
					["state_id"]: "",
					["city_id"]: "",
					["city_name"]: "",
					["district"]: "",
				});
			}
		} else if (name === "city_id") {
			let city_name = "";
			cities?.map(function (val, i) {
				if (val.city_id === value) {
					city_name = val?.city_name;
				}
			});
			setShipping({ ...getShipping, [name]: value, ["city_name"]: city_name });
		} else if (!(name === "state_id" || name === "pincode")) {
			if (validationFunction?.validateUserFields("spaceonly", value)) {
				setShipping({ ...getShipping, [name]: value.trimLeft() });
			}
		}
		// console.log(getShipping)
	};

	const sameAsFunc = event => {
		const { name, value } = event.target;
		if (checkValue) {
			setcheckValue(false);
			setShipping({
				student_address_details_id: getShipping?.student_address_details_id
					? getShipping?.student_address_details_id
					: "",
				student_id: getShipping?.student_id ? getShipping?.student_id : "",
				address_line1: "",
				address_line2: "",
				address_line3: "",
				state_name: "",
				state_id: "",
				city_id: "",
				city_name: "",
				district: "",
				pincode: "",
				address_status: 101,
				address_type: 242,
			});
			setCities_1([]);
		} else {
			setcheckValue(true);
			setShipping({
				student_address_details_id: getShipping?.student_address_details_id
					? getShipping?.student_address_details_id
					: "",
				student_id: getShipping?.student_id ? getShipping?.student_id : "",
				address_line1: getBilling?.address_line1 ? getBilling?.address_line1 : "",
				address_line2: getBilling?.address_line2 ? getBilling?.address_line2 : "",
				address_line3: getBilling?.address_line3 ? getBilling?.address_line3 : "",
				state_name: getBilling?.state_name ? getBilling?.state_name : "",
				state_id: getBilling?.state_id ? getBilling?.state_id : "",
				city_id: getBilling?.city_id ? getBilling?.city_id : "",
				city_name: getBilling?.city_name ? getBilling?.city_name : "",
				district: getBilling?.district ? getBilling?.district : "",
				pincode: getBilling?.pincode ? getBilling?.pincode : "",
				address_status: 101,
				address_type: 242,
			});
			// console.log("length",cities.length)
			setCities_1([...cities]);
		}
	};

	const addEditAddress = () => {
		//console.log(getBilling, getShipping);
		if (getBilling?.address_line1?.trim() === "" || (getBilling?.address_line1 === null)) {
			toast.error("Enter Billing Address Line 1");
		} else if (getBilling?.pincode === "" || getBilling?.pincode.length !== 6 || parseInt(getBilling?.pincode) === 0) {
			toast.error("Please Enter Valid Billing Pincode");
		} else if (getBilling?.state_id === "" || getBilling?.state_id === "--- Select ---") {
			toast.error("Select Billing State");
		} else if (getBilling?.district === "") {
			toast.error("Enter Billing District");
		} else if (getBilling?.city_id === "" || getBilling?.city_id === "--- Select ---") {
			toast.error("Select Billing City");
		} else if (getShipping?.address_line1?.trim() === "" || getShipping?.address_line1 === null) {
			toast.error("Enter Shipping Address Line 1");
		} else if (
			getShipping?.pincode === "" ||
			getShipping?.pincode.length !== 6 ||
			parseInt(getShipping?.pincode) === 0
		) {
			toast.error("Please Enter Valid Shipping Pincode");
		} else if (getShipping?.state_id === "" || getShipping?.state_id === "--- Select ---") {
			toast.error("Select Shipping State");
		} else if (getShipping?.district === "") {
			toast.error("Enter Shipping District");
		} else if (getShipping?.city_id === "" || getShipping?.city_id === "--- Select ---") {
			toast.error("Select Shipping City");
		} else {
			MixpanelInit(
				"Identify",
				"$identify",
				window.localStorage.getItem("FORM_NO") ? window.localStorage.getItem("FORM_NO") : ""
			);
			MixpanelInit("People_Set", "billing_address_updated", {
				address: getBilling?.address_line1 + " " + getBilling?.address_line2,
				pincode: getBilling.pincode,
				state: getBilling.state_name,
				district: getBilling.district,
				$city: getBilling.city_name,
			});
			MixpanelInit("Track", "billing_address_updated", {
				address: getBilling?.address_line1 + " " + getBilling?.address_line2,
				pincode: getBilling.pincode,
				state: getBilling.state_name,
				district: getBilling.district,
				$city: getBilling.city_name,
			});

			MixpanelInit(
				"Identify",
				"$identify",
				window.localStorage.getItem("FORM_NO") ? window.localStorage.getItem("FORM_NO") : ""
			);
			MixpanelInit("People_Set", "shipping_address_updated", {
				shipping_address: getShipping?.address_line1 + " " + getShipping?.address_line2,
				shipping_pincode: getShipping.pincode,
				shipping_state: getShipping.state_name,
				shipping_district: getShipping.district,
				shipping_city: getShipping.city_name,
			});
			MixpanelInit("Track", "shipping_address_updated", {
				shipping_address: getShipping?.address_line1 + " " + getShipping?.address_line2,
				shipping_pincode: getShipping.pincode,
				shipping_state: getShipping.state_name,
				shipping_district: getShipping.district,
				shipping_city: getShipping.city_name,
			});

			const requestOptionsUser = {
				method: "POST",
				headers: {
					Authorization: "Basic " + auth_token,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ billing: getBilling, shipping: getShipping }),
			};
			fetch(environment.ecomApiUrl + `address/add`, requestOptionsUser).then(resp => {
				resp.json().then(result => {
					if (result?.status == "success") {
						toast.success(result?.message);

						getAddressData();
						// scroll("profiledetailsdiv")
						// var x = document.getElementById("BillingForm").style.display = "none";
						//var y = document.getElementById("shippingForm").style.display = "none";
						//   y.style.display = "none";
						// x.style.display = "none";
						// x.reset();
						try {
							props.onChange();
						} catch (error) {
							console.log(error);
						}
						cancelBillingAddressFrom();

						//console.log('sdfsd')
					} else {
						toast.error(result?.message);
					}
				});
			});
		}
	};

	// const handleEvent = (e) => {
	//   const {name,value} = e.target;
	//   if(name === "country"){
	//     //setCountry(value);
	//     getState(value);
	//   }
	//   if(name === "state"){
	//     //setState(value);
	//     getCities(value);
	//     setText((preValue)=>{
	//       return{
	//           ...preValue,
	//           city_id:""
	//       };
	//   })
	//     //console.log("state");
	//   }
	//   if((name === 'pincode' &&
	//           /^[0-9]*$/.test(value) &&
	//           !value.includes('e') &&
	//           !value.includes('.') &&
	//           !value.includes('-') &&
	//           !value.includes('+')) ||
	//         value === ''){
	//       if(value.length == 6){
	//         // console.log(value)
	//         addressByPincode(value);
	//       }
	//       setText((preValue)=>{
	//         return{
	//             ...preValue,
	//             [name] : value.trimLeft(),
	//         };
	//     })
	//   }

	//   if(text?.student_address_details_id === "" && name !== "pincode"){
	//     if (validationFunction?.validateUserFields('space', value)){
	//     setText((preValue)=>{
	//         return{
	//             ...preValue,
	//             [name] : value,
	//         };
	//     })
	//   }
	//   }else if(name !== "pincode"){
	//     if (validationFunction?.validateUserFields('space', value)){
	//     setText((preValue)=>{
	//         return{
	//             ...preValue,
	//             ["student_address_details_id"] : text?.student_address_details_id,
	//             ["address_type"] : text?.address_type,
	//             [name] : value.trimLeft(),
	//         };
	//     })
	//     }
	//   }

	//   //console.log(text);
	// }

	const addressByPincode = (type, pincode) => {
		setLoader(true);
		// console.log(type,pincode)
		const requestOptionsUser = {
			method: "POST",
			headers: {
				Authorization: "Basic " + auth_token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ pincode: pincode }),
		};

		fetch(environment.ecomApiUrl + `address/address-pincode-list`, requestOptionsUser).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				setLoader(false);
				if (result?.data[0]?.Status == "Success") {
					// console.log("result",result?.data[0].PostOffice[0])

					if (type === "billing") {
						state?.map(function (val, i) {
							if (result?.data[0]?.PostOffice[0]?.State?.toLowerCase() == val?.state_name?.toLowerCase()) {
								// console.log('data no match',(result?.data[0]?.PostOffice[0]?.State).toLowerCase(),(val?.state_name).toLowerCase())
								getCities(
									result?.data[0]?.PostOffice[0]?.State,
									val.state_id,
									result?.data[0].PostOffice[0].Block,
									result?.data[0]?.PostOffice[0]?.District,
									pincode
								);

								if (checkValue) {
									getCities1(
										result?.data[0]?.PostOffice[0]?.State,
										val.state_id,
										result?.data[0]?.PostOffice[0]?.Block,
										result?.data[0]?.PostOffice[0]?.District,
										pincode
									);
								}
							} else {
							}
						});
					} else if (type === "shipping") {
						//console.log("aaa",result?.data[0])

						state?.map(function (val, i) {
							if (result?.data[0]?.PostOffice[0]?.State?.toLowerCase() == val?.state_name?.toLowerCase()) {
								getCities1(
									result?.data[0]?.PostOffice[0]?.State,
									val.state_id,
									result?.data[0]?.PostOffice[0]?.Block,
									result?.data[0]?.PostOffice[0]?.District,
									pincode
								);
							}
						});
					}
				} else {
					toast.error(result?.data[0]?.Message);
				}
			});
		});
	};

	// const addressByPincode = (pincode) => {
	//   const requestOptionsUser = {
	//     method: 'POST',
	//     headers : {
	//       'Authorization': 'Basic '+auth_token
	//     },
	//     body : JSON.stringify({pincode:pincode})
	//   };

	//   fetch('http://localhost:4000/' + `api/ecom/address`,requestOptionsUser).then((resp)=>{ // fetching api url for courses listing
	//       resp.json().then((result)=>{
	//         if(result.data[0].Status == 'Success'){
	//              console.log(result.data[0].PostOffice[0])
	//             // setText((prev)=>{
	//             //   return{
	//             //     ...prev,
	//             //   ['district']:result.data[0].PostOffice[0].District,
	//             //   }
	//             // })

	//             getCityId(result.data[0].PostOffice[0].Block, result.data[0].PostOffice[0].State);
	//         }else{
	//           toast.error(result.data[0].Message)
	//         }
	//       })
	//   })
	// }

	// const getCityId = (city, state) => {
	//   const requestOptionsUser = {
	//     method: 'POST',
	//     headers : {
	//       'Authorization': 'Basic '+auth_token,
	//       "Content-Type": "application/json",
	//     },
	//     body: JSON.stringify({city_name: city, state_name:state})

	//   };

	//   fetch(environment.ecomApiUrl + `address/details`,requestOptionsUser).then((resp)=>{ // fetching api url for courses listing
	//     resp.json().then((result)=>{
	//       if(result?.status =="success"){
	//         getCities(result?.data[0]?.state_id);
	//         setText((prev)=>{
	//           return{
	//             ...prev,
	//           ['state']:result?.data[0]?.state_id,
	//           ['city_id']:result?.data[0]?.city_id
	//           }
	//         })
	//       }
	//       else{
	//         toast.error(result?.message);
	//       }
	//     })
	// })
	// }

	// const selectAddress = (object, type) =>{
	//   props.onChange(object, type);
	// }

	/* Update Address Method using props */
	const editBillingAddress = (obj, type) => {
		//  console.log("hi",obj,type)
		getCities(obj?.state_id ? obj?.state_id : 1);
		setText({
			student_address_details_id: obj?.student_address_details_id ? obj?.student_address_details_id : "",
			address_line1: obj?.address_line1 ? obj?.address_line1 : "",
			address_line2: obj?.address_line2 ? obj?.address_line2 : "",
			address_line3: obj?.address_line3 ? obj?.address_line3 : "",
			city_id: obj?.city_id ? obj?.city_id : "",
			pincode: obj?.pincode ? obj?.pincode : "",
			country: obj?.country_id ? obj?.country_id : "",
			district: obj?.district ? obj?.district : "",
			state: obj?.state_id ? obj?.state_id : "",
			address_type: type,
		});
		// let y = document.getElementById("shippingForm");
		// if(y.style.display === "block"){
		//   y.style.display = "none"
		// }
		let x = document.getElementById("BillingForm");
		if (x.style.display === "block") {
			x.style.display = "none";
		} else {
			x.style.display = "block";
			// let x = document.getElementById("addbillinghide");
			//       x.style.display = "none"
			// props.onChange();
			addbillinghide();
		}
		// getAddressData()
	};

	const editShippingAddress = (obj, type) => {
		getCities(obj?.state_id ? obj?.state_id : 1);
		setText({
			student_address_details_id: obj?.student_address_details_id ? obj?.student_address_details_id : "",
			address_line1: obj?.address_line1 ? obj?.address_line1 : "",
			address_line2: obj?.address_line2 ? obj?.address_line2 : "",
			address_line3: obj?.address_line3 ? obj?.address_line3 : "",
			city_id: obj?.city_id ? obj?.city_id : "",
			pincode: obj?.pincode ? obj?.pincode : "",
			country: obj?.country_id ? obj?.country_id : "",
			district: obj?.district ? obj?.district : "",
			state: obj?.state_id ? obj?.state_id : "",
			address_type: type,
		});
		let y = document.getElementById("BillingForm");
		if (y.style.display === "block") {
			y.style.display = "none";
		}
		let x = document.getElementById("shippingForm");
		if (x.style.display === "block") {
			x.style.display = "none";
		} else {
			x.style.display = "block";
		}
	};

	function cancelBillingAddressFrom() {
		var x = document.getElementById("BillingForm");
		x.style.display = "none";
		x.reset();
		setBilling(billing);
		setShipping(shipping);
		getAddressData();
		//scroll("profiledetailsdiv")

		//   setText({
		//     address_line1:"",
		//     address_line2:"",
		//     address_line3:"",
		//     city_id:"",
		//     pincode:"",
		//     country:"",
		//     district:"",
		//     state:"",
		//     address_type:""
		// });
	}

	// function cancelShippingAddressFrom() {
	//   scroll("profiledetailsdiv")
	//   var x = document.getElementById("shippingForm");
	//   x.style.display = "none";
	//   x.reset();
	//   setText({
	//     address_line1:"",
	//     address_line2:"",
	//     address_line3:"",
	//     city_id:"",
	//     pincode:"",
	//     country:"",
	//     district:"",
	//     state:"",
	//     address_type:""
	// });
	// }

	//   const submitForm = (e) => {
	//     // console.log("add address object",text);
	//     e.preventDefault();
	//     if(text?.address_line1.trim().length < 1){
	//       toast.error("Please Enter Address Line One");
	//       return false;
	//     }else if(text?.pincode.trim().length < 6){
	//       toast.error("Please Enter Correct Pincode");
	//       return false;
	//     }else if(text?.state.trim().length < 1){
	//       toast.error("Please Select State");
	//       return false;
	//     }else if(text?.district.trim().length < 1){
	//       toast.error("Please Enter District");
	//       return false;
	//     }else if(text?.city_id.trim().length < 1){
	//       toast.error("Please Select City");
	//       return false;
	//     }

	//     const requestOptions = {
	//         method: 'POST',
	//         headers : {
	//           'Content-Type' : "application/json",
	//           'Authorization': 'Basic '+auth_token
	//         },
	//         body: JSON.stringify(text)
	//       };
	//       setLoader(true)
	//       // console.log(text)
	//       fetch(environment.ecomApiUrl + 'address/add',requestOptions).then((resp)=>{ // fetching api url for courses listing
	//           resp.json().then((result)=>{
	//             if(result.status == "success"){
	//                 props.onChange();
	//                 const requestOptions = {
	//                     method: 'GET',
	//                     headers : {
	//                       'Authorization': 'Basic '+auth_token
	//                     }
	//                   };
	//                   fetch(environment.ecomApiUrl + 'address/list',requestOptions).then((resp)=>{ // fetching api url for courses listing
	//                       resp.json().then((result)=>{
	//                         setcheckoutDetails(result.data);
	//                         ////console.log(checkoutDetails);
	//                       })
	//                   })
	//               setLoader(false)
	//               toast.success("Shipping Address Updated Successfully");
	//               scroll("profiledetailsdiv")
	//               var x = document.getElementById("BillingForm").style.display = "none";
	//               var y = document.getElementById("shippingForm").style.display = "none";
	//               // y.style.display = "none";
	//               // x.style.display = "none";
	//               // x.reset();
	//               setText({
	//                 address_line1:"",
	//                 address_line2:"",
	//                 address_line3:"",
	//                 city_id:"",
	//                 pincode:"",
	//                 country:"690352c39f603fa69683af93d18a690d",
	//                 state:"",
	//                 district:"",
	//                 address_type:""
	//             });
	//             }else{
	//               setLoader(false)
	//               toast.error(result.message);
	//             }
	//           })
	//       })
	// }

	// const submitUpdatedForm = (e) => {
	//   //console.log("updated",text);
	//   //setText([...text, {student_address_details_id:student_address_details_id}]);
	//   e.preventDefault();
	//   if(text.address_line1.trim().length < 1){
	//     toast.error("Please Enter Address Line One");
	//     return false;
	//   }else if((text.pincode.trim().length < 6)){
	//     toast.error("Please Enter Correct Pincode");
	//     return false;
	//   }else if(text.state.trim().length < 1){
	//     toast.error("Please Select State");
	//     return false;
	//   }else if(text.district.trim().length < 1){
	//     toast.error("Please Enter District");
	//     return false;
	//   }else if(text.city_id.trim().length < 1){
	//     toast.error("Please Select City");
	//     return false;
	//   }
	//   // console.log(text)
	//   const requestOptionsupdate = {
	//       method: 'PATCH',
	//       headers : {
	//         'Content-Type' : "application/json",
	//         'Authorization': 'Basic '+auth_token
	//       },
	//       body: JSON.stringify(text)
	//     };
	//     setLoader(true)
	//     fetch(environment.ecomApiUrl + 'address/update',requestOptionsupdate).then((resp)=>{ // fetching api url for courses listing
	//         resp.json().then((result)=>{
	//           if(result.status == "success"){
	//             props.onChange();
	//             const requestOptions = {
	//                 method: 'GET',
	//                 headers : {
	//                   'Authorization': 'Basic '+auth_token
	//                 }
	//               };
	//               fetch(environment.ecomApiUrl + 'address/list',requestOptions).then((resp)=>{ // fetching api url for courses listing
	//                   resp.json().then((result)=>{
	//                     setcheckoutDetails(result.data);
	//                     ////console.log(checkoutDetails);
	//                   })
	//               })
	//               setLoader(false)
	//             toast.success("Address Updated Successfully");
	//             //console.log("Form Submitted Successfully");
	//             // props.onChange();
	//             scroll("profiledetailsdiv")
	//             var x = document.getElementById("BillingForm").style.display = "none";
	//             var y = document.getElementById("shippingForm").style.display = "none";
	//             // y.style.display = "none";
	//             // x.style.display = "none";
	//             // x.reset();
	//             setText({
	//               address_line1:"",
	//               address_line2:"",
	//               address_line3:"",
	//               city_id:"",
	//               pincode:"",
	//               country:"690352c39f603fa69683af93d18a690d",
	//               state:"",
	//               district:"",
	//               address_type:""
	//           });
	//           }else{
	//             setLoader(false)
	//             toast.error(result.message);
	//           }
	//         })
	//     })
	// }

	function getState() {
		const requestOptions2 = {
			method: "POST",
			headers: {
				Authorization: "Basic " + localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ country_id: "690352c39f603fa69683af93d18a690d" }),
		};
		fetch(environment.ecomApiUrl + "address/states", requestOptions2)
			.then(resp => {
				// fetching api url for courses listing
				resp.json().then(result => {
					setState(result.data);
					//console.log(data);
				});
			})
			.catch(function (error) {
				console.log("Request failed", error);
			});
	}

	function getCities(state_name, data, city, district, pincode) {
		// console.log('dataa',data,city,district,pincode)
		const requestOptions2 = {
			method: "POST",
			headers: {
				Authorization: "Basic " + localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ state_id: data }),
		};
		fetch(environment.ecomApiUrl + "address/cities", requestOptions2).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				if (result?.status === "failed") {
					setCities([]);
				} else {
					setCities(result?.data);
					if (city !== "") {
						let city_name = "",
							city_id = "";
						result?.data?.map(function (val, i) {
							if (
								city.toLowerCase() == (val?.city_name).toLowerCase() ||
								district.toLowerCase() == (val?.city_name).toLowerCase()
							) {
								city_name = val.city_name;
								city_id = val.city_id;
							}
						});

						setBilling({
							...getBilling,
							["city_name"]: city_name,
							["city_id"]: city_id,
							["state_id"]: data,
							["state_name"]: state_name,
							["district"]: district,
							["pincode"]: pincode,
						});

						if (checkValue) {
							setShipping({
								...getShipping,
								["city_name"]: city_name,
								["city_id"]: city_id,
								["state_id"]: data,
								["state_name"]: state_name,
								["district"]: district,
								["pincode"]: pincode,
							});
						}
					}
				}
			});
		});
	}

	function getCities1(state_name, data, city, district, pincode) {
		const requestOptions2 = {
			method: "POST",
			headers: {
				Authorization: "Basic " + localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ state_id: data }),
		};
		fetch(environment.ecomApiUrl + "address/cities", requestOptions2).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				if (result?.status === "failed") {
					setCities_1([]);
				} else {
					setCities_1(result?.data);
					if (city !== "") {
						let city_name = "",
							city_id = "";
						result?.data?.map(function (val, i) {
							if (
								city.toLowerCase() == (val?.city_name).toLowerCase() ||
								district.toLowerCase() == (val?.city_name).toLowerCase()
							) {
								city_name = val.city_name;
								city_id = val.city_id;
							}
						});

						setShipping({
							...getShipping,
							["city_name"]: city_name,
							["city_id"]: city_id,
							["state_id"]: data,
							["state_name"]: state_name,
							["district"]: district,
							["pincode"]: pincode,
						});
					}
				}
			});
		});
	}
	function addbillinghide() {
		let x = document.getElementById("addbillinghide");
		x.style.display = "none";
	}
	function addbillingShow() {
		let x = document.getElementById("addbillinghide");
		x.style.display = "block";
	}
	function addshippinghide() {
		let x = document.getElementById("addshippinghide");
		x.style.display = "none";
	}

	const getAddressData = () => {
		//console.log('check')
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Basic " + auth_token,
			},
		};
		fetch(environment.ecomApiUrl + "address/list", requestOptions).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				setcheckoutDetails(result.data);

				//console.log("result.data",result.data)

				if (result?.message === "No Student Address Found.") {
					addbillingShow();
				} else {
					addbillinghide();
				}

				// addbillinghide()
				result?.data?.map((val, i) => {
					if (val?.address_type == "241") {
						setBilling(val);
						getCities("", val?.state_id, "", "", "");
					} else if (val?.address_type == "242") {
						getCities1("", val?.state_id, "", "");
						setShipping(val);
					}
				});
			});
		});
	};

	const editAddressInfo = obj => {
		var x = document.getElementById("BillingForm");
		let y = document.getElementById("addbillinghide");
		if (x.style.display === "block") {
			x.style.display = "none";
			//y.style.display = "block"
			getAddressData();
		} else {
			x.style.display = "block";
			y.style.display = "none";
		}
	};

	useEffect(() => {
		getState();
	}, []);
	useEffect(() => {
		getAddressData();
	}, []);

	return (
		<>
			<div className='checkout-address-detail mb-2'>
				<div className='address-text-box'>
					<div className='address-text-box' style={{ display: "flex", justifyContent: "space-between" }}>
						<b>Address Info</b>{" "}
						<b onClick={() => editAddressInfo()} style={{ color: "green", cursor: "pointer" }}>
							Edit
						</b>
					</div>
				</div>
			</div>

			{checkoutDetails?.map((itm, i) => {
				return (
					<>
						{itm?.address_type == 241 ? (
							<>

								{itm.student_address_details_id ? (
									<>
										{addbillinghide()}
										<div className='d-flex justify-content-between'>
											<h6 style={{ color: "#116234" }}>
												<strong>Billing Address</strong>
											</h6>
										</div>
										<div className='d-flex'>
											&nbsp; &nbsp;{" "}
											<h6>

												{itm.address_line1 && itm.address_line1 + ", "} {itm.address_line2 && itm.address_line2 + ", "} {itm.address_line3 && itm.address_line3 + ", "} {itm.district && itm.district + ", "}
												{itm.city_name && itm.city_name + ", "}{itm?.state_name && itm?.state_name + " "}{itm.pincode && itm.pincode && itm.pincode != 0 && "" + itm.pincode}
											</h6>
											&nbsp; &nbsp;
										</div>
										<hr></hr>
									</>
								) : (
									<></>
								)}
							</>
						) : (
							<>
								{itm?.student_address_details_id ? (
									<>
										{addshippinghide()}
										<div className=' d-flex justify-content-between'>
											<h6 style={{ color: "#116234" }}>
												<strong>Shipping Address</strong>
											</h6>
										</div>
										<div className='d-flex'>
											&nbsp; &nbsp;{" "}
											<h6>
												{itm.address_line1 && itm.address_line1 + ", "} {itm.address_line2 && itm.address_line2 + ", "} {itm.address_line3 && itm.address_line3 + ", "} {itm.district && itm.district + ", "}
												{itm.city_name && itm.city_name + ", "}{itm?.state_name && itm?.state_name + " "}{itm.pincode && itm.pincode && itm.pincode != 0 && "" + itm.pincode}
											</h6>
											&nbsp; &nbsp;
										</div>
									</>
								) : (
									<></>
								)}
							</>
						)}
					</>
				);
			})}
			<div id='addbillinghide'>
				<button className='new-add-btn mt-4 mb-4' onClick={() => editBillingAddress("", "241")} id='addNewAddress'>
					<b>Add Address</b>
				</button>
			</div>

			<form className='address-form' id='BillingForm' style={{ display: "none" }}>
				<div className='new-add-btn mt-3 mb-3 border-none p-0' style={{ marginTop: "2%" }}>
					{getBilling?.student_address_details_id ? <b>Billing Address</b> : <b>Billing Address</b>}
				</div>
				<div className='form-group row'>
					<div className='col-md-6 col-sm-10'>
						<label htmlFor='address1' className='col-form-label'>
							Address line 1 <span className='star-red'>*</span>
						</label>
						<div className='col-12 px-1'>
							<input
								required
								type='text'
								className='form-control'
								id='address1'
								maxLength={80}
								name='address_line1'
								value={getBilling?.address_line1 ? getBilling.address_line1 : ""}
								placeholder=''
								onChange={handleBilling}></input>
						</div>
					</div>

					<div className='col-md-6 col-sm-10'>
						<label htmlFor='address2' className='col-form-label'>
							Address line 2{" "}
						</label>
						<div className='col-12 px-1'>
							<input
								type='text'
								className='form-control'
								id='address2'
								maxLength={80}
								name='address_line2'
								value={getBilling?.address_line2 ? getBilling?.address_line2 : ""}
								placeholder=''
								onChange={handleBilling}></input>
						</div>
					</div>
				</div>

				<div className='form-group row'>
					<div className='col-md-6 col-sm-10'>
						<label htmlFor='address3' className='col-form-label'>
							Landmark{" "}
						</label>
						<div className='col-12 px-1'>
							<input
								type='text'
								className='form-control'
								id='address3'
								maxLength={80}
								name='address_line3'
								value={getBilling?.address_line3 ? getBilling?.address_line3 : ""}
								placeholder=''
								onChange={handleBilling}></input>
						</div>
					</div>
					<div className='col-md-6 col-sm-10'>
						<label htmlFor='pincode' className='col-form-label'>
							Pincode <span className='star-red'>*</span>
						</label>
						<div className='col-12 px-1'>
							<input
								required
								type='text'
								className='form-control'
								id='pincode'
								name='pincode'
								maxLength={6}
								value={getBilling?.pincode ? getBilling?.pincode == 0 ? "" : getBilling?.pincode : ""}
								placeholder=''
								onChange={handleBilling}
							/>
						</div>
					</div>
				</div>

				<div className='form-group row'>
					<div className='col-md-6 col-sm-10'>
						<label htmlFor='state' className='col-form-label'>
							State <span className='star-red'>*</span>
						</label>
						<div className='col-12 px-1'>
							<select
								required
								id='state'
								name='state_id'
								className='form-control'
								onChange={handleBilling}
								value={getBilling?.state_id}>
								<option>--- Select ---</option>
								{(state ? state : [])?.map((st, i) => {
									//console.log("see",st.state_id+'='+checkoutDetails.state_id)
									return (
										<>
											<option value={st?.state_id}> {st?.state_name} </option>
										</>
									);
								})}
							</select>
						</div>
					</div>

					<div className='col-md-6 col-sm-10'>
						<label htmlFor='pincode' className='col-form-label'>
							District <span className='star-red'>*</span>
						</label>
						<div className='col-12 px-1'>
							<input
								required
								type='text'
								className='form-control'
								id='district'
								maxLength={30}
								name='district'
								value={getBilling?.district ? getBilling?.district : ""}
								placeholder=''
								onChange={handleBilling}></input>
						</div>
					</div>
				</div>

				<div className='form-group row'>
					<div className='col-md-6 col-sm-10'>
						<label htmlFor='state' className='col-form-label'>
							City <span className='star-red'>*</span>
						</label>
						<div className='col-12 px-1'>
							<select
								required
								id='city'
								name='city_id'
								className='form-control'
								onChange={handleBilling}
								value={getBilling?.city_id}>
								<option>--- Select ---</option>
								{(cities ? cities : [])?.map((ct, i) => {
									return (
										<>
											<option value={ct.city_id}> {ct?.city_name} </option>
										</>
									);
								})}
							</select>
						</div>
					</div>
				</div>

				<>
					<div className='d-flex align-items-center flex-wrap flex-md-nowrap  mt-4 mb-3 gap-2'>
						<div className='new-add-btn border-none mr-3 p-0 mt-1'>
							{getBilling?.student_address_details_id ? <b>Shipping Address</b> : <b>Shipping Address</b>}
						</div>

						<div className='form-check'>
							<input
								className='form-check-input'
								name='check_same_as'
								onChange={sameAsFunc}
								type='checkbox'
								value=''
								id='defaultCheck1'
								checked={checkValue}></input>
							<label className='form-check-label mt-1' htmlFor='defaultCheck1'>
								&nbsp; Same As Billing Address
							</label>
						</div>
					</div>
				</>

				<div className='form-group row'>
					<div className='col-md-6 col-sm-10'>
						<label htmlFor='address1' className='col-form-label'>
							Address line 1 <span className='star-red'>*</span>
						</label>
						<div className='col-12 px-1'>
							<input
								required
								type='text'
								className='form-control'
								id='address1'
								maxLength={80}
								disabled={checkValue}
								name='address_line1'
								value={getShipping?.address_line1 ? getShipping?.address_line1 : ""}
								placeholder=''
								onChange={handleShipping}></input>
						</div>
					</div>

					<div className='col-md-6 col-sm-10'>
						<label htmlFor='address2' className='col-form-label'>
							Address line 2{" "}
						</label>
						<div className='col-12 px-1'>
							<input
								type='text'
								className='form-control'
								id='address2'
								maxLength={80}
								disabled={checkValue}
								name='address_line2'
								value={getShipping?.address_line2 ? getShipping?.address_line2 : ""}
								placeholder=''
								onChange={handleShipping}></input>
						</div>
					</div>
				</div>

				<div className='form-group row'>
					<div className='col-md-6 col-sm-10'>
						<label htmlFor='address3' className='col-form-label'>
							Landmark{" "}
						</label>
						<div className='col-12 px-1'>
							<input
								type='text'
								className='form-control'
								id='address3'
								maxLength={80}
								disabled={checkValue}
								name='address_line3'
								value={getShipping?.address_line3 ? getShipping?.address_line3 : ""}
								placeholder=''
								onChange={handleShipping}></input>
						</div>
					</div>
					<div className='col-md-6 col-sm-10'>
						<label htmlFor='pincode' className='col-form-label'>
							Pincode <span className='star-red'>*</span>
						</label>
						<div className='col-12 px-1'>
							<input
								required
								type='text'
								className='form-control'
								id='pincode'
								name='pincode'
								maxLength={6}
								disabled={checkValue}
								value={getShipping?.pincode}
								placeholder=''
								onChange={handleShipping}
							/>
						</div>
					</div>
				</div>

				<div className='form-group row'>
					<div className='col-md-6 col-sm-10'>
						<label htmlFor='state_id' className='col-form-label'>
							State <span className='star-red'>*</span>
						</label>
						<div className='col-12 px-1'>
							<select
								required
								id='state'
								name='state_id'
								className='form-control'
								disabled={checkValue}
								onChange={handleShipping}
								value={getShipping?.state_id}>
								<option>--- Select ---</option>
								{(state ? state : [])?.map((st, i) => {
									return (
										<>
											<option value={st?.state_id}> {st?.state_name} </option>
										</>
									);
								})}
							</select>
						</div>
					</div>

					<div className='col-md-6 col-sm-10'>
						<label htmlFor='pincode' className='col-form-label'>
							District <span className='star-red'>*</span>
						</label>
						<div className='col-12 px-1'>
							<input
								required
								type='text'
								className='form-control'
								id='district'
								disabled={checkValue}
								maxLength={30}
								name='district'
								value={getShipping?.district ? getShipping?.district : ""}
								placeholder=''
								onChange={handleShipping}></input>
						</div>
					</div>
				</div>

				<div className='form-group row'>
					<div className='col-md-6 col-sm-10'>
						<label htmlFor='state' className='col-form-label'>
							City <span className='star-red'>*</span>
						</label>
						<div className='col-12 px-1'>
							<select
								required
								id='city'
								name='city_id'
								className='form-control'
								disabled={checkValue}
								onChange={handleShipping}
								value={getShipping?.city_id}>
								<option>--- Select ---</option>
								{(cities_1 ? cities_1 : [])?.map((ct, i) => {
									return (
										<>
											<option value={ct.city_id}> {ct?.city_name} </option>
										</>
									);
								})}
							</select>
						</div>
					</div>
				</div>

				{getBilling?.student_address_details_id ? (
					<button type='button' className='btn btn-primary btn-save-add mt-3 mb-2' onClick={addEditAddress}>
						{loader ? <TailSpin color='white' height={25} width={25} /> : "Update"}
					</button>
				) : (
					<button type='button' className='btn btn-primary btn-save-add mt-3 mb-2' onClick={addEditAddress}>
						{loader ? <TailSpin color='white' height={25} width={25} /> : "Save"}
					</button>
				)}

				<span
					className='btn btn-danger btn-save-add mt-3 mb-2'
					onClick={cancelBillingAddressFrom}
					style={{ marginLeft: "1%", borderColor: "#bd2130", backgroundColor: "#bd2130", color: "#fff" }}>
					Cancel
				</span>
			</form>

			<div id='addshippinghide'>
				{/* <button className="new-add-btn mt-4 mb-4" onClick={()=>editShippingAddress("", "242")} id="addNewAddress"><b>Add Shipping Address</b></button> */}
			</div>

			{/* <form className="address-form" id="shippingForm" style={{display:"none"}}>
                                                <div className="new-add-btn mt-3 mb-3 border-none p-0" style={{marginTop:"2%"}}>{text.student_address_details_id? <b>Update Shipping Address</b> : <b>Add Shipping Address</b> }</div>
                                                 
                                                    
                                                    <div className="form-group row">
                                                    <div className="col-md-6 col-sm-10"> 
                                                      <label htmlFor="address1" className="col-form-label">Address line 1 *</label>
                                                      <div className="col-12 px-1">
                                                        <input type="text" className="form-control" id="address1" maxLength={80} name="address_line1" value={text.address_line1?text.address_line1:""} placeholder="" onChange={handleEvent}></input>
                                                      </div>
                                                      </div>
                                                    
                                                      <div className="col-md-6 col-sm-10"> 
                                                      <label htmlFor="address2" className="col-form-label">Address line 2 </label>
                                                      <div className="col-12 px-1">
                                                        <input type="text" className="form-control" id="address2" maxLength={80} name="address_line2" value={text.address_line2?text.address_line2:""} placeholder="" onChange={handleEvent}></input>
                                                      </div>
                                                      </div>
                                                      </div>

                                                      <div className="form-group row">
                                                      <div className="col-md-6 col-sm-10"> 
                                                      <label htmlFor="address3" className="col-form-label">Landmark </label>
                                                      <div className="col-12 px-1">
                                                        <input type="text" className="form-control" id="address3" maxLength={80} name="address_line3" value={text.address_line3?text.address_line3:""} placeholder="" onChange={handleEvent}></input>
                                                      </div>
                                                      </div>
                                                      <div className="col-md-6 col-sm-10">
                                                      <label htmlFor="pincode" className="col-form-label">Pincode *</label>
                                                      <div className="col-12 px-1">
                                                        <input type="text" className="form-control" id="pincode" name="pincode" maxLength={6} value={text.pincode?text.pincode:""} placeholder="" onChange={handleEvent} />
                                                      </div>
                                                      </div>
                                                     
                                                    </div>

                                                    <div className="form-group row">
                                                    <div className="col-md-6 col-sm-10"> 
                                                      <label htmlFor="state" className="col-form-label">State *</label>
                                                      <div className="col-12 px-1">
                                                        <select id="state" name="state" className="form-control" onChange={handleEvent} >  
                                                        <option value=""> --- Select ---</option>
                                                            {
                                                                (state?state:[]).map((st,i)=>{
                                                                 
                                                                    return(
                                                                        <>
                                                                            <option value={st.state_id} selected={st.state_id == text.state ? true : false}> {st.state_name} </option>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                      </div>
                                                      </div>
                                                    
                                                      <div className="col-md-6 col-sm-10">
                                                      <label htmlFor="pincode" className="col-form-label">District *</label>
                                                      <div className="col-12 px-1">
                                                        <input type="text" className="form-control" id="district" maxLength={30} name="district" value={text.district?text.district:""} placeholder="" onChange={handleEvent}></input>
                                                      </div>
                                                      </div>
                                                      
                                                      </div>

                                                      <div className="form-group row">
                                                      <div className="col-md-6 col-sm-10">
                                                        <label htmlFor="state" className="col-form-label">City *</label>
                                                        <div className="col-12 px-1">
                                                          <select id="city" name="city_id" className="form-control" onChange={handleEvent} >
                                                          <option value="">--- Select ---</option>
                                                          {
                                                                  (cities?cities:[]).map((ct,i)=>{
                                                                      return(
                                                                          <>
                                                                              <option value={ct.city_id} selected={ct.city_id == text.city_id ? true : false}> {ct.city_name} </option>
                                                                          </>
                                                                      )
                                                                  })
                                                              }
                                                          </select>
                                                          </div>
                                                      </div>
                                                        
                                                    </div>

                                                   
                                                    {
                                                      text.student_address_details_id?
                                                      <button type="submit" className="btn btn-primary btn-save-add mt-3 mb-2" onClick={submitUpdatedForm}>{loader? <TailSpin color="white" height={25} width={25}/>: 'Update'}</button>:
                                                      <button type="submit" className="btn btn-primary btn-save-add mt-3 mb-2" onClick={submitForm}>{loader? <TailSpin color="white" height={25} width={25}/>: 'Save'}</button>
                                                    }
                                                    
                                                    <span  className="btn btn-danger btn-save-add mt-3 mb-2" onClick={cancelShippingAddressFrom} style={{marginLeft:"1%",borderColor:"#bd2130",backgroundColor:"#bd2130",color:"#fff"}}>Cancel</span>

          </form> */}
			{/* {loader ? <Loader /> : null} */}
		</>
	);
};

export default EcomCheckoutPayment;
