import React, { Component } from 'react';
import ImgBoard1 from "../../images/Board-1.gif";
import ImgBoard2 from "../../images/Board-2.gif";
import ImgBoard3 from "../../images/Board-3.gif";
import ImgBoard4 from "../../images/Board-4.gif";
// import { environment } from '../../Services/HttpData';
import CourseCardGrid from "../../components/Ecom/CourseCardGrid";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class HomeUpcomingCourses extends React.Component {

  constructor(props) {
    super(props);
    //   //console.log(this.props);
    this.state = {
      PageContent: [],
      upcomingCourse: [],
      examsFilters: []
    };
  }
  componentWillReceiveProps(props) {
    //   //console.log(props.handelEvent);
    let course = {}
    course.title = props.handelEvent.title;
    course.content = [];
    props.handelEvent.content.map((item) => {
      if (item.status === 1) course.content.push(item);
    });
    // //console.log('Course',course);
    this.setState({ PageContent: course, examsFilters: props.examsFilters, upcomingCourse: props.upcomingCourse });
  }

  getCoureByFilterId(filterId) {
    //console.log(filterId)
    var element = document.getElementById("all-course" + filterId);
    //var allcourse = document.getElementById(filterId);


    let elements = document.querySelectorAll('.custom-nav');

    // add className to all chosen elements
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.remove('nav-btn');
    }


    element.classList.add("nav-btn");

    let requestOptions;
    if (filterId === "") {
      requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "filters": [],
          "sort": "latest",
          "page": 1,
          "limit": 50
        })
      };
    } else {
      requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "filters": [[filterId]],
          "sort": "latest",
          "page": 1,
          "limit": 50
        })
      };
    }
    //const auth_token =  localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : '';

    fetch(environment.ecomApiUrl + 'product/list', requestOptions)
      .then(async response => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data1 = /* isJson && */ await response.json();

        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data1 && data1.message) || response.status;
          return Promise.reject(error);
        }

        this.setState({ upcomingCourse: data1.data.records });
        setTimeout(() => {
          window.$("#carouselExampleControls .carousel-item").removeClass("active").eq(0).addClass("active")
        }, 1000);
        //console.log("upcoming",this.state);
      })
      .catch(error => {
        this.setState({ errorMessage: error.toString() });
        console.error('There was an error!', error);
      });
  }

  render() {
    return <>
      <div className="container my-4 my-md-5">
        <div className=" trending-course-home">
          <div className="sub-heading">

            <h3>{this.state.PageContent?.title}</h3>
          </div>

          {/* <!-- <p className="text-center mt-5">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Totam doloremque officiis tenetur repellendus explicabo! Temporibus nemo facere voluptatem sequi illum voluptate odio, deserunt cumque, alias quod a culpa, mollitia eum!</p>
            --> */}
          <div className="d-flex flex-row flex-wrap justify-content-around my-md-5 my-3 w-100 ml-auto mr-auto">
            <div>
              <a ><h6 className="custom-nav nav-btn" id="all-course" style={{ padding: "10px" }} onClick={() => this.getCoureByFilterId("")}>All Courses</h6></a>
            </div>

            {
              this.state.examsFilters?.map((item1) => (

                <>
                  <div>
                    <a key={item1.id}><h6 style={{ padding: "10px" }} className="custom-nav" onClick={() => this.getCoureByFilterId(item1.id)} id={"all-course" + item1.id} >{item1.title}</h6></a>
                  </div>
                </>
              )
              )}

          </div>
          <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner row w-100">
              {/* <div className="carousel-item active">
      <img className="d-block w-100" src="..." alt="First slide"></img>
    </div> */}
              {
                this.state.upcomingCourse.map((item, i) => {
                  return (
                    <div className={i === 0 ? "carousel-item col-12 col-sm-6 col-md-4 col-lg-3 active" : "carousel-item col-12 col-sm-6 col-md-4 col-lg-3"}>
                      <div className="col-12 p-0">

                        <CourseCardGrid key={item.id}
                          productId={item.id}
                          image={item.image}
                          slug={item.slug}
                          title={item.title}
                          price={item.price}
                          display_price={item.display_price}
                          description={item.description}
                          sub_title={item.sub_title}
                          is_freemium={item.is_freemium}
                          commencement_date={item.commencement_date}
                        />
                      </div>
                    </div>
                  )
                })
              }

            </div>
            <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <i className="fa-solid fa-angle-left"></i>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <i className="fa-solid fa-angle-right"></i>
              <span className="sr-only">Next</span>
            </a>
          </div>

          {this.state.upcomingCourse?.length < 1 && (<div className="no-course-found">
            <h5 className='text-center'>No Course Found </h5>
          </div>)}

          {/* <div className="row  justify-content-center my-3 my-md-5">
              
            {
                        this.state.upcomingCourse.map((item) => {
                           return(
                           
                            <div className="col-xl-3 col-lg-3  my-2 col-12 col-md-3 p-2">
                            <CourseCardGrid key={item.id}
                                productId={item.id}
                                image={item.image}
                                slug={item.slug}
                                title={item.title}
                                price={item.price}
                                display_price={item.display_price}
                                description={item.description}
                                />
                            </div>
                           
                           )
                        })
            }
             
          </div> */}



        </div>
      </div>
    </>;
  }
}

export default HomeUpcomingCourses;