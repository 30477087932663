
import React, {useEffect, useState} from "react";
import arrowDown from "../../images/dropdown-arrow.png";
import { useLocation } from 'react-router-dom';


const CoursesFilter = (props) => {

    const location = useLocation();
    
    let filtersData = props.handelEvent;
   // const [filtersDatau, setFiltersDatau] = useState(filtersData);
    // console.log('props',props,filtersData)
    ////console.log(location.pathname);
    const pathname = location.pathname;
    

    const [checked, setChecked] = useState([]);
    const [filterType, setFilterType] = useState([]);

    //console.log("checkedin",checked)
    const handleCheck = (event, filter_Type) => {
        // console.log(event.target.name === "Online Test Series")
        /* alert(event.target.checked)
        alert(filter_Type) */
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, (event.target.id) ]
        } else {
          updatedList.splice(checked.indexOf((event.target.id)), 1);
        }

       
        // console.log(updatedList)
        setChecked(updatedList);
        
        props.onChange({
            "filters" : updatedList,
            "sort" : props?.filters?.sort,
            /* "page" : 1,
            "limit" : 2 */
        });
    };
    const isChecked = (item) => {
        return checked.indexOf((item)) > -1 ? true : false;
    }
    // useEffect(() => {
        
    //     let prev_selected = [];
    //     filtersData.forEach((item, index) => {
    //         item.filters?.forEach((itemA, indexA) => {
    //             if(item.title.toLowerCase() == 'course mode' && ( pathname.indexOf(itemA.title.toLowerCase()) > -1 ) ){
    //                 prev_selected.push( itemA.id );
    //             }
                
    //         })
    //     })
    //     console.log(prev_selected,"himanshu")
    //     // setChecked(prev_selected)
        
    // }, [props.handelEvent]);

    const clearAll = () => {
        document.querySelector(".filters-clear-btn").value = ''
        props.onChange({sort:''});
        setChecked([]);
    }

    const toggleButton = (title) => {
       // var x = document.getElementById("arrow-"+title);
        var y = document.getElementById("filter-"+title);
        ////console.log(y.style.display)
        if(y.style.display === ""){
            y.style.display = "none";
        }
        else if(y.style.display === "block"){
          y.style.display = "none";
        }
       else {
          y.style.display = "block";
       }
    }

    useEffect(() => {
    let data = document.location.search.substr(1).split("=")[1];
      if (data && !window.location.href.includes("utm_source")) {
        let filterData = data
        .replaceAll("--a/a--a/a--","{")
        .replaceAll( "/b-b/b-b/","}")
        .replaceAll("//c---c//","[")
        .replaceAll( "///d-d///","]")
        .replaceAll( "/e-e////e--e////e-e/",'"');
        // setFilterProduct(data.split(","))
          let apiData = JSON.parse(filterData);
          let arrayData = [];
          if (apiData?.filters?.filters) {
            apiData?.filters?.filters.map((val) => { 
                arrayData = [...arrayData,...val] 
           })
          } else {
            apiData?.filters?.map((val) => { 
                arrayData = [...arrayData,...val] 
           })
         }
        //   console.log(arrayData)
          setChecked(arrayData);
      } else {
        if (pathname.indexOf("online-test-series".toLowerCase()) > -1) {
            setChecked(["cfa4920e8d54af8d443e1e0adedab0da"])
          }
      }
        
       
    },[])
    
        return (
            <>
                <nav className=" col-lg-3 col-md-3 d-lg-block sidebar filter-fluid  filters">
                    <div className="sidebar-sticky">
                        <ul className="nav flex-column p-2 filter-ul">
                            <li className="nav-item">
                                <div className="d-flex flex-row flex-wrap justify-content-between align-items-center pt-5 pb-2">
                                    <h5 className="mr-1 mb-0  ">Filters</h5>
                                    
                                    <button onClick={clearAll} className="btn text-success">Clear All</button>
                                </div>
                            </li>
                            {filtersData && filtersData.map((item, indx) => (
                                <li className="nav-item mb-3" key={indx}>
                                    <div
                                        className="row m-0 align-items-center mb-2 cursor-pointer"
                                        style={{ borderBottom: "1px solid grey" }}
                                        data-toggle="collapse"
                                        data-target={`#${item?.type + indx}`}
                                        aria-expanded="true"
                                        aria-controls={item?.type + indx}
                                    >
                                        <h5 className="p-0 my-auto">
                                            <button className="btn btn-link filter-collapse-link p-0">
                                            {item.title}
                                            </button>
                                        </h5>
                                        <img
                                            src={arrowDown}
                                            className="ml-auto pointer"
                                            width="16px"
                                            height="10px"
                                            id={"arrow-"+item.title}
                                            onClick={(e)=>toggleButton(item.title)}
                                        />
                                    </div>
                                    <div
                                        /* id={item?.type + indx} */
                                        className="collapse show my-hide"
                                        aria-labelledby="headingOne"
                                        data-parent="#accordion"
                                        id={"filter-"+item.title}
                                    >
                                        {
                                            
                                        <div className="card-body col" style={{ padding: "0" }}>
                                            {item.filters?.map((itm, i) => (
                                                <div
                                                    className="row m-0 align-items-center custom-checkbox"
                                                    data-target={itm.type + indx + i}
                                                    key={i}
                                                >
                                                    <input
                                                        id={itm.id}
                                                        className="filter-check"
                                                        type="checkbox"
                                                        aria-label="Checkbox for following text input"
                                                       name={itm.title}
                                                        checked={ isChecked(itm.id)  }
                                                        onChange={(event)=>handleCheck(event,item.id)}
                                                        value={itm.type}
                                                    />
                                                    <label htmlFor={itm.id} className="ml-1 mb-0" onChange={(event)=>handleCheck(event,item.id)} >
                                                        {itm.title}
                                                    </label>
                                                    <p className="ml-auto">({itm.total})</p>
                                                </div>
                                            ))}
                                        </div>
                                        }
                                       
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </nav>
            </>
        );
    }


export default CoursesFilter;
