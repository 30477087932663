import React from 'react';
// import { environment } from '../../Services/HttpData';
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class TestimonialsBanner extends React.Component {

  constructor(props) {
    super(props);
    //   //console.log(this.props);
    this.state = {
      PageContent: [],
    };
  }
  componentWillReceiveProps(props) {
    this.setState({ PageContent: props.handelEvent });
  }

  render() {
    return <>
      <div className="testimonial" >

        <div className="banner-3" style={{backgroundImage:`url(${environment.baseUrlImg +this.state.PageContent?.admin_image})`}}>
          <div className="container banner-content">
            <div className="testimonials-banner-content">
              <h4>{this.state.PageContent?.title}</h4>
              <p>{this.state.PageContent?.meta_description}</p>
            </div>
          </div>
        </div>
      </div>
    </>;
  }
}

export default TestimonialsBanner;