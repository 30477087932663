import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class ArticleChapters extends Component {
    constructor(props) {
      super(props);
    }
    render() { 
        return (<>
            <Link className='rounded-pill border shadow px-3 py-2 m-2 bg-white d-inline-block' to={'/article-details/'+this.props.data?.slug}>{this.props.data?.title}</Link>
        </>);
    }
}
 
export default ArticleChapters;