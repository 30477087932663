import React from "react";
import $ from "jquery";
import "bootstrap";
import { Link } from "react-router-dom";
import { encode as base64_encode } from "base-64";
// import { environment } from "../../Services/HttpData";
import { toast } from "react-toastify";
import CourseGrid from "./CourseGrid";
import CourseList from "./CourseList";
import noCourseImg from "../../images/no-course.png";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class AllCourses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHideGrid: true,
      showHideList: false,
      listItems: [],
      filtersUsed: [],
      slug: null
    };
    this.viewlist = this.viewlist.bind(this);
  }
  componentDidMount() {
    $(function () {
      $('[data-toggle="popover"]').popover({
        html: true,
        content: function () {
          return $(".hovercard").html();
        },
      });
    });
  }
  componentWillReceiveProps(props) {
    //console.log(props.handelEvent);
    this.setState({
      listItems: props.handelEvent.data,
      allTotalItems: props.handelEvent.allTotalItems,
      filtersUsed: props.handelEvent.filtersUsed,
      slug: props.handelEvent.slug,
    });
  }

  viewlist(name) {
    switch (name) {
      case "grid":
        this.setState({ showHideGrid: true, showHideList: false });
        break;
      case "list":
        this.setState({ showHideGrid: false, showHideList: true });
        break;
      default:
    }
    ////console.log('asdasd');
  }
  getPercantage(price, total) {
    return 100 - (price / total) * 100;
  }
  addToCart(item, buyNow = false) {
    //console.log(item);
    const skvDetails = JSON.parse(sessionStorage.getItem("skavaTokenDetails"));

    const wid_data =  localStorage.getItem("userWID") ? JSON.parse( localStorage.getItem("userWID") ) : null;
  let wid =   null;
  if(wid_data){
      if(wid_data.results){
          if(wid_data.results.length){
              if(wid_data.results[0].wid){
                  wid = wid_data.results[0].wid;
              }
          }
      }
  }
    // //console.log(skvDetails.sessionId);
    if (skvDetails !== null && skvDetails.sessionId !== undefined && wid) {
      this.props.changeLoading(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          apiKey: skvDetails.sessionId,
        },
        body: JSON.stringify({
          "cartItem": [
            {
              "quantity": 1,
              "skus": [
                {
                  "productId": item.productId,
                  "skuId": item.productId,
                  "type": "DEFAULT"
                }
              ],
              "properties": {
                "imageURL": item.appIcon,
                "name": item.name,
                "link": `${process.env.REACT_APP_LMS_URL}en/app/toc/${item.identifier}/overview`,
                "fulfillmentType": "DIGITAL"
              }
            }
          ],
          "properties": {
            "enquiryId": "",
            "callerRef": "business"
          }
        })
      };
      fetch(environment.baseUrl + "addToCartSkSite", requestOptions)
        .then(async (response) => {
          // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
          const data = /* isJson && */ await response.json();
          // check for error response
          // //console.log(response);
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
          setTimeout(() => {
          this.props.changeLoading(false);            
          }, 500);
          if (response.ok) {
            if (response.status === 201) {
              toast.success("Item Added to Cart");
              this.props.onChange("reloadCart");
              if (buyNow)
                window.location = environment.skavaBaseUrl + "checkout?return_url=" + environment.REACT_APP_BASE_URL
            }
            else {
              toast.error(data.message);
            }
            // this.setState({cartItems:data.items,cartDetails:{value:data.value,currencySign:data.currencySign}})
            //console.log(data);
          }
        })
        .catch((error) => {
          this.setState({ errorMessage: error.toString() });
          console.error("There was an error!", error);
        });
    }
    else {
      toast.warning("Please login first.");
    }
  }


  render() {
    const { showHideGrid, showHideList, listItems,allTotalItems, filtersUsed } = this.state;
    return (
      <>
        <main
          role="main"
          className=" col-lg-9 col-12 col-sm-12 px-4 pt-4 all-courses"
        >
          {/* <div className="row mx-0 mt-3">
            {filtersUsed.map((item) => {
              return (
                <button
                  type="button"
                  className="btn btn-outline-green-no-width px-4 mr-3 py-1 font-weight-bold rightPadding" 
                  key={item.type} 
                >
                  {item?.displayName}
                  <span className="badge"></span>
                </button>
              );
            })}
           
          </div> */}
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2">
            <h3 className="h3 mb-0">Our Courses{/* {this.state.slug === "live-courses" ? "Live Courses" : this.state.slug === "recorded-lectures" ? "Recorded Lectures" : "Courses"} */}</h3>
            <div className="row m-0 align-items-center">
              {/* <p className="mb-0 sort-by-label">Sort By:</p>
              <select name="" id="" className="sort-list" defaultValue={""} onChange={this.filterStatus}>
                <option defaultValue="">Recent</option>
                <option value="latest">Latest</option>
                <option value="new">New</option>
              </select> */}
              <i
                className={
                  showHideGrid
                    ? "active fas fa-th changeview mx-3"
                    : "fas fa-th changeview m-3"
                }
                onClick={() => this.viewlist("grid")}
              ></i>
              <i
                className={
                  !showHideGrid
                    ? "active fas fa-list changeview mr-3"
                    : "fas fa-list changeview mr-3"
                }
                onClick={() => this.viewlist("list")}
              ></i>
            </div>
          </div>
          {showHideGrid && (
            <div className="row m-0">
              {listItems!==undefined && listItems.map((item) => {
                return (
                  <div
                    className=" col-xl-4 col-lg-4  my-2 col-12 col-md-6 p-2"
                    key={item.productId}
                  >
                    <CourseGrid item =  {item} changeLoading = {this.props.changeLoading} handleCartCount={this.props.handleCartCount}/>

                  </div>
                );
              })}
             
            </div>
          )}

          {showHideList && (
            <div>
              {listItems!==undefined && listItems.map((item) => {
                return (
                  <div
                    className="card p-3 mb-3 course-card"
                    key={item.productId}
                  >
                    <CourseList item = {item} changeLoading = {this.props.changeLoading} handleCartCount={this.props.handleCartCount}/>
                  </div>
                );
              })}
             
            </div>
          )}
          {
          (listItems!==undefined && listItems.length > 0)  ?
          (
            <>
              <div className="text-center"> 
              
                {
                  allTotalItems > listItems.length ? 
                  <button onClick={()=>this.props.loadMore()} className="btn btn-primary nav-btn">Load More</button>
                  :
                  null
                }
               
              </div>
            </>
              
          ) :
            (
              <>
                <div className="text-center no-course-center">
                <img src={noCourseImg} />
                </div>
              </>
              
            )
          }
        </main>
      </>
    );
  }
}

export default AllCourses;
