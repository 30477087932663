import React from "react";
import { toast } from 'react-toastify';
import CoursesFilter from "./../components/Courses/CoursesFilter";
import AllCourses from "./../components/Courses/AllCourses";
// import { environment } from "../Services/HttpData";
import PageNotFound from './PageNotFound';
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class Courses extends React.Component {
  constructor(props) {
    super(props);
    // //console.log(this.props);
    this.apiAccessTokenLms = sessionStorage.getItem("apiAccessTokenLms");
    this.state = {
      courses: [],
      usedfilters: [],
      selectedFilters: { contentType: [], learningMode: [] },
      currentpage:0
    };
  }
  /* componentWillReceiveProps(props) {
    //console.log(this.props.match.params.slug);
  } */

  async componentDidMount() {
    
    await this.setState({usedfilters : []});
    const slug = this.props.match.params.slug;
    
    if (slug === "recorded-lectures") {
      await this.setState({selectedFilters:{ contentType: [], learningMode: ["Self-Paced"] }});
      this.getCourses(true, { contentType: [], learningMode: ["Self-Paced"] },false,0);
    }
    else if (slug === "live-courses") {
      await this.setState({selectedFilters:{ contentType: [], learningMode: ["Instructor-Led"] }});
      this.getCourses(true, { contentType: [], learningMode: ["Instructor-Led"] },false,0);
    }
    else {
      this.getCourses(true);
    }

  }
  async fetchToken() {
    // POST request using fetch with async/await
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({}),
    };
    fetch(environment.baseUrl + "genrateAuthTokentBusinessSite", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data = /* isJson && */ await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        if (data.status === 200) {
          this.apiAccessTokenLms = data.body.access_token;
          sessionStorage.setItem("apiAccessTokenLms", data.body.access_token);
          return Promise.resolve(this.apiAccessTokenLms);
          // this.getCourses();
        } else {
          console.error(data.body.message);
          return null;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  async getCourses(setFilters, extraFilter = null,loadMore=false,currentpage=null) {
    // POST request using fetch with async/await
    // //console.log(setFilters,extraFilter);
    return;
    if(currentpage === null){
      currentpage = this.state.currentpage;
    }
    var filter;
    if (extraFilter)
      filter = extraFilter;
    else
      filter = this.state.selectedFilters;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.apiAccessTokenLms,
        /* "apiKey":process.env.API_KEY,
            "rootOrg":process.env.ROOT_ORG */
      },
      body: JSON.stringify({
        url:
          `${process.env.REACT_APP_LMS_URL}api-gw/external-consumers/allendigital-business/content-search/v6/search/external`,
        rootOrg: environment.ROOT_ORG,
        org: environment.ROOT_ORG,
        uuid: environment.WID,
        locale: ["en"],
        pageSize: 12,
        pageNo:currentpage,
        query: "",
        didYouMean: true,
        purchaseWindow: true,
        filters: [
          {
            andFilters: [
              filter
            ],
            notFilters: [],
          },
        ],
        visibleFilters: {
          learningMode: {
            displayName: "Course Mode",
          },
         /*  duration: {
            displayName: "Duration",
          },
          contentType: {
            displayName: "Content Type",
          },
          complexityLevel: {
            displayName: "Level",
          }, */
          catalogPaths: {
            displayName: "Exam",
          },
          sourceShortName: {
            displayName: "Source",
          },
          resourceType: {
            displayName: "Format",
          },
          concepts: {
            displayName: "Concepts",
          },
          unit: {
            displayName: "Unit",
          },
          /* lastUpdatedOn: {
            displayName: "Published Date",
          }, */
        },
        sort: [
          {
            lastUpdatedOn: "desc",
          },
        ],
      }),
    };
    this.props.changeLoading(true);
    fetch(environment.baseUrl + "callBusinessAPI", requestOptions)
      .then(async (response) => {
        //console.log("res",response)
        this.props.changeLoading(false);
        if(response.status === 204){
          let coursesData=this.state.courses;
          coursesData.result=[];
          this.setState({ courses:coursesData});
        }
        else{
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
          const data = /* isJson && */ await response.json();
          // check for error response
          
          if (!response.ok) {
            if (data.status === 401) {
              await this.fetchToken();
              await this.getCourses(setFilters);
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            } else {
              //console.log("ERROR Come");
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
          }
          if (data.status === 200) {
            let usedfilters = this.state.usedfilters;
            // const usedfilters = data.body.filters.filter((item) => {
            //   return data.body.filtersUsed.some((f) => {
            //     return f === item.type;
            //   });
            // });
            if (setFilters) {
              usedfilters = [];
              var filtersData = data.body.filters.map((item) => {
                item.content = item.content.map((itm) => {
                  itm.isChecked = false;
                  if(extraFilter!==null && extraFilter.learningMode[0]===itm.type){
                    itm.isChecked = true;
                    usedfilters.push(item)
                  }
                  return itm;
                });
                return item;
              });
              data.body.filters = filtersData;
            }
            else {
              data.body.filters = this.state.courses.filters;
            }
            if(loadMore){
              let coursesData=this.state.courses;
              coursesData.result=[...coursesData.result,...data.body.result];
              this.setState({ courses: coursesData, usedfilters: usedfilters,currentpage:currentpage+1 });
            }
            else{
              //console.log('usedfilters',usedfilters)
            this.setState({ courses: data.body, usedfilters: usedfilters,currentpage:currentpage+1 });
          }
            // localStorage.setItem("userDetails", JSON.stringify(data.body));
          } else {
            toast.error(data.body.message);
          }

          //console.log(data);
        }
      })
      .catch((error) => {
        this.props.changeLoading(false);
        console.error("There was an error!", error);
      });
  }
 
  async handleChildClick(pindx, cindex,event) {
    /* //console.log(

      "The Child button data is: ",
      pindx,
      cindex,
      this.state.courses.filters[pindx].type
    ); */
    // return;
    //console.log('myevent',event)
    let courses = this.state.courses;
    let selected_filters = [];
    var filtersData = courses?.filters.map((item, pindex) => {
      item.content = item.content.map((itm, cidx) => {
        if (pindx === pindex && cidx === cindex) {
         
          if (event.target.checked) {
            let selectedFilters = this.state.selectedFilters;
            if (selectedFilters[this.state.courses.filters[pindx].type] === undefined) {
              // //console.log("pushed");
              selectedFilters[this.state.courses.filters[pindx].type] = [];
            }
            selectedFilters[this.state.courses.filters[pindx].type].push(event.target.value);
             this.setState({ selectedFilters: selectedFilters,currentpage:0 });
            
          } else {
            //console.log('this.state.selectedFilters',this.state.selectedFilters)
            //console.log('this.state.selectedFilters',this.state.filters)
            let selectedIndex = this.state.selectedFilters[this.state.courses.filters[pindx].type].findIndex(
              (filter) => filter === event.target.value
            );
            let selectedFilters = this.state.selectedFilters;
            selectedFilters[this.state.courses.filters[pindx].type].splice(selectedIndex, 1);
             this.setState({ selectedFilters: selectedFilters,currentpage:0 });
          }
          itm.isChecked = !itm.isChecked;
        }
        if (itm.isChecked) {
          selected_filters.push(item)
        }
        return itm;
      });
      return item;
    });
    // //console.log(this.state.selectedFilters);
    courses.filters = filtersData;
    await this.setState({ courses: courses,currentpage:0,usedfilters: selected_filters });
    //console.log("myfilter",selected_filters)
    this.getCourses(false);
  }
  handleCartCount(status) {
    this.props.onChange(status);
  }
  handleLoading(status) {
    this.props.changeLoading(status);
  }
  handleLoadMore(e){
    this.getCourses(false,null,true);
    
  }

  

  render() {
    return (
      <>
        <div className="container">
          <div className="row">
            <CoursesFilter
              handelEvent={this.state.courses?.filters}
              onChange={this.handleChildClick.bind(this)}
              clearAll={()=>this.componentDidMount()}
            />
            
            <AllCourses
              handelEvent={{
                filtersUsed: this.state.usedfilters,
                data: this.state.courses?.result,
                allTotalItems: this.state.courses?.totalHits,
                slug: this.props.match.params.slug
              }}
              
              handleCartCount={this.handleCartCount.bind(this)}
              changeLoading={this.handleLoading.bind(this)}
              loadMore={this.handleLoadMore.bind(this)}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Courses;
