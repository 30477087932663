import React from 'react';
import { useState } from 'react';
import popImage from "../images/300X400.jpg";

const EarlyBird = () => {

    const [isOpen, setisOpen] = useState(true);

    function closeEarlybird() {
        setisOpen(false);
    }

    return (
        <div>
            {

                isOpen ? <div className='early_bird_popup_overlay'>

                    <div className='early_bird_popup'>
                        <div className='early_bird_popup_close' onClick={closeEarlybird}>x</div>
                        <a href='https://allen.in/ultimate-program-live-courses?utm_source=digitalweb&utm_medium=pop-up&utm_campaign=onlinecourse' target='blank'>
                            <img className='early_bird_image' src={"https://cdn.digital.allen.ac.in/fe-bsite/images/your-dream-welcome-popup.jpg"} alt="Allen Digital"></img>
                        </a>
                    </div>
                </div>

                    : ""
            }

        </div>
    );
};

export default EarlyBird;