import React from "react";
// import { environment } from "../../Services/HttpData";
import { toast } from "react-toastify";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
export default function CoureshCancel(props) {
  const auth_token = localStorage.getItem("apiAccessToken")
    ? localStorage.getItem("apiAccessToken")
    : "";
  const CoureshCancelApi = (e) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Basic " + auth_token,
      },
    };

    fetch(
      environment.ecomApiUrl +
        `student/ticket-cancel/${props?.ticket_data?.ticket_id}`,
      requestOptions
    ).then((resp) => {
      resp
        .json()
        .then((result) => {
          if (result.status === "success") {
            props?.getStudentTicketLog();
            toast.success(result.message);
          }
        })
        .catch((error) => console.error(error));
    });
  };
  return (
    <button type="button" class="btn btn-danger" onClick={CoureshCancelApi}>
      Cancel
    </button>
  );
}
