import React from "react";
import videoicon from "../../images/video.png";
import documenticon from "../../images/document.png";
import clockicon from "../../images/clock.png";
// import {EContentTypes,EMimeTypes} from "../../Services/constants"
import { convertSecondsToReadableString, getHierarchyStructureHelper } from "../../Services/courseService";

class ContentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detailsData: null,
      contentCounters: null,
      tableContent : props.tableContent?props.tableContent:[],
      slideShow:false,

    };
  }
  componentWillReceiveProps(props) {
    this.setState({ detailsData: props.handelEvent, contentCounters: getHierarchyStructureHelper(props.handelEvent) });
    // if (props.handelEvent !== undefined)
      //console.log("props.handelEvent", getHierarchyStructureHelper(props.handelEvent)/* EContentTypes */);
    // getHierarchyStructureHelper()
  }

  toggleArrow(topic_id) {
    var x = document.getElementById("myDIVarrowtab-"+topic_id);
  
    if ( x.style.transform == "rotate(180deg)") {
      x.style.transform = "rotate(360deg)";
    }
     else {
      x.style.transform = "rotate(180deg)";
    }
  }
  
  render() {
    //console.log('tableContent',this.props.tableContent)
  return (
    <>
      {/* <div>{JSON.stringify(content)}</div> */}
      {/* Start of body/subject */}
      {
        this.props.tableContent.length > 0 ?
        (
          <>
              <div className="col my-5  courses-subjact">
        <h5 className="font-weight-bold mb-4">Content</h5>
        <div className="row mx-0">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            
                {this.props.tableContent.map((item,i) => {
                  //console.log(item.contents.length);
                    return(
                      item.contents.length?
                      <li className="nav-item" role="presentation">
                      <a
                        className={i==0?"nav-link active":"nav-link"}
                        id={item.subject_name + "-tab"}
                        data-toggle="tab"
                        href={"#" + item.subject_name}
                        role="tab"
                        aria-controls={item.subject_name}
                        aria-selected="true"
                      >{item.subject_name}
                      </a>
                    </li>
                    :null
                    )}
              )}
              
          </ul>

          {/* <select
            className="form-select ml-auto mobile-on"
            aria-label="Default select example"
            defaultValue={'1'}            >
            <option value="1">
              Chapters
            </option>
            <option value="2"> Videos</option>
            <option value="3">22hrs 31mins</option>
          </select> */}
          
        </div>
        <div className="tab-content" id="myTabContent">
        {this.props.tableContent.map((item1,i) => {
            return( 
              <>
              {
                item1.contents.length?
                <div
                className={i === 0 ? "tab-pane fade active show": "tab-pane fade"} 
                id={item1.subject_name}
                role="tabpanel"
                aria-labelledby={item1.subject_name + "-tab"}
              >
                {/* Content of subject /sub topic/ topic title */}
              {
                (item1.contents).map((item2)=>{
                  return(
                    <>
                      <div className="accordion" id="accordionExample">
                        <div
                          id="headingOne"
                          className="row m-0 align-items-center dropdown-toggle" 
                          onClick={()=>this.setState(!this.slideShow)}
                          >
                            
                              
                                
                                {
                                  item2.child.length ?
                                  (
                                    <>
                                    <h2 className="mb-0" onClick={()=>this.toggleArrow(item2.topic_id)}>
                                    <button
                                      className="btn btn-link btn-block text-left text-dark"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#collapseOne"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                      onClick={()=>this.setState({slideShow:!this.state.slideShow})}
                                    ></button>
                                    <i className="fa-solid fa-chevron-down ml-2 font-size-19" id={"myDIVarrowtab-"+item2.topic_id}></i>
                                    </h2>
                                    </>
                                  ):
                                  (
                                    <>
                                      <span
                                      className="btn-block text-left text-dark"
                                      
                                    >
                                      {item2.topic_title}
                                    </span>
                                    </>
                                  )
                                 
                                }
                                
                             
                            
                              {/* <p className="mb-0 ml-5 mr-auto text-muted h5">
                              14 Lectures | 42 Mins
                              </p> */}
                        </div>
                        {/* Sub Topics Data */}
                        {
                          this.state.slideShow ? 
                            <div
                              id={"collapseOne-"+item2.topic_id}
                              className="collapse show"
                              aria-labelledby="headingOne"
                              data-parent="#accordionExample"
                            >
                              {
                                (item2.children ? item2.children: []).map((item3)=>{
                                  return(
                                    <>
                                      <div className="card-body">
                                      {item3.topic_title}
                                     {/*  <code>.show</code> className. */}
                                      </div>
                                    </>
                                  );
                                })
                              }
                            </div>:
                          null
                        }
                      </div>
                      <hr />
                    </>
                    );
                  })
              }
              </div>
              :null
              }
              
              </>
            )}
          )}
        </div>
      </div>
          </>
        ):null
  }
  {/* Faq */}
  {/* {
    props.data.faq?
    (
      <>
        <div className="col my-5 aboutCourse">
          <p className="text-muted font-size-22-normal" dangerouslySetInnerHTML={{__html:props.data.faq}}>
          </p>
        </div>
      </>
    ):null
  } */}
      
    </>
  );
  }
}

export default ContentDetails;
