import React from "react";
import iit from '../../images/iit-main.jpg';
import { Link } from "react-router-dom";

const CourseCard = (props) =>{
    const dateFormat = (date) => {
        let today = new Date(date);
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        
        //let new_date = dd + '/' + mm + '/' + yyyy;
        const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        const d = new Date(today);
        let mn = month[d.getMonth()];
        let new_date = d.getDate()+" "+month[d.getMonth()]+" "+d.getFullYear()
        return new_date;
      }
    return(
        <>
        <Link
        to={`/courses-details/${encodeURIComponent(
            props.slug.toLowerCase()
        ).replace("%20", "+")}`}
        >
            <div
                className="card p-3 mb-3 course-card"
                key={props.productId}
                >
                <div className="row m-0 align-items-center">
                    <div className="col-12 col-lg-4 p-0">
                    <Link
                        to={`/courses-details/${encodeURIComponent(
                            props.slug.toLowerCase()
                        ).replace("%20", "+")}`}
                        >
                        <img src={props.image===""?iit:props.image} alt="" width="100%" />
                        {/* <Link to="#" className="bookmark-btn">
                            <i className="far fa-bookmark"></i>
                        </Link> */}
                    </Link>
                    </div>
                    <div className="card-content col-12 col-lg-8">
                    <div className="d-flex flex-row flex-wrap mb-2">
                        <div>
                            <Link
                            to={`/courses-details/${encodeURIComponent(
                                props.slug.toLowerCase()
                            ).replace("%20", "+")}`}
                            >
                            <h6 className="mr-2">{props.title}</h6>
                            </Link>
                            <p className="cut-text-short" title={props.description}>{props.description}</p>
                        </div>
                        
                    </div>
                    
                    <div className=" mx-0 " >
                        {
                            props.is_freemium === 1 ?
                            null:
                            (
                                <>
                                    <div className="row m-0" style={{ justifyContent: 'space-between'}}>
                                        <h5 className="font-weight-bold mr-3 mb-0 my-font text-black">
                                        {"₹ " + props.price}
                                        </h5>
                                        {
                                            props.price != props.display_price?
                                            (
                                                <>
                                                    <h5 className="font-weight-bold text-danger mb-0 my-font text-black">
                                                        <s>
                                                        {"₹ " + props.display_price} 
                                                        </s>
                                                    </h5>
                                                </>
                                            ):null
                                        }
                                         <span className="">{dateFormat(props.commencement_date)}</span>
                                    </div>
                                </>
                            )
                        }
                        
                        
                        {/* <div className="row mx-0 mb-2">
                        <Link
                            type="button"
                            className="btn btn-outline-green-low-radius mr-3 mb-1"
                            to="/cart"
                        >
                            Buy Now
                        </Link>
                        <button
                            type="button"
                            className="btn btn-green-low-radius mb-1"
                            onClick={() =>this.addToCart(item)}
                        >
                            Add to Bag
                        </button>
                        </div> */}
                    </div>
                    {props?.price?.value > props?.salesPrice?.value && (
                        <p
                        className="mb-0"
                        style={{
                            color: "#046635",
                            marginTop: "-28px",
                            color: "#429B00",
                            fontSize: "16px",
                        }}
                        >
                        Save
                        {props?.price?.currency === "INR"
                            ? "₹"
                            : props?.price?.currency}
                        {props?.price?.value - props?.salesPrice?.value} (
                        {this.getPercantage(
                            props?.salesPrice?.value,
                            props?.price?.value
                        ).toFixed(2)}
                        % OFF)
                        </p>
                    )}
                    </div>
                </div>
            </div>
        </Link>
        </>
    )
}

export default CourseCard;