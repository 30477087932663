import React from "react";
import { toast } from "react-toastify";
import saveicon from "../../images/save-icon.png";
import shareicon from "../../images/share-icon.png";
// import { environment } from "../../Services/HttpData";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detailsData: null,
      showReadMore:true
    };
  }
  componentWillReceiveProps(props) {
    this.setState({ detailsData: props.handelEvent });
  }
  getPercantage(price, total) {
    return 100 - (price / total) * 100;
  }
  addToCart(item, buyNow = false){
    //console.log(item);
  const skvDetails = JSON.parse(sessionStorage.getItem("skavaTokenDetails"));
  // //console.log(skvDetails.sessionId);
  const wid_data =  localStorage.getItem("userWID") ? JSON.parse( localStorage.getItem("userWID") ) : null;
  let wid =   null;
  if(wid_data){
      if(wid_data.results){
          if(wid_data.results.length){
              if(wid_data.results[0].wid){
                  wid = wid_data.results[0].wid;
              }
          }
      }
  }

  if(skvDetails!==null && skvDetails.sessionId!==undefined && wid){
    this.props.changeLoading(true);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      apiKey: skvDetails.sessionId,
    },
    body:JSON.stringify({
      "cartItem": [
          {
              "quantity": 1,
              "skus": [
                  {
                      "productId": item.productId,
                      "skuId": item.productId,
                      "type": "DEFAULT"
                  }
              ],
              "properties": {
                  "imageURL": item.appIcon,
                  "name": item.name,
                  "link": `${process.env.REACT_APP_LMS_URL}en/app/toc/${item.identifier}/overview`,
                  "fulfillmentType": "DIGITAL"
              }
          }
      ],
      "properties": {
          "enquiryId": "",
          "callerRef": "business"
      }
  })
  };
  fetch(environment.baseUrl + "addToCartSkSite", requestOptions)
    .then(async (response) => {
      this.props.changeLoading(false);
      // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
      const data = /* isJson && */ await response.json();
      // check for error response
      // //console.log(response);
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }
      if (response.ok) {
        if(response.status===201){
          toast.success("Item Added to Cart");
          //this.props.onChange("reloadCart");
          if (buyNow){
            window.location = environment.skavaBaseUrl + "?return_url=" + environment.REACT_APP_BASE_URL
          }
          else{
            this.props.handleCartCount('reloadCarts');
          }
        }
        else if (response.status === 200 && buyNow)  {
          if( data.purchased ){
            toast.error(data.message);
          }
          else{
            window.location = environment.skavaBaseUrl + "?return_url=" + environment.REACT_APP_BASE_URL
          }
        }
        else{
          toast.error(data.message);
        }
          // this.setState({cartItems:data.items,cartDetails:{value:data.value,currencySign:data.currencySign}})
        //console.log(data);
      }
    })
    .catch((error) => {
      this.props.changeLoading(false);
      toast.error("There was an error!");
      this.setState({ errorMessage: error.toString() });
      console.error("There was an error!", error);
    });
    }
    else{
      toast.warning("Please login first.");
    }
  }
  render() {
    return (
      <>
        <div className="row m-0">
          <div className="col-12 col-lg-4">
            <img
              src={this.state.detailsData?.appIcon}
              style={{ width: "100%" }}
            />
            <div className="my-3">
              <div className="row m-0">
                <h4 className="font-weight-bold color-h4 mr-3 mb-0 font-size-30">
                  {this.state.detailsData?.salesPrice?.currency === "INR"
                    ? "₹"
                    : this.state.detailsData?.salesPrice?.currency}
                  {this.state.detailsData?.salesPrice?.value}
                </h4>
                {this.state.detailsData?.price?.value >
                  this.state.detailsData?.salesPrice?.value && (
                  <h4 className="font-weight-bold text-danger mb-0 font-size-30">
                    {this.state.detailsData?.price?.currency === "INR"
                      ? "₹"
                      : this.state.detailsData?.price?.currency}
                    <s>{this.state.detailsData?.price?.value}</s>
                  </h4>
                )}
                {/* <h4 className="font-weight-bold text-danger mb-0 font-size-30">MRP: <s>&#8377;1499</s></h4> */}
              </div>

              {this.state.detailsData?.price?.value >
                this.state.detailsData?.salesPrice?.value && (
                <p className="my-0 font-size-22" style={{ color: "#046635" }}>
                  Save
                  {this.state.detailsData?.price?.currency === "INR"
                    ? "₹"
                    : this.state.detailsData?.price?.currency}
                  {this.state.detailsData?.price?.value -
                    this.state.detailsData?.salesPrice?.value}{" "}
                  (
                  {this.getPercantage(
                    this.state.detailsData?.salesPrice?.value,
                    this.state.detailsData?.price?.value
                  ).toFixed(2)}
                  % OFF)
                </p>
              )}
              {/* Save Rs. 300 (20% OFF) */}
            </div>
            <div className="row mx-0 mb-2">

              <div className="">

              <button
                type="button"
                className="btn btn-primary mt-3 mr-4"
                onClick={() => this.addToCart(this.state.detailsData,true)}
               style={{border: "solid 1px #046635", background: "none", color: "#000", margin: "5px 0 0 0",  padding: "9px 22px;"}}
               >
                Buy Now
              </button>

              </div>
             
              <div className="">
                <button
                  type="button"
                  className="btn btn-primary mt-3 "
                  onClick={() => this.addToCart(this.state.detailsData)}
                  style={{border: "solid 1px #046635", background: "#046635", color: "#fff", margin: "5px 0 0 0",  padding: "9px 22px;"}}
                  >
                  Add to Bag
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            {/* <div className="row m-0">
              <div className="d-flex flex-row">
                <p className="best-seller">Best Seller</p>
              </div>
              <div className="row ml-auto">
                <img
                  className="mr-2"
                  src={saveicon}
                  alt=""
                  width="15px"
                  height="15px"
                />
                <a className="mr-2" href="#">
                  <p>Add to wishlist</p>
                </a>
                <img src={shareicon} alt="" width="15px" height="15px" />
              </div>
            </div> */}
            <div>
              <h5 className="font-weight-bold font-size-30">
                {this.state.detailsData?.name}
              </h5>
              <p dangerouslySetInnerHTML={{__html:this.state.detailsData?this.state.detailsData.body:null}}></p>
             {/*  { <p className="mt-3 text-muted font-size-22-normal" dangerouslySetInnerHTML={{__html:this.state.detailsData?.body?.substring(0, 150)+this.state.detailsData?.body?.length>150?'...':''}}></p>}
              {!this.state.showReadMore && (<p className="mt-3 text-muted font-size-22-normal" dangerouslySetInnerHTML={{__html:this.state.detailsData?.body}}>
              </p>)}
              { (this.state.detailsData?.body?.length>150) && this.state.showReadMore && (<div className="text-right">
                <button type="button" className="font-size-22-normal btn btn-link text-success" onClick={()=>{this.setState({showReadMore:!this.state.showReadMore});//console.log(this.state.showReadMore);}}>
                  Read More
                </button>
              </div>)}
              { (this.state.detailsData?.body?.length>150) && !this.state.showReadMore && (<div className="text-right">
                <button type="button" className="font-size-22-normal btn btn-link text-success" onClick={()=>{this.setState({showReadMore:!this.state.showReadMore});//console.log(this.state.showReadMore);}}>
                  Read Less
                </button>
              </div>)} */}
            </div>
          </div>
        </div>

        <div className="col my-5 aboutCourse">
          <h5 className="font-weight-bold mb-4">About this Course</h5>
          <p className="text-muted font-size-22-normal" dangerouslySetInnerHTML={{__html:this.state.detailsData?.description}}>
          </p>
          {/* <ul className="nav flex-column p-2 abooutthiscourses">
            <li className="nav-item mb-3 font-size-22-normal">
              <i className="fas fa-circle"></i>Lorem ipsum dolor sit amet,
              consectetur adipiscing elit.
            </li>
            <li className="nav-item mb-3 font-size-22-normal">
              <i className="fas fa-circle"></i>Fusce porta sem vel orci interdum
              faucibus.
            </li>
            <li className="nav-item mb-3 font-size-22-normal">
              <i className="fas fa-circle"></i>Maecenas et mauris ut ex
              hendrerit blandit in ac augue.
            </li>
          </ul> */}
        </div>
      </>
    );
  }
}

export default Details;
