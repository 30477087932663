import React, { useEffect, useState } from "react";
import cartImg from "../images/cart-img-new.png";
import starFill from "../images/star-fill.png";
import greenCheckIcon from "../images/green-correct-icon.png";
import plaIconGreen from "../images/play-store-green.png";
import appIconGreen from "../images/app-store-green.png";
import formMan from "../images/form-man.png";
import videoProgram from "../images/video-program.png";
import digitalFeartueBg from "../images/digital-feature-bg-img.png";
import ellipseImg from "../images/ellipse-310.png";
import keyFeaturesImg from "../images/key-features-img.png";
import { Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";
import { toast } from "react-toastify";
// import { environment } from "../Services/HttpData";
import IntlTelInput from "react-intl-tel-input";
import country from "../js/countries+states.json";
import AboutUsBanner from "../components/AboutUs/AoutusBanner";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
const FreeResourceJeeMain = () => {
  /* constructor(props) {
    super(props);
    this.wid_data = null ;
    this.wid = null;
    this.state = {
        PageContent: []
    };
  } */

  const [PageContent, setPageContent] = useState([]);
  let wid_data = null;
  const [wid, setWid] = useState(false);
  let counter = 0;

  useEffect(() => {
    setInterval(() => {
      wid_data = localStorage.getItem("userWID")
        ? JSON.parse(localStorage.getItem("userWID"))
        : null;
      //console.log("wid", wid_data);
      if (wid_data) {
        if (wid_data.results) {
          if (wid_data.results.length) {
            if (wid_data.results[0].wid) {
              setWid(wid_data.results[0].wid);
            }
          }
        }
      }
    }, 1000);
    // POST request using fetch with async/await
    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({ keyword: "" }),
    // };
    // fetch(environment.baseUrl + "view_list_all", requestOptions)
    //   .then(async (response) => {
    //     // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
    //     const data = /* isJson && */ await response.json();
    //     // check for error response
    //     // //console.log('view-lists',data);
    //     if (!response.ok) {
    //       // get error message from body or default to response status
    //       const error = (data && data.message) || response.status;
    //       return Promise.reject(error);
    //     }
    //     let index = data.data.findIndex(
    //       (item) => item.slug === "pyp-jee-main"
    //     );
    //     ////console.log("section",data.data[index].sections[0].content[0].description);
    //     let sections = {};
    //     data.data[index]?.sections.map((item) => {
    //       // //console.log(item)
    //       switch (item.id) {
    //         case 143:
    //           sections.banner = item;
    //           break;
    //         case 143:
    //           sections.content = item;
    //           break;
    //       }
    //     });
    //     setPageContent(data.data[index].sections[0].content[0]);
    //     ////console.log('advanced',this.state.PageContent);
    //   })
    //   .catch((error) => {
    //     setPageContent(error.toString());
    //     console.error("There was an error!", error);
    //   });
    //
    const getViewListAll = () => {
      if (!window?.AllenDigitalFEBSite) {
        setTimeout(() => {
          getViewListAll();
        }, 50);
        return false
      }
      let index = window?.AllenDigitalFEBSite.data.findIndex(
        (item) => item.slug === "pyp-jee-main"
      );
      ////console.log("section",data.data[index].sections[0].content[0].description);
      let sections = {};
      window?.AllenDigitalFEBSite.data[index]?.sections.map((item) => {
        // //console.log(item)
        switch (item.id) {
          case 143:
            sections.banner = item;
            break;
          case 143:
            sections.content = item;
            break;
        }
      });
      setPageContent(window?.AllenDigitalFEBSite.data[index].sections[0].content[0]);
    };
    getViewListAll();
  }, []);

  /* if (!wid) {
    //  //console.log("doLogin() Called");
    window.location = `${
      process.env.REACT_APP_AUTH_URL
    }?client_id=allendigital-business&redirect_uri=${
      window.location.href.replace("www.", "").split("#")[0]
    }&response_mode=fragment&response_type=code&scope=openid`;
  } */

  return (
    <>
      <AboutUsBanner
        handelEvent={() => {}}
        slug="JEE Main Previous Year Papers"
      />

      { (
        <>
          <div className="tab-content" id="pills-tabContent">
            <div className=" app-features">
              <div className="container py-5">
                <div className="">
                  <h2 className="font-weight-bold text-center mb-3">
                    {PageContent.title}
                  </h2>
                  <div className="card py-5 course-card">
                    <div className="row">
                      <div
                        className="col-12 col-lg-12"
                        dangerouslySetInnerHTML={{
                          __html: `<script>
                          const enviroPath = '';
                          
                       </script>
                       <style>
                       .course-card{
                        background: #ffffff !important;
                        }
                          .ace-table {
                          font-family: sans-serif;
                          border: 1px solid #ddd;
                          border-collapse: collapse;
                          background-color: #ebebeb4a;
                          }
                          .bg-1 {
                          background-color: #ebebeb4a;
                          padding: 10px 20px 20px 20px;
                          border: solid 1px #e7e5e2;
                          margin-bottom: 30px;
                          padding-top: 0;
                          }
                          .bg-1 h2 {
                          text-align: center;
                          font-family: sans-serif;
                          color: #555;
                          margin: 10px 0px;
                          font-size: 20px !important;
                          }
                          .ace-table th {
                          border: 1px solid #ddd;
                          padding: 8px;
                          font-family: sans-serif;
                          }
                          .ace-table td {
                          border: 1px solid #ddd;
                          padding: 8px;
                          font-family: sans-serif;
                          }
                          .ace-table a {
                          text-decoration: none;
                          color: #72c02c !important;
                          font-family: sans-serif;
                          }
                       </style>
                       <section class="bg-1">
                          <h2>JEE MAINS 2021 (March. &amp; August. Attempt) Question Papers with Solutions PDF</h2>
                          <!--   Jee Main Paper  -->
                          <table class="table  ace-table" width="100%">
                             <tbody>
                                <tr>
                                   <td rowspan="2" align="center" style="vertical-align: middle;"><strong>Subject</strong></td>
                                   <td colspan="2" align="center" valign="middle"><strong>Paper With Solution</strong></td>
                                </tr>
                                <tr>
                                </tr>
                                <!--       Chemistry August 2021              -->
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (0109 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Chem/0109_Evening/0109_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (2608 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Chem/2608_Evening/2608_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (2608 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Chem/2608_Morning/2608_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (2708 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/jJEE-MAINS-2021/AUGAST/Chem/2708_Evening/2708_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (2708 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Chem/2708_Morning/2708_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (3108 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Chem/3108_Evening/3108_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (3108 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Chem/3108_Morning/3108_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <!--       Maths August 2021              -->
                                <tr>
                                   <td valign="middle">Maths Previous Years’ Question Papers with Solutions PDF <b>English (0109 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Math/0109/0109_JM_MATHEMATICS_PAPER_SOLUTION_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Maths Previous Years’ Question Papers with Solutions PDF <b>English (2608 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Math/2608_Evening/2608_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Maths Previous Years’ Question Papers with Solutions PDF <b>English (2608 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Math/2608_Morning/2608_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Maths Previous Years’ Question Papers with Solutions PDF <b>English (2708 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Math/2708_Evening/2708_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Maths Previous Years’ Question Papers with Solutions PDF <b>English (2708 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Math/2708_Morning/2708_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Maths Previous Years’ Question Papers with Solutions PDF <b>English (3108 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Math/3108_Evening/3108_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Maths Previous Years’ Question Papers with Solutions PDF <b>English (3108 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Math/3108_Morning/3108_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <!--       Physics August 2021              -->
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (0109 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Phy/0109/0109_JM_PHYSICS_PAPER_SOLUTION_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (2608 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Phy/2608_Evening/2608_JM_PHYSICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (2608 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Phy/2608_Morning/2608_JM_PHYSICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (2708 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Phy/2708_Evening/2708_JM_PHYSICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (2708 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Phy/2708_Morning/2708_JM_PHYSICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (3108 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Phy/3108_Evening/3108_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (3108 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/AUGAST/Phy/3108_Morning/3108_JM_PHYSICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <!--    February    -->
                                <tr>
                                </tr>
                                <!--       Chemistry February 2021              -->
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (2402 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Chemistry/2402_Evening/2402_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (2402 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Chemistry/2402_Morning/2402_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (2502 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Chemistry/2502_Evening/2502_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (2502 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Chemistry/2502_Morning/2502_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (2602 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Chemistry/2602_Evening/2602_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (2602 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Chemistry/2602_Morning/2602_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <!--       Maths September 2021              -->
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (2402 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Mathematics/2402_Evening/2402_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (2402 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Physics/2402_Morning/2402_JM_PHYSICS_PAPER_%20SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (2502 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Mathematics/2502_Evening/2502_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (2502 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Mathematics/2502_Morning/2502_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (2602 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Mathematics/2602_Evening/2602_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (2602 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Mathematics/2602_Morning/2602_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <!--       Physics September 2021              -->
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (2402 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Physics/2402_Evening/2402_JM_PHYSICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (2402 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Physics/2402_Morning/2402_JM_PHYSICS_PAPER_%20SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (2502 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Physics/2502_Evening/2502_JM_PHYSICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (2502 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Physics/2502_Morning/2502_JM_PHYSICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (2602 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Physics/2602_Evening/2602_JM_PHYSICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (2602 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/FEBRUARY/Physics/2602_Morning/2602_JM_PHYSICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <!--       Chemistry July 2021              -->
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (2007 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Chemistry/2007_Evening/2007_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (2007 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Chemistry/2007_Morning/2007_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (2207 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Chemistry/2207_Evening/2207_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (2507 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Chemistry/2507_Evening/2507_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (2507 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Chemistry/2507_Morning/2507_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (2707 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Chemistry/2707_Evening/2707_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (2707 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Chemistry/2707_Morning/2707_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <!--  Mathematics July 2021   -->
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (2007 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Mathematics/2007_Evening/2007_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (2007 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Mathematics/2007_Morning/2007_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (2207 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Mathematics/2207_Evening/2207_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (2507 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Mathematics/2507_Evening/2507_JM_MATHEMATICS_PAPER_%20SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (2507 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Mathematics/2507_Morning/2507_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (2707 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Mathematics/2707_Evening/2707_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (2707 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Mathematics/2707_Morning/2707_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <!--  Physics July 2021   -->
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (2007 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Physics/2007_Evening/2007_JM_PHYSICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (2007 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Physics/2007_Morning/2007_JM_PHYSICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (2207 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Physics/2207_Evening/2207_JM_PHYSICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (2507 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Physics/2507_Evening/2507_JM_PHYSICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (2507 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Physics/2507_Morning/2507_JM_PHYSICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (2707 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Physics/2707_Evening/2707_JM_PHYSICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (2707 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/JULY/Physics/2707_Morning/2707_JM_PHYSICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <!--       Chemistry March 2021              -->
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (1603 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/MARCH/Chemistry/1603_Evening/1603_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (1603 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/MARCH/Chemistry/1603_Morning/1603_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (1703 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/MARCH/Chemistry/1703_Evening/1703_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (1703 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/MARCH/Chemistry/1703_Morning/1703_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (1803 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/MARCH/Chemistry/1803_Evening/1803_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (1803 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/MARCH/Chemistry/1803_Morning/1803_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <!--       Mathematics March 2021              -->
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (1603 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/MARCH/Mathematics/1603_Evening/1603_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (1603 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/MARCH/Mathematics/1603_Morning/1603_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (1703 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/MARCH/Mathematics/1703_Evening/1703_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (1703 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/MARCH/Mathematics/1703_Morning/1703_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (1803 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/MARCH/Mathematics/1803_Evening/1803_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2021.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (1803 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2021/MARCH/Mathematics/1803_Morning/1803_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2021.pdf">Download</a></td>
                                </tr>
                             </tbody>
                          </table>
                       </section>
                       <section class="bg-1">
                          <h2>JEE MAIN 2020 (Jan. &amp; Sep. Attempt) Question Papers with Solutions PDF</h2>
                          <!--   Jee Main Paper  -->
                          <table class="table  ace-table" width="100%">
                             <tbody>
                                <tr>
                                   <td rowspan="2" align="center" style="vertical-align: middle;"><strong>Subject</strong></td>
                                   <td colspan="2" align="center" valign="middle"><strong>Paper With Solution</strong></td>
                                </tr>
                                <tr>
                                </tr>
                                <!--       Chemistry january 2020              -->
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (0701 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Chemistry(Eng)/0701_Evening/0701_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (0701 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Chemistry(Eng)/0701_Morning/0701_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (0801 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Chemistry(Eng)/0801_Evening/0801_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (0801 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Chemistry(Eng)/0801_Morning/0801_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (0901 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Chemistry(Eng)/0901_Evening/0901_CHEMISTRY_JAN_2020_EVENING.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (0901 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Chemistry(Eng)/0901_Morning/0901_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <!--       Maths january 2020              -->
                                <tr>
                                   <td valign="middle">Maths Previous Years’ Question Papers with Solutions PDF <b>English (0701 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Maths(Eng)/0701_Evening/0701_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Maths Previous Years’ Question Papers with Solutions PDF <b>English (0701 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Maths(Eng)/0701_Morning/0701_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Maths Previous Years’ Question Papers with Solutions PDF <b>English (0801 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Maths(Eng)/0801_Evening/0801_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Maths Previous Years’ Question Papers with Solutions PDF <b>English (0801 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Maths(Eng)/0801_Morning/0801_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Maths Previous Years’ Question Papers with Solutions PDF <b>English (0901 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Maths(Eng)/0901_Evening/0901_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Maths Previous Years’ Question Papers with Solutions PDF <b>English (0901 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Maths(Eng)/0901_Morning/0901_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <!--       Physics january 2020              -->
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (0701 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Physics(Eng)/0701_Evening/0701_JM_PHYSICS_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (0701 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Physics(Eng)/0701_Morning/0701_JM_PHYSICS_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (0801 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Physics(Eng)/0701_Evening/0701_JM_PHYSICS_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (0801 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Physics(Eng)/0801_Morning/0801_JM_PHYSICS_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (0901 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Physics(Eng)/0901_Evening/0901_JM_PHYSICS_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (0901 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/JANUARY/Physics(Eng)/0901_Morning/0901_JM_PHYSICS_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <!--    September    -->
                                <tr>
                                </tr>
                                <!--       Chemistry september 2020              -->
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (02-09-2020 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/CHEMISTRY/02-09-2020/EVENING/0209_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (02-09-2020 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/CHEMISTRY/02-09-2020/MORNING/0209_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (03-09-2020 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/CHEMISTRY/03-09-2020/EVENING/0309_JM_CHEMISTRY_PAPER_%20SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (03-09-2020 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/CHEMISTRY/03-09-2020/MORNING/0309_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (04-09-2020 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/CHEMISTRY/04-09-2020/EVENING/0409_JM_CHEMISTRY_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (04-09-2020 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/CHEMISTRY/04-09-2020/MORNING/0409_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (05-09-2020 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/CHEMISTRY/05-09-2020/EVENING/0509_JM_CHEMISTRY_PAPER_%20SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (05-09-2020 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/CHEMISTRY/05-09-2020/MORNING/0509_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (06-09-2020 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/CHEMISTRY/06-09-2020/EVENING/0609_JM_CHEMISTRY_PAPER_%20SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>English (06-09-2020 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/CHEMISTRY/06-09-2020/MORNING/0609_JM_CHEMISTRY_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <!--       Maths january 2020              -->
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (02-09-2020 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/MATHS/02-09-2020/EVENING/0209_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (02-09-2020 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/MATHS/02-09-2020/MORNING/0209_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (03-09-2020 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/MATHS/03-09-2020/EVENING/0309_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (03-09-2020 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/MATHS/03-09-2020/MORNING/0309_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (04-09-2020 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/MATHS/04-09-2020/EVENING/0409_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (04-09-2020 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/MATHS/04-09-2020/MORNING/0409_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (05-09-2020 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/MATHS/05-09-2020/EVENING/0509_JM_MATHEMATICS_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (05-09-2020 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/MATHS/05-09-2020/MORNING/0509_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (06-09-2020 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/MATHS/06-09-2020/EVENING/0609_JM_MATHEMATICS_PAPER_%20SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Mathematics Previous Years’ Question Papers with Solutions PDF <b>English (06-09-2020 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/MATHS/06-09-2020/MORNING/0609_JM_MATHEMATICS_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <!--       Physics january 2020              -->
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (02-09-2020 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/PHYSICS/02-09-2020/EVENING/0209_JM_PHYSICS_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (02-09-2020 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/PHYSICS/02-09-2020/MORNING/0209_JM_PHYSICS_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (03-09-2020 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/PHYSICS/03-09-2020/EVENING/0309_JM_PHYSICS_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (03-09-2020 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/PHYSICS/03-09-2020/MORNING/0309_JM_PHYSICS_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (04-09-2020 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/PHYSICS/04-09-2020/EVENING/0409_JM_PHYSICS_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (04-09-2020 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/PHYSICS/04-09-2020/MORNING/0409_JM_PHYSICS_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (05-09-2020 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/PHYSICS/05-09-2020/EVENING/0509_JM_PHYSICS_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (05-09-2020 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/PHYSICS/05-09-2020/MORNING/0509_JM_PHYSICS_PAPER_WITH%20SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (06-09-2020 Evening)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/PHYSICS/06-09-2020/EVENING/0609_JM_PHYSICS_PAPER_SOLUTION_EVENING_2020.pdf">Download</a></td>
                                </tr>
                                <tr>
                                   <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>English (06-09-2020 Morning)</b> </td>
                                   <td align="center" valign="middle"><a target="_blank" href="https://adpl-free-resources.allendigital.in/JEE-MAINS-2020/SEPTEMBER/PHYSICS/06-09-2020/MORNING/0609_JM_PHYSICS_PAPER_SOLUTION_MORNING_2020.pdf">Download</a></td>
                                </tr>
                             </tbody>
                          </table>
                       </section>
                       `,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )/*  : (
        <>
          <div className="tab-content" id="pills-tabContent">
            <div className=" app-features">
              <div className="container py-5">
                <div className="">
                  <h2 className="font-weight-bold text-center mb-3">
                    {PageContent.title}
                  </h2>
                  <div className="card py-5 course-card">
                    <div className="row">
                      <div className="col-12 col-lg-12 text-center">
                        {wid}
                        Please Login first
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) */}
    </>
  );
};

export default FreeResourceJeeMain;