import React from 'react';

const EcomProfileDetails = (props) => {

    let profileDetails = props.profileDetails;

    var gender;
    if(profileDetails?.student_gender_id == 111){
        gender = "Male";
    } else if(profileDetails?.student_gender_id == 112){
        gender = "Female";
    }
    else if(profileDetails?.student_gender_id == 112){
        gender = "Female";
    }

    let ch_date = '';
    if(profileDetails?.student_dob){
        let date1 = new Date(profileDetails?.student_dob);
                  const YYYY1 = date1?.getFullYear();
                  let DD1 = ("0" + (date1?.getMonth()+1)).slice(-2);
                  let MM1 = ("0" + date1?.getDate())?.slice(-2);
            ch_date= MM1+'-'+DD1+'-'+YYYY1;
    }
            
    // console.log("from details",profileDetails)
    return (
        <div className='form-group row' id="profiledetailsdiv">
            <div className='col-md-6 col-sm-10'>
                <strong>Name &nbsp;: </strong> &nbsp;{profileDetails?.student_first_name + " "+ profileDetails?.student_middle_name +" "+ profileDetails?.student_last_name }<br></br>
                <strong>Mobile &nbsp;:</strong> &nbsp;{profileDetails?.student_mobile_no}<br></br>
                <strong>DOB &nbsp;:</strong> &nbsp;{ch_date}<br></br>
                <strong>Father Name &nbsp;: </strong> &nbsp;{profileDetails?.father_name}<br></br>

            </div>
            <div className='col-md-6 col-sm-10'>
            <strong>Email &nbsp;: </strong> &nbsp;{profileDetails?.student_email}<br></br>
            <strong>Alternate Mobile &nbsp;:</strong> &nbsp;{profileDetails?.student_alternate_mobile_no}<br></br>
            <strong>Gender &nbsp;: </strong> &nbsp;{gender}<br></br>
            <strong>Mother Name &nbsp;: </strong> &nbsp;{profileDetails?.mother_name}<br></br>

            </div>
        </div>
    );
};

export default EcomProfileDetails;