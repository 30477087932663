import React from 'react';
class ArticleHeading extends React.Component {
    constructor(props) {
      super(props);
    }
    render() { 
        return <>
            <h1 className='article-heading'>{this.props.data}</h1>
        </>;
    }
}
 
export default ArticleHeading;