import React, { useState } from "react";
import FooterLogo from "../images/logo-new-r-footer.svg";
import AndroidAppIcon from "../images/google-play.png";
import IosAppIcon from "../images/app-store.png";
import SocialIconFb from "../images/facebook.png";
import SocialIconTw from "../images/twitter.png";
import SocialIconLinkIn from "../images/linkedin.png";
import SocialIconInsta from "../images/instagram.png";
import YouTubeIcon from "../images/youtube_icon.png"
// import { environment } from "../Services/HttpData";
import { Link } from "react-router-dom";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PageContent: [],
    };
  }
  async componentDidMount() {
    // POST request using fetch with async/await
    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({ keyword: "" }),
    // };
    // fetch(environment.baseUrl + "view_list_all", requestOptions)
    //   .then(async (response) => {
    //     // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
    //     const data = /* isJson && */ await response.json();
    //     // check for error response
    //     // //console.log(response);
    //     if (!response.ok) {
    //       // get error message from body or default to response status
    //       const error = (data && data.message) || response.status;
    //       return Promise.reject(error);
    //     }
    //     let index = data.data.findIndex(
    //       (item) => item.slug === "footer-bottom"
    //     );
    //     // //console.log(data.data[index]?.sections);
    //     let sections = {};
    //     data.data[index]?.sections.map((item) => {
    //       switch (item.id) {
    //         case 19:
    //           sections.logo = item;
    //           break;
    //         case 20:
    //           sections.company = item;
    //           break;
    //         case 21:
    //           sections.exams = item;
    //           break;
    //         case 22:
    //           sections.apps = item;
    //           break;
    //         case 23:
    //           sections.follow = item;
    //           break;
    //         case 125:
    //           sections.copyright = item;
    //           break;
    //       }
    //     });
    //     // //console.log(sections);
    //     this.setState({ PageContent: sections });
    //   })
    //   .catch((error) => {
    //     this.setState({ errorMessage: error.toString() });
    //     console.error("There was an error!", error);
    //   });
    const getViewListAll = () => {
      if (!window?.AllenDigitalFEBSite) {
        setTimeout(() => {
          getViewListAll();
        }, 50);
        return false
      }
      let index = window?.AllenDigitalFEBSite?.data.findIndex(
        (item) => item.slug === "footer-bottom"
      );
      // //console.log(data.data[index]?.sections);
      let sections = {};
      window?.AllenDigitalFEBSite.data[index]?.sections.map((item) => {
        switch (item.id) {
          case 19:
            sections.logo = item;
            break;
          case 20:
            sections.company = item;
            break;
          case 21:
            sections.exams = item;
            break;
          case 22:
            sections.apps = item;
            break;
          case 23:
            sections.follow = item;
            break;
          case 125:
            sections.copyright = item;
            break;
        }
      });
      // //console.log(sections);
      this.setState({ PageContent: sections });
    };
    getViewListAll();
  }
  render() {
    return (
      <>
        <footer className="footer-image">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3 col-xl-3">
                <Link to="/">
                  <img
                    src={
                      process.env.REACT_APP_CDN_URL + "uploads/footer-logo-aci.svg"
                    }
                    alt=""
                    className="img-fluid footer-logo"
                  />
                </Link>
                <div className="d-none d-md-block">
                  <h6 className="mt-3 font-weight-normal">
                    {this.state.PageContent?.apps?.title}
                  </h6>
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-row justify-content-start mb-3 footer-app-icons mr-xl-5">
                      <a
                        href={
                          this.state.PageContent?.apps?.content[0]?.subtitle
                        }
                      >
                        <img
                          src={
                            this.state.PageContent?.apps?.content[0]?.image
                              ? environment.baseUrlImg +
                              this.state.PageContent?.apps?.content[0]?.image
                              : AndroidAppIcon
                          }
                          alt=""
                          className="img-fluid app-size"
                        />
                      </a>
                      <a
                        href={
                          this.state.PageContent?.apps?.content[1]?.subtitle
                        }
                      >
                        <img
                          src={
                            this.state.PageContent?.apps?.content[1]?.image
                              ? environment.baseUrlImg +
                              this.state.PageContent?.apps?.content[1]?.image
                              : IosAppIcon
                          }
                          alt=""
                          className="img-fluid app-size"
                        />
                      </a>
                      {/* <h2 className="coming-soon">Coming Soon</h2> */}
                    </div>
                    <div className="social-flex">
                      <h6 className="font-weight-normal">{this.state.PageContent?.follow?.title}</h6>
                      <div className="d-flex footer-social">
                        <a href='https://www.youtube.com/@ALLENDigital' target="_blank">
                          <img src={YouTubeIcon} alt="YouTube" className="img-fluid icon-size" /></a>
                        {this.state.PageContent?.follow?.content?.length !== 0 &&
                          this.state.PageContent?.follow?.content?.map((item) => (
                            <a href={item?.subtitle} target={'_blank'} key={item?.id}><img src={environment.baseUrlImg + item.image} alt={item.title} className="img-fluid icon-size" /></a>
                          ))}

                        {/* <a
                        href="https://www.facebook.com/WeAreADPL/"
                        target="_blank"
                      >
                        <img src={SocialIconFb} alt="" className="img-fluid" />
                      </a>
                      <a href="https://twitter.com/weareadpl" target="_blank">
                        <img src={SocialIconTw} alt="" className="img-fluid" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/weareadpl"
                        target="_blank"
                      >
                        <img
                          src={SocialIconLinkIn}
                          alt=""
                          className="img-fluid"
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/weareadpl/"
                        target="_blank"
                      >
                        <img
                          src={SocialIconInsta}
                          alt=""
                          className="img-fluid"
                        />
                      </a> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <ul>
                                {this.state.PageContent.length !== 0 && this.state.PageContent.map(item => (
                                    <>
                                        <li>{item?.title}</li>
                                    </>
                                    ))}
                                </ul> */}
              </div>
              <div className="mt-3 mt-md-0 col-12 col-md-6 col-lg-3 col-xl-3">
                <h5 className="mb-1 mb-md-3">
                  {this.state.PageContent?.company?.title}
                </h5>
                <div className="d-flex flex-column footer-mobile-links">
                  {/*  {this.state.PageContent?.company?.content?.length !== 0 &&
                      this.state.PageContent?.company?.content?.map((item) => (
                        item?.subtitle === "https://www.allen.ac.in/" ?
                          <a href="https://www.allen.ac.in/" key={item?.id} id="1">{item?.title}</a>
                          :
                          <Link to={item?.subtitle} key={item?.id}>{item?.title}</Link>
                      ))} */}
                  <a href="/about-us">About Us</a>
                  <a href="https://www.allen.ac.in/" target="_blank" >ALLEN Career Institute</a>
                  <a href="/app-features">App Features</a>
                  <a href="/jobs">Work With Us</a>
                  <a href="https://cdn.digital.allen.ac.in/fe-bsite/ALLEN-Material-Copyright-Trademark-Public-Notice.pdf" target="_blank">Public Notice</a>
                  <a href="/contact-us">Contact Us</a>
                </div>
              </div>

              <div className="mt-3 mt-lg-0 col-12 col-md-6 col-lg-3 col-xl-3">
                <h5 className="mb-1 mb-md-3">
                  Policies
                </h5>
                <div className="d-flex flex-column footer-mobile-links">
                  {/*  {this.state.PageContent?.company?.content?.length !== 0 &&
                      this.state.PageContent?.company?.content?.map((item) => (
                        item?.subtitle === "https://www.allen.ac.in/" ?
                          <a href="https://www.allen.ac.in/" key={item?.id} id="1">{item?.title}</a>
                          :
                          <Link to={item?.subtitle} key={item?.id}>{item?.title}</Link>
                      ))} */}
                  <a href="/t&c">Terms & Conditions</a>
                  <a href="/policies">Privacy Policy</a>

                  <a href="/refund-policy">Refund Policy</a>
                  <a href="/transfer-policies">Transfer Policies</a>

                </div>
              </div>
              <div className="mt-3 mt-lg-0 col-12 col-md-6 col-lg-3 col-xl-3">
                <h5 className="mb-2 mb-md-3">
                  {this.state.PageContent?.exams?.title}
                </h5>
                <div className="d-flex flex-column">
                  {/* {this.state.PageContent?.exams?.content?.length !== 0 &&
                      this.state.PageContent?.exams?.content?.map((item) => (
                          <Link to={item?.subtitle} key={item?.id}>{item?.title}</Link>
                      ))} */}

                  <a href="tel:+ 919513736499" className="support-flex"><i className="fa-solid fa-phone"></i>
                    <div className="div">+ 91-9513736499<br />
                      <span style={{ fontSize: "11px" }}>( 08:00 AM to 10:00 PM )</span>
                    </div>
                  </a>
                  <a href="https://wa.me/+917849901001" className="support-flex"><i className="fa-brands fa-whatsapp"></i> + 91-7849901001</a>
                  <a href="mailto:wecare@allendigital.in" className="support-flex"><i className="fa-solid fa-envelope"></i> wecare@allendigital.in</a>

                </div>
              </div>

              <div className="mt-3 mt-lg-0 col-12 col-md-6 col-lg-3 col-xl-3 d-md-none">
                <h6>
                  {this.state.PageContent?.apps?.title}
                </h6>
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row justify-content-start mb-3 footer-app-icons mr-xl-5">
                    <a
                      href={
                        this.state.PageContent?.apps?.content[0]?.subtitle
                      }
                    >
                      <img
                        src={
                          this.state.PageContent?.apps?.content[0]?.image
                            ? environment.baseUrlImg +
                            this.state.PageContent?.apps?.content[0]?.image
                            : AndroidAppIcon
                        }
                        alt=""
                        className="img-fluid app-size"
                      />
                    </a>
                    <a
                      href={
                        this.state.PageContent?.apps?.content[1]?.subtitle
                      }
                    >
                      <img
                        src={
                          this.state.PageContent?.apps?.content[1]?.image
                            ? environment.baseUrlImg +
                            this.state.PageContent?.apps?.content[1]?.image
                            : IosAppIcon
                        }
                        alt=""
                        className="img-fluid app-size"
                      />
                    </a>
                    {/* <h2 className="coming-soon">Coming Soon</h2> */}
                  </div>
                  <div className="social-flex">
                    <h6>{this.state.PageContent?.follow?.title}</h6>
                    <div className="d-flex footer-social">
                      <a href='https://www.youtube.com/@ALLENDigital' target="_blank">
                        <img src={YouTubeIcon} alt="YouTube" className="img-fluid icon-size" /></a>
                      {this.state.PageContent?.follow?.content?.length !== 0 &&
                        this.state.PageContent?.follow?.content?.map((item) => (
                          <a href={item?.subtitle} target={'_blank'} key={item?.id}><img src={environment.baseUrlImg + item.image} alt={item.title} className="img-fluid icon-size" /></a>
                        ))}

                      {/* <a
                        href="https://www.facebook.com/WeAreADPL/"
                        target="_blank"
                      >
                        <img src={SocialIconFb} alt="" className="img-fluid" />
                      </a>
                      <a href="https://twitter.com/weareadpl" target="_blank">
                        <img src={SocialIconTw} alt="" className="img-fluid" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/weareadpl"
                        target="_blank"
                      >
                        <img
                          src={SocialIconLinkIn}
                          alt=""
                          className="img-fluid"
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/weareadpl/"
                        target="_blank"
                      >
                        <img
                          src={SocialIconInsta}
                          alt=""
                          className="img-fluid"
                        />
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="my-3 col-12 col-md-6 col-lg-3">
                
                </div> */}
            </div>
            <div >
              <p className="footer-addr text-center mt-4">
                <div className="text">Registered &amp; Corporate Office : <br className="d-xl-none" /><b>ALLEN Career Institute Private Limited</b> <br class="d-md-none" /> (CIN: U80100RJ2021PTC077131),<br class="d-md-none" /> “SANKALP”, CP-6, Indra Vihar, Kota (Rajasthan) - 324005</div>
              </p>
            </div>
          </div>

          <div
            className="mt-4"
            style={{ backgroundColor: "#e6f7fd", padding: 15 + "px" }}
          >
            <div className="container bottom-footer">


              <div className="row">
                <div className="col-md-12">
                  <h6
                    className="text-center p-0 m-0"
                    style={{ color: "#000" }}
                  >
                    {this.state.PageContent?.copyright?.title}
                  </h6>
                </div>
              </div>

            </div>



          </div>
        </footer>

        {/* <!-- Optional JavaScript --> */}
        {/* <!-- jQuery first, then Popper.js, then Bootstrap JS --> */}
        {/* <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
            <script src="https://code.jquery.com/jquery-3.2.1.min.js"></script>*/}
      </>
    );
  }
}

export default Footer;
