import React, { Component } from "react";
import { withRouter } from "react-router";
import AboutTabContents from "../components/AboutUs/AboutTabsContents";
import AboutUsValuChain from "../components/AboutUs/AboutUsValuChain";
import AboutUsBanner from "../components/AboutUs/AoutusBanner";
import "../css/about-us.css";
// import { environment } from '../Services/HttpData';
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);
class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "about-introduction",
      PageContent: [],
    };
  }
  /* componentDidMount() {
        const id = this.props.match.params.id;
        this.fetchData(id);
    } */

  async componentDidMount() {
    // POST request using fetch with async/await
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "keyword": ''})
    // };
    // fetch(environment.baseUrl+'view_list_all', requestOptions)
    // .then(async response => {
    //     // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
    //     const data = /* isJson && */ await response.json();
    //     // check for error response
    //     // //console.log('about-us',data);
    //     if (!response.ok) {
    //         // get error message from body or default to response status
    //         const error = (data && data.message) || response.status;
    //         return Promise.reject(error);
    //     }
    //     let index=data.data.findIndex((item)=>item.slug==="about-us");
    //     // //console.log('About US',data.data[index]);
    //     let sections = {all:[]};
    //     data.data[index]?.sections.map((item) => {
    //      if(item.id == 127){
    //          sections.intro = item
    //      }
    //      else if(item.id == 131){
    //          sections.chain = item
    //      }
    //      else{
    //         sections.all.push(item);
    //      }
    //     });
    //     this.setState({ PageContent: sections });
    //     if(this.props.match.params.id!=undefined){
    //         this.setState({selectedTab:this.props.match.params.id})
    //         // //console.log(this.state.selectedTab);
    //     }
    //     // //console.log(this.state.PageContent);
    // })
    // .catch(error => {
    //     this.setState({ errorMessage: error.toString() });
    //     console.error('There was an error!', error);
    // });

    const getViewListAll = () => {
      if (!window?.AllenDigitalFEBSite) {
        setTimeout(() => {
          getViewListAll();
        }, 50);
        return false;
      }
      let index = window?.AllenDigitalFEBSite.data.findIndex(
        (item) => item.slug === "about-us"
      );
      // //console.log('About US',data.data[index]);
      let sections = { all: [] };
      window?.AllenDigitalFEBSite.data[index]?.sections.map((item) => {
        if (item.id == 127) {
          sections.intro = item;
        } else if (item.id == 131) {
          sections.chain = item;
        } else {
          sections.all.push(item);
        }
      });

      this.setState({ PageContent: sections });
      const PageTitalArray = ['about-introduction', 'an-enduring-foundation', 'directors-message', 'academic-strength', 'teaching-methodology', "CSR", "child-safety"]
      if (this.props.match.params.id != undefined) {
        if (!PageTitalArray.includes(this.props.match.params.id)) {
          window.location.href = "/about-us";
        }
        this.setState({ selectedTab: this.props.match.params.id });
        // //console.log(this.state.selectedTab);
      }
    };
    getViewListAll();
  }


  render() {

    return (
      <>
        <Helmet>
          {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
          <link rel="canonical" href={window.location.origin + window.location.pathname} />
          <title>About Us | ALLEN Digital</title>
          <meta name="description" content="At ALLEN Digital, we're on a mission to transform the education landscape with our online programs, world-class faculty and innovative technology." />
          <meta property="og:title" content="About Us | ALLEN Digital" />
          <meta property="og:site_name" content="ALLEN Digital" />
          <meta property="og:url" content="https://digital.allen.ac.in/about-us" />
          <meta property="og:description" content="At ALLEN Digital, we're on a mission to transform the education landscape with our online programs, world-class faculty and innovative technology." />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://digital.allen.ac.in/static/media/intro.2abf5c59166cf8a87332.jpg" />
        </Helmet>
        <AboutUsBanner
          handelEvent={this.state.PageContent?.intro}
          slug={this.state.selectedTab}
        />
        <AboutTabContents
          handelEvent={this.state.PageContent?.all}
          slug={this.state.selectedTab}
        />
        <AboutUsValuChain handelEvent={this.state.PageContent?.chain} />
      </>
    );
  }
}

export default withRouter(AboutUs); // default AboutUs;
