import React from 'react'
import videoicon from "../../images/video.png";
import documenticon from "../../images/document.png";
import clockicon from "../../images/clock.png";
import certificateicon from "../../images/certificate.png";
import charticon from "../../images/chart.png";
import documentblankicon from "../../images/document-blank.png";
import languageicon from "../../images/language.png";
import bookicon from "../../images/book.png";
import { convertSecondsToReadableString } from '../../Services/courseService';
// import { environment } from './../../Services/HttpData';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { encode as base64_encode } from "base-64";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class RightSidenav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detailsData: null,
            intersetedCoursesList: []
        };
    }
    componentWillReceiveProps(props) {
        this.setState({ detailsData: props.handelEvent });
        //console.log(props.handelEvent);
        if(props.handelEvent){
            if (props.handelEvent.identifier !== undefined && localStorage.getItem("userWID") !== null)
                this.getCoursDetails(props.handelEvent.identifier, props.handelEvent.learningMode);
        }
        
    }
    /* componentWillReceiveProps(props) {
    //   this.setState({ detailsData: props.handelEvent });
      //console.log(props.handelEvent);
      this.getCoursDetails(props.handelEvent);
    } */

    getPercantage(price, total) {
        return 100 - (price / total) * 100;
    }
    async getCoursDetails(id, mode = "") {

        return;
        // POST request using fetch with async/await
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("apiAccessToken")
            },
            body: JSON.stringify({
                wid: JSON.parse(localStorage.getItem("userWID")).results[0].wid,
                learningMode: mode
            }),
        };
        fetch(environment.baseUrl + "courseMayInterested", requestOptions)
            .then(async (response) => {
                // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
                const data = /* isJson && */ await response.json();
                // check for error response
                //console.log(response);
                if (!response.ok) {
                    if (data.status === 401) {
                        await this.fetchToken();
                        await this.getCoursDetails(id);
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    } else {
                        //console.log("ERROR Come");
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }
                }
                if (data.status === 200) {
                    //console.log(data.body);
                    this.setState({ intersetedCoursesList: data.body.result.response });
                    //   localStorage.setItem("intersetedCoursesList", JSON.stringify(data.body));
                } else {
                    toast.error(data.body.message);
                }
                // //console.log(data);
            })
            .catch((error) => {
                console.error("There was an error!", error);
            });
    }
    addToCart(item, buyNow = false){
      //console.log(item);
    const skvDetails = JSON.parse(sessionStorage.getItem("skavaTokenDetails"));
    // //console.log(skvDetails.sessionId);
    if(skvDetails!==null && skvDetails.sessionId!==undefined){
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apiKey: skvDetails.sessionId,
      },
      body:JSON.stringify({
        "cartItem": [
            {
                "quantity": 1,
                "skus": [
                    {
                        "productId": item.productId,
                        "skuId": item.productId,
                        "type": "DEFAULT"
                    }
                ],
                "properties": {
                    "imageURL": item.appIcon,
                    "name": item.name,
                    "link": `${process.env.REACT_APP_LMS_URL}en/app/toc/${item.identifier}/overview`,
                    "fulfillmentType": "DIGITAL"
                }
            }
        ],
        "properties": {
            "enquiryId": "",
            "callerRef": "business"
        }
    })
    };
    fetch(environment.baseUrl + "addToCartSkSite", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data = /* isJson && */ await response.json();
        // check for error response
        // //console.log(response);
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        if (response.ok) {
            if(response.status===201){
            toast.success("Item Added to Cart");
            this.props.onChange("reloadCart");
            if (buyNow)
              window.location = environment.skavaBaseUrl + "checkout?return_url=" + environment.REACT_APP_BASE_URL
          }
          else{
            toast.error(data.message);
          }
            // this.setState({cartItems:data.items,cartDetails:{value:data.value,currencySign:data.currencySign}})
          //console.log(data);
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: error.toString() });
        console.error("There was an error!", error);
      });
      }
      else{
        toast.warning("Please login first.");
      }
    }
    render() {
        return <>
            <nav className="col-md-3 d-none d-md-block sidebar ">
                <div className="sidebar-sticky">
                    <ul className="nav flex-column p-2">
                        <li className="nav-item prerequisite-knowledge d-none">
                            <div className="card p-3 course-card">
                                <h5 className="font-weight-bold">Prerequisite Knowledge</h5>
                                <ul className="nav flex-column p-2 ">
                                    <li className="nav-item mb-3 d-flex"><i className="fas fa-circle"></i>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                    <li className="nav-item mb-3 d-flex"><i className="fas fa-circle"></i>Fusce porta sem vel orci interdum faucibus.</li>
                                    <li className="nav-item mb-3 d-flex"><i className="fas fa-circle"></i>Maecenas et mauris ut ex hendrerit blandit in ac augue.</li>
                                    <li className="nav-item mb-3 d-flex"><i className="fas fa-circle"></i>Vestibulum ut metus a mauris ullamcorper volutpat.</li>
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item mb-3">
                            <div className="card p-3 course-card">
                                <h5 className="font-weight-bold">At a Glance</h5>
                                <ul className="nav flex-column p-2">
                                    {/* <li className="nav-item mb-3"><img className="mr-2" src={documenticon} />21 Test Series</li>
                                    {this.state.detailsData?.duration && (<li className="nav-item mb-3"><img className="mr-2" src={videoicon} /> {convertSecondsToReadableString(this.state.detailsData?.duration)}</li>)}
                                            <li className="nav-item mb-3"><img className="mr-2" src={clockicon} />Lifetime Access</li> */}
                                    {this.state.detailsData?.complexityLevel && (<li className="nav-item mb-3"><img className="mr-2" src={charticon} />{this.state.detailsData?.complexityLevel} Level</li>)}
                                    {this.state.detailsData?.category && (<li className="nav-item mb-3"><img className="mr-2" src={documentblankicon} /> {this.state.detailsData?.category}</li>)}
                                    {this.state.detailsData?.categoryType && (<li className="nav-item mb-3"><img className="mr-2" src={certificateicon} />{this.state.detailsData?.categoryType}</li>)}
                                    {/* <li className="nav-item mb-3"><img className="mr-2" src={languageicon} />Available In Hindi, English</li>
                                            <li className="nav-item mb-3"><img className="mr-2" src={bookicon} />IIT-JEE, BITSAT, UPSC, AIEEE</li> */}
                                </ul>
                            </div>
                        </li>
                        {this.state.intersetedCoursesList.length>0 && (<li className="nav-item mb-3 glance-section ">
                            <div className="card p-3 course-card">
                                <h5 className="font-weight-bold">Recommended Courses</h5>

                                {this.state.intersetedCoursesList && this.state.intersetedCoursesList.map((item, indx) => (
                                    <div className="card col-lg-3 col-md-4 p-0 col-sm-12 news-card-buy" key={indx}>
                                        <div className="img">
                                            <Link
                                                to={`/courses-details/${encodeURIComponent(
                                                    item?.name.toLowerCase()
                                                ).replace("%20", "+")}/${base64_encode(
                                                    item?.identifier
                                                )}`}
                                            >
                                                <img src={item?.appIcon} alt={item?.name} className="img-fluid w-100 mb-2" />
                                            </Link>
                                        </div>
                                        <div className="card-content p-2">
                                            <Link
                                                to={`/courses-details/${encodeURIComponent(
                                                    item?.name.toLowerCase()
                                                ).replace("%20", "+")}/${base64_encode(
                                                    item?.identifier
                                                )}`}
                                            >
                                                <p>{item?.name}</p>
                                            </Link>
                                            <div className="d-flex justify-content-start">
                                                <div>
                                                    <h5>{item?.salesPrice?.currency === "INR"
                                                        ? "₹"
                                                        : item?.salesPrice?.currency}
                                                        {item?.salesPrice?.value}</h5>
                                                </div>
                                                <div className="ml-2">
                                                    <h5 className="text-danger"><s>{item?.price?.currency === "INR"
                                                        ? "₹"
                                                        : item?.price?.currency}
                                                        {item?.price?.value}</s></h5>
                                                </div>
                                            </div>
                                            <p className="my-0" style={{ color: "#046635" }}>Save {item?.price?.currency === "INR"
                                                ? "₹"
                                                : item?.price?.currency}
                                                {item?.price?.value - item?.salesPrice?.value} (
                                                {this.getPercantage(
                                                    item?.salesPrice?.value,
                                                    item?.price?.value
                                                ).toFixed(2)}
                                                % OFF)</p>
                                            <div className="row mx-0 mt-2 justify-content-between">
                                                <p className="mr-1">Live Class</p>
                                                <p className="mr-1">Test Series</p>
                                                <p className="mr-1">Online Test</p>
                                            </div>
                                            <div className="d-flex flex-wrap justify-content-between">
                                                <a className="nav-link p-0 mb-2" href="#"><button type="button"
                                                    className="btn btn-outline-primary nav-btn-outline"
                                                    style={{ maxwidth: "150px", fontsize: "13px" }} onClick={() => this.addToCart(item,true)}>Buy Now</button></a>
                                                <a className="nav-link p-0 mb-2" onClick={() => this.addToCart(item)}><button type="button"
                                                    className="btn btn-primary nav-btn" style={{ maxwidth: "150px", fontsize: "13px" }}>Add
                                                    to Bag</button></a>
                                            </div>
                                        </div>
                                    </div>)
                                )}
                                {/* <ul className="nav flex-column p-2 ">
                                            <li className="nav-item mb-3"><i  className="fas fa-circle"></i>IIT JEE</li>
                                            <li className="nav-item mb-3"><i className="fas fa-circle"></i>Leader - JEE Main 2023 -  Self paced</li>
                                            <li className="nav-item mb-3"><i className="fas fa-circle"></i>Lifetime Access</li>
                                        </ul> */}
                            </div>
                        </li>)}
                    </ul>
                </div>
            </nav>
        </>;
    }
}

export default RightSidenav;