import React ,{useEffect, useState} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const HomePageSlider = () => {

    const getFunction = (functionName, click_url) => {
        if (functionName == "goClickUrl") {
          window.location = click_url;
        } else if (functionName == "target_blank") {
          window.open(click_url);
        } else {
          callThisFunctionForFun(functionName);
        }
      };
    
      // This function is used for anchor link
      const callThisFunctionForFun = (currentPath) => {
        let lastPath = window.location.pathname;
        if (lastPath == "/") {
          if (document.getElementById(currentPath) != null) {
            document.getElementById(currentPath)?.scrollIntoView();
          }
        } else {
          window.location = "/#" + currentPath;
        }
      };

      const [sliderData,setsliderData] = useState([])

    function getTimeInMiliSeconds(splitTime) {
        var timeInString = splitTime; // Any value here
        var milliseconds;
        if (timeInString.split(":").length === 2) {
          /* For MM:SS */
          milliseconds =
            Number(timeInString.split(":")[0]) * 60000 +
            Number(timeInString.split(":")[1]) * 1000;
        } else if (timeInString.split(":").length === 3) {
          /* For HH:MM:SS */
          milliseconds =
            Number(timeInString.split(":")[0]) * 3600000 +
            Number(timeInString.split(":")[1]) * 60000 +
            Number(timeInString.split(":")[2]) * 1000;
        } else if (timeInString.split(":").length === 4) {
          /* For DD:HH:MM:SS */
          milliseconds =
            Number(timeInString.split(":")[0]) * 86400000 +
            Number(timeInString.split(":")[1]) * 3600000 +
            Number(timeInString.split(":")[2]) * 60000 +
            Number(timeInString.split(":")[3]) * 1000;
        }
        return milliseconds;
      }

      function getSliderData() {
        if (window?.sliderHome) {
          let tempHome = window?.sliderHome;
          let current_Date = new Date();
          let customizedSlider = [];
          let currentTimeInMS = current_Date?.getTime();
          tempHome.forEach((slider, i) => {

              // for validity
            let splitDate = slider?.validity?.split(" ")[0];
            let dateInMS = new Date(splitDate)?.getTime();
            let splitTime = slider?.validity?.split(" ")[1];
            let hoursInMS = getTimeInMiliSeconds(splitTime);
            let gmtHoursInMS = getTimeInMiliSeconds("05:30:00");
            let completeValidityInMS = dateInMS + hoursInMS;
            completeValidityInMS = completeValidityInMS - gmtHoursInMS;

            // for start date
            let splitDateStart = slider?.startDateTime?.split(" ")[0];
            let dateInMSStart = new Date(splitDateStart)?.getTime();
            let splitTimeStart = slider?.startDateTime?.split(" ")[1];
            let hoursInMSStart = getTimeInMiliSeconds(splitTimeStart);
            let gmtHoursInMSStart = getTimeInMiliSeconds("05:30:00");
            let completeValidityInMSStart = dateInMSStart + hoursInMSStart;
            completeValidityInMSStart = completeValidityInMSStart - gmtHoursInMSStart;
            if (completeValidityInMS >= currentTimeInMS && completeValidityInMSStart < currentTimeInMS) {
              customizedSlider.push(slider);
            }
            // if (completeValidityInMS >= currentTimeInMS) {
            //   customizedSlider.push(slider);
            // }
          });
          setsliderData(customizedSlider);
        } else {
          setTimeout(() => {
            getSliderData();
          }, 100);
        }
      }

    // function getSliderData(){
    //     if(window?.sliderHome){
    //         set_sliderData(window?.sliderHome)
    //     }else{
    //        setTimeout(()=>{
    //         getSliderData();
    //        },100)
    //     }
    // }

    useEffect(()=>{
        getSliderData()
    },[])

    return (
        <>
          {/* Desktop */}
          <div className="home-page-slider homedesktopcarousel">
            <OwlCarousel
              id="Desktopbannerslider"
              items="1"
              autoplay="true"
              loop={true}
              autoplayTimeout="3000"
              autoplayHoverPause="true"
              item="1"
              dots="true"
              nav="true"
              touchDrag={true}
              mouseDrag={true}
            >
              {sliderData.map((data, i) => {
                return (
                  <>
                      <div
                      key={i}
                        className="bannerslider"
                        onClick={() =>
                          getFunction(data?.functionName, data?.click_url)
                        }
                      >
                        {data?.is_click ? (
                         <a href={data?.click_url} target={data?.functionName == "target_blank" ? "_blank" : ""} rel="noreferrer">
                         <img src={data?.sliderDesktopUrl} alt="banner" loading={data.lazy?"lazy":""}/>
                       </a>
                        ) : (
                          <img src={data?.sliderDesktopUrl} alt="banner" loading={data.lazy?"lazy":""}/>
                        )}
                      </div>
                  </>
                );
              })}
            </OwlCarousel>
          </div>
          {/* Desktop End */}
    
          {/* Tablet */}
          <div className="home-page-slider hometabletcarousel">
            <OwlCarousel
              id="Tabletbannerslider"
              items="1"
              autoplay="true"
              loop={true}
              autoplayTimeout="3000"
              autoplayHoverPause="true"
              item="1"
              dots="true"
              nav="true"
              touchDrag={true}
              mouseDrag={true}
            >
              {sliderData.map((data, i) => {
                return (
                  <>
                    <div
                    key={i}
                      className="bannerslider"
                      onClick={() => getFunction(data?.functionName)}
                    >
                      {data?.is_click ? (
                         <a href={data?.click_url} target={data?.functionName == "target_blank" ? "_blank" : ""} rel="noreferrer">
                         <img src={data?.sliderTabletUrl} alt="banner" loading={data.lazy?"lazy":""}/>
                        </a>
                      ) : (
                        <img src={data?.sliderTabletUrl} alt="banner" loading={data.lazy?"lazy":""}/>
                      )}
                    </div>
                  </>
                );
              })}
            </OwlCarousel>
          </div>
          {/* Tablet End */}
    
          {/* Mobile */}
          <div className="home-page-slider homemobilecarousel">
            <OwlCarousel
              id="Mobilebannerslider"
              items="1"
              autoplay="true"
              loop={true}
              autoplayTimeout="3000"
              autoplayHoverPause="true"
              item="1"
              dots="true"
              nav="true"
              touchDrag={true}
              mouseDrag={true}
            >
              {sliderData.map((data, i) => {
                return (
                  <>
                    <div
                    key={i}
                      className="bannerslider"
                      onClick={() => getFunction(data?.functionName)}
                    >
                      {data?.is_click ? (
                         <a href={data?.click_url} target={data?.functionName == "target_blank" ? "_blank" : ""} rel="noreferrer">
                         <img src={data?.sliderMobileUrl} alt="banner" loading={data.lazy?"lazy":""}/>
                        </a>
                      ) : (
                        <img src={data?.sliderMobileUrl} alt="banner" loading={data.lazy?"lazy":""}/>
                      )}
                    </div>
                  </>
                );
              })}
            </OwlCarousel>
          </div>
          {/* Mobile End */}
        </>
      );
};

export default HomePageSlider;