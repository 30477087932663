import React from 'react'

const EcomCourseBox = (props) => {
  return (
    <>
            <div className="card px-4 py-2 mb-4 course-card" key={props.id}>
  <div className="row">
    <div className="col-md-4">
      <img
        src={props.img}
        alt=""
        width="100%"
      />
    </div>
    <div className="col-md-8">
      <a
        href={"/courses-details/" + props.course_slug}
        className="cart-list-title"
        >{props.courseName}</a>
      {/* <p
        className="lessPtag"
        title="A Course to Offer Seamless and Fun Learning Experience to NEET Aspirants Under the Guidance of India’s Best Faculties"
      >
        <span  className="default-hide"
          >A Course to Offer Seamless and Fun Learning Experience to NEET
          Aspirants Under the Guidance of India’s Best Faculties</span><span id="dots-292666f2ce38d3461b21ec0f042f435b" className=""
          >A Course to Offer Seamless and Fun Learning Experience to NEET
                Aspirants Under the Guidance of India’s Best Faculties...
              </span>
      </p> */}
      <p className="cart-list-desc p-0 m-0">Date : {props.date}</p>
      <p className="cart-list-price-order p-0 m-0">
      Price : ₹ {props.netAmount}
      </p>
      {/* <p className="cart-list-price-order p-0 m-0">
      {props.refund_status}
      </p> */}
      <div className="third-box-cnt">
        <div className="d-flex justify-content-start">
          <span className="full-course-order-list2" type="button"
            >
            
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
    </>
  )
}

export default EcomCourseBox