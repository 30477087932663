import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo-new-r.svg";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { validationFunction } from "../Ecom/ValidationHelper";
import MixpanelInit from "../Mixpanel/MixpanelInit";
import Select from "react-select";
import Modal from "react-modal";

const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);
var b = 60;
var setIntervalFunction;
export default function Singup(props) {
	const history = useHistory();
	const AuthToken = props?.location?.state ? props?.location?.state : "";
	const [state, setState] = useState([]);
	const [city, setCity] = useState([]);
	const [classListData, setClassListdata] = useState([]);
	const [IsButton, setIsButton] = useState(false);
	const [userDetails, setUserDetails] = useState({
		student_first_name: "",
		student_middle_name: "",
		student_last_name: "",
		student_email: "",
		student_state: "",
		student_city: "",
		student_class: "",
	});
	const [citySelectValue, setCitySelectValue] = useState("");
	const [getOpen, setOpen] = useState(false);
	const [isEmailOTPSent, setEmailOTPSent] = useState(false);
	const [isEmailOtp, setEmailOtp] = useState("");
	const [buttonEmailName, setbuttonEmailName] = useState("Send OTP");
	const [isEmailOTPReSent, setEmailOTPReSent] = useState(false);
	const [getTimeInSecEmail, setTimeInSecEmail] = useState("");
	const [VerifiedEmailID, SetVerifiedEmailID] = useState("")
	const [sendOtpButton, setsendOtpButton] = useState(false)

	const GetUserData = (e, type) => {
		const { name, value } = e.target;
		setUserDetails(pre => ({ ...pre, [type]: value }));
		if (type === "student_state" && value !== "") {
			gitCity(value);
		}
		if (type === "student_state" && value === "") {
			setCity([]);
			setUserDetails(pre => ({ ...pre, ["student_state"]: "" }));
		}
	};



	const SubmitFormData = e => {
		e.preventDefault();
		if (
			userDetails?.student_first_name?.trim()?.length < 2 ||
			!/^[A-Za-z\s]*$/.test(userDetails?.student_first_name)
		) {
			toast.warning("Please Enter Your Valid First Name. Special characters, numbers and spaces are not allowed.");
			return;
		}
		if (!/^[A-Za-z\s]*$/.test(userDetails?.student_middle_name)) {
			toast.warning("Please Enter Your Valid Middle Name. Special characters, numbers and spaces are not allowed.");
			return;
		}
		if (!/^[A-Za-z\s]*$/.test(userDetails?.student_last_name)) {
			toast.warning("Please Enter Your Valid Last Name. Special characters, numbers and spaces are not allowed.");
			return;
		}
		if (
			userDetails?.student_email?.trim().length &&
			!validationFunction?.validateUserFields("email", userDetails?.student_email)
		) {
			toast.error("Enter Valid Email Address");
			return false;
		}

		// if (userDetails?.student_email?.trim()?.length < 7) {
		//   toast.warning("Please Enter email Name")
		//   return
		// }
		if (userDetails?.student_state === "") {
			toast.warning("Please Select State");
			return;
		}

		if (
			userDetails?.student_email?.trim().length &&
			validationFunction?.validateUserFields("email", userDetails?.student_email) && buttonEmailName !== "Verified" || userDetails?.student_email?.trim().length &&
			validationFunction?.validateUserFields("email", userDetails?.student_email) && VerifiedEmailID !== userDetails?.student_email
		) {
			clearMobileEmailOTPData()
			setOpen(true)
		} else {
			setIsButton(true);
			makeProfile()
		}

	};

	const makeProfile = (e) => {
		setIsButton(true);
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + AuthToken?.token);
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: JSON.stringify(userDetails),
			redirect: "follow",
		};

		fetch(process.env.REACT_APP_LOGIN_URL + "auth/add-profile", requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result?.status === "success") {
					// MixpanelInit("Identify", "$identify",result?.data?.form_no);
					const stateName = state?.filter(val => val?.state_id === userDetails?.student_state);
					MixpanelInit("Track", "login_successful", "");
					MixpanelInit("Identify", "$identify", AuthToken?.mobile_no);
					MixpanelInit("People_Set_Once", "login_successful", AuthToken?.mobile_no);
					MixpanelInit("Track", "personal_information_added", "");
					MixpanelInit("People_Set", "personal_information_added", {
						$name: `${userDetails?.student_first_name +
							" " +
							userDetails?.student_middle_name +
							" " +
							userDetails?.student_last_name
							}`,
						state: stateName[0]?.state_name,
						email_id: userDetails?.email_id,
					});

					toast.success(result?.message);
					window.localStorage.setItem("apiAccessToken", result?.data?.token);
					window.localStorage.setItem("IS_DASHBOARD_ICON", true);
					window.localStorage.setItem("FORM_NO", AuthToken?.mobile_no);
					window.localStorage.setItem("FORM_ID", result?.data?.form_no);
					setIsButton(false);
					window.location = props?.location?.state?.previous_Path ? props?.location?.state?.previous_Path == "/login" ? "/" : props?.location?.state?.previous_Path == "/user-details" ? "/" : props?.location?.state?.previous_Path == "/sign-up" ? "/" : props?.location?.state?.previous_Path : "/";
				} else {
					setIsButton(false);
					toast.warning(result?.message);
				}
			})
			.catch(error => {
				setIsButton(false);
				console.log("error", error);
			});
	}

	function getState(data) {
		const requestOptions2 = {
			method: "GET",
			headers: {
				Authorization: "Basic " + localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "",
			},
		};
		fetch(environment.ecomApiUrl + "app/states/" + data, requestOptions2).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				const getStateData = result?.data?.map((val) => ({
					...val, value: val?.state_id, label: val?.state_name
				}))
				setState(getStateData);
			});
		});
	}

	function gitCity(data) {
		const requestOptions2 = {
			method: "GET",
			headers: {
				Authorization: "Basic " + localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "",
			},
		};
		fetch(environment.ecomApiUrl + 'app/cities/' + data, requestOptions2).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				const getCiteAll = result?.data?.map((val) => ({ ...val, value: val?.city_id, label: val?.city_name }))
				setCity(getCiteAll);
			});
		});
	}
	function getClassList() {
		const requestOptions2 = {
			method: "GET",
			headers: {
				Authorization: "Basic " + localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "",
			},
		};

		fetch(process.env.REACT_APP_LOGIN_URL + "global/class-list", requestOptions2).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				const getClassListData = [...result.data]
				const readyCalssListData = getClassListData?.map((val) => ({
					...val, label: val?.class_name, value: val?.class_id
				}))
				setClassListdata(readyCalssListData);
			});
		});
	}



	const setTimeEmail = () => {
		setIntervalFunction = setInterval(() => {
			if (b >= 0) {
				b = +b - 1;
				setTimeInSecEmail(`Resend OTP in ${b} sec`);
			} else {
				clearInterval(setIntervalFunction)
				setEmailOTPReSent(true);
			}
		}, 1000);


	};

	const clearMobileEmailOTPData = () => {
		if (setIntervalFunction) { clearInterval(setIntervalFunction) }
		b = 0;
		setOpen(false);
		// setTimeInSec('')
		setTimeInSecEmail("");
		setEmailOTPSent(false);
		setbuttonEmailName("Send OTP");
		setEmailOtp("");
		setEmailOTPReSent(false);
	};
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			width: "32%",
			height: "100% !important",
		},
	};
	const handleEmailOtp = e => {
		const { name, value } = e.target;

		if (name === "student_mobile_no" &&
			/^[0-9]*$/.test(value) &&
			!value.includes('e') &&
			!value.includes('.') &&
			!value.includes('-') &&
			!value.includes('+')) {
			setEmailOtp(value);
		}

	};
	const sendEmailOTP = () => {
		setsendOtpButton(true);
		if (
			!validationFunction?.validateUserFields("email", userDetails?.student_email)
		) {
			toast.error("Enter Valid Email Address");
			setsendOtpButton(false);
			return false;
		}
		var reqURL;
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({}),
		};
		reqURL = process.env.REACT_APP_ECOM_URL + "otp/otp_generate_email";
		requestOptions.body = JSON.stringify({ email: userDetails?.student_email, lead_type: 914 });
		fetch(reqURL, requestOptions)
			.then(async response => {
				const data = await response.json();
				// check for error response
				// //console.log(response);
				//console.log(data);
				// if (!response.ok) {
				//   // get error message from body or default to response status
				//   const error = (data && data.message) || response.status;
				//   return Promise.reject(error);
				// }
				if (data.replyCode === "success") {
					if (setIntervalFunction) { clearInterval(setIntervalFunction) }
					b = 60;
					setTimeEmail();
					setTimeout(() => {

					}, 1000);
					setEmailOTPSent(true);
					setEmailOTPReSent(false);
					setbuttonEmailName("Verify OTP");
					toast.success(data.replyMsg);
					setsendOtpButton(false);
				} else {
					setsendOtpButton(false);
					toast.error(data.replyMsg ? data.replyMsg : data.message);
				}
			})
			.catch(error => {
				setsendOtpButton(false);
				this?.setState({ errorMessage: error.toString() });
				console.error("There was an error!", error);
			});
	};

	const verifyEmailOtp = e => {

		if (isEmailOtp.length !== 6) {
			toast.error("Please enter valid OTP")
			return
		}
		setsendOtpButton(true)
		fetch(process.env.REACT_APP_ECOM_URL + "otp/otp_verify_email", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				// 'Authorization': 'Bearer ' + user.token,
			},
			body: JSON.stringify({ email: userDetails?.student_email, otp: isEmailOtp, lead_type: 914 }),
		}).then(async response => {
			const data = await response.json();
			if (data.replyCode === "success") {
				// toast.success(data.replyMsg);
				SetVerifiedEmailID(userDetails?.student_email)
				setbuttonEmailName("Verified");
				setEmailOTPSent(false);
				setOpen(false);
				makeProfile();
				toast.success("Email OTP Verified");
				
			} else {
				toast.error("Email OTP is not valid, Please enter valid OTP")
				//toast.error(data.replyMsg);
			}
			setsendOtpButton(false)
		});
	};

	useEffect(() => {
		getState("690352c39f603fa69683af93d18a690d");
		getClassList();
	}, []);
	if (AuthToken === "") {
		history.push("/");
		return false;
	}

	return (
		<div className='container h-100 plus-container-padding login-container-wrapper'>
			<div className='row justify-content-center align-items-center width-100'>
				<div className='col col-sm-9 col-md-7 col-lg-5 col-xl-4 card p-4 m-0'>
					<Link className='m-auto' to='/'>
						<img src={Logo} alt='' className='img-fluid' />
					</Link>
					<div className='m-auto pt-3'></div>
					<div className='m-auto pt-3'>
						<h6>Profile details</h6>
					</div>
					<form onSubmit={SubmitFormData}>
						<div className='form-row'>
							<div className='form-group col-md-12'>
								<input
									type='text'
									className='form-control'
									name='student_first_name'
									id='inputPassword4'
									placeholder='First Name*'
									required
									onChange={(e) => GetUserData(e, "student_first_name")}
									maxLength='80'
									data-tip="Please enter your first name"
								/>
							</div>
						</div>
						<div className='form-row'>
							<div className='form-group col-md-6'>
								<input
									type='text'
									className='form-control'
									name='student_middle_name'
									id='inputPassword4'
									placeholder='Middle Name'
									onChange={(e) => GetUserData(e, "student_middle_name")}
									maxLength='80'
								/>
							</div>
							<div className='form-group col-md-6'>
								<input
									type='text'
									className='form-control'
									name='student_last_name'
									id='inputPassword4'
									placeholder='Last Name'
									onChange={(e) => GetUserData(e, "student_last_name")}
									maxLength='80'
								/>
							</div>
						</div>
						<div className='form-row'>
							<div className='form-group col-md-12'>
								<input
									type='email'
									className='form-control'
									name='student_email'
									id='inputPassword4'
									placeholder='Email'
									onChange={(e) => GetUserData(e, "student_email")}
									maxLength='180'
								/>
							</div>
						</div>
						{/* <div className='form-row'>
							<select className='form-control' required onChange={(e) => GetUserData(e, "student_state")}>
								<option value=''>Select State*</option>
								{state.map(val => (
									<option value={val?.state_id}>{val?.state_name}</option>
								))}
							</select>
						</div> */}
						<Select className=''

							theme={(theme) => ({
								...theme,
								borderRadius: 0,

								colors: {
									...theme.colors,
									// primary25: '#0c703f',
									primary: 'black',
								},
							})}
							placeholder="Select State*" required options={state} onChange={(e, b, c) => {
								setUserDetails(pre => ({ ...pre, ["student_state"]: e.state_id, ["student_city"]: "" }));
								setCitySelectValue("")
								gitCity(e.state_id);
							}} />
						{/* <select className='form-control mt-3' required onChange={(e) => GetUserData(e, "student_city")}>
							<option value=''>Select City*</option>
							{city.map(val => (
								<option value={val?.city_id}>{val?.city_name}</option>
							))}
						</select> */}
						{/* <div className='form-row'> */}
						<Select className=' mt-3'
							value={citySelectValue}
							theme={(theme) => ({
								...theme,
								borderRadius: 0,

								colors: {
									...theme.colors,
									// primary25: '#0c703f',
									primary: 'black',
								},
							})}
							placeholder="Select City*" required options={city} onChange={(e, b, c) => {
								setUserDetails(pre => ({ ...pre, ["student_city"]: e.city_id }));
								setCitySelectValue(e);

							}} />
						{/* </div> */}
						<Select className=' mt-3'
							theme={(theme) => ({
								...theme,
								borderRadius: 0,

								colors: {
									...theme.colors,
									// primary25: '#0c703f',
									primary: 'black',
								},
							})}
							placeholder="Select Class*" required options={classListData} onChange={(e, b, c) => {
								setUserDetails(pre => ({ ...pre, ["student_class"]: e.class_id }));

							}} />
						{/* <div className='form-row mt-3'>
							<select className='form-control' required onChange={(e) => GetUserData(e, "student_class")}>
								<option value=''>Select Class*</option>
								{classListData?.map((val) => {
									return <option value={val?.class_id}>{val?.class_name}</option>
								}
								)}
							</select>
						</div> */}
						<button className='btn btn-success form-control mt-3' disabled={IsButton}>
							Submit
						</button>
					</form>
				</div>
			</div>
			<Modal
				appElement={document.getElementById("root")}
				isOpen={getOpen}
				// onAfterOpen={afterEditOpenModal}
				// onRequestClose={onclosefunction}
				style={customStyles}
				contentLabel='Example Modal'
				id='propelldotpverify'>
				<div style={{ textAlign: "end" }}>
					<button className='btn btn-sm btn-danger mb-2' onClick={() => clearMobileEmailOTPData()}>
						X
					</button>
				</div>

				<div>
					<label htmlFor='pincode' className=' col-form-label col-lg-12 p-0'>
						Email Id *{" "}
					</label>
					<div className='col-lg-12 p-0'>
						<input
							type='text'
							className='form-control'
							id='email'
							name='student_email'
							value={userDetails?.student_email}
							disabled={true}
							placeholder=''
							// onChange={handleEvent}
							required></input>
					</div>

					{/* </div> */}

					<div className='row justify-content-center align-items-center mt-3 no-gutters'>
						{isEmailOTPSent ? (
							<>
								<div className='col-7 col-md-8 col-lg-6 col-xl-8 mob_num_otp'>
									<input
										required
										type='text'
										className='form-control otp-input'
										id='mobile_number'
										name='student_mobile_no'
										maxLength={6}
										value={isEmailOtp}
										placeholder='OTP'
										onChange={handleEmailOtp}></input>
								</div>
							</>
						) : null}
						<div className='col-5  col-md-4 col-lg-6 col-xl-4 pl-2'>
							<button
								disabled={sendOtpButton}
								type='button'
								className='btn btn-success  btn-block'
								onClick={buttonEmailName === "Send OTP" ? sendEmailOTP : verifyEmailOtp}>
								{buttonEmailName}
							</button>
						</div>
					</div>
					{isEmailOTPSent ? (
						<>
							<div className='form-group row justify-content-center mt-3'>
								<div>
									{isEmailOTPReSent ? (
										<>
											<span
												className='resendotppropelld'
												type='button'
												// className="btn btn-primary btn-save-add "
												onClick={sendEmailOTP}>
												Re-Send OTP
											</span>
										</>
									) : (
										<span className='resendotppropelld'>{getTimeInSecEmail}</span>
									)}
								</div>
							</div>
						</>
					) : null}
				</div>
			</Modal>
		</div>
	);
}
