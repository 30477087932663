import React,{ useState, useEffect  } from 'react'
import { Link } from 'react-router-dom'

export default function NEETScholarship(props) {
   const [utmSource, setUtmSource] = useState("")
   const [utmMedium, setUtmMedium] = useState("")
   const [utmCampaign, setUtmCampaign] = useState("")

   const getprams = () => {

      const searchParams = new URLSearchParams(document.location.search)
      let utm_source = searchParams.get('utm_source')
      if(utm_source !== null){setUtmSource(utm_source)}
      let utm_medium = searchParams.get('utm_medium')
      if(utm_medium !== null){setUtmMedium(utm_medium)}
      let utm_campaign = searchParams.get('utm_campaign')
      if(utm_campaign !== null){setUtmCampaign(utm_campaign)}
   }

   useEffect(()=>{
      getprams()
   },[])
return (
<>
<div className="container mt-5 jee-scholarship">
   <div className="row">
      <div class="col-12 col-md-12">
         <div className='title'>How to Apply <span className='color-green'>for Scholarships </span></div>
         <ol type='1' className='jee-scholarship-listing mt-2'>
         <li>Scholarship Eligibility Criteria are mentioned below.</li>
<li>Scholarship requests made verbally or through phone/email/fax shall not be entertained in any case.</li>
<li>To avail of the scholarship, a student has to submit the required Documents (as mentioned below).</li>
<li>The last date to apply for a scholarship is <b className='star-red'>31 August'23</b>. No application for a scholarship thereafter shall be accepted in any case.</li>

         </ol>
      </div>
      <div class="col-12 col-md-12 mt-4">
         <div className='title'>Important <span className='color-green'>Information:</span></div>
         <ol type='1' className='jee-scholarship-listing mt-2'>
{/* <li>Scholarship at a later stage (before the last date) with the below-mentioned documents, if eligible.</li>
<li>In case of Applying for a Scholarship after taking Admission, you can avail scholarship by applying for Scholarship Disbursement.</li>
<li>Disbursement of Scholarship will be credited to Father / Mother / Student Bank Account by <b className='star-red'>31 August'23</b> or 1 month from the date of Application (whichever is later) through NEFT / RTGS only. For this, you have to submit a cancelled cheque or bank passbook first-page copy along with the required Documents.</li> */}
<li>Scholarship provided based on JEE (Main) 2023 / JEE (Advanced) 2023 will be provided only in the Current Academic Session (2023-24) only.</li>

         </ol>
         <p className='mt-4 mb-0'><b>A student is eligible for only one scholarship at a time. No two criteria can be clubbed to avail deduction in Fee at ALLEN.</b></p>
      </div>
      <div class="col-12 col-md-12 mt-4">
         <div className="jee-scholarship-table-title text-center">
         JEE (MAIN+ADVANCED) - LIVE
         </div>
         <div className="table-responsive">
            <table class="table table-bordered jee-scholarship-table">
               <thead>
                  <tr>
                     <th>JEE (MAIN+ADVANCED) 2023 CRL Rank (AIR)</th>
                     <th>Scholarship</th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td className='font-weight-500'>upto 7000</td>
                     <td>90%</td>
                  </tr>
                  <tr>
                     <td className='font-weight-500'>7001 to 10000</td>
                     <td>75%</td>
                  </tr>
 
                  <tr>
                     <td className='font-weight-500'>10001 to 15000</td>
                     <td>50%</td>
                  </tr> 
                  <tr>
                     <td className='font-weight-500'>15001 to 20000</td>
                     <td>25%</td>
                  </tr>   
                  <tr>
                     <td className='font-weight-500'>20001 to 25000</td>
                     <td>20%</td>
                  </tr>  
                  <tr>
                     <td className='font-weight-500'>All JEE (Advanced) 2023 Qualified Student</td>
                     <td>15%</td>
                  </tr>         
                  
                  <tr>
                     <td colSpan="4" className='text-center color-green'><b>Last date to avail scholarship: 31 August'23</b></td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
      {/* <div class="col-12 col-md-12 mt-4">
         <div className="jeescholarship-live">
            <div className="text-center">
               <div className="title-head"><i>JEE (MAIN+ADVANCED)</i></div>
               <div className="title-bg mt-2">LEADER (Live)</div>
               <div className="jeescholarship-live-first row-direction">
                 
                 <div className="phase-wrap-container border-mobile-show">
                  <div className="phase"><span className='font-weight-500'>Medium</span><i> :  English</i></div>
                     <div className="pahse-data">
                        <div className="phase-wrap">
                           <div className="phase">Phase 3 </div>
                           <div className="pahsedate">22 JUNE'23</div>
                        </div>
                                   </div>
                    
                  </div>
                  <div className="phase-wrap-container">
                  <div className="phase"><span className='font-weight-500'>Medium</span><i> :  English + Hindi</i></div>
                     <div className="pahse-data">
                        <div className="phase-wrap">
                           <div className="phase">Phase 5 </div>
                           <div className="pahsedate">23 JUNE'23</div>
                        </div>
                                   </div>
                    
                  </div> 
                <div className="phase-wrap-container border-mobile-show w-100">
                  <div className="phase"><span className='font-weight-500'>Medium</span><i> :  English + Hindi</i></div>
                     <div className="pahse-data">
                                     <div className="phase-wrap">
                           <div className="phase">Phase 7 </div>
                           <div className="pahsedate">21 JULY'23</div>
                        </div>
                     </div>
                   
                  </div> 
                
                
               </div>
            </div>
         </div>
      </div> */}
      <div className="col-12 mt-4">
         <div className="jeescholarship-list-wrap">
            <div className="jeescholarship-list">
               <img src='https://cdn.digital.allen.ac.in/fe-bsite/images/check-mark.png' />
               Learn from comfort of home
            </div>
            <div className="jeescholarship-list">
               <img src='https://cdn.digital.allen.ac.in/fe-bsite/images/check-mark.png' />
               ALLEN's proven pedagogy
            </div>
            <div className="jeescholarship-list">
               <img src='https://cdn.digital.allen.ac.in/fe-bsite/images/check-mark.png' />
               Best academics with best technology
            </div>
         </div>
      </div>
      <div className="col-12 mt-4 mt-md-5 text-center">
         <a href={`https://cloud.engage.allendigital.in/scholarships-jee-advanced?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`} class="request-call-back">Request a Call Back</a>
      </div>
      <div className="col-12 mt-2 text-center pahsedate">
         Or
      </div>
      <div className="col-12 mt-2 text-center call-text">
         Call: <a href='tel:+91-95137-36491'>+919513736491</a>
      </div>
      <div className="col-12 mt-4">
         <div className="neet-footer text-center">
            <div className="heading">
            DOCUMENTS REQUIRED FOR SCHOLARSHIP
            </div>
            <div className="neet-footer-flex">
               <div className="document-flex">
                  <div className="icon"><img src='https://cdn.digital.allen.ac.in/fe-bsite/images/student-id-icon.png'></img></div>
                  <div className="content">Photo ID Card Of <br className='d-none d-lg-block'/> Student</div>
               </div>
               <div className="document-flex borders-right borders-left">
                  <div className="icon"><img src='https://cdn.digital.allen.ac.in/fe-bsite/images/admit-icon.png'></img></div>
                  <div className="content">Admit Card Of JEE (Advanced)  <br className='d-none d-lg-block'/> 2023</div>
               </div>
               <div className="document-flex">
                  <div className="icon"><img src='https://cdn.digital.allen.ac.in/fe-bsite/images/admit-icon.png'></img></div>
                  <div className="content">JEE (Advanced) 2023 <br className='d-none d-lg-block'/> Score Card</div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</>
)
}