import React from 'react';
import notFoundImage from "../images/notfound.png";
class PageNotFound extends React.Component {
    render() { 
        return <div id="wrapper" className="container text-center align-items-center">
        <div id="info" className="notfoundbox">
            <h1>404</h1>
            <p className="text-uppercase">Page <strong>Not found</strong></p>
            <img className="mr-2" src={notFoundImage}/>
        </div>
    </div >;
    }
}
 
export default PageNotFound;