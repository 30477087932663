import mixpanel from "mixpanel-browser"

async function MixpanelInit(evntType, eventName, data) {
  // console.log(process.env.REACT_APP_MIXPANEL_TOKEN)
  //   await mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true }); 
  //   if (evntType === "Track") {
  //      await mixpanel.track(eventName,data)
  //   } else if (evntType === "Identify") {
  //     await  mixpanel.identify(data)
  //   } else if (evntType === "People_Set_Once") {
  //     await  mixpanel.people.set_once(data)
  //   } else if (evntType === "People_Set") {
  //     await  mixpanel.people.set(data)
  //   } else if (evntType === "Register") {
  //     await mixpanel.register(data)
  //   } else if (evntType === "Reset") {
  //     await mixpanel.reset()
  //   }

}

export default MixpanelInit