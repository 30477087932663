import React from "react";
import { Link } from "react-router-dom";
// import { environment } from "../../Services/HttpData";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class ShopingCartlist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartItems: [],
      currency: null,
    };
  }
  componentWillReceiveProps(props) {
    this.setState({
      cartItems: props.handelEvent.cartItems,
      currency: props.handelEvent.currency,
    });
  }
  async removeCartItem(item) {
    // //console.log(item);
    const skvDetails = JSON.parse(sessionStorage.getItem("skavaTokenDetails"));
    // //console.log(skvDetails.sessionId);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apiKey: skvDetails.sessionId,
      },
      body: JSON.stringify({ itemId: item }),
    };
    fetch(environment.baseUrl + "deleteCartItemSkSite", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data = /* isJson && */ await response.json();
        // check for error response
        // //console.log(response);
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        if (response.ok) {
          //console.log(response);
          if (response.status === 202) this.props.onChange("reload");
          // this.setState({cartItems:data.items,cartDetails:{value:data.value,currencySign:data.currencySign}})
          //   //console.log(data);
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: error.toString() });
        console.error("There was an error!", error);
      });
  }
  render() {
    return (
      <>
        <div className="col-12 col-md-8 ml-auto mt-5 shopingcart">
          <div className="row m-0 aline d-flex aling-items-center justify-content-between flex-wrap">
            <h4 className="font-weight-bold cart-title mb-0">Your Shopping Cart</h4>
            <Link to="/courses" className="ml-auto text-success cart-shop-link line-height-normal">
              Continue Shopping
            </Link>
          </div>
          {(this.state.cartItems === null || this.state.cartItems?.length === 0) && (<div
            className="text-center"
          >
            No Item in the cart.
          </div>)}
          {this.state.cartItems && this.state.cartItems.map((item, i) => {
            return (
              <div
                className="card px-4 py-2 mb-4 course-card"
                key={"crtItem" + i}
              >
                <div className="row  ">
                  <img
                    className="padding-b-t"
                    src={item?.properties?.imageURL}
                    alt=""
                    width="30%"
                  />
                  <div className="col">
                    <p className="cart-list-title">{item?.properties?.name}</p>
                    <p className="cart-list-desc">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Etiam sollicitudin laoreet massa, nec molestie urna
                    </p>
                    <p>
                      <strong>Quantity:</strong> {item?.quantity}
                      <button
                        className="btn btn-link text-success"
                        onClick={(e) => {
                          this.removeCartItem(item?.itemId);
                        }}
                      >
                        Remove
                      </button>
                    </p>
                  </div>
                  <p className="cart-list-price">
                    {this.state.currency === "INR" ? "₹" : this.state.currency}{" "}
                    {item?.totalValue?.grandTotal}
                  </p>
                </div>
              </div>
            );
          })}
          {/* <div className="card px-4 py-2 mb-4 course-card">
            <div className="row">
              <img
                className="padding-b-t"
                src="http://blog.deekshaclasses.com/wp-content/uploads/2019/11/Capsule-Course-scaled.jpg"
                alt=""
                width="30%"
              />
              <div className="col">
                <p className="cart-list-title">
                  JEE Main 2021 (February Attempt) Paper Analysis (Physics,
                  Chemistry, Maths)…
                </p>
                <p className="cart-list-desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  sollicitudin laoreet massa, nec molestie urna
                </p>
                <a href="#">Remove</a>
              </div>
              <p className="cart-list-price">&#8377; 455</p>
            </div>
          </div>

         <div className="card px-4 py-2 mb-4 course-card">
                <div className="row">
                    <img  className="padding-b-t" src="http://blog.deekshaclasses.com/wp-content/uploads/2019/11/Capsule-Course-scaled.jpg" alt="" width="30%" />
                    <div className="col">
                        <p className="cart-list-title">JEE Main 2021 (February Attempt) Paper Analysis 
                            (Physics, Chemistry, Maths)…</p>
                        <p className="cart-list-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                            sollicitudin laoreet massa, nec molestie urna</p> 
                        <a href="#">Remove</a>       
                    </div>
                    <p className="cart-list-price">&#8377; 455</p>
                </div>
            </div> */}
        </div>
      </>
    );
  }
}

export default ShopingCartlist;
