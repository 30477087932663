import React, { Component } from 'react';
import TestimonialImg1 from "../../images/Ellipse 23.png";
import TestimonialImg2 from "../../images/Ellipse 24.png";
import TestimonialImg3 from "../../images/Ellipse 25.png";
// import { environment } from '../../Services/HttpData';
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)

class HomeTestimonials extends React.Component {

    constructor(props) {
        super(props);
        //   //console.log(props.handelEvent);
        this.state = {
            PageContent: [],
        };
    }
    componentWillReceiveProps(props) {
        //   //console.log(props.handelEvent);
        let testi = {}
        testi.title = props.handelEvent.title;
        testi.content = [];
        // console.log(props);
        if (props.testimonials !== undefined) {
            props.testimonials.map((item) => {
                if (item.status === 1) testi.content.push(item);
            });
        }
        //   //console.log(testi);
        this.setState({ PageContent: testi });
    }

    render() {
        // console.log(props)
        const testimonialW70 = {
            width: "40px",
            height: "40px",
            borderRadius: "50%"
        };
        const font16 = {
            fontSize: "16px"
        };
        const font12 = {
            fontSize: "12px"
        };
        return <>
            <div className="container">
                <div className="testimonials">
                    <h2 className="text-center mt-5 font-weight-bold">WHAT STUDENTS &amp; PARENTS SAY ABOUT ALLEN?</h2>
                    <div className="row">
                        <div className="col-12 col-md-6 col-gl-3 col-xl-3 mt-4">
                            <div className="card testimonial-card">
                                <p><span style={{ color: "rgb(33, 37, 41)", fontSize: "16px" }}>
                                    I did self-study for 6-7 hours daily. Start your preparation from the first day rather than leaving it for the last minute.<br /><br />

                                    ALLEN stands out as a top institute with Doubt Counters, Weekly Tests, disciplined environment led by experts.<br /><br />
                                </span>
                                </p>
                                <div className="d-inline-flex my-4">
                                    <div className="test-col"><img src="https://cdn.digital.allen.ac.in/be-cm/uploads/tanisha.png" alt="" className="img-fluid" style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: "15px" }} /></div>
                                    <div className="test-col">
                                        <p className="mb-0 p-0 testimonial-home-name" style={{ fontSize: "16px" }}>Tanishka</p>
                                        <p className="mb-0 p-0 testimonial-home-detail" style={{ fontSize: "12px" }}>AIR-1 (NEET-UG 2022)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-gl-3 col-xl-3 mt-4">
                            <div className="card testimonial-card">
                                <p><span style={{ color: "rgb(33, 37, 41)", fontSize: "16px" }}>Despite lack of resources and physical classes during the pandemic, the swiftness with which ALLEN provided me with online academic support is commendable. The faculties completed the course on time and helped me release the mental pressure I was going through!</span></p>
                                <div className="d-inline-flex my-4">
                                    <div className="test-col"><img src="https://cdn.digital.allen.ac.in/be-cm/uploads/attachment-1641985556728.jpg" alt="" className="img-fluid" style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: "15px" }} /></div>
                                    <div className="test-col ml-xl-3">
                                        <p className="mb-0 p-0 testimonial-home-name" style={{ fontSize: "16px" }}>Mridul Agarwal</p>
                                        <p className="mb-0 p-0 testimonial-home-detail" style={{ fontSize: "12px", textAlign:"left" }}>AIR-1 (JEE Main & Adv. 2021) </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-gl-3 col-xl-3 mt-4">
                            <div className="card testimonial-card">
                                <p><span style={{ color: "rgb(33, 37, 41)", fontSize: "16px" }}>All of a sudden, I was under so much pressure when the Pandemic happened just before the exam. I can never forget how rapidly the faculties of ALLEN switched to the online medium and gave me immense support during those tough times and helped me stay calm &amp; focused. </span></p>
                                <div className="d-inline-flex my-4">
                                    <div className="test-col"><img src="https://cdn.digital.allen.ac.in/be-cm/uploads/attachment-1641985625694.jpg" alt="" className="img-fluid" style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: "15px" }} /></div>
                                    <div className="test-col">
                                        <p className="mb-0 p-0 testimonial-home-name" style={{ fontSize: "16px" }}>Soyeb Aftab</p>
                                        <p className="mb-0 p-0 testimonial-home-detail" style={{ fontSize: "12px" }}>AIR-1 (NEET-UG 2020)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-gl-3 col-xl-3 mt-4">
                            <div className="card testimonial-card">
                                <p><span style={{ color: "rgb(33, 37, 41)", fontSize: "16px" }}>I can proudly say that joining ALLEN was the best decision I ever made as I was not only acquainted with the best study materials and peer learning environment, but also was under the guidance of highly qualified &amp; supportive faculties.</span><br /><br /></p>
                                <div className="d-inline-flex my-4">
                                    <div className="test-col"><img src="https://cdn.digital.allen.ac.in/be-cm/uploads/attachment-1641985658612.jpg" alt="" className="img-fluid" style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: "15px" }} /></div>
                                    <div className="est-col">
                                        <p className="mb-0 p-0 testimonial-home-name" style={{ fontSize: "16px" }}>Nalin Khandelwal</p>
                                        <p className="mb-0 p-0 testimonial-home-detail" style={{ fontSize: "12px" }}>AIR-1 (NEET-UG 2019)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>;
    }
}

export default HomeTestimonials;