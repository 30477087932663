import React, { useState, useEffect } from "react";
// import { environment } from "../../Services/HttpData";
import { toast } from "react-toastify";
import { propTypes } from "react-bootstrap/esm/Image";
import { TailSpin } from "react-loader-spinner";
import MixpanelInit from "../Mixpanel/MixpanelInit";

const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);

const EcomAddUserAddress = props => {
	const [addressDetails, setaddressDetails] = useState([]);
	const [checkoutDetails, setcheckoutDetails] = useState([]);
	const [loader, setLoader] = useState(false);
	// //console.log("addressDetails",addressDetails)
	//console.log("checkoutDetails",checkoutDetails[0])

	// if(addressDetails.student_address_details_id) {
	//   var x = document.getElementById("myDIV");
	//   var y = document.getElementById("addNewAddress");
	//   x.style.display = "block";
	//   y.style.display = "none";
	// }

	const [showAddressForm, setShowAddressForm] = useState(false);
	const [country, setCountry] = useState();
	const [state, setState] = useState();
	const [cities, setCities] = useState();
	const [prefilledState, setprefilldState] = useState("");
	const [prefilledCity, setprefilldCity] = useState("");
	const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "";
	const [text, setText] = useState({
		student_address_details_id: "",
		address_line1: "",
		address_line2: "",
		city_id: "",
		pincode: "",
		country: "690352c39f603fa69683af93d18a690d",
		state: "",
		district: "",
		address_type: "",
	});
	// console.log(text);
	const handleEvent = e => {
		const { name, value } = e.target;
		if (name === "country") {
			//setCountry(value);
			getState(value);
		}
		if (name === "state") {
			//setState(value);
			setCities([]);
			getCities(value);
			setText(preValue => {
				return {
					...preValue,
					city_id: "",
				};
			});
			//console.log("state");
		}
		if (name == "pincode" && value.length <= 6 && !value.includes("e")) {
			setText(preValue => {
				return {
					...preValue,
					[name]: value.trimLeft(),
				};
			});
		}

		if (addressDetails.student_address_details_id === "") {
			setText(preValue => {
				return {
					...preValue,
					[name]: value,
				};
			});
		} else if (name !== "pincode") {
			setText(preValue => {
				return {
					...preValue,
					["student_address_details_id"]: addressDetails.student_address_details_id,
					[name]: value.trimLeft(),
				};
			});
		}

		//console.log(text);
	};

	const submitForm = e => {
		// console.log("add address object",text);
		e.preventDefault();
		if (text.address_line1.trim().length < 1) {
			toast.error("Please Enter Address Line One");
			return false;
		} else if (text.state.trim().length < 1) {
			toast.error("Please Select State");
			return false;
		} else if (text.district.trim().length < 1) {
			toast.error("Please Enter District");
			return false;
		} else if (text.city_id.trim().length < 1) {
			toast.error("Please Select City");
			return false;
		} else if (text.pincode.trim().length < 6) {
			toast.error("Please Enter Correct Pincode");
			return false;
		}

		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Basic " + auth_token,
			},
			body: JSON.stringify(text),
		};
		setLoader(true);
		fetch(environment.ecomApiUrl + "user/add-address", requestOptions).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				if (result.status == "success") {
					const requestOptions = {
						method: "GET",
						headers: {
							Authorization: "Basic " + auth_token,
						},
					};
					fetch(environment.ecomApiUrl + "user/address", requestOptions).then(resp => {
						// fetching api url for courses listing
						resp.json().then(result => {
							setcheckoutDetails(result.data);
							////console.log(checkoutDetails);
						});
					});
					setLoader(false);
					toast.success("Shipping Address Updated Successfully");
					//console.log("Form Submitted Successfully");
					//   props.onChange();
					var x = document.getElementById("myDIV");
					var y = document.getElementById("addNewAddress");
					y.style.display = "none";
					x.style.display = "none";
					x.reset();
					setText({
						address_line1: "",
						address_line2: "",
						city_id: "",
						pincode: "",
						country: "690352c39f603fa69683af93d18a690d",
						state: "",
						district: "",
						address_type: "",
					});
				} else {
					setLoader(false);
					toast.error(result.message);
				}
			});
		});
	};

	const updateForm = obj => {
		// console.log(obj)
		setText({
			address_line1: obj?.address_line1,
			address_line2: obj?.address_line2,
			city_id: obj?.city_id,
			pincode: obj?.pincode,
			country: "690352c39f603fa69683af93d18a690d",
			state: obj?.state_id,
			district: obj?.district,
			address_type: "",
			student_address_details_id: obj?.student_address_details_id,
		});
		setaddressDetails(obj);
		setText(preValue => ({ ...preValue, ...{ address_type: "242" } }));
		var x = document.getElementById("myDIV");
		var y = document.getElementById("addNewAddress");
		y.style.display = "none";
		if (x.style.display === "block") {
			x.style.display = "none";
		} else {
			x.style.display = "block";
		}
	};
	/* Update Address API */
	const submitUpdatedForm = e => {
		//console.log("updated",text);
		//setText([...text, {student_address_details_id:student_address_details_id}]);
		e.preventDefault();
		if (text.address_line1.trim().length < 1) {
			toast.error("Please Enter Address Line One");
			return false;
		} else if (text.state.trim().length < 1) {
			toast.error("Please Select State");
			return false;
		} else if (text.district.trim().length < 1) {
			toast.error("Please Enter District");
			return false;
		} else if (text.city_id.trim().length < 1) {
			toast.error("Please Select City");
			return false;
		} else if (text.pincode.trim().length < 6) {
			toast.error("Please Enter Correct Pincode");
			return false;
		}
		MixpanelInit("Track", "shipping_address_updated", {
			shipping_address: text?.address_line1 + " " + text?.address_line2,
			shipping_pincode: text.pincode,
			shipping_state: text.state,
			shipping_district: text.district,
			shipping_city: text.city_id,
		});
		const requestOptionsupdate = {
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Basic " + auth_token,
			},
			body: JSON.stringify(text),
		};
		setLoader(true);
		fetch(environment.ecomApiUrl + "user/update-address", requestOptionsupdate).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				if (result.status == "success") {
					const requestOptions = {
						method: "GET",
						headers: {
							Authorization: "Basic " + auth_token,
						},
					};
					fetch(environment.ecomApiUrl + "user/address", requestOptions).then(resp => {
						// fetching api url for courses listing
						resp.json().then(result => {
							setcheckoutDetails(result.data);
							////console.log(checkoutDetails);
						});
					});
					setLoader(false);
					toast.success("Shipping Address Updated Successfully");
					//console.log("Form Submitted Successfully");
					// props.onChange();
					var x = document.getElementById("myDIV");
					var y = document.getElementById("addNewAddress");
					y.style.display = "block";
					x.style.display = "none";
					x.reset();
					setText({
						address_line1: "",
						address_line2: "",
						city_id: "",
						pincode: "",
						country: "690352c39f603fa69683af93d18a690d",
						state: "",
						district: "",
						address_type: "",
					});
					setaddressDetails([]);
				} else {
					setLoader(false);
					toast.error(result.message);
				}
			});
		});
	};

	useEffect(() => {
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Basic " + auth_token,
			},
		};
		fetch(environment.ecomApiUrl + "user/address", requestOptions).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				setcheckoutDetails(result.data);
				result?.data?.map(address => {
					if (address.address_type == 242) {
						getCities(address.state_id);
					}
				});
			});
		});
	}, []);

	useEffect(() => {
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Basic " + auth_token,
			},
		};
		fetch(environment.ecomApiUrl + "app/countries", requestOptions).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				setCountry(result.data);
				////console.log(checkoutDetails);
			});
		});
		/* const requestOptions1 = {
          method: 'GET',
          headers : {
            'Authorization': 'Basic '+auth_token
          }
        };
        fetch(environment.ecomApiUrl + 'app/states/f0f33a',requestOptions1).then((resp)=>{ // fetching api url for courses listing
            resp.json().then((result)=>{
                setState(result.data);
              ////console.log(checkoutDetails);
            })
        }) */
	}, []);

	useEffect(() => {
		getState("690352c39f603fa69683af93d18a690d");
		// if(checkoutDetails?.length > 0){
		//   getState('690352c39f603fa69683af93d18a690d');
		// }
	}, [checkoutDetails]);

	function getState(data) {
		const requestOptions2 = {
			method: "GET",
			headers: {
				Authorization: "Basic " + localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "",
			},
		};
		fetch(environment.ecomApiUrl + "app/states/" + data, requestOptions2).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				setState(result.data);
				//console.log(data);
			});
		});
	}

	function getCities(data) {
		const requestOptions2 = {
			method: "GET",
			headers: {
				Authorization: "Basic " + localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "",
			},
		};
		fetch(environment.ecomApiUrl + "app/cities/" + data, requestOptions2).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				setCities(result.data);
				////console.log(checkoutDetails);
			});
		});
	}

	function myFunction() {
		setText(preValue => ({ ...preValue, ...{ address_type: "242" } }));
		var x = document.getElementById("myDIV");
		var y = document.getElementById("addNewAddress");
		y.style.display = "none";
		if (x.style.display === "block") {
			x.style.display = "none";
		} else {
			x.style.display = "block";
		}
	}

	function cancelAddressFrom() {
		var x = document.getElementById("myDIV");
		var y = document.getElementById("addNewAddress");
		y.style.display = "block";
		x.style.display = "none";
		/* alert(x.style.display) */
		x.reset();
		setText({
			address_line1: "",
			address_line2: "",
			city_id: "",
			pincode: "",
			country: "",
			district: "",
			state: "",
		});
	}
	// console.log(checkoutDetails)
	const controladdbutton = () => {
		document.getElementById("addNewAddress").style.display = "none";
	};

    return(
        <>
        <div className="col-md-8 px-4 py-4" style={{border:'1px solid #e1e1e1', backgroundColor:'white', borderRadius:'5px'}}>
            <div>
                <h5>Address Info</h5> <hr></hr>
               
                {
                    checkoutDetails?.map((address, i)=>{
                        return(
                            <>
                                {
                                    (address?.address_type == 241) ? 
                                    (   
                                        <>
                                        <h6 style={{color:'#116234'}}><strong>Billing Address</strong></h6>
                                        <h6 className="ml-2" style={{lineHeight:"25px"}}>{address?.address_line1}, {address?.address_line2}, {address?.address_line3}<br />{address?.city_name}-{address?.pincode}, Dist- {address?.district}, {address?.state_name}, {address?.country_name}.</h6>
                                        <hr></hr>
                                        </>
                                    ) : (
                                        <>
                                        {
                                             controladdbutton()
                                        }
                                        <div className=" d-flex justify-content-between">
                                        <h6 style={{color:'#116234'}}><strong>Shipping Address</strong></h6> <p style={{color:'#116234', fontWeight:'600', cursor:'pointer'}} onClick={() => updateForm(address)}><u>Edit</u></p>
                                        </div>
                                        <h6 className="ml-2" style={{lineHeight:"25px"}}>{address?.address_line1}, {address?.address_line2}, {address?.address_line3}<br />{address?.city_name}-{address?.pincode}, Dist- {address?.district}, {address?.state_name}, {address?.country_name}.</h6>
                                        </>
                                    )
                                }
                            </>
                        )
                    })
                }
               

            </div>
            <button className="new-add-btn mt-4 mb-4" onClick={myFunction} id="addNewAddress"><b>Add Shipping Address</b></button>
            
            <form className="address-form" id="myDIV">
            <div className="new-add-btn mt-3 mb-3 border-none p-0" style={{marginTop:"2%"}}>{addressDetails.student_address_details_id? <b>Update Shipping Address</b> : <b>Add Shipping Address</b> }</div>
                    {/* <h1>Hello {text.country}</h1> */}
                <div className="form-group row">
                  <label htmlFor="address1" className="col-sm-2 col-form-label">Address line 1 *</label>
                  <div className="col-sm-10">
                    <input type="text" className="form-control" id="address1" maxLength={80} name="address_line1" value={text.address_line1?text.address_line1:""} placeholder="" onChange={handleEvent}></input>
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="address2" className="col-sm-2 col-form-label">Address line 2 </label>
                  <div className="col-sm-10">
                    <input type="text" className="form-control" id="address2" maxLength={80} name="address_line2" value={text.address_line2?text.address_line2:""} placeholder="" onChange={handleEvent}></input>
                  </div>
                </div>
                {/* {
                  !addressDetails.student_address_details_id?(
                    <div className="form-group row">
                    <label htmlFor="country" className="col-sm-2 col-form-label">Country *</label>
                    <div className="col-sm-10">
                      <select id="country" name="country" className="form-control" onChange={handleEvent} value={checkoutDetails?.country_id}>
                      
                            <option value="">--- Select ---</option>
                              {
                                (country?country:[]).map((cn,i)=>{
                                  return(
                                      <>
                                          <option value={cn.country_id} selected={true}> {cn.country_name} </option>
                                      </>
                                  )
                              })
                              }
                            
                      
                      </select>
                    </div>
                  </div>
                  ):null
                }
                 */}

					<div className='form-group row'>
						<label htmlFor='state' className='col-sm-2 col-form-label'>
							State *
						</label>
						<div className='col-sm-10'>
							<select id='state' name='state' className='form-control' onChange={handleEvent}>
								<option value=''> --- Select ---</option>
								{(state ? state : []).map((st, i) => {
									//console.log("see",st.state_id+'='+checkoutDetails.state_id)
									return (
										<>
											<option value={st.state_id} selected={st.state_id === text.state ? true : false}>
												{" "}
												{st.state_name}{" "}
											</option>
										</>
									);
								})}
							</select>
						</div>
					</div>

					<div className='form-group row'>
						<label htmlFor='pincode' className='col-sm-2 col-form-label'>
							District *
						</label>
						<div className='col-sm-10'>
							<input
								type='text'
								className='form-control'
								id='district'
								maxLength={30}
								name='district'
								value={text.district ? text.district : ""}
								placeholder=''
								onChange={handleEvent}></input>
						</div>
					</div>

					<div className='form-group row'>
						<label htmlFor='state' className='col-sm-2 col-form-label'>
							City *
						</label>
						<div className='col-sm-10'>
							<select id='city' name='city_id' className='form-control' onChange={handleEvent}>
								<option value=''>--- Select ---</option>
								{(cities ? cities : []).map((ct, i) => {
									return (
										<>
											<option value={ct.city_id} selected={ct.city_id === text.city_id ? true : false}>
												{" "}
												{ct.city_name}{" "}
											</option>
										</>
									);
								})}
							</select>
						</div>
					</div>

					<div className='form-group row'>
						<label htmlFor='pincode' className='col-sm-2 col-form-label'>
							Pincode *
						</label>
						<div className='col-sm-10'>
							<input
								type='number'
								className='form-control'
								id='pincode'
								name='pincode'
								maxLength={6}
								value={text.pincode ? text.pincode : ""}
								placeholder=''
								onChange={handleEvent}
							/>
						</div>
					</div>

					{/* <div className="form-group row">
                  <label htmlFor="address2" className="col-sm-2 col-form-label">Address line 3 </label>
                  <div className="col-sm-10">
                    <input type="text" className="form-control" id="address3" name="address_line3" value={text.address_line3?text.address_line3:addressDetails.address_line3} placeholder="" onChange={handleEvent}></input>
                  </div>
                </div> */}
					<div className='form-group row'>
						<div className='col-sm-10'>
							{/*  <input type="hidden" className="form-control" id="student_address_details_id" name="student_address_details_id" value={text.student_address_details_id?text.student_address_details_id:addressDetails.student_address_details_id}  onChange={handleEvent}></input> */}
						</div>
					</div>
					{addressDetails.student_address_details_id ? (
						<button type='submit' className='btn btn-primary btn-save-add mt-3 mb-2' onClick={submitUpdatedForm}>
							{loader ? <TailSpin color='white' height={25} width={25} /> : "Update"}
						</button>
					) : (
						<button type='submit' className='btn btn-primary btn-save-add mt-3 mb-2' onClick={submitForm}>
							{loader ? <TailSpin color='white' height={25} width={25} /> : "Save"}
						</button>
					)}

					<span
						className='btn btn-danger btn-save-add mt-3 mb-2'
						onClick={cancelAddressFrom}
						style={{ marginLeft: "1%", borderColor: "#bd2130", backgroundColor: "#bd2130", color: "#fff" }}>
						Cancel
					</span>
				</form>
			</div>
		</>
	);
};

export default EcomAddUserAddress;
