import React, { useEffect, useState } from "react";
import EcomDetails from "./../../components/Ecom/EcomDetails2";
import EcomContentDetails from "./../../components/Ecom/EcomContentDetails";
import LearningCycle from "./../../components/CoursesDetails/LearningCycle";
import EcomSimilarCourse from "./../../components/Ecom/EcomSimilarCourse";
import EcomRightSidenav from "./../../components/Ecom/EcomRightSidenav";
import { decode as base64_decode } from "base-64";
import { Helmet } from "react-helmet";
// import { environment } from "../../Services/HttpData";
import { toast } from 'react-toastify';
import { useParams } from "react-router";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
let ogTagIs = true
const CoursesDetails = (props) => {
  //console.log("ppp",props)
  const [productDetails, setProductDetails] = useState([]);
  const { slug } = useParams();
  // const [ogTag, setOgTag] = useState(true)
  // fetch url for products/courses listing
  const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : '';
  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + auth_token
      },
    };
    fetch(environment.ecomApiUrl + 'product/' + slug, requestOptions).then((resp) => { // fetching api url for courses listing
      resp.json().then((result) => {
        if (result?.data?.center_course_status === 101 && result?.data?.center_course_publish_status === 951) {
          setProductDetails(result?.data);  // set result in state this will set current/updated value of setProduct to product
        }
        else if (result?.data.center_course_status === 101 && result?.data?.center_course_publish_status === 952) {
          if (window.location.href.indexOf('?preview') < 0) {
            window.location = environment.REACT_APP_BASE_URL
          } else {
            setProductDetails(result.data);
          }
        }
        else {
          window.location = 'https://allen.in/online-test-series'//environment.REACT_APP_BASE_URL
        }
        //console.log(productDetails);
      })
    })
  }, []);
  const handleCartCount = (status) => {
    props.onChange(status);
    //console.log("co",status)
  }
  return (
    <>
      <Helmet>
        {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
        {productDetails?.center_course_meta_ottag && ogTagIs ? <> {(document.head.innerHTML += productDetails?.center_course_meta_ottag)}{ogTagIs = false}</> : ""}
        {/* {productDetails?.center_course_meta_ottag} */}
        {productDetails?.center_course_page_title && <title>
          {productDetails?.center_course_page_title}
        </title>}
        <link rel="canonical" href={window.location.origin + window.location.pathname} />
        {productDetails?.center_course_meta_description && <meta
          name="description"
          content={productDetails?.center_course_meta_description}
        />}

      </Helmet>

      <div className="container-fluid">
        <div className="row">
          <main
            role="main"
            className="col-md-9 ml-sm-auto col-lg-9 px-3 py-5"
          >
            <EcomDetails data={productDetails}  /* onChange={()=>handleCartCount.bind()} handleCartCount={props.handleCartCount} */ />
            <EcomContentDetails data={productDetails} />
            {/* <FaqDetails handelEvent={this.state.courseDetails} /> */}
            {/* <CourseFaqDetails/> */}
          </main>
          <EcomRightSidenav data={productDetails} />
          <LearningCycle data={productDetails} />
          <EcomSimilarCourse data={productDetails} />
        </div>
      </div>
    </>
  );
}
export default CoursesDetails;