import React, { useState } from "react";
import EmiTableList from "./EmiTableList";

const EcomEmi = (props) => {
  const emiDetails = props.emi ? props.emi : [];

  let totalPriceEmi = emiDetails.reduce(function (accumulator, item) {
    return accumulator + item.installment_amount;
  }, 0);

  return (
    <>
      <div className="row mb-2">
        <div className="col-md-1 col-1">
          <input
            type="radio"
            name="payment_type"
            value="full_course"
            checked={props.isCheckFullCourse}
            onClick={props.onChange}
          ></input>
        </div>
        <div className="col-md-6 col-6">
          <b>Full Course </b>
        </div>
        <div className="col-md-5 col-5">
          <b className="text-right">{props.price}/-</b>
        </div>
      </div>
      {/*  <div className="row">
                <div className="col-md-1 col-1">
                <input type="radio" name="payment_type" value="emi" onClick={props.onChange} checked={props.isCheckedEmi}></input>
                </div>
                <div className="col-md-6 col-6">
                <b>EMI</b></div>
                <div className="col-md-5 col-5">
                <b className="text-right">{totalPriceEmi}/-</b>  
                </div>
                </div> */}

      {props.installment ? (
        <table
          width="100%"
          border="0"
          cellspacing="0"
          cellPadding="0"
          className="course-table"
        >
          <EmiTableList emiDetails={emiDetails} />
        </table>
      ) : null}
    </>
  );
};

export default EcomEmi;
