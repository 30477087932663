import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
const WebinarsNeetNcertDecode = () => {
   const formDataObj = {
      firstName: "",
      lastName: "",
      MiddleName: "",
      mobileNo: "",
      email: "",
      homeno: "",
      State: "",
      CountryCode: "91",
      stateCode: "",
      city: "",
      Class_s: "",
      OTPVerification: true,
      EmailVerified: false,
      TickAgreeToTC: true,
      fatherName: "",
      MotherName: " ",
      dob: null,
      gender: " ",
      category: " ",
      leadsource: "Marketing - Search Engine",
      SubLeadSource: "Landing Page",
      PreferredLanguage: "",
      InstituteName: "",
      FormId: "NEET-UG online classes",
      formUrl: window.location.href,
      UtmMedium: " ",
      UtmDevice: " ",
      utmCampaign: " ",
      utmSource: " ",
      description: " ",
      campaign_id: 1,
      age: "",
      ExistingAllenStudent: "",
      AllenFormNo: "",
      ACIPLRollNo: "",
      PostalCode: "",
      Stream: "NEET-UG",
      NewEcom: true,
   };

   const [webinarData, setWebinarData] = useState([]);
   const [previous, setPrevious] = useState([]);
   const [FormData, setFromData] = useState({ ...formDataObj });
   const [getTimeInSec, setTimeInSec] = useState("");
   const [isMobileOTPReSent, setMobileOTPReSent] = useState(false);
   const [resendOtpButtonShow, setResendOtpButtonShow] = useState(false);
   const [buttonmobileName, setbuttonmobileName] = useState("Send OTP");
   const [isMobileOtp, setMobileOtp] = useState("");
   const [lead_is, setLead_is] = useState(false);
   const [redireactUrl, setRedireactUrl] = useState("")
   let serialNumber = 0;
   const options = {
      autoplay: true,
      loop: true,
      infinite: false,
      nav: true,
      dots: false,
      autoplayHoverPause: true,
      //navText: ["Prev", "Next"],
      smartSpeed: 800,
      margin: 0,
      responsive: {
         0: {
            items: 1,
            margin: 100,
         },
         768: {
            items: 3,
            slideBy: 3,
         },
         992: {
            items: 3,
            slideBy: 3,
         },
      },
   };
   const scroll = () => {
      let e = document.getElementById("scrolltarget");
      e.scrollIntoView({
         block: "start",
         behavior: "smooth",
         inline: "start",
      });
   };
   function mapdata() {
      if (window?.NeetNcertDecode) {
         const updatedWebinar = [];
         const previousWebinar = [];
         window?.NeetNcertDecode.map((item, i) => {
            let currentdate = new Date();
            let currenttime = new Date().getTime();
            if (new Date(item?.developer_time) < currentdate && new Date(item?.developer_time).getTime() < currenttime) {
               item.is_upcoming = false;
               updatedWebinar.push(item);
               previousWebinar.push(item);
            } else {
               updatedWebinar.push(item);
               previousWebinar.push(item);
            }
         });
         setWebinarData(updatedWebinar);
         setPrevious(previousWebinar.reverse());
      } else {
         setTimeout(() => {
            mapdata();
         }, 100);
      }
   }
   const checkLead = e => {

      const requestOptions = {
         method: "POST",
         headers: { "Content-Type": "application/json" },
         body: JSON.stringify({
            campaign_id: "1",
            mobileNo: FormData?.mobileNo,
         }),
      };
      const reqURL = process.env.REACT_APP_AUTH_URL + "sales-force/validate-lead";
      fetch(reqURL, requestOptions)
         .then(res => res.json())
         .then(reqest => {
            if (reqest.status === "success") {
               setLead_is(false);
               // toast.success(data.replyMsg);
               // toast.success("Mobile OTP Verified");
               setbuttonmobileName("Verified");
               setResendOtpButtonShow(true);
               setMobileOTPReSent(false);
            } else {
               // toast.success("Mobile OTP Verified");
               setbuttonmobileName("Verified");
               setResendOtpButtonShow(true);
               setMobileOTPReSent(false);
               if (redireactUrl !== "") {
                  window.open(redireactUrl, '_blank');
               }
               setRedireactUrl("")
               document.querySelector("#WebinarFormModal > div > div > div > button").click()
               // setLead_is(true);
            }
         });
   };
   const handleformData = e => {
      const { name, value } = e.target;
      if (
         name === "student_mobile_no" &&
         /^[0-9]*$/.test(value) &&
         !value.includes("e") &&
         !value.includes(".") &&
         !value.includes("-") &&
         !value.includes("+")
      ) {
         if (value.length <= 10)
            setFromData(pre => ({
               ...pre,
               mobileNo: value,
            }));
      }
      if (
         name === "student_mobile_otp" &&
         /^[0-9]*$/.test(value) &&
         !value.includes("e") &&
         !value.includes(".") &&
         !value.includes("-") &&
         !value.includes("+") &&
         value.length <= 6
      ) {
         setMobileOtp(value);
      }
   };
   const formDatasetHendal = e => {
      const { name, value } = e.target;
      if (name === "city") {
         let cityValue = value.trimLeft()
         cityValue = cityValue.match(/[a-zA-Z/\s/g]+/g)
         if (cityValue === null) {
            setFromData(pre => ({ ...pre, [name]: "" }));
         } else {
            cityValue = cityValue[0]
            setFromData(pre => ({ ...pre, [name]: cityValue }));
         }

         return false
      }
      if (name !== "TickAgreeToTC") {
         setFromData(pre => ({ ...pre, [name]: value?.trimLeft() }));
      }
   };
   var a = 60;
   const setTime = () => {
      a = +a - 1;
      setTimeout(() => {
         if (a >= 0) {
            setTimeInSec(`Resend OTP in ${a} sec`);
            setTime();
         } else {
            setMobileOTPReSent(false);

            setbuttonmobileName("Resend OTP");
         }
      }, 1000);
   };

   const SendMobileOtp = e => {
      var phoneno = /^\+?([6-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/;
      let isMobile = FormData?.mobileNo;
      if (isMobile.trim().length < 10) {
         toast.error("Please Enter Valid Mobile Number");
         return false;
      }
      if (isMobile.trim().match(phoneno) === null || isMobile[0] === "+") {
         toast.error("Please Enter Valid Mobile Number");
         return false;
      }
      // setmobileInputBox(true);
      const requestOptions = {
         method: "POST",
         headers: { "Content-Type": "application/json" },
         body: JSON.stringify({}),
      };
      var reqURL;
      reqURL = process.env.REACT_APP_ECOM_URL + "otp/otp_generate";
      requestOptions.body = JSON.stringify({ mobile: isMobile, country_code: "+91", lead_type: 913 });
      fetch(reqURL, requestOptions)
         .then(async response => {
            const data = await response.json();
            if (data.replyCode === "success") {
               a = 60;
               toast.success(data.replyMsg);
               setTime();
               setMobileOTPReSent(true);
            } else {
               toast.error(data.replyMsg);
            }
         })
         .catch(error => {
            this?.setState({ errorMessage: error.toString() });
            console.error("There was an error!", error);
         });
   };
   const verifyMobileOtp = () => {
      let isMobile = FormData?.mobileNo;
      if (isMobileOtp.length !== 6) {
         toast.error("Please Enter Valid OTP");
         return;
      }

      fetch(process.env.REACT_APP_ECOM_URL + "otp/otp_verify", {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify({ mobile: isMobile, otp: isMobileOtp, lead_type: 913 }),
      }).then(async response => {
         const data = await response.json();
         //console.log(data);
         if (data.replyCode === "success") {
            checkLead()

         } else {
            toast.error(data.replyMsg);
         }
      });
   };

   const submitLeadForm = e => {
      e.preventDefault();
      e.stopPropagation();

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(FormData);

      var requestOptions = {
         method: "POST",
         headers: myHeaders,
         body: raw,
         redirect: "follow",
      };

      fetch(process.env.REACT_APP_AUTH_URL + "sales-force/campaign-lead", requestOptions)
         .then(response => response.json())
         .then(result => {
            if (result?.status === "success") {
               document.querySelector("#WebinarFormsModal > div > div > div > button")?.click();
               toast.success(result?.message);
               setFromData({ ...formDataObj });
               setMobileOTPReSent(false);
               setResendOtpButtonShow(false);
               setbuttonmobileName("Send OTP");
               setLead_is(false);
               setMobileOtp("");
               document.querySelector("#WebinarFormsModal > div > div > div > div > form").reset()
               if (redireactUrl !== "") {
                  window.open(redireactUrl, '_blank');
               }
               setRedireactUrl("")
            } else {
               toast.error(result?.message);
            }
         })
         .catch(error => console.log("error", error));
   };
   useEffect(() => {
      mapdata();
      try {
         const url = new URL(window.location.href);
         const params = new URLSearchParams(url);
         url.searchParams.forEach(function (val, key) {
            setFromData((pre) => {
               return { ...pre, [key]: val }
            })
         });
      } catch (err) {

      }
   }, []);
   return (
      <div>
         <Helmet>
            {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
            <title>Free NEET-UG NCERT Online Classes (Videos) by ALLEN Digital</title>
            <meta name='description' content="ALLEN Digital brings Free NEET-UG NCERT-based Video Series where India’s best educators will teach you the NCERT Concepts via Questions. Watch them live and achieve success in the NEET-UG Exam." />
            <meta property="og:title" content="Free NEET-UG NCERT Online Classes (Videos) by ALLEN Digital" />
            <meta property="og:site_name" content="Allen Digital" />
            <meta property="og:url" content="https://digital.allen.ac.in/" />
            <meta property="og:description" content="ALLEN Digital brings Free NEET-UG NCERT-based Video Series where India’s best educators will teach you the NCERT Concepts via Questions. Watch them live and achieve success in the NEET-UG Exam." />
            <meta property="og:type" content="Website" />
            <meta property="og:image" content="https://cdn.digital.allen.ac.in/fe-bsite/neetdecode-thumbnail.jpg" />
            <link rel="canonical" href="https://digital.allen.ac.in/neet/ncertdecode" />
            {/* <script>
               {(function (w, d, s, l, i) {
                  w[l] = w[l] || [];
                  w[l].push({
                     "gtm.start": new Date().getTime(),
                     event: "gtm.js",
                  });
                  var f = d.getElementsByTagName(s)[0],
                     j = d.createElement(s),
                     dl = l != "dataLayer" ? "&l=" + l : "";
                  j.async = true;
                  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                  f.parentNode.insertBefore(j, f);
               })(window, document, "script", "dataLayer", "GTM-TZXF9P5")}
            </script> */}
         </Helmet>
         {/* <noscript>
            <iframe
               src='https://www.googletagmanager.com/ns.html?id=GTM-TZXF9P5'
               height='0'
               width='0'
               style={{ display: "none", visibility: "hidden" }}></iframe>
         </noscript> */}
         <div className='webinar-banner pb-0'>
            <div className='container'>
               <div className='row'>
                  <div className='col-12 text-center'>
                     <h1 className='webinar-title mb-0'>
                        NCERT Decode Series for NEET-UG <br className='d-none d-md-block' /> by ALLEN Digital</h1>
                     <div className='webinar-subtitle'>Watch, Learn and Lead with FREE NCERT Videos & PDFs</div>
                     <div className='webinar-description'>
                        <b>ALLEN Digital</b> presents meticulously designed NCERT-Questions based NEET-UG Video Series with the objective of nurturing<br className='d-none d-md-block' /> a strong grasp of NCERT concepts.

                     </div>
                     {/* <img onClick={scroll} className='webinarbigbanner' src="https://cdn.allendigital.in/fe-bsite/result_page/1129x350_.png"></img> */}
                     <p id='scrolltarget' style={{ height: "0px" }}></p>
                  </div>
               </div>
            </div>
         </div>
         <div className='webinar-table-wrapper'>
            <div className='container'>
               <div className='row'>
                  <div className='col-12'>
                     <div className='table-title text-center'>NCERT DECODE Schedule (Upcoming Videos)!</div>
                  </div>
                  <div className='col-12 mt-4'>
                     <div className='table-wrappers'>
                        {/* 
                  <div className="jee-scholarship-table-title">MENTIQUIZZES</div>
                  */}
                        <div className='table-responsive table-height-fix'>
                           <table className='table table-bordered jee-scholarship-table white-space-normal table-scroll'>
                              <thead>
                                 <tr>
                                    {/* 
                              <th width="8%">S. No.</th>
                              */}
                                    <th width='18%'>Subject</th>
                                    <th width='46%'>Topics</th>
                                    <th width='18%'>Date</th>
                                    <th width='18%'>Time</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <span style={{ display: "none" }}>{(serialNumber = 0)}</span>
                                 {webinarData.map((item, i) => {
                                    return (
                                       <>
                                          {item.is_upcoming == true ? (
                                             <tr>
                                                <span style={{ display: "none" }}>{(serialNumber += 1)}</span>
                                                {/* 
                              <td>{serialNumber += 1}</td>
                              */}               <td>{item.subject}</td>
                                                <td>{item.perticulars}</td>
                                                <td className="text-nowrap">{item.date}</td>
                                                <td className="text-nowrap">{item.time}</td>
                                             </tr>
                                          ) : (
                                             ""
                                          )}
                                       </>
                                    );
                                 })}
                                 {serialNumber == 0 ? (
                                    <tr>
                                       <td colSpan={"5"} style={{ textAlign: "center" }}>
                                          Stay Tuned For Upcoming Sessions
                                       </td>
                                    </tr>
                                 ) : (
                                    ""
                                 )}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
                  <div className='col-12 mt-3 mt-md-4'>
                     <div className='table-title text-center'>Previous NCERT Videos & PDFs</div>
                  </div>
                  {/* 
            <div className="col-12 mt-4">
               <div className="table-wrappers">
                  <div className="jee-scholarship-table-title">MENTORSHIP SERIES</div>
                  <div className="table-responsive">
                     <div className="table-responsive">
                        <table className="table table-bordered jee-scholarship-table white-space-normal">
                           <thead>
                              <tr>
                                 <th width="8%">S. No.</th>
                                 <th width="40%">Particulars</th>
                                 <th width="15%">Date</th>
                                 <th width="22%">Subject</th>
                                 <th width="18%">Session Link</th>
                                 <th width="18%">PDF Booklet</th>
                              </tr>
                           </thead>
                           <tbody>
                              <span style={{display:"none"}}>{serialNumber = 0}</span>
                              {
                              webinarData.map((item, i) => {
                              return(
                              <>    
                              {
                              item.is_upcoming == false && item.category == "mentorship_series" ?
                              (
                              <tr>
                                 <td>{serialNumber += 1}</td>
                                 <td>{item.perticulars}</td>
                                 <td>{item.date}</td>
                                 <td>{item.subject}</td>
                                 <td>{item.webinar_link?.length > 0? (<a href={item.webinar_link} target='blank' className='register-now'><span>Click Here</span> <img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/know_more.png'></img></a>) : ""}</td>
                                 <td>{item.booklet_link?.length > 0 ? (<a href={item.booklet_link} target='blank' className = 'register-now'><span>Download</span><img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/know_more.png'></img></a>) : ""}</td>
                              </tr>
                              ):""
                              }
                              </>
                              )
                              })
                              }    
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
            */}
                  {/* 
            <div className="col-12 mt-2">
               <div className="table-wrappers">
                  <div className="jee-scholarship-table-title">WINNER ONLINE TEST SERIES</div>
                  <div className="table-responsive">
                     <div className="table-responsive">
                        <table className="table table-bordered jee-scholarship-table white-space-normal">
                           <thead>
                              <tr>
                                 // 
                                 <th width="8%">S. No.</th>
                                 <th width="40%">Paper</th>
                                 <th width="15%">Date</th>
                                 <th width="22%">Subject</th>
                                 <th width="18%">Session Link</th>
                                 <th width="18%">PDF Booklet</th>
                              </tr>
                           </thead>
                           <tbody>
                              <span style={{display:"none"}}>{serialNumber = 0}</span>
                              {
                              webinarData.map((item, i) => {
                              return(
                              <>    
                              {
                              item.is_upcoming == false && item.category == "winnerolts" ?
                              (
                              <tr>
                                 // 
                                 <td>{serialNumber += 1}</td>
                                 <td>{item.perticulars}</td>
                                 <td>{item.date}</td>
                                 <td>{item.subject}</td>
                                 <td>{item.webinar_link?.length > 0 ? (<a href={item.webinar_link} target='blank' className='register-now'><span>Click Here</span> <img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/know_more.png'></img></a>) : ""}</td>
                                 <td>{item.booklet_link?.length > 0 ? (<a href={item.booklet_link} target='blank' className = 'register-now'><span>Download</span><img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/know_more.png'></img></a>) : ""}</td>
                              </tr>
                              ):""
                              }
                              </>
                              )
                              })
                              }   
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
            */}
                  <div className='col-12 mt-4'>
                     <div className='table-wrappers'>
                        {/* 
                  <div className="jee-scholarship-table-title">MENTIQUIZZES</div>
                  */}
                        <div className='table-responsive table-height-fix'>
                           <table className='table table-bordered jee-scholarship-table white-space-normal'>
                              <thead>
                                 <tr>
                                    {/* 
                              <th width="8%">S. No.</th>
                              */}
                                    <th width='22%'>Subject</th>
                                    <th width='40%'>Topics</th>
                                    <th width='22%'>Date</th>
                                    {/* <th width='18%'>Class Time</th> */}
                                    <th width='18%'>Link</th>
                                    <th width='15%'>Practice Questions</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <span style={{ display: "none" }}>{(serialNumber = 0)}</span>
                                 {previous.map((item, i) => {
                                    return (
                                       <>
                                          {item.is_upcoming == false ? (
                                             <tr>
                                                {/* 
                              <td>{serialNumber += 1}</td>
                              */}
                                                <td>{item.subject}</td>
                                                <td>{item.perticulars}</td>
                                                <td className="text-nowrap">{item.date}</td>
                                                {/* <td>{item.time}</td> */}
                                                <td>
                                                   {item.webinar_link?.length > 0 ? (
                                                      <a href={item.webinar_link} target='blank' className='register-now text-nowrap'>
                                                         <span>Click Here</span>{" "}
                                                         <img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/know_more.png'></img>
                                                      </a>
                                                   ) : (
                                                      ""
                                                   )}
                                                </td>
                                                <td>
                                                   {item.booklet_link?.length > 0 ? (
                                                      // <a href={item.booklet_link} target='blank' className='register-now' onClick={()}>
                                                      <a
                                                         href='javascript:void(0);'
                                                         className='download-now text-nowrap'
                                                         data-toggle='modal'
                                                         data-target='#WebinarFormModal'
                                                         onClick={(e) => setRedireactUrl(item.booklet_link)}
                                                      >
                                                         <span>Download Here</span>
                                                         {/* <img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/know_more.png'></img> */}
                                                      </a>
                                                   ) : (
                                                      ""
                                                   )}
                                                </td>
                                             </tr>
                                          ) : (
                                             ""
                                          )}
                                       </>
                                    );
                                 })}
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
                  {previous.length === 0 && <div className='col-12 col-md-6 my-3 text-center mx-auto'>
                     <a
                        href='javascript:void(0);'
                        className='register-session-link d-block'
                        data-toggle='modal'
                        data-target='#WebinarFormModal'>
                        Register Here
                     </a>
                  </div>}
                  {/* 
            <div className="col-12 mt-2">
               <div className="table-wrappers">
                  <div className="jee-scholarship-table-title">Toppers' Talk Show</div>
                  <div className="table-responsive">
                     <div className="table-responsive">
                        <table className="table table-bordered jee-scholarship-table mb-0 white-space-normal">
                           <thead>
                              <tr>
                                 <th width="8%">S. No.</th>
                                 <th width="40%">Particulars</th>
                                 <th width="15%">Date</th>
                                 <th width="22%">Subject</th>
                                 <th width="18%">Session Link</th>
                                 <th width="18%">PDF Booklet</th>
                              </tr>
                           </thead>
                           <tbody>
                              <span style={{display:"none"}}>{serialNumber = 0}</span>
                              {
                              webinarData.map((item, i) => {
                              return(
                              <>    
                              {
                              item.is_upcoming == false && item.category == "topperstalk" ?
                              (
                              <tr>
                                 <td>{serialNumber += 1}</td>
                                 <td>{item.perticulars}</td>
                                 <td>{item.date}</td>
                                 <td>{item.subject}</td>
                                 <td>{item.webinar_link?.length > 0 ? (<a href={item.webinar_link} target='blank' className='register-now'><span>Click Here</span> <img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/know_more.png'></img></a>) : ""}</td>
                                 <td>{item.booklet_link?.length > 0 ? (<a href={item.booklet_link} target='blank' className = 'register-now'><span>Download</span><img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/know_more.png'></img></a>) : ""}</td>
                              </tr>
                              ):""
                              }
                              </>
                              )
                              })
                              }        
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
            */}
               </div>
            </div>
         </div>
         <div className='webinar-benefits'>
            <div className='container'>
               <div className='row'>
                  <div className='col-12'>
                     {/* <div className='top-title text-center'>Here are just a few of the many</div> */}
                     <div className='table-title text-center'>
                        Benefits of ALLEN Digital’s Free NCERT Decode Series Videos
                     </div>
                  </div>
                  <div className='col-12 col-xl-10 mx-auto mt-2 mt-md-5'>
                     <div className='row no-gutters'>
                        <div className='col-12 col-md-6'>
                           <img
                              src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/wb_desktop.png'
                              className='img-fluid d-none d-md-block'></img>
                           <img
                              src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/wb_mobile.png'
                              className='img-fluid mx-auto d-block d-md-none'></img>
                        </div>
                        <div className='col-12 col-md-6 margin-left-minus'>
                           <div className='list'>
                              <div className='icon'>1</div>
                              Easy Grasp of NCERT Concepts

                           </div>
                           <div className='list'>
                              <div className='icon'>2</div>
                              Covers the Class 11th and 12th NCERT Syllabus
                           </div>
                           <div className='list'>
                              <div className='icon'>3</div>
                              Topic-wise Live Conceptual Sessions by India’s Best Educators
                           </div>
                           <div className='list'>
                              <div className='icon'>4</div>
                              Watch Previous Video on ALLEN NEET Youtube Channel
                           </div>
                           <div className='list'>
                              <div className='icon'>5</div>
                              Practice Questions Available with PDFs to Master Every Topic

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className='live-session-webinar-bg'>
            <div className='container'>
               <div className='row'>
                  <div className='col-12'>
                     <div className='ls-title text-center'>
                        <span>What are You Waiting for?? </span>
                        <br /> Register Now for Free NCERT Decode Today!
                     </div>
                  </div>
                  <div className='col-12 text-center'>
                     <a href='https://www.youtube.com/@ALLENNEET' target='_blank' className='register-session'>
                        Watch Free Videos Here
                        <img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/hand.png' className='hand'></img>
                     </a>
                  </div>
               </div>
            </div>
         </div>
         <div className='webinar-vidoes'>
            <div className='container'>
               <div className='row'>
                  <div className='col-12'>
                     <div className='table-title text-center'>Frequently Asked Questions</div>
                  </div>
                  <div className='col-12'>
                     <div id='accordion' className='accordion-layout'>
                        <div className='accordion-item'>
                           <div className='accordion-header' id='headingOne'>
                              <button
                                 className='accordion-button'
                                 data-toggle='collapse'
                                 data-target='#collapseOne'
                                 aria-expanded='true'
                                 aria-controls='collapseOne'>
                                 Q1. How do I access the Videos of NCERT DECODE series for NEET-UG?
                              </button>
                           </div>
                           <div id='collapseOne' className='collapse show' aria-labelledby='headingOne' data-parent='#accordion'>
                              <div className='card-body'>
                                 Free Videos of NCERT DECODE Series will be available on  <a href='https://www.youtube.com/@ALLENNEET' target='_blank'>
                                    <b>ALLEN Digital’s NEET YouTube channel</b></a>. Engage and learn with India’s best educators and excel in NEET-UG Exam.

                              </div>
                           </div>
                        </div>
                        <div className='accordion-item'>
                           <div className='accordion-header' id='headingTwo'>
                              <button
                                 className='accordion-button collapsed'
                                 data-toggle='collapse'
                                 data-target='#collapseTwo'
                                 aria-expanded='false'
                                 aria-controls='collapseTwo'>
                                 Q2. Are all Videos & PDFs available free of charge?
                              </button>
                           </div>
                           <div id='collapseTwo' className='collapse' aria-labelledby='headingTwo' data-parent='#accordion'>
                              <div className='card-body'>
                                 Yes, these Videos & PDFs are completely free. Students can learn anytime, anywhere.
                              </div>
                           </div>
                        </div>
                        <div className='accordion-item'>
                           <div className='accordion-header' id='headingThree'>
                              <button
                                 className='accordion-button collapsed'
                                 data-toggle='collapse'
                                 data-target='#collapseThree'
                                 aria-expanded='false'
                                 aria-controls='collapseThree'>
                                 Q3. What is the duration of Videos?

                              </button>
                           </div>
                           <div id='collapseThree' className='collapse' aria-labelledby='headingThree' data-parent='#accordion'>
                              <div className='card-body'>
                                 Topic-wise NCERT Videos for NEET-UG will be for a duration of approx. 45 minutes.

                              </div>
                           </div>
                        </div>
                        <div className='accordion-item'>
                           <div className='accordion-header' id='headingFour'>
                              <button
                                 className='accordion-button collapsed'
                                 data-toggle='collapse'
                                 data-target='#collapseFour'
                                 aria-expanded='false'
                                 aria-controls='collapseFour'>
                                 Q4. Are these Videos & PDFs based on the NCERT syllabus?

                              </button>
                           </div>
                           <div id='collapseFour' className='collapse' aria-labelledby='headingFour' data-parent='#accordion'>
                              <div className='card-body'>
                                 Yes, these free Videos & PDFs are designed to help you grab every NCERT concept easily. These will cover NCERT-based questions and solutions for classes 11th and 12th.
                              </div>
                           </div>
                        </div>
                        <div className='accordion-item'>
                           <div className='accordion-header' id='headingFive'>
                              <button
                                 className='accordion-button collapsed'
                                 data-toggle='collapse'
                                 data-target='#collapseFive'
                                 aria-expanded='false'
                                 aria-controls='collapseFive'>
                                 Q5. Will the practice questions be available for each video/topic?

                              </button>
                           </div>
                           <div id='collapseFive' className='collapse' aria-labelledby='headingFive' data-parent='#accordion'>
                              <div className='card-body'>
                                 We understand that with learning, practice is also important. So, with every video, students will get a set of questions in PDF format which they have to solve on their own. Students can download the pdf file and start practising.
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div
            className='modal fade WebinarFormModal'
            id='WebinarFormModal'
            tabindex='-1'
            role='dialog'
            aria-labelledby='exampleModalCenterTitle'
            aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered' role='document'>
               <div className='modal-content'>
                  <div className='modal-body'>
                     <button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={(e) => {
                        setFromData({ ...formDataObj });
                        setMobileOTPReSent(false);
                        setResendOtpButtonShow(false);
                        setbuttonmobileName("Send OTP");
                        setLead_is(false);
                        setMobileOtp("");

                        setRedireactUrl("")
                        document.querySelector("#WebinarFormsModal > div > div > div > div > form").reset()
                     }}>
                        <span aria-hidden='true'>&times;</span>
                     </button>
                     <div className='webinar-form'>
                        <form id='submitForm'>
                           <div className='row'>
                              <div className='col-12'>
                                 <div className='heading'>Register for the Upcoming Sessions</div>
                              </div>
                              <div className='col-lg-6 col-12 btn-colors'>
                                 <div className='form-group'>
                                    <label for='Mobile'>Mobile</label>
                                    <div className='input-group'>
                                       <span className='input-group-text'>+91</span>
                                       <input
                                          type='number'
                                          className='form-control phone-no'
                                          id='Mobile'
                                          aria-describedby='mobilemsg'
                                          placeholder='Mobile Number'
                                          onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')"
                                          maxlength='10'
                                          minlength='10'
                                          name='student_mobile_no'
                                          onChange={handleformData}
                                          value={FormData?.mobileNo}
                                          required
                                          disabled={resendOtpButtonShow}
                                       />
                                       {!isMobileOTPReSent && (
                                          <span className='input-group-text verify-otp'>
                                             <button
                                                type='button'
                                                class='btn btn-link btn btn-link'
                                                onClick={SendMobileOtp}
                                                disabled={resendOtpButtonShow}>
                                                {buttonmobileName}
                                             </button>
                                          </span>
                                       )}
                                    </div>
                                 </div>
                              </div>
                              {/* <div className='col-md-6 col-12  btn-colors'></div> */}
                              <div className='col-lg-6 col-12  btn-colors'>
                                 <div className='form-group'>
                                    <label for='Mobile'>Verify OTP</label>
                                    <div className='verify-wrap'>
                                       <div className='input-group'>
                                          <input
                                             type='text'
                                             className='form-control'
                                             value={isMobileOtp}
                                             placeholder='Enter OTP'
                                             name='student_mobile_otp'
                                             onChange={handleformData}
                                             disabled={resendOtpButtonShow}
                                          />
                                          <span className='input-group-text verify-otp'>
                                             <button
                                                type='button'
                                                class='btn btn-link'
                                                onClick={verifyMobileOtp}
                                                disabled={resendOtpButtonShow}>
                                                Verify OTP
                                             </button>
                                          </span>
                                       </div>
                                       {isMobileOTPReSent && (
                                          <div className='resend-text'>
                                             <div>{getTimeInSec}</div>
                                          </div>
                                       )}
                                    </div>
                                 </div>
                              </div>
                              {resendOtpButtonShow && (
                                 <>
                                    <div className='col-12'>
                                       <div className='heading'>Successfully verified.</div>
                                    </div>
                                    <div className='col-12'>
                                       <div className='btn-outer'>
                                          <button
                                             type='button'
                                             className='submit-btn'
                                             data-toggle='modal'
                                             href='#WebinarFormsModal'
                                             data-dismiss='modal'>
                                             Next
                                          </button>
                                       </div>
                                    </div>
                                 </>
                              )}

                              {lead_is && (
                                 <div className='col-12'>
                                    <div className='heading'>You are already register for these campaign</div>
                                 </div>
                              )}
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div
            className='modal fade WebinarFormModal'
            id='WebinarFormsModal'
            tabindex='-1'
            role='dialog'
            aria-labelledby='exampleModalCenterTitle'
            aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered' role='document'>
               <div className='modal-content'>
                  <div className='modal-body'>
                     <button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={(e) => {
                        setFromData({ ...formDataObj });
                        setMobileOTPReSent(false);
                        setResendOtpButtonShow(false);
                        setbuttonmobileName("Send OTP");
                        setLead_is(false);
                        setMobileOtp("");
                        setRedireactUrl("")
                        document.querySelector("#WebinarFormsModal > div > div > div > div > form").reset()
                     }}>
                        <span aria-hidden='true'>&times;</span>
                     </button>
                     <div className='webinar-form'>
                        <form onSubmit={submitLeadForm}>
                           <div className='row'>
                              <div className='col-12'>
                                 <div className='heading'>Register for the Upcoming Sessions</div>
                              </div>
                              <div className='col-12 col-md-6'>
                                 <div className='form-group'>
                                    <label for='Name'>Name</label>
                                    <input
                                       type='text'
                                       className='form-control'
                                       id='Name'
                                       name='firstName'
                                       aria-describedby='usernamemsg'
                                       placeholder='Name'
                                       value={FormData?.firstName}
                                       onChange={formDatasetHendal}
                                       required
                                    />
                                 </div>
                              </div>
                              <div className='col-12 col-md-6'>
                                 <div className='form-group'>
                                    <label for='Email'>Email</label>
                                    <input
                                       type='email'
                                       pattern='.+@globex\'
                                       className='form-control'
                                       id='Email'
                                       name='email'
                                       aria-describedby='usernamemsg'
                                       placeholder='Email'
                                       onChange={formDatasetHendal}
                                       value={FormData?.email}
                                       required
                                    />
                                 </div>
                              </div>
                              <div className='col-md-6 col-12'>
                                 <div className='form-group'>
                                    <label for='State'>State</label>
                                    <select
                                       id='State'
                                       name='State'
                                       class='form-control'
                                       aria-describedby='classmsg'
                                       required
                                       onChange={formDatasetHendal}>
                                       <option value=''>Select State</option>
                                       <option value='Andaman and Nicobar Islands'>Andaman and Nicobar Islands</option>
                                       <option value='Andhra Pradesh'>Andhra Pradesh</option>
                                       <option value='Arunachal Pradesh'>Arunachal Pradesh</option>
                                       <option value='Assam'>Assam</option>
                                       <option value='Bihar'>Bihar</option>
                                       <option value='Chandigarh'>Chandigarh</option>
                                       <option value='Chhattisgarh'>Chhattisgarh</option>
                                       <option value='Dadra and Nagar Haveli'>Dadra and Nagar Haveli</option>
                                       <option value='Daman and Diu'>Daman and Diu</option>
                                       <option value='Delhi'>Delhi</option>
                                       <option value='Goa'>Goa</option>
                                       <option value='Gujarat'>Gujarat</option>
                                       <option value='Haryana'>Haryana</option>
                                       <option value='Himachal Pradesh'>Himachal Pradesh</option>
                                       <option value='Jammu and Kashmir'>Jammu and Kashmir</option>
                                       <option value='Jharkhand'>Jharkhand</option>
                                       <option value='Karnataka'>Karnataka</option>
                                       <option value='Kerala'>Kerala</option>
                                       <option value='Ladakh'>Ladakh</option>
                                       <option value='Lakshadweep'>Lakshadweep</option>
                                       <option value='Madhya Pradesh'>Madhya Pradesh</option>
                                       <option value='Maharashtra'>Maharashtra</option>
                                       <option value='Manipur'>Manipur</option>
                                       <option value='Meghalaya'>Meghalaya</option>
                                       <option value='Mizoram'>Mizoram</option>
                                       <option value='Nagaland'>Nagaland</option>
                                       <option value='Odisha'>Odisha</option>
                                       <option value='Puducherry'>Puducherry</option>
                                       <option value='Punjab'>Punjab</option>
                                       <option value='Rajasthan'>Rajasthan</option>
                                       <option value='Sikkim'>Sikkim</option>
                                       <option value='Tamil Nadu'>Tamil Nadu</option>
                                       <option value='Telangana'>Telangana</option>
                                       <option value='Tripura'>Tripura</option>
                                       <option value='Uttarakhand'>Uttarakhand</option>
                                       <option value='Uttar Pradesh'>Uttar Pradesh</option>
                                       <option value='West Bengal'>West Bengal</option>
                                    </select>
                                 </div>
                              </div>
                              <div className='col-md-6 col-12'>
                                 <div className='form-group'>
                                    <label for='City'>City</label>
                                    <input
                                       type='text'
                                       className='form-control'
                                       id='City'
                                       name='city'
                                       aria-describedby='usernamemsg'
                                       placeholder='City'
                                       required
                                       onChange={formDatasetHendal}
                                       value={FormData?.city}
                                    />
                                 </div>
                              </div>
                              <div className='col-md-6 col-12'>
                                 <div className='form-group'>
                                    <label for='Stream'>Stream</label>
                                    <select
                                       id='Stream'
                                       name='Stream'
                                       className='form-control'
                                       aria-describedby='streammsg'
                                       required
                                       onChange={formDatasetHendal}>
                                       <option value=''>Select Stream</option>
                                       <option value='NEET-UG' selected>
                                          NEET-UG
                                       </option>
                                    </select>
                                 </div>
                              </div>
                              <div className='col-12 mt-2'>
                                 <div className='form-group'>
                                    <div className='form-checkbox'>
                                       <input
                                          type='checkbox'
                                          name='TickAgreeToTC'
                                          className='form-check-input'
                                          id='authorize'
                                          value=''
                                          onChange={formDatasetHendal}
                                          required
                                       />
                                       <label className='form-check-label' for='authorize'>
                                          I agree to{" "}
                                          <a
                                             href='https://www.allendigital.in/policies'
                                             target='_blank'
                                             style={{ textDecoration: "underline", color: " rgb(0, 0, 255)" }}>
                                             Terms &amp; Conditions
                                          </a>
                                       </label>
                                    </div>
                                 </div>
                              </div>
                              <div className='col-12'>
                                 <div className='form-group'>
                                    <div className='form-checkbox'>
                                       <input
                                          type='checkbox'
                                          name='authorize_one'
                                          className='form-check-input'
                                          id='authorize_one'
                                          value=''
                                          required
                                       />
                                       <label className='form-check-label' for='authorize_one'>
                                          I authorize ALLEN Career Institute Pvt. Ltd. to send me regular updates via Phone Calls,
                                          WhatsApp, SMS, Robocalls (Automated Calls), Email, or on Postal addresses
                                       </label>
                                    </div>
                                 </div>
                              </div>
                              <div className='col-12 otp-send-button'>
                                 <div className='btn-outer'>
                                    <button type='submit' className='submit-btn' id='customotppopup'>
                                       Verify & Submit
                                    </button>
                                 </div>
                              </div>
                           </div>
                           <input
                              type='hidden'
                              id='formUrlId'
                              name='formURLName'
                              value='https://www.digital.allen.ac.in/scholarships/jee/'
                           />
                           <input type='hidden' id='formTagId' name='formTagName' value='ScheduleACallback' />
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export default WebinarsNeetNcertDecode;
