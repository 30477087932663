import React from 'react';
// import { environment } from "../../Services/HttpData";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class ArticleImage extends React.Component {
    constructor(props) {
      super(props);
    }
    render() { 
        return (
            <img className='img-fluid article-img' src={environment.baseUrlImg+this.props.data} alt="" />
        );
    }
}
 
export default ArticleImage;