import React from 'react';
// import { environment } from '../../Services/HttpData';
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class JobsBanner extends React.Component {

    constructor(props) {
        super(props);
      //   //console.log(this.props);
        this.state = {
          PageContent: [],
        };
      }
      componentWillReceiveProps(props){
        //   //console.log('jobs Banner:', props.handelEvent);
        //   let article={}
        //   article.title=props.handelEvent.title;
        //   article.content=[];
        //   props.handelEvent.content.map((item) => {
        //       if (item.status === 1) article.content.push(item);
        //     });
            // //console.log('Article',article);
        this.setState({PageContent: props.handelEvent});
      }

    render() { 
        return <>
           <div className="banner-content">
        <div className="banner-3">
            <div className="container banner-content ">
                <h4>{this.state.PageContent?.title}</h4>
                <p>{this.state.PageContent?.subtitle}</p>
            </div>
        </div>
        </div>
    
        </>;
    }
}
 
export default JobsBanner;