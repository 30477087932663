import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
export default function NeetugResultPage() {
  const [resultData, set_ResultData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(16);
  const [totalPages, setTotalPages] = useState(0);


  function getResultData() {
    if (window?.AllenNeetUgResult) {
      // set data
      set_ResultData(window?.AllenNeetUgResult);
    } else {
      setTimeout(() => {
        getResultData();
      }, 100);
    }
  }
  useEffect(() => {
    getResultData();
  }, []);
  useEffect(() => {
    const totalPages = Math.ceil(resultData.length / resultsPerPage);
    setTotalPages(totalPages);
  }, [resultData, resultsPerPage]);
  const sharetab = (e) => {
    document
      .querySelector(
        "#root > div > div > div:nth-child(3) > div > div.share-wrapper > i"
      )
      .classList.toggle("active");
    document
      .querySelector(
        "#root > div > div > div:nth-child(3) > div > div.share-wrapper > ul"
      )
      .classList.toggle("active");
  };
  const toppers = {
    autoplay: true,
    loop: true,
    infinite: false,
    nav: true,
    dots: true,
    autoplayTimeout: 4000,
    autoplayHoverPause: true,
    //navText: ["Prev", "Next"],
    smartSpeed: 800,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
        nav: true,
        slideBy: 3,
      },
      992: {
        margin: 30,
        items: 3,
        nav: true,
        slideBy: 3,
      },
    },
  };
  const testimonials = {
    margin: 30,
    autoplay: false,
    loop: true,
    infinite: false,
    nav: false,
    dots: true,
    autoplayTimeout: 4000,
    autoplayHoverPause: true,
    //navText: ["Prev", "Next"],
    smartSpeed: 800,
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      768: {
        items: 2,
        nav: true,
      },
      1200: {
        items: 3,
        nav: true,
      },
    },
  };
  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = resultData.slice(
    indexOfFirstResult,
    indexOfLastResult
  );
  const paginate = (pageNumber) => {
    if (pageNumber === "...") {
      setCurrentPage((prevPage) => prevPage + 2);
    } else {
      setCurrentPage(pageNumber);
    }
  };
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  const getPaginationRange = () => {
    const maxDisplayedPages = 3; // Number of pagination buttons to display
    const ellipsisThreshold = 2; // Number of pages after which to show ellipsis
    const rangeStart = 1;
    if (totalPages <= maxDisplayedPages) {
      return Array.from({ length: totalPages }, (_, i) => rangeStart + i);
    }
    let rangeEnd = Math.min(rangeStart + maxDisplayedPages - 1, totalPages);
    if (currentPage >= ellipsisThreshold) {
      rangeEnd = Math.min(currentPage + 2, totalPages);
    }
    const paginationRange = Array.from(
      { length: rangeEnd - rangeStart + 1 },
      (_, i) => rangeStart + i
    );
    if (rangeEnd < totalPages) {
      paginationRange.push("...");
      paginationRange.push(totalPages);
    }
    return paginationRange;
  };
  return (
    <div>
      <div className="share-wrapper">
        <i className="fa fa-share-alt share" onClick={sharetab}></i>
        <ul className="social">
          <li id="desktopwhatsappicon">
            <a
              href={`https://web.whatsapp.com/send?text=${window.location.href}`}
              target="_blank"
              title="whatsapp"
            >
              <i className="fa fa-whatsapp whatsapp"></i>
            </a>
          </li>
          <li id="mobilewhatsappicon">
            <a
              href={`https://web.whatsapp.com/send?text=${window.location.href}`}
              target="_blank"
              title="whatsapp"
            >
              <i className="fa fa-whatsapp whatsapp"></i>
            </a>
          </li>
          <li>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
              target="_blank"
              title="facebook"
            >
              <i className="fa fa-facebook-official facebook"></i>
            </a>
          </li>
          <li>
            <a
              href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
              target="_blank"
              title="twitter"
            >
              <i className="fa fa-twitter twitter"></i>
            </a>
          </li>
          <li>
            <a
              href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`}
              target="_blank"
              title="linkedin"
            >
              <i className="fa fa-linkedin linkedin"></i>
            </a>
          </li>
          {/* 
         <li><a href="#" target="_blank" title="google"><i className="fa fa-google-plus google"></i></a></li>
         */}
          {/* 
         <li><a href="#" target="_blank" title="instagram"><i className="fa fa-instagram instagram"></i></a></li>
         */}
        </ul>
      </div>
      <Helmet>
        {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
        <title>NEET-UG Result 2023 | ALLEN Digital</title>
        <meta name="description" content="ALLEN Digital has produced amazing NEET-UG Results 2023. Witness the remarkable performance of our top-performing students." />
        <meta property="og:title" content="NEET-UG Result 2023 | ALLEN Digital" />
        <meta property="og:site_name" content="ALLEN Digital" />
        <meta property="og:url" content="https://digital.allen.ac.in/results/neet-ug" />
        <meta property="og:description" content="ALLEN Digital has produced amazing NEET-UG Results 2023. Witness the remarkable performance of our top-performing students." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://digital.allen.ac.in/static/media/logo-new-r.4a9d1fde3fd2a6868a621b27352bda3a.svg" />
        {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
      </Helmet>
      <div className="result-page-container">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="result-page-title">
                NEET-UG 2023 RESULTS
              </div>
            </div>
            <div className="col-8 col-md-6 col-lg-12 mx-auto mb-4 mb-lg-0">
              <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/neet-ug-2023/jeet-bharose-kee.png" className="img-fluid d-block mx-auto"></img>
            </div>
          </div>
          <div className="row row-cols-2 row-cols-md-4 row-cols-lg-4 result-highlight-gap justify-content-center">
            <div className="col text-center padding-right5">
              <div className="result-star">
                <div className="data">7</div>
              </div>
              <div className="result-highlight-point">
                Students Scored More <br className="d-md-none d-xl-block" /> Than 700 Marks
              </div>
            </div>
            <div className="col text-center padding-left0 padding-right5">
              <div className="result-star">
                <div className="data">90</div>
              </div>
              <div className="result-highlight-point">
                Students Scored More <br className="d-md-none d-xl-block" /> Than 650 Marks
              </div>
            </div>
            <div className="col text-center padding-right5">
              <div className="result-star">
                <div className="data">18</div>
              </div>
              <div className="result-highlight-point">
                Students in the Top 1000 <br className="d-md-none d-xl-block" />All India Rank
              </div>
            </div>
            <div className="col text-center padding-left0 padding-right5">
              <div className="result-star">
                <div className="data">37</div>
              </div>
              <div className="result-highlight-point">
                Students in the Top 2500 <br className="d-md-none d-xl-block" />All India Rank
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="result-topper-container">
                <div className="toppers-heading col-12 col-md-6 mx-auto col-lg-12 p-0"><img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/Toppers_Heading.png" className="img-fluid d-block mx-auto"></img></div>
                <div className="container">
                  <OwlCarousel {...toppers}>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/neet-ug-2023/student/21236865.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">LIVE</div>
                        <div className="student-percentages">AIR  - 121</div>
                        <div className="studentnames text-uppercase">KAVIYARASU</div>
                      </div>
                    </div>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/neet-ug-2023/student/21299545.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">LIVE</div>
                        <div className="student-percentages">AIR - 239</div>
                        <div className="studentnames text-uppercase">ANUBHAV JAROLA</div>
                      </div>
                    </div>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/neet-ug-2023/student/22970879.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">LIVE</div>
                        <div className="student-percentages">AIR - 711</div>
                        <div className="studentnames text-uppercase">SHAMANJAN BERA</div>
                      </div>
                    </div>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/neet-ug-2023/student/21361596.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">OLTS</div>
                        <div className="student-percentages">AIR - 21</div>
                        <div className="studentnames text-uppercase">PRAKHAR AGRAWAL</div>
                      </div>
                    </div>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/neet-ug-2023/student/21369109.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">OLTS</div>
                        <div className="student-percentages">AIR - 56</div>
                        <div className="studentnames text-uppercase">SHRADDHA VADLAMANI</div>
                      </div>
                    </div>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/neet-ug-2023/student/1111161320.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">OLTS</div>
                        <div className="student-percentages">AIR - 92</div>
                        <div className="studentnames text-uppercase">Navya Mishra</div>
                      </div>
                    </div>

                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="result-student-container">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="student-data-title">
                <div className="title">2647</div>
                <div className="subtitle">STUDENTS</div>
              </div>
              <div className="student-subitle">
                Qualified in the Very First Year of Our Online Coaching
              </div>
            </div>
          </div>
          <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4">
            {currentResults.map((data) => {
              return (
                <>
                  <div
                    className="col text-center student-updated-data"
                    key={data.id}
                  >
                    <div className="student-picture-wrap">
                      <img
                        src={
                          data.is_Img === "no"
                            ? "https://cdn.allendigital.in/fe-bsite/results/NO_IMAGE_FOUND.png"
                            : `https://cdn.digital.allen.ac.in/fe-bsite/result_page/neet-ug-2023/student/${data["fno"]}.png`
                        }
                        alt="temp"
                        className="student-picture"
                      />
                    </div>
                    <div className="olts">{data.Type}</div>
                    <div className="student-percentage">
                      {data.final_Total1}
                    </div>
                    <div className="studentname text-uppercase">{data.cname1}</div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="pagination">
                <button onClick={prevPage} disabled={currentPage === 1}>
                  <span className="d-md-none" style={{ fontSize: "24px", lineHeight: "0px", paddingBottom: "5px" }}>{"‹"}</span> <span className="d-none d-md-flex">PREVIOUS</span>
                </button>
                {getPaginationRange().map((pageNumber, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(pageNumber)}
                    className={currentPage === pageNumber ? "active" : ""}
                  >
                    {pageNumber}
                  </button>
                ))}
                <button
                  onClick={nextPage}
                  disabled={currentPage === totalPages}
                >
                  <span className="d-md-none" style={{ fontSize: "24px", lineHeight: "0px", paddingBottom: "5px" }}>{"›"}</span> <span className="d-none d-md-flex">NEXT</span>
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="talk-to-expert">
                <img
                  src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/jee-main-2023-session-2/talk-to-expert.png"
                  className="img-fluid"
                />
                <div className="div">
                  <div className="title">Talk To Our Expert</div>
                  <div className="connect-wrap">
                    <a href="tel:+ 919513736499" className="connect-btn">
                      <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/jee-main-2023-session-2/call-now.png" />{" "}
                      Call Now
                    </a>
                    <a href="/contact-us" className="connect-btn">
                      <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/jee-main-2023-session-2/message-now.png" />{" "}
                      Drop us a message
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="student-testimonials-container">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div class="result-page-title">
                What Students Say About ALLEN?
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <OwlCarousel {...testimonials}>
                <div class="item testimonials-item">
                  <img
                    src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/neet-ug-2023/testimonial/anubhav-jarola.png"
                    className="testimonials-picture"
                  />
                  <div className="div">
                    <div className="testimonials-name">ANUBHAV JAROLA</div>
                    <div className="testimonials-course-name">
                      AIR - 239
                    </div>
                  </div>
                  <div className="testimonials-content">
                    Over the past year, my encounter with ALLEN Digital has been exceptionally gratifying. The instructors were incredibly helpful and consistently accessible to assist with our academic concerns, consistently inspiring us along the way. The test series provided a valuable emulation of real exam circumstances. All in all, my involvement with ALLEN Digital has been immensely pleasing, equipping me with the essential resources, guidance, and readiness for my academic pursuit.


                  </div>
                  <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/jee-main-2023-session-2/quote.png" className="testimonials-quote"></img>
                </div>
                <div class="item testimonials-item">
                  <img
                    src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/neet-ug-2023/testimonial/rumaan-rafiq.png"
                    className="testimonials-picture"
                  />
                  <div className="div">
                    <div className="testimonials-name">RUMAAN RAFIQ</div>
                    <div className="testimonials-course-name">
                      AIR - 998
                    </div>
                  </div>
                  <div className="testimonials-content">
                    I would like to express my deepest appreciation as I share my experience with ALLEN Digital during my NEET preparation. Undoubtedly, they went above and beyond my expectations and catered to all my requirements. Despite studying from home, I never felt isolated thanks to the unwavering dedication of my teachers and the entire faculty. I extend my heartfelt gratitude to the entire ALLEN Digital system and, specifically, to my teachers for making my journey seamless and unforgettable.
                  </div>
                  <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/jee-main-2023-session-2/quote.png" className="testimonials-quote"></img>
                </div>
                <div class="item testimonials-item">
                  <img
                    src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/neet-ug-2023/testimonial/satwik-singhal.png"
                    className="testimonials-picture"
                  />
                  <div className="div">
                    <div className="testimonials-name">SATWIK SINGHAL</div>
                    <div className="testimonials-course-name">
                      AIR - 1012
                    </div>
                  </div>
                  <div className="testimonials-content">
                    I enrolled in ALLEN Digital with the intention of successfully clearing the NEET exam with flying colors. I am extremely thankful to the committed educators at ALLEN Digital who consistently provided me with guidance in pursuit of this objective. With their invaluable support, I was able to attain an impressive rank in NEET-UG 2023. I feel privileged to have received timely mentorship from the institute's exceptional faculty members.<br className="d-none d-lg-block" /><br className="d-none d-lg-block" />
                  </div>
                  <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/jee-main-2023-session-2/quote.png" className="testimonials-quote"></img>
                </div>

              </OwlCarousel>
            </div>
          </div>

          {/* <div className="row mt-5">
            <div className="col-12">
              <img
                className="img-fluid d-block mx-auto"
                src="https://cdn.allendigital.in/fe-bsite/result_page/desktop_result_banner.png"
              ></img>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
