import React, { useState } from "react";
import CourseCardGrid from "./CourseCardGrid";
import CourseCardList from "./CourseCardList";
import nocourse from "../../images/no-course.png";
import { useEffect } from "react";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);
export default function FreemiumMajorCourse() {
  const [showHideGrid, setshowHideGrid] = useState(true);
  const [showHideList, setshowHideList] = useState(false);
  const [listItems, SetlistItems] = useState([]);
  const viewlist = (name) => {
    switch (name) {
      case "grid":
        setshowHideGrid(true);
        setshowHideList(false);
        break;
      case "list":
        setshowHideGrid(false);
        setshowHideList(true);
        break;
      default:
    }
  };
  const GetProductData = () => {
    const testSeries = [
      "jee-main-major-online-test-series-eng",
      "jee-main-advanced-major-online-test-series-eng",
      "neet-ug-major-online-test-series-target-eng",
    ];
    let requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filters: [],
        sort: "",
        search: "",
      }),
    };

    fetch(environment.ecomApiUrl + "product/list", requestOptions).then(
      (resp) => {
        // fetching api url for courses listing
        resp.json().then((result) => {
          const data = result.data.records ? result.data.records : [];
          const FreeMimu = data.filter((val, ind) => val?.is_freemium == 1);
          const TestSeriousData = data.filter(
            (val, ind) => testSeries.indexOf(val?.slug) >= 0
          );
          //   console.log(FreeMimu,TestSeriousData)
          SetlistItems(
            window.location.href.includes("online-test-series-major")
              ? TestSeriousData
              : FreeMimu
          ); // set result in state this will set current/updated value of
        });
      }
    );
  };
  useEffect(() => {
    GetProductData();
  }, []);
  return (
    <div className="container">
      <div className="  col-12 col-sm-12 px-4 pt-4 all-courses col-md-11 pl-md-5">
        <div className="pl-5">
          <i
            className={
              showHideGrid
                ? "active fas fa-th changeview mx-3"
                : "fas fa-th changeview m-3"
            }
            onClick={() => viewlist("grid")}
          ></i>
          <i
            className={
              !showHideGrid
                ? "active fas fa-list changeview mr-3"
                : "fas fa-list changeview mr-3"
            }
            onClick={() => viewlist("list")}
          ></i>
        </div>
        {showHideGrid && (
          <div className="row m-0 pr-5 pl-5">
            {listItems.length ? (
              listItems.map((item) => {
                return (
                  <div className=" col-xl-4 col-lg-4  my-2 col-12 col-md-6 p-3">
                    <CourseCardGrid
                      key={item.id}
                      productId={item.id}
                      image={item.image}
                      slug={item.slug}
                      title={item.title}
                      price={item.price}
                      display_price={item.display_price}
                      description={item.description}
                      is_freemium={item.is_freemium}
                      commencement_date={item.commencement_date}
                      courseData={item}
                    />
                  </div>
                );
              })
            ) : (
              <div
                className="justify-content-center d-flex mx-auto"
                style={{ margin: "auto" }}
              >
                <div className="text-center">
                  <img src={nocourse} className=""></img>
                  <p className="m-1">
                    <b>No Course Found</b>
                  </p>
                </div>
              </div>
            )}
          </div>
        )}

        {showHideList && (
          <div className="pr-5 pl-5">
            {listItems.length ? (
              listItems.map((item) => {
                return (
                  <CourseCardList
                    key={item.id}
                    productId={item.id}
                    image={item.image}
                    slug={item.slug}
                    title={item.title}
                    price={item.price}
                    display_price={item.display_price}
                    description={item.description}
                    is_freemium={item.is_freemium}
                    commencement_date={item.commencement_date}
                  />
                );
              })
            ) : (
              <div
                className="justify-content-center d-flex mx-auto"
                style={{ margin: "auto" }}
              >
                <div className="text-center">
                  <img src={nocourse} className=""></img>
                  <p className="m-1">
                    <b>No Course Found</b>
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
