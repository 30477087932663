import React, {useEffect, useState} from "react";
import cartImg from "../images/cart-img-new.png";
import starFill from "../images/star-fill.png";
import greenCheckIcon from "../images/green-correct-icon.png";
import plaIconGreen from "../images/play-store-green.png";
import appIconGreen from "../images/app-store-green.png";
import formMan from "../images/form-man.png";
import videoProgram from "../images/video-program.png";
import digitalFeartueBg from "../images/digital-feature-bg-img.png";
import ellipseImg from "../images/ellipse-310.png";
import keyFeaturesImg from "../images/key-features-img.png";
import { Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";
import { toast } from "react-toastify";
// import { environment } from "../Services/HttpData";
import IntlTelInput from "react-intl-tel-input";
import country from "../js/countries+states.json";
import AboutUsBanner from "../components/AboutUs/AoutusBanner";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
const FreeResourceJeeMain = () => {
  /* constructor(props) {
    super(props);
    this.wid_data = null ;
    this.wid = null;
    this.state = {
        PageContent: []
    };
  } */

  const [PageContent,setPageContent] = useState([]);
  let wid_data = null ;
  const [wid,setWid] = useState(false);
  let counter = 0;
 

  
  useEffect(()=>{
    setInterval(() => {
        
     
    
        wid_data =  localStorage.getItem("userWID") ? JSON.parse( localStorage.getItem("userWID") ) : null;
        //console.log('wid',wid_data)
        if(wid_data){
            if(wid_data.results){
                if(wid_data.results.length){
                    if(wid_data.results[0].wid){
                        setWid(wid_data.results[0].wid);
                    }
                }
            }
        }
    },1000);
        // POST request using fetch with async/await
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ "keyword": ''})
        // };
        // fetch(environment.baseUrl+'view_list_all', requestOptions)
        // .then(async response => {
        //     // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        //     const data = /* isJson && */ await response.json();
        //     // check for error response
        //     // //console.log('view-lists',data);
        //     if (!response.ok) {
        //         // get error message from body or default to response status
        //         const error = (data && data.message) || response.status;
        //         return Promise.reject(error);
        //     }
        //     let index=data.data.findIndex((item)=>item.slug==="pyp-neet");
        //     ////console.log("section",data.data[index].sections[0].content[0].description);
        //     let sections = {};
        //     data.data[index]?.sections.map((item) => {
        //         // //console.log(item)
        //       switch (item.id) {
        //         case 145:
        //           sections.banner = item;
        //           break;
        //         case 145:
        //           sections.content = item;
        //           break;
        //       }
        //     });
        //     setPageContent( data.data[index].sections[0].content[0] );
        //     ////console.log('advanced',this.state.PageContent);
        // })
        // .catch(error => {
        //     setPageContent( error.toString() );
        //     console.error('There was an error!', error);
        // });
      
        const getViewListAll = () => {
            if (!window?.AllenDigitalFEBSite) {
                setTimeout(() => {
                getViewListAll();
              }, 50);
              return false
            }
            let index=window?.AllenDigitalFEBSite.data.findIndex((item)=>item.slug==="pyp-neet");
            ////console.log("section",data.data[index].sections[0].content[0].description);
            let sections = {};
            window?.AllenDigitalFEBSite.data[index]?.sections.map((item) => {
                // //console.log(item)
              switch (item.id) {
                case 145:
                  sections.banner = item;
                  break;
                case 145:
                  sections.content = item;
                  break;
              }
            });
            setPageContent( window?.AllenDigitalFEBSite.data[index].sections[0].content[0] );
          };
          getViewListAll();
  },[]);

  return (
    <>
      <AboutUsBanner
        handelEvent={() => {}}
        slug="NEET Previous Year Papers"
      />
      {
          /* wid? */
          (
              <>
                  <div className="tab-content" id="pills-tabContent">
                      <div className=" app-features">
                          <div className="container py-5">
                          <div className="">
                              <h2 className="font-weight-bold text-center mb-3">{PageContent.title}</h2>
                              <div className="card py-5 course-card">
                              <div className="row">
                              

                              <div className="col-12 col-lg-12" dangerouslySetInnerHTML={{
                                      __html:`<script>
                                      const enviroPath = '';
                                   </script>
                                   <style>
                                   .course-card{
                                    background: #ffffff !important;
                                    }
                                      .ace-table {
                                      font-family: sans-serif;
                                      border: 1px solid #ddd;
                                      border-collapse: collapse;
                                      background-color: #ebebeb4a;
                                      }
                                      .bg-1 {
                                      background-color: #ebebeb4a;
                                      padding: 10px 20px 20px 20px;
                                      border: solid 1px #e7e5e2;
                                      margin-bottom: 30px;
                                      padding-top: 0;
                                      }
                                      .bg-1 h2 {
                                      text-align: center;
                                      font-family: sans-serif;
                                      color: #555;
                                      margin: 10px 0px;
                                      font-size: 20px !important;
                                      }
                                      .ace-table th {
                                      border: 1px solid #ddd;
                                      padding: 8px;
                                      font-family: sans-serif;
                                      }
                                      .ace-table td {
                                      border: 1px solid #ddd;
                                      padding: 8px;
                                      font-family: sans-serif;
                                      }
                                      .ace-table a {
                                      text-decoration: none;
                                      color: #72c02c !important;
                                      font-family: sans-serif;
                                      }
                                   </style>
                                   <section class="bg-1">
                                      <h2>NEET 2022 Question Papers with Solutions PDF
                                      </h2>
                                      <!--   NEET Paper 2022 -->
                                      <table class="table  ace-table" width="100%">
                                         <tbody>
                                            <tr>
                                               <td rowspan="2" style="vertical-align: middle;" align="center"><strong>Subject</strong></td>
                                               <td colspan="2" valign="middle" align="center"><strong>Paper With Solution</strong></td>
                                            </tr>
                                            <tr>
                                            </tr>
                                            <tr>
                                               <td valign="middle">Biology Previous Years’ Question Papers with Solutions PDF <b>(English)</b> </td>
                                               <td valign="middle" align="center"><a target="_blank" href="https://adpl-free-resources.allendigital.in/NEET-2022/Biology_Paper_with_Answer_Solution.pdf">Download</a></td>
                                            </tr>
                                            <tr>
                                               <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>(English)</b> </td>
                                               <td valign="middle" align="center"><a target="_blank" href="https://adpl-free-resources.allendigital.in/NEET-2022/Chemistry_Paper_with_Answer_Solutions.pdf">Download</a></td>
                                            </tr>
                                            <tr>
                                               <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>(English)</b> </td>
                                               <td valign="middle" align="center"><a target="_blank" href="https://adpl-free-resources.allendigital.in/NEET-2022/Physics_Paper_with_Answer_Solutions.pdf">Download</a></td>
                                            </tr>
                                            <tr>
                                               <td valign="middle">Full Previous Years’ Question Papers with Solutions PDF <b>(English)</b> </td>
                                               <td valign="middle" align="center"><a target="_blank" href="https://adpl-free-resources.allendigital.in/NEET-2022/NEET_Full_Paper_2022.pdf">Download</a></td>
                                            </tr>
                                         </tbody>
                                      </table>
                                   </section>
                                   <section class="bg-1">
                                      <h2>NEET 2021 Question Papers with Solutions PDF
                                      </h2>
                                      <!--   NEET Paper 2021 -->
                                      <table class="table  ace-table" width="100%">
                                         <tbody>
                                            <tr>
                                               <td rowspan="2" style="vertical-align: middle;" align="center"><strong>Subject</strong></td>
                                               <td colspan="2" valign="middle" align="center"><strong>Paper With Solution</strong></td>
                                            </tr>
                                            <tr>
                                            </tr>
                                            <tr>
                                               <td valign="middle">Biology Previous Years’ Question Papers with Solutions PDF <b>(English)</b> </td>
                                               <td valign="middle" align="center"><a target="_blank" href="https://adpl-free-resources.allendigital.in/NEET-2021/BIOLOGY/NEET_BIOLOGY_2021.pdf">Download</a></td>
                                            </tr>
                                            <tr>
                                               <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>(English)</b> </td>
                                               <td valign="middle" align="center"><a target="_blank" href="https://adpl-free-resources.allendigital.in/NEET-2021/CHEMISTRY/NEET_CHEMISTRY_2021.pdf">Download</a></td>
                                            </tr>
                                            <tr>
                                               <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>(English)</b> </td>
                                               <td valign="middle" align="center"><a target="_blank" href="https://adpl-free-resources.allendigital.in/NEET-2021/PHYSICS/NEET_PHYSICS_2021.pdf">Download</a></td>
                                            </tr>
                                         </tbody>
                                      </table>
                                   </section>
                                   <section class="bg-1">
                                      <h2>NEET 2020 Question Papers with Solutions PDF
                                      </h2>
                                      <!--   NEET Paper 2020 -->
                                      <table class="table  ace-table" width="100%">
                                         <tbody>
                                            <tr>
                                               <td rowspan="2" style="vertical-align: middle;" align="center"><strong>Subject</strong></td>
                                               <td colspan="2" valign="middle" align="center"><strong>Paper With Solution</strong></td>
                                            </tr>
                                            <tr>
                                            </tr>
                                            <tr>
                                               <td valign="middle">Biology Previous Years’ Question Papers with Solutions PDF <b>(English)</b> </td>
                                               <td valign="middle" align="center"><a target="_blank" href="https://adpl-free-resources.allendigital.in/NEET-2020/BIOLOGY/NEET_BIOLOGY_2020.pdf">Download</a></td>
                                            </tr>
                                            <tr>
                                               <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>(English)</b> </td>
                                               <td valign="middle" align="center"><a target="_blank" href="https://adpl-free-resources.allendigital.in/NEET-2020/CHEMISTRY/NEET_CHEMISTRY_2020.pdf">Download</a></td>
                                            </tr>
                                            <tr>
                                               <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>(English)</b> </td>
                                               <td valign="middle" align="center"><a target="_blank" href="https://adpl-free-resources.allendigital.in/NEET-2020/PHYSICS/NEET_PHYSICS_2020.pdf">Download</a></td>
                                            </tr>
                                         </tbody>
                                      </table>
                                   </section>
                                   <section class="bg-1">
                                      <h2>NEET 2019 Question Papers with Solutions PDF
                                      </h2>
                                      <!--   NEET Paper 2019 -->
                                      <table class="table  ace-table" width="100%">
                                         <tbody>
                                            <tr>
                                               <td rowspan="2" style="vertical-align: middle;" align="center"><strong>Subject</strong></td>
                                               <td colspan="2" valign="middle" align="center"><strong>Paper With Solution</strong></td>
                                            </tr>
                                            <tr>
                                            </tr>
                                            <tr>
                                               <td valign="middle">Biology Previous Years’ Question Papers with Solutions PDF <b>(English)</b> </td>
                                               <td valign="middle" align="center"><a target="_blank" href="https://adpl-free-resources.allendigital.in/NEET-2019/BIOLOGY/NEET_BIOLOGY_2019.pdf">Download</a></td>
                                            </tr>
                                            <tr>
                                               <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>(English)</b> </td>
                                               <td valign="middle" align="center"><a target="_blank" href="https://adpl-free-resources.allendigital.in/NEET-2019/CHEMISTRY/NEET_CHEMISTRY_2019.pdf">Download</a></td>
                                            </tr>
                                            <tr>
                                               <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>(English)</b> </td>
                                               <td valign="middle" align="center"><a target="_blank" href="https://adpl-free-resources.allendigital.in/NEET-2019/PHYSICS/NEET_PHYSICS_2019.pdf">Download</a></td>
                                            </tr>
                                         </tbody>
                                      </table>
                                   </section>
                                   <section class="bg-1">
                                      <h2>NEET 2018 Question Papers with Solutions PDF
                                      </h2>
                                      <!--   NEET Paper 2018 -->
                                      <table class="table  ace-table" width="100%">
                                         <tbody>
                                            <tr>
                                               <td rowspan="2" style="vertical-align: middle;" align="center"><strong>Subject</strong></td>
                                               <td colspan="2" valign="middle" align="center"><strong>Paper With Solution</strong></td>
                                            </tr>
                                            <tr>
                                            </tr>
                                            <tr>
                                               <td valign="middle">Biology Previous Years’ Question Papers with Solutions PDF <b>(English)</b> </td>
                                               <td valign="middle" align="center"><a target="_blank" href="https://adpl-free-resources.allendigital.in/NEET-2018/BIOLOGY/NEET_BIOLOGY_2018.pdf">Download</a></td>
                                            </tr>
                                            <tr>
                                               <td valign="middle">Chemistry Previous Years’ Question Papers with Solutions PDF <b>(English)</b> </td>
                                               <td valign="middle" align="center"><a target="_blank" href="https://adpl-free-resources.allendigital.in/NEET-2018/CHEMISTRY/NEET_CHEMISTRY_2018.pdf">Download</a></td>
                                            </tr>
                                            <tr>
                                               <td valign="middle">Physics Previous Years’ Question Papers with Solutions PDF <b>(English)</b> </td>
                                               <td valign="middle" align="center"><a target="_blank" href="https://adpl-free-resources.allendigital.in/NEET-2018/PHYSICS/NEET_PHYSICS_2018.pdf">Download</a></td>
                                            </tr>
                                         </tbody>
                                      </table>
                                   </section>
                                   
                                   
                                   
                                   
                                  ` }}>
                                  
                              </div>
                              </div>
                              </div>
                          </div>
                          </div>
                      </div>
                  </div>
              </>
          )/* :
          (
              <>
                  <div className="tab-content" id="pills-tabContent">
                      <div className=" app-features">
                          <div className="container py-5">
                          <div className="">
                              <h2 className="font-weight-bold text-center mb-3">{PageContent.title}</h2>
                              <div className="card py-5 course-card">
                              <div className="row">
                              

                              <div className="col-12 col-lg-12 text-center" >{wid}
                                  Please Login first
                              </div>
                              </div>
                              </div>
                          </div>
                          </div>
                      </div>
                  </div>
              </>
          ) */
      }
     </>
  );

  
}

export default FreeResourceJeeMain;