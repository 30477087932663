import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";


const ResendOtp = (props) => {
  const [resendTime, setresendTime] = useState(0);
  const [callFun, setfun] = useState(false);
  const { fieldName, value, country_code, resendotpFunction, TypeButton } =
    props;

  const resendSubmit = () => {
    setresendTime(60);
    callFun
      ? resendotpFunction(fieldName, value, country_code, toast)
      : setfun(true);
    
    const timer = setInterval(() => {
      setresendTime((pre) => {
        if (pre > 0) {
          return pre - 1;
        } else {
          clearInterval(timer);
          return 0;
        }
      });
    }, 1000);
  };

  useEffect(() => {
    resendSubmit();
  }, []);

  return (
    <>
      {resendTime === 0 ? (
        TypeButton === "Login" ? (<div className="m-auto">
        <span className="pt-3">
          Don't receive OTP ?{" "}
          <span
            className="text-success cursor-pointer"
            style={{ cursor: "pointer" }}
            onClick={resendSubmit}
          >
            Resend
          </span>
        </span>
      </div>): (<button
          type="button"
          className="btn btn-primary nav-btn m-2 mb-2"
          style={{ padding: "3px 16px", margin: "7px 0 0 14px" }}
          onClick={resendSubmit}
        >
          Resend
        </button>)
      ) : (
        <span
          style={{ fontSize: "14px", fontWeight: "700", marginLeft: "3px" }}
        >
          Resend OTP in {resendTime < 10 ? "0" + resendTime : resendTime} sec{" "}
        </span>
      )}
    </>
  );
};

export default ResendOtp;
