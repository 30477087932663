import React, {useEffect, useState} from "react";
import cartImg from "../images/cart-img-new.png";
import starFill from "../images/star-fill.png";
import greenCheckIcon from "../images/green-correct-icon.png";
import plaIconGreen from "../images/play-store-green.png";
import appIconGreen from "../images/app-store-green.png";
import formMan from "../images/form-man.png";
import videoProgram from "../images/video-program.png";
import digitalFeartueBg from "../images/digital-feature-bg-img.png";
import ellipseImg from "../images/ellipse-310.png";
import keyFeaturesImg from "../images/key-features-img.png";
import { Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";
import { toast } from "react-toastify";
// import { environment } from "../Services/HttpData";
import IntlTelInput from "react-intl-tel-input";
import country from "../js/countries+states.json"
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
const JeeAdvancedLeader = () => {
  /* constructor(props) {
    super(props);
    this.wid_data = null ;
    this.wid = null;
    this.state = {
        PageContent: []
    };
  } */

  const [PageContent,setPageContent] = useState([]);
  let wid_data = null ;
  const [wid,setWid] = useState(false);
  let counter = 0;
 

  
  useEffect(()=>{
    setInterval(() => {
        
     
    
        wid_data =  localStorage.getItem("userWID") ? JSON.parse( localStorage.getItem("userWID") ) : null;
        //console.log('wid',wid_data)
        if(wid_data){
            if(wid_data.results){
                if(wid_data.results.length){
                    if(wid_data.results[0].wid){
                        setWid(wid_data.results[0].wid);
                    }
                }
            }
        }
    },1000);
        // POST request using fetch with async/await
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ "keyword": ''})
        // };
        // fetch(environment.baseUrl+'view_list_all', requestOptions)
        // .then(async response => {
        //     // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        //     const data = /* isJson && */ await response.json();
        //     // check for error response
        //     // //console.log('view-lists',data);
        //     if (!response.ok) {
        //         // get error message from body or default to response status
        //         const error = (data && data.message) || response.status;
        //         return Promise.reject(error);
        //     }
        //     let index=data.data.findIndex((item)=>item.slug==="/jee-advanced-leader");
        //     ////console.log("section",data.data[index].sections[0].content[0].description);
        //     let sections = {};
        //     data.data[index]?.sections.map((item) => {
        //         // //console.log(item)
        //       switch (item.id) {
        //         case 151:
        //           sections.banner = item;
        //           break;
        //         case 151:
        //           sections.content = item;
        //           break;
        //       }
        //     });
        //     setPageContent( data.data[index].sections[0].content[0] );
        //     ////console.log('advanced',this.state.PageContent);
        // })
        // .catch(error => {
        //     setPageContent( error.toString() );
        //     console.error('There was an error!', error);
        // });
      
      //
      const getViewListAll = () => {
        if (!window?.AllenDigitalFEBSite) {
          setTimeout(() => {
            getViewListAll();
          }, 50);
          return false
        }
        let index=window?.AllenDigitalFEBSite.data.findIndex((item)=>item.slug==="/jee-advanced-leader");
        ////console.log("section",data.data[index].sections[0].content[0].description);
        let sections = {};
        window?.AllenDigitalFEBSite.data[index]?.sections.map((item) => {
            // //console.log(item)
          switch (item.id) {
            case 151:
              sections.banner = item;
              break;
            case 151:
              sections.content = item;
              break;
          }
        });
        setPageContent( window?.AllenDigitalFEBSite.data[index].sections[0].content[0] );
      };
      getViewListAll();
  },[]);

  return (
    <>
             
      {
          wid?
          (
              <>
                  <div className="tab-content" id="pills-tabContent">
                      <div className=" app-features">
                          <div className="container py-5">
                          <div className="">
                              <h2 className="font-weight-bold text-center mb-3">{PageContent.title}</h2>
                              <div className="card py-5 course-card">
                              <div className="row">
                              

                              <div className="col-12 col-lg-12" dangerouslySetInnerHTML={{
                                      __html: PageContent.description}}>
                                  
                              </div>
                              </div>
                              </div>
                          </div>
                          </div>
                      </div>
                  </div>
              </>
          ):
          (
              <>
                  <div className="tab-content" id="pills-tabContent">
                      <div className=" app-features">
                          <div className="container py-5">
                          <div className="">
                              <h2 className="font-weight-bold text-center mb-3">{PageContent.title}</h2>
                              <div className="card py-5 course-card">
                              <div className="row">
                              

                              <div className="col-12 col-lg-12 text-center" >{wid}
                                  Please Login first
                              </div>
                              </div>
                              </div>
                          </div>
                          </div>
                      </div>
                  </div>
              </>
          )
      }
     </>
  );

}

export default JeeAdvancedLeader;
