import React from "react";
import arrowDown from "../../images/dropdown-arrow.png";
class ArticlesFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filtersData: [],
        };
    }
    async componentDidMount() {
        // //console.log(this.props.match.params.slug);
        this.getArticles();
      }
      async getArticles() {
        this.setState({ filtersData: [] })
      }
    /* async componentWillReceiveProps(props) {
        //console.log(props.handelEvent);
        this.setState({ filtersData: props.handelEvent });
    } */
    render() {
        return (
            <>
                <nav className=" col-lg-3 col-md-3 d-none d-lg-block sidebar filters">
                    <div className="sidebar-sticky">
                        <ul className="nav flex-column p-2 filter-ul">
                            {(this.state.filtersData!=undefined && this.state.filtersData.length>0) && this.state.filtersData.map((item, indx) => (
                                <li className="nav-item mb-3" key={indx}>
                                    <div
                                        className="row m-0 align-items-center mb-2 cursor-pointer articles-menu-list"
                                        data-toggle="collapse"
                                        data-target={`#${item?.id + indx.toString()}`}
                                        aria-expanded="true"
                                        aria-controls={item?.id + indx}
                                    >
                                        <h5 className="p-0 my-auto">
                                            <button className="btn btn-link filter-collapse-link p-0">
                                                {item?.title}
                                            </button>
                                        </h5>
                                        <img
                                            src={arrowDown}
                                            className="ml-auto"
                                            width="16px"
                                            height="10px"
                                        />
                                    </div>
                                    <div
                                        id={item?.id + indx}
                                        className="collapse show"
                                        aria-labelledby="headingOne"
                                        data-parent="#accordion"
                                    >
                                        <div className="card-body col" style={{ padding: "0" }}>
                                            {(item?.sub_category!=undefined && item?.sub_category.length>0) && item?.sub_category.map((itm, i) => (
                                                <div
                                                    className="row m-0 align-items-center custom-checkbox"
                                                    data-target={itm.id + indx + i}
                                                    key={i}
                                                    onClick={() => {
                                                        window.location = '/article-details/'+itm?.slug

                                                    }}
                                                >
                                                    <label htmlFor={itm.id + indx + i} className="ml-4 mb-0">
                                                        {itm?.title}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </nav>
            </>
        );
    }
}

export default ArticlesFilter;
