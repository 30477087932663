import React from "react";
import CardImage from "../../images/Group38085.png";
// import { environment } from "../../Services/HttpData";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class HomeLegacy extends React.Component {
  constructor(props) {
    super(props);
    // //console.log(this.props);
    this.state = {
      PageContent: {},
    };
  }
  componentWillReceiveProps(props) {
    // //console.log(Array.isArray(props.handelEvent?.content),props.handelEvent?.content);
    let items = props.handelEvent;
    if (Array.isArray(props.handelEvent?.content)) {
      let contents = props.handelEvent?.content;
      let cindex = contents.findIndex(item => item.id === 393)
      // //console.log(contents[cindex]);
      // items.content=contents.filter((item)=>item.status===1)
      items.content = contents[cindex]
    }
    else {
      // //console.log(props.handelEvent?.content);
      items.content = props.handelEvent?.content;
    }
    this.setState({ PageContent: items });
  }
  render() {

    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="third-row">
                <div className="row align-items-center">
                  <div className="col-12 col-lg-5 align-self-center">
                    <img
                      src={
                        this.state.PageContent?.image
                          ? environment.baseUrlImg + this.state.PageContent?.image
                          : CardImage
                      }
                      alt=""
                      className="img-fluid d-block mx-auto"
                    />
                  </div>

                  <div className="col-12 col-lg-7">
                    <h6 className="mb-3 sub-title">
                      {this.state.PageContent?.title}
                    </h6>
                    <h3 className="mb-3 title">
                      {this.state.PageContent?.subtitle}
                    </h3>
                    <a href="about-us">
                      <button
                        type="button"
                        className="btn btn-primary home-legacy-btn nav-btn mb-4"
                      >
                        {this.state.PageContent?.link}
                      </button>
                    </a>
                    <div className="description" dangerouslySetInnerHTML={{ __html: this.state.PageContent?.content?.description }}></div>
                    {/* <p>
                  Best Academics: ALLEN’s legacy of more than three decades in
                  the coaching industry has paved way for its two Strongest
                  Resources:
                </p>
                <ul>
                  <li>Time-tested Teaching Methodology & Content </li>
                  <li>Best Academics Team with highly-experienced Faculties</li>
                </ul>
                <p>
                  Best Technology: ALLEN Digital combines ALLEN’s strongest
                  resources with advanced technology to create a tech-enabled
                  classroom feel for the learners at their homes. Thereby
                  ensuring that there are no barriers to learning.
                  <br /> <br />
                  Student Care: ALLEN has always focused and keen towards the
                  student care
                </p> */}
                  </div>
                </div>
                <div id="home-drop-message-form"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HomeLegacy;
