import React, {useEffect, useState} from "react";
// import { environment } from "../../Services/HttpData";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import "../../css/ecom/ecomStyle.css";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
const EcomInstallmentStatus = () =>{
  const {installmentId} = useParams();
  const [installmentStatus,setInstallmentStatus] = useState([]);
  const [success,setSuccess] = useState("none");
  const [failed,setFailed] = useState("none");
  const auth_token =  localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : '';
  const [loading,setLoading] = useState(false);
  useEffect(()=>{
    
    const requestOptions = {
      method: 'GET',
      headers : {
        'Authorization': 'Basic '+auth_token
      }
    };
    fetch(environment.ecomApiUrl + 'order/installment/status/'+installmentId,requestOptions).then((resp)=>{ // fetching api url for courses listing
        resp.json().then((result)=>{
          if(result.data.status){
          setInstallmentStatus(result.data.status);  // set result in state this will set current/updated value of setProduct to product
          //console.log("orderstatus "+result.data.status);
          setLoading(true);
          if(result.data.status === "complete"){
            setSuccess("block");
          }else{
            setFailed("block");
          }
        }
        })
    })
},[]);
  return (
    <>
      <div className="container pr-0 mt-5">
        <div className="col-md-12">
        {
          installmentStatus === "complete" ?
          (
            <>
              
              <div className="success-cotent-top text-center" style={{display:success}}>
                <i className="fa-solid fa-circle-check"></i>
                <h4> Your Installment Amount Paid Successfully !!</h4>
                      <span className="order-id mt-5"> Installment Id : {installmentId} </span><br/>
                  <div className="mt-5"></div>
                <div className="success-bottom-btn mt-4">
                  <Link className="btn my-font order-history-btn" to="/ecom/orders">Order History</Link>
                {/*  <button className="btn my-font order-history-btn2">Start Learning</button> */}
                </div>
              </div>
              
            </>
          ):
          (
            <>
              
              <div className="failed-cotent-top text-center" style={{display:failed}}>
                <i className="fa-solid fa-circle-xmark"></i>
                <h4> Your Installment Amount has been Failed !!</h4>
                    <span className="order-id mt-5"> Installment Id : {installmentId} </span><br/>
                <div className="mt-5"></div>
                <div className="success-bottom-btn mt-4">
                <Link className="btn my-font order-history-btn" to="/ecom/orders">Order History</Link>
                </div>
              </div>
              
            </>
          )
        }
        
        </div>
      </div>
      


    </>
  );
}

export default EcomInstallmentStatus;
