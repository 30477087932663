import React from 'react'
class FaqDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0
        }
    }
    render() {
        return <>
            <div className="col my-5 faq-courses">
                <h5 className="font-weight-bold mb-4 font-size-60">FAQ'S</h5>
                <div className="row m-0">
                    <div className="col-3 col-md-3 pt-2 padding-0  display-faq">
                        <div className="list-group" id="list-tab" role="tablist">

                            {this.props.data.length > 0 && this.props.data.map((faq, index) => {
                                if (faq.faq.length) {
                                    return (
                                        <a key={faq?.id} className={this.state.selectedIndex === index ? "list-group-item list-group-item-action text-green text-center active" : "list-group-item list-group-item-action text-green text-center"} id={"list-tab-" + index + "-list"} data-toggle="list" href={"#list-tab-" + index} role="tab" aria-controls="tab" onClick={() => { this.setState({ selectedIndex: index }) }}>{faq?.title}</a>)
                                }
                            }
                            )}
                            {/* <a className="list-group-item list-group-item-action text-green text-center" id="list-profile-list" data-toggle="list" href="#list-profile" role="tab" aria-controls="profile">CATEGORY 1</a>
                            <a className="list-group-item list-group-item-action text-green text-center" id="list-messages-list" data-toggle="list" href="#list-messages" role="tab" aria-controls="messages">CATEGORY 1</a>
                            <a className="list-group-item list-group-item-action text-green text-center" id="list-settings-list" data-toggle="list" href="#list-settings" role="tab" aria-controls="settings">CATEGORY 1</a> */}
                        </div>
                    </div>
                    <div className="col-12 col-md-9 padding-0">
                        <div className="tab-content card border-none p-3" id="nav-tabContent">
                            {
                                this.props.data.length > 0 && this.props.data.map((faq, index) => {
                                    return (
                                        <div className={this.state.selectedIndex === index ? "tab-pane fade show active" : "tab-pane fade"} id={"list-tab-" + index} role="tabpanel" aria-labelledby={"list-tab-" + index + "-list"} key={faq.id + "-" + index}>
                                            <div>
                                                {
                                                    faq.faq.length > 0 && faq.faq.map((item, indx) => {
                                                        return (
                                                            <div key={index+indx}>
                                                                <div id={"headingSix" + indx} className="row m-0 align-items-center dropdown-toggle" data-toggle="collapse" data-target={"#collapseSix" + indx} aria-expanded="true" aria-controls={"collapseSix" + indx}>
                                                                    <h2 className="mb-0 mr-auto">
                                                                        <button className="btn btn-link btn-block text-left text-dark font-size-22-normal" type="button">
                                                                            {item?.question}
                                                                        </button>
                                                                    </h2>
                                                                </div>
                                                                <div id={"collapseSix" + indx} className={indx === 0 ? "collapse show" : "collapse"} aria-labelledby={"headingSix" + indx} data-parent="#accordionExample">
                                                                    <div className="card-body text-muted" dangerouslySetInnerHTML={{
                                                                        __html: item?.answer
                                                                    }}></div>
                                                                </div>
                                                                {indx !== faq.faq.length - 1 && <hr />}
                                                            </div>)
                                                    })}
                                                {faq.faq.length == 0 && <p>There is no FAQ in this category</p>}
                                            </div>
                                        </div>
                                    )
                                }
                                )}
                            {/* <div className="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">
                                <div>
                                    <div id="headingSix" className="row m-0 align-items-center dropdown-toggle"data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                        <h2 className="mb-0 mr-auto">
                                          <button className="btn btn-link btn-block text-left text-dark font-size-22-normal" type="button" >
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                        sollicitudin laoreet massa, nec molestie urna
                                          </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                        <div className="card-body text-muted">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque.
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <div id="headingSeven" className="row m-0 align-items-center dropdown-toggle"data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                        <h2 className="mb-0 mr-auto">
                                          <button className="btn btn-link btn-block text-left text-dark font-size-22-normal" type="button" >
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                           sollicitudin laoreet massa, nec molestie urna
                                          </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                                        <div className="card-body text-muted">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque.
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <div id="headingEight" className="row m-0 align-items-center dropdown-toggle"data-toggle="collapse" data-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                                        <h2 className="mb-0 mr-auto">
                                          <button className="btn btn-link btn-block text-left text-dark font-size-22-normal" type="button" >
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                            <br/>sollicitudin laoreet massa, nec molestie urna
                                          </button>
                                        </h2>
                                    </div>
                                    <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                        <div className="card-body text-muted">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque.
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <div id="headingNine" className="row m-0 align-items-center dropdown-toggle"data-toggle="collapse" data-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
                                        <h2 className="mb-0 mr-auto">
                                          <button className="btn btn-link btn-block text-left text-dark font-size-22-normal" type="button" >
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                            <br/>sollicitudin laoreet massa, nec molestie urna
                                          </button>
                                        </h2>
                                    </div>
                                    <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                        <div className="card-body text-muted">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque.
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <div id="headingTen" className="row m-0 align-items-center dropdown-toggle"data-toggle="collapse" data-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">
                                        <h2 className="mb-0 mr-auto">
                                          <button className="btn btn-link btn-block text-left text-dark" type="button" >
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                            <br/>sollicitudin laoreet massa, nec molestie urna
                                          </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                                        <div className="card-body text-muted">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">
                                <div>
                                    <div id="headingSix" className="row m-0 align-items-center dropdown-toggle">
                                        <h2 className="mb-0 mr-auto">
                                          <button className="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                            <br/>sollicitudin laoreet massa, nec molestie urna
                                          </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                        <div className="card-body text-muted">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque.
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <div id="headingSeven" className="row m-0 align-items-center dropdown-toggle">
                                        <h2 className="mb-0 mr-auto">
                                          <button className="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                            <br/>sollicitudin laoreet massa, nec molestie urna
                                          </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                                        <div className="card-body text-muted">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque.
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <div id="headingEight" className="row m-0 align-items-center dropdown-toggle">
                                        <h2 className="mb-0 mr-auto">
                                          <button className="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                            <br/>sollicitudin laoreet massa, nec molestie urna
                                          </button>
                                        </h2>
                                    </div>
                                    <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                        <div className="card-body text-muted">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque.
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <div id="headingNine" className="row m-0 align-items-center dropdown-toggle">
                                        <h2 className="mb-0 mr-auto">
                                          <button className="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                            <br/>sollicitudin laoreet massa, nec molestie urna
                                          </button>
                                        </h2>
                                    </div>
                                    <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                        <div className="card-body text-muted">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque.
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <div id="headingTen" className="row m-0 align-items-center dropdown-toggle">
                                        <h2 className="mb-0 mr-auto">
                                          <button className="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                            <br/>sollicitudin laoreet massa, nec molestie urna
                                          </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                                        <div className="card-body text-muted">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="list-settings" role="tabpanel" aria-labelledby="list-settings-list">
                                <div>
                                    <div id="headingSix" className="row m-0 align-items-center dropdown-toggle">
                                        <h2 className="mb-0 mr-auto">
                                          <button className="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                            <br/>sollicitudin laoreet massa, nec molestie urna
                                          </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                        <div className="card-body text-muted">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque.
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <div id="headingSeven" className="row m-0 align-items-center dropdown-toggle">
                                        <h2 className="mb-0 mr-auto">
                                          <button className="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                            <br/>sollicitudin laoreet massa, nec molestie urna
                                          </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                                        <div className="card-body text-muted">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque.
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <div id="headingEight" className="row m-0 align-items-center dropdown-toggle">
                                        <h2 className="mb-0 mr-auto">
                                          <button className="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                            <br/>sollicitudin laoreet massa, nec molestie urna
                                          </button>
                                        </h2>
                                    </div>
                                    <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                                        <div className="card-body text-muted">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque.
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <div id="headingNine" className="row m-0 align-items-center dropdown-toggle">
                                        <h2 className="mb-0 mr-auto">
                                          <button className="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                            <br/>sollicitudin laoreet massa, nec molestie urna
                                          </button>
                                        </h2>
                                    </div>
                                    <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                                        <div className="card-body text-muted">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque.
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <div id="headingTen" className="row m-0 align-items-center dropdown-toggle">
                                        <h2 className="mb-0 mr-auto">
                                          <button className="btn btn-link btn-block text-left text-dark" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                                            <br/>sollicitudin laoreet massa, nec molestie urna
                                          </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                                        <div className="card-body text-muted">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur sodales odio feugiat tincidunt. Curabitur pellentesque nisi at enim elementum ullamcorper. Donec quis metus quam. Suspendisse potenti. Nullam ornare mollis est. Nunc faucibus magna dui, vitae accumsan lorem elementum nec. Duis pulvinar metus ac tincidunt pellentesque.
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }
}

export default FaqDetails;