import React from 'react';
class ArticleParaghraph extends React.Component {
    constructor(props) {
      super(props);
    }
    render() { 
        return (<>
            <p className='article-paragraph' dangerouslySetInnerHTML={{
                        __html: this.props.data}}></p>
        </>);
    }
}
 
export default ArticleParaghraph;