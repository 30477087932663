import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import webinarbigbanner from "../../images/1129x350.png";
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useState } from 'react';

const Webinars = () => {
    const [getTabledat, setTableDataArr] = useState({ upcoming: [], past: [] })
    const options = {
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 2,
            },
            992: {
                items: 3,
            }
        },
    };
    const scroll = () => {
        let e = document.getElementById("scrolltarget");
        e.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'start'
        });
    }

    var upcomingsession = [];
    var pastsession = [];

    function getsession() {

        if (window?.webinarTable) {
            window?.webinarTable?.forEach((data) => {
                let currentdate = new Date();
                let currenttime = new Date().getTime();
                if ((new Date(data?.developer_date_time) < currentdate) && (new Date(data?.developer_date_time).getTime() < currenttime)) {
                    pastsession.push(data);
                }
                else {
                    upcomingsession.push(data);
                }
            })
            setTableDataArr({ "upcoming": upcomingsession, "past": pastsession })
            if (upcomingsession.length <= 0) {
                document.getElementById("staytunedwebinar").style.display = "table-row";
            }
        } else {
            setTimeout(() => {
                getsession();
            }, 100)
            return false
        }
    }
    useEffect(() => {
        getsession()
    }, [])
    return (
        <div>
            <Helmet>
                {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
                <title>Webinars for CBSE class 10th Boards Exams with Free Preparation Materials</title>
                <meta
                    name="description"
                    content="Webinars for CBSE class 10th Boards Exams with Free Preparation Materials"
                />
                {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
            </Helmet>
            <div className='container'>
                <div className='webinaruppertext'>
                    <div className='webinarheadingimg'><img src="https://cdn.allendigital.in/fe-bsite/result_page/webinarheighlight.png" alt="heading"></img></div>
                    <div style={{ textAlign: "center" }}>
                        <strong style={{ marginBottom: '30px', fontSize: '16px' }}><i>Are you ready to rock your exams and unleash your full potential? ALLEN Digital Experts are here to help!</i></strong><br></br>
                        <span>ALLEN Digital conducts live sessions, webinars, talk shows and virtual events to ensure that the students remain updated with the latest information on changing exam patterns, career counseling, and preparation strategy.</span>
                    </div>
                    <div className='webinarlivebanner'>
                        <img onClick={scroll} className='webinarbigbanner' src="https://cdn.allendigital.in/fe-bsite/result_page/1129x350_.png"></img>
                    </div>
                    <p id="scrolltarget">In the online sessions, the expert team of ALLEN Digital interacts with the students & their parents and shares with them the essential tips for competitive exam preparation & answers the students' queries during the live sessions.</p>
                </div>
                <div className="table-responsive">
                    <table className="table table-bordered table-striped" id="table-details">
                        <tbody>
                            <tr style={{ backgroundColor: "#046635", color: "white" }}>
                                <th colSpan={4} style={{ textAlign: "center" }}>Upcoming Sessions</th>
                            </tr>
                            <tr style={{ backgroundColor: "#046635", color: "white" }}>
                                <th>S.No.</th>
                                <th style={{ width: "700px" }}>Session Name</th>
                                <th align="center">Date &amp; Time</th>
                                <th align="center">Registration Link</th>
                            </tr>
                            {
                                getTabledat?.upcoming?.map((data, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{data?.session_name}</td>
                                        <td>{data?.date_time}</td>
                                        <td><a href={data?.registration_link} target='blank'>Click Here</a></td>
                                    </tr>
                                ))
                            }
                            <tr id="staytunedwebinar" style={{ display: "none" }}>
                                <td colSpan={4} style={{ textAlign: "center" }}>Stay Tuned for More Upcoming Sessions</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div className="table-responsive">
                    <table className="table table-bordered table-striped" id="table-details">
                        <tbody>
                            <tr style={{ backgroundColor: "#046635", color: "white" }}>
                                <th colSpan={4} style={{ textAlign: "center" }}>Past Sessions</th>
                            </tr>
                            <tr style={{ backgroundColor: "#046635", color: "white" }}>
                                <th>S.No.</th>
                                <th style={{ width: "700px" }}>Session Name</th>
                                <th align="center">Session Link</th>
                                <th align="center">PDF Booklet</th>
                            </tr>
                            {
                                getTabledat?.past?.map((data, i) => (
                                    <tr>
                                        <td>{i + 1}</td>
                                        <td>{data?.session_name}</td>
                                        <td>{data?.session_link.length > 0 ? (<a href={data.session_link} target='blank'>Click Here</a>) : ""}</td>
                                        <td>{data?.pdf_booklet.length > 0 ? (<a href={data.pdf_booklet} target='blank'>Download</a>) : ""}</td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>

                <strong>Here are just a few of the many benefits of joining ALLEN Digital's webinars</strong>
                <ul>
                    <li>Stay updated on changing exam patterns and career counseling</li>
                    <li>Learn effective preparation strategies from experts & toppers in the field</li>
                    <li>Get valuable insights and tips to help you succeed on exam day</li>
                    <li>Interact with our expert faculty and get your questions answered in real-time</li>
                    <li>Explore various subjects and discover your passions</li>
                </ul>
                <div className='webinarclickhere'>
                    <span>So what are you waiting for?</span><br></br><strong> Register for ALLEN Digital's webinars today and let's get ready to ace the exam preparation!</strong><br></br>
                    <button onClick={scroll}>Click Here & Register for Upcoming Sessions</button>
                </div>
                <div className='webinarheighlights' >
                    <div style={{ textAlign: "center", fontSize: '25px', fontWeight: '600' }}>Webinars Highlights</div>
                    <OwlCarousel id="webinarsliderbottom" autoplay="true" autoplayTimeout="2500" autoplayHoverPause="true" className='owl-theme' loop margin={10} nav lazyContent="true" {...options}>
                        <div className="webinar-item">
                            <a href="https://www.youtube.com/watch?v=9xRvdQVbDKc" target="_blank">
                                <img src='https://img.youtube.com/vi/9xRvdQVbDKc/sddefault.jpg'>

                                </img>
                            </a>
                        </div>
                        <div className="webinar-item">
                            <a href="https://www.youtube.com/watch?v=ISkzu4O9rnE" target="_blank">
                                <img src='https://i3.ytimg.com/vi/ISkzu4O9rnE/hqdefault.jpg'>

                                </img>
                            </a>
                        </div>
                        <div className="webinar-item">
                            <a href="https://www.youtube.com/watch?v=Y9RNfHvIHwQ" target="_blank">
                                <img src='https://i3.ytimg.com/vi/Y9RNfHvIHwQ/hqdefault.jpg'>

                                </img>
                            </a>
                        </div>

                    </OwlCarousel>
                </div>
            </div>
        </div>
    );
};

export default Webinars;