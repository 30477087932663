import React from "react";
// import { environment } from '../Services/HttpData';
import { Helmet } from "react-helmet";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);
class Policies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PageContent: [],
    };
  }
  async componentDidMount() {
    // POST request using fetch with async/await
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "keyword": ''})
    // };
    // fetch(environment.baseUrl+'view_list_all', requestOptions)
    // .then(async response => {
    //     // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
    //     const data = /* isJson && */ await response.json();
    //     // check for error response
    //     // //console.log(response);
    //     if (!response.ok) {
    //         // get error message from body or default to response status
    //         const error = (data && data.message) || response.status;
    //         return Promise.reject(error);
    //     }
    //     let index=data.data.findIndex((item)=>item.slug==="policies");
    //     //console.log(data.data[index]);
    //     let sections = {};
    //     data.data[index]?.sections.map((item) => {
    //       switch (item.id) {
    //         case 43:
    //           sections.banner = item;
    //           break;
    //       }
    //     });
    //     this.setState({ PageContent: sections });
    //     //console.log(this.state.PageContent);
    // })
    // .catch(error => {
    //     this.setState({ errorMessage: error.toString() });
    //     console.error('There was an error!', error);
    // });
    /* const response = await fetch(environment.baseUrl+'user_blog_category_list', requestOptions);
      const data = await response.json();
      if(data.replyCode==="success"){
          //console.log(data.data);
          this.setState(data.data);
      } */
    const getViewListAll = () => {
      if (!window?.AllenDigitalFEBSite) {
        setTimeout(() => {
          getViewListAll();
        }, 50);
        return false;
      }
      const data = window?.AllenDigitalFEBSite;

      let index = data.data.findIndex((item) => item.slug === "policies");
      //console.log(data.data[index]);
      let sections = {};
      data.data[index]?.sections.map((item) => {
        switch (item.id) {
          case 43:
            sections.banner = item;
            break;
        }
      });
      this.setState({ PageContent: sections });
    };
    getViewListAll();
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Privacy Policy | ALLEN Digital
          </title>
          {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
          {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
          <meta name="description" content="Protecting your privacy is our top priority. Our privacy policy explains how we handle your personal information and what steps we take to safeguard it." />
          <meta property="og:title" content="Privacy Policy | ALLEN Digital" />
          <meta property="og:site_name" content="ALLEN Digital" />
          <meta property="og:url" content="https://digital.allen.ac.in/policies" />
          <meta property="og:description" content="Protecting your privacy is our top priority. Our privacy policy explains how we handle your personal information and what steps we take to safeguard it." />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://digital.allen.ac.in/static/media/logo-new-r.4a9d1fde3fd2a6868a621b27352bda3a.svg" />
        </Helmet>
        <div className="container-fluid">
          <div className="banner-3" style={{ paddingTop: "150px" }}>
            <div className="container banner-content">
              <h4>{this.state.PageContent?.banner?.title}</h4>
              {/* <!--<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis aliquam dolorem perferendis
                    quibusdam,<br>
                    assumenda perspiciatis necessitatibus deleniti consequuntur blanditiis libero minima <br>sed
                    cupiditate
                    veniam! Impedit, sunt! Fuga magni voluptate veniam.</p>--> */}
            </div>
          </div>
        </div>
        <div class="container mt-5 privacy-policy"><article _ngcontent-jwi-c171="" class="text-justify ng-tns-c165-4" dir="ltr">
<h4 class="mb-3">Privacy Policy</h4>
    <p>This Privacy Policy discloses the privacy practices for ALLEN Career Institute Pvt. Ltd. (“ACIPL”) with regard to your (a) use of our website, our applications or any products or services in connection with the application, website/products (“Services”) or (b) any modes of registrations or usage of products, including through SD cards, tablets or other storage/transmitting device, including at our physical centers, workshops, events hosted by ACIPL. 
       
    </p>

    <p>This Privacy Policy along with Terms of Use, describes our practices regarding, including your choices in relation to how we collect, store, use, share and secure your personal information across our Website. It also describes your choices regarding use, access and correction of your personal information and your rights in relation to your personal information and how to contact us or supervisory authorities in the event you have a complaint.</p>

    <p>By visiting https://digital.allen.ac.in/ (“Website”), you agree to be bound by the terms and conditions of this Privacy Policy. Please read this Privacy Policy carefully. By using the Website, you indicate that you understand, agree and consent to this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use/access this Website.
    </p>

    <p>We review this Privacy Policy from time to time to make sure it is up to date. Please note that this Privacy Policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this Privacy Policy periodically. 
    </p>

    <p>By “Personal Data”, we mean any information that can either itself identify you as an individual or that can be connected to you indirectly by linking it to non-personal data.

</p>

    <h4>COLLECTION OF INFORMATION</h4>

    <p>As a visitor, you can browse our Website to ﬁnd out more about our Website. You are not required to provide us with any Personal Data as a visitor. When you visit our Website, we collect and store certain information in order to improve security, analyse trends/user behaviour and administer the Website with a view to assist us in improving customer experience. We use this information to evaluate patterns on our Website so that we can make it more useful to our visitors.
    </p>

    <p>We collect your Personal Data when you register with us, when you express an interest in obtaining information about us or our Services, when you participate in activities on our Website or otherwise contact us.
    </p>

    <p>We will only collect your Personal Data if we have a proper reason for doing so, e.g.: to comply with our legal and regulatory obligations; for the performance of our contract with you or to take steps at your request before entering into a contract; for our legitimate interests or those of a third party; or where you have given consent. A legitimate interest is when we have a business or commercial reason to use your information, so long as this is not overridden by your own rights and interests. In some cases, we may also have a legal obligation to collect personal information from you or may otherwise need the personal information to protect your vital interests or those of another person (for example, to prevent payment fraud or to conﬁrm your identity).</p>

    <p>Our primary goal in collecting your Personal Data and other personal information is to provide you a safe, efficient, smooth and customized experience. This allows us to provide courses, study material, tutorials that most likely meet your needs and to customize our Website to make your experience safer and easier. We also use your Personal Data together with non-personal data (such as aggregated usage information, anonymous feedback etc) to help us better understand our users and to improve the content and functionality of our Website. The information we learn from you helps us personalize and continually improve your experience at our Website. We do not voluntarily make this information available to third parties, nor do we use it for any other purpose, except as set out herein.</p>

    <p>When you visit our Website, as part of a purchase of one of our courses or by registering on our Website, we may collect a variety of information that you agree to voluntarily provide us via the Website, contact forms, or via a chat or phone session (Personal Identifying Information), such as:</p>

   

    <ul style={{marginLeft:"15px"}}>
    <li>
            <p>Contact Information, such as name, email address, display picture, mailing address, phone number, IP address, geographic location, or phone number;
            </p>
        </li>
        <li>
            <p>Information to enable us to check and verify your identity, e.g. your date of birth, mark-sheets, or official identification documents, such as Passport/ Aadhaar data/ Voter ID details;
            </p>
        </li>
        <li>
            <p>Other details such as gender, age to provide you relevant details about financial aid and scholarships;
            </p>
        </li>
        <li>
            <p>Other details you submit to help us administer the course better;
            </p>
        </li>
        <li>
            <p>Billing Information, such as debit card number, credit card number and billing address; Unique Identiﬁers, such as username or password;
            </p>
        </li>
        <li>
            <p>Reviews or ratings, syllabus and course preferences, account settings, (including preferences as set out in the “Account” section of our Website); and
            </p>
        </li>
        <li>
            <p>Information provided to us through our service, interaction with our customer service, participation in surveys or marketing promotions.

            </p>
        </li>
    </ul>

    <p>We collect information about you and your use of our service, your interactions with us as well as information regarding your computer or other device used to access our service. This information includes your activity on our Website, such as classes, study materials, courses and search queries; details of your interactions with customer service, such as the date, time and reason for contacting us, transcripts of any chat conversations, and if you call us, your phone number and call recordings; device IDs or other unique identiﬁers, device and software characteristics (such as type and conﬁguration), preferred language, connection information, statistics on page views, referring source (for example, referral URLs ), IP address (which may tell us your general location), browser and standard web server log information, information collected via the use of cookies, web beacons and other technologies. 
    </p>

    <p>With the help of back-end applications, we aim at providing better services and better interaction with teachers, depending on the beneﬁciary’s line of interest. We also may ask for this information at other times, such as when you enter contests or other promotions sponsored by us and/or our partners.
    </p>

    <p>If you use a feature that requires payment of a fee, options like NEFT or Card will appear. In case of payments via credit or debit card, we will redirect you to registered payment gateways such as razorpay, ccavenue, payubiz and other such registered gateways. You may choose to store and save your payment details like card numbers with the respective gateway. We do not have access to this data. All payment transactions are processed through secure payment gateway providers. We do not store any card information (other than the last 4 digits of your card) in our servers.
    </p>

    <p>When you use one of our paid products, we track the web pages, and information that has been accessed by you, and store it on our servers. This enables us to track items that you have completed, and those that you need to see.
    </p>

    <p>The user's full name and their display picture can be publicly displayed on the Website. Depending on what proﬁle and what services they are seeking, ACIPL can ask for more information relating to address, billing information, certiﬁcates, academic information etc.

</p>

    <p>ACIPL can use technologies such as cookies, clear gifs, log ﬁles, and ﬂash cookies for several purposes, including to remember your device, to help understand how you interact with our Website and Service, in order to provide a better experience.</p>

    <h4>USAGE AND RETENTION OF INFORMATION
    </h4>

    <p>We use the personal information we collect, including your Personal Data where it is necessary to deliver the services you have requested, where it is necessary to exercise or comply with legal rights or obligations or for normal business purposes of the kind set out in this Privacy Policy.

</p>

    <p>We will use your personal information to provide, analyse, administer and improve our Services, to provide you with a personalized experience on our Website (especially, by offering you Services that is best suited for you), to contact you about your account and our Services, to provide you customer service, to provide you with personalized marketing and to detect, prevent, mitigate and investigate fraudulent or illegal activities.
    </p>

    <p>We further use your personal information to determine your general geographic location, provide localized courses and classes; enhance your user experience; provide you with customized and personalized study material, recommendations; determine your internet service provider; quickly and efficiently respond to your inquiries and requests; to enforce our terms (such as  determining free trial eligibility) and to communicate with you concerning our Services (for example by email, push notiﬁcations, text messaging ,and online messaging channels), so that we can send you details about new features and content available on the Website, special offers, promotional announcements, surveys, and to assist you with operational requests such as password reset requests. </p>
    <p>We may use your information for our marketing and publicity purposes including in case of your selection in the medical / engineering entrance exams, national or international Olympiads, National Talent Search Exam (NTSE), Kishore Vaigyanik Protsahan Yojana (KVPY) exam, and /or any other examinations. We keep your personal data for as long as it is necessary to provide you our Services, or for the purpose for which the data was collected.</p>
    <p>
    We use your Personal Data to derive insights into your usage of our Service, and to customize the user experience for you.

    </p>
    <p>
    We may need to retain information even after you have requested us to erase your personal data, terminated your account with us, or have stopped using the Services to ensure compliance with applicable laws or other legal obligations, or to exercise our legal rights.  We may also keep your contact information and other details for fraud prevention, for the exercise/defence of a legal claim, or for business compliances such as maintaining accurate accounting, financial, and other operational records, resolving disputes, and enforcing our agreements.
    </p>

    <h4>SHARING AND DISCLOSING PERSONAL INFORMATION
    </h4>

    <p>We may disclose your Personal Data, for these purposes, with the following third parties:
    </p>
    
    <ul style={{marginLeft:"15px"}}>
   <li><p><b><u>Legal purposes:</u></b> We share data when required by law, so as to comply with subpoena, court orders, requests by law enforcement authorities or similar legal processes, to enforce applicable ToU (Terms of Use), including investigation of potential violations thereof; when we believe in good faith that the disclosure is necessary to establish or protect our rights, protect your safety or the safety of others, investigate fraud, assess security or technical issues or respond to government requests; to protect against imminent harm to the Service or our users or the public as required or permitted by law.</p> </li>

   <li><p><b><u>Service providers:</u></b> We use other companies, agents, or contractors ('Service Providers') to perform services on our behalf or to assist us with the provision of Services to you. We engage Service Providers to provide marketing, advertising, communications, infrastructure, and IT services, and to help us collect payments from you, assist us in customer service, to personalize and optimize our Services, to analyse and enhance data (including data about users’ interactions with our Services).</p> </li>

   <li><p><b><u>Banking partners:</u></b> We may, at your request, share your Personal Data with our banking partners, their employees or other banking agents, based on your expression of interest regarding financial aid. The banking partners may contact you to offer their products or services. </p></li>

   <li><p><b><u>Corporate restructuring:</u></b> We may share Personal Data with another company pursuant to any corporate re-organisation, amalgamation, or restructuring. </p></li>

   <li><p><b><u>Other third parties:</u></b> We may share your Personal Data with other third parties, for fraud prevention, credit risk reduction or other purposes, on a need-to-know basis, such as accountants, lawyers, auditors, etc. </p> </li>
    </ul>

    <p>Other than as set out in this Privacy Policy, we shall not sell or disclose your Personal Data to third parties without obtaining your prior consent unless it is necessary for the purposes set out in this Privacy Policy or unless we are required to do so by law. The Website may contain advertising of third parties and links to other sites or frames of other sites. Please be aware that we are not responsible for the privacy practices or content of those third parties or other sites, nor for any third party to whom we transfer your Personal Data in accordance with our Privacy Policy.</p>

    <h4>SECURITY</h4>

    <p>We shall try to take all precautions to protect the Personal Data both online and offline. We will try to protect your information using technical and administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure, and alteration. We have standard SSL certiﬁcation which basically helps us create a secure connection between our server and user to render any information or action. Some of the safeguards we use are ﬁrewalls and data encryption, physical access controls to our data centres and information access authorization controls. Only user passwords are encrypted in 64-bit encryption and stored (since users generally use the same password on multiple sites), to prevent any kind of theft, piracy, or unauthorized access. If you believe your account has been abused or hacked, please contact us by sending us an email at wecare@allen.in.
    </p>

    <p>We do not sell, transfer, or rent your Personal Data to third parties for their marketing purposes without your explicit consent, and we only use your information as described in the Privacy Policy. We view protection of your privacy as a very important community principle. We understand clearly that you and your Personal Data is one of our most important assets. We store and process your Personal Data on computers located in India that are protected by physical as well as technological security devices. We use third parties to verify and certify our privacy principles. If you object to your Information being transferred or used in this way, please do not use this Website.

</p>

    <p>Under no circumstances do we rent, trade, transfer or share your Personal Data that we have collected with any other company for their marketing purposes without your consent. We reserve the right to communicate your Personal Data to any third party that makes a legally compliant request for its disclosure.
    </p>

    <h4>KEEPING YOUR PERSONAL DATA SECURE</h4>

    <p>We have appropriate security measures to prevent your Personal Data from being accidentally lost or used or accessed unlawfully. Processing your Personal Data will be done only in an authorized manner and subject to a duty of conﬁdentiality. We also have procedures in place to deal with any suspected data security breach. We will notify you about any applicable regulator of a suspected data security breach where we are legally required to do so.
    </p>

    <p>Not with standing anything contained anywhere in this Privacy Policy, we cannot assure absolute security to your Personal Data, and by entering into or by logging into our Website, you explicitly agree not to sue us for any data breach. 
    </p>

    <h4>USE BY CHILDREN 
    </h4>

    <p>As stated in out in the Terms of Use, you must meet the age requirements for registering and using our Services. Persons who are minors (i.e. a child who has not completed the age of 18 (eighteen) years), un-discharged insolvents etc. are not eligible to register for the Services. As a minor if you wish to use our Services, such use shall be made available to you by your legal guardian or parents, who has agreed to the Terms of Use and this Privacy Policy. In the event a minor utilizes the Services, it is assumed that he/she has obtained the consent of the legal guardian, or parents and such use is made available by the legal guardian or parents. We will not be responsible for any consequence that arises as a result of misuse of any kind that may occur by virtue of any person including a minor registering for the Services provided. 

</p>

    <p>By using the Services, you warrant that all the data provided by you is accurate and complete and that the users using the Services has obtained the consent of their parent/legal guardian (in case of minors). We reserve the right to terminate your access to the Services if it is discovered that you are under the age of 18 (eighteen) years and the consent to use the Services is not made by your parent/legal guardian, or any information provided by you is inaccurate.
    </p>
    <h4>COOKIES</h4>
    <p>
    We transfer cookies, which are small ﬁles containing a string of character, to your IP address, giving the browser distinct identiﬁcation, in order to keep track of the user’s preferences. Furthermore, these ﬁles also help in logging-in faster and they act as a mechanism to determine user trends. The data thus retrieved from the user’s IP address, enables us to enhance our offers, including but not limited to more content in areas of greater interest to a majority of users.
    </p>
    <p>Our Website uses 'Cookies' to identify the areas of our Website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to personalize the content that you see on our Website. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our Website correctly or at all. We never place Personal Data in Cookies.
    </p>

    <h4>THIRD PARTIES AND LINKS

</h4>

    <p>Our Website may contain links to other websites that are not under our direct control. These websites may have their own policies regarding privacy. We have no control of or responsibility for linked websites and provide these links solely for the convenience and information of our visitors. You are accessing such linked Websites shall be at your own risk. These websites are not subject to this Privacy Policy. You should check the privacy policies, if any, of those individual websites to see how the operators of those third-party websites will utilize your personal information. In addition, these websites may contain a link to websites of our affiliates. The websites of our affiliates may not be subject to this Privacy Policy, and you should examine their individual privacy policies to see how the operators of such websites will utilize your Personal Data.</p>

    <h4>CONSULTING</h4>

    <p>We use third parties to help us provide services to you including the fulﬁlment of Service, processing of payments, monitoring site activity, conducting surveys, maintaining our database, administering emails, and administering contents, and to provide aggregate, comparative information on the performance of our Website to us and a select group.
    </p>

    <h4>CHOICE</h4>

    <p>It is open for you to customize our usage of your Personal Data to communicate with you, to send you marketing information, how we provide you with customized and relevant advertising, and whether you want to stay signed into your account.
    </p>

    <p>If you do not wish to receive marketing communications from us, you can write in to us at wecare@allen.in.  
    </p>

    <h4>OWNERSHIP OF RIGHTS
    </h4>

    <p>All rights, including copyright, in this Website are owned by or licensed to us. Any use of this Website or its contents, including copying or storing it or them in whole or part, other than for your own personal, non-commercial use is prohibited without our permission. You are prohibited from modifying, copying, distributing, transmitting, displaying, printing, publishing, selling, licensing, creating derivative works or using any content available on or through our Website for commercial or public purposes. You shall not modify, distribute or re- post something on this Website for any purpose.
    </p>

    <p>The Site contains copyrighted material, trademarks, and other proprietary information, including, but not limited to, text, software, photos, video, graphics, music, sound, and the entire contents of ACIPL is protected by copyright as a collective work under the applicable copyright laws. ACIPL owns a copyright in the selection, coordination, arrangement, and enhancement of such content, as well as copyright or license to use in the content original to it. You shall not modify, publish, transmit, participate in the transfer or sale, create derivative works, or in any way exploit, any of the content, in whole or in part. You may download / print / save copyrighted material for your personal use only.

</p>

    <p>Except as otherwise expressly stated under copyright law, no copying, redistribution, retransmission, publication, or commercial exploitation of downloaded material without the express permission of ACIPL is permitted. If copying, redistribution, or publication of copyrighted material is expressly permitted by ACIPL, then no changes in or deletion of author attribution, trademark legend or copyright notice shall be made.
    </p>

    <p>You acknowledge that you do not acquire any ownership rights by downloading copyrighted material. Trademarks that are located within or on our Website or a website otherwise owned or operated in conjunction with ACIPL shall not be deemed to be in the public domain but rather the exclusive property of ACIPL, unless such site is under license from the trademark owner thereof in which case such license is for the exclusive beneﬁt and use of ACIPL, unless otherwise stated.
    </p>
    <p>
    ACIPL does not have any express burden or responsibility to provide you with indications, markings, or anything else that may aid you in determining whether the material in question is copyrighted or trademarked. You shall be solely liable for any damage resulting from any infringement of copyrights, trademarks, proprietary rights, or any other harm resulting from such a submission. By submitting material to any public area of the Website, you warrant that the owner of such material has expressly granted ACIPL the royalty-free, perpetual, irrevocable, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate and distribute such material (in whole or in part) worldwide and/or to incorporate it in other works in any form, media or technology now known or hereafter developed for the full term of any copyright that may exist in such material. You also permit any other end user to access, view, store or reproduce the material for that end user's personal use. You hereby grant ACIPL, the right to edit, copy, publish and distribute any material made available on the Website by you.

    </p>

    <p>If you come across any abuse or violation of these Terms, please report to wecare@allen.in.
    </p>

    <h4>CONSENT</h4>

    <p>By submitting data to us or our agent or using the Site, you consent to our use of your data in the manner set out in this Privacy Policy.
    </p>

    <h4>CONDITIONS OF USE </h4>

    <p>We do not warrant that this Website, its servers, or email sent by us or on our behalf are virus free. We will not be liable for any damages of any kind arising from the use of this website, including, but not limited to compensatory, direct, indirect, incidental, punitive, special, or consequential damages, loss of data, goodwill, business opportunity, income or proﬁt, loss of or damage to property and claims of third parties. 
 
 </p>

    <h4>GRIEVANCE OFFICER 
    </h4>

    <p>In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below: <br />
Name: Dhananjay Srivastava  <br />
Email: grievance@allen.in<br />
</p>


    <h4>CONSENT TO THIS POLICY 
    </h4>

    <p>The Terms of Use Agreement is incorporated herein by reference in its entirety. 
    </p>

    <h4>GENERAL
MODIFICATION :
</h4>


        <p>We may at any time modify the Terms of Use of our Website or this Privacy Policy without any prior notiﬁcation to you. Should you wish to terminate your account due to a modiﬁcation to the Terms or the Privacy Policy, you may do so email us at wecare@allen.in. However, if you continue to use the service you shall be deemed to have agreed to accept and abide by the modiﬁed Terms of this Website.
        </p>

        <h4>PRIVILEGED/EXCLUSIVE SERVICE :</h4>

        <p>By having an ACIPL account, you have explicitly given consent for us to capture images (followed by analysis), camera/mic permissions to make video calls and record the same.
        </p>

        <h4>GOVERNING LAW AND JURISDICTION :</h4>

        <p>In the event of any dispute arising between the parties with respect to the Terms of Use or this Privacy Policy, the same shall be referred to a sole arbitrator and the arbitration shall be in accordance with Arbitration and Conciliation Act of 1996. The language of arbitration proceedings shall be English. The venue of arbitration shall be Kota, Rajasthan, India, and the decision of the Arbitrator shall be ﬁnal and binding on both parties herein. This Privacy Policy shall be subject to the exclusive jurisdiction of courts in Kota, Rajasthan, India and shall be governed by the Indian laws. 
        </p>
</article></div>
        {/* <div
          className="container mt-5 privacy-policy"
          dangerouslySetInnerHTML={{
            __html: this.state.PageContent?.banner?.content[0]?.description,
          }}
        > */}
          {/* <div className="mt-5">
            <h4 className="mb-3">Privacy Policy</h4>
            <p>UPDATED ON 16-09-2021</p>
            <p>
              This Privacy Policy (“Privacy Policy/Policy”) discloses the
              privacy practices for ALLEN Digital Private Limited (“ADPL”,
              “We/we” or “Us/us”) with regard to Your (“You/you” or “Your/your”)
              (a) use of our website{" "}
              <a href="https://www.allendigital.in">www.allendigital.in</a>{" "}
              (“Website”), our applications (“Application”) or any products or
              services in connection with the Application/, Website/products
              (“Services”) or (b) any modes of registrations or usage of
              products, including through SD cards, tablets or other
              storage/transmitting device
            </p>
            <p>
              This Privacy Policy along with Terms of Use describes our
              practices regarding, including your choices in relation to how we
              collect, store, use, share and secure your personal information
              across our Website. It also describes your choices regarding use,
              access and correction of your personal information and your rights
              in relation to your personal information and how to contact us or
              supervisory authorities in the event you have a complaint. By
              visiting this Website, you agree to be bound by the terms and
              conditions of this Privacy Policy. Please read this Privacy Policy
              carefully. By using the Website, you indicate that you understand,
              agree and consent to this Privacy Policy. If you do not agree with
              the terms of this Privacy Policy, please do not use/access this
              Website.
            </p>
            <p>
              We’ll review this Privacy Policy from time to time to make sure it
              is up-to-date. If you are just a visitor, then please note that
              this Privacy Policy is subject to change at any time without
              notice. To make sure you are aware of any changes, please review
              this Policy periodically. If you are our registered user, we will
              notify you before we make changes to this Policy and give you the
              opportunity to review the revised Policy before you choose to
              continue using our services.{" "}
            </p>
            <p>
              By Personal Data, we mean any information that can either itself
              identify you as an individual ("Personally Identifying Information
              ") or that can be connected to you indirectly by linking it to
              Personally Identifying Information
            </p>
          </div>
          <div className="mt-5">
            <h4 className="mb-3">COLLECTION OF INFORMATION</h4>
            <p>
              As a visitor, you can browse our Website to find out more about
              our Website. You are not required to provide us with any Personal
              Data as a visitor. When you visit the Site, we collect and store
              certain information in order to improve security, analyse trends
              and administer the Website with a view to assist us in improving
              customer experience. We use this information to evaluate traffic
              patterns on our Website so that we can make it more useful to our
              visitors.
            </p>
            <p>
              We collect your Personal Data when you register with us, when you
              express an interest in obtaining information about us or our
              products and services, when you participate in activities on our
              Website or otherwise contact us.
            </p>
            <p>
              We will only collect your Personal Data if we have a proper reason
              for doing so, e.g.: to comply with our legal and regulatory
              obligations; for the performance of our contract with you or to
              take steps at your request before entering into a contract; for
              our legitimate interests or those of a third party; or where you
              have given consent. A legitimate interest is when we have a
              business or commercial reason to use your information, so long as
              this is not overridden by your own rights and interests. In some
              cases, we may also have a legal obligation to collect personal
              information from you or may otherwise need the personal
              information to protect your vital interests or those of another
              person (for example, to prevent payment fraud or confirm your
              identity
            </p>
            <p>
              Our primary goal in collecting your Personal Data and other
              personal information is to provide you a safe, efficient, smooth
              and customized experience. This allows us to provide courses,
              study material, tutorials that most likely meet your needs and to
              customize our Website to make your experience safer and easier. We
              also use your Personal Identifiable Information together with
              other Personally Non-Identifiable Information to help us better
              understand our users and to improve the content and functionality
              of our Website. The information we learn from you helps us
              personalize and continually improve your experience at our
              Website. We do not voluntarily make this information available to
              third parties, nor do we use it for any other purpose, except as
              set out herein.
            </p>
            <p>
              When you visit our Website, as part of a purchase of one of our
              courses or registering on our Website, we may collect a variety of
              information that you will voluntarily provide to us via one of our
              contact forms, via a chat or phone session (Personal Identifying
              Information), such as:
            </p>
            <ul>
              <li>
                Contact Information, such as name, email address, display
                picture, mailing address, phone number, IP address, geographic
                location, or phone number
              </li>
              <li>
                Information to enable us to check and verify your identity, e.g.
                your date of birth, mark-sheets, Passport/Aadhar /Voter ID
                details;
              </li>
              <li>
                Billing Information, such as debit card number, credit card
                number and billing address;
              </li>
              <li>Unique Identifiers, such as username or password;</li>
              <li>
                Reviews or ratings, syllabus and course preferences, account
                settings, (including preferences set in the " Account " section
                of our Website); and
              </li>
              <li>
                Information provided to us through our service, interaction with
                our customer service, participation in surveys or marketing
                promotions.
              </li>
            </ul>
            <p>
              We collect information about you and your use of our service, your
              interactions with us as well as information regarding your
              computer or other device used to access our service).This
              information includes your activity on our Website, such as
              classes, study materials, courses and search queries; details of
              your interactions with customer service , such as the date , time
              and reason for contacting us ,transcripts of any chat
              conversations, and if you call us , your phone number and call
              recordings; device IDs or other unique identifiers, device and
              software characteristics (such as type and configuration),
              connection information, statistics on page views , referring
              source ( for example, referral URLs ), IP address (which may tell
              us your general location), browser and standard web server log
              information, information collected via the use of cookies, web
              beacons and other technologies
            </p>

            <p>
              With the help of back end applications, we aim at providing better
              services and better interaction with teachers, depending on the
              beneficiary’s line of interest. We also may ask for this
              information at other times, such as when you enter contests or
              other promotions sponsored by us and/or our partners. If you use a
              feature that requires payment of a fee options like NEFT or Card
              will appear. In case of payments via credit or debit card, we will
              redirect you to registered payment gateways such as razorpay,
              ccavenue, payubiz and other such registered gateways. You may
              store and save your payment details like card numbers with the
              gateway. We do not have access to this data. All payment
              transactions are processed through secure payment gateway
              providers. We do not store any card information (other than the
              last 4 digits of your card) in our servers.
            </p>
            <p>
              When you use one of our paid products, we track the web pages, and
              information that has been accessed by you, and store it on our
              servers. This enables us to track items that you have completed,
              and those that you need to see.
            </p>
            <p>
              The user's full name and his/her display picture can be publicly
              displayed on the Website. Depending on what profile and what
              services they are seeking, ADPL can ask for more information
              relating to address, billing information, certificates, Academic
              information etc. ADPL can use technologies such as cookies, clear
              gifs, log files, and flash cookies for several purposes, including
              to help understand how you interact with our site and services, in
              order to provide a better experience.
            </p>
          </div>
          <div className="mt-5">
            <h4 className="mb-3">USAGE AND RETENTION OF INFORMATION</h4>
            <p>
              We use the personal information we collect, including your
              Personal Data where it is necessary to deliver the services you
              have requested, where it is necessary to exercise or comply with
              legal rights or obligations or for normal business purposes of the
              kind set out in this Policy.
            </p>
            <p>
              We will use your personal information to provide, analyse,
              administer and improve our services, to provide you with a
              personalized experience on our Website (especially, by offering
              you services that is best suited for you), to contact you about
              your account and our services, to provide you customer service, to
              provide you with personalized marketing and to detect, prevent,
              mitigate and investigate fraudulent or illegal activities.
            </p>
            <p>
              We further use your personal information to determine your general
              geographic location, provide localized courses and classes,
              provide you with customized and personalized study material,
              recommendations, determine your Internet service provider , and
              help us quickly and efficiently respond to inquiries and requests
              and enforcing our terms (such as determining free trial
              eligibility) and communicate with you concerning our service (for
              example by email, push notifications, text messaging ,and online
              messaging channels), so that we can send you details about new
              features and content available on the Website, special offers,
              promotional announcements, surveys, and to assist you with
              operational requests such as password reset requests.
            </p>
          </div>
          <div className="mt-5">
            <h4 className="mb-3">
              SHARING AND DISCLOSING PERSONAL INFORMATION
            </h4>
            <p>
              We use other companies, agents or contractors ("Service
              Providers") to perform services on our behalf or to assist us with
              the provision of services to you. We engage Service Providers to
              provide marketing, advertising, communications, infrastructure and
              IT services, to personalize and optimize our service, to analyze
              and enhance data (including data about users’ interactions with
              our service), and to process and administer consumer surveys. In
              the course of providing such services, these Service Providers may
              have access to your personal or other information.
            </p>
            <p>
              We do not authorize them to use or disclose your personal
              information except in connection with providing their services.
            </p>
          </div>

          <div className="mt-5">
            <h4 className="mb-3">SECURITY</h4>
            <p>
              We shall try to take all precautions to protect the personal
              information both online and offline. We will try to protect your
              information using technical and administrative security measures
              to reduce the risks of loss, misuse, unauthorized access,
              disclosure and alteration. We have standard SSL certification
              which basically helps us create a secure connection between our
              server and user to render any information or action. Some of the
              safeguards we use are firewalls and data encryption, physical
              access controls to our data centres and information access
              authorization controls. Only user passwords are encrypted in
              64-bit encryption and stored because generally users use the same
              password on multiple sites, to prevent any kind of theft, piracy
              or unauthorised access. If you believe your account has been
              abused or hacked, please contact us by sending us an email at{" "}
              <a href="mailto:wecare@allendigital.in">
                {" "}
                wecare@allendigital.in{" "}
              </a>
              .
            </p>
            <p>
              We do not sell, transfer or rent your personal information to
              third parties for their marketing purposes without your explicit
              consent and we only use your information as described in the
              Privacy Policy. We view protection of your privacy as a very
              important community principle. We understand clearly that you and
              your Information is one of our most important assets. We store and
              process your personal information on computers located in India
              that are protected by physical as well as technological security
              devices. We use third parties to verify and certify our privacy
              principles. If you object to your Information being transferred or
              used in this way, please do not use this Website.
            </p>
            <p>
              Under no circumstances, we rent, trade, transfer or share your
              personal information that we have collected with any other company
              for their marketing purposes without your consent. We reserve the
              right to communicate your personal information to any third party
              that makes a legally-compliant request for its disclosure.
            </p>
          </div>
          <div className="mt-5">
            <h4 className="mb-3">Keeping your Personal Data secure</h4>
            <p>
              We have appropriate security measures to prevent your Personal
              Data from being accidentally lost or used or accessed unlawfully.
              Processing your Personal Data will be done only in an authorised
              manner and subject to a duty of confidentiality. We also have
              procedures–in-place to deal with any suspected data security
              breach. We will notify you about any applicable regulator of a
              suspected data security breach where we are legally required to do
              so.
            </p>
            <p>
              Notwithstanding anything contained anywhere in this Agreement; we
              cannot assure absolute security to your personal data and by
              entering into or by logging into our website, you explicitly agree
              not to sue us for any data breach.
            </p>
          </div>
          <div className="mt-5">
            <h4 className="mb-3">COOKIES</h4>
            <p>
              We transfer cookies, which are small files containing a string of
              character, to your IP address, giving the browser distinct
              identification, in order to keep track of the user’s preferences.
              Furthermore, these files also help in logging-in faster and they
              act as a mechanism to determine user trends. The data thus
              retrieved from the user’s IP address, enables us to enhance our
              offers, including but not limited to more content in areas of
              greater interest to a majority of users.
            </p>
            <p>
              Our Website uses "Cookies" to identify the areas of our Website
              that you have visited. A Cookie is a small piece of data stored on
              your computer or mobile device by your web browser. We use Cookies
              to personalize the Content that you see on our Website. Most web
              browsers can be set to disable the use of Cookies. However, if you
              disable Cookies, you may not be able to access functionality on
              our Website correctly or at all. We never place Personally
              Identifiable Information in Cookies.
            </p>
          </div>
          <div className="mt-5">
            <h4 className="mb-3">THIRD PARTIES AND LINKS</h4>
            <p>
              We may pass your details to other companies in our group. We may
              also pass your details to our agents and subcontractors to help us
              with any of our uses of your data set out in our Privacy Policy.
              For example, we may use third parties to assist us with delivering
              services to you, to help us to collect payments from you, to
              analyse data and to provide us with marketing or customer service
              assistance. We may exchange information with third parties for the
              purposes of fraud protection and credit risk reduction. We may
              transfer our databases containing your personal information if we
              sell our business or part of it. Other than as set out in this
              Privacy Policy, we shall NOT sell or disclose your personal data
              to third parties without obtaining your prior consent unless it is
              necessary for the purposes set out in this Privacy Policy or
              unless we are required to do so by law. The Site may contain
              advertising of third parties and links to other sites or frames of
              other sites. Please be aware that we are not responsible for the
              privacy practices or content of those third parties or other
              sites, nor for any third party to whom we transfer your data in
              accordance with our Privacy Policy.
            </p>
            <p>
              Our Website may contain links to other websites that are not under
              our direct control. These websites may have their own policies
              regarding privacy. We have no control of or responsibility for
              linked websites and provide these links solely for the convenience
              and information of our visitors. You accessing such linked
              Websites shall be at your own risk. These websites are not subject
              to this Privacy Policy. You should check the privacy policies, if
              any, of those individual websites to see how the operators of
              those third-party websites will utilize your personal information.
              In addition, these websites may contain a link to Websites of our
              affiliates. The websites of our affiliates are not subject to this
              Privacy Policy, and you should check their individual privacy
              policies to see how the operators of such websites will utilize
              your personal information.
            </p>
          </div>

          <div className="mt-5">
            <h4 className="mb-3">CONSULTING</h4>
            <p>
              We use third parties to help us provide services to You including
              the fulfilment of service, processing of payments, monitoring site
              activity, conducting surveys, maintaining our database,
              administering emails, and administering contents, and to provide
              aggregate, comparative information on the performance of our
              website to us and a select group.
            </p>
          </div>
          <div className="mt-5">
            <h4 className="mb-3">CHOICE</h4>
            <p>
              It is open for you to customize our usage of your personal
              information to communicate with you, to send you marketing
              information, how we provide you with customized and relevant
              advertising, and whether you want to stay signed into your
              account.
            </p>
            <p>
              If you do not wish to receive marketing communications from us,
              you can unsubscribe from the link in the email you would receive
              or change your Communication preferences or indicate your
              communication preferences. You can also reach us via chat,
              WhatsApp, call or email to block promotional communication to you.
              Keep in mind, we do not sell or rent your personal information to
              third parties for their marketing purposes without your explicit
              consent.
            </p>
          </div>
          <div className="mt-5">
            <h4 className="mb-3">OWNERSHIP OF RIGHTS</h4>
            <p>
              All rights, including copyright, in this Website are owned by or
              licensed to us. Any use of this Website or its contents, including
              copying or storing it or them in whole or part, other than for
              your own personal, non-commercial use is prohibited without our
              permission. You are prohibited from modifying, copying,
              distributing, transmitting, displaying, printing, publishing,
              selling, licensing, creating derivative works or using any content
              available on or through our Website for commercial or public
              purposes. You may not modify, distribute or re-post something on
              this Website for any purpose.
            </p>
            <p>
              The Site contains copyrighted material, trademarks and other
              proprietary information, including, but not limited to, text,
              software, photos, video, graphics, music, sound, and the entire
              contents of ADPL is protected by copyright as a collective work
              under the applicable copyright laws. ADPL owns a copyright in the
              selection, coordination, arrangement and enhancement of such
              content, as well as copyright or license to use in the content
              original to it. You may not modify, publish, transmit, participate
              in the transfer or sale, create derivative works, or in any way
              exploit, any of the content, in whole or in part. You may download
              / print / save copyrighted material for your personal use only.
              Except as otherwise expressly stated under copyright law, no
              copying, redistribution, retransmission, publication or commercial
              exploitation of downloaded material without the express permission
              of ADPL is permitted. If copying, redistribution or publication of
              copyrighted material is expressly permitted by ADPL, then no
              changes in or deletion of author attribution, trademark legend or
              copyright notice shall be made.
            </p>
            <p>
              You acknowledge that you do not acquire any ownership rights by
              downloading copyrighted material. Trademarks that are located
              within or on our Website or a website otherwise owned or operated
              in conjunction with ADPL shall not be deemed to be in the public
              domain but rather the exclusive property of ADPL, unless such site
              is under license from the trademark owner thereof in which case
              such license is for the exclusive benefit and use of ADPL, unless
              otherwise stated.
            </p>
            <p>
              ADPL does not have any express burden or responsibility to provide
              you with indications, markings or anything else that may aid you
              in determining whether the material in question is copyrighted or
              trademarked. You shall be solely liable for any damage resulting
              from any infringement of copyrights, trademarks, proprietary
              rights or any other harm resulting from such a submission. By
              submitting material to any public area of the Website, you warrant
              that the owner of such material has expressly granted ADPL the
              royalty-free, perpetual, irrevocable, non-exclusive right and
              license to use, reproduce, modify, adapt, publish, translate and
              distribute such material (in whole or in part) worldwide and/or to
              incorporate it in other works in any form, media or technology now
              known or hereafter developed for the full term of any copyright
              that may exist in such material. You also permit any other end
              user to access, view, store or reproduce the material for that end
              user's personal use. You hereby grant ADPL, the right to edit,
              copy, publish and distribute any material made available on the
              Website by you.
            </p>
            <p>
              If you come across any abuse or violation of these Terms, please
              report to{" "}
              <a href="mailto:vcare@allendigital.in"> vcare@ADPL.com </a>
            </p>
          </div>
          <div className="mt-5">
            <h4 className="mb-3">CONSENT</h4>
            <p>
              By submitting data to us or our agent or using the Site, you
              consent to our use of your data in the manner set out in this
              Privacy Policy.
            </p>
          </div>
          <div className="mt-5">
            <h4 className="mb-3">CONDITIONS OF USE</h4>
            <p>
              Licensee does not warrant that this website, its servers, or email
              sent by us or on our behalf are virus free. We will not be liable
              for any damages of any kind arising from the use of this website,
              including, but not limited to compensatory, direct, indirect,
              incidental, punitive, special or consequential damages, loss of
              data, goodwill, business opportunity, income or profit, loss of or
              damage to property and claims of third parties.
            </p>
          </div>
          <div className="mt-5">
            <h4 className="mb-3">GRIEVANCE OFFICER</h4>
            <p>
              In accordance with Information Technology Act 2000 and rules made
              there under, the name and contact details of the Grievance Officer
              are provided below:
              <a href="mailto:wecare@allendigital.in">
                wecare@allendigital.in{" "}
              </a>
            </p>
          </div>
          <div className="mt-5">
            <h4 className="mb-3">CONSENT TO THIS POLICY</h4>
            <p>
              The Terms of Use Agreement is incorporated herein by reference in
              its entirety.
            </p>
          </div>
          <div className="mt-5"> 
            <h4 className="mb-3">GENERAL</h4>
            <h5>Modification:</h5>
            <p>
              We may at any time modify the Terms of Use of our Website without
              any prior notification to you. Should you wish to terminate your
              account due to a modification to the Terms or the Privacy Policy,
              you may do so email us at{" "}
              <a href="mailto:wecare@allendigital.in">
                {" "}
                wecare@allendigital.in
              </a>
              . However, if you continue to use the service you shall be deemed
              to have agreed to accept and abide by the modified Terms of this
              Website.
            </p>
            <h5>PRIVILEGED/EXCLUSIVE SERVICE:</h5>
            <p>
              By having an ADPL account, you have explicitly given consent for
              us to capture images (followed by analysis), camera/mic
              permissions to make video calls and record the same.
            </p>
            <h5>ADPL MAY DISCLOSE INFORMATION:</h5>
            <p>
              as required by law, such as to comply with a subpoena, or similar
              legal process; to enforce applicable ToU (Terms of Use), including
              investigation of potential violations thereof; when we believe in
              good faith (doctrine of uberrima fides) that the disclosure is
              necessary to protect our rights, protect your safety or the safety
              of others, investigate fraud, address security or technical issues
              or respond to a government request; with our trusted service
              providers who work on our behalf and do not have an independent
              use of the information we disclose to them and have agreed to and
              adhered to the rules set forth in this Policy; to protect against
              imminent harm to the rights, property or safety of the
              Application/Website or our users or the public as required or
              permitted by law; with third party service providers in order to
              personalize the Application/Website/Services/products for a better
              user experience and to perform behavioural analysis;
            </p>
            <h5>Governing Law and Jurisdiction:</h5>
            <p>
              In the event of any dispute arising between the parties with
              respect to this Agreement, the same shall be referred to the Sole
              Arbitrator and the arbitration shall be in accordance with
              Arbitration and Conciliation Act of 1996. The language of
              arbitration proceedings shall be English. The seat and place of
              arbitration shall be KOTA and the decision of the Arbitrator shall
              be final and binding on both parties herein.
            </p>
            <p>
              This contract shall be subject to the exclusive jurisdiction of
              courts in KOTA, Rajasthan, India and shall be governed by the
              Indian laws.
            </p>
          </div>*/}
        {/* </div> */}
      </>
    );
  }
}

export default Policies;
