import React from 'react';
class FormSuccess extends React.Component {
    render() { 
        return  <div className="card-success-msg">
        <div style={{borderRadius:"200px", height:"200px", width:"200px", background: "#F8FAF5", margin:"0 auto"}}>
          <i className="i-check">✓</i>
        </div>
          <h1>Success</h1> 
          <p>We received your request;<br/> we'll be in touch shortly!</p>
        </div>;
    }
}
 
export default FormSuccess;