import React, { useEffect, useState } from "react";
// import { environment } from "../../Services/HttpData";
import { Link } from "react-router-dom";
import "../../css/ecom/ecomStyle.css";
import razorpay from "../../images/razor-pay-logo.png";
import hdfclogo from "../../images/hdfc-logo.png";
import { useParams } from "react-router";
import EcomShowAddress from "../../components/Ecom/EcomShowAddress";
import EcomAddUserAddress from "../../components/Ecom/EcomAddUserAddress";
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import EcomCheckoutPayment from "../../components/Ecom/EcomCheckoutPyament";
import CheckoutInstallment from "../../components/Ecom/CheckoutInstallment";
import EcomProfileDetails from "../../components/Ecom/EcomProfileDetails";
import EcomEditProfile from "../../components/Ecom/EcomEditProfile";
import MixpanelInit from "../../components/Mixpanel/MixpanelInit";
import Modal from "react-modal";
import { useHistory } from "react-router";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);

const EcomCheckout = () => {
	const history = useHistory();
	const { orderId } = useParams();
	const { cartId } = useParams();
	const [productDetails, setProductDetails] = useState([]);
	const [showInstallment, setShowInstallment] = useState(orderId ? true : false);
	const [loading, setLoading] = useState(true);
	const [addressObject, setAddressObject] = useState([]);
	const [addressDetails, setAddressDetails] = useState({});
	const [profileDetails, setProfileDetails] = useState([]);
	/* const [orderId,setOrderId] = useState(""); */
	const [response, setResponse] = useState("");
	const [checkoutDetails, setCheckoutDetails] = useState([]);
	const [shouldProceed, setShouldProceed] = useState(true);
	const [gstin, setGstin] = useState("");
	const [gstinselected, setGstinselected] = useState(false);
	const [messageToShow, setMessageToShow] = useState("");
	const [gstVerfy_is, SetgstVerfy_is] = useState({});

	//const [installmentValue,setInstallmentValue] = useState(0);
	let [totalProductPrice, setProductPrice] = useState(0);
	const [productDiscount, setProductDiscount] = useState({
		coupon_id: "",
		coupon_title: "",
		discount: 0,
	});
	const [totalCartPrice, setCartPrice] = useState(0);
	const [cartDiscount, setCartDiscount] = useState({
		coupon_id: "",
		coupon_title: "",
		discount: 0,
	});
	const [initialCartItems, setInitialCartItems] = useState({});
	let cartItems;
	let userDetails;
	const [cartDetails, setCartDetails] = useState({
		value: null,
		currencySign: "INR",
	});
	const [BankTransferPop, setBankTransferPop] = useState(false);
	const BDFValue = {
		cartID: "",
		type: "",
		addressObject: {},
	};
	const [BankTransferPopValue, setBankTransferPopValue] = useState(BDFValue);
	const [virtualorderlist, setVirtualorderlist] = useState({});
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			padding: "0px",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			borderRadius: "8px",
			backgroundColor: "rgb(0 0 0 / 75%)",
		},
		overlay: {
			background: "rgb(0 0 0 / 75%)",
		},
	};

	const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "";
	const wid_data = localStorage.getItem("userWID") ? JSON.parse(localStorage.getItem("userWID")) : null;
	let wid = window.localStorage.getItem("IS_DASHBOARD_ICON") ? window.localStorage.getItem("IS_DASHBOARD_ICON") : false;
	const handleevent = e => {
		const { name, value } = e.target;
		if (!/\s/g.test(value)) {
			let convertToCaps = value.toUpperCase()
			setGstin(convertToCaps);
		}
	};

	const getEditData = () => {
		alert("Data Show");
	};

	const getAddress = () => {
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Basic " + auth_token,
			},
		};
		fetch(environment.ecomApiUrl + "address/list", requestOptions).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				if (result?.data) {
					setAddressObject(result?.data);
				} else {
					setAddressObject([]);
				}
			});
		});
	};

	useEffect(() => {
		// //console.log('cart-load')
		// if(Object.keys(initialCartItems).length==0){
		if (!orderId) {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Basic " + auth_token,
				},
			};
			fetch(environment.ecomApiUrl + "cart/list", requestOptions).then(resp => {
				// fetching api url for courses listing
				resp.json().then(result => {
					//setCartItems(result.data.items);  // set result in state this will set current/updated value of setProduct to product
					//console.log('abcd',result.data);
					let failed = true;
					if (result.data) {
						if (result.data.items) {
							if (result.data.items.length) {
								failed = false;
								setCartDetails(result.data.cart);
								setInitialCartItems(result.data.items[0]);
								setProductDetails(result.data.items[0]);
								if (result.data.items[0].emi.length) {
									//setShowInstallment(true);
									////console.log("emi lenth "+result.data.items[0].emi.length);
								}
							}
						}
					}

					if (failed) {
						window.location = "/ecom/cart";
					}
				});
			});
		}

		//}
	}, []);

	function loadScript(src) {
		return new Promise(resolve => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = () => {
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};
			document.body.appendChild(script);
		});
	}

	function selectUserAddress() {
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Basic " + auth_token,
			},
		};
		fetch(environment.ecomApiUrl + "user/address", requestOptions).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				setCheckoutDetails(result.data);
				////console.log(checkoutDetails);
			});
		});
	}

	function myFunction() {
		var x = document.getElementById("myDIV");
		if (x.style.display === "block") {
			x.style.display = "none";
		} else {
			x.style.display = "block";
		}
	}

	const [checkoutinstallment, setCheckoutinstallment] = useState([]);
	useEffect(() => {
		if (orderId) {
			//console.log("emi api");
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Basic " + auth_token,
				},
			};
			fetch(environment.ecomApiUrl + "order/get-emi/" + orderId, requestOptions).then(resp => {
				// fetching api url for courses listing
				resp.json().then(result => {
					installmentChk(true, result.data.emi[0]);
					setCheckoutinstallment(result.data.emi);
					// setShowInstallment(true);
					if (result.data.details) {
						if (result.data.details.items) {
							if (result.data.details.items.length) {
								cartItems = result.data.details.items[0];
								//console.log("get-emi",cartItems)
							}
						}
					}

					//console.log(result.data.emi);
				});
			});
		} else {
			const requestOptions = {
				method: "GET",
				headers: {
					Authorization: "Basic " + auth_token,
				},
			};
			fetch(environment.ecomApiUrl + "order/get-emi/", requestOptions).then(resp => {
				// fetching api url for courses listing
				resp.json().then(result => {
					if (result.data.emi.length) {
						installmentChk(true, result.data.emi[0]);
						setCheckoutinstallment(result.data.emi);
						setShowInstallment(true);
					}

					//console.log(result.data.emi);
				});
			});
		}
	}, []);

	const proceedCheckOut = async (cartId, type, address) => {
		if (shouldProceed) {
			if (profileDetails?.student_email === null || profileDetails?.student_email === "") {
				toast.warning("Please Fill Out The Complete Profile Information.");
				return;
			}
			if (profileDetails?.student_dob === null || profileDetails?.student_dob === "") {
				toast.warning("Please Fill Out The Complete Profile Information.");
				return;
			}
			if (profileDetails?.father_name === null || profileDetails?.father_name === "") {
				toast.warning("Please Fill Out The Complete Profile Information.");
				return;
			}
			if (profileDetails?.student_gender_id === null || profileDetails?.student_gender_id === "") {
				toast.warning("Please Fill Out The Complete Profile Information.");
				return;
			}
			if (gstinselected && gstin.length < 1) {
				toast.warning("Please Enter GSTIN");
				return;
			}
			if (gstinselected && !gstVerfy_is?.status) {
				toast.warning("Please verify GSTIN.");
				return;
			}
			if (gstinselected && gstVerfy_is?.status == "failed") {
				toast.warning("Please verify GSTIN.");
				return;
			}
			if (address[0]?.student_address_details_id && address[1]?.student_address_details_id) {
				if (!orderId) {
					setLoading(false);
					const requestOptionsCart = {
						method: "GET",
						headers: {
							Authorization: "Basic " + auth_token,
						},
					};
					//let isEmi = false;
					await fetch(environment.ecomApiUrl + "cart/list", requestOptionsCart).then(resp => {
						// fetching api url for courses listing
						resp.json().then(result => {
							if (result.status == "failed" && result.message == "Cart was Expired") {
								window.location = "/Linkexpired";
							}
							//setCartItems(result.data.items);  // set result in state this will set current/updated value of setProduct to product
							cartItems = result.data.items;
							//totalProductPrice = cartItems[0].mrp - cartItems[0].discount;
							////console.log(cartItems);
						});
					});
				} else {
					setLoading(false);
					const requestOptions = {
						method: "GET",
						headers: {
							Authorization: "Basic " + auth_token,
						},
					};
					await fetch(environment.ecomApiUrl + "order/get-emi/" + orderId, requestOptions).then(resp => {
						// fetching api url for courses listing
						resp.json().then(result => {
							installmentChk(true, result.data.emi[0]);
							setCheckoutinstallment(result.data.emi);
							// setShowInstallment(true);
							if (result.data.details) {
								if (result.data.details.items) {
									if (result.data.details.items.length) {
										cartItems = result.data.details.items[0];
										//console.log("get-emi",cartItems)
									}
								}
							}

							//console.log(result.data.emi);
						});
					});
				}
				const requestOptionsUser = {
					method: "GET",
					headers: {
						Authorization: "Basic " + auth_token,
					},
				};

				await fetch(environment.ecomApiUrl + "user/detail", requestOptionsUser).then(resp => {
					// fetching api url for courses listing
					resp.json().then(result => {
						//setCartItems(result.data.items);  // set result in state this will set current/updated value of setProduct to product
						userDetails = result.data;
						//console.log(userDetails);
					});
				});

				/*  if (localStorage.getItem("userWID")) { */
				const requestOptions = {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Basic " + auth_token,
					},
					body: JSON.stringify({
						cart_id: cartId,
						type: type,
						address: address[0],
						order_id: orderId,
						pay_emi: installments.length,
						gst_in: gstin,
						cart_item_id: initialCartItems.cart_item_id ? initialCartItems.cart_item_id : "",
						coupon_code: cartDetails.coupon_title ? cartDetails.coupon_title : "",
					}),
				};
				const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
				fetch(environment.ecomApiUrl + "order/checkout", requestOptions).then(resp => {
					// fetching api url for courses listing
					resp.json().then(result => {
						//setOrderId(result.data.order_id);
						if (result?.status == "failed") {
							toast.warning(result?.message)
							setLoading(false);
							// setCartDetails ({...cartDetails,'cart_discount_amount': ''})
							setTimeout(() => {
								window.location = "/";
							  }, 1000);
						} else {
							if (result.data.gateway_responce) {
								MixpanelInit("Track", "payment_initiated", {
									course_id: initialCartItems?.product_id,
									course_title: initialCartItems?.product_title,
									course_fee: initialCartItems?.mrp,
									discount: cartDetails?.cart_discount_amount,
									total_price: cartDetails?.final_pay_amount,
									"coupon_applied?": cartDetails?.cart_coupon_id !== null ? "Y" : "N",
								});
								setResponse(result.data.gateway_responce);
								if (type == "razorpay") {
									let options = {
										key: environment.razorpayKey,
										//"amount": 100.00,
										currency: "INR",
										name: cartItems.product_title,
										description: "",
										image: `${environment?.baseUrlImg}attachment-1632935528953.png`,
										order_id: result.data.gateway_responce.id,
										handler: function (response) {
											if (result?.data?.IS_WEBHOOK === 1) {
												setTimeout(() => {
													history.push("/ecom/order/status/" + result.data.order_id, {
														student_email: userDetails.student_email,
													});
												}, 6000);
											} else {
												const requestOptions1 = {
													method: "POST",
													headers: {
														"Content-Type": "application/json",
														Authorization: "Basic " + auth_token,
													},
													body: JSON.stringify({
														order_id: result.data.order_id,
														gateway_order_id: response.razorpay_order_id,
														gateway_payment_id: response.razorpay_payment_id,
														gateway_signature: response.razorpay_signature,
														cart_id: cartId,
														coupon_code: cartDetails.coupon_title ? cartDetails.coupon_title : "",
														cart_item_id: initialCartItems.cart_item_id,
													}),
												};
												fetch(environment.ecomApiUrl + "order/razorpay-verify", requestOptions1).then(resp => {
													// fetching api url for courses listing
													resp.json().then(result1 => {
														//console.log(result1);
														/* if(result1.status == "success"){ */
														//toast.success(result1.message);
														if (typeof orderId == "undefined") {
															history.push("/ecom/order/status/" + result.data.order_id, {
																student_email: userDetails.student_email,
															});
															// window.location = "/ecom/order/status/" + result.data.order_id;
														} else {
															window.location = "/ecom/order-installment/status/" + result.data.installment_id;
														}
														/* }else{
											  toast.error(result1.message);
											} */
													});
												});
											}

											//console.log(response);

											// toast.success("This step of Payment Succeeded");
										},
										prefill: {
											contact: userDetails.student_mobile_no,
											name: userDetails.student_first_name,
											email: userDetails.student_email,
										},
										notes: {
											description: "",
											name: cartItems.product_title,
										},
										theme: {
											color: "#2300a3",
										},
										modal: {
											ondismiss: function () {

												if (result?.data?.IS_WEBHOOK === 1) {
													const requestOptions = {
														method: "POST",
														headers: {
															"Content-Type": "application/json",
															Authorization: "Basic " + auth_token,
														},
														body: JSON.stringify({
															order_id: result.data.order_id
														}),
													};
													fetch(
														environment.ecomApiUrl + "order/make/failed/odrer",
														requestOptions
													).then((resp) => {
														// fetching api url for courses listing
														resp.json().then((result1) => {
															history.push("/ecom/order/status/" + result.data.order_id, {
																student_email: userDetails.student_email,
															});
														});
													});
												} else {
													if (typeof orderId == "undefined") {
														// window.location = "/ecom/order/status/" + result.data.order_id;
														history.push("/ecom/order/status/" + result.data.order_id, {
															student_email: userDetails.student_email,
														});
													} else {
														setLoading(true);
													}
												}
												//console.log('Checkout form closed');

											},
										},
									};
									let razorpayObject = new window.Razorpay(options);

									//console.log(razorpayObject);
									razorpayObject.on("payment.failed", function (response) {
										//console.log(response);
										toast.success("This step of Payment Failed");
									});
									razorpayObject.open();
								} else if (type === "hdfc") {
									let f = document.createElement("form");
									f.action = environment.ccavenue;
									f.method = "POST";

									let i = document.createElement("input");
									i.type = "hidden";
									i.name = "encRequest";
									i.value = result.data.gateway_responce;
									f.appendChild(i);

									let j = document.createElement("input");
									j.type = "hidden";
									j.name = "access_code";
									j.value = environment.hdfcKey;
									f.appendChild(j);

									document.body.appendChild(f);
									f.submit();
								} else {
									// window.location = "/ecom/order/status/" + result.data.order_id;
									history.push("/ecom/order/status/" + result.data.order_id, {
										student_email: userDetails.student_email,
									});
								}
							} else {
								if (typeof orderId == "undefined") {
									// window.location = "/ecom/order/status/" + result.data.order_id;
									history.push("/ecom/order/status/" + result.data.order_id, {
										student_email: userDetails.student_email,
									});
								} else {
									window.location = "/ecom/order-installment/status/" + result.data.installment_id;
								}
							}
						}


						//console.log(result.data);
					});
				});
				/*  }else{
			doLogin();
		  } */
			} else {
				toast.error("Please Add Billing and Shipping Address");
				//console.log("Please Select Address");
			}
		} else {
			let id = "t101";
			toast.warning(messageToShow, {
				toastId: id,
			});
		}
	};

	// const selectAddress = (object, type) => {
	//   // setAddressObject(object);
	//   setAddressObject((preValue)=>{
	//     return{
	//         ...preValue,
	//         [type] : object
	//     };

	// })
	// // console.log("addressobject",addressObject);
	// }
	/* Update Address */
	const updateAddress = obj => {
		setAddressDetails(obj);
		//console.log(obj);
	};

	let sum = 0;
	const [installments, setInstallments] = useState([]);
	//console.log(installments)
	//console.log('object-chk-1',installments)
	const installmentChk = (chk, object) => {
		if (chk) {
			let findTmp = false;
			for (let i = 0; i < installments.length; i += 1) {
				if (object.installment_id == installments[i].installment_id) {
					findTmp = true;
				}
			}
			//console.log('object-chk-0.0',findTmp)
			if (!findTmp) {
				//console.log('object-chk-0.1')
				let nerval = setInstallments(installments => [...installments, object]);
				//console.log('object-chk-0.2',nerval)
			}
		} else {
			setInstallments(installments => {
				const copyItems = [...installments];
				const finalItems = [];
				for (let i = 0; i < copyItems.length; i += 1) {
					if (object.installment_id != copyItems[i].installment_id) {
						finalItems.push(copyItems[i]);
					}
				}
				return finalItems;
			});
		}

		//console.log('object-chk-0',chk)

		const newcopyItems = [...installments];
		//console.log('object-chk-2',newcopyItems)
		//console.log('object-chk-3',object)
	};

	const proceedToCheckout = () => {
		const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "";
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Basic " + auth_token,
			},
		};
		fetch(environment.ecomApiUrl + "order/check-active-course", requestOptions).then(res => {
			res.json().then(result => {
				if (result?.data?.course_is_active == "1" || result?.data?.course_is_active == 1) {
					setShouldProceed(false);
					setMessageToShow(result.message);
				} else {
					setShouldProceed(true);
				}
			});
		});
	};

	const installmentValue = () => {
		let sum = 0;
		//console.log(installments)
		for (let i = 0; i < installments.length; i += 1) {
			if (installments[i]) {
				sum += Number(installments[i].installment_amount) - Number(installments[i].discount);
			}
		}
		//console.log('sum',sum)
		return sum;
	};
	const getprofileDetails = () => {
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Basic " + auth_token,
			},
		};
		fetch(environment.ecomApiUrl + "address/profile-details", requestOptions).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				if (result?.status !== "failed") {
					// console.log(result?.data);
					setProfileDetails(result?.data);
					if (
						result?.data?.student_gender_id === null ||
						result?.data?.student_gender_id === "" ||
						result?.data?.father_name === null ||
						result?.data?.father_name === "" ||
						result?.data?.student_dob === null ||
						result?.data?.student_dob === ""
					) {
						if (
							document.querySelector("#profileUpdateForm").style.display === "none" ||
							document.querySelector("#profileUpdateForm").style.display === ""
						) {
							document.querySelector("#profileUpdateForm").style.display = "block";
						}
					} else {
						if (!addressObject[0]?.student_address_details_id && !addressObject[1]?.student_address_details_id) {
							if (
								document.getElementById("BillingForm").style.display === "none" ||
								document.getElementById("BillingForm").style.display === ""
							)
								editAddressInfo();
						}
					}
				} else {
					setProfileDetails([]);
				}

				//console.log(checkoutDetails);
			});
		});
	};

	const editProfile = obj => {
		var x = document.getElementById("profileUpdateForm");
		if (x.style.display === "block") {
			x.style.display = "none";
		} else {
			x.style.display = "block";
		}

		//addbillinghide()
	};

	const editAddressInfo = obj => {
		var x = document.getElementById("BillingForm");
		let y = document.getElementById("addbillinghide");
		if (x.style.display === "block") {
			x.style.display = "none";
			// y.style.display = "block"
		} else {
			x.style.display = "block";
			y.style.display = "none";
		}
	};

	const gstinform = () => {
		if (gstinselected == false) {
			setGstin('')
			SetgstVerfy_is('')
			setGstinselected(true);
		} else {
			setGstin('')
			SetgstVerfy_is('')
			setGstinselected(false);
		}

		var x = document.getElementById("gstininput");
		if (x.style.display == "none") {
			x.style.display = "block";
		} else {
			x.style.display = "none";
		}
	};

	const DirectBankTransferCheck = (cartId, type, address) => {
		if (shouldProceed) {
			if (profileDetails?.student_email === null || profileDetails?.student_email === "") {
				toast.warning("Please Fill Out The Complete Profile Information.");
				return;
			}
			if (profileDetails?.student_dob === null || profileDetails?.student_dob === "") {
				toast.warning("Please Fill Out The Complete Profile Information.");
				return;
			}
			if (profileDetails?.father_name === null || profileDetails?.father_name === "") {
				toast.warning("Please Fill Out The Complete Profile Information.");
				return;
			}
			if (profileDetails?.student_gender_id === null || profileDetails?.student_gender_id === "") {
				toast.warning("Please Fill Out The Complete Profile Information.");
				return;
			}
			if (gstinselected && gstin.length < 1) {
				toast.warning("Please Fill Out The Complete Profile Information.");
				return;
			}
			if (gstinselected && !gstVerfy_is?.status) {
				toast.warning("Please verify GSTIN.");
				return;
			}
			if (gstinselected && gstVerfy_is?.status == "failed") {
				toast.warning("Please verify GSTIN.");
				return;
			}
			if (address[0]?.student_address_details_id && address[1]?.student_address_details_id) {
				const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "";
				var myHeaders = new Headers();
				myHeaders.append("Authorization", "Bearer " + auth_token);

				var requestOptions = {
					method: "GET",
					headers: myHeaders,
					redirect: "follow",
				};

				fetch(environment.ecomApiUrl + "order/virtual-order-list", requestOptions)
					.then(response => response.json())
					.then(result => {
						if (result?.status === "success") {
							if (result?.data.length === 0) {
								proceedCheckOut(cartDetails.cart_id, "HDFC-DBT", addressObject);
							} else {
								setBankTransferPop(true);
								setVirtualorderlist(result?.data?.data);
								setBankTransferPopValue({
									cartID: cartDetails.cart_id,
									type: "HDFC-DBT",
									addressObject: addressObject,
								});
							}
						}
					})
					.catch(error => console.log("error", error));
			} else {
				toast.error("Please Add Billing and Shipping Address");
				//console.log("Please Select Address");
			}
		} else {
			let id = "t101";
			toast.warning(messageToShow, {
				toastId: id,
			});
		}
	};
	const gstVerify = e => {
		if (gstinselected && gstin.length <= 14) {
			toast.warning("Please enter valid GSTIN");
			return;
		}
		const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "";
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + auth_token);

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};
		fetch(process.env?.REACT_APP_AUTH_URL + "validate/" + gstin, requestOptions)
			.then(res => res.json())
			.then(resp => {
				if (resp?.status == "success") {
					toast.success(resp?.message);
					SetgstVerfy_is(resp);
				} else {
					toast?.error(resp?.message);
				}
			});
	};
	useEffect(() => {
		proceedToCheckout();
		getprofileDetails();
		getAddress();
	}, []);

	return (
		<>
			<div className='container mt-5'>
				<div className='row'>
					<div className='col-md-9'>
						{showInstallment ? (
							<CheckoutInstallment
								onChange={installmentChk.bind()}
								checkoutinstallment={checkoutinstallment}
								selectedInstallment={installments}
							/>
						) : null}
						<div className='side-profile-box'>
							<div className='checkout-address-detail'>
								<div className='address-text-box' style={{ display: "flex", justifyContent: "space-between" }}>
									<b>Profile Info</b>{" "}
									<b onClick={() => editProfile(profileDetails[0])} style={{ color: "green", cursor: "pointer" }}>
										Edit
									</b>
								</div>
							</div>
							<div className='address-and-btns mt-3'>{<EcomProfileDetails profileDetails={profileDetails} />}</div>
							<EcomEditProfile profileDetails={profileDetails} onChange={getprofileDetails.bind()} />
						</div>
						<div className='side-address-box'>{<EcomShowAddress onChange={getAddress.bind()} />}</div>

						{/* <div className='side-address-box'>
							<div className='checkout-address-detail'>
								<div className='address-text-box'>
								<div className='address-text-box' style={{ display: "flex", justifyContent: "space-between" }}>
									<b>Address Info</b>{" "}
									<b onClick={() => editAddressInfo() } style={{ color: "green", cursor: "pointer" }}>
										Edit
									</b>
								</div>
								</div>
							</div>
							<div className='address-and-btns mt-3'>
								{<EcomShowAddress onChange={getAddress.bind()} onChangeUpdateAddress={updateAddress.bind()} />}
							</div> */}
						{/* <EcomAddUserAddress onChange = {selectUserAddress.bind()} checkoutDetails = {checkoutDetails} addressDetails={addressDetails}/> */}
						{/* </div> */}
						<div className='side-gst-box'>
							<input type='checkbox' style={{ display: "inline-block" }} id='gstincheck' onClick={gstinform}></input>{" "}
							Have GSTIN(Optional) <br></br>
							<input
								type='text'
								className='form-control col-6 mt-2'
								id='gstininput'
								placeholder='Enter GSTIN'
								style={{ display: "none" }}
								name='gstin'
								value={gstin ? gstin : ""}
								maxLength={15}
								disabled={gstVerfy_is?.status == "success"}
								onChange={handleevent}></input>
							{gstVerfy_is?.status == "success" && gstinselected && (
								<>
									<span style={{ color: "#046635", fontSize: "10px" }}>{gstVerfy_is?.data?.name}</span>
									<br></br>
									<button
										onClick={e => {
											SetgstVerfy_is({});
										}}>
										Update GSTIN
									</button>
									<br />
								</>
							)}
							<br />
							{(gstinselected && gstVerfy_is?.status !== "success") && (
								<button disabled={gstVerfy_is?.status == "success"} onClick={gstVerify}>
									Verify GSTIN
								</button>
							)}
						</div>
					</div>
					<div className='col-md-3'>
						<div className='payment-side-bar'>
							<EcomCheckoutPayment
								initialCartItems={initialCartItems}
								cartDetails={cartDetails}
								onChange={proceedCheckOut.bind()}
								showInstallment={showInstallment}
								installmentCal={() => installmentValue()}
							/>
							{loading ? (
								<div className='pay-btn text-center mt-3'>
									<button
										className='continue-with-razor'
										onClick={() => proceedCheckOut(cartDetails.cart_id, "razorpay", addressObject)}>
										<span> Continue With</span> <img src={razorpay}></img>
									</button>
									<button
										className='continue-with-hdfc mt-2'
										onClick={() => proceedCheckOut(cartDetails.cart_id, "hdfc", addressObject)}>
										<span> Continue With</span> <img src={hdfclogo}></img>
									</button>
									{/* <button
										style={{ width: "85%" }}
										className='continue-with-razor mt-2'
										onClick={() => DirectBankTransferCheck(cartDetails.cart_id, "HDFC-DBT", addressObject)}>
										<span> Direct Bank Transfer</span>
									</button> */}
								</div>
							) : (
								<div className='pay-btn text-center mt-3'>
									<TailSpin color='#00BFFF' height={30} width={30} />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<Modal
				isOpen={BankTransferPop}
				onAfterOpen={() => { }}
				onRequestClose={e => {
					setBankTransferPop(false);
					setBankTransferPopValue(BDFValue);
				}}
				style={customStyles}
				contentLabel='Example Modal'
				ariaHideApp={false}>
				{/* <div className="row">
					<div className="col-md-6">
						<div style={{ textAlign: "end" }}>
							<button
								className="btn btn-sm btn-danger"
								onClick={(e) => {
									setBankTransferPop(false);
									setBankTransferPopValue(BDFValue)
								}}
								style={{ position: "relative", width: "50px" }}
							>
								X
							</button>
						</div>
					</div>
				</div>

				<div style={{ textAlign: "end" }}>
					<h4>You already have pending order</h4>
					<div>
						<div>
							Course Name : {virtualorderlist[0]?.course_name}
						</div>
						<div>
							Price : {virtualorderlist[0]?.order_amount}
						</div>
					</div>

				</div> */}

				<div
					style={{
						maxWidth: "550px",
						textAlign: "center",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}>
					<h4 className='text-center text-white' style={{ background: "#dc3545", padding: "15px", width: "calc(100% + 40px)", marginLeft: "-20px", marginTop: "-20px" }}>You already have pending order.</h4>
					<div className="d-flex justify-content-between py-2">
						<svg style={{ fill: "#dc3545", marginTop: "5px" }} xmlns="http://www.w3.org/2000/svg" height="35px" width="35px" viewBox="0 0 576 512"><path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>
						<div className="text-left ml-3">
							<div className="d-flex justify-content-start"><span className="font-weight-bold  text-nowrap">Course Name :  &nbsp;</span> {virtualorderlist[0]?.course_name}</div>
							<div className="d-flex justify-content-start"><span className="font-weight-bold  text-nowrap">Price (₹) : &nbsp;</span>  {virtualorderlist[0]?.order_amount}</div>
						</div>
					</div>


					<div className="font-weight-bold"
						// ref={(_subtitle) => (subtitle = _subtitle)}
						style={{ color: "#000 !important", fontSize: "18px", borderTop: "solid 1px #ced4da", marginTop: "15px", padding: "15px 0px" }}>
						Are you sure to Cancel ?
					</div>

					<div className=''>
						<div className='row justify-content-center mt-2'>
							<button
								className='btn btn-success'
								onClick={e => {
									setBankTransferPop(false);
									setBankTransferPopValue(BDFValue);
									proceedCheckOut(BankTransferPopValue?.cartID, "HDFC-DBT", BankTransferPopValue?.addressObject);
								}}
								style={{
									position: "relative",
									top: "0px",
									borderRadius: "0px",
									marginRight: "10px",
									minWidth: "100px",
									background: "none",
									border: "solid 1px #ccc",
									color: "#000",
								}}
							// data-toggle="modal" data-target="#exampleModal"
							>
								Yes
							</button>
							<button
								className='btn btn-danger'
								onClick={e => {
									setBankTransferPop(false);
									setBankTransferPopValue(BDFValue);
								}}
								style={{
									position: "relative",
									top: "-0px",
									marginLeft: "1%",
									borderRadius: "0px",
									minWidth: "100px",
								}}>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default EcomCheckout;
