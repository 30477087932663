import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { validationFunction } from "../Ecom/ValidationHelper";
import Modal from "react-modal";
import MixpanelInit from "../Mixpanel/MixpanelInit";
const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);

const EcomEditProfile = props => {
	let profileDetails = props.profileDetails;
	// console.log("from profile edit",profileDetails)
	// console.log("profileDetails",profileDetails)
	const [getOpen, setOpen] = useState(false);
	const [isEmailOTPSent, setEmailOTPSent] = useState(false);
	const [isEmailOtp, setEmailOtp] = useState("");
	const [buttonEmailName, setbuttonEmailName] = useState("Send OTP");
	const [isEmailOTPReSent, setEmailOTPReSent] = useState(false);
	const [getTimeInSecEmail, setTimeInSecEmail] = useState("");
	const [getEmailTimeInSec, setEmailTimeInSec] = useState("");
	const [getAction, setAction] = useState("");
	const [emailVerifyBtn, setEmailVerifyBtn] = useState("Verify");
	const [emailDisabled, setEmailDisabled] = useState(false);

	const [text, setText] = useState({
		student_first_name: "",
		student_middle_name: "",
		student_last_name: "",
		student_email: "",
		student_dob: "",
		student_alternate_mobile_no: "",
		student_mobile_no: "",
		student_gender_id: "",
		father_name: "",
		mother_name: "",
	});

	function scroll(id) {
		let e = document.getElementById(id);
		e.scrollIntoView({
			block: "start",
			behavior: "smooth",
			inline: "start",
		});
	}

	const handleEvent = e => {
		const { name, value } = e.target;
		if (
			(name === "student_mobile_no" && /^[0-9,+,-]*$/.test(value) && !value.includes("e") && !value.includes(".")) ||
			value === ""
		) {
			setText(preValue => {
				return {
					...preValue,
					[name]: value,
				};
			});
		} else if (
			name === "student_alternate_mobile_no" &&
			/^[0-9,+,-]*$/.test(value) &&
			!value.includes("e") &&
			!value.includes(".")
		) {
			setText(preValue => {
				return {
					...preValue,
					[name]: value,
				};
			});
		} else if (!(name === "student_mobile_no" || name === "student_alternate_mobile_no")) {
			if (validationFunction?.validateUserFields("spaceonly", value)) {
				setText(preValue => {
					return {
						...preValue,
						[name]: value.trimLeft(),
					};
				});
			}
		}

		// console.log(text)
	};
	const EmailPOP_open = () => {
		if (!validationFunction?.validateUserFields("email", text?.student_email) || text?.student_email?.trim().length === 0) {
			toast.warning("Enter Valid Email Address");
		} else {
			setOpen(true)
		}

	}
	const submitUpdatedForm = e => {
		e.preventDefault();
		if (
			text?.student_first_name?.trim()?.length < 2 ||
			!/^[A-Za-z\s]*$/.test(text?.student_first_name)
		) {
			toast.warning("Please Enter Your Valid First Name. Special characters, numbers and spaces are not allowed.");
			return;
		} else if (!/^[A-Za-z\s]*$/.test(text?.student_middle_name)) {
			toast.warning("Please Enter Your Valid Middle Name. Special characters, numbers and spaces are not allowed.");
			return;
		} else if (!/^[A-Za-z\s]*$/.test(text?.student_last_name)) {
			toast.warning("Please Enter Your Valid Last Name. Special characters, numbers and spaces are not allowed.");
			return;
		} else if (!validationFunction?.validateUserFields("email", text?.student_email) || text?.student_email?.trim().length === 0) {
			toast.warning("Enter Valid Email Address");
			return false;
		} else if (emailVerifyBtn == "Verify" && profileDetails?.student_email?.trim() !== text?.student_email?.trim()) {
			toast.warning("Please Verify Email");
			return false;
		} else if (text.student_alternate_mobile_no.trim().length && text.student_alternate_mobile_no.trim().length < 10) {
			toast.warning("Enter Valid Alternate Mobile Number");
			return false;
		} else if (text.student_alternate_mobile_no.trim() == text.student_mobile_no.trim()) {
			toast.warning("Mobile Number and Alternate Mobile Number Should not be Same.");
			return false;
		} else if (text?.student_dob === "" || text?.student_dob === null) {
			toast.warning("Please Enter Date Of Birth");
			return false;
		}
		else if ((text?.father_name?.trim().length < 1) || (!/^[A-Za-z\s]*$/.test(text?.father_name))) {
			//toast.error("Please Enter Father's Name");
			toast.warning("Please Enter Your Valid Father's Name. Special characters, numbers and spaces are not allowed.");
			return false;
		} else if (text?.mother_name?.trim().length > 0 && !/^[A-Za-z\s]*$/.test(text?.mother_name)) {
			//toast.error("Please Enter Father's Name");
			toast.warning("Please Enter Your Valid Mother's Name. Special characters, numbers and spaces are not allowed.");
			return false;
		} else if (text?.student_gender_id.length < 1) {
			toast.warning("Please Select Gender");
			return false;
		}

		//   else if (text?.student_email.trim().length < 1 || !validationFunction?.validateUserFields('email',text?.student_email)) {
		//     toast.error("Enter Valid Email Address");
		//     return false;
		//   }
		// console.log("final submit",(new Date(text?.student_dob)))
		MixpanelInit("Identify", "$identify", (window.localStorage.getItem("FORM_NO") ? window.localStorage.getItem("FORM_NO") : ""));
		MixpanelInit("People_Set", "profile_updated", {
			email_id: text?.student_email,
			dob: (new Date(text?.student_dob)),
			alternate_mobile_number: text.student_alternate_mobile_no,
			gender: text?.student_gender_id == 111 ? "Male" : "Female",
			father_name: text?.father_name,
			mother_name: text?.mother_name,
			$name: text?.student_first_name + " " + text?.student_middle_name + " " + text?.student_last_name,
		});
		MixpanelInit("Track", "profile_updated", {
			email_id: text?.student_email,
			dob: (text?.student_dob),
			alternate_mobile_number: text.student_alternate_mobile_no,
			gender: text?.student_gender_id == 111 ? "Male" : "Female",
			father_name: text?.father_name,
			mother_name: text?.mother_name,
			$name: text?.student_first_name + " " + text?.student_middle_name + " " + text?.student_last_name,
		});
		const requestOptionsupdate = {
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Basic " + auth_token,
			},
			body: JSON.stringify(text),
		};

		fetch(environment.ecomApiUrl + "address/profile-update", requestOptionsupdate).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				if (result.status == "success") {
					props.onChange();
					toast.success(result.message);
					scroll("profiledetailsdiv");
					var x = document.getElementById("profileUpdateForm");
					x.style.display = "none";
					x.reset();
					setText({
						student_first_name: "",
						student_middle_name: "",
						student_last_name: "",
						student_email: "",
						student_dob: "",
						student_alternate_mobile_no: "",
						student_mobile_no: "",
						student_gender_id: "",
						father_name: "",
						mother_name: "",
					});
					setEmailDisabled(false);
				} else {
					toast.error(result.message);
				}
			});
		});
	};

	function cancelAddressFrom() {
		scroll("profiledetailsdiv");
		setEmailDisabled(false);
		var x = document.getElementById("profileUpdateForm");
		if (x.style.display === "block") {
			x.style.display = "none";
		} else {
			x.style.display = "block";
		}

		setEmailVerifyBtn("Verify");

		setText({
			student_first_name: profileDetails?.student_first_name ? profileDetails?.student_first_name : "",
			student_middle_name: profileDetails?.student_middle_name ? profileDetails?.student_middle_name : "",
			student_last_name: profileDetails?.student_last_name ? profileDetails?.student_last_name : "",
			student_email: profileDetails?.student_email ? profileDetails?.student_email : "",
			student_dob: profileDetails?.student_dob ? profileDetails?.student_dob : "",
			student_alternate_mobile_no: profileDetails?.student_alternate_mobile_no
				? profileDetails?.student_alternate_mobile_no
				: "",
			student_mobile_no: profileDetails?.student_mobile_no ? profileDetails?.student_mobile_no : "",
			student_gender_id: profileDetails?.student_gender_id ? profileDetails?.student_gender_id : "",
			father_name: profileDetails?.father_name ? profileDetails?.father_name : "",
			mother_name: profileDetails?.mother_name ? profileDetails?.mother_name : "",
		});
	}

	var b = 60;
	const setTimeEmail = () => {
		b = +b - 1;
		setTimeout(() => {
			if (b >= 0) {
				setTimeInSecEmail(`Resend OTP in ${b} sec`);
				setTimeEmail();
			} else {
				setEmailOTPReSent(true);
			}
		}, 1000);
	};
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			width: "32%",
			height: "100% !important",
		},
	};
	const clearMobileEmailOTPData = () => {
		setOpen(false);
		// setTimeInSec('')
		setTimeInSecEmail("");
		setEmailOTPSent(false);
		setbuttonEmailName("Send OTP");
		setEmailTimeInSec("");
		setEmailOtp("");
		setEmailOTPReSent(false);
		setAction("");
	};
	const handleEmailOtp = e => {
		const { name, value } = e.target;

		if (name === "student_mobile_no" &&
			/^[0-9]*$/.test(value) &&
			!value.includes('e') &&
			!value.includes('.') &&
			!value.includes('-') &&
			!value.includes('+')) {
			setEmailOtp(value);
		}

	};
	const sendEmailOTP = () => {
		if (
			!validationFunction?.validateUserFields("email", text.student_email)
		) {
			toast.error("Enter Valid Email Address");
			return false;
		}
		var reqURL;
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({}),
		};
		reqURL = process.env.REACT_APP_ECOM_URL + "otp/otp_generate_email";
		requestOptions.body = JSON.stringify({ email: text.student_email, lead_type: 914 });
		fetch(reqURL, requestOptions)
			.then(async response => {
				const data = await response.json();
				// check for error response
				// //console.log(response);
				//console.log(data);
				// if (!response.ok) {
				//   // get error message from body or default to response status
				//   const error = (data && data.message) || response.status;
				//   return Promise.reject(error);
				// }
				if (data.replyCode === "success") {
					b = 60;
					setTimeEmail();
					setEmailOTPSent(true);
					setEmailOTPReSent(false);
					setbuttonEmailName("Verify OTP");
					toast.success(data.replyMsg);
				} else {
					toast.error(data.replyMsg ? data.replyMsg : data.message);
				}
			})
			.catch(error => {
				this?.setState({ errorMessage: error.toString() });
				console.error("There was an error!", error);
			});
	};

	const verifyEmailOtp = e => {

		if (isEmailOtp.length !== 6) {
			toast.error("Please enter valid OTP")
			return
		}

		fetch(process.env.REACT_APP_ECOM_URL + "otp/otp_verify_email", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				// 'Authorization': 'Bearer ' + user.token,
			},
			body: JSON.stringify({ email: text.student_email, otp: isEmailOtp, lead_type: 914 }),
		}).then(async response => {
			const data = await response.json();
			if (data.replyCode === "success") {
				// toast.success(data.replyMsg);
				toast.success("Email OTP Verified");
				setbuttonEmailName("Verified");
				setEmailVerifyBtn("Verified");
				setEmailOTPSent(false);
				setEmailDisabled(true);
				setOpen(false);
			} else {
				toast.error("Email OTP is not valid, Please enter valid OTP")
				//toast.error(data.replyMsg);
			}
		});
	};
	useEffect(() => {
		setEmailVerifyBtn("Verify");
		setText({
			student_first_name: profileDetails?.student_first_name ? profileDetails?.student_first_name : "",
			student_middle_name: profileDetails?.student_middle_name ? profileDetails?.student_middle_name : "",
			student_last_name: profileDetails?.student_last_name ? profileDetails?.student_last_name : "",
			student_email: profileDetails?.student_email ? profileDetails?.student_email : "",
			student_dob: profileDetails?.student_dob ? profileDetails?.student_dob : "",
			student_alternate_mobile_no: profileDetails?.student_alternate_mobile_no
				? profileDetails?.student_alternate_mobile_no
				: "",
			student_mobile_no: profileDetails?.student_mobile_no ? profileDetails?.student_mobile_no : "",
			student_gender_id: profileDetails?.student_gender_id ? profileDetails?.student_gender_id : "",
			father_name: profileDetails?.father_name ? profileDetails?.father_name : "",
			mother_name: profileDetails?.mother_name ? profileDetails?.mother_name : "",
		});
	}, [profileDetails]);
	return (
		<div>
			<form className='address-form' id='profileUpdateForm'>
				<div className='new-add-btn mt-3 mb-3 border-none p-0' style={{ marginTop: "2%" }}>
					<b>Edit Profile</b>
				</div>
				{/* <h1>Hello {text.country}</h1> */}

				<div className='form-group row'>
					<div className='col-md-4 col-sm-10'>
						<label htmlFor='pincode' className=' col-form-label'>
							First Name *{" "}
						</label>
						<div className='col-12 px-1'>
							<input
								type='text'
								className='form-control'
								id='student_first_name'
								name='student_first_name'
								value={text?.student_first_name ? text?.student_first_name : ""}
								placeholder=''
								onChange={handleEvent}
								maxLength={30}
								required></input>
						</div>
					</div>
					<div className='col-md-4 col-sm-10'>
						<label htmlFor='pincode' className=' col-form-label'>
							Middle Name{" "}
						</label>
						<div className='col-12 px-1'>
							<input
								type='text'
								className='form-control'
								id='student_middle_name'
								name='student_middle_name'
								value={text?.student_middle_name ? text?.student_middle_name : ""}
								placeholder=''
								maxLength={30}
								onChange={handleEvent}
								required></input>
						</div>
					</div>
					<div className='col-md-4 col-sm-10'>
						<label htmlFor='pincode' className=' col-form-label'>
							Last Name{" "}
						</label>
						<div className='col-12 px-1'>
							<input
								required
								type='text'
								className='form-control'
								id='student_last_name'
								name='student_last_name'
								value={text?.student_last_name ? text.student_last_name : ""}
								placeholder=''
								maxLength={30}
								onChange={handleEvent}></input>
						</div>
					</div>
				</div>

				<div className='form-group row'>
					<div className='col-md-4 col-sm-10'>
						<label htmlFor='address1' className='col-form-label'>
							Email ID *{" "}
						</label>
						<div className='d-flex'>
							<div className='col-9 px-1'>
								<input
									required
									type='text'
									className='form-control'
									id='student_email'
									name='student_email'
									value={text?.student_email ? text.student_email : ""}
									placeholder=''
									maxLength={80}
									onChange={handleEvent}
									disabled={emailDisabled}></input>
							</div>
							{text?.student_email?.length > 5 && (
								<strong
									className='col-3 p-0 mt-2'
									type='button'
									onClick={EmailPOP_open}
									style={{ color: "green" }}>
									{" "}
									{emailVerifyBtn}
								</strong>
							)}
						</div>
					</div>
					<div className='col-md-4 col-sm-10'>
						<label htmlFor='address2' className='col-form-label'>
							Mobile No.{" "}
						</label>
						<div className='col-12 px-1'>
							<input
								type='text'
								className='form-control'
								id='student_mobile_no'
								name='student_mobile_no'
								value={text?.student_mobile_no ? text.student_mobile_no : ""}
								placeholder=''
								maxLength={14}
								disabled={true}
								onChange={handleEvent}></input>
						</div>
					</div>
					<div className='col-md-4 col-sm-10'>
						<label htmlFor='address2' className='col-form-label'>
							Alternate Mobile No.{" "}
						</label>
						<div className='col-12 px-1'>
							<input
								type='text'
								className='form-control'
								id='student_alternate_mobile_no'
								name='student_alternate_mobile_no'
								value={text?.student_alternate_mobile_no ? text.student_alternate_mobile_no : ""}
								placeholder=''
								maxLength={14}
								onChange={handleEvent}></input>
						</div>
					</div>
				</div>

				<div className='form-group row'>
					<div className='col-md-4 col-sm-10'>
						<label htmlFor='address2' className='col-form-label'>
							DOB *{" "}
						</label>
						<div className='col-12 px-1'>
							<input
								type='date'
								className='form-control'
								id='student_dob'
								name='student_dob'
								value={text?.student_dob ? text.student_dob : ""}
								max={new Date()?.toISOString()?.split("T")[0]}
								placeholder=''
								onChange={handleEvent}></input>
						</div>
					</div>
					<div className='col-md-4 col-sm-10'>
						<label htmlFor='pincode' className='col-form-label'>
							Father Name *{""}
						</label>
						<div className='col-12 px-1'>
							<input
								required
								type='text'
								className='form-control'
								id='father_name'
								name='father_name'
								value={text?.father_name ? text.father_name : ""}
								placeholder=''
								maxLength={30}
								onChange={handleEvent}></input>
						</div>
					</div>
					<div className='col-md-4 col-sm-10'>
						<label htmlFor='pincode' className='col-form-label'>
							Mother Name {""}
						</label>
						<div className='col-12 px-1'>
							<input
								required
								type='text'
								className='form-control'
								id='mother_name'
								name='mother_name'
								value={text?.mother_name ? text.mother_name : ""}
								placeholder=''
								maxLength={30}
								onChange={handleEvent}></input>
						</div>
					</div>
				</div>

				<div className='form-group row'>
					<label htmlFor='address2' className='col-sm-2 col-form-label'>
						Gender *{" "}
					</label>
					<div onChange={handleEvent} className='profilegenderinput'>
						<div className='mr-1' style={{ display: "flex" }}>
							<div className=''>
								<input
									type='radio'
									className='form-control mr-1 mt-1'
									id='student_gender_id'
									name='student_gender_id'
									value='111'
									placeholder=''
									checked={text?.student_gender_id == 111 ? true : false}></input>
							</div>
							Male
						</div>
						<div className='mr-1' style={{ display: "flex" }}>
							<div className=''>
								<input
									type='radio'
									className='form-control mr-1 mt-1'
									id='student_gender_id'
									name='student_gender_id'
									value='112'
									placeholder=''
									checked={text?.student_gender_id == 112 ? true : false}></input>
							</div>
							Female
						</div>
						<div className='mr-1' style={{ display: "flex" }}>
							<div className=''>
								<input
									type='radio'
									className='form-control mr-1 mt-1'
									id='student_gender_id'
									name='student_gender_id'
									value='113'
									placeholder=''
									checked={text?.student_gender_id == 113 ? true : false}></input>
							</div>
							Transgender
						</div>
					</div>
				</div>

				<button type='button' className='btn btn-primary btn-save-add mt-3 mb-2' onClick={submitUpdatedForm}>
					Update
				</button>
				<span
					className='btn btn-danger btn-save-add mt-3 mb-2'
					onClick={cancelAddressFrom}
					style={{
						marginLeft: "1%",
						borderColor: "#bd2130",
						backgroundColor: "#bd2130",
						color: "#fff",
					}}>
					Cancel
				</span>
			</form>
			<Modal
				appElement={document.getElementById("root")}
				isOpen={getOpen}
				// onAfterOpen={afterEditOpenModal}
				// onRequestClose={() => CourierSetup('')}
				style={customStyles}
				contentLabel='Example Modal'
				id='propelldotpverify'>
				<div style={{ textAlign: "end" }}>
					<button className='btn btn-sm btn-danger mb-2' onClick={() => clearMobileEmailOTPData()}>
						X
					</button>
				</div>

				<div>
					<label htmlFor='pincode' className=' col-form-label col-lg-12 p-0'>
						Email Id *{" "}
					</label>
					<div className='col-lg-12 p-0'>
						<input
							type='text'
							className='form-control'
							id='email'
							name='student_email'
							value={text?.student_email ? text?.student_email : ""}
							disabled={true}
							placeholder=''
							// onChange={handleEvent}
							required></input>
					</div>

					{/* </div> */}

					<div className='row justify-content-center align-items-center mt-3 no-gutters'>
						{isEmailOTPSent ? (
							<>
								<div className='col-7 col-md-8 col-lg-6 col-xl-8 mob_num_otp'>
									<input
										required
										type='text'
										className='form-control otp-input'
										id='mobile_number'
										name='student_mobile_no'
										maxLength={6}
										value={isEmailOtp}
										placeholder='OTP'
										onChange={handleEmailOtp}></input>
								</div>
							</>
						) : null}
						<div className='col-5  col-md-4 col-lg-6 col-xl-4 pl-2'>
							<button
								type='button'
								className='btn btn-success  btn-block'
								onClick={buttonEmailName === "Send OTP" ? sendEmailOTP : verifyEmailOtp}>
								{buttonEmailName}
							</button>
						</div>
					</div>
					{isEmailOTPSent ? (
						<>
							<div className='form-group row justify-content-center mt-3'>
								<div>
									{isEmailOTPReSent ? (
										<>
											<span
												className='resendotppropelld'
												type='button'
												// className="btn btn-primary btn-save-add "
												onClick={sendEmailOTP}>
												Re-Send OTP
											</span>
										</>
									) : (
										<span className='resendotppropelld'>{getTimeInSecEmail}</span>
									)}
								</div>
							</div>
						</>
					) : null}
				</div>
			</Modal>
		</div>
	);
};

export default EcomEditProfile;
