import React from "react";
import iit from '../../images/iit-main.jpg';
import { Link } from "react-router-dom";

const CourseCardGrid = (props) => {
  
  let CourseSlug 
  if (window.location.href.includes("ZSJ58TTQIc")) {
    CourseSlug = `/courses-details/${encodeURIComponent(
      props.slug.toLowerCase()
    ).replace("%20", "+")}?ZSJ58TTQIc`
   }else if (window.location.href.includes("HUlcCWxcGH")) {
    CourseSlug = `/courses-details/${encodeURIComponent(
      props.slug.toLowerCase()
    ).replace("%20", "+")}?HUlcCWxcGH`
  }
  else {
    CourseSlug = `/courses-details/${encodeURIComponent(
      props.slug.toLowerCase()
    ).replace("%20", "+")}`
  }
  const dateFormat = (date) => {
    let today = new Date(date);
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
   // let new_date = new Date(props.commencement_date);
   
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const d = new Date(today);
    let mn = month[d.getMonth()];
    let new_date = d.getDate()+" "+month[d.getMonth()]+" "+d.getFullYear()
    return new_date;
  }
    return(
        <>
            <a
              to={CourseSlug}
            >
                <div
                    
                    key={props.productId}
                  >
                    <div
                      className="card boder-card m-0 hover-card gridbox"
                      data-toggle="popover"
                      data-trigger="hover"
                      data-placement="right"
                      data-html="true"
                      to="#login"
                    >
                      <div className="img">
                        <a
                          href={CourseSlug}
                        >
                          <img
                            src={props.image===""?iit:props.image}
                            alt=""
                            className="img-fluid mb-2"
                          />
                        </a>
                      </div>
                      <div className="card-content px-0 py-2">
                        <a
                          href={CourseSlug}
                          className="h6 font-weight-bold cut-text-ord-list" title={props.title} >
                          {props.title}
                        </a>
                            <p className="cut-text-allcourse"  dangerouslySetInnerHTML={{
                    __html: props.description,
                  }} title={props.description}></p>
                  {
                    props.is_freemium === 1? "Free"
                    :
                    (
                      <>
                        <div className="d-flex padding-bottom-one" style={{justifyContent:"space-between"}}>
                            <div>
                              <h5>
                              ₹ { props?.courseData?.propelld_status === true && window.location.href.includes("ZSJ58TTQIc") ? props?.courseData?.loan_charges+props?.price : props?.price}
                              </h5>
                            </div>
                              {
                                props.price != props.display_price?
                                (
                                  <>  
                                  <div className="ml-2">
                                    <h5 className="text-danger">
                                      <s>
                                        {"₹ " + props.display_price} 
                                      </s>
                                    </h5>
                                  </div>
                                  </>
                                ):null
                              }
                              <h5 >{dateFormat(props.commencement_date)}</h5>
                          
                          </div>
                      </>
                    )
                  }
                       
                       {/*  {item?.price?.value > item?.salesPrice?.value && (
                          <p
                            className="my-0 font-weight-bold"
                            style={{ color: "#046635", fontSize: "16px" }}
                          >
                            Save
                            {item?.price?.currency === "INR"
                              ? "₹"
                              : item?.price?.currency}
                            {item?.price?.value - item?.salesPrice?.value} (
                            {this.getPercantage(
                              item?.salesPrice?.value,
                              item?.price?.value
                            ).toFixed(2)}
                            % OFF)
                          </p>
                        )} */}
                        {/* <div className="row mx-0 mt-2 justify-content-between">
                          <p className="mr-1 font-size18">Live Class</p>
                          <p className="mr-1 font-size18">Test Series</p>
                          <p className="mr-1 font-size18">Online Test</p>
                        </div> */}
                        <div className="d-flex flex-wrap justify-content-between">
                          {/* <Link className="nav-link p-0 mb-2" to="cart">
                            <button
                              type="button"
                              className="btn btn-outline-primary  nav-btn-outline"
                              style={{ fontSize: "13px" }}
                              onClick={() =>addToCart(item.id)}
                            >
                              Buy Now
                            </button>
                          </Link> */}
                          {/* <span className="nav-link p-0 mb-2">
                            <button
                              type="button"
                              className="btn btn-primary nav-btn"
                              style={{ fontSize: "13px" }}
                              onClick={() =>addToCart(item.id)}
                            >
                              Add to Bag
                            </button>
                          </span> */}
                        </div>
                      </div>

                     {/*  <div
                        id="login"
                        className=" hovercard col-lg-4 p-3 m-0 col-12 mb-2 col-md-6 hover-card-content"
                      >
                        <Link
                          to="/courses-details"
                          className="h6 font-weight-bold"
                        >
                          JEE Main 2021 (February Attempt) Paper Analysis
                          (Physics, Chemistry,
                        </Link>
                        <div className="row m-0 p-0">
                          <p className="mr-1 col-4 p-0">No. of Tests</p>
                          <p className="col-7 p-0 ml-auto">
                            19 Total Tests * (1st Year: 10 Unit + 4 Review + 5
                            Major, 2nd Year: Schedule in April 2022)
                          </p>
                        </div>
                        <div className="row m-0">
                          <p className="mr-1 col-4 p-0">Target Year</p>
                          <p className="col-7 p-0 ml-auto text-left">2023</p>
                        </div>
                        <div className="row m-0">
                          <p className="mr-1 col-4 p-0">Eligibility</p>
                          <p className="col-7 p-0 ml-auto text-left">
                            XI ( Studying)
                          </p>
                        </div>
                        <div className="row m-0">
                          <p className="mr-1 col-4 p-0">Language</p>
                          <p className="col-7 p-0 ml-auto text-left">
                            English / Hindi
                          </p>
                        </div>
                        <div className="row m-0">
                          <p className="mr-1 col-4 p-0">Stream</p>
                          <p className="col-7 p-0 ml-auto text-left">Science</p>
                        </div>
                      </div> */}
                    </div>
                </div>
              </a>
        </>
    )
}

export default CourseCardGrid;