import React, { useState } from "react";
import { toast } from "react-toastify";
import { TailSpin } from 'react-loader-spinner';

const EcomCheckoutPayment = (props) => {

  const [loading, setLoading] = useState(true);
  const cartItem = props.initialCartItems;
  const cartDetails = props.cartDetails;
  const finalAmount = cartItem.net_amount - cartDetails.cart_discount_amount;
  const productDiscountPer = (cartItem.discount / cartItem.mrp) * 100;
  const cartDiscountPer = (cartDetails.cart_discount_amount / cartItem.mrp) * 100;


  /* Installment Calculation */
  const installmentValue = props.installmentCal();
  let cartGSt = cartDetails.gst_rate ? cartDetails.gst_rate : "18";
  let gst = 0;
  cartDetails.gst_rate = 0;
  let proceedToCheckout;
  if (cartDetails.gst_rate === 0) {

    gst = cartDetails.gst_rate;
    proceedToCheckout = installmentValue + gst;
  } else {
    gst = 0;
    proceedToCheckout = installmentValue + gst;
    /* gst = (cartGSt/100)*installmentValue;
    proceedToCheckout = installmentValue + gst; */
  }

  /* const [instTotal,setInstTotal] = useState(cartDetails.cart_amount);
  const [gstAmount,setGstAmount] = useState(gst);
  const [proceedAmount,setProceedAount] = useState(proceedToCheckout); */

  ////console.log("newValue "+installmentValue);

  function myFunctionn() {
    var x = document.getElementById("myDIVV");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  function myFunction() {
    var x = document.getElementById("myDIVDiscount");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  const handleEvent = (e) => {
    // props.onChange(e.target.value);
    // //console.log(e.target.value);
  }

  return (

    <>
      {
        !props.showInstallment ?
          (
            <>
              <div className="row"></div>
              <div className="address-text-box mb-2"><b>Payment Info</b></div>

              <div className="row">

                <div className="col-md-8">
                  <span className="in-payment-course-text">{cartItem.product_title}</span>
                </div>
                <div className="col-md-4">
                  <span className="in-payment-price">₹ {cartDetails.cart_amount}</span>
                </div>

              </div>
              {
                cartItem.discount !== 0 ?
                  (
                    <>
                      <div className="row">
                        <div className="col-md-8" onClick={myFunction}>
                          <span className="in-payment-discount-text">Discount Applied &nbsp;
                            <i className="fa-solid fa-circle-info color-green"></i></span>
                        </div>

                        <div className="col-md-4">
                          <span className="in-payment-discount-price">-₹ {cartItem.discount}</span>
                        </div>
                      </div>
                    </>
                  ) : null
              }



              <div className="couopn-detail-popup" id="myDIVDiscount">
                <span className="arrow-up-couopn-popup"></span>
                <div className="row">
                  <div className="col-auto">
                    <span className="coupon-design">{cartItem.coupon_title}</span>
                  </div>
                  <div className="col-auto">
                    <span className="discount-percent-design">{parseInt(productDiscountPer)}% Discount</span>
                  </div>
                </div>
                <div className="border-bottom"></div>

              </div>

              {/* <div className="row mt-2">
            <div className="col-md-8">
              <span className="in-payment-course-text">{cartItem.product_title}</span>
            </div>
            <div className="col-md-4">
              <span className="in-payment-price">₹ {cartItem.net_amount}</span>
            </div>
          </div> */}


              <div className="border-bottom"></div>
              <div className="row mt-2">

                <div className="col-md-8">
                  <span className="in-payment-course-text">Total</span>
                </div>
                <div className="col-md-4">
                  <span className="in-payment-price">₹ {cartDetails.cart_amount}</span>
                </div>
              </div>

              {
                cartDetails.cart_discount_amount !== 0 ?
                  (
                    <>
                      <div className="row">
                        <div className="col-md-8" onClick={myFunctionn}>
                          <span className="in-payment-discount-text">Cart Applied <i className="fa-solid fa-circle-info color-green"></i></span>
                        </div>
                        <div className="col-md-4">
                          <span className="in-payment-discount-price">-₹ {cartDetails.cart_discount_amount}</span>
                        </div>
                      </div>
                    </>
                  ) : null
              }




              <div className="couopn-detail-popup" id="myDIVV">
                <span className="arrow-up-couopn-popup"></span>
                <div className="row">
                  <div className="col-auto">
                    <span className="coupon-design">{cartDetails.coupon_title}</span>
                  </div>
                  <div className="col-auto">
                    <span className="discount-percent-design">{parseInt(cartDiscountPer)}% Discount</span>
                  </div>
                </div>
                <div className="border-bottom"></div>

              </div>


              <div className="border-bottom"></div>

              <div className="row mt-2">
                <div className="col-md-8">
                  <span className="in-payment-course-text">Payable Amount</span>
                </div>
                <div className="col-md-4">
                  <span className="in-payment-price">₹ {cartDetails.cart_pay_amount}</span>
                </div>
              </div>

              <div className="border-bottom"></div>
              {/* GST Amount */}
              {
                cartDetails.gst_rate == 0 ? null :
                  (
                    <>
                      <div className="row mt-2">
                        <div className="col-md-8">
                          <span className="in-payment-course-text">Gst ({cartDetails.gst_rate + "%"})</span>
                        </div>
                        <div className="col-md-4">
                          <span className="in-payment-price">₹ {cartDetails.gst_amount}</span>
                        </div>
                      </div>
                    </>
                  )
              }


              <div className="border-bottom"></div>

              <div className="row mt-4">
                <div className="col-md-12">
                  <h5 className="in-payment-proceedpay-text"><b>Proceed To Pay ₹ {cartDetails.final_pay_amount}</b></h5>
                </div>
              </div>
            </>
          ) :

          /* Installment Code */
          (
            <>
              <div className="row"></div>
              <div className="address-text-box mb-2"><b>Payment Info</b></div>

              <div className="border-bottom"></div>
              <div className="row mt-2">

                <div className="col-md-8">
                  <span className="in-payment-course-text">Total</span>
                </div>
                <div className="col-md-4">
                  <span className="in-payment-price">₹ {props.installmentCal()}</span>
                </div>
              </div>



              {/* GST Amount */}
              {
                cartDetails.gst_rate == 0 ? null :
                  (
                    <>
                      <div className="border-bottom"></div>
                      <div className="row mt-2">
                        <div className="col-md-8">
                          <span className="in-payment-course-text">Gst ({cartDetails.gst_rate ? cartDetails.gst_rate + "%" : "18%"})</span>
                        </div>
                        <div className="col-md-4">
                          <span className="in-payment-price">₹ {gst}</span>
                        </div>
                      </div>
                    </>
                  )
              }


              <div className="border-bottom"></div>

              <div className="row mt-4">
                <div className="col-md-12">
                  <h5 className="in-payment-proceedpay-text"><b>Proceed To Pay ₹ {proceedToCheckout}</b></h5>
                </div>
              </div>
            </>
          )

      }

    </>
  )
}

export default EcomCheckoutPayment;