import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AboutUsBanner from "../components/AboutUs/AoutusBanner";
import TabContainer from "../components/TabContainer/TabContainer";

const ImportantPoints = () => {
  const tabData = [
    { title: "Session 2023-24", active: true, dropdown: false },
    //{ title: "Session 2022-23", active: false, dropdown: false },

  ];
  const [tabContainerData, SetTabContainerData] = useState(tabData);
  const tabChange = (e) => {
    let tabArray = tabData.map((val, ind) => {
      if (e.target.textContent === val.title) {
        return { ...val, active: true };
      } else {
        return { ...val, active: false };
      }
    });
    SetTabContainerData(tabArray);
  };
  const data1 = `<div class="col-12 col-lg-12" style="padding:0px"><style>
  * {
    font-family: sans-serif;
    padding: 0;
  }
  h4{
      text-align: center;
  }
  h3{
      color: red;
  }
  table,
  th,
  td,
  tr {
    border: 1px solid black !important;
    border-collapse: collapse;
    padding: 10px;
    text-align: center;
  }
</style>

  <div class="container">
      <h4 style="font-size: 22px;">
          COURSE /PHASE / STREAM Change Policy<br>
          SESSION 2023-24 
        </h4>
        <h3>• For LIVE Course</h3>
        <p style="text-align:left !important">
          After Fee Deposition Course/Phase/Stream Change is permissible (subject to availability of seat and
performance of the student) on payment of prescribed fee as per list given below along with the
difference of Course Fee:
        </p>
        <br>
        <table style="width: 100% ">
          <tbody><tr>
            <td colspan="3">NEET (UG)/ JEE (MAIN) / JEE (MAIN+ADV.) / PNCF</td>
          </tr>
          <tr>
            <td>Particulars</td>
            <td>Days*</td>
            <td>Fee</td>
          </tr>
          <tr>
            <td>Phase Change</td>
            <td>0-15</td>
            <td>1500/-</td>
          </tr>
          <tr>
            <td>Course Change</td>
            <td>0-15</td>
            <td>1500/-</td>
          </tr>
          <tr>
            <td>Stream Change</td>
            <td>0-15</td>
            <td>1500/-</td>
          </tr>
        </tbody></table>
        <br>
        <p style="text-align:left !important">*Days will be counted from class commencement date. Phase/Course/Stream change is not
          permissible after 15 days from the course commencement date. </p>
          <br>
        <h3>• For LIVE+RECORDED Courses </h3>
        <p style="text-align:left !important">After Fee Deposition Course/Phase/Stream Change is permissible (subject to availability of seat and
          performance of the student) on payment of prescribed fee as per list given below along with the
          difference of Course Fee: </p>
          <br>
        <table style="width: 100% ">
          <tbody><tr>
            <td colspan="3">NEET (UG)/ JEE (MAIN) / JEE (MAIN+ADV.) / PNCF 
            </td>
          </tr>
          <tr>
            <td>Particulars</td>
            <td>Days*</td>
            <td>Fee</td>
          </tr>
          <tr>
            <td>Phase Change</td>
            <td>0-15</td>
            <td>1500/-</td>
          </tr>
          <tr>
            <td>Course Change</td>
            <td>0-15</td>
            <td>1500/-</td>
          </tr>
          <tr>
            <td>Stream Change</td>
            <td>0-15</td>
            <td>1500/-</td>
          </tr>
        </tbody></table>
        <br>
        <p style="text-align:left !important">
          *Days will be counted from class commencement date. Phase/Course/Stream change is not
permissible after 15 days from the course commencement date.
        </p>
        <br>
        <h3>• For RECORDED Course</h3>
        <p style="text-align:left !important">
        Course Change Permissible only within <b>3 days</b> of Course Commencement date or Course purchase date whichever is later.
        </p>
        <p style="text-align:left !important">
        If a student wants to change his recorded course from JEE(Main) to JEE (Main + Advanced), JEE(Main) to NEET(UG), NEET(UG) to JEE(Main), NEET(UG) to JEE (Main + Advanced), JEE(Main + Advanced) to NEET(UG), JEE(Main + Advanced) to JEE(Main), PNCF (NCERT) to PNCF(Olympiad), PNCF(Olympiad) to PNCF (NCERT) then, student must pay Fee of INR 1999 along with the difference of Course Fee
        </p>
        <p>* Course/Stream change is Not permissible after <b>3 days</b>  of the course commencement date or course purchase date whichever is later.

        </p>
  </div></div>`;
  const data2 = `<div class="col-12 col-lg-12" style="padding:0px"><style>
  * {
    font-family: sans-serif;
    padding: 0;
  }
  h4{
      text-align: center;
  }
  h3{
      color: red;
  }
  table,
  th,
  td,
  tr {
    border: 1px solid black !important;
    border-collapse: collapse;
    padding: 10px;
    text-align: center;
  }
</style>

  <div class="container">
      <h4 style="font-size: 22px;">
          COURSE /PHASE / STREAM Change Policy<br>
          SESSION 2022-23
        </h4>
        <h3>• For LIVE Course</h3>
        <p style="text-align:left !important">
          After Fee Deposition Course/Phase/Stream Change is permissible (subject to
          availability of seat and performance of the student) on payment of
          prescribed fee as per list given below along with the difference of Course
          Fee:
        </p>
        <table style="width: 100% ">
          <tbody><tr>
            <td colspan="3">NEET (UG)/ JEE (MAIN) / JEE (M+A) / PNCF</td>
          </tr>
          <tr>
            <td>Particulars</td>
            <td>Days*</td>
            <td>Fee</td>
          </tr>
          <tr>
            <td>Phase Change</td>
            <td>0-15</td>
            <td>1500/-</td>
          </tr>
          <tr>
            <td>Course Change</td>
            <td>0-15</td>
            <td>1500/-</td>
          </tr>
          <tr>
            <td>Stream Change</td>
            <td>0-15</td>
            <td>1500/-</td>
          </tr>
        </tbody></table>
        <p style="text-align:left !important">* Phase/Course/Stream change is Not permissible after 15 days.</p><br />
        <h3>• For RECORDED Course</h3>
        <p style="text-align:left !important">
          Course Change Permissible only within 7 days of Course Commencement date
          or Course purchase date whichever is later.
        </p>
        <p style="text-align:left !important">
          If a student wants to change his recorded course from JEE(Main) to JEE
          (Main + Advanced), JEE(Main) to NEET(UG), NEET(UG) to JEE(Main), NEET(UG)
          to JEE (Main + Advanced), JEE(Main + Advanced) to NEET(UG), JEE(Main +
          Advanced) to JEE(Main) then, student must pay Fee of INR 1999 along with
          the difference of Course Fee
        </p>
        <span>* Course/Stream change is Not permissible after 7 days.</span>
  </div></div>`;


  return (
    <>
      <Helmet>
        {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
        {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
        <title>Transfer Policies | ALLEN Digital</title>
        <meta name='description' content="Our transfer policies are designed to make it easy and hassle-free for you to make changes to your phase, course and stream. Learn more about our transfer options here." />
        <meta property="og:title" content="Transfer Policies | ALLEN Digital" />
        <meta property="og:site_name" content="ALLEN Digital" />
        <meta property="og:url" content="https://digital.allen.ac.in/transfer-policies" />
        <meta property="og:description" content="Our transfer policies are designed to make it easy and hassle-free for you to make changes to your phase, course and stream. Learn more about our transfer options here." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://digital.allen.ac.in/static/media/logo-new-r.4a9d1fde3fd2a6868a621b27352bda3a.svg" />
      </Helmet>
      <AboutUsBanner handelEvent={() => { }} slug="Transfer Policies" />
      {
        /* wid? */
        <>
          <TabContainer contant={tabContainerData} tabChange={tabChange} />
          <div className="tab-content p-0" id="pills-tabContent">
            <div className=" app-features">
              <div className="container py-5">
                <div className="">
                  <h2 className="font-weight-bold text-center mb-3">
                    Transfer Policies
                  </h2>
                  <div className="card py-5 course-card">
                    <div className="row">
                      {tabContainerData[0].active ? (
                        <div
                          className="col-12 col-lg-12"
                          dangerouslySetInnerHTML={{
                            __html: data1,
                          }}
                        ></div>
                      ) : (
                        <div
                          className="col-12 col-lg-12"
                          dangerouslySetInnerHTML={{
                            __html: data2,
                          }}
                        ></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </> /* :
          (
              <>
                  <div className="tab-content" id="pills-tabContent">
                      <div className=" app-features">
                          <div className="container py-5">
                          <div className="">
                              <h2 className="font-weight-bold text-center mb-3">{PageContent.title}</h2>
                              <div className="card py-5 course-card">
                              <div className="row">
                              

                              <div className="col-12 col-lg-12 text-center" >{wid}
                                  Please Login first
                              </div>
                              </div>
                              </div>
                          </div>
                          </div>
                      </div>
                  </div>
              </>
          ) */
      }
    </>
  );
};

export default ImportantPoints;
