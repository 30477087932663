import React from 'react';
import ConatctUsBanner from '../components/ContactUs/ContactUsBanner';
import ContactUsForm from '../components/ContactUs/ContactUsForm';
import ContactUsFormNewStaging from '../components/ContactUs/ContactUsFormNewStaging';
// import { environment } from '../Services/HttpData';
import { Helmet } from 'react-helmet';
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PageContent: []
    };
  }
  async componentDidMount() {
    // POST request using fetch with async/await
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "keyword": ''})
    // };
    // fetch(environment.baseUrl+'view_list_all', requestOptions)
    // .then(async response => {
    //     // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
    //     const data = /* isJson && */ await response.json();
    //     // check for error response
    //     // //console.log(response);
    //     if (!response.ok) {
    //         // get error message from body or default to response status
    //         const error = (data && data.message) || response.status;
    //         return Promise.reject(error);
    //     }
    //     let index=data.data.findIndex((item)=>item.slug==="contact-us");
    //     //console.log(data.data[index]);
    //     let sections = {};
    //     data.data[index]?.sections.map((item) => {
    //       switch (item.id) {
    //         case 24:
    //           sections.banner = item;
    //           break;
    //         case 25:
    //           sections.contactDetails = item;
    //           break;
    //       }
    //     });
    //     this.setState({ PageContent: sections });
    //     //console.log(this.state.PageContent);
    // })
    // .catch(error => {
    //     this.setState({ errorMessage: error.toString() });
    //     console.error('There was an error!', error);
    // });
    /* const response = await fetch(environment.baseUrl+'user_blog_category_list', requestOptions);
    const data = await response.json();
    if(data.replyCode==="success"){
        //console.log(data.data);
        this.setState(data.data);
    } */
    const getViewListAll = () => {
      if (!window?.AllenDigitalFEBSite) {
        setTimeout(() => {
          getViewListAll();
        }, 50);
        return false
      }
      let index = window?.AllenDigitalFEBSite.data.findIndex((item) => item.slug === "contact-us");
      //console.log(data.data[index]);
      let sections = {};
      window?.AllenDigitalFEBSite.data[index]?.sections.map((item) => {
        switch (item.id) {
          case 24:
            sections.banner = item;
            break;
          case 25:
            sections.contactDetails = item;
            break;
        }
      });
      this.setState({ PageContent: sections });
    };
    getViewListAll();
  }
  render() {
    return <>
      <Helmet>
        {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
        {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
        <title>Contact Us | ALLEN Digital</title>
        <meta
          name="description"
          content="Contact ALLEN Digital for expert online coaching services. Our experienced instructors are dedicated to helping you achieve your goals. Get in touch today!" />
        <meta property="og:title" content="Contact Us | ALLEN Digital" />
        <meta property="og:site_name" content="ALLEN Digital" />
        <meta property="og:url" content="https://digital.allen.ac.in/contact-us" />
        <meta property="og:description" content="Contact ALLEN Digital for expert online coaching services. Our experienced instructors are dedicated to helping you achieve your goals. Get in touch today!" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://digital.allen.ac.in/static/media/logo-new-r.4a9d1fde3fd2a6868a621b27352bda3a.svg" />
      </Helmet>
      <ConatctUsBanner handelEvent={this.state.PageContent?.banner} />
      <ContactUsForm handelEvent={this.state.PageContent?.contactDetails} />
      {/* <ContactUsFormNewStaging handelEvent={this.state.PageContent?.contactDetails}/> */}
    </>;
  }
}

export default ContactUs;