import React from "react";
import AboutImg1 from "../../images/about-img-1.jpg";
import AboutImg2 from "../../images/mail-open-outline.png";
import AboutImg3 from "../../images/chat-bot.png";
import AboutImg4 from "../../images/inventory-management.png";
import AboutImg5 from "../../images/about-img-2.jpg";
import AboutImg6 from "../../images/building.png";
import AboutImg7 from "../../images/Rectangle 1738.png";
import AboutImg8 from "../../images/directors.png";
import AboutImg9 from "../../images/Rectangle534.png";
import AboutImg10 from "../../images/hexagon.png";
// import { environment } from "../../Services/HttpData";
import csrAboutUs from "../../images/CSR.png";
import Ethics from "../../images/Ethics.png";
import Child from "../../images/Child.png";
import Intro from "../../images/intro.jpg";

const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class AboutTabContents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PageContent: [],
      slug: null,
    };
  }
  componentWillReceiveProps(props) {
    this.setState({ PageContent: props.handelEvent, slug: props.slug });
    // //console.log('about tab', props.handelEvent)
    // //console.log(props);
  }
  isActiveTab(slugUrl) {
    if (slugUrl === this.props.slug)
      return "list-group-item list-group-item-action about-list-group-item active";
    else return "list-group-item list-group-item-action about-list-group-item";
  }
  isActiveTabContent(slugUrl) {
    if (slugUrl === this.props.slug) return "tab-pane fade show active";
    else return "tab-pane fade";
  }
  render() {
    return (
      <>
        <div className="container mt-5">
          <div className="row">
            <nav className="col-12 col-md-3 ">
              <div className="list-group" id="list-tab" role="tablist">
                <a
                  className={this.isActiveTab(this.state.PageContent[0]?.slug)}
                  id="list-intro-list"
                  href={"/about-us/" + this.state.PageContent[0]?.slug}
                  role="tab"
                  aria-controls="intro"
                >
                  {this.state.PageContent[0]?.title}
                </a>
                <a
                  className={this.isActiveTab(this.state.PageContent[1]?.slug)}
                  id="list-proud-list"
                  href={"/about-us/" + this.state.PageContent[1]?.slug}
                  role="tab"
                  aria-controls="proud"
                >
                  {this.state.PageContent[1]?.title}
                </a>
                <a
                  className={this.isActiveTab(this.state.PageContent[2]?.slug)}
                  id="list-director-list"
                  href={"/about-us/" + this.state.PageContent[2]?.slug}
                  role="tab"
                  aria-controls="director"
                >
                  {this.state.PageContent[2]?.title}
                </a>
                {/* Academic Strength */}
                <a
                  className={this.isActiveTab(this.state.PageContent[3]?.slug)}
                  id="list-academic-list"
                  href={"/about-us/" + this.state.PageContent[3]?.slug}
                  role="tab"
                  aria-controls="academic"
                >
                  {this.state.PageContent[3]?.title}
                </a>
                {/* Teaching Methodology */}
                <a
                  className={this.isActiveTab(this.state.PageContent[4]?.slug)}
                  id="list-teaching-methodology-list"
                  href={"/about-us/" + this.state.PageContent[4]?.slug}
                  role="tab"
                  aria-controls="teaching-methodology"
                >
                  {this.state.PageContent[4]?.title}
                </a>
                <a
                  className={this.isActiveTab("CSR")}
                  id="list-csr-list"
                  href={"/about-us/CSR"}
                  role="tab"
                  aria-controls="csr"
                >
                  CSR
                </a>
                <a
                  className={this.isActiveTab("child-safety")}
                  id="list-child-safety-list"
                  href={"/about-us/child-safety"}
                  role="tab"
                  aria-controls="child-safety"
                >
                  Child Safety
                </a>

                {/* <!--<a className="list-group-item list-group-item-action about-list-group-item" id="list-csr-list" data-toggle="list"
                                href="#list-csr" role="tab" aria-controls="csr">CSR</a>--> */}
              </div>
            </nav>
            {/* About us Introduction */}
            <div
              className="col-12 col-sm-9 container tab-content"
              id="nav-tabContent"
            >
              <div
                className={this.isActiveTabContent(
                  this.state.PageContent[0]?.slug
                )}
                id="list-intro"
                role="tabpanel"
                aria-labelledby="intro-tab"
              >
                <div className="d-flex flex-row flex-wrap">
                  <div className="col-12 col-md-12 mb-5">
                    <img src={Intro} alt="" className="img-fluid" />
                  </div>
                  <div className="col-12 col-md-12">
                    <h3>{this.state.PageContent[0]?.content[0]?.title}</h3>
                    <p>{this.state.PageContent[0]?.content[0]?.subtitle}</p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.PageContent[0]?.content[0]
                          ?.description,
                      }}
                    ></p>
                  </div>
                  <div
                    className="col-12 mt-4"
                    dangerouslySetInnerHTML={{
                      __html: this.state.PageContent[0]?.content[1]
                        ?.description,
                    }}
                  ></div>
                </div>
                {/* About us vision,mission.value */}
                <div className="row">
                  {/* About us vision */}
                  <div
                    className=" col-12 col-md-6 align-items-center text-center"
                    style={{
                      padding: "15px",
                    }}
                  >
                    <div className="vision">
                      <img
                        src={
                          this.state.PageContent[0]?.content[2]?.image
                            ? environment.baseUrlImg +
                              this.state.PageContent[0]?.content[2]?.image
                            : AboutImg2
                        }
                        alt=""
                        className="img-fluid mb-3"
                        style={{ maxWidth: "" }}
                      />
                      <h5 className="mb-2">
                        {this.state.PageContent[0]?.content[2]?.title}
                      </h5>
                      <p>
                        {this.state.PageContent[0]?.content[2]?.description}
                      </p>
                    </div>
                  </div>
                  {/* About us mission */}
                  <div
                    className=" col-12 col-md-6 align-items-center text-center"
                    style={{
                      padding: "15px",
                    }}
                  >
                    <div className="mission">
                      <img
                        src={
                          this.state.PageContent[0]?.content[3]?.image
                            ? environment.baseUrlImg +
                              this.state.PageContent[0]?.content[3]?.image
                            : AboutImg3
                        }
                        alt=""
                        className="img-fluid mb-3"
                        style={{ maxWidth: "" }}
                      />
                      <h5 className="mb-2">
                        {this.state.PageContent[0]?.content[3]?.title}
                      </h5>
                      <p>
                        {this.state.PageContent[0]?.content[3]?.description}{" "}
                      </p>
                    </div>
                  </div>
                  {/* Value static */}
                  <div
                    className="col-12 col-md-12"
                    style={{
                      padding: "15px",
                      margin: "5px",
                    }}
                  >
                    <div className="col-12 col-md-12">
                      <h4 className="mb-2">
                        {this.state.PageContent[0]?.content[4]?.title}
                      </h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.PageContent[0]?.content[4]
                            ?.description,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Foundation */}
              <div
                className={this.isActiveTabContent(
                  this.state.PageContent[1]?.slug
                )}
                id="list-proud"
                role="tabpanel"
                aria-labelledby="proud-tab"
              >
                <div className="d-flex flex-row flex-wrap container ">
                
                  <div className="col-12 col-lg-12 p-0">
                    <img
                      src={
                        this.state.PageContent[1]?.content[0]?.image
                          ? environment.baseUrlImg +
                            this.state.PageContent[1]?.content[0]?.image
                          : AboutImg6
                      }
                      alt=""
                      className="img-fluid mb-4"
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-12">
                    <h3>{this.state.PageContent[1]?.content[0]?.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.PageContent[1]?.content[0]
                          ?.description,
                      }}
                    ></p>
                  </div>
                <div
                  className="mt-4"
                  dangerouslySetInnerHTML={{
                    __html: this.state.PageContent[1]?.content[1]?.description,
                  }}
                ></div>
                <div className="d-flex flex-row flex-wrap mt-5">
                  <div className="col-12 col-lg-12 mb-4">
                    <img
                      src={
                        this.state.PageContent[1]?.content[2]?.image
                          ? environment.baseUrlImg +
                            this.state.PageContent[1]?.content[2]?.image
                          : Ethics
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-12 col-lg-12">
                    <h4>{this.state.PageContent[1]?.content[2]?.title}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.PageContent[1]?.content[2]
                          ?.description,
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div
                className={this.isActiveTabContent(
                  this.state.PageContent[2]?.slug
                )}
                id="list-director"
                role="tabpanel"
                aria-labelledby="director-tab"
              >
                <div className="d-flex flex-row flex-wrap foundation-2 mb-4">
                  
                  <div className="col-12 col-lg-12 p-0 ">
                    <img
                      src={
                        this.state.PageContent[2]?.content[0]?.image
                          ? environment.baseUrlImg +
                            this.state.PageContent[2]?.content[0]?.image
                          : AboutImg8
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-12">
                    <h3>{this.state.PageContent[2]?.content[0]?.title}</h3>
                    <p>{this.state.PageContent[2]?.content[0]?.description}</p>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.PageContent[2]?.content[1]?.description,
                  }}
                ></div>
              </div>
              {/* Academic Strength */}
              <div
                className={this.isActiveTabContent(
                  this.state.PageContent[3]?.slug
                )}
                id="list-academic"
                role="tabpanel"
                aria-labelledby="academic-tab"
              >
                <div className="d-flex flex-row flex-wrap">
                  <div className="col-12 col-md-12 mb-5">
                    <img
                      src={
                        this.state.PageContent[3]?.admin_image
                          ? environment.baseUrlImg +
                            this.state.PageContent[3]?.admin_image
                          : AboutImg9
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div
                    className="col-12 col-md-12"
                    dangerouslySetInnerHTML={{
                      __html: this.state.PageContent[3]?.content[1]
                        ?.description,
                    }}
                  ></div>
                </div>
                <div
                  className="col-12 col-md-12"
                  dangerouslySetInnerHTML={{
                    __html: this.state.PageContent[3]?.content[0]?.description,
                  }}
                ></div>
              </div>

              {/* CSR */}
              <div
                className={this.isActiveTabContent("CSR")}
                id="list-academic"
                role="tabpanel"
                aria-labelledby="academic-tab"
              >
                <div className="">
                  <img src={csrAboutUs} alt="" className="img-fluid mb-3" />{" "}
                  <p>
                    Motivated by the passion for making education accessible to
                    all sections of society, ALLEN Digital is providing
                    customized courses to students from economically weaker
                    sections of society. This initiative to remove barriers to
                    learning and promote social change and equal opportunity is
                    a joint effort by ALLEN Digital and Infosys.
                  </p>
                  <p>
                    This initiative will benefit one lakh economically weak
                    students. They will learn from India's best faculty pool for
                    JEE and NEET examinations at ALLEN.
                  </p>
                </div>
              </div>
              {/* Child Safety */}
              <div
                className={this.isActiveTabContent("child-safety")}
                id="list-academic"
                role="tabpanel"
                aria-labelledby="academic-tab"
              >
                <img src={Child} alt="" className="img-fluid mb-5" />{" "}
                <div className="">
                  <h3>We Value Your Well-Being</h3>
                  <br />
                  <p>
                    At ALLEN Digital, we prioritize the safety and security of
                    our learners and educators. We provide a positive learning
                    environment to our students that promote good mental health
                    and emotional well-being. Our motto is to create a space
                    where learners blossom academically, personally, and
                    culturally.
                  </p>
                  <br />
                  <h6>
                    <strong>Child Safety Measures</strong>
                  </h6>
                  <p>
                    <strong>Policy:</strong> We have strict guidelines outlining
                    appropriate and inappropriate behavior of students,
                    teachers, and parents.
                    <br />
                    <strong>Surveillance:</strong> We monitor students' and
                    teachers' behavior in the live classes and activities on the
                    App to ensure propriety.
                    <br />
                    <strong>Training:</strong> We have a standard procedure for
                    all eventualities, and our employees have been trained to
                    handle things accordingly.
                    <br />
                    <strong>Resolution:</strong> Our team makes sure that all
                    reports are acutely addressed and resolved relatively within
                    a short period.
                  </p>
                  <br />
                  <h6>
                    <strong>What Comes Under Impermissible Acts?</strong>
                  </h6>
                  <p>
                    Sharing sexually explicit content, leaking someone's
                    personal information, using swear words, hate speech,
                    bullying, spamming, harassment, etc.
                  </p>
                  <br />

                  <h6>
                    <strong>Together, We Create a Positive Space</strong>
                  </h6>
                  <p>
                    A positive environment is essential for students to
                    concentrate on their studies and to feel mentally and
                    emotionally healthy, and we are committed to it. The
                    combined efforts of our IT team, teachers, and
                    administrative staff have made it possible for ALLEN Digital
                    to keep inappropriateness and negativity out of the system.
                  </p>
                  <br />

                  <p>
                    <strong>Filters:</strong> ALLEN Digital app technology has
                    an inbuilt profanity filter that censors inappropriate
                    texts, pictures, and recordings.
                    <br />
                    <strong>Vigilant Teachers:</strong> The teachers monitor
                    students' activities during class, and inappropriate
                    behavior is reported.
                    <br />
                    <strong>Child Safety Team:</strong> ALLEN Digital Child
                    safety team is solely dedicated to ensuring that behavior
                    guidelines and standard procedures for handling
                    events/activities are followed.
                    <br />
                    <strong>Counselors:</strong> Counsellors provide a safe
                    space for students to communicate their issues and report
                    inappropriate behavior.
                    <br />
                    <strong>Core Team:</strong> The policymakers at ALLEN
                    Digital prioritize student care and ensure that all policies
                    are followed and regularly updated.
                  </p>
                  <br />

                  <h6>
                    <strong>A Healthy Learning Environment</strong>
                  </h6>
                  <p>
                    At ALLEN Digital, we are proud of the nurturing, secure, and
                    positive environment that we’ve created for our learners.
                    Some guidelines that have enabled us to do so are:
                  </p>
                  <ul>
                    <li>Be kind and friendly towards your fellow students.</li>
                    <li>
                      Respect your teachers, both inside and outside the
                      classroom.
                    </li>
                    <li>
                      Keep your parents updated about your learning experience
                      at ALLEN Digital.
                    </li>
                    <li>
                      Do not share your personal information with anyone before
                      consulting an adult.
                    </li>
                    <li>
                      Talk to counselors, teachers, or parents if you feel
                      stressed.
                    </li>
                    <li>
                      Help fellow learners seek help if you notice bad or
                      inappropriate behavior towards them.
                    </li>
                  </ul>
                  <br />

                  {/*  <h6><strong>Steps to Report Unethical Behaviour</strong></h6>
                    <p>There is a standard procedure to handle inappropriate incidents/activities at ALLEN Digital, and a team of skilled counselors is always ready to offer their support. Do not hesitate in reporting behavior that makes you uncomfortable. Do not skip classes or handle the situation on your own. Reach out to our Safety team, teachers, or counselors to solve the problem and nip it in the bud.</p><br />

                    <h6><strong>How to File a Complaint?</strong></h6>
                    <ul>
                      <li>Call us at: #ChildSafetyNumber</li>
                      <li>Email us at: #ChildSafetyEmail</li>
                      <li>Report it Here (Link to reporting form)</li>
                      <li>Via Website Chatbot</li>
                      <li>Report to the teacher in Live Class Support</li>
                    </ul><br /> */}

                  <h6>
                    <strong>Combat Stress and Hesitation</strong>
                  </h6>
                  <p>
                    Mental issues can hinder academic progress and impact your
                    well-being. You must confront anything that discomforts you
                    and resolve the problem earliest. At ALLEN Digital, we are
                    committed to creating a healthy learning environment and
                    promise to resolve any issue that may cause stress fairly
                    and transparently.
                  </p>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="list-csr"
                role="tabpanel"
                aria-labelledby="csr-tab"
              ></div>
              {/* Teching Methodology */}
              <div
                className={this.isActiveTabContent(
                  this.state.PageContent[4]?.slug
                )}
                id="list-teaching-methodology"
                role="tabpanel"
                aria-labelledby="proud-tab"
              >
                <div className="d-flex flex-row flex-wrap">
                  <div className="col-12 col-lg-12 mb-5">
                    <img
                      src={
                        this.state.PageContent[4]?.content[0]?.image
                          ? environment.baseUrlImg +
                            this.state.PageContent[4]?.content[0]?.image
                          : AboutImg7
                      }
                      alt=""
                      className="img-fluid"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <h4>{this.state.PageContent[4]?.content[0]?.title}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.PageContent[4]?.content[1]
                          ?.description,
                      }}
                    ></div>
                  </div>

                  <div className="col-12 col-lg-12">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.PageContent[4]?.content[0]
                          ?.description,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AboutTabContents;
