import React from 'react'
import CoureshCancel from './CoureshCancel'
import RazorPayApiInti from './RazorPayApiInti'

export default function RequestLogs(props) {
  return (
    <div className="request-log mt-4">
    <h4 class="text-success text-decoration-none" style={{padding: "0px 11px",}}> Request Logs</h4>
    <table className="table course-batch-chnage-table table-hover mt-4" border="0" width="100%">
      <thead>
      <tr>
      <th width="12%">Request Type</th>
      <th width="12%">Request Date</th>
      <th width="54%">Your Request</th>
        <th width="12%">Request Status</th>
        <th width="10%"> Action</th>
      </tr>
      </thead>
      <tbody>
      {props?.ticketLogs?.map((val, ind) => (
                <tr key={ind}>
                <td> {val?.request_type} </td>
                <td> {props?.CreatedDateGet(val?.created_date)}  </td>
                <td> {val?.course_name_from} <br/>  <b className='ml-2 mr-2'>TO</b>  <br/>  {val?.course_name_to}</td>
                <td> {props?.ticketStutas[val?.ticket_status]}</td>
                <td> {val?.button_url !== "" ? (<RazorPayApiInti ticket_data={val} />) : val?.ticket_status === 761 ? null : "--"}
                {val?.ticket_status === 761 ? (<CoureshCancel  ticket_data={val} getStudentTicketLog={props?.getStudentTicketLog} /> ) : val?.button_url !== "" ? null : "--"}
                </td>
              </tr>
      ))}
      </tbody>
    </table>
  </div>
  )
}
