import React, {useState, useEffect} from "react";
// import { environment } from "../../Services/HttpData";
import { toast } from 'react-toastify';
import { propTypes } from "react-bootstrap/esm/Image";

const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)

const EcomAddUserAddress = (props) => {

    const addressDetails = props.addressDetails;
    const checkoutDetails = props.checkoutDetails;
   // //console.log("addressDetails",addressDetails)
    //console.log("checkoutDetails",checkoutDetails[0])
   

    if(addressDetails.student_address_details_id) {
      var x = document.getElementById("myDIV");
      var y = document.getElementById("addNewAddress");
      x.style.display = "block";
      y.style.display = "none";
    }

    const [showAddressForm,setShowAddressForm] = useState(false);
    const [country,setCountry] = useState();
    const [state,setState] = useState();
    const [cities,setCities] = useState();
    const auth_token =  localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : '';
    const [text,setText] = useState({
        student_address_details_id:"",
        address_line1:"",
        address_line2:"",
        city_id:"",
        pincode:"",
        country:"",
        state:"",
    });
    const handleEvent = (e) => {
        const {name,value} = e.target;
        if(name === "country"){
          //setCountry(value);
          getState(value);
        }
        if(name === "state"){
          //setState(value);
          getCities(value);
          //console.log("state");
        }
        if(addressDetails.student_address_details_id === ""){
          setText((preValue)=>{
              return{
                  ...preValue,
                  [name] : value,
              };
          })
        }else{
          
          setText((preValue)=>{
              return{
                  ...preValue,
                  ["student_address_details_id"] : addressDetails.student_address_details_id,
                  [name] : value,
              };
          })
        }
        
        //console.log(text);
    }

    const submitForm = (e) => {
        //console.log(text);
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers : {
              'Content-Type' : "application/json",
              'Authorization': 'Basic '+auth_token
            },
            body: JSON.stringify(text)
          };
          fetch(environment.ecomApiUrl + 'user/add-address',requestOptions).then((resp)=>{ // fetching api url for courses listing
              resp.json().then((result)=>{
                if(result.status == "success"){
                  toast.success(result.message);
                  //console.log("Form Submitted Successfully");
                  props.onChange();
                  var x = document.getElementById("myDIV");
                  var y = document.getElementById("addNewAddress");
                  y.style.display = "block";
                  x.style.display = "none";
                  x.reset();
                  setText({
                    address_line1:"",
                    address_line2:"",
                    city_id:"",
                    pincode:"",
                    country:"",
                    state:"",
                });
                }else{
                  toast.error(result.message);
                }
              })
          })
    }
    /* Update Address API */
    const submitUpdatedForm = (e) => {
      //console.log("updated",text);
      //setText([...text, {student_address_details_id:student_address_details_id}]);
      e.preventDefault();
      const requestOptionsupdate = {
          method: 'PATCH',
          headers : {
            'Content-Type' : "application/json",
            'Authorization': 'Basic '+auth_token
          },
          body: JSON.stringify(text)
        };
        fetch(environment.ecomApiUrl + 'user/update-address',requestOptionsupdate).then((resp)=>{ // fetching api url for courses listing
            resp.json().then((result)=>{
              if(result.status == "success"){
                toast.success(result.message);
                //console.log("Form Submitted Successfully");
                props.onChange();
                var x = document.getElementById("myDIV");
                var y = document.getElementById("addNewAddress");
                y.style.display = "block";
                x.style.display = "none";
                x.reset();
                setText({
                  address_line1:"",
                  address_line2:"",
                  city_id:"",
                  pincode:"",
                  country:"",
                  state:"",
              });
              }else{
                toast.error(result.message);
              }
            })
        })
  }

    useEffect(()=>{
        const requestOptions = {
          method: 'GET',
          headers : {
            'Authorization': 'Basic '+auth_token
          }
        };
        fetch(environment.ecomApiUrl + 'app/countries',requestOptions).then((resp)=>{ // fetching api url for courses listing
            resp.json().then((result)=>{
              setCountry(result.data);
              ////console.log(checkoutDetails);
            })
        })
        /* const requestOptions1 = {
          method: 'GET',
          headers : {
            'Authorization': 'Basic '+auth_token
          }
        };
        fetch(environment.ecomApiUrl + 'app/states/f0f33a',requestOptions1).then((resp)=>{ // fetching api url for courses listing
            resp.json().then((result)=>{
                setState(result.data);
              ////console.log(checkoutDetails);
            })
        }) */
        
    }, []);
  
  useEffect(() => {
    if(props.checkoutDetails.length > 0){
      getState('690352c39f603fa69683af93d18a690d');
    }
  },[props.checkoutDetails])

    function getState(data){
      
      const requestOptions2 = {
        method: 'GET',
        headers : {
          'Authorization': 'Basic ' + localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : '',
          
        }
      };
      fetch(environment.ecomApiUrl + 'app/states/'+data,requestOptions2).then((resp)=>{ // fetching api url for courses listing
          resp.json().then((result)=>{
              setState(result.data);
            //console.log(data);
          })
      })
    }

    function getCities(data){
    
      const requestOptions2 = {
        method: 'GET',
        headers : {
          'Authorization': 'Basic '+localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : '',
        }
      };
      fetch(environment.ecomApiUrl + 'app/cities/'+data,requestOptions2).then((resp)=>{ // fetching api url for courses listing
          resp.json().then((result)=>{
              setCities(result.data);
            ////console.log(checkoutDetails);
          })
      })
    }

    function myFunction() {
      var x = document.getElementById("myDIV");
      var y = document.getElementById("addNewAddress");
      y.style.display = "none";
      if (x.style.display === "block") {
        x.style.display = "none";
      } else {
        x.style.display = "block";
      }
    }

    function cancelAddressFrom() {
      var x = document.getElementById("myDIV");
      var y = document.getElementById("addNewAddress");
      y.style.display = "block";
      x.style.display = "none";
      /* alert(x.style.display) */
      x.reset();
      setText({
        address_line1:"",
        address_line2:"",
        city_id:"",
        pincode:"",
        country:"",
        state:"",
    });
    }

    return(
        <>
            <button className="new-add-btn mt-4 mb-4" onClick={myFunction} id="addNewAddress"><b>Add New Address</b></button>
            
            <form className="address-form" id="myDIV">
            <div className="new-add-btn mt-3 mb-3 border-none p-0" style={{marginTop:"2%"}}><b>Add New Address</b></div>
                    {/* <h1>Hello {text.country}</h1> */}
                <div className="form-group row">
                  <label htmlFor="country" className="col-sm-2 col-form-label">Country *</label>
                  <div className="col-sm-10">
                    <select id="country" name="country" className="form-control" onChange={handleEvent} value={checkoutDetails.country_id}>
                     
                          <option value="">--- Select ---</option>
                            {
                               (country?country:[]).map((cn,i)=>{
                                return(
                                    <>
                                        <option value={cn.country_id} selected={true}> {cn.country_name} </option>
                                    </>
                                )
                            })
                            }
                          
                    
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="state" className="col-sm-2 col-form-label">State *</label>
                  <div className="col-sm-10">
                    <select id="state" name="state" className="form-control" onChange={handleEvent} > 
                    <option value="">--- Select ---</option>
                        {
                            (state?state:[]).map((st,i)=>{
                              //console.log("see",st.state_id+'='+checkoutDetails.state_id)
                                return(
                                    <>
                                        <option value={st.state_id} selected={st.country_id === checkoutDetails.state_id?true:false}> {st.state_name} </option>
                                    </>
                                )
                            })
                        }
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="state" className="col-sm-2 col-form-label">City *</label>
                  <div className="col-sm-10">
                    <select id="city" name="city_id" className="form-control" onChange={handleEvent} >
                    <option value="">--- Select ---</option>
                    {
                            (cities?cities:[]).map((ct,i)=>{
                                return(
                                    <>
                                        <option value={ct.city_id} selected={ct.country_id === checkoutDetails.city_id?true:false}> {ct.city_name} </option>
                                    </>
                                )
                            })
                        }
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="pincode" className="col-sm-2 col-form-label">Pincode *</label>
                  <div className="col-sm-10">
                    <input type="text" className="form-control" id="pincode" name="pincode" maxLength={6} value={text.pincode?text.pincode:addressDetails.pincode} placeholder="" onChange={handleEvent}></input>
                  </div>
                </div>


                <div className="form-group row">
                  <label htmlFor="address1" className="col-sm-2 col-form-label">Address line 1 *</label>
                  <div className="col-sm-10">
                    <input type="text" className="form-control" id="address1"  name="address_line1" value={text.address_line1?text.address_line1:addressDetails.address_line1} placeholder="" onChange={handleEvent}></input>
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="address2" className="col-sm-2 col-form-label">Address line 2 </label>
                  <div className="col-sm-10">
                    <input type="text" className="form-control" id="address2" name="address_line2" value={text.address_line2?text.address_line2:addressDetails.address_line2} placeholder="" onChange={handleEvent}></input>
                  </div>
                </div>
                {/* <div className="form-group row">
                  <label htmlFor="address2" className="col-sm-2 col-form-label">Address line 3 </label>
                  <div className="col-sm-10">
                    <input type="text" className="form-control" id="address3" name="address_line3" value={text.address_line3?text.address_line3:addressDetails.address_line3} placeholder="" onChange={handleEvent}></input>
                  </div>
                </div> */}
                <div className="form-group row">
                  
                  <div className="col-sm-10">
                   {/*  <input type="hidden" className="form-control" id="student_address_details_id" name="student_address_details_id" value={text.student_address_details_id?text.student_address_details_id:addressDetails.student_address_details_id}  onChange={handleEvent}></input> */}
                  </div>
                </div>
                {
                  addressDetails.student_address_details_id?
                  <button type="submit" className="btn btn-primary btn-save-add mt-3 mb-2" onClick={submitUpdatedForm}>Update</button>:
                  <button type="submit" className="btn btn-primary btn-save-add mt-3 mb-2" onClick={submitForm}>Save</button>
                }
                
                <span  className="btn btn-danger btn-save-add mt-3 mb-2" onClick={cancelAddressFrom} style={{marginLeft:"1%",borderColor:"#bd2130",backgroundColor:"#bd2130",color:"#fff"}}>Cancel</span>

            </form>
        </>
    )
}

export default EcomAddUserAddress;