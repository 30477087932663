import React, { PureComponent } from 'react';
import { Link } from "react-router-dom";
import iconNEET from "../../images/neet.png";
// import IconNTA from "../../images/nta.png";
// import IconCA from "../../images/cafinal.png";
// import IconCMA from "../../images/CMA.png";
// import IconRAS from "../../images/rasfinal.png";
// import { environment } from '../../Services/HttpData';
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)

class HomeProgrammes extends React.Component {
    constructor(props) {
        super(props);
        //   //console.log(this.props);
        this.state = {
            PageContent: {},
            examsList: []
        };
    }
    componentWillReceiveProps(props) {
        // //console.log(props);
        this.setState({ PageContent: props.handelEvent, examsList: props.exams });
    }
    render() {
        const cardStyle = {
            maxWidth: "50px",
            marginBottom: "15px"
        };
        return <>
            <div className="container my-4 my-md-5">
                <div className="row">
                    <div className="col-12">
                        <div className="sub-heading">
                            <h3>{this.state.PageContent?.title}</h3>
                        </div>
                    </div>
                </div>
                <div className="row">

                    {this.state.examsList?.length !== 0 &&
                        this.state.examsList?.map((item) => (
                            <div key={item.id} className="col-12 col-md-6 col-lg-3 text-center mt-4 mt-md-5">
                                <div className="card my-card">
                                    <img style={cardStyle} src={item?.image
                                        ? environment.baseUrlImg +
                                        item?.image
                                        : iconNEET} alt="" className="img-fluid" />
                                    <p className='program-title'>{item.title}</p>
                                    <Link to={"/exam/" + item.slug}><button type="button" className="btn btn-primary nav-btn">{item.subtitle}</button></Link>
                                </div>
                            </div>
                            //   <li className="nav-item" key={item?.id}>
                            //     <a className="nav-link" key={item?.id} href={item?.slug}>
                            //       {item?.title}
                            //     </a>
                            //   </li>
                        ))}
                    {/* <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img style={cardStyle} src={iconNEET} alt="" className="img-fluid"/>
                <p>Class 9, 10</p>
                <a href="exam.php"><button type="button" className="btn btn-primary nav-btn">Coming Soon</button></a>
            </div>
            <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img style={cardStyle} src={IconNTA} alt="" className="img-fluid"/>
                <p>JEE</p>
                <a href="exam.php"><button type="button" className="btn btn-primary nav-btn">Coming Soon</button></a>
            </div>
            <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img style={cardStyle} src={IconNTA} alt="" className="img-fluid"/>
                <p>NEET</p>
                <a href="exam.php"><button type="button" className="btn btn-primary nav-btn">Coming Soon</button></a>
            </div>
            <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img style={cardStyle} src={IconCA} alt="" className="img-fluid"/>
                <p>CA Foundation</p>
                <a href="exam.php"><button type="button" className="btn btn-primary nav-btn">Coming Soon</button></a>
            </div>
            <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img style={cardStyle} src={IconCMA} alt="" className="img-fluid"/>
                <p>CMA</p>
                <a href="exam.php"><button type="button" className="btn btn-primary nav-btn">Coming Soon</button></a>
            </div>
            <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img style={cardStyle} src={IconRAS} alt="" className="img-fluid"/>
                <p>RAS</p>
                <a href="exam.php"><button type="button" className="btn btn-primary nav-btn">Coming Soon</button></a>
            </div> */}
                    {/* <!-- <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img style="max-width: 50px; margin-bottom: 15px;" src="images/CBSE.png" alt="" className="img-fluid">
                <p>Class 9 to 10</p>
                <button type="button" className="btn btn-primary nav-btn">Explore Now</button>
            </div>
            <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img style="max-width: 50px; margin-bottom: 15px;" src="images/download (2).jpg" alt="" className="img-fluid">
                <p>JEE</p>
                <button type="button" className="btn btn-primary nav-btn">Explore Now</button>
            </div>
            <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img style="max-width: 50px; margin-bottom: 15px;" src="images/download (2).jpg" alt="" className="img-fluid">
                <p>NEET</p>
                <button type="button" className="btn btn-primary nav-btn">Explore Now</button>
            </div>
            <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img style="max-width: 50px; margin-bottom: 15px;" src="images/CA.jpg" alt="" className="img-fluid">
                <p>CA Foundation</p>
                <button type="button" className="btn btn-primary nav-btn">Explore Now</button>
            </div>
            <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img style="max-width: 50px; margin-bottom: 15px;" src="images/cma.png" alt="" className="img-fluid">
                <p>CMA</p>
                <button type="button" className="btn btn-primary nav-btn">Explore Now</button>
            </div>
            <div className="card my-card col-12 col-md-4 col-lg text-center">
                <img style="max-width: 50px; margin-bottom: 15px;" src="images/RAS.webp" alt="" className="img-fluid">
                <p>RAS</p>
                <button type="button" className="btn btn-primary nav-btn">Explore Now</button>
            </div> --> */}
                </div>
            </div>
        </>;
    }
}

export default HomeProgrammes;