import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import saveicon from "../../images/save-icon.png";
import shareicon from "../../images/share-icon.png";
// import { environment } from "../../Services/HttpData";
import iit from "../../images/iit-main.jpg";
import "../../css/ecom/ecomStyle.css";
import EcomEmi from "./EcomEmi";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import maintenance from "../../images/maintenance.svg";
import { useHistory } from "react-router";
import MixpanelInit from "../Mixpanel/MixpanelInit";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);

const EcomDetails = props => {
    const history = useHistory();
    const [modalShow2, setModalShow2] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [installment, setInstallment] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [buyNow, setBuyNow] = useState(false);
    const [isCheckFullCourse, setIsCheckFullCourse] = useState(true);
    const [isCheckedEmi, setIsCheckedEmi] = useState(false);
    const [name_current, setName_current] = useState("");
    const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "";
    const [feeType, setFeeType] = useState();
    const handlePayment = e => {
        //alert(e.target.value);
        const payment_type = e.target.value;
        if (payment_type == "emi") {
            setInstallment(true);
            setBuyNow(true);
            setIsCheckedEmi(true);
            setIsCheckFullCourse(false);
        } else {
            setInstallment(false);
            setBuyNow(false);
            setIsCheckedEmi(false);
            setIsCheckFullCourse(true);
        }
    };
    //console.log(modalShow);

    const doLogin = () => {
        // //console.log("doLogin() Called");
        window.location = `${process.env.REACT_APP_AUTH_URL}?client_id=allendigital-business-portal&redirect_uri=${window.location.href.replace("www.", "www.").split("#")[0]
            }&response_mode=fragment&response_type=code&scope=openid&page=checkout`;
    };

    const addToCart = (item, buyNow = false) => {
        //console.log(item);
        const skvDetails = JSON.parse(sessionStorage.getItem("skavaTokenDetails"));
        // //console.log(skvDetails.sessionId);
        const wid_data = localStorage.getItem("userWID") ? JSON.parse(localStorage.getItem("userWID")) : null;
        let wid = null;
        if (wid_data) {
            if (wid_data.results) {
                if (wid_data.results.length) {
                    if (wid_data.results[0].wid) {
                        wid = wid_data.results[0].wid;
                    }
                }
            }
        }

        if (skvDetails !== null && skvDetails.sessionId !== undefined && wid) {
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    apiKey: skvDetails.sessionId,
                },
                body: JSON.stringify({
                    cartItem: [
                        {
                            quantity: 1,
                            skus: [
                                {
                                    productId: item.sku_id,
                                    skuId: item.sku_id,
                                    type: "DEFAULT",
                                },
                            ],
                            properties: {
                                imageURL: item.image,
                                name: item.title,
                                link: `${process.env.REACT_APP_BASE_URL}/ecom/courses-details/${item.slug}`,
                                fulfillmentType: "DIGITAL",
                            },
                        },
                    ],
                    properties: {
                        enquiryId: "",
                        callerRef: "business",
                    },
                }),
            };
            fetch(environment.baseUrl + "addToCartSkSite", requestOptions)
                .then(async response => {
                    // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
                    const data = /* isJson && */ await response.json();
                    // check for error response
                    // //console.log(response);
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }
                    if (response.ok) {
                        if (response.status === 201) {
                            toast.success("Item Added to Cart");
                            //this.props.onChange("reloadCart");
                            // if (buyNow) {
                            //   window.location =
                            //     environment.skavaBaseUrl +
                            //     "?return_url=" +
                            //     environment.REACT_APP_BASE_URL;
                            // } else {
                            //   window.location =
                            //     environment.skavaBaseUrl +
                            //     "?return_url=" +
                            //     environment.REACT_APP_BASE_URL;
                            //   //props.handleCartCount('reloadCarts');
                            // }
                        } else if (response.status === 200 && buyNow) {
                            if (data.purchased) {
                                toast.error(data.message);
                            } else {
                                window.location = environment.skavaBaseUrl + "?return_url=" + environment.REACT_APP_BASE_URL;
                            }
                        } else {
                            toast.error(data.message);
                        }
                        // this.setState({cartItems:data.items,cartDetails:{value:data.value,currencySign:data.currencySign}})
                        //console.log(data);
                    }
                })
                .catch(function (error) {
                    //console.log(error);
                    //setLoading(false)
                    // setFailedStatus(error.response.data.message)
                    let failedData = error.response.data.data;
                    // setFailedDataStatus(failedData.join(','))
                    if (error.response) {
                        toast.error(error.response.data.message);
                    } else if (error.request) {
                        toast.error(error.request);
                    } else {
                        toast.error(error.message);
                    }
                });
        } else {
            doLogin();
            toast.warning("Please login first.");
        }
    };

    const addToCart1 = (productId, fee_type, check_in_cart) => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + auth_token,
            },
        };
        fetch(environment.ecomApiUrl + "order/check-active-course", requestOptions).then(res => {
            res.json().then(result => {
                if (result?.data?.course_is_active == "1" || result?.data?.course_is_active == 1) {
                    let id = "t101";
                    toast.warning(result.message, {
                        toastId: id,
                    });
                } else {
                    //put your code
                    if (check_in_cart === true) {
                        toast.error("Already Added In Cart");
                    } else {
                        setFeeType(fee_type);
                        const requestOptions1 = {
                            method: "GET",
                            headers: {
                                Authorization: "Basic " + auth_token,
                            },
                        };
                        fetch(environment.ecomApiUrl + "cart/list", requestOptions1).then(resp => {
                            // fetching api url for courses listing
                            resp.json().then(result => {
                                //console.log('abcd',result.data);
                                let failed = true;
                                if (result.data) {
                                    if (result.data.items) {
                                        if (result.data.items.length) {
                                            failed = false;
                                            setModalShow(true);
                                            //console.log(modalShow);
                                            //console.log("Replacement");
                                        }
                                    }
                                }

                                if (failed) {
                                    MixpanelInit("Track", "added_to_cart", {
                                        course_id: productDetails?.course_id,
                                        course_title: productDetails?.title,
                                        academic_session: productDetails?.academic_session,
                                        commencement_date: productDetails?.commencement_date,
                                        duration: productDetails?.duration,
                                        study_mode: "Online (Live)",
                                        study_medium: productDetails?.medium_name,
                                        course_fee: productDetails?.display_price,
                                        refund_last_day: productDetails?.refund_last_day,
                                    });
                                    const requestOptions = {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                            Authorization: "Basic " + auth_token,
                                        },
                                        body: JSON.stringify({
                                            product_id: productId,
                                            fee_type: fee_type,
                                            replace: 1,
                                        }),
                                    };
                                    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
                                    window.dataLayer.push({
                                        event: "add_to_cart",
                                        ecommerce: {
                                            currency: "INR",
                                            value: productDetails?.display_price,
                                            items: [
                                                {
                                                    item_id: productDetails?.course_id,
                                                    item_name: productDetails?.title,
                                                },
                                            ],
                                        },
                                    });
                                    fetch(environment.ecomApiUrl + "cart/item/add", requestOptions).then(resp => {
                                        // fetching api url for courses listing
                                        resp.json().then(result => {
                                            if (result?.status === "success") {
                                                setModalShow(false);
                                                window.location = "/ecom/cart";
                                            } else {
                                                setModalShow2(true);
                                                setName_current(result?.message);
                                                // toast.warning(result?.message)
                                            }
                                        });
                                    });
                                    //console.log("No Replacement");
                                }
                            });
                        });
                        // alert(productId);
                    }
                }
            });
        });
    };

    const replaceCart = (productId, fee_type) => {
        const requestOptions2 = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + auth_token,
            },
            body: JSON.stringify({
                product_id: productId,
                fee_type: fee_type,
                replace: 1,
            }),
        };
        MixpanelInit("Track", "added_to_cart", {
            course_id: productDetails?.course_id,
            course_title: productDetails?.title,
            academic_session: productDetails?.academic_session,
            commencement_date: productDetails?.commencement_date,
            duration: productDetails?.duration,
            study_mode: "Online (Live)",
            study_medium: productDetails?.medium_name,
            course_fee: productDetails?.display_price,
            refund_last_day: productDetails?.refund_last_day,
        });
        window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
                currency: "INR",
                value: productDetails?.display_price,
                items: [
                    {
                        item_id: productDetails?.course_id,
                        item_name: productDetails?.title,
                    },
                ],
            },
        });
        fetch(environment.ecomApiUrl + "cart/item/add", requestOptions2).then(resp => {
            // fetching api url for courses listing
            resp.json().then(result => {
                //setProduct(result.data); // set result in state this will set current/updated value of setProduct to product
                setModalShow(false);
                //console.log("success");
                window.location = "/ecom/cart";
            });
        });
    };

    const doStartLearningLogin = aceess_url => {
        // //console.log("doLogin() Called");
        const wid_data = localStorage.getItem("userWID") ? JSON.parse(localStorage.getItem("userWID")) : null;
        let wid = null;
        if (wid_data) {
            if (wid_data.results) {
                if (wid_data.results.length) {
                    if (wid_data.results[0].wid) {
                        wid = wid_data.results[0].wid;
                    }
                }
            }
        }
        if (wid) {
            window.location = aceess_url;
        } else {
            //window.location = `${process.env.REACT_APP_AUTH_URL}?client_id=allendigital-business-portal&redirect_uri=${window.location.href.replace("www.", "").split('#')[0]}&response_mode=fragment&response_type=code&scope=openid`;
            window.location = aceess_url;
        }
    };

    const productDetails = props.data;
    const is_purchased = productDetails.is_purchase;
    const userWid = window.localStorage.getItem("IS_DASHBOARD_ICON")
        ? window.localStorage.getItem("IS_DASHBOARD_ICON")
        : false;
    let BuyNowButton1;
    let BuyNowButton2;
    // console.log("hiamsnhu avsjskjhd")
    if (window.location.href.includes("ZSJ58TTQIc")) {
        if (userWid) {
            if (productDetails?.propelld_status) {
                BuyNowButton1 = course_id => {
                    history.push("/ecom/Propelld/checkout/" + course_id);
                    // addToCart1(productDetails.id, "emi", productDetails.already_in_cart);
                };
                BuyNowButton2 = course_id => {
                    history.push("/ecom/Propelld/checkout/" + course_id);
                    // addToCart1(productDetails.id, "", productDetails.already_in_cart);
                };
            } else {
                BuyNowButton1 = course_id => {
                    toast.warning("This course is not available for Propelld");
                    // addToCart1(productDetails.id, "emi", productDetails.already_in_cart);
                };
                BuyNowButton2 = course_id => {
                    toast.warning("This course is not available for Propelld");
                    // addToCart1(productDetails.id, "", productDetails.already_in_cart);
                };
            }
        } else {
            BuyNowButton1 = course_id => {
                history.push("/login", { from: window.location.pathname + "?=" + course_id });
                toast.warning("Please login first.");
                // addToCart1(productDetails.id, "emi", productDetails.already_in_cart);
            };
            BuyNowButton2 = course_id => {
                history.push("/login", { from: window.location.pathname + "?=" + course_id });
                toast.warning("Please login first.");
                // addToCart1(productDetails.id, "", productDetails.already_in_cart);
            };
        }
    } else {
        if (userWid) {
            BuyNowButton1 = () => addToCart1(productDetails.id, "emi", productDetails.already_in_cart);
            BuyNowButton2 = () => addToCart1(productDetails.id, "", productDetails.already_in_cart);
        } else {
            BuyNowButton1 = course_id => {
                history.push("/login", { from: window.location.pathname + "?=" + course_id });
                toast.warning("Please login first.");
                // addToCart1(productDetails.id, "emi", productDetails.already_in_cart);
            };
            BuyNowButton2 = course_id => {
                history.push("/login", { from: window.location.pathname + "?=" + course_id });
                toast.warning("Please login first.");
                // addToCart1(productDetails.id, "", productDetails.already_in_cart);
            };
        }
    }
    /* const is_purchased = false; */

    function MyVerticallyCenteredModal(props) {
        //console.log(props.fee_type);
        // alert(cart_item_id);
        return (
            <Modal {...props} size='md' aria-labelledby='contained-modal-title-vcenter' centered className='replace-modal'>
                {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header> */}
                <Modal.Body className='text-center' style={{ width: "60% !important" }}>
                    <h5 className='my-font text-center replace-cart-text'>Are You Sure To Replace The Cart Product ?</h5>
                    {
                        <Button
                            className='btn btn-green-header mt-4 mr-3 hover-green'
                            onClick={() => replaceCart(props.productId, props.fee_type)}>
                            Yes
                        </Button>
                    }
                    <Button className='btn btn-outline-green-header mt-4 hover-white' onClick={props.onHide}>
                        No
                    </Button>
                </Modal.Body>
                {/* <Modal.Footer>
    
      </Modal.Footer> */}
            </Modal>
        );
    }
    function MyVerticallyCenteredModalAlreadyEnrolled(props) {
        return (
            <Modal
                {...props}
                size='md'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                className='replace-modal course-modal'>
                {/* <Modal.Header closeButton>
    <Modal.Title id="contained-modal-title-vcenter">
     
    </Modal.Title>
  </Modal.Header> */}
                <Modal.Body className='p-0'>
                    <div className='course-popup'>
                        <div className='row align-items-center'>
                            <div className='col-6 col-md-4 col-xl-4 mx-auto'>
                                <img
                                    className='img-fluid d-block mx-auto'
                                    src={process.env.REACT_APP_CDN_URL + "fe-bsite/coursepopup.png"}
                                    alt='First slide'
                                />
                            </div>
                            <div className='col-12 col-md-12 col-xl-8 mt-3 mt-xl-0'>
                                <p>
                                    Dear Student, <br />
                                    You are actively enrolled on the course <b>{name_current}</b>
                                    <br />
                                    <br />
                                    For details on any other courses, please get in touch <br className='d-none d-md-block d-xl-none' />{" "}
                                    with our <br className='d-none d-xl-block' /> support team by calling{" "}
                                    <b>
                                        <a href='tel:+91-9513736499' className='popup-link'>
                                            +91-9513736499{" "}
                                        </a>
                                    </b>
                                    or by <br className='d-none d-md-block d-xl-none' />
                                    email at{" "}
                                    <b>
                                        <a href='mailto:wecare@allendigital.in' className='popup-link'>
                                            wecare@allendigital.in
                                        </a>
                                    </b>
                                </p>
                            </div>
                        </div>
                        <img
                            onClick={props.onHide}
                            className='img-fluid d-block mx-auto closecross'
                            src={process.env.REACT_APP_CDN_URL + "fe-bsite/cross.png"}
                            alt='First slide'></img>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>

  </Modal.Footer> */}
            </Modal>
        );
    }

    useEffect(() => {
        if (userWid) {
            if (window.location.search !== "" && !window.location.href.includes("ZSJ58TTQIc") && !window.location.href.includes("preview")) {
                ///////////////
                const requestOptions = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Basic " + auth_token,
                    },
                    body: JSON.stringify({
                        product_id: window.location.search.replaceAll("?=", ""),
                        fee_type: "",
                        replace: 1,
                    }),
                };
                fetch(environment.ecomApiUrl + "cart/item/add", requestOptions).then(resp => {
                    // fetching api url for courses listing
                    resp.json().then(result => {
                        if (result?.status === "success") {
                            setModalShow(false);
                            window.location = "/ecom/cart";
                        } else {
                            setModalShow2(true);
                            setName_current(result?.message);
                            // toast.warning(result?.message)
                        }
                    });
                });
            }
        }
    }, []);
    return (
        <>
            <MyVerticallyCenteredModal
                show={modalShow}
                productId={productDetails.id}
                fee_type={feeType}
                onHide={() => setModalShow(false)}
            />
            <MyVerticallyCenteredModalAlreadyEnrolled show={modalShow2} onHide={() => setModalShow2(false)} />
            <div className='row m-0'>
                <div className='col-12 col-lg-4'>
                    <img src={productDetails.image === "" ? iit : productDetails.image} />
                    {is_purchased || productDetails.is_freemium === 1 ? null : (
                        <div className='my-3'>
                            <div className='row m-0'>
                                <h4 className='font-weight-bold color-h4 mr-3 mb-0 font-size-30 my-font'>
                                    ₹{" "}
                                    {productDetails?.propelld_status === true && window.location.href.includes("ZSJ58TTQIc")
                                        ? productDetails?.price + productDetails?.loan_charges
                                        : productDetails.price}
                                </h4>
                                {productDetails.price != productDetails.display_price ? (
                                    <>
                                        <h4 className='font-weight-bold text-danger mb-0 font-size-30 my-font'>
                                            <s>{"₹ " + productDetails.display_price}</s>
                                        </h4>
                                    </>
                                ) : null}

                                {/* <h4 className="font-weight-bold text-danger mb-0 font-size-30">MRP: <s>&#8377;1499</s></h4> */}
                            </div>

                            {/* {this.state.detailsData?.price?.value >
        this.state.detailsData?.salesPrice?.value && (
        <p className="my-0 font-size-22" style={{ color: "#046635" }}>
          Save
          {this.state.detailsData?.price?.currency === "INR"
          ? "₹"
          : this.state.detailsData?.price?.currency}
          {this.state.detailsData?.price?.value -
          this.state.detailsData?.salesPrice?.value}{" "}
          (
          {this.getPercantage(
          this.state.detailsData?.salesPrice?.value,
          this.state.detailsData?.price?.value
          ).toFixed(2)}
          % OFF)
        </p>
        )} */}
                            {/* Save Rs. 300 (20% OFF) */}
                        </div>
                    )}

                    {/* Payment Option */}
                    {is_purchased || productDetails.is_freemium === 1 ? null : (
                        <>
                            <hr />
                            <EcomEmi
                                onChange={handlePayment}
                                installment={installment}
                                emi={productDetails.emi_plan}
                                price={
                                    productDetails?.propelld_status === true && window.location.href.includes("ZSJ58TTQIc")
                                        ? productDetails?.price + productDetails?.loan_charges
                                        : productDetails.price
                                }
                                isCheckedEmi={isCheckedEmi}
                                isCheckFullCourse={isCheckFullCourse}
                            />
                            <hr />
                        </>
                    )}

                    {/* Buy Now  */}
                    {productDetails.is_freemium ? (
                        <>
                            <div className='row mx-0 mb-2 mt-4'>
                                <button
                                    type='button'
                                    className='btn btn-outline-green-no-width mr-2 padding-6-40 boder-2'
                                    onClick={() => doStartLearningLogin(productDetails.access_url)}>
                                    Start Learning
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            {is_purchased ? (
                                <>
                                    <div
                                        className='row'
                                        style={{
                                            justifyContent: "space-between",
                                            padding: "0 15px",
                                        }}>
                                        <h6 className='mt-3'>You are already Enrolled</h6>
                                        {productDetails.already_in_cart ? (
                                            <button
                                                type='button'
                                                className='btn btn-outline-green-header'
                                                onClick={() => addToCart(productDetails.id, "emi", productDetails.already_in_cart)}>
                                                Start Learning
                                            </button>
                                        ) : null}
                                    </div>
                                </>
                            ) : (
                                <div
                                    className='d-flex mx-0 mb-2 align-items-center'
                                    style={{
                                        justifyContent: "space-between",
                                        flexWrap: "nowrap",
                                    }}>
                                    {buyNow ? (
                                        <button
                                            type='button'
                                            className='btn btn-outline-green-no-width mr-2 padding-6-40 boder-2'
                                            onClick={() => {
                                                MixpanelInit("Track", "added_to_cart", {
                                                    course_id: productDetails?.course_id,
                                                    course_title: productDetails?.title,
                                                    academic_session: productDetails?.academic_session,
                                                    commencement_date: productDetails?.commencement_date,
                                                    duration: productDetails?.duration,
                                                    study_mode: "Online (Live)",
                                                    study_medium: productDetails?.medium_name,
                                                    course_fee: productDetails?.display_price,
                                                    refund_last_day: productDetails?.refund_last_day,
                                                });
                                                BuyNowButton1(productDetails?.course_id);
                                            }}
                                        //  onClick={() => addToCart(productDetails)}
                                        /* data-toggle="modal" data-target="#myModal" */
                                        // data-toggle="modal" data-target="#exampleModal"
                                        >
                                            Buy Now
                                        </button>
                                    ) : (
                                        <button
                                            type='button'
                                            className='btn btn-outline-green-no-width mr-2 padding-6-40'
                                            onClick={() => BuyNowButton2(productDetails?.course_id)}
                                        //  onClick={() => addToCart(productDetails)}
                                        /* data-toggle="modal" data-target="#myModal" */
                                        // data-toggle="modal" data-target="#exampleModal"
                                        >
                                            Buy Now
                                        </button>
                                    )}
                                    {productDetails.doc_file ? (
                                        /*  true? */
                                        <div className='m-2'>
                                            {" "}
                                            <div
                                                className='text-right text-nowrap'
                                                onClick={e => {
                                                    MixpanelInit("Track", "schedule_downloaded", {
                                                        course_id: productDetails?.course_id,
                                                        course_title: productDetails?.title,
                                                        academic_session: productDetails?.academic_session,
                                                        commencement_date: productDetails?.commencement_date,
                                                        duration: productDetails?.duration,
                                                        study_mode: "Online (Live)",
                                                        study_medium: productDetails?.medium_name,
                                                        course_fee: productDetails?.display_price,
                                                        refund_last_day: productDetails?.refund_last_day,
                                                    });
                                                }}>
                                                <a href={productDetails.doc_file} target='_blank' rel='noreferrer'>
                                                    <i className='fa fa-download' aria-hidden='true'></i>
                                                    {productDetails?.course_category_name === "Online Test Series"
                                                        ? " Schedule & Syllabus"
                                                        : " Syllabus"}
                                                </a>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            )}
                        </>
                    )}

                    {/* Is freemium */}
                </div>
                <div className='col-12 col-lg-8'>
                    <div className='row m-0'>
                        {productDetails.is_best_seller === "No" ? null : (
                            <>
                                <div className='d-flex flex-row'>
                                    <p className='best-seller'>Best Seller</p>
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        <h1 className='font-weight-bold font-size-30'>{productDetails.title}</h1>
                        {/* <footer className="blockquote-footer mt-0 font-size-18">
          {productDetails.description}
        </footer> */}
                        <p
                            className='mt-3 text-muted font-size-18-normal'
                            style={{ fontSize: "18px" }}
                            dangerouslySetInnerHTML={{ __html: productDetails.sub_title }}></p>
                        <p
                            className='mt-3 text-muted font-size-18-normal'
                            style={{ fontSize: "18px" }}
                            dangerouslySetInnerHTML={{ __html: productDetails.description }}></p>
                        {/* <div className="text-right">
          <a className="font-size-22-normal" href="#!">
            Read More
          </a>
        </div> */}
                    </div>
                </div>
            </div>

            <div className='col my-5 aboutCourse'>
                <h5 className='font-weight-bold mb-4'>About this Course</h5>
                <p className='text-muted font-size-22-normal' dangerouslySetInnerHTML={{ __html: productDetails.body }}></p>
            </div>

            {/* <div className="modal fade" id="myModal" role="dialog">
    <div className="modal-dialog pop-center">     
      <div className="modal-content border-radius-pop">
      
        </div>
        <div className="modal-body padding-none" style={{padding:'0px'}}>
        <section className="d-flex justify-content-center">
        <button type="button" className="close" data-dismiss="modal">X</button>
          <div id="maintenance-pop">
          <img src={maintenance} />
          <h1><b>OOPS, SORRY!!!</b></h1>
          <p>The service you are requesting is down as we are performing a maintenance activity. We're very sorry for the inconvenience.</p>
         
          </div>
          <div className="modal-body padding-none" style={{padding:'0px'}}>
          <section className="d-flex justify-content-center">
          <button type="button" className="close" data-dismiss="modal">X</button>
            <div id="maintenance-pop">
            <img src={maintenance} />
            <h1><b>OOPS, SORRY!!!</b></h1>
            <p>The service you are requesting is down as we are performing a maintenance activity. We're very sorry for the inconvenience.</p>
          
            </div>
          </section>
          </div>
        </div> */}

            {/* Redirect aci site  */}
            <div
                className='modal fade'
                id='exampleModal'
                tabIndex='-1'
                role='dialog'
                aria-labelledby='exampleModalLabel'
                aria-hidden='true'>
                <div className='modal-dialog pop-center' role='document'>
                    <div className='modal-content border-radius-pop'>
                        <div className='modal-body padding-none' style={{ padding: "0px" }}>
                            <section className='d-flex justify-content-center'>
                                {/* <button type="button" className="close" data-dismiss="modal">X</button> */}
                                <div id='maintenance-pop'>
                                    <img src={`${environment.baseUrlImg}attachment-1654175224510.svg`} />
                                    {/* <img src={maintenance} /> */}
                                    {/* <h1><b>OOPS, SORRY!!!</b></h1> */}
                                    {/* <h1><b>OOPS SORRY!!!</b></h1> */}

                                    <p>
                                        <b>Dear student</b>
                                        <br /> We have temporarily restricted e-commerce services on the allendigital.in portal. You may
                                        continue with your enrollment process through the{" "}
                                        <a
                                            href={
                                                productDetails?.medium_id === 174
                                                    ? "https://www.allen.ac.in/ahmedabad/"
                                                    : "https://www.allen.ac.in/"
                                            }>
                                            {productDetails?.medium_id === 174 ? "allen.ac.in/ahmedabad" : "allen.ac.in"}
                                        </a>{" "}
                                        portal.
                                    </p>
                                    <a
                                        href={
                                            productDetails?.medium_id === 174
                                                ? "https://www.allen.ac.in/ahmedabad/"
                                                : "https://www.allen.ac.in/"
                                        }
                                        className='btn btn-success'
                                        style={{ borderRadius: "4px !important" }}>
                                        Click here to navigate to{" "}
                                        {productDetails?.medium_id === 174 ? "allen.ac.in/ahmedabad" : "allen.ac.in"}
                                    </a>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EcomDetails;
