import React from 'react';
import iit from '../../images/iit-main.jpg';
import CourseCardGrid from "./CourseCardGrid";

const SimilarCourses = (props) => {
    const similarCourse = props.data.similar_courses ? props.data.similar_courses : [];
    return (
        <>
            <div className="container mt-4 mt-md-5">
                <div className='row justify-content-center'>
                    <div className="col-12 mb-4"><h2 className="font-weight-bold text-center section-headline">TRENDING COURSES</h2></div>
                    {
                        similarCourse.map((item) => {
                            return (
                                <div className=" col-xl-3 col-lg-3  my-2 col-12 col-md-3 p-2">
                                    <CourseCardGrid key={item.id}
                                        productId={item.id}
                                        image={item.image}
                                        slug={item.slug}
                                        title={item.title}
                                        price={item.price}
                                        display_price={item.display_price}
                                        description={item.description}
                                        sub_title={item.sub_title}
                                        is_freemium={item.is_freemium}
                                        commencement_date={item.commencement_date}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    );
}

export default SimilarCourses;