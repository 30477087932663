import React from "react";
// import $ from "jquery";
import ImgBuilding from "../../images/contact-building.png";
// import { environment } from "../../Services/HttpData";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
// import country from "../../js/countries+states.json"
import categories from "../../js/contactCategories.json"
import { toast } from "react-toastify";
import ResendOtp from "../Resendotp/ResendOtp";
import MixpanelInit from "../Mixpanel/MixpanelInit";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
const baseUrl = environment.baseUrl;


class ContactUsForm extends React.Component {
  constructor(props) {
    super(props);
    //console.log(categories);
    this.state = {
      PageContent: [],
      contactForm: {
        country_code: "+91",
        phone: "",
        email: "",
        OTP_verification__c: false
      },
      mOTP: "",
      mailOTP: "",
      isMobileOTPSent: false,
      isMobileEditable: true,
      isEmailOTPSent: false,
      isEmailEditable: true,
      subCategroies: []
    };
  }
  componentWillReceiveProps(props) {
    //console.log(props.handelEvent);
    this.setState({ PageContent: props.handelEvent });
  }
  handleUserMobile = (fieldName, validate, value, countryData) => {
    //console.log(validate, value, countryData);
    // //console.log(name, value);
    // return;
    /* if (fieldName === "mobile") {
      this.setState({ statesList: country.filter(item => item.iso2 === countryData.iso2.toUpperCase())[0].states });
    } */
    if (validate && value.length === 10) {
      var contactForm = { ...this.state.contactForm }
      contactForm[fieldName] = value;
      contactForm['country_code'] = countryData.dialCode;
      this.setState({ contactForm: contactForm, isMobileOTPSent: false })
      setTimeout(() => {
        //console.log(this.state.contactForm);
      }, 1000);
      // this.setState({ contactForm: contactForm });
      // this.sendOTP(fieldName, value, countryData.dialCode);
    }
    else {
      toast.error("Please Enter Valid mobile number.");
    }
    // //console.log(this.state);
  };
  verifyEmailOtp(e) {
    if (this.state.mailOTP?.length < 6) {
      toast.warning("Please Enter valid OTP number");
      return false;
    }
    fetch(process.env.REACT_APP_ECOM_URL + 'otp/otp_verify_email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + user.token,
      },
      body: JSON.stringify({ "email": this.state.contactForm.email, "otp": this.state.mailOTP, lead_type: 912 })
    })
      .then(async response => {
        const data = await response.json();
        //console.log(data);
        if (data.replyCode === "success") {
          this.setState({ isEmailOTPSent: false, isEmailEditable: false });
          /* var contactForm = { ...this.state.contactForm }
          // contactForm.OTP_verification__c = true;
          this.setState({ contactForm: contactForm }); */
          //console.log("Email OTP Verified");
        }
        else {
          toast.error(data.replyMsg);
        }
      })
  }
  verifyMobileOtp(e) {
    if (this.state.mOTP?.length < 6) {
      toast.warning("Please Enter valid OTP number");
      return false;
    }
    fetch(process.env.REACT_APP_ECOM_URL + 'otp/otp_verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + user.token,
      },
      body: JSON.stringify({ "mobile": this.state.contactForm.phone, "otp": this.state.mOTP, lead_type: 912 })
    })
      .then(async response => {
        const data = await response.json();
        //console.log(data);
        if (data.replyCode === "success") {
          MixpanelInit("Track", "otp_verified", { "phone_number": this.state.contactForm.phone })
          this?.setState({ isMobileOTPSent: false, isMobileEditable: false });
          var contactForm = { ...this.state.contactForm }
          contactForm.OTP_verification__c = true;
          this?.setState({ contactForm: contactForm });
          //console.log("Mobie OTP Verified");
        }
        else {
          toast.error(data.replyMsg);
        }
      })
  }
  async sendOTP(fieldName, value, country_code = null) {
    //console.log(fieldName, value, country_code);

    // return ;
    // POST request using fetch with async/await
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({})
    };
    var reqURL;
    if (fieldName === "phone" && value !== "") {
      if (value.length === 10) {
        //alert(value)
        reqURL = process.env.REACT_APP_ECOM_URL + "otp/otp_generate";
        requestOptions.body = JSON.stringify({ "mobile": value, "country_code": country_code, lead_type: 912 })
        fetch(reqURL, requestOptions)
          .then(async response => {
            const data = await response.json();
            // check for error response
            // //console.log(response);
            //console.log(data);
            if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
            if (data.replyCode === "success" && fieldName === "phone") {
              MixpanelInit("Track", "otp_requested", { "phone_number": value })
              this?.setState({ isMobileOTPSent: true });
              toast.success(data.replyMsg);
            }
            else {
              toast.error(data.replyMsg);
            }
          })
          .catch(error => {
            this?.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
          });
      }
      else {
        toast.error("Please Enter Valid mobile number.");
      }
    }
    else if (fieldName === "MobilePhone" && value === "") {
      toast.error("Please Enter Valid mobile number.");
    }
    else if (fieldName === "email") {
      reqURL = process.env.REACT_APP_ECOM_URL + "otp/otp_generate_email";
      if (value.length >= 13) {
        requestOptions.body = JSON.stringify({ "email": value, lead_type: 912 })
        fetch(reqURL, requestOptions)
          .then(async response => {
            const data = await response.json();
            // check for error response
            // //console.log(response);
            //console.log(data);
            if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
            if (data.replyCode === "success" && fieldName === "email") {
              this?.setState({ isEmailOTPSent: true });
              toast.success(data.replyMsg);
            }
            else {
              toast.error(data.replyMsg ? data.replyMsg : data.message);
            }
          })
          .catch(error => {
            this?.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
          });
      }
      else {
        toast.error("Please enter valid Email")
      }
    }
    //console.log(reqURL, requestOptions);
  }
  changeCategory(item) {
    // var value = e.target.value;
    let index = categories.findIndex(o => o.title === item.target.value)
    this.setState({ subCategroies: categories[index].subcategories })
    //console.log(item.target.value, index);
  }
  validateFrom(e) {
    //console.log("OTP_verification__c", this.state.contactForm.OTP_verification__c, "isEmailEditable", this.state.contactForm.isEmailEditable, this.state.contactForm);
    if (this.state.contactForm.phone === "") {
      toast.error("Please Enter Mobile First");
      e.preventDefault();
    }
    else if (this.state.isMobileEditable) {
      toast.error("Verify Mobile OTP First");
      e.preventDefault();
    }
    else if (this.state.contactForm.email !== "" && this.state.isEmailEditable) {
      toast.error("Verify Email OTP First");
      e.preventDefault();
    }

    // return false;
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    var value = e.target.value;
    // //console.log(name, value);
    // if(name ==="email")
    // this.sendOTP(name,value);
    // //console.log(name, value);
    // return;
    var contactForm = { ...this.state.contactForm }
    contactForm[name] = value;
    /* () => {
      this.validateField(name, value);
    } */
    this.setState({ contactForm: contactForm });
    // //console.log(this.state);
  };
  handleOTPInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <>
        <div className="container mt-5">
          <div
            className="d-flex flex-row flex-wrap mt-5">
            <div className="col-12 col-md-6 p-3">
              <h3 className="mb-4">{this.state.PageContent?.title}</h3>
              {this.state.PageContent?.content?.length !== 0 &&
                this.state.PageContent?.content?.map((item) => (
                  // <li className="nav-item" key={item?.id}>
                  //   <a className="nav-link" key={item?.id} href={item?.slug}>
                  //     {item?.title}
                  //   </a>
                  // </li>
                  <div className="mb-3 ml-1" key={item?.id}>
                    <div className="d-flex">
                      <img
                        src={
                          item?.image
                            ? environment.baseUrlImg + item?.image
                            : ImgBuilding
                        }
                        alt=""
                        className="img-fluid mr-3 mb-3"
                        style={{ width: "30px", height: "30px" }}
                      />
                      <h5>{item?.title}</h5>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    ></div>
                  </div>
                ))}

              {/* <div className="mb-3 ml-1">
                <div className="d-flex">
                  <img
                    src={ImgBuilding}
                    alt=""
                    className="img-fluid mr-3 mb-3"
                    style={{ width: "30px", height: "30px" }}
                  />
                  <h5>Jaipur Office</h5>
                </div>
                <p>
                  C-1B, Apex Circle, Near C2 Plaza,
                  <br />
                  Malviya Nagar,
                  <br />
                  Jaipur, Rajasthan - 302017
                </p>
              </div>

              <div className="mb-3 ml-1">
                <div className="d-flex">
                  <img
                    src={ImgBuilding}
                    alt=""
                    className="img-fluid mr-3 mb-3"
                    style={{ width: "30px", height: "30px" }}
                  />
                  <h5>Bengaluru Office</h5>
                </div>
                <p>
                  Sanjana Plaza, 74/2, Elephant Rock Road,
                  <br />
                  3rd Block, Jayanagar
                  <br />
                  Bengaluru, Karnataka - 560011
                </p>
              </div> */}

              {/* <!--  <div className="row mb-3 ml-1">
                    <img src="images/contact-phone.png" alt="" className="img-fluid mr-3"
                        style={{width: 30px; "heig",t: 30px";"}}
                    <p>+91-7849901001</p>
                </div>
                <div className="row mb-3 ml-1">
                    <img src="images/mail-open-outline.png" alt="" className="img-fluid mr-3"
                        style={{width: 30px; "heig",t: 30px";"}}
                    <p>support@allendigital.in<br/>sales@allendigital.in</p>
                </div> --> */}
            </div>

            <div
              className="col-12 col-md-6 p-3"
              style={{ backgroundColor: "#F6F6F6" }}
            >
              {/* <!-- <h3 className="mb-3">Have any Query?</h3>
                <form>
                    <div className="form-row mb-2">
                        <div className="col">
                            <input type="text" className="form-control" placeholder="First name and last name">
                        </div>
                        <div className="col">
                            <input type="text" className="form-control" placeholder="Class">
                        </div>
                    </div>
                    <div className="form-row mb-2">
                        <div className="col">
                            <input type="email" className="form-control" placeholder="Email ID">
                        </div>
                        <div className="col">
                            <input type="text" className="form-control" placeholder="State">
                        </div>
                    </div>
                    <div className="form-row mb-2">
                        <div className="col">
                            <input type="tel" className="form-control" placeholder="Mobile No.">
                        </div>
                        <div className="col">
                            <input type="number" className="form-control" placeholder="Enter OTP">
                        </div>
                    </div>
                    <div className="d-flex mt-3">
                        <div className=" form-check ">
                            <input className="form-check-input position-static" type="checkbox" id="blankCheckbox"
                                value="option1" aria-label="...">
                        </div>
                        <p style="font-size: 13px;">I accept <a href="t&c.php" target="_blank"> Terms & Conditions </a> and <a href="policies.php" target="_blank"> Privacy Policy </a></p>
                    </div>
                    <div className="d-flex flex-wrap">
                        <div className="">
                            <button style="width: 200px;" type="button" className="btn btn-primary nav-btn">Submit</button>
                        </div>
                    </div>
                </form> --> */}
              <h3 className="mb-3">Contact Us</h3>
              <form className="btn-colors"
                action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
                method="POST"
                onSubmit={(e) => { this.validateFrom(e); }}
              >

                {/* 
<input type="hidden" name="debug" value="1" />                         
<input type="hidden" name="debugEmail" value="jyoti@plus91labs.com" /> */}

                {/* <form
                action="https://allendigitalprivatelimited--dev.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
                method="POST"
                onSubmit={(e) => { this.validateFrom(e); }}
              > */}
                {/* <form
                action="https://allendigital.in/servlet/servlet.WebToCase?encoding=UTF-8"
                method="GET"
              > */}
                <input type="hidden" name="orgid" value="00D5g0000072S0V" />
                <input type="hidden" name="00N5g00000Q7f5A" value={"+" + this.state.contactForm.country_code + "-" + this.state.contactForm.phone} />
                <input type="hidden" name="00N5g00000SKOCZ" value={this.state.contactForm.OTP_verification__c ? "1" : "0"} />
                {/*  <input type="hidden" name="debug" value="1" />   */}
                <input
                  type="hidden"
                  name="retURL"
                  // value="https://allendigital.in/form-submited-success"
                  value={`${document.location.origin}/form-submited-success`}
                />
                {/* <!--  ----------------------------------------------------------------------  -->
                    <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
                    <!--  these lines if you wish to test in debug mode.                          -->
                    <!--  <input type="hidden" name="debug" value=1>                              -->
                    <!--  <input type="hidden" name="debugEmail" value="jyoti@plus91labs.com">    -->
                      <!--  ----------------------------------------------------------------------  --> */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        id="00N5g00000SKOCP"
                        maxLength={"80"}
                        name="00N5g00000SKOCP"
                        size="20"
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        required
                        autoComplete={"none"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        id="00N5g00000SKOCU"
                        maxLength={"80"}
                        name="00N5g00000SKOCU"
                        size="20"
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        required
                        autoComplete={"none"}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <IntlTelInput
                        containerClassName="intl-tel-input d-block"
                        inputClassName="form-control"
                        fieldName="phone"
                        fieldId="phone"
                        preferredCountries={["in", "us"]}
                        placeholder="Mobile No."
                        required
                        onPhoneNumberBlur={(validate, value, countryData) => this.handleUserMobile("phone", validate, value, countryData)}
                        autoComplete={"none"}
                        disabled={!this.state.isMobileEditable}
                      />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="form-group">
                      {(!this.state.isMobileOTPSent && !this.state.contactForm.OTP_verification__c) && (<button type="button" className="btn btn-link text-success" onClick={(e) => { this.sendOTP("phone", this.state.contactForm['phone'], this.state.contactForm['country_code']) }}>Send OTP</button>)}


                      {this.state.contactForm.OTP_verification__c && (<button type="button" className="btn btn-link text-success">Verified</button>)}
                      <p
                        className="text-danger mb-0 text-small error"
                        id="mobileError"
                      ></p>
                      {this.state.isMobileOTPSent && (
                        <div className="row">
                          <div className="col-md-7 ">
                            <input
                              type="number"
                              name="mOTP"
                              id="mOTP"
                              // onKeyUp={this.validateField('Email',10)}
                              onChange={(e) => this.handleOTPInput(e)}
                              value={this.state.mOTP}
                              className="form-control"
                              placeholder="Enter  OTP"
                              autoComplete={"none"}
                            />

                            <ResendOtp
                              fieldName={"phone"}
                              value={this.state?.contactForm['phone']}
                              country_code={this.state?.contactForm['country_code']}
                              resendotpFunction={this.sendOTP}
                            />

                          </div>
                          <div className="col-md-5 ">
                            <button className="btn btn-link btn-block text-success" type="button" onClick={(e) => { this.verifyMobileOtp(e) }}>Verify OTP</button>
                          </div>

                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group">
                      <input
                        id="email"
                        placeholder="Email"
                        className="form-control"
                        maxLength={"80"}
                        name="email"
                        size="20"
                        type="email"
                        onChange={(e) => this.handleUserInput(e)}
                        value={this.state.contactForm.email}
                        autoComplete={"none"}
                      />
                      <p
                        className="text-danger mb-0 text-small error"
                        id="emailError"
                      ></p>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="form-group">
                      {(!this.state.isEmailOTPSent && this.state.isEmailEditable) && (<button type="button" className="btn btn-link text-success" onClick={(e) => { this.sendOTP("email", this.state.contactForm.email) }}>Send OTP</button>)}

                      {(!this.state.isEmailOTPSent && !this.state.isEmailEditable) && (<button type="button" className="btn btn-link text-success">Verified</button>)}
                      {this.state.isEmailOTPSent && (
                        <div className="row">
                          <div className="col-md-7">
                            <input
                              type="number"
                              name="mailOTP"
                              id="mailOTP"
                              // onKeyUp={this.validateField('Email',10)}
                              onChange={(e) => this.handleOTPInput(e)}
                              value={this.state.mailOTP}
                              className="form-control"
                              placeholder="Enter OTP"
                              autoComplete={"none"}
                            />
                            <ResendOtp
                              fieldName={"email"}
                              value={this.state?.contactForm?.email ? this.state?.contactForm?.email : ""}
                              resendotpFunction={this.sendOTP}
                            />

                          </div>
                          <div className="col-md-5">
                            <button className="btn btn-link btn-block text-success" type="button"
                              onClick={(e) => { this.verifyEmailOtp(e) }}>Verify OTP</button>
                          </div>

                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      {/* <label htmlFor="type">Type</label> */}
                      <select id="00N5g00000Q7f5F" name="00N5g00000Q7f5F" className="form-control"
                        onChange={(e) => this.changeCategory(e)}
                        defaultValue={''}
                        required
                        autoComplete={"none"}
                      >
                        {/* <option value="">--None--</option> */}
                        <option value="">--Type--</option>
                        {categories.map((opItem, ind) => <option key={ind} value={opItem.title}>{opItem.title}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        id="00N5g00000Q7f5D"
                        name="00N5g00000Q7f5D"
                        title="Sub-Type"
                        className="form-control"
                        defaultValue={''}
                        required
                        autoComplete={"none"}
                      >
                        <option value="">--Sub-Type--</option>
                        {this.state.subCategroies.map((sopItem, indx) => <option key={indx} value={sopItem.title}>{sopItem.title}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        id="00N5g00000SKNf6"
                        maxLength={"255"}
                        name="00N5g00000SKNf6"
                        size="20"
                        type="text"
                        className="form-control"
                        placeholder="Registration Number"
                        autoComplete={"none"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="input-group">
                        {/* <input id="phone" maxlength="40" name="mobile" size="20" type="text" className="form-control mobileinput" placeholder="Mobile" /> */}
                        <input
                          id="subject"
                          maxLength={"80"}
                          name="subject"
                          size="20"
                          type="text"
                          className="form-control"
                          placeholder="Subject"
                          required
                          autoComplete={"none"}
                        />
                      </div>
                      {/*  <input id="mobile" maxlength="40" name="mobile" size="20" type="text" className="form-control" placeholder="Mobile" /> */}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea
                        name="description"
                        className="form-control"
                        placeholder="Description"
                        rows="5"
                        required
                        autoComplete={"none"}
                      ></textarea>
                    </div>
                  </div>
                </div>
                {/* Sub-Type: */}
                {/* T&amp; C: <input id="00NO0000008b97Q" name="00NO0000008b97Q" type="checkbox" value="1" /><br /> */}
                <div className="">
                  <label className="d-flex">
                    <div className=" form-check mr-2">
                      <input
                        value="1"
                        className="form-check-input position-static"
                        type="checkbox"
                        id="00NO0000008b97Q"
                        name="00NO0000008b97Q"
                        required
                      />
                    </div>
                    <p
                      style={{
                        fontSize: "13px",
                        marginBottom: 0,
                        marginTop: "4px",
                        lineHeight: "25px",
                      }}
                    > I accept <a href="t&c" target="_blank">
                        Terms & Conditions  {" "}
                      </a>
                      and {" "}
                      <a href="policies" target="_blank">
                        Privacy Policy
                      </a>
                    </p>
                  </label>
                  <p
                    className="text-danger mb-2 text-small error"
                    id="agreeError"
                  ></p>
                </div>
                {/* utm_medium: */}{" "}
                <input
                  id="00N5g00000Q7f5I"
                  maxLength={"255"}
                  name="00N5g00000Q7f5I"
                  size="20"
                  type="hidden"
                  className="form-control"
                />
                {/* utm_campaign: */}{" "}
                <input
                  id="00N5g00000Q7f5G"
                  maxLength={"255"}
                  name="00N5g00000Q7f5G"
                  size="20"
                  type="hidden"
                  className="form-control"
                />
                {/* utm_source: */}{" "}
                <input
                  id="00N5g00000SKNf6"
                  maxLength={"255"}
                  name="00N5g00000SKNf6"
                  size="20"
                  type="hidden"
                  className="form-control"
                />
                {/* utm_device: */}{" "}
                <input
                  id="00N5g00000Q7f5H"
                  maxLength={"255"}
                  name="00N5g00000Q7f5H"
                  size="20"
                  type="hidden"
                  className="form-control"
                />
                <input
                  style={{ width: "150px" }}
                  type="submit"
                  className="btn btn-primary nav-btn"
                  name="submit"
                />
              </form>

              {/* <form method="POST" id="enq-form">
                <p
                  id="formSuccess"
                  className="text-success mb-0 text-small"
                ></p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="firstName"
                        onKeyUp="validateField('firstName',3)"
                        id="firstName"
                        className="form-control"
                        placeholder="Name"
                      />
                      <p
                        className="text-danger mb-0 text-small error"
                        id="fnameError"
                      ></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="tel"
                        name="mobile"
                        onKeyUp="validateField('mobile',10)"
                        id="mobile"
                        className="form-control"
                        placeholder="Contact Number"
                      />
                      <p
                        className="text-danger mb-0 text-small error"
                        id="mobileError"
                      ></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <select name="class" className="form-control" id="class">
                        <option value="" selected disabled>
                          Class
                        </option>
                        <option value="IX">IX</option>
                        <option value="X">X</option>
                        <option value="XI">XI</option>
                        <option value="XII">XII</option>
                        <option value="XII Pass">XII Pass</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <select className="form-control" name="state" id="state">
                        <option value="Select State" selected>
                          Select State
                        </option>
                        <option value="Andaman &amp; Nicobar">
                          Andaman &amp; Nicobar
                        </option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="5"
                        placholder="Message"
                      ></textarea>
                    </div>
                    <p
                      className="text-danger mb-0 text-small error"
                      id="msgError"
                    ></p>
                  </div>
                </div>
                <div className="">
                  <label className="d-flex">
                    <div className=" form-check ">
                      <input
                        className="form-check-input position-static"
                        type="checkbox"
                        id="agree"
                      />
                    </div>
                    <p
                      style={{
                        fontSize: "13px",
                        marginBottom: 0,
                        lineHeight: "25px",
                      }}
                    >
                      I accept
                      <a href="t&c.php" target="_blank">

                        Terms & Conditions
                      </a>
                      and
                      <a href="policies.php" target="_blank">

                        Privacy Policy
                      </a>
                    </p>
                  </label>
                  <p
                    className="text-danger mb-2 text-small error"
                    id="agreeError"
                  ></p>
                </div>
                <div className="d-flex flex-wrap">
                  <div className="">
                    <button
                      style={{ width: "150px" }}
                      type="submit"
                      className="btn btn-primary nav-btn"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>*/}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ContactUsForm;
