import React from "react";

export default function CreatedDateGet(date) {
  let orderdt = new Date(date);
  let order_date =
    orderdt.getDate() +
    "/" +
    (orderdt.getMonth() + 1) +
    "/" +
    orderdt.getFullYear();
  return order_date;
}
