import React from "react";
import ContactImage from "../../images/form-bg.png";
// import { environment } from "../../Services/HttpData";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import country from "../../js/countries+states.json"
import { toast } from "react-toastify";
import ResendOtp from "../Resendotp/ResendOtp";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
const baseUrl = environment.baseUrl;
class HomeContactForm extends React.Component {
  constructor(props) {
    super(props);
    //console.log(country);
    // //console.log("Contact Form Called");
    this.state = {
      isMobileOTPSent: false,
      isMobileEditable: true,
      isEmailEditable: true,
      mOTP: "",
      isEmailOTPSent: false,
      mailOTP: "",
      isFormSubmited: false,
      pageContent: [],
      defaultValues: {
        FirstName: "",
        LastName: "",
        MiddleName: "",
        MobilePhone: "",
        Email: "",
        Home_Phone__c: "",
        State: "",
        Country: "India",
        City: "",
        Class__c: "",
        OTP_verification__c: false,
        Father_Name__c: "",
        Mother_Name__c: "",
        DOB__c: null,
        Gender__c: "",
        Category__c: "",
        LeadSource: "Website",
        Preffered_Language__c: "",
        Institute_Name__c: "",
        Tick_Agree_to_T_C__c: false,
        Form_Id__c: "enq-form",
        Form_URL__c: `${environment.REACT_APP_BASE_URL}`,
        Utm_Medium__c: "",
        utm_device__c: "",
        utm_Campaign__c: "",
        utm_source__c: "",
        Description: "",
        access_token: null,
        country_code: "91"
      },
      contactForm: {
        FirstName: "",
        LastName: "",
        MiddleName: "",
        MobilePhone: "",
        Email: "",
        Home_Phone__c: "",
        State: "",
        Country: "India",
        City: "",
        Class__c: "",
        OTP_verification__c: false,
        Father_Name__c: "",
        Mother_Name__c: "",
        DOB__c: null,
        Gender__c: "",
        Category__c: "",
        LeadSource: "Website",
        Preffered_Language__c: "",
        Institute_Name__c: "",
        Tick_Agree_to_T_C__c: false,
        Form_Id__c: "enq-form",
        Form_URL__c: `${environment.REACT_APP_BASE_URL}`,
        Utm_Medium__c: "",
        utm_device__c: "",
        utm_Campaign__c: "",
        utm_source__c: "",
        Description: "",
        access_token: null,
        country_code: "91",
        email_verified: false
      },
      statesList: [],
      submitButton: false
    };
    // this.doSubmitEnqForm=this.doSubmitEnqForm.bind(this)
  }
  componentWillMount() {
    this.setState({ statesList: country.filter(item => item.iso2 === "IN")[0].states });
  }
  validateForm() {
    //console.log(this.state.contactForm);
    if (this.state.contactForm.FirstName === "") {
      toast.error("First Name is Required");
      return false;
    }
    else if (this.state.contactForm.LastName === "") {
      toast.error("Last Name is Required");
      return false;
    }
    else if (this.state.contactForm.MobilePhone === "" && !this.state.contactForm.MobilePhone.length !== 10) {
      toast.error("Mobile is Required or Invalid");
      return false;
    }
    else if (this.state.contactForm.State === "") {
      toast.error("State is Required");
      return false;
    }
    else if (this.state.contactForm.Description === "") {
      toast.error("Message is required");
      return false;
    }
    else if (!this.state.contactForm.Tick_Agree_to_T_C__c) {
      toast.error("Please accept Terms first");
      return false;
    }
    else if (!this.state.contactForm.OTP_verification__c) {
      toast.error("Please Verify mobile first");
      return false;
    }
    else if (this.state.contactForm.Email !== "" && this.state.isEmailEditable) {
      toast.error("Please Verify Email first");
      return false;
    }
    else {
      return true;
    }
  }
  doSubmitEnqForm(e) {
    // //console.log(this.state.defaultValues);
    if (!this.validateForm()) {
      e.preventDefault();
      return;
    }
    const formData = this.state.contactForm;
    if (formData.MobilePhone.indexOf("+" + formData.country_code) < 0) {
      formData.MobilePhone = "+" + formData.country_code + "-" + formData.MobilePhone;
    }
    this.setState({ submitButton: true })
    fetch(process.env.REACT_APP_AUTH_URL + 'sales-force/create_allen_lead_prod', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + user.token,
      },
      body: JSON.stringify(formData)
    })
      .then(async response => {
        const data = await response.json();

        if (data.status === "success") {
          toast.success('Form Submited');
          // if (data?.data.success) {
          this.setState({ isFormSubmited: true, isEmailEditable: true, isMobileEditable: true });
          this.setState({ contactForm: this.state.defaultValues });
          // document.getElementById("enq-form").reset();
          document.querySelector("#contactForm\\.MobilePhone").value = "";
          document.querySelector("#Description").value = "";
          document.querySelector("#State").value = '';
          // }
          //console.log("Submited");
          // toast(data.replyMsg, { position: toast.POSITION.TOP_CENTER });
          // sessionStorage.removeItem('cart');
          // sessionStorage.removeItem('productDetails');
          // window.location.reload()
        }
        else {
          toast.error('Form Submission Failed');
        }
        this.setState({ submitButton: false })
      }).catch(() => {
        this.setState({ submitButton: false })
      })
  }
  componentWillReceiveProps(props) {
    // //console.log(props.handelEvent);
    this.setState({ pageContent: props.handelEvent });
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    var value = e.target.value;
    // //console.log(name, value);
    if (name === 'Tick_Agree_to_T_C__c' && value === 'true') {
      value = false;
    }
    else if (name === 'Tick_Agree_to_T_C__c' && value === 'false') {
      value = true;
    }
    /* if(name ==="Email")
    this.sendOTP(name,value); */
    // //console.log(name, value);
    // return;
    var contactForm = { ...this.state.contactForm }
    contactForm[name] = value;
    /* () => {
      this.validateField(name, value);
    } */
    this.setState({ contactForm: contactForm });
    // //console.log(this.state);
  };
  handleOTPInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({ [name]: value });
  };
  handleUserMobile = (fieldName, validate, value, countryData) => {
    //console.log(validate, value, countryData);
    // //console.log(name, value);
    // return;
    if (fieldName === "MobilePhone") {
      this.setState({ statesList: country.filter(item => item.iso2 === countryData.iso2.toUpperCase())[0].states });
    }
    if (validate && value.length === 10) {
      var contactForm = { ...this.state.contactForm }
      contactForm[fieldName] = value;
      contactForm['country_code'] = countryData.dialCode;
      this.setState({ contactForm: contactForm });
      // this.sendOTP(fieldName,value,countryData.dialCode);
    }
    else {
      toast.error("Please Enter Valid mobile number.");
    }
    // //console.log(this.state);
  };
  verifyEmailOtp(e) {
    if (this.state.mailOTP?.length < 6) {
      toast.warning("Please Enter valid OTP number");
      return false;
    }
    fetch(process.env.REACT_APP_ECOM_URL + 'otp/otp_verify_email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + user.token,
      },
      body: JSON.stringify({ "email": this.state.contactForm.Email, "otp": this.state.mailOTP, lead_type: 912 })
    })
      .then(async response => {
        const data = await response.json();
        //console.log(data);
        if (data.replyCode === "success") {
          this.setState({ isEmailOTPSent: false, isEmailEditable: false });
          var contactForm = { ...this.state.contactForm }
          contactForm.email_verified = true;
          this.setState({ contactForm: contactForm });
          //console.log("Email OTP Verified");
        }
        else {
          toast.error(data.replyMsg);
        }
      })
  }
  verifyMobileOtp(e) {
    if (this.state.mOTP?.length < 6) {
      toast.warning("Please Enter valid OTP number");
      return false;
    }
    fetch(process.env.REACT_APP_ECOM_URL + 'otp/otp_verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + user.token,
      },
      body: JSON.stringify({ "mobile": this.state.contactForm.MobilePhone, "otp": this.state.mOTP, lead_type: 912 })
    })
      .then(async response => {
        const data = await response.json();
        //console.log(data);
        if (data.replyCode === "success") {
          this.setState({ isMobileOTPSent: false, isMobileEditable: false });
          var contactForm = { ...this.state.contactForm }
          contactForm.OTP_verification__c = true;
          this.setState({ contactForm: contactForm });
          //console.log("Mobie OTP Verified");
        }
        else {
          toast.error(data.replyMsg);
        }
      })
  }
  async sendOTP(fieldName, value, country_code = null) {
    //console.log(fieldName, value, country_code);
    // return ;
    // POST request using fetch with async/await
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({})
    };
    var reqURL;
    if (fieldName === "MobilePhone" && value !== "") {
      if (value.length === 10) {
        reqURL = process.env.REACT_APP_ECOM_URL + "otp/otp_generate";
        requestOptions.body = JSON.stringify({ "mobile": value, "country_code": country_code, lead_type: 912 })
        fetch(reqURL, requestOptions)
          .then(async response => {
            const data = await response.json();
            // check for error response
            // //console.log(response);
            //console.log(data);
            if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
            if (data.replyCode === "success" && fieldName === "MobilePhone") {
              this?.setState({ isMobileOTPSent: true });
              toast?.success(data.replyMsg);
            }
          })
          .catch(error => {
            this?.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
          });
      }
      else {
        toast.error("Please Enter Valid mobile number.");
      }
    }
    else if (fieldName === "MobilePhone" && value === "") {
      toast.error("Please Enter Valid mobile number.");
    }
    else if (fieldName === "Email") {
      reqURL = process.env.REACT_APP_ECOM_URL + "otp/otp_generate_email";
      if (value.length >= 13) {
        requestOptions.body = JSON.stringify({ "email": value, lead_type: 912 })
        fetch(reqURL, requestOptions)
          .then(async response => {
            const data = await response.json();
            // check for error response
            // //console.log(response);
            //console.log(data);
            if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
            if (data.replyCode === "success" && fieldName === "Email") {
              this?.setState({ isEmailOTPSent: true });
              toast.success(data.replyMsg);
            } else {
              toast.error(data.replyMsg ? data.replyMsg : data.message);
            }
          })
          .catch(error => {
            this.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
          });
      }
      else {
        toast.error("Please enter valid Email")
      }
    }
    //console.log(reqURL, requestOptions);
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let mobileValid = this.state.mobileValid;
    let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case "mobile":
        mobileValid = value.match(/^\d{10}$/);
        // mobileValid = value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
        fieldValidationErrors.mobile = mobileValid ? "" : " is invalid";
        break;
      case "password":
        passwordValid = value !== "";
        fieldValidationErrors.password = passwordValid ? "" : " is required";
        break;
    }
    this.setState(
      {
        mobileValid: mobileValid,
        passwordValid: passwordValid,
      },
      this.validateForm
    );
  }
  render() {
    const formSbmit = {
      width: "150px",
    };
    return (
      <>
        <div className="form-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="form-card btn-colors">
                  <div className="row align-items-center">
                    <div className="col-12 col-lg-6">
                      <img
                        src={
                          this.state.pageContent?.admin_image
                            ? environment.baseUrlImg +
                            this.state.pageContent?.admin_image
                            : ContactImage
                        }
                        alt=""
                        className="img-fluid d-block mx-auto"
                      />
                    </div>
                    <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                      <div className="sub-heading"><h3 className="text-left">{this.state.pageContent?.title}</h3></div>

                      <form className="mt-3 mt-md-4" method="POST" id="enq-form" ref={(el) => this.myFormRef = el}>
                        {this.state.isFormSubmited && <div className="alert alert-success alert-dismissible fade show" role="alert">
                          <strong>Success</strong> Form Submited successfully.
                          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>}
                        <input
                          type="hidden"
                          name="LeadSource"
                          id="LeadSource"
                          onChange={(e) => this.handleUserInput(e)}
                          value="Website"
                          className="form-control"
                        />
                        <input
                          type="hidden"
                          name="Form_Id__c"
                          id="Form_Id__c"
                          value="enq-form"
                          className="form-control"
                        />
                        <input
                          type="hidden"
                          name="Form_URL__c"
                          id="Form_URL__c"
                          value={environment.REACT_APP_BASE_URL}
                          className="form-control"
                        />
                        <p
                          id="formSuccess"
                          className="text-white mb-0 text-small"
                        ></p>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              {/*  onKeyUp="validateField('firstName',3)" */}
                              <input
                                type="text"
                                id="FirstName"
                                name="FirstName"
                                onChange={(e) => this.handleUserInput(e)}
                                value={this.state?.contactForm?.FirstName ? this.state?.contactForm?.FirstName : ""}
                                className="form-control"
                                placeholder="First Name"
                                autoComplete="none"
                              />
                              <p
                                className="text-danger mb-0 text-small error"
                                id="fnameError"
                              ></p>
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            id="middleName"
                            name="MiddleName"
                            onChange={(e) => this.handleUserInput(e)}
                            value={this.state.contactForm.MiddleName}
                            className="form-control"
                            placeholder="Middle Name"
                          />
                          <p
                            className="text-danger mb-0 text-small error"
                            id="fnameError"
                          ></p>
                        </div>
                      </div> */}
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                id="LastName"
                                name="LastName"
                                onChange={(e) => this.handleUserInput(e)}
                                value={this.state?.contactForm?.LastName ? this.state?.contactForm?.LastName : ""}
                                className="form-control"
                                placeholder="Last Name"
                                autoComplete="none"
                              />
                              <p
                                className="text-danger mb-0 text-small error"
                                id="fnameError"
                              ></p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <IntlTelInput
                                containerClassName="intl-tel-input w-100"
                                inputClassName="form-control"
                                fieldName="contactForm.MobilePhone"
                                fieldId="contactForm.MobilePhone"
                                onPhoneNumberBlur={(validate, value, countryData) => this.handleUserMobile("MobilePhone", validate, value, countryData)}
                                preferredCountries={["in", "us"]}
                                placeholder="Contact No."
                                disabled={!this.state?.isMobileEditable}
                                autoComplete="none"
                              />

                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              {(!this.state?.isMobileOTPSent && this.state?.isMobileEditable) &&
                                (<button type="button" className="btn btn-link text-white" onClick={(e) => { this.sendOTP("MobilePhone", this.state?.contactForm['MobilePhone'], this.state?.contactForm['country_code']) }}>Send OTP</button>)}

                              {(this.state?.contactForm?.OTP_verification__c) && (<button type="button" className="btn btn-link text-white">Verified</button>)}
                              {/* <p
                            className="text-danger mb-0 text-small error"
                            id="mobileError"
                          ></p> */}
                              {this.state?.isMobileOTPSent && (
                                <div className="row">
                                  <div className="col-md-6">
                                    <input
                                      type="number"
                                      name="mOTP"
                                      id="mOTP"
                                      // onKeyUp={this.validateField('Email',10)}
                                      onChange={(e) => this.handleOTPInput(e)}
                                      value={this.state.mOTP}
                                      className="form-control"
                                      placeholder="Enter OTP"
                                      autoComplete={"none"}
                                    />
                                    <ResendOtp
                                      fieldName={"MobilePhone"}
                                      value={this.state?.contactForm['MobilePhone']}
                                      country_code={this.state?.contactForm['country_code']}
                                      resendotpFunction={this.sendOTP}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <button className="btn btn-link btn-block text-white" type="button" onClick={(e) => { this.verifyMobileOtp(e) }}>Verify OTP</button>
                                  </div>

                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="email"
                                name="Email"
                                id="Email"
                                // onKeyUp={this.validateField('Email',10)}
                                onChange={(e) => this.handleUserInput(e)}
                                value={this.state?.contactForm?.Email ? this.state?.contactForm?.Email : ""}
                                className="form-control"
                                placeholder="Email"
                                readOnly={!this?.state?.isEmailEditable}
                                autoComplete={"none"}
                              />

                              {/* <p
                            className="text-danger mb-0 text-small error"
                            id="emailError"
                          ></p>
                          {this.state.isEmailOTPSent && (
                            <div className="row">
                              <div className="col-md-7">
                                <input
                                  type="number"
                                  name="mailOTP"
                                  id="mailOTP"
                                  // onKeyUp={this.validateField('Email',10)}
                                  onChange={(e) => this.handleOTPInput(e)}
                                  value={this.state.mailOTP}
                                  className="form-control"
                                  placeholder="Enter Email OTP"
                                  autoComplete={"off"}
                                />

                              </div>
                              <div className="col-md-5">
                                <button className="btn btn-link btn-block text-white" type="button"
                                  onClick={(e) => { this.verifyEmailOtp(e) }}>Verify OTP</button>
                              </div>

                            </div>
                          )} */}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              {(!this.state?.isEmailOTPSent && this.state?.isEmailEditable) && (<button type="button" className="btn btn-link text-white" onClick={(e) => { this.sendOTP("Email", this.state?.contactForm?.Email) }}>Send OTP</button>)}

                              {(!this?.state?.isEmailOTPSent && !this?.state?.isEmailEditable) && (<button type="button" className="btn btn-link text-white">Verified</button>)}
                              {this.state?.isEmailOTPSent && (
                                <div className="row">
                                  <div className="col-md-7">
                                    <input
                                      type="number"
                                      name="mailOTP"
                                      id="mailOTP"
                                      // onKeyUp={this.validateField('Email',10)}
                                      onChange={(e) => this.handleOTPInput(e)}
                                      value={this?.state?.mailOTP}
                                      className="form-control"
                                      placeholder="Enter OTP"
                                      autoComplete={"none"}
                                    />
                                    <ResendOtp
                                      fieldName={"Email"}
                                      value={this.state?.contactForm?.Email ? this.state?.contactForm?.Email : ""}
                                      resendotpFunction={this.sendOTP}
                                    />
                                  </div>
                                  <div className="col-md-5">
                                    <button className="btn btn-link btn-block text-white" type="button"
                                      onClick={(e) => { this.verifyEmailOtp(e) }}>Verify OTP</button>
                                  </div>

                                </div>
                              )}
                            </div>
                          </div>
                          {/*<div className="col-md-6">
                        <div className="form-group">
                          <IntlTelInput
                            containerClassName="intl-tel-input"
                            inputClassName="form-control"
                            fieldName="contactForm.Home_Phone__c"
                            fieldId="contactForm.Home_Phone__c"
                            onPhoneNumberBlur={(validate, value, countryData) => this.handleUserMobile("Home_Phone__c", validate, value, countryData)}
                            preferredCountries={["in", "us"]}
                            placeholder="Home Phone"
                          />
                          <p
                            className="text-danger mb-0 text-small error"
                            id="emailError"
                          ></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="City"
                            id="City"
                            onChange={(e) => this.handleUserInput(e)}
                            value={this.state.contactForm.City}
                            className="form-control"
                            placeholder="City"
                          />
                          <p
                            className="text-danger mb-0 text-small error"
                            id="CityError"
                          ></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <select
                            name="Category__c"
                            className="form-control"
                            id="Category__c"
                            onChange={(e) => this.handleUserInput(e)}
                            defaultValue={''}
                          >
                            <option value="" disabled>Category</option>
                            <option value="General">General</option>
                            <option value="OBC">OBC</option>
                            <option value="SC/ST">SC/ST</option>
                            <option value="other">Other</option>
                          </select>
                          <p
                            className="text-danger mb-0 text-small error"
                            id="ClassError"
                          ></p>
                        </div>
                      </div>
                       <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="Institute_Name__c"
                            id="Institute_Name__c"
                            onChange={(e) => this.handleUserInput(e)}
                            value={this.state.contactForm.Institute_Name__c}
                            className="form-control"
                            placeholder="Institute Name"
                          />
                          <p
                            className="text-danger mb-0 text-small error"
                            id="CityError"
                          ></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <select
                            name="Preffered_Language__c"
                            className="form-control"
                            id="Preffered_Language__c"
                            onChange={(e) => this.handleUserInput(e)}
                            defaultValue={''}
                          >
                            <option value="" disabled>Select Language</option>
                            <option value="hindi">Hindi</option>
                            <option value="english">English</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <select
                            name="Gender__c"
                            className="form-control"
                            id="Gender__c"
                            onChange={(e) => this.handleUserInput(e)}
                            defaultValue={''}
                          >
                            <option value="" disabled>Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                      </div> 
                      <div className="col-md-6">
                        <div className="form-group">
                          <select
                            name="Class__c"
                            className="form-control"
                            id="Class__c"
                            onChange={(e) => this.handleUserInput(e)}
                            defaultValue={''}
                          >
                            <option value="" disabled>Class</option>
                            <option value="1st">1st</option>
                            <option value="2nd">2nd</option>
                            <option value="3rd">3rd</option>
                            <option value="4th">4th</option>
                            <option value="5th">5th</option>
                            <option value="6th">6th</option>
                            <option value="7th">7th</option>
                            <option value="8th">8th</option>
                            <option value="9th">9th</option>
                            <option value="10th">10th</option>
                            <option value="11th">11th</option>
                            <option value="12th">12th</option>
                          </select>
                        </div>
                      </div> */}
                          <div className="col-md-12">
                            <div className="form-group">
                              <select
                                className="form-control"
                                name="State"
                                id="State"
                                onChange={(e) => this.handleUserInput(e)}
                                defaultValue={''}
                                autoComplete={"off"}
                              >
                                <option value="">
                                  Select State
                                </option>
                                {this.state.statesList.map((option) => (
                                  <option key={option.id} value={option.name}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <textarea
                                name="Description"
                                id="Description"
                                className="form-control"
                                rows="5"
                                placeholder="Message"
                                onChange={(e) => this.handleUserInput(e)}
                                value={this?.state?.contactForm?.Description}
                              ></textarea>
                            </div>
                            <p
                              className="text-danger mb-0 text-small error"
                              id="msgError"
                            ></p>
                          </div>
                        </div>
                        <div className="">
                          <label className="d-flex">
                            <div className=" form-check mr-2">
                              <input
                                className="form-check-input position-static"
                                type="checkbox"
                                name="Tick_Agree_to_T_C__c"
                                onChange={(e) => this.handleUserInput(e)}
                                value={this?.state.contactForm?.Tick_Agree_to_T_C__c}
                                id="Tick_Agree_to_T_C__c"
                              />
                            </div>
                            <p className="terms">
                              I accept {" "}
                              <a href="t&c" target="_blank">Terms & Conditions</a> and <a href="policies" target="_blank">Privacy Policy</a>
                            </p>
                          </label>
                          <p
                            className="text-danger mb-2 text-small error"
                            id="agreeError"
                          ></p>
                        </div>
                        <div className="d-flex flex-wrap">
                          <div className="">
                            <button
                              style={formSbmit}
                              type="button"
                              className="btn btn-primary nav-btn"
                              onClick={(e) => { this.doSubmitEnqForm(e) }}
                              disabled={this?.state?.submitButton}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HomeContactForm;
