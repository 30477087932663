import React from "react";
import jwt_decode from "jwt-decode";
// import { environment } from "../../Services/HttpData";
import { toast } from "react-toastify";
import { Redirect ,withRouter} from "react-router";
//import { Route, useParams,useLocation  } from "react-router-dom";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class AuthComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    
   
   
    /* if (this.getUrlParameter('session_state')) {
            sessionStorage.setItem("session_state", this.getUrlParameter('session_state'));
        }; */
    if (this.getUrlParameter("code")) {
      this.getToken(this.getUrlParameter("code"));
      // sessionStorage.setItem("code", this.getUrlParameter('code'));
      // window.location = "/";
    }
  }
  getUrlParameter(sParam) {
    // ////console.log(window.location.hash);
    var url = document.location.href;
    var qs = url.substring(url.indexOf("#") + 1).split("&");
    for (var i = 0, result = {}; i < qs.length; i++) {
      qs[i] = qs[i].split("=");
      result[qs[i][0]] = decodeURIComponent(qs[i][1]);
      if (qs[i][0] === sParam) return result[qs[i][0]];
    }
    /*
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] === sParam) {
                return sParameterName[1];
            }
        } */
  }
  async getToken(code) {
    // POST request using fetch with async/await
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        grant_type: "authorization_code",
        client_id: environment.REACT_APP_IDENTITY_CLIENT_ID,
        //redirect_uri: environment.REACT_APP_REDIRECT_URL,
        redirect_uri: document.location.href.split('#')[0],
        code: code,
        url:`${process.env.REACT_APP_LMS_URL}auth/realms/allendigital/protocol/openid-connect/token`,
      }),
    };
    fetch(environment.baseUrl + "callAPI", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data = /* isJson && */ await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        if (data.status === 200) {
          localStorage.setItem("userTokenDetails", JSON.stringify(data.body));
          this.decodeToken(data.body.access_token);
        } else {
          toast.error(data.body.error_description);
        }
        ////console.log(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  decodeToken(token) {
    var decoded = jwt_decode(token);
    if (decoded.wid !== undefined) {
      this.getWid(false,decoded.wid);

    }
    else if (decoded.sub !== undefined) {
      this.getWid(decoded.sub,false);
    }
     
  }
  async getWid(kid,wid) {
    // POST request using fetch with async/await
    let new_condition = {
      kid: kid,
    }
    if(wid){
      new_condition = {
        wid: wid,
      }
    }
    
    
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        source_fields: ["wid", "first_name", "last_name", "alternate_email","contact_phone_number_personal"],
        conditions: new_condition,
      }),
    };
    fetch(environment.baseUrl + "getWidBusinessSite", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data = /* isJson && */ await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        if (data.status === 200) {
          

          //ECOM 
          let wid_data = data.body;
          let wid =   null;
          if(wid_data){
              if(wid_data.results){
                  if(wid_data.results.length){
                      if(wid_data.results[0].wid){
                          wid = wid_data.results[0].wid;
                      }
                  }
              }
          }
          if(wid){
            localStorage.setItem("userWID", JSON.stringify(data.body));
            const requestOptionsSync = {
              method: "POST",
              headers: {
                'Content-Type' : "application/json",
                'Authorization': localStorage.getItem("apiAccessToken")?"Basic " + localStorage.getItem("apiAccessToken"):null,
              },
              body: JSON.stringify({
                wid: wid
              }),
            };
            fetch(environment.ecomApiUrl + "user/sync", requestOptionsSync)
            .then(async (response) => {
              const jwdata =  await response.json();
              if(jwdata.status == 'success'){
                  if( jwdata.data.auth_token ){
                    localStorage.setItem("apiAccessToken", jwdata.data.auth_token );
                  }
              }
              

                this.props.onChange(data.body.results[0]);
                toast.success("Login Successfull. You will redirected to Home page.");
                setTimeout(() => {
                  let args =  (document.location.href.split('#')[0].split('/') )
                  
                  let page = args[(args.length - 1)]
                 
               
                    if(page == "checkout"){
                      window.location = "/ecom/cart";
                    }else{
                      window.location = "/";
                    }

                 
                  
                }, 2500);
            })
            .catch((error) => {
              console.error("There was an error!", error);
            });

          }
          else{
            toast.error("Please Login First")
          }

          // this.props.onChange(data.body.results[0]);
          // toast.success("Login Successfull. You will redirected to Home page.");
          // setTimeout(() => {
          //   window.location = "/";
          // }, 2500);
        }
        ////console.log(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  render() {
    return (
      <>
        <h5 className="text-center mt-3">Loading Please wait...</h5>
        {/* <ToastContainer delay={3000} position={'bottom-right'}/> */}
      </>
    );
  }
}

export default AuthComponent;
