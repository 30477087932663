import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useState } from 'react';

const WebinarsLiveSessionsNeet = () => {

    const [webinarData, setWebinarData] = useState([]);
    const [previous, setPrevious] = useState([]);
    let serialNumber = 0;

    const options = {
        autoplay: true,
        loop: true,
        infinite: false,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        //navText: ["Prev", "Next"],
        smartSpeed: 800,
        margin: 0,
        responsive: {
            0: {
                items: 1,
                margin: 100,
            },
            768: {
                items: 3,
                slideBy: 3,
            },
            992: {

                items: 3,
                slideBy: 3,
            }
        },
    };
    const scroll = () => {
        let e = document.getElementById("scrolltarget");
        e.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'start'
        });
    }


    function mapdata() {
        if (window?.NeetWebinarsLiveSessions) {
            const updatedWebinar = []
            const previousWebinar = []
            window?.NeetWebinarsLiveSessions.map((item, i) => {
                let currentdate = new Date();
                let currenttime = new Date().getTime();
                if ((new Date(item?.developer_time) < currentdate) && (new Date(item?.developer_time).getTime() < currenttime)) {
                    item.is_upcoming = false;
                    updatedWebinar.push(item);
                    previousWebinar.push(item)
                } else {
                    updatedWebinar.push(item)
                    previousWebinar.push(item)
                }
            })
            setWebinarData(updatedWebinar)
            setPrevious(previousWebinar.reverse())
        }
        else {
            setTimeout(() => {
                mapdata();
            }, 100)
        }
    }
    useEffect(() => {
        mapdata()
    }, [])
    return (

        <div>
            <Helmet>
                {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
                <title>Webinars & Live Sessions for NEET-UG by ALLEN Experts</title>
                <meta
                    name="description"
                    content="Join ALLEN Digital's exclusive live webinar sessions to enhance your understanding of the NEET-UG Exam and improve your chances of success."
                />
                {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
                {/* <script>{(function (w, d, s, l, i) {
                    w[l] = w[l] || []; w[l].push({
                        'gtm.start':
                            new Date().getTime(), event: 'gtm.js'
                    }); var f = d.getElementsByTagName(s)[0],
                        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', 'GTM-TZXF9P5')
                }
                </script> */}
            </Helmet>
            {/* <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TZXF9P5" height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe></noscript> */}
            <div className="webinar-banner pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="webinar-title">Webinars & Live Sessions for NEET-UG <br className='d-none d-lg-block' /> by ALLEN Experts</div>
                            <div className="webinar-subtitle">Exclusive Live Webinar Sessions to Discover the Winning Strategies for NEET-UG Success!</div>
                            <div className="webinar-description"><b>ALLEN Digital</b> is here with live sessions, webinars, talk shows and virtual events to unlock the doors to success and <br className='d-none d-lg-block' />take you a step closer to your dream medical career.
                            </div>
                            {/* <img onClick={scroll} className='webinarbigbanner' src="https://cdn.allendigital.in/fe-bsite/result_page/1129x350_.png"></img> */}
                            <p id="scrolltarget" style={{ height: "0px" }}></p>
                        </div>
                    </div>
                </div>
            </div>




            <div className="webinar-table-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="table-title text-center">Upcoming Sessions</div>
                        </div>
                        <div className="col-12 mt-4">
                            <div className="table-wrappers">
                                {/* <div class="jee-scholarship-table-title">MENTIQUIZZES</div> */}
                                <div class="table-responsive">
                                    <table class="table table-bordered jee-scholarship-table white-space-normal">
                                        <thead>
                                            <tr className="border-top">
                                                {/* <th width="8%">S. No.</th> */}

                                                <th width="40%">Particulars</th>
                                                <th width="15%">Date</th>
                                                {/* <th width="22%">Subject</th> */}
                                                <th width="14%">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <span style={{ display: "none" }}>{serialNumber = 0}</span>
                                            {
                                                webinarData.map((item, i) => {
                                                    return (
                                                        <>
                                                            {
                                                                item.is_upcoming == true ?
                                                                    (

                                                                        <tr>
                                                                            <span style={{ display: "none" }}>{serialNumber += 1}</span>
                                                                            {/* <td>{serialNumber += 1}</td> */}

                                                                            <td>{item.perticulars}</td>
                                                                            <td>{item.date}</td>
                                                                            {/* <td>{item.subject}</td> */}
                                                                            <td>{item.registration_link?.length > 0 ? (<a href={item.registration_link} target='blank' className='register-now'><span>Register Now</span> <img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/know_more.png'></img></a>) : ""}</td>
                                                                        </tr>
                                                                    ) : ""
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                            {
                                                serialNumber == 0 ? (
                                                    <tr>
                                                        <td colSpan={"5"} style={{ textAlign: "center" }}>Stay Tuned For Upcoming Sessions</td>
                                                    </tr>
                                                ) : ""
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 my-3 text-center">
                            <a target="_blank" href="https://cloud.engage.allendigital.in/RegisterforNEETwebinar" class="btn btn-success">Register Here</a>
                        </div>

                        <div className="col-12 mt-3 mt-md-4">
                            <div className="table-title text-center">Previous Sessions</div>
                        </div>
                        {/* <div className="col-12 mt-4">
                            <div className="table-wrappers">
                                <div class="jee-scholarship-table-title">MENTORSHIP SERIES</div>
                                <div className="table-responsive">
                                <div class="table-responsive">
                                <table class="table table-bordered jee-scholarship-table white-space-normal">
                                    <thead>
                                        <tr>
                                            <th width="8%">S. No.</th>
                                            
                                            <th width="40%">Particulars</th>
                                            <th width="15%">Date</th>
                                            <th width="22%">Subject</th>
                                            <th width="18%">Session Link</th>
                                            <th width="18%">PDF Booklet</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <span style={{display:"none"}}>{serialNumber = 0}</span>
                                        {
                                            webinarData.map((item, i) => {
                                                return(
                                                    <>    
                                                        {
                                                        item.is_upcoming == false && item.category == "mentorship_series" ?
                                                            (
                                                                <tr>
                                                                <td>{serialNumber += 1}</td>
                                                                
                                                                <td>{item.perticulars}</td>
                                                                <td>{item.date}</td>
                                                                <td>{item.subject}</td>
                                                                <td>{item.webinar_link?.length > 0? (<a href={item.webinar_link} target='blank' className='register-now'><span>Click Here</span> <img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/know_more.png'></img></a>) : ""}</td>
                                                                <td>{item.booklet_link?.length > 0 ? (<a href={item.booklet_link} target='blank' className = 'register-now'><span>Download</span><img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/know_more.png'></img></a>) : ""}</td>
                                                                </tr> 
                                                            ):""
                                                        }
                                                    </>
                                                )
                                            })
                                        }    
                                    </tbody>
                                </table>
                                </div>
  </div>
</div>
                            </div> */}
                        {/* <div className="col-12 mt-2">
                            <div className="table-wrappers">
                                <div class="jee-scholarship-table-title">WINNER ONLINE TEST SERIES</div>
                                <div className="table-responsive">
                                <div class="table-responsive">
                                <table class="table table-bordered jee-scholarship-table white-space-normal">
                                    <thead>
                                        <tr>
                                        // <th width="8%">S. No.</th>
                                            
                                            <th width="40%">Paper</th>
                                            <th width="15%">Date</th>
                                            <th width="22%">Subject</th>
                                            <th width="18%">Session Link</th>
                                            <th width="18%">PDF Booklet</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <span style={{display:"none"}}>{serialNumber = 0}</span>
                                    {
                                            webinarData.map((item, i) => {
                                                return(
                                                    <>    
                                                        {
                                                        item.is_upcoming == false && item.category == "winnerolts" ?
                                                            (
                                                                <tr>
                                                                // <td>{serialNumber += 1}</td>
                                                                
                                                                <td>{item.perticulars}</td>
                                                                <td>{item.date}</td>
                                                                <td>{item.subject}</td>
                                                                <td>{item.webinar_link?.length > 0 ? (<a href={item.webinar_link} target='blank' className='register-now'><span>Click Here</span> <img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/know_more.png'></img></a>) : ""}</td>
                                                                <td>{item.booklet_link?.length > 0 ? (<a href={item.booklet_link} target='blank' className = 'register-now'><span>Download</span><img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/know_more.png'></img></a>) : ""}</td>
                                                                </tr> 
                                                            ):""
                                                        }
                                                    </>
                                                )
                                            })
                                        }   
                                    </tbody>
                                </table>
                                </div>
  </div>
</div>
                            </div> */}
                        <div className="col-12 mt-4">
                            <div className="table-wrappers">
                                {/* <div class="jee-scholarship-table-title">MENTIQUIZZES</div> */}
                                <div class="table-responsive">
                                    <table class="table table-bordered jee-scholarship-table white-space-normal">
                                        <thead>
                                            <tr className="border-top">
                                                {/* <th width="8%">S. No.</th> */}

                                                <th width="40%">Particulars</th>
                                                <th width="15%">Date</th>
                                                {/* <th width="22%">Subject</th> */}
                                                <th width="18%">Session Link</th>
                                                {/* <th width="18%">PDF Booklet</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <span style={{ display: "none" }}>{serialNumber = 0}</span>
                                            {
                                                previous.map((item, i) => {
                                                    return (
                                                        <>
                                                            {
                                                                item.is_upcoming == false ?
                                                                    (
                                                                        <tr>
                                                                            {/* <td>{serialNumber += 1}</td> */}

                                                                            <td>{item.perticulars}</td>
                                                                            <td>{item.date}</td>
                                                                            {/* <td>{item.subject}</td> */}
                                                                            <td>{item.webinar_link?.length > 0 ? (<a href={item.webinar_link} target='blank' className='register-now'><span>Click Here</span> <img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/know_more.png'></img></a>) : ""}</td>
                                                                            {/* <td>{item.booklet_link?.length > 0 ? (<a href={item.booklet_link} target='blank' className = 'register-now'><span>Download</span><img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/know_more.png'></img></a>) : ""}</td> */}
                                                                        </tr>
                                                                    ) : ""
                                                            }
                                                        </>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12 mt-2">
                            <div className="table-wrappers">
                                <div class="jee-scholarship-table-title">Toppers' Talk Show</div>
                                <div className="table-responsive">
                                <div class="table-responsive">
                                <table class="table table-bordered jee-scholarship-table mb-0 white-space-normal">
                                    <thead>
                                        <tr>
                                        <th width="8%">S. No.</th>
                                            
                                            <th width="40%">Particulars</th>
                                            <th width="15%">Date</th>
                                            <th width="22%">Subject</th>
                                            <th width="18%">Session Link</th>
                                            <th width="18%">PDF Booklet</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <span style={{display:"none"}}>{serialNumber = 0}</span>
                                    {
                                            webinarData.map((item, i) => {
                                                return(
                                                    <>    
                                                        {
                                                        item.is_upcoming == false && item.category == "topperstalk" ?
                                                            (
                                                                <tr>
                                                                <td>{serialNumber += 1}</td>
                                                                
                                                                <td>{item.perticulars}</td>
                                                                <td>{item.date}</td>
                                                                <td>{item.subject}</td>
                                                                <td>{item.webinar_link?.length > 0 ? (<a href={item.webinar_link} target='blank' className='register-now'><span>Click Here</span> <img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/know_more.png'></img></a>) : ""}</td>
                                                                <td>{item.booklet_link?.length > 0 ? (<a href={item.booklet_link} target='blank' className = 'register-now'><span>Download</span><img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/know_more.png'></img></a>) : ""}</td>
                                                                </tr> 
                                                            ):""
                                                        }
                                                    </>
                                                )
                                            })
                                        }        
                                      
                                    </tbody>
                                </table>
                                </div>
  </div>
</div>
                            </div> */}
                    </div>
                </div>
            </div>

            <div className="webinar-benefits">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="top-title text-center">Here are just a few of the many</div>
                            <div className="table-title text-center">Benefits of Joining ALLEN Digital's Webinars</div>
                        </div>
                        <div className="col-12 col-xl-10 mx-auto mt-2 mt-md-5">
                            <div className="row no-gutters">
                                <div className="col-12 col-md-6">
                                    <img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/wb_desktop.png' className='img-fluid d-none d-md-block'></img>
                                    <img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/wb_mobile.png' className='img-fluid mx-auto d-block d-md-none'></img>
                                </div>
                                <div className="col-12 col-md-6 margin-left-minus">
                                    <div className="list">
                                        <div className="icon">1</div>
                                        Learn Effective Preparation Strategies from Toppers and Industry Experts
                                    </div>
                                    <div className="list">
                                        <div className="icon">2</div>
                                        Unlock Topper's Secrets to Success
                                    </div>
                                    <div className="list">
                                        <div className="icon">3</div>
                                        Learn at Your Convenience with Our Exclusive Live Sessions & Webinars
                                    </div>
                                    <div className="list">
                                        <div className="icon">4</div>
                                        Interact with India's Best Faculties and Get Your Questions Answered in Real-time
                                    </div>
                                    <div className="list">
                                        <div className="icon">5</div>
                                        Stay Updated with the Latest Exam Changes
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="live-session-webinar-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="ls-title text-center"><span>
                                Get Benefited from ALLEN Digital's Exclusive</span><br /> Live Sessions and Webinars</div>
                        </div>
                        <div className="col-12 text-center">
                            <a href='https://cloud.engage.allendigital.in/RegisterforNEETwebinar' target='_blank' className='register-session'>Register Here for Upcoming Sessions
                                <img src='https://cdn.digital.allen.ac.in/fe-bsite/result_page/hand.png' className='hand'></img>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="webinar-vidoes">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div class="table-title text-center">Webinars Highlights</div>
                        </div>
                        <div className="col-12">
                            <OwlCarousel className='owl-theme'  {...options}>
                                <div className="webinar-item">
                                    <a href="https://www.youtube.com/watch?v=kBZ52MF-jPE" target="_blank">
                                        <img src='https://img.youtube.com/vi/kBZ52MF-jPE/sddefault.jpg'>

                                        </img>
                                    </a>
                                </div>
                                <div className="webinar-item">
                                    <a href="https://www.youtube.com/watch?v=dgupRQU0pl0" target="_blank">
                                        <img src='https://i3.ytimg.com/vi/dgupRQU0pl0/hqdefault.jpg'>

                                        </img>
                                    </a>
                                </div>
                                <div className="webinar-item">
                                    <a href="https://www.youtube.com/watch?v=6iPJNmC7a5Q" target="_blank">
                                        <img src='https://i3.ytimg.com/vi/6iPJNmC7a5Q/hqdefault.jpg'>

                                        </img>
                                    </a>
                                </div>

                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default WebinarsLiveSessionsNeet;