import React from "react";
import { toast } from "react-toastify";
// import { environment } from "../../Services/HttpData";

const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class CartDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartDetails: null,
      couponCode: "",
    };
  }
  componentWillReceiveProps(props) {
    ////console.log(props.handelEvent);
    this.setState({ cartDetails: props.handelEvent });
  }
  applyCoupon(e) {
    const skvDetails = JSON.parse(sessionStorage.getItem("skavaTokenDetails"));
    // ////console.log(skvDetails.sessionId);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apiKey: skvDetails.sessionId,
      },
      body: JSON.stringify([{ code: this.state.couponCode }]),
    };
    fetch(environment.baseUrl + "applyCouponSkSite", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data = /* isJson && */ await response.json();
        // check for error response
        // ////console.log(response);
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        if (response.ok) {
          ////console.log(response);
          if (response.status === 200) {
            toast.success("Coupon Applied successfully");
            this.setState({couponCode:""});
          } else toast.error(data.message);
          // this.setState({cartItems:data.items,cartDetails:{value:data.value,currencySign:data.currencySign}})
          //   ////console.log(data);
        }
      })
      .catch((error) => {
        this.setState({couponCode:""});
        toast.error(error);
      });
  }

  handleOTPInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({ [name]: value });
  };
  render() {
    return (
      <>
        <div className="col-12 col-md-3 p-4 order-summary pt-5">
          <h6 className="font-weight-bold cart-summery">Order Summary</h6>
          <div className="row m-0">
            <p className="cart-label">Product Total</p>
            <p className="font-weight-bold ml-auto cart-price">
              {this.state.cartDetails?.currencySign === "INR"
                ? "₹"
                : this.state.cartDetails?.currencySign}{" "}
              {this.state.cartDetails?.value?.originalPrice
                ? this.state.cartDetails?.value?.originalPrice
                : 0}
            </p>
          </div>
          <div className="row m-0">
            <p className="cart-label">Tax</p>
            <p className="font-weight-bold ml-auto cart-price">
              {this.state.cartDetails?.currencySign === "INR"
                ? "₹"
                : this.state.cartDetails?.currencySign}{" "}
              {this.state.cartDetails?.value?.taxOnPrice
                ? this.state.cartDetails?.value?.taxOnPrice
                : 0}
            </p>
          </div>
          <hr />
          <div className="row m-0">
            <p className="cart-label">Total</p>
            <p className="font-weight-bold ml-auto cart-price">
              {this.state.cartDetails?.currencySign === "INR"
                ? "₹"
                : this.state.cartDetails?.currencySign}{" "}
              {this.state.cartDetails?.value?.grandTotal
                ? this.state.cartDetails?.value?.grandTotal
                : 0}
            </p>
          </div>
          <div className="row m-0">
            <form className="w-100">
              <div className="form-group">
                <label className="cart-label">Have coupon?</label>
                <div className="input-group cart-coupon-box">
                  <input
                    type="text"
                    className="form-control coupon"
                    name="couponCode"
                    value={this.state.couponCode}
                    placeholder="Coupon code"
                    onChange={(e) => this.handleOTPInput(e)}
                    autoComplete={"off"}
                  />
                  <span className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-apply coupon"
                      disabled={!this.state.couponCode}
                      onClick={(e) => {
                        this.applyCoupon(e);
                      }}
                    >
                      Apply
                    </button>
                  </span>
                </div>
              </div>
              <button type="button" className="btn btn-checkout mt-4">
                Proceed to Checkout
              </button>
            </form>
          </div>
          <div className="dashed-box bg-light p-3">
            <h6 className="font-weight-bold font-size-pack">Booster Pack</h6>
            <div className="row m-0">
              <div className="col-8 px-0">
                <p>Extend your product validity for year long</p>
              </div>
              <div className="col-4 px-0 text-right">
                <p className="font-weight-bold">
                  {this.state.cartDetails?.currencySign === "INR"
                    ? "₹"
                    : this.state.cartDetails?.currencySign}{" "}
                  700
                </p>
              </div>
            </div>
            <button type="button" className="btn btn-booster">
              Add Booster
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default CartDetails;
