import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import highlighticon from '../../images/highlighticon.png'

const VvmResult = () => {
  const [resultData, set_ResultData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(15);
  const [totalPages, setTotalPages] = useState(0);


  function getResultData() {
    if (window?.VVM) {
      // set data
      set_ResultData(window?.VVM)
    } else {
      setTimeout(() => {
        getResultData();
      }, 100)
    }
  }
  useEffect(() => {
    getResultData()
  }, []);
  useEffect(() => {
    const totalPages = Math.ceil(resultData.length / resultsPerPage);
    setTotalPages(totalPages);
  }, [resultData, resultsPerPage]);

  const sharetab = (e) => {
    document.querySelector("#root > div > div > div:nth-child(3) > div > div.share-wrapper > i").classList.toggle("active")
    document.querySelector("#root > div > div > div:nth-child(3) > div > div.share-wrapper > ul").classList.toggle("active")
  }

  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = resultData.slice(
    indexOfFirstResult,
    indexOfLastResult
  );
  const paginate = (pageNumber) => {
    if (pageNumber === "...") {
      setCurrentPage((prevPage) => prevPage + 2);
    } else {
      setCurrentPage(pageNumber);
    }
  };
  const nextPage = () => {
    const maxPage = Math.ceil(resultData.length / resultsPerPage);
    if (currentPage < maxPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const getPaginationRange = () => {
    const maxDisplayedPages = 3; // Number of pagination buttons to display
    const ellipsisThreshold = 2; // Number of pages after which to show ellipsis
    const rangeStart = 1;
    if (totalPages <= maxDisplayedPages) {
      return Array.from({ length: totalPages }, (_, i) => rangeStart + i);
    }
    let rangeEnd = Math.min(rangeStart + maxDisplayedPages - 1, totalPages);
    if (currentPage >= ellipsisThreshold) {
      rangeEnd = Math.min(currentPage + 2, totalPages);
    }
    const paginationRange = Array.from(
      { length: rangeEnd - rangeStart + 1 },
      (_, i) => rangeStart + i
    );
    if (rangeEnd < totalPages) {
      paginationRange.push("...");
      paginationRange.push(totalPages);
    }
    return paginationRange;
  };
  return (
    <div>
      <div className="share-wrapper">
        <i className="fa fa-share-alt share" onClick={sharetab}></i>
        <ul className="social">
          <li id="desktopwhatsappicon"><a href={`https://web.whatsapp.com/send?text=${window.location.href}`} target="_blank" title="whatsapp"><i className="fa fa-whatsapp whatsapp"></i></a></li>
          <li id="mobilewhatsappicon"><a href={`https://web.whatsapp.com/send?text=${window.location.href}`} target="_blank" title="whatsapp"><i className="fa fa-whatsapp whatsapp"></i></a></li>
          <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" title="facebook"><i className="fa fa-facebook-official facebook"></i></a></li>
          <li><a href={`https://twitter.com/intent/tweet?url=${window.location.href}`} target="_blank" title="twitter"><i className="fa fa-twitter twitter"></i></a></li>
          <li><a href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`} target="_blank" title="linkedin"><i className="fa fa-linkedin linkedin"></i></a></li>
          {/* <li><a href="#" target="_blank" title="google"><i className="fa fa-google-plus google"></i></a></li> */}
          {/* <li><a href="#" target="_blank" title="instagram"><i className="fa fa-instagram instagram"></i></a></li> */}

        </ul>
      </div>
      <Helmet>
        {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
        <title>Extra ordinary performance by ALLEN Digital-PNCF Students | ALLEN Digital</title>
        <meta name="description" content="Extra ordinary performance by ALLEN Digital-PNCF Students | ALLEN Digital" />
        <meta property="og:title" content="Extra ordinary performance by ALLEN Digital-PNCF Students | ALLEN Digital" />
        <meta property="og:site_name" content="ALLEN Digital" />
        <meta property="og:url" content="https://digital.allen.ac.in/results/olympiad-2023/vvm" />
        <meta property="og:description" content="Extra ordinary performance by ALLEN Digital-PNCF Students | ALLEN Digital" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://digital.allen.ac.in/static/media/logo-new-r.4a9d1fde3fd2a6868a621b27352bda3a.svg" />
        {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
      </Helmet>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-12'>
            <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/resultheading12.png" className="resultheadingimg img-fluid d-block mx-auto" style={{ width: "35%" }} alt="resultHeading"></img>
          </div>
          <div className="col-12">
            <h2 className="text-center font-weight-bold mb-2 resultmaintextcentered">Extra ordinary performance by ALLEN Digital-PNCF Students</h2>
            {/* <h4 className="text-center mb-4 resultmaintextcentered font-weight-normal">Stage-1 of International Mathematical Olympiad (IMO) 2024
</h4> */}
            <div className='highlightstabular'>
              <div className='highlightheading'>
                        
              <span style={{ color: "#f5eca3" }}>Vidyarthi Vigyan Manthan (VVM) - Organised by Vijnana Bharti & NCERT
</span>
                        
                    </div>
              <div className='highlightcontent'>
                <div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'>34 Students Selected for State Level Camp</span></div>
              </div>
            </div>
            <div className='card-main'>
              {
                currentResults.map((data) => {
                  return (
                    <>
                      <div className='result-card'>

                        <img src={data.is_Img === "no" ? ('https://cdn.allendigital.in/fe-bsite/results/NO_IMAGE_FOUND.png') : (`https://cdn.digital.allen.ac.in/fe-bsite/results/olympiad/vvm/${data["Form_no"]}.png`)} alt='temp' className='resultimages-radius'></img>
                        <div>
                          <span className='resstudentname'>{data.Student_Name}</span><br />
                          <span className='adfno'>(Class {data.Class})</span>
                        </div>
                      </div>

                    </>
                  )
                })
              }


            </div>
          </div>
          {/* <img className='mainresultimagebig' src={gg}></img> */}
          {/* <div>
                    <img className='jeemainresultfooter' src="https://cdn.allendigital.in/fe-bsite/result_page/desktop_result_banner.png" style={{width:"100%", marginTop:"50px"}}></img>
                </div> */}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="pagination">
              {resultData.length > resultsPerPage && (
                <button onClick={prevPage} disabled={currentPage === 1}>
                  <span className="d-md-none" style={{ fontSize: "24px", lineHeight: "0px", paddingBottom: "5px" }}>{"‹"}</span> <span className="d-none d-md-flex">PREVIOUS</span>
                </button>
              )}
              {resultData.length > resultsPerPage && (
                <>
                  {getPaginationRange().map((pageNumber, index) => (
                    <button
                      key={index}
                      onClick={() => paginate(pageNumber)}
                      className={currentPage === pageNumber ? "active" : ""}
                    >
                      {pageNumber}
                    </button>
                  ))}
                </>
              )}
              {resultData.length > resultsPerPage && (
                <button
                  onClick={nextPage}
                  disabled={currentPage === totalPages}
                >
                  <span className="d-md-none" style={{ fontSize: "24px", lineHeight: "0px", paddingBottom: "5px" }}>{"›"}</span> <span className="d-none d-md-flex">NEXT</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VvmResult;