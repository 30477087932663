import React from 'react';
import { Link } from 'react-router-dom';
class ArticleChapterLink extends React.Component {
    constructor(props) {
      super(props);
    }
    render() { 
        return (<>
            <Link to={'/article-details/'+this.props.data?.slug} className='article-chapter-link d-block'>{this.props.data?.title}</Link>
        </>);
    }
}
 
export default ArticleChapterLink;