import React from "react";
// import { environment } from '../Services/HttpData';
import { Helmet } from "react-helmet";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);
class Policies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PageContent: [],
      tabContainerData: [
        { title: "Session 2023-24", active: true, dropdown: false },
        //{ title: "Session 2022-23", active: false, dropdown: true },
      ],
    };
  }

  // const [tabContainerData, SetTabContainerData] = useState(tabData);
  tabChange = (e) => {
    let tabArray = this.state.tabContainerData.map((val, ind) => {
      if (e.target.textContent === val.title) {
        return { ...val, active: true };
      } else {
        return { ...val, active: false };
      }
    });
    this.setState({ ...this.state, tabContainerData: tabArray });
  };
  // async componentDidMount() {
  //     // POST request using fetch with async/await
  //     const requestOptions = {
  //         method: 'POST',
  //         headers: { 'Content-Type': 'application/json' },
  //         body: JSON.stringify({ "keyword": ''})
  //     };
  //     // fetch(environment.baseUrl+'view_list_all', requestOptions)
  //     // .then(async response => {
  //     //     // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
  //     //     const data = /* isJson && */ await response.json();
  //     //     // check for error response
  //     //     // //console.log(response);
  //     //     if (!response.ok) {
  //     //         // get error message from body or default to response status
  //     //         const error = (data && data.message) || response.status;
  //     //         return Promise.reject(error);
  //     //     }
  //     //     let index=data.data.findIndex((item)=>item.slug==="refund-policy");
  //     //     //console.log(data.data[index]);
  //     //     let sections = {};
  //     //     data.data[index]?.sections.map((item) => {
  //     //       switch (item.id) {
  //     //         case 141:
  //     //           sections.banner = item;
  //     //           break;
  //     //           case 177:
  //     //             sections.banner1 = item;
  //     //             break;
  //     //       }
  //     //     });
  //     //   // console.log(sections)
  //     //     this.setState({...this.state, PageContent: sections });
  //     //     //console.log(this.state.PageContent);
  //     // })
  //     // .catch(error => {
  //     //     this.setState({ errorMessage: error.toString() });
  //     //     console.error('There was an error!', error);
  //     // });
  //     const getViewListAll = () => {
  //       if (!window?.AllenDigitalFEBSite) {
  //         setTimeout(() => {
  //           getViewListAll();
  //         }, 50);
  //         return false
  //       }
  //       const data = window?.AllenDigitalFEBSite

  //      let index=data.data.findIndex((item)=>item.slug==="refund-policy");
  //       //console.log(data.data[index]);
  //       let sections = {};
  //       data.data[index]?.sections.map((item) => {
  //         switch (item.id) {
  //           case 141:
  //             sections.banner = item;
  //             break;
  //             case 177:
  //               sections.banner1 = item;
  //               break;
  //         }
  //       });
  //     // console.log(sections)
  //       this.setState({...this.state, PageContent: sections });
  //     };
  //     getViewListAll();
  //     /* const response = await fetch(environment.baseUrl+'user_blog_category_list', requestOptions);
  //     const data = await response.json();
  //     if(data.replyCode==="success"){
  //         //console.log(data.data);
  //         this.setState(data.data);
  //     } */
  // }

  render() {
    const data1 = `<div class="container mt-2 privacy-policy"><style>
    .table td, .table th {
        padding: 13px;
        vertical-align: top;
        border-top: 1px solid #DEE2E6;
        font-size: 14px;
        font-weight: 500;
    }
    ul{
       padding: 0 10px;
        list-style: none;
    }
    ul ul{
     padding: 0 20px !important;
    }
    li{
        padding: 5px 0;
    }
    </style>
    <div class="container">
    <h5><b>How to Apply for Refund</b></h5>
    <ul>
        <li><b>1.</b> Last Date to Apply for Refund is mentioned on relevant course detail page of website.</li>
        <li><b>2.</b> Amount Deductible in Refund Case (Towards Administrative &amp; Academic Expenses) are mentioned course wise in table below.</li>
        <li><b>3.</b> Refund request made verbally or through phone shall not be entertained in any case.</li>
        <li><b>4.</b> Refund Applications will be accepted only through <a href="/ecom/refund" target="_blank" id="refpolicytext">ALLEN Digital Refund Portal</a>. No refund will be made after the Last Refund Date.</li>
        <li><b>5.</b> Refund will be credited in Father / Mother  Bank Account through NEFT / RTGS mode only. For this you must submit Cancelled Cheque or Bank Passbook first page scanned copy.</li>
        <li><b>6.</b> Refund rules are SAME even</li>
        <ul>
            <li><b>a.</b> If a student joins class after commencement date or</li>
            <li><b>b.</b> If a student doesn't join or attend even a single class at all or</li>
            <li><b>c.</b> If a student applies for refund before start of the class.</li>
        </ul>
    </ul>
    <h5><b>Refund in Special Case After Last Refund Date</b></h5>
    <ul>
        <li><b>1.</b> If an enrolled Student get Admission in any Medical / Engineering College (for which he/she has taken admission) located in India for MBBS / BDS / B.Tech. / B.E. / B.Arch. only through any Competitive Examination, refund will be made after deducting amount equivalent to double of Refund amount deductible out of Fee Paid. Refund shall not be payable if amount paid is less than amount equivalent to Refund amount.</li>
        <li><b>2.</b> Refund of fee will be applicable only for the aforesaid courses. Refund will not be made for B.Pharma / B.YNS / B. Physiotherapy / B.VSc, B.Sc. and other Courses.</li>
        <li><b>3.</b> Last date for such special case refund is 30/11/2022.</li>
      
        <li><b>4</b>. Refund application on the ground of severe disease may be considered by the management committee, after passing Refund date on submission of Medical Prescription / Report.</li>
    </ul>
    </div>
    <div class="mt-5">
            <table class="table table-striped" cellpadding="0" cellspacing="0" width="100%">
            <tbody>
                <tr style="background-color:#0c703f;color:#fff">
                    <th>Class</th>
                    <th>Amount Deductible (₹)</th>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" width="360" style="height:15.0pt;width:270pt">CLASS 6 (NCERT / NCERT + OLYMPIAD / ICSE + OLYMPIAD) LIVE</td>
                    <td class="xl65" width="79" style="border-left:none;width:59pt">5000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">CLASS 7 (NCERT / NCERT + OLYMPIAD / ICSE + OLYMPIAD) LIVE</td>
                    <td class="xl65" style="border-top:none;border-left:none">5000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">CLASS 8 (NCERT / NCERT + OLYMPIAD / ICSE + OLYMPIAD) LIVE</td>
                    <td class="xl65" style="border-top:none;border-left:none">5000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">CLASS 9 (NCERT / NCERT + OLYMPIAD / ICSE + OLYMPIAD / NCERT + OLYMPIAD + IIT / MEDICAL FOUNDATION) LIVE</td>
                    <td class="xl65" style="border-top:none;border-left:none">6000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">CLASS 10 (NCERT / NCERT + OLYMPIAD / ICSE + OLYMPIAD / NCERT + OLYMPIAD + IIT / MEDICAL FOUNDATION) LIVE</td>
                    <td class="xl65" style="border-top:none;border-left:none">6000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">CLASS 9 FOUNDATION LIVE GUJARATI</td>
                    <td class="xl65" style="border-top:none;border-left:none">6000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">CLASS 10 FOUNDATION LIVE GUJARATI</td>
                    <td class="xl65" style="border-top:none;border-left:none">6000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">JEE (MAIN+ADVANCED) NURTURE LIVE (TARGET 2024)</td>
                    <td class="xl65" style="border-top:none;border-left:none">20000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">JEE (MAIN+ADVANCED) ENTHUSIAST LIVE (TARGET 2023)</td>
                    <td class="xl65" style="border-top:none;border-left:none">20000</td>
                </tr>
                <tr height="21" style="mso-height-source:userset;height:15.75pt">
                    <td height="21" class="xl64" style="height:15.75pt;border-top:none">JEE (MAIN+ADVANCED) LEADER LIVE (TARGET 2023)</td>
                    <td class="xl65" style="border-top:none;border-left:none">20000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">JEE (MAIN) NURTURE LIVE (TARGET 2024)</td>
                    <td class="xl65" style="border-top:none;border-left:none">15000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">JEE (MAIN) ENTHUSIAST LIVE (TARGET 2023)</td>
                    <td class="xl65" style="border-top:none;border-left:none">15000</td>
                </tr>
                <tr height="21" style="mso-height-source:userset;height:15.75pt">
                    <td height="21" class="xl64" style="height:15.75pt;border-top:none">JEE (MAIN) LEADER LIVE (TARGET 2023)</td>
                    <td class="xl65" style="border-top:none;border-left:none">15000</td>
                </tr>
                <tr height="21" style="mso-height-source:userset;height:15.75pt">
                    <td height="21" class="xl64" style="height:15.75pt;border-top:none">NEET (UG) NURTURE LIVE (TARGET 2024)</td>
                    <td class="xl65" style="border-top:none;border-left:none">20000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">NEET (UG) ENTHUSIAST LIVE (TARGET 2023)</td>
                    <td class="xl65" style="border-top:none;border-left:none">20000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">NEET (UG) LEADER LIVE (TARGET 2023)</td>
                    <td class="xl65" style="border-top:none;border-left:none">20000</td>
                </tr>
                            <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">Leader Plus (Live + Recorded Course) (Target 2023)</td>
                    <td class="xl65" style="border-top:none;border-left:none">20000</td>
                </tr>                        <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">Leader Plus (Live + Recorded Course) with TAB (Target 2023)</td>
                    <td class="xl65" style="border-top:none;border-left:none">35000</td>
                </tr>
            </tbody>
        </table>
        <ol style="margin-top: 20px;">
        </ol>
    </div></div>`;
    const data2 = `<div class="container mt-2 privacy-policy"><style>
    .table td, .table th {
        padding: 13px;
        vertical-align: top;
        border-top: 1px solid #DEE2E6;
        font-size: 14px;
        font-weight: 500;
    }
    ul{
       padding: 0 10px;
        list-style: none;
    }
    ul ul{
     padding: 0 20px !important;
    }
    li{
        padding: 5px 0;
    }
    </style>
    <div class="container">
    <h5><b>How to Apply for Refund</b></h5>
    <ul>
        <li><b>1.</b> Last Date to Apply for Refund is mentioned on the relevant course detail page of the website.</li>
        <li><b>2.</b> Amount Deductible in Refund Case (Towards Administrative &amp; Academic Expenses) are mentioned course wise in table below.</li>
        <li><b>3.</b> Refund requests made verbally or through phone / email shall not be entertained in any case.</li>
        <li><b>4.</b> Refund Applications will be accepted only through <a href="/ecom/refund" target="_blank" id="refpolicytext">ALLEN Digital Refund Portal.</a> No refund will be made after the Last Refund Date.</li>
        <li><b>5.</b> Refunds will be credited in the Father / Mother Bank Account through NEFT / RTGS mode only. For this you must submit a Cancelled Cheque or Bank Passbook first page scanned copy.</li>
        <li><b>6.</b> Refund rules are SAME even</li>
        <ul>
            <li><b>a.</b> If a student joins class after commencement date or</li>
            <li><b>b.</b> If a student doesn't join or attend even a single class at all or</li>
            <li><b>c.</b> If a student applies for refund before the start of the class.</li>
        </ul>
    </ul>
    <h5><b>Refund in Special Case After Last Refund Date</b></h5>
    <ul>
        <li><b>1.</b> If an enrolled Student get Admission in any Medical / Engineering College (for which he/she has taken admission) located in India for MBBS / BDS / B.Tech. / B.E. / B.Arch. only through any Competitive Examination, refund will be made after deducting amount equivalent to double of Refund amount deductible out of Fee Paid. Refund shall not be payable if amount paid is less than amount equivalent to Refund amount.</li>
        <li><b>2.</b> Refund of fee will be applicable only for the aforesaid courses. Refund will not be made for B.Pharma / B.YNS / B. Physiotherapy / B.VSc, B.Sc. and other Courses.</li>
        <li><b>3.</b> Last date for such special case refund is <b>30/09/2023</b>.</li>
        <li><b>4</b>. For this, following documents must be submitted along with Refund Application.</li>
        <ul>
            <li><b>a.</b> Invoice of ALLEN Digital Course.</li>
            <li><b>b.</b> Photo Copy of College Call Letter / Counseling Letter.</li>
            <li><b>c.</b> Fee Receipt of College Admission.</li>
        </ul>
    </ul>
    </div>
    <div class="mt-5">
            <table class="table table-striped" cellpadding="0" cellspacing="0" width="100%">
            <tbody>
                <tr style="background-color:#0c703f;color:#fff">
                    <th>Class</th>
                    <th>Amount Deductible (₹)</th>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" width="360" style="height:15.0pt;width:270pt">CLASS 6 Live or Live + Recorded Course</td>
                    <td class="xl65" width="79" style="border-left:none;width:59pt">7500</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">CLASS 7 Live or Live + Recorded Course</td>
                    <td class="xl65" style="border-top:none;border-left:none">7500</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">CLASS 8 Live or Live + Recorded Course</td>
                    <td class="xl65" style="border-top:none;border-left:none">7500</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">CLASS 9 Live or Live + Recorded Course</td>
                    <td class="xl65" style="border-top:none;border-left:none">9000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">CLASS 10 Live or Live + Recorded Course</td>
                    <td class="xl65" style="border-top:none;border-left:none">9000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">JEE (MAIN+ADVANCED) NURTURE LIVE (TARGET 2025)</td>
                    <td class="xl65" style="border-top:none;border-left:none">25000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">JEE (MAIN+ADVANCED) ENTHUSIAST LIVE (TARGET 2024)</td>
                    <td class="xl65" style="border-top:none;border-left:none">25000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">JEE (MAIN) NURTURE LIVE (TARGET 2025)</td>
                    <td class="xl65" style="border-top:none;border-left:none">20000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">JEE (MAIN) ENTHUSIAST LIVE (TARGET 2024)</td>
                    <td class="xl65" style="border-top:none;border-left:none">20000</td>
                </tr>
                <tr height="21" style="mso-height-source:userset;height:15.75pt">
                    <td height="21" class="xl64" style="height:15.75pt;border-top:none">NEET (UG) NURTURE LIVE (TARGET 2025)</td>
                    <td class="xl65" style="border-top:none;border-left:none">25000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">NEET (UG) ENTHUSIAST LIVE (TARGET 2024)</td>
                    <td class="xl65" style="border-top:none;border-left:none">25000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">JEE (MAIN+ADVANCED) LEADER LIVE (TARGET 2024)</td>
                    <td class="xl65" style="border-top:none;border-left:none">25000</td>
                </tr>
                <tr height="21" style="mso-height-source:userset;height:15.75pt">
                    <td height="21" class="xl64" style="height:15.75pt;border-top:none">JEE (MAIN) LEADER LIVE (TARGET 2024)</td>
                    <td class="xl65" style="border-top:none;border-left:none">20000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">NEET (UG) LEADER LIVE (TARGET 2024)</td>
                    <td class="xl65" style="border-top:none;border-left:none">25000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">NEET (UG) ACHIEVER PLUS LIVE + RECORDED (TARGET 2024)</td>
                    <td class="xl65" style="border-top:none;border-left:none">25000</td>
                </tr>
                <tr height="20" style="height:15.0pt">
                    <td height="20" class="xl64" style="height:15.0pt;border-top:none">NEET (UG) ALPHA ACHIEVER LIVE + RECORDED (TARGET 2024)</td>
                    <td class="xl65" style="border-top:none;border-left:none">25000</td>
                </tr>
            </tbody>
        </table>
        <ol style="margin-top: 20px;">
        </ol>
    </div></div>`;

    return (
      <>
        <Helmet>
          {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
          <title>Refund Policy | ALLEN Digital</title>
          {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
          <meta
            name="description"
            content="Explore our refund policy page to know how to apply for a refund. Allen Digital provides an effective way to do so without any hassle." />
          <meta property="og:title" content="Refund Policy | ALLEN Digital" />
          <meta property="og:site_name" content="ALLEN Digital" />
          <meta property="og:url" content="https://digital.allen.ac.in/refund-policy" />
          <meta property="og:description" content="Explore our refund policy page to know how to apply for a refund. Allen Digital provides an effective way to do so without any hassle." />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://digital.allen.ac.in/static/media/logo-new-r.4a9d1fde3fd2a6868a621b27352bda3a.svg" />
        </Helmet>
        <div className="container-fluid">
          <div className="banner-3" style={{ paddingTop: "150px" }}>
            <div className="container banner-content">
              <h4>Refund Policy</h4>
              {/* <!--<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis aliquam dolorem perferendis
                    quibusdam,<br>
                    assumenda perspiciatis necessitatibus deleniti consequuntur blanditiis libero minima <br>sed
                    cupiditate
                    veniam! Impedit, sunt! Fuga magni voluptate veniam.</p>--> */}
            </div>
          </div>
        </div>
        <div className=" mt-5">
          <ul className="container nav nav-pills mt-4 mt-md-4">
            {this?.state?.tabContainerData?.map((val, ind) => {
              return (
                <li
                  onClick={this?.tabChange}
                  className={`nav-item mr-4 h5 ${val.active ? `border-bottom-green my-order-text` : ``
                    }`}
                  role="button"
                >
                  <span className="border-bottom-green ">
                    <b>{val.title}</b>
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
        {this.state.tabContainerData[0]["active"] ? (
          <div
            className="container mt-2 privacy-policy"
            dangerouslySetInnerHTML={{ __html: data2 }}
          ></div>
        ) : null}
        {/* {this.state.tabContainerData[1]["active"] ? (
          <div
            className="container mt-2 privacy-policy"
            dangerouslySetInnerHTML={{ __html: data1 }}
          ></div>
        ) : null} */}
      </>
    );
  }
}

export default Policies;
