import React from "react";
import AboutUsBanner from "../components/AboutUs/AoutusBanner";
const PypjeeMain2023 = () => {
// var jeeMain2023 = [
// {
// date: "24 January 2023",
// shift: "Shift 1 (9:00 AM – 12:00 PM)",
// physics:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/24-JAN-2023_SHIFT-1_JM_PHYSICS_PAPER_SOLUTION.pdf",
// chemistry:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/24-JAN-2023_SHIFT-1_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
// math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/24-JAN-2023_SHIFT-1_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
// },
// {
// shift: "Shift 2 (3:00 PM – 06:00 PM)",
// physics:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/24-JAN-2023_SHIFT-2_JM_PHYSICS_PAPER_SOLUTION.pdf",
// chemistry:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/24-JAN-2023_SHIFT-2_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
// math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/24-JAN-2023_SHIFT-2_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
// },
// {
// date: "25 January 2023",
// shift: "Shift 1 (9:00 AM – 12:00 PM)",
// physics:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/25-JAN-2023_SHIFT-1_JM_PHYSICS_PAPER_SOLUTION.pdf",
// chemistry:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/25-JAN-2023_SHIFT-1_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
// math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/25-JAN-2023_SHIFT-1_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
// },
// {
// shift: "Shift 2 (3:00 PM – 06:00 PM)",
// physics:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/25-JAN-2023_SHIFT-2_JM_PHYSICS_PAPER_SOLUTION.pdf",
// chemistry:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/25-JAN-2023_SHIFT-2_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
// math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/25-JAN-2023_SHIFT-2_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
// },
// {
// date: "29 January 2023",
// shift: "Shift 1 (9:00 AM – 12:00 PM)",
// physics:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/29-JAN-2023_SHIFT-1_JM_PHYSICS_PAPER_SOLUTION.pdf",
// chemistry:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/29-JAN-2023_SHIFT-1_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
// math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/29-JAN-2023_SHIFT-1_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
// },
// {
// shift: "Shift 2 (3:00 PM – 06:00 PM)",
// physics:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/29-JAN-2023_SHIFT-2_JM_PHYSICS_PAPER_SOLUTION.pdf",
// chemistry:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/29-JAN-2023_SHIFT-2_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
// math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/29-JAN-2023_SHIFT-2_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
// },
// {
// date: "30 January 2023",
// shift: "Shift 1 (9:00 AM – 12:00 PM)",
// physics:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/30-JAN-2023_SHIFT-1_JM_PHYSICS_PAPER_SOLUTION.pdf",
// chemistry:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/30-JAN-2023_SHIFT-1_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
// math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/30-JAN-2023_SHIFT-1_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
// },
// {
// shift: "Shift 2 (3:00 PM – 06:00 PM)",
// physics:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/30-JAN-2023_SHIFT-2_JM_PHYSICS_PAPER_SOLUTION.pdf",
// chemistry:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/30-JAN-2023_SHIFT-2_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
// math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/30-JAN-2023_SHIFT-2_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
// },
// {
// date: "31 January 2023",
// shift: "Shift 1 (9:00 AM – 12:00 PM)",
// physics:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/31-JAN-2023_SHIFT-1_JM_PHYSICS_PAPER_SOLUTION.pdf",
// chemistry:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/31-JAN-2023_SHIFT-1_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
// math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/31-JAN-2023_SHIFT-1_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
// },
// {
// shift: "Shift 2 (3:00 PM – 06:00 PM)",
// physics:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/31-JAN-2023_SHIFT-2_JM_PHYSICS_PAPER_SOLUTION.pdf",
// chemistry:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/31-JAN-2023_SHIFT-2_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
// math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/31-JAN-2023_SHIFT-2_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
// },
// {
// date: "01 February 2023",
// shift: "Shift 1 (9:00 AM – 12:00 PM)",
// physics:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/01-FEB-2023_SHIFT-1_JM_PHYSICS_PAPER_SOLUTION.pdf",
// chemistry:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/1-FEB-2023_SHIFT-1_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
// math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/01-FEB-2023_SHIFT-1_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
// },
// {
// shift: "Shift 2 (3:00 PM – 06:00 PM)",
// physics:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/01-FEB-2023_SHIFT-2_JM_PHYSICS_PAPER_SOLUTION.pdf",
// chemistry:
// "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/1-FEB-2023_SHIFT-2_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
// math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/01-FEB-2023_SHIFT-2_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
// },
// ];
return (
<>
<AboutUsBanner handelEvent={() =>
{}} slug="JEE Main April 2023" />
{
/* wid? */
<>
<div className="tab-content" id="pills-tabContent">
   <div className=" app-features">
      <div className="container py-5">
         <div className="">
            <h2 className="font-weight-bold text-center mb-3"></h2>
            <div className="card py-5 course-card">
               <div className="row">
                  <div className="col-12 col-lg-12">
                     <section class="bg-1">
                        <h2>
                           JEE Main April 2023 Question Papers with Solutions PDF
                           (English)
                        </h2>
                        <div className="table-responsive">
                           <table class="table  ace-table" width="100%">
                              <tr class="table-first-heading">
                                 <th rowspan="2" style={{verticalAlign:"middle"}}>Date</th>
                                 <th rowspan="2" style={{verticalAlign:"middle"}}>Shift</th>
                                 <th rowspan="2" style={{verticalAlign:"middle", textAlign:"center"}}>Without Solution</th>
                                 <th colspan="3" className="text-center">With Solution</th>
                              </tr>
                              <tr class="table-first-heading text-center">
                                 <th>Physics</th>
                                 <th>Chemistry</th>
                                 <th>Maths</th>
                              </tr>
                              <tr>
                                 <td rowspan="2" className="text-left">06 April 2023</td>
                                 <td className="text-left">Shift 1 (9:00 AM - 12:00 PM)</td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Without_Solution/JEE_Main_Paper_06-04-23_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Physics/With_Solution/0604-Physics_Paper_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Chemistry/With_Solution/0604-Chemistry_Paper_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Maths/With_Solution/0604-Mathematics_Paper_Morning.pdf">Download</a></td>
                              </tr>
                              <tr>
                                 <td className="text-left">Shift 2 (3:00 PM - 06:00 PM)</td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Without_Solution/JEE_Main_Paper_06-04-23_Evening.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Physics/With_Solution/0604-Physics_Paper_Evening.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Chemistry/With_Solution/0604-Chemistry_Paper_Evening.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Maths/With_Solution/0604-Mathematics_Paper_Evening.pdf">Download</a></td>
                              </tr>
                              <tr>
                                 <td rowspan="2" className="text-left">08 April 2023</td>
                                 <td className="text-left">Shift 1 (9:00 AM - 12:00 PM)</td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Without_Solution/JEE_Main_Paper_08-04-23_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Physics/With_Solution/0804-Physics_Paper_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Chemistry/With_Solution/0804-Chemistry_Paper_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Maths/With_Solution/0804-Mathematics _Paper_Morning.pdf">Download</a></td>
                              </tr>
                              <tr>
                                 <td className="text-left">Shift 2 (3:00 PM - 06:00 PM)</td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Without_Solution/JEE_Main_Paper_08-04-23_Evening.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Physics/With_Solution/0804-Physics_Paper_Evening.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Chemistry/With_Solution/0804-Chemistry_Paper_Evening.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Maths/With_Solution/0804-Mathematics_Paper_Evening.pdf">Download</a></td>
                              </tr> 
                              <tr>
                                 <td rowspan="2" className="text-left">10 April 2023</td>
                                 <td className="text-left">Shift 1 (9:00 AM - 12:00 PM)</td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Without_Solution/JEE_Main_Paper_10-04-23_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Physics/With_Solution/1004-Physics_Paper_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Chemistry/With_Solution/1004-Chemistry_Paper_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Maths/With_Solution/1004-Mathematics_Paper_Morning.pdf">Download</a></td>
                              </tr>
                              <tr>
                                 <td className="text-left">Shift 2 (3:00 PM - 06:00 PM)</td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Without_Solution/JEE_Main_Paper_10-04-23_Evening.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Physics/With_Solution/1004-Physics_Paper_Evening.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Chemistry/With_Solution/1004-Chemistry_Paper_Evening.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Maths/With_Solution/1004-Mathematics_Paper_Evening.pdf">Download</a></td>
                              </tr>
                              <tr>
                                 <td rowspan="2" className="text-left">11 April 2023</td>
                                 <td className="text-left">Shift 1 (9:00 AM - 12:00 PM)</td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Without_Solution/JEE_Main_Paper_11-04-23_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Physics/With_Solution/1104-Physics_Paper_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Chemistry/With_Solution/1104_Chemistry_Paper_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Maths/With_Solution/1104-Mathematics_Paper_Morning.pdf">Download</a></td>
                              </tr>
                              <tr>
                                 <td className="text-left">Shift 2 (3:00 PM - 06:00 PM)</td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Without_Solution/JEE_Main_Paper_11-04-23_Evening.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Physics/With_Solution/1104-Physics_Paper_Evening.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Chemistry/With_Solution/1104-Chemistry_Paper_Evening.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Maths/With_Solution/1104-Mathematics_Paper_Evening.pdf">Download</a></td>
                              </tr> 
                              <tr>
                                 <td  className="text-left">12 April 2023</td>
                                 <td className="text-left">Shift 1 (9:00 AM - 12:00 PM)</td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Without_Solution/JEE_Main_Paper_12-04-23_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Physics/With_Solution/1204-Physics_Paper_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Chemistry/With_Solution/1204-Chemistry_Paper_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Maths/With_Solution/1204-Mathematics_Paper_Morning.pdf">Download</a></td>
                              </tr>
                              <tr>
                                 <td rowspan="2" className="text-left">13 April 2023</td>
                                 <td className="text-left">Shift 1 (9:00 AM - 12:00 PM)</td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Without_Solution/JEE_Main_Paper_13-04-23_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Physics/With_Solution/1304_Physics_Paper_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Chemistry/With_Solution/1304_Chemistry_Paper_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Maths/With_Solution/1304-Mathematics_Paper_Morning.pdf">Download</a></td>
                              </tr>
                              <tr>
                                 <td className="text-left">Shift 2 (3:00 PM - 06:00 PM)</td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Without_Solution/JEE_Main_Paper_13-04-23_Evening.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Physics/With_Solution/1304-Physics_Paper_Evening.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Chemistry/With_Solution/1304-Chemistry_Paper_Evening.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Maths/With_Solution/1304-Mathematics_Paper_Evening.pdf">Download</a></td>
                              </tr>
                              <tr>
                                 <td className="text-left">15 April 2023</td>
                                 <td className="text-left">Shift 1 (9:00 AM - 12:00 PM)</td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Without_Solution/JEE_Main_Paper_15-04-23_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Physics/With_Solution/1504-Physics_Paper_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Chemistry/With_Solution/1504-Chemistry_Paper_Morning.pdf">Download</a></td>
                                 <td valign="middle" align="center"><i class="fa-solid fa-download"></i> <a target="_blank" href="https://cdn.digital.allen.ac.in/fe-bsite/free_resources/JEE_Main_APRIL_2023_ADPL/Maths/With_Solution/1504-Mathematics_Paper_Morning.pdf">Download</a></td>
                              </tr>
                                   
                           </table>
                        </div>
                     </section>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</>
}
</>
);
};
export default PypjeeMain2023;