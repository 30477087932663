import React from "react";
import HomeArticals from "../components/home/HomeArticals";
import HomeBanner from "../components/home/HomeBanner";
import HomeContactForm from "../components/home/HomeContactForm";
import HomeLegacy from "../components/home/HomeLegacy";
import HomeProgrammes from "../components/home/HomeProgrammes";
import HomeTestimonials from "../components/home/HomeTestimonials";
import HomeUpcomingCourses from "../components/home/HomeUpcomingCourses";
import HomeUpcomingCoursesLms from "../components/home/HomeUpcomingCoursesLms";
import HomeFAQ from "./HomeFAQ";
import MixpanelInit from "../components/Mixpanel/MixpanelInit";
import { Helmet } from "react-helmet";
// import { environment } from '../Services/HttpData';
import { toast } from "react-toastify";
import EarlyBird from "./EarlyBird";
import HomePageSlider from "./HomePageSlider";
import HomeVideo from "../components/home/HomeVideo";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);

const testimonials_list = {
  replyCode: "success",
  replyMsg: "testimonials list",
  data: [
    {
      id: 1,
      title: "Mridul Agarwal",
      description:
        "<span style='color: rgb(33, 37, 41); font-family: &quot;Josefin Sans&quot;, sans-serif; font-size: 16px;'>Despite lack of resources and physical classes during the pandemic, the swiftness with which ALLEN provided me with online academic support is commendable. The faculties completed the course on time and helped me release the mental pressure I was going through!</span>",
      image: "attachment-1641985556728.jpg",
      video: "attachment-1640081262519.mp4",
      person_name: "AIR-1 IIT JEE (advanced) 2021 ",
      person_photo: "attachment-1627632180825.jpg",
      person_description: "Personal Details",
      subtitle: "Subtitle",
      featured: 1,
      exam_id: 1,
      stream_id: 1,
      status: 1,
      created: "2021-07-29T18:30:00.000Z",
      totalcount: 23,
      exam_title: "JEE (Main)",
      stream_title: "Engineering",
    },
    {
      id: 2,
      title: "Soyeb Aftab",
      description:
        "<span style='color: rgb(33, 37, 41); font-family: &quot;Josefin Sans&quot;, sans-serif; font-size: 16px;'>All of a sudden, I was under so much pressure when the Pandemic happened just before the exam. I can never forget how rapidly the faculties of ALLEN switched to the online medium and gave me immense support during those tough times and helped me stay calm &amp; focused. </span>",
      image: "attachment-1641985625694.jpg",
      video: "attachment-1640081468780.mp4",
      person_name: "AIR-1 NEET 2020",
      person_photo: "attachment-1629453259505.jpg",
      person_description: "Personal Details",
      subtitle: "Subtitle",
      featured: 1,
      exam_id: 2,
      stream_id: 2,
      status: 1,
      created: "2021-08-19T18:30:00.000Z",
      totalcount: 23,
      exam_title: "NEET UG",
      stream_title: "Pre Medical",
    },
    {
      id: 3,
      title: "Nalin Khandelwal",
      description:
        "<span style='color: rgb(33, 37, 41); font-family: &quot;Josefin Sans&quot;, sans-serif; font-size: 16px;'>I can proudly say that joining ALLEN was the best decision I ever made as I was not only acquainted with the best study materials and peer learning environment, but also was under the guidance of highly qualified &amp; supportive faculties.</span><br><br>",
      image: "attachment-1641985658612.jpg",
      video: "",
      person_name: "AIR-1 NEET 2019",
      person_photo: "attachment-1629453312042.jpg",
      person_description: "Personal Details",
      subtitle: "Subtitle",
      featured: 1,
      exam_id: 2,
      stream_id: 2,
      status: 1,
      created: "2021-08-19T18:30:00.000Z",
      totalcount: 23,
      exam_title: "NEET UG",
      stream_title: "Pre Medical",
    },
    {
      id: 4,
      title: "Trial Testimonial",
      description:
        "“I had a great experience learning with Ashish Sir during my JEE preparation. He challenged our thinking and understanding of the material with challenging but relevant problems. His understanding the subject is very thorough and even more importantly.”",
      image: "attachment-1629453323916.jpg",
      video: "",
      person_name: "Full_Name",
      person_photo: "attachment-1629453322381.jpg",
      person_description: "Personal Details",
      subtitle: "Subtitle",
      featured: 1,
      exam_id: 1,
      stream_id: 2,
      status: 1,
      created: "2021-08-19T18:30:00.000Z",
      totalcount: 23,
      exam_title: "JEE (Main)",
      stream_title: "Pre Medical",
    },
  ],
  cmd: "testimonials_list",
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    // //console.log(props);
    this.state = {
      PageContent: [],
      examsList: [],
      examsFilters: [],
      upcomingCourse: [],
      testimonialsList: [],
      courses: [],
      usedfilters: [],
      selectedFilters: { contentType: [], learningMode: ["Self-Paced"] },
      currentpage: 0,
      isLmsHome: false,
    };
  }
  /* Exam Fetch Api */
  fetchExams() {
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "featured": '1','isAdmin':'0'})
    // };
    // fetch(environment.baseUrl+'exam_list', requestOptions)
    // .then(async response => {
    //     // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
    //     const data1 = /* isJson && */ await response.json();
    //     // check for error response
    //     // //console.log(response);
    //     if (!response.ok) {
    //         // get error message from body or default to response status
    //         const error = (data1 && data1.message) || response.status;
    //         return Promise.reject(error);
    //     }
    //     // //console.log(data.data[index]);
    //     // let sections = {};
    //       // //console.log(data1.data,this.state);
    //     this.setState({ examsList:data1.data});
    //      //console.log("exam",this.state);
    // })
    // .catch(error => {
    //     this.setState({ errorMessage: error.toString() });
    //     console.error('There was an error!', error);
    // });

    const getExamList = () => {
      if (!window?.AllenDigitalFEBSiteExamList) {
        setTimeout(() => {
          getExamList();
        }, 50);
        return false;
      }
      const data = window?.AllenDigitalFEBSiteExamList;
      let examsList = data.data;
      this.setState({ examsList: data.data });
    };
    getExamList();
  }
  /* Exam Filters Api */
  fetchExamsFilters() {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify({ "featured": '1','isAdmin':'0'})
    };
    fetch(environment.ecomApiUrl + "filters/list", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const dataFilter = /* isJson && */ await response.json();
        // check for error response
        // //console.log(response);
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (dataFilter && dataFilter.message) || response.status;
          return Promise.reject(error);
        }
        // //console.log(data.data[index]);
        // let sections = {};
        // //console.log(data1.data,this.state);
        this.setState({ examsFilters: dataFilter.data[1].filters });
        //console.log("examFilter",this.state.examsFilters);
      })
      .catch((error) => {
        this.setState({ errorMessage: error.toString() });
        console.error("There was an error!", error);
      });
  }

  /* Get Courses */
  //alert()
  getCoureByFilterId() {
    //const auth_token =  localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : '';
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filters: [],
        sort: "latest",
        page: 1,
        limit: 50,
      }),
    };
    fetch(environment.ecomApiUrl + "product/list", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data1 = /* isJson && */ await response.json();

        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data1 && data1.message) || response.status;
          return Promise.reject(error);
        }

        this.setState({ upcomingCourse: data1.data.records });
        //console.log("upcoming",this.state);
      })
      .catch((error) => {
        this.setState({ errorMessage: error.toString() });
        console.error("There was an error!", error);
      });
  }

  /* Testimonial Api */
  fetchTestimonials() {
    this.setState({ testimonialsList: testimonials_list?.data });
  }
  async componentDidMount() {
    // POST request using fetch with async/await
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ "keyword": ''})
    // };
    // fetch(environment.baseUrl+'view_list_all', requestOptions)
    // .then(async response => {
    //     // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
    //     const data = /* isJson && */ await response.json();
    //     // check for error response
    //     // //console.log(response);
    //     if (!response.ok) {
    //         // get error message from body or default to response status
    //         const error = (data && data.message) || response.status;
    //         return Promise.reject(error);
    //     }
    //     let index=data.data.findIndex((item)=>item.slug==="home");
    //     // //console.log(data.data[index]);
    //     let sections = {};
    //     data.data[index]?.sections.map((item) => {
    //       switch (item.id) {
    //         case 2:
    //           sections.banner = item;
    //           break;
    //         case 3:
    //           sections.programs = item;
    //           break;
    //         case 4:
    //           sections.uniqueFetures = item;
    //           break;
    //         case 5:
    //           sections.contact = item;
    //           break;
    //         case 6:
    //           sections.testimonials = item;
    //           break;
    //         case 7:
    //           sections.resources = item;
    //           break;
    //         case 8:
    //           sections.upcomingCourses = item;
    //           break;
    //       }
    //     });
    //     this.setState({ PageContent: sections });
    //     // //console.log(this.state.PageContent);
    //     this.fetchExams();
    //     this.fetchTestimonials();
    //     if(!this.state.isLmsHome){
    //       this.fetchExamsFilters();
    //       this.getCoureByFilterId();
    //     }
    // })
    // .catch(error => {
    //     this.setState({ errorMessage: error.toString() });
    //     console.error('There was an error!', error);
    // });
    /* const response = await fetch(environment.baseUrl+'user_blog_category_list', requestOptions);
    const data = await response.json();
    if(data.replyCode==="success"){
        //console.log(data.data);
        this.setState(data.data);
    } */

    const getViewListAll = () => {
      if (!window?.AllenDigitalFEBSite) {
        setTimeout(() => {
          getViewListAll();
        }, 50);
        return false;
      }
      let index = window?.AllenDigitalFEBSite.data.findIndex(
        (item) => item.slug === "home"
      );
      // //console.log(data.data[index]);
      let sections = {};
      window?.AllenDigitalFEBSite.data[index]?.sections.map((item) => {
        switch (item.id) {
          case 2:
            sections.banner = item;
            break;
          case 3:
            sections.programs = item;
            break;
          case 4:
            sections.uniqueFetures = item;
            break;
          case 5:
            sections.contact = item;
            break;
          case 6:
            sections.testimonials = item;
            break;
          case 7:
            sections.resources = item;
            break;
          case 8:
            sections.upcomingCourses = item;
            break;
        }
      });
      this.setState({ PageContent: sections });
      // console.log(sections);
      this.fetchExams();
      this.fetchTestimonials();
      if (!this.state.isLmsHome) {
        this.fetchExamsFilters();
        this.getCoureByFilterId();
      }
    };
    getViewListAll();

    if (this.state.isLmsHome) {
      await this.getCourses(true);
    }

    MixpanelInit("Track", "home_page_viewed", "");
    const student_form_id = window.localStorage.getItem("FORM_ID");
    if (student_form_id) {
      MixpanelInit("Register", "", { form_id: student_form_id });
    }
  }

  async fetchToken() {
    // POST request using fetch with async/await
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({}),
    };
    fetch(environment.baseUrl + "genrateAuthTokentBusinessSite", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data = /* isJson && */ await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        if (data.status === 200) {
          this.apiAccessTokenLms = data.body.access_token;
          sessionStorage.setItem("apiAccessTokenLms", data.body.access_token);
          return Promise.resolve(this.apiAccessTokenLms);
          // this.getCourses();
        } else {
          console.error(data.body.message);
          return null;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  async getCourses(setFilters, extraFilter = null, loadMore = false) {
    // POST request using fetch with async/await
    // //console.log(setFilters,extraFilter);

    var filter;
    return;
    if (extraFilter) filter = extraFilter;
    else filter = this.state.selectedFilters;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.apiAccessTokenLms,
        /* "apiKey":process.env.API_KEY,
            "rootOrg":process.env.ROOT_ORG */
      },
      body: JSON.stringify({
        url: `${process.env.REACT_APP_LMS_URL}api-gw/external-consumers/allendigital-business/content-search/v6/search/external`,
        rootOrg: environment.ROOT_ORG,
        org: environment.ROOT_ORG,
        uuid: "9714b704-0f25-4dff-bff9-61ac349d2688",
        locale: ["en"],
        pageSize: 4,
        pageNo: this.state.currentpage,
        query: "",
        didYouMean: true,
        filters: [
          {
            andFilters: [filter],
            notFilters: [],
          },
        ],
        visibleFilters: {
          learningMode: {
            displayName: "Mode",
          },
          duration: {
            displayName: "Duration",
          },
          contentType: {
            displayName: "Content Type",
          },
          complexityLevel: {
            displayName: "Level",
          },
          catalogPaths: {
            displayName: "Catalog",
          },
          sourceShortName: {
            displayName: "Source",
          },
          resourceType: {
            displayName: "Format",
          },
          concepts: {
            displayName: "Concepts",
          },
          unit: {
            displayName: "Unit",
          },
          lastUpdatedOn: {
            displayName: "Published Date",
          },
        },
        sort: [
          {
            lastUpdatedOn: "desc",
          },
        ],
      }),
    };
    //this.props.changeLoading(true);
    fetch(environment.baseUrl + "callBusinessAPI", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data = /* isJson && */ await response.json();
        // check for error response
        //this.props.changeLoading(false);
        //this.setState({ courses: data.body.result});
        //console.log("dta",this.state.courses)
        if (!response.ok) {
          if (data.status === 401) {
            await this.fetchToken();
            await this.getCourses(setFilters);
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          } else {
            //console.log("ERROR Come");
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
        }
        if (data.status === 200) {
          const usedfilters = data.body.filters.filter((item) => {
            return data.body.filtersUsed.some((f) => {
              return f === item.type;
            });
          });
          if (setFilters) {
            var filtersData = data.body.filters.map((item) => {
              item.content = item.content.map((itm) => {
                itm.isChecked = false;
                if (
                  extraFilter !== null &&
                  extraFilter.learningMode[0] === itm.type
                )
                  itm.isChecked = true;
                return itm;
              });
              return item;
            });
            data.body.filters = filtersData;
          } else {
            data.body.filters = this.state.courses.filters;
          }

          //console.log('homecourse-1',data.body)
          this.setState({ courses: data.body.result });
          //
          // localStorage.setItem("userDetails", JSON.stringify(data.body));
        } else {
          toast.error(data.body.message);
        }
        //console.log(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  render() {
    return (
      <>
        <Helmet>
          <link
            rel="canonical"
            href={window.location.origin + window.location.pathname}
          />
          <meta
            property="og:title"
            content="Best Online Coaching for IIT JEE, NEET-UG | ALLEN Digital"
          />
          <meta property="og:site_name" content="ALLEN Digital" />
          <meta property="og:url" content="https://digital.allen.ac.in" />
          <meta
            property="og:description"
            content="ALLEN is the Best Coaching institute in India for JEE, NEET-UG, Classes 6th to 10th & Commerce."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://cdn.digital.allen.ac.in/be-cm/uploads/attachment-1656326610541.png"
          ></meta>
        </Helmet>
        {/* <HomeBanner handelEvent={this.state.PageContent?.banner} exams={this.state.examsList}/> */}
        <HomePageSlider />
        <HomeProgrammes
          handelEvent={this.state.PageContent?.programs}
          exams={this.state.examsList}
        />
        <HomeLegacy handelEvent={this.state.PageContent?.uniqueFetures} />
        <HomeVideo />
        <HomeContactForm handelEvent={this.state.PageContent?.contact} />
        <HomeTestimonials
          handelEvent={this.state.PageContent?.testimonials}
          testimonials={this.state.testimonialsList}
        />
        <HomeArticals handelEvent={this.state.PageContent?.resources} />
        {this.state.isLmsHome ? (
          <HomeUpcomingCoursesLms
            courses={this.state.courses ? this.state.courses : []}
            onChange={this.getCourses.bind(this)}
          />
        ) : (
          <HomeUpcomingCourses
            handelEvent={this.state.PageContent?.upcomingCourses}
            examsFilters={this.state.examsFilters}
            upcomingCourse={this.state.upcomingCourse}
          />
        )}
        {/* <HomeFAQ/> */}
        <EarlyBird />
      </>
    );
  }
}

export default Home;
