import React from "react";

const EmiTableList = (props) =>{
    var date = new Date(); // Now
    date.setDate(date.getDate() + 30); // Set now + 30 days as the new date
    let date_30 = date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear();

    var dt = new Date(); // Now
   /*  dt.setDate(dt.getDate() + 60); // Set now + 60 days as the new date
    let date_60 = dt.getDate()+"/"+(dt.getMonth()+1)+"/"+dt.getFullYear(); */
    return (
        <>
            
                <tr>
                <th>SN</th>
                <th>Price</th>
                <th>Pay Date</th>
                </tr>
                {
                    props.emiDetails.map((itm,i)=>{
                        dt.setDate(dt.getDate() + itm.installment_days);
                        date = dt.getDate()+"/"+(dt.getMonth()+1)+"/"+dt.getFullYear();
                        if(!itm.discount){itm['discount'] =0}
                        return(
                            <>
                                <tr>
                                    <td>{i+1}</td>
                                    <td>{itm.discount === 0 ? itm.installment_amount : 
                                        (
                                            <>
                                                <span className="font-weight-bold color-h4 mr-3 mb-0 ">
                                                    { "₹ " +(itm.installment_amount - itm.discount)}
                                                </span>
                                                <span className="font-weight-bold text-danger mb-0 ">
                                                    <s>{ "₹ " + itm.installment_amount}</s>
                                                </span>
                                            </>
                                        )
                                        }
                                    </td>
                                    <td>
                                        {date}
                                    </td>
                                </tr>
                            </>
                        )
                    })
                }
                
               
        </>
    )
}

export default EmiTableList;