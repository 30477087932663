import React, { useEffect, useState } from "react";
import EcomRefundOrder from "../../components/Ecom/EcomRefundOrder";
import EcomProfile from "../../components/Ecom/EcomProfile";
// import { environment } from "../../Services/HttpData";
import { Link } from "react-router-dom";

import { Oval } from "react-loader-spinner";
import noOrder from "../../images/no-order.png";
import { useHistory } from "react-router";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
const RefundOrders = () => {
  const history = useHistory();
  const [OrderItems, setOrderItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState("-");
  const auth_token = localStorage.getItem("apiAccessToken")
    ? localStorage.getItem("apiAccessToken")
    : "";


  const wid_data = window.localStorage.getItem("IS_DASHBOARD_ICON")
    ? window.localStorage.getItem("IS_DASHBOARD_ICON")
    : false;

  let authToken = "";

  // fetch url of user Profile
  // fetch url for products/courses listing
  useEffect(() => {
    refund();
  }, []);

  /* Fetch Refund Info */

  const refund = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Basic " + auth_token,
      },
    };
    fetch(
      environment.ecomApiUrl + "student/my-admissions",
      requestOptions
    ).then((resp) => {
      // fetching api url for courses listing
      resp.json().then((result) => {
        setOrderItems(result.data.records); // set result in state this will set current/updated value of setProduct to product
        //console.log("refund", OrderItems);
        setLoading(true);
      });
    });
  };

  if (!wid_data) {
    history.push("/login", { from: window.location.pathname })
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 mt-5 shopingcart">
            <div className="row m-0 aline d-flex aling-items-center justify-content-between">
              <h4 className=" cart-title mb-0" style={{ fontWeight: "500" }}>
                Course Refund
              </h4>
              {/*  <Link to="/ecom/courses/" className="ml-auto text-success cart-shop-link">
                Continue Shopping
              </Link> */}
            </div>

            {/* <span className="border-bottom-green my-order-text">Orders List</span> */}

            {loading ? (
              OrderItems?.length ? (
                <EcomRefundOrder orderItems={OrderItems} onchange={refund} />
              ) : (
                <div className="text-center">
                  <img src={noOrder} className="no-order mt-5"></img>
                  <p className="m-1">
                    <b>No course available for refund</b>
                  </p>
                  {/*   <p>Looks Like! You haven't made your order yet.</p> */}
                </div>
              )
            ) : (
              <Oval color="#00BFFF" height={80} width={80} />
            )}
          </div>
          <EcomProfile />
        </div>
      </div>
    </>
  );
};

export default RefundOrders;
