import React from 'react';

const LinkExpiry = () => {
    return (
        <div>
            <div className="container pr-0 mt-5" >
                <div className="col-md-12">
                    <div className="failed-cotent-top text-center">
                        <i className="fa-solid fa-circle-xmark"></i>
                        <h4>The Cart/Link You Followed Has Expired</h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LinkExpiry;