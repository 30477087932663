import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import highlighticon from '../../images/highlighticon.png'

const ResultPage = () => {
    const [resultData, set_ResultData] = useState([])
    function getResultData() {
        if (window?.ResultAllenData) {
            // set data
            set_ResultData(window?.ResultAllenData)
        } else {
            setTimeout(() => {
                getResultData();
            }, 100)
        }
    }
    useEffect(() => {
        getResultData()
    }, [])

    const sharetab = (e) => {
        document.querySelector("#root > div > div > div:nth-child(3) > div > div.share-wrapper > i").classList.toggle("active")
        document.querySelector("#root > div > div > div:nth-child(3) > div > div.share-wrapper > ul").classList.toggle("active")
    }

    return (
        <div>
            <Helmet>
                {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
                <title>JEE Main Result 2023 Session 1 | ALLEN Digital</title>
                <meta name="description" content="ALLEN Digital has produced amazing JEE Main Results 2023 in Session 1. Witness the remarkable performance of our top-performing students." />
                <meta property="og:title" content="JEE Main Result 2023 Session 1 | ALLEN Digital" />
                <meta property="og:site_name" content="ALLEN Digital" />
                <meta property="og:url" content="https://digital.allen.ac.in/results/jee-main-session-1/2023" />
                <meta property="og:description" content="ALLEN Digital has produced amazing JEE Main Results 2023 in Session 1. Witness the remarkable performance of our top-performing students." />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://digital.allen.ac.in/static/media/logo-new-r.4a9d1fde3fd2a6868a621b27352bda3a.svg" />
                {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
            </Helmet>
            <div className="share-wrapper">
                <i className="fa fa-share-alt share" onClick={sharetab}></i>
                <ul className="social">
                    <li id="desktopwhatsappicon"><a href={`https://web.whatsapp.com/send?text=${window.location.href}`} target="_blank" title="whatsapp"><i className="fa fa-whatsapp whatsapp"></i></a></li>
                    <li id="mobilewhatsappicon"><a href={`https://web.whatsapp.com/send?text=${window.location.href}`} target="_blank" title="whatsapp"><i className="fa fa-whatsapp whatsapp"></i></a></li>
                    <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" title="facebook"><i className="fa fa-facebook-official facebook"></i></a></li>
                    <li><a href={`https://twitter.com/intent/tweet?url=${window.location.href}`} target="_blank" title="twitter"><i className="fa fa-twitter twitter"></i></a></li>
                    <li><a href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`} target="_blank" title="linkedin"><i className="fa fa-linkedin linkedin"></i></a></li>
                    {/* <li><a href="#" target="_blank" title="google"><i className="fa fa-google-plus google"></i></a></li> */}
                    {/* <li><a href="#" target="_blank" title="instagram"><i className="fa fa-instagram instagram"></i></a></li> */}

                </ul>
            </div>

            <div className='container'>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "4%" }}>
                    <img src="https://cdn.allendigital.in/fe-bsite/result_page/resultheading11.png" className="resultheadingimg" style={{ margin: "auto", width: "35%" }} alt="resultHeading"></img>
                </div>
                <h2 className="text-center font-weight-bold mb-4 resultmaintextcentered">Meet our ALLEN Digital Champions of<br></br> <strong style={{ color: "#046635" }}>JEE Main 2023 (Session-1)</strong> in the Very First Year</h2>
                <div className='highlightstabular'>
                    <div className='highlightheading'>

                        <span style={{ color: 'white' }}>180 Students from Live Classes & OLTS Course</span><br></br> <span style={{ color: "#f5eca3" }}>scored overall 99 percentile & above in the JEE Main 2023 (Session 1)</span>

                    </div>
                    <div className='highlightcontent'>

                        <div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'> 8 students scored 100%ile in Physics</span></div>
                        <div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'> 1 student scored 100%ile in Chemistry</span></div>
                        <div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'> 2 students scored 99.99%ile & above</span></div>
                        <div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'> 18 students scored 99.9%ile & above</span></div>
                        <div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'> 180 students scored 99%ile & above</span></div>

                    </div>
                </div>
                <div className='card-main'>
                    {
                        resultData.map((data) => {
                            return (
                                <>
                                    <div className='result-card'>

                                        <img src={data.is_Img === "no" ? ('https://cdn.allendigital.in/fe-bsite/results/NO_IMAGE_FOUND.png') : (`https://cdn.allendigital.in/fe-bsite/results/jee-main/2023/session_1/${data["Form_No."]}.png`)} alt='temp' className='resultimages'></img>
                                        <div>
                                            <span className='percentage'>{data.Percentile} %ile</span><br />
                                            <span className='resstudentname'>{data.Corrected_Names}</span><br />
                                            <span className='adfno'>({data.Program})</span>
                                        </div>
                                    </div>

                                </>
                            )
                        })
                    }


                </div>
                {/* <img className='mainresultimagebig' src={gg}></img> */}
                <div>
                    <img className='jeemainresultfooter' src="https://cdn.allendigital.in/fe-bsite/result_page/desktop_result_banner.png" style={{ width: "100%", marginTop: "50px" }}></img>
                </div>
            </div>
        </div>
    );
};

export default ResultPage;