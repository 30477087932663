import React from 'react';
class ConatctUsBanner extends React.Component {
    constructor(props) {
      super(props);
      //console.log(this.props);
      this.state = {
        PageContent: [],
      };
    }
    componentWillReceiveProps(props){
        //console.log(props.handelEvent);
        this.setState({PageContent: props.handelEvent});
    }
    render() { 
        return <>
        <div className="">
        <div className="banner-3" style={{paddingTop: "150px"}}>
            <div className="container banner-content">
                <h3 style={{fontWeight: "bolder"}}>{this.state.PageContent?.title}</h3>
            </div>
        </div>
    </div>
        </>;
    }
}
 
export default ConatctUsBanner;