import React from "react";
import { Link } from "react-router-dom";
import { encode as base64_encode } from "base-64";
// import { environment } from "../../Services/HttpData";
import { toast } from "react-toastify";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)

const CourseGrid = (props) =>{


  const addToCart = (item, buyNow = false) => {
     const skvDetails = JSON.parse(sessionStorage.getItem("skavaTokenDetails"));

    const wid_data =  localStorage.getItem("userWID") ? JSON.parse( localStorage.getItem("userWID") ) : null;
  let wid =   null;
  if(wid_data){
      if(wid_data.results){
          if(wid_data.results.length){
              if(wid_data.results[0].wid){
                  wid = wid_data.results[0].wid;
              }
          }
      }
  }
    // //console.log(skvDetails.sessionId);
    if (skvDetails !== null && skvDetails.sessionId !== undefined && wid) {
   /*  if (true) { */
      props.changeLoading(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          apiKey: skvDetails.sessionId,
        },
        body: JSON.stringify({
          "cartItem": [
            {
              "quantity": 1,
              "skus": [
                {
                  "productId": item.productId,
                  "skuId": item.productId,
                  "type": "DEFAULT"
                }
              ],
              "properties": {
                "imageURL": item.appIcon,
                "name": item.name,
                "link": `${process.env.REACT_APP_LMS_URL}en/app/toc/${item.identifier}/overview`,
                "fulfillmentType": "DIGITAL"
              }
            }
          ],
          "properties": {
            "enquiryId": "",
            "callerRef": "business"
          }
        })
      };
      fetch(environment.baseUrl + "addToCartSkSite", requestOptions)
        .then(async (response) => {
          // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
          const data = /* isJson && */ await response.json();
          // check for error response
          // //console.log(response);
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
          setTimeout(() => {
          props.changeLoading(false);            
          }, 500);
          if (response.ok) {
            if (response.status === 201) {
              toast.success("Item Added to Cart");
              
              if (buyNow){
                window.location = environment.skavaBaseUrl + "?return_url=" + environment.REACT_APP_BASE_URL
              }
              else{
                props.handleCartCount('reloadCarts');
              }
            }
            else if (response.status === 200 && buyNow)  {
              
              if( data.purchased ){
                toast.error(data.message);
              }
              else{
                window.location = environment.skavaBaseUrl + "?return_url=" + environment.REACT_APP_BASE_URL
              }
            }
            else {
              toast.error(data.message);
            }
            // this.setState({cartItems:data.items,cartDetails:{value:data.value,currencySign:data.currencySign}})
            //console.log(data);
          }
        })
        .catch((error) => {
          props.changeLoading(false);
          toast.error("There was an error!");
          //this.setState({ errorMessage: error.toString() });
          console.error("There was an error!", error);
        });
    }
    else {
      toast.warning("Please login first.");
    }
  }

    const getPercantage  = (price, total)=> {
        return 100 - (price / total) * 100;
      }

    const item = props.item;
    
    return(
        <>
         <div
                      className="card boder-card m-0 hover-card gridbox"
                      data-toggle="popover"
                      data-trigger="hover"
                      data-placement="right"
                      data-html="true"
                      to="#login"
                    >
                      <div className="img">
                        <Link
                          to={`/courses-details/${base64_encode(
                            item?.identifier
                          )}`}
                        >
                          <img
                            src={item?.appIcon}
                            alt=""
                            className="img-fluid mb-2"
                          />
                        </Link>
                      </div>
                      <div className="card-content px-0 py-2">
                        <Link
                          to={`/courses-details/${base64_encode(
                            item?.identifier
                          )}`}
                          className="h6 font-weight-bold cut-text-courses-grid"
                        >
                          {item?.name}
                        </Link>
                        <p className="cut-text-allcourse">{item.description}</p>

                        <div className="d-flex justify-content-start padding-bottom-one">
                          <div>
                            <h5>
                              {item?.salesPrice?.currency === "INR"
                                ? "₹"
                                : item?.salesPrice?.currency}
                              {item?.salesPrice?.value}
                            </h5>
                          </div>
                          {item?.price?.value > item?.salesPrice?.value && (
                            <div className="ml-2">
                              <h5 className="text-danger">
                                <s>
                                  {item?.price?.currency === "INR"
                                    ? "₹"
                                    : item?.price?.currency}
                                  {item?.price?.value}
                                </s>
                              </h5>
                            </div>
                          )}
                        </div>
                        {item?.price?.value > item?.salesPrice?.value ? (
                          <p
                            className="my-0 font-weight-bold save-price"
                            
                          >
                            Save
                            {item?.price?.currency === "INR"
                              ? "₹"
                              : item?.price?.currency}
                            {item?.price?.value - item?.salesPrice?.value} (
                            {getPercantage(
                              item?.salesPrice?.value,
                              item?.price?.value
                            ).toFixed(2)}
                            % OFF)
                          </p>
                        ):(
                          <>
                            <p className="save-price-blank d-none"></p>
                          </>
                        ) }

                        {/* <div className="row mx-0 mt-2 justify-content-between three-lines-courses-box">
                          {item?.complexityLevel && (<p className="mr-1 font-size18">{item?.complexityLevel}</p>)}
                          {item?.learningMode && (<p className="mr-1 font-size18">{item?.learningMode}</p>)}
                          {item?.contentType && (<p className="mr-1 font-size18">{item?.contentType}</p>)}
                        </div> */}
                        <div className="d-flex flex-wrap justify-content-between">
                          <span className="nav-link p-0 mb-2">
                            <button
                              type="button"
                              className="btn btn-outline-primary  nav-btn-outline"
                              style={{ fontSize: "13px" }}
                              onClick={() => addToCart(item,true)}
                            >
                              Buy Now
                            </button>
                          </span>
                          <span className="nav-link p-0 mb-2">
                            <button
                              type="button"
                              className="btn btn-primary nav-btn"
                              style={{ fontSize: "13px" }}
                              onClick={() => addToCart(item)}
                            >
                              Add to Bag
                            </button>
                          </span>
                        </div>
                      </div>

                     
                    </div>
        </>
    )
}

export default CourseGrid;
