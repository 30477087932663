import React, { useState } from 'react';
import { saveAs } from "file-saver";
class ArticlePdfDownload extends React.Component {
    state = {}
    render() {
        const saveFile = () => {
            saveAs(
              this.props.data?.download_link,
              this.props?.data?.title?this.props?.data?.title+".pdf":"example.pdf"
            );
          };
        return <>
            <div className="article-download-card">
                {(this.props.data?.description && this.props.data?.description !== "") && (<p className="article-download-paragraph" dangerouslySetInnerHTML={{
                    __html: this.props.data?.description
                }}></p>)}
                {(this.props.data?.title && this.props.data?.title !== "") && <h2 className="article-download-heading">{this.props.data?.title}</h2>}
                <button className='btn btn-article-pdf-download' onClick={saveFile} download>Download PDF</button>
            </div>
            <div className="article-download-pdf-frame mb-4">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src={this.props.data?.download_link} allowFullScreen></iframe>
                </div>
                {/* <Document
                    file={artclePDF}
                    // onLoadSuccess={onDocumentLoadSuccess}
                >
                </Document> */}
            </div>
        </>;
    }
}

export default ArticlePdfDownload;