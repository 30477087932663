import React from 'react'
// import { environment } from './../../Services/HttpData';
import { encode as base64_encode } from "base-64";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import CourseGrid from "../Courses/CourseGrid";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class SimilarCourses extends React.Component {
    constructor(props) {
      super(props);
      // //console.log(this.props);
      this.apiAccessToken = sessionStorage.getItem("apiAccessToken");
      this.state = {
        similarCoursesList: []
      };
    }
    componentDidMount() {
      //console.log('my-det',this.props);
     /*  const slug = this.props.match.params.slug;
      const id = this.props.match.params.id;
      //console.log(slug, base64_decode(id));
      this.getCoursDetails(base64_decode(id)); */
      if(this.props.handelEvent){
        this.getCoursDetails(this.props.handelEvent);
      }
    }
    componentWillReceiveProps(props) {
    //   this.setState({ detailsData: props.handelEvent });
      //console.log(props.handelEvent);
      
    }
    
  getPercantage(price, total) {
    return 100 - (price / total) * 100;
  }
    async getCoursDetails(id) {
      // POST request using fetch with async/await
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.apiAccessToken,
          wid:environment.WID
          /* "apiKey":process.env.API_KEY,
                "rootOrg":process.env.ROOT_ORG */
        },
        body: JSON.stringify({
          url: `${process.env.REACT_APP_LMS_URL}api-gw/external-consumers/allendigital-business/more-like-this-service/api/v1/moreLikeThis/${id}`,
        }),
      };
      fetch(environment.baseUrl + "callBusinessSiteWithWID", requestOptions)
        .then(async (response) => {
          // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
          const data = /* isJson && */ await response.json();
          // check for error response
          //console.log(response);
          if (!response.ok) {
            if (data.status === 401) {
              await this.fetchToken();
              await this.getCoursDetails(id);
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            } else {
              //console.log("ERROR Come");
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
          }
          if (data.status === 200) {
            //console.log(data.body);
            this.setState({ similarCoursesList: data.body.result.response});
          //   localStorage.setItem("similarCoursesList", JSON.stringify(data.body));
          } else {
            toast.error(data.body.message);
          }
          // //console.log(data);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
    addToCart(item, buyNow = false){
      //console.log(item);
    const skvDetails = JSON.parse(sessionStorage.getItem("skavaTokenDetails"));
    // //console.log(skvDetails.sessionId);
    if(skvDetails!==null && skvDetails.sessionId!==undefined){
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apiKey: skvDetails.sessionId,
      },
      body:JSON.stringify({
        "cartItem": [
            {
                "quantity": 1,
                "skus": [
                    {
                        "productId": item.productId,
                        "skuId": item.productId,
                        "type": "DEFAULT"
                    }
                ],
                "properties": {
                    "imageURL": item.appIcon,
                    "name": item.name,
                    "link": `${process.env.REACT_APP_LMS_URL}en/app/toc/${item.identifier}/overview`,
                    "fulfillmentType": "DIGITAL"
                }
            }
        ],
        "properties": {
            "enquiryId": "",
            "callerRef": "business"
        }
    })
    };
    fetch(environment.baseUrl + "addToCartSkSite", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data = /* isJson && */ await response.json();
        // check for error response
        // //console.log(response);
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        if (response.ok) {
            if(response.status===201){
            toast.success("Item Added to Cart");
            this.props.onChange("reloadCart");
            if (buyNow)
              window.location = environment.skavaBaseUrl + "checkout?return_url=" + environment.REACT_APP_BASE_URL
          }
          else{
            toast.error(data.message);
          }
            // this.setState({cartItems:data.items,cartDetails:{value:data.value,currencySign:data.currencySign}})
          //console.log(data);
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: error.toString() });
        console.error("There was an error!", error);
      });
      }
      else{
        toast.warning("Please login first.");
      }
    }
    render() { 
        return <>
            {this.state.similarCoursesList.length>0 && (<div className="container">
                <h2 className="font-weight-bold text-center my-5">SIMILAR COURSES</h2>
                <div className="row">
                    {this.state.similarCoursesList && this.state.similarCoursesList.map((item, indx) => (
                     <div className="col-lg-3 col-md-3 col-sm-12 mb-3"  key={indx}>
                      <CourseGrid item = {item} />
                     </div>
                    )
                    )}
                </div>
            </div>)}
        </>;
    }
}
 
export default SimilarCourses;