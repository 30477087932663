import React,{ useState, useEffect  } from 'react'
import { Link } from 'react-router-dom'

export default function JEEScholarship(props) {
   const [utmSource, setUtmSource] = useState("")
   const [utmMedium, setUtmMedium] = useState("")
   const [utmCampaign, setUtmCampaign] = useState("")

   const getprams = () => {

      const searchParams = new URLSearchParams(document.location.search)
      let utm_source = searchParams.get('utm_source')
      if(utm_source !== null){setUtmSource(utm_source)}
      let utm_medium = searchParams.get('utm_medium')
      if(utm_medium !== null){setUtmMedium(utm_medium)}
      let utm_campaign = searchParams.get('utm_campaign')
      if(utm_campaign !== null){setUtmCampaign(utm_campaign)}
   }

   useEffect(()=>{
      getprams()
   },[])
return (
<>
<div className="container mt-5 jee-scholarship">
   <div className="row">
      <div class="col-12 col-md-12">
         <div className='title'>How to Apply <span className='color-green'>for Scholarships </span></div>
         <ol type='1' className='jee-scholarship-listing mt-2'>
<li>Scholarship Eligibility Criteria are mentioned below.</li>
<li>Scholarship request made verbally or through phone / email / fax shall not be entertained in any case.</li>
<li>To avail the scholarship, student has to submit required Documents (as mentioned below).</li>
<li>Last date to apply for scholarship is <b className='star-red'>31 August'23</b>. No application for scholarship there after shall be accepted in any case.</li>
         </ol>
      </div>
      <div class="col-12 col-md-12 mt-4">
         <div className='title'>Important <span className='color-green'>Information:</span></div>
         <ol type='1' className='jee-scholarship-listing mt-2'>
{/* <li>Students of whom Result of JEE (Main) 2023 / JEE Advanced 2023 is awaited at the time of Admission can also Apply for Scholarship at later stage (before last date) with the below mentioned documents, if eligible.</li>
<li>In case of Applying for Scholarship after taking Admission you can avail scholarship by applying for Scholarship Disbursement.</li>
<li>Disbursement of Scholarship will be credited in Father / Mother / Student Bank Account by <b className='star-red'>31 August'23</b> or 1 month from the date of Application (whichever is later) through NEFT / RTGS only. For this you have to submit cancelled cheque or bank passbook first page copy along with required Documents.</li> */}
<li>Scholarship provided on the basis of JEE (Main) 2023 / JEE (Advanced) 2023 will be provided only in Current Academic Session (2023-24) only.</li>
         </ol>
         <p className='mt-4 mb-0'><b>A student is eligible for only one scholarship at a time, No two criteria can be clubbed to avail deduction in Fee at ALLEN.</b></p>
      </div>
      <div class="col-12 col-md-12 mt-4">
         <div className="jee-scholarship-table-title text-center">
         Based on CRL (AIR) in JEE (MAIN) 2023 - Live
         </div>
         <div className="table-responsive">
            <table class="table table-bordered jee-scholarship-table">
               <thead>
                  <tr>
                     <th>JEE (MAIN) 2023 CRL Rank (AIR) </th>
                     <th>Scholarship</th>
                     <th>JEE (MAIN) 2023 CRL Rank (AIR) </th>
                     <th>Scholarship</th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td className='font-weight-500'>CRL Rank upto 5000</td>
                     <td>90%</td>
                     <td className='font-weight-500'>40001 to 60000</td>
                     <td>25%</td>
                  </tr>
                  <tr>
                     <td className='font-weight-500'>5001 to 8000</td>
                     <td>75%</td>
                     <td className='font-weight-500'>60001 to 100000</td>
                     <td>20%</td>
                  </tr>
                  <tr>
                     <td className='font-weight-500'>8001 to 12000</td>
                     <td>60%</td>
                     <td className='font-weight-500'>100001 to 150000</td>
                     <td>15%</td>
                  </tr>
                  <tr>
                     <td className='font-weight-500'>12001 to 25000</td>
                     <td>50%</td>
                     <td className='font-weight-500'>150001 to 200000<br />
                     + All Eligible for JEE Adv. 2023</td>
                     <td>10%</td>
                  </tr>
                  <tr>
                     <td className='font-weight-500'>25001 to 40000</td>
                     <td>40%</td>
                     <td colSpan="2"></td>
                  </tr>
                  <tr>
                     <td colSpan="4" className='text-center color-green'><b>Last date to avail scholarship: 31 August'23</b></td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
      {/* <div class="col-12 col-md-12 mt-4">
         <div className="jeescholarship-live">
             <div className="text-center">
               <div className="title-head"><i>JEE (MAIN+ADVANCED)</i></div>
               <div className="title-bg mt-2">LEADER (Live)</div>
               <div className="jeescholarship-live-first">
                  <div className="phase-wrap-container">
                  <div className="phase"><span className='font-weight-500'>Medium</span><i> :  English + Hindi</i></div>
                     <div className="pahse-data">
                          <div className="phase-wrap">
                           <div className="phase">Phase 7 </div>
                           <div className="pahsedate">21 JULY'23</div>
                        </div>
                     </div>                    
                  </div>
               </div>
            </div> 
            <div className="text-center">
               <div className="title-head"><i>JEE (MAIN)</i></div>
               <div className="title-bg mt-2">LEADER (Live)</div>
               <div className="jeescholarship-live-first">
                  <div className="phase-wrap-container">
                  <div className="phase"><span className='font-weight-500'>Medium</span><i> :  English + Hindi</i></div>
                     <div className="pahse-data">
                                  <div className="phase-wrap">
                           <div className="phase">Phase 6 </div>
                           <div className="pahsedate">12 JULY'23</div>
                        </div>
                     </div>
                     
                  </div>
               </div>
            </div> 
         </div>
      </div> */}
      <div className="col-12 mt-4">
         <div className="jeescholarship-list-wrap">
            <div className="jeescholarship-list">
               <img src='https://cdn.digital.allen.ac.in/fe-bsite/images/check-mark.png' />
               Learn from comfort of home
            </div>
            <div className="jeescholarship-list">
               <img src='https://cdn.digital.allen.ac.in/fe-bsite/images/check-mark.png' />
               ALLEN's proven pedagogy
            </div>
            <div className="jeescholarship-list">
               <img src='https://cdn.digital.allen.ac.in/fe-bsite/images/check-mark.png' />
               Best academics with best technology
            </div>
         </div>
      </div>
      <div className="col-12 mt-4 mt-md-5 text-center">
         <a href={`https://cloud.engage.allendigital.in/scholarships-jee-main?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`} class="request-call-back">Request a Call Back</a>
      </div>
      <div className="col-12 mt-2 text-center pahsedate">
         Or
      </div>
      <div className="col-12 mt-2 text-center call-text">
         Call: <a href='tel:+91-95137-36491'>+919513736491</a>
      </div>
      <div className="col-12 mt-4">
         <div className="row no-gutters">
            <div className="col-12 col-md-4">
               <div className="document-wrap">
                  <div className="d-inline-flex text-center text-md-left">
                     DOCUMENTS <br className='d-none d-md-block' /> REQUIRED <br className='d-md-none' /> FOR <br className='d-none d-md-block' /> SCHOLARSHIP
                  </div>
               </div>
            </div>
            <div className="col-12 col-md-8">
               <div className="document-wrap-right">
                  <img src='https://cdn.digital.allen.ac.in/fe-bsite/images/photo_id.png' />
                  <img src='https://cdn.digital.allen.ac.in/fe-bsite/images/admit_card.png' />
                  <img src='https://cdn.digital.allen.ac.in/fe-bsite/images/result_card.png' />
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</>
)
}