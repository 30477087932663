import React, {useState} from "react";

const EcomCartBatch = (props) => {
    const [selectedBatch, setSelectedBatch] = useState(props.selected_batch);
    const onValueChange = (event,pre) => {
     setSelectedBatch(event.target.value);
     props.onBatchSave(event.target.value,props.cart_item_id);
      }
      let available_seats=0;
    return(
        <>
        {/*  <h4>{JSON.stringify(props.batch)}</h4> */}
    
          {
                
                ((props.batch?props.batch:[])).map((itm,i)=>{
                     available_seats = (itm.batch_capacity - itm.batch_allocated_seats);
                  return(
                    <>
                     
                     <div className="container">
                          <div className="row border-bottom pb-3">
                            <div className="col-md-1 col-1 d-flex align-items-center">
                            <input type="radio" name="chkBatch" value={itm.batch_id}  checked={selectedBatch==itm.batch_id} onChange={onValueChange} />
                            </div>

                            <div className="col-md-6 col-6">
                            <strong>{itm.batch_name}</strong><br />
                            <span>{"Batch Date : " + itm.batch_start_date}</span>
                            </div>

                            <div className="col-md-5 col-5">
                               {available_seats >= "0" ? 
                               <button className="btn mobile-top-margin not-btn">Available Seats {available_seats} </button>: 
                              <button >Full</button>}
                              </div>
                              </div>
                          </div>
                         
                      
                    </>
                    
                  );
                  
                })
              }
         
        </>
    )
}

export default EcomCartBatch;