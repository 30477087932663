import React from "react";
// import { environment } from "../../Services/HttpData";
import { toast } from "react-toastify";
import hdfclogo from "../../images/hdfc-logo.png";
import razorpay from "../../images/razor-pay-logo.png";


const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
export default function RazorPayApiInti(props) {
  // console.log(props)
  const type = props.type;


  const auth_token = localStorage.getItem("apiAccessToken")
    ? localStorage.getItem("apiAccessToken")
    : "";
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const getNewCourse = async () => {
    props?.setLoading(true)
    let userDetails;
    const requestOptionsUser = {
      method: "GET",
      headers: {
        Authorization: "Basic " + auth_token,
      },
    };
    await fetch(
      environment.ecomApiUrl + "user/detail",
      requestOptionsUser
    ).then((resp) => {
      // fetching api url for courses listing
      resp.json().then((result) => {
        //setCartItems(result.data.items);  // set result in state this will set current/updated value of setProduct to product
        userDetails = result.data;
        //  console.log(userDetails);
      }).catch((err) => {
        props?.setLoading(false)
      });
    });
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + auth_token,
      },
      body: JSON.stringify({
        type: type,
        order_id: props?.payment_gateway_data?.order_id
      }),
    };
    fetch(environment.ecomApiUrl + "propelled/razrorpay-hdfc/order", requestOptions).then((resp) => {
      // fetching api url for courses listing
      resp.json().then((result) => {
        // console.log(result);
        //setOrderId(result.data.order_id);
        // console.log(result);
        if (result?.data[0]?.order_id) {
          // setResponse(result.data.gateway_responce);
          if (type == "razorpay") {
            let options = {
              key: environment.razorpayKey,
              //"amount": 100.00,
              currency: "INR",
              name: "",
              description: "",
              image:
                environment.baseUrlImg + "attachment-1632935528953.png",
              order_id: result?.data[0]?.payment_gateway_response?.id,
              handler: function (response) {
                if (result?.data[0]?.IS_WEBHOOK === 1) {
                  setTimeout(() => {
                    window.location =
                      "/payment/status/" + result?.data[0]?.order_id;
                  }, 6000);
                } else {
                  // console.log(response)
                  // console.log(response);
                  const requestOptions1 = {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Basic " + auth_token,
                    },
                    body: JSON.stringify({
                      order_id: result?.data[0]?.order_id,
                      gateway_order_id: response.razorpay_order_id,
                      gateway_payment_id: response.razorpay_payment_id,
                      gateway_signature: response.razorpay_signature,
                    }),
                  };
                  // console.log(requestOptions1)
                  fetch(
                    environment.ecomApiUrl + "propelled/verify/razorpay/payment",
                    requestOptions1
                  ).then((resp) => {
                    // fetching api url for courses listing
                    resp.json().then((result1) => {
                      //   console.log(result1);
                      /* if(result1.status == "success"){ */
                      //toast.success(result1.message);
                      if (typeof orderId == "undefined") {
                        window.location =
                          "/payment/status/" + result?.data[0]?.order_id;
                      } else {
                        // window.location =
                        //   "/ecom/order-installment/status/" +
                        //   result.data.installment_id;
                      }
                    }).catch((error) => {
                      props?.setLoading(false)
                    });
                  });
                }
              },
              prefill: {
                contact: userDetails.student_mobile_no,
                name: userDetails.student_first_name,
                email: userDetails.student_email,
              },
              theme: {
                color: "#2300a3",
              },
              modal: {
                ondismiss: function () {
                  //   console.log("Checkout form closed");
                  if (result?.data[0]?.IS_WEBHOOK === 1) {
                    const requestOptions = {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: "Basic " + auth_token,
                      },
                      body: JSON.stringify({
                        order_id: result.data[0].order_id
                      }),
                    };
                    fetch(
                      environment.ecomApiUrl + "propelled/make/failed/odrer",
                      requestOptions
                    ).then((resp) => {
                      // fetching api url for courses listing
                      resp.json().then((result1) => {
                        window.location =
                          "/ecom/order/status/" + result.data[0].order_id;
                      });
                    });
                  } else {
                    if (typeof orderId == "undefined") {
                      window.location =
                        "/payment/status/" + result?.data[0]?.order_id;
                    } else {
                      //   setLoading(true);
                    }
                  }

                },
              },
            };
            let razorpayObject = new window.Razorpay(options);
            //   console.log(razorpayObject);
            razorpayObject.on("payment.failed", function (response) {
              //  console.log(response);
              toast.error("This step of Payment Failed");
              window.location =
                "/payment/status/" + result?.data[0]?.order_id;
            });
            razorpayObject.open();
          } else {
            let f = document.createElement('form');
            f.action = environment.ccavenue;
            f.method = 'POST';

            let i = document.createElement('input');
            i.type = 'hidden';
            i.name = 'encRequest';
            i.value = result?.data[0]?.encResp;
            f.appendChild(i);

            let j = document.createElement('input');
            j.type = 'hidden';
            j.name = 'access_code';
            j.value = environment.hdfcKey;
            f.appendChild(j);

            document.body.appendChild(f);
            f.submit();
          }
        } else {
          props?.setLoading(false)
        }
      });
    });
  };

  return (type == "razorpay" ? <button className="continue-with-razor" onClick={getNewCourse} ><span> Continue With</span> <img src={razorpay}></img></button> :
    <button className="continue-with-hdfc mt-2" onClick={getNewCourse}><span> Continue With</span> <img src={hdfclogo}></img></button>
  );
}
