import React, { useState, useEffect } from "react";
// import { environment } from "../../Services/HttpData";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { Rating } from "react-simple-star-rating";
import * as imageConversion from "image-conversion";
import { ThreeDots } from "react-loader-spinner";

const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);
const EcomRefundOrder = (props) => {
  let subtitle = {};
  let payDue = props.pending_due;

  /* Star Rating */
  const [rating, setRating] = useState(0);

  const auth_token = localStorage.getItem("apiAccessToken")
    ? localStorage.getItem("apiAccessToken")
    : "";

  const [modalIsFirstOpen, setIsFirstOpen] = useState(false);
  const [modalIsOpenFirstConfirm, setIsOpenFisrtConfirm] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalViewIsOpen, setViewIsOpen] = useState(false);
  const [modalIsOpenConfirm, setIsOpenConfirm] = useState(false);
  const [modalCancelIsOpen, setCancelIsOpen] = useState(false);
  const [studentId, setStudentId] = useState();
  const [refundId, setRefundId] = useState();
  const [remark, setremark] = useState(" ");
  const [remarkError, setRemarkError] = useState("");
  const [refundQuestions, setRefundQuestions] = useState([]);
  const [refundQuestionsCopy, setRefundQuestionscpoy] = useState([]);
  const [netFees, setNetFees] = useState("");
  const [refundableFees, setRefundableFees] = useState("");
  const [reasons, setReasons] = useState([]);
  const [tnc, setTnc] = useState(0);
  const [refundViewData, setRefundViewData] = useState([]);
  const [terms_conditions_button, set_terms_conditions_button] = useState(true);
  const [isDocUpload, setIsDocUpload] = useState(false);
  const [IFSCCodeData, setIFSCCodeData] = useState({});
  const [IFSCValidate, setIFSCValidate] = useState(false);
  const [FirtTypeIS, setIsFirstType] = useState(false);
  let feeAmountIsZero = false

  const refundFromData = {
    account_name: "",
    relation_with_student: "",
    bank_name: "",
    account_no: "",
    confirm_account_no: "",
    ifsc_code: "",
    document_path: "",
    refund_reason: "",
  };
  const [refundInput, setRefundInput] = useState(refundFromData);
  const [refundReason, setrefundReason] = useState("0");
  const popRefundInput = (e) => {
    // console.log(e);
    const { name, value } = e.target;
    setRefundInput((pre) => {
      return { ...pre, [name]: value };
    });

    if (name === "ifsc_code" && value?.trim()?.length === 11) {
      const requestOptionsRefund = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + auth_token,
        },
        body: JSON.stringify({
          IFSC: value
        }),
      };
      fetch(process.env.REACT_APP_AUTH_URL + "/ifsc/get-ifsc", requestOptionsRefund).then((res) => {
        res.json().then((data) => {
          if (data?.status === "success") {
            setIFSCCodeData(data?.data);
            setRefundInput({ ...refundInput, bank_name: data?.data?.BANK, ifsc_code: value });
            setIFSCValidate(true);
          } else {
            setIFSCCodeData({});
            setIFSCValidate(false);
            toast.error(data?.message);
          }
        })

      }).catch((error) => {

        console.log(error);
      })
    } if (name === "ifsc_code" && value?.trim()?.length !== 11) {
      setIsFirstType(true)
      setIFSCValidate(false);
      setIFSCCodeData({});

    }
    // console.log(refundInput);
  };

  const relationStudentSet = (e) => {
    setRefundInput((pre) => {
      return { ...pre, ["relation_with_student"]: e.target.value };
    });
  };

  const submitRefundData = () => {
    const validAccountNo = new RegExp("^[0-9]+$");
    const validAccountname = new RegExp("[a-zA-Z]{3,50}");
    const validBankname = new RegExp("[a-zA-Z]{3,30}");
    // const validRelation = new RegExp("[a-zA-Z]{3,30}");

    if (!validAccountname.test(refundInput?.account_name)) {
      toast.error("Invalid account holder name");
      return false;
    } else if (refundInput?.relation_with_student === "") {
      toast.error("Invalid relation detail");
      return false;
    } else if (!validBankname.test(refundInput?.bank_name)) {
      toast.error("Invalid bank name");
      return false;
    } else if (
      !validAccountNo.test(refundInput?.account_no) ||
      refundInput?.account_no.split("+").length > 1 ||
      refundInput?.account_no.split("-").length > 1 ||
      refundInput?.account_no.split(".").length > 1 ||
      refundInput?.account_no.length < 5 ||
      refundInput?.account_no.length > 20
    ) {
      toast.error("Invalid account number");
      return false;
    } else if (refundInput.account_no !== refundInput.confirm_account_no) {
      toast.error("Account number mismatch");
      return false;
    } else if (refundInput?.ifsc_code.length < 9) {
      toast.error("Invalid IFSC Code");
      return false;
    } else if (!IFSCValidate) {
      toast.error("IFSC Code is not valid");
      return false;
    }
    else if (refundInput?.refund_reason?.trim() === "") {
      toast.error("Please Enter Refund Reason");
      return false;
    } else if (refundInput?.document_path === "") {
      toast.error("Please upload file");
      return false;
    } else {
      toast.success("Detail Submitted");
      document.querySelector(".close-Deductable-fee-modal-a").click();
      document.querySelector(".allen-model-open-class").click();
    }
  };

  const refundQuestionsGet = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Basic " + auth_token,
      },
    };
    fetch(
      environment.ecomApiUrl + "student/refund-questions",
      requestOptions
    ).then((resp) => {
      // fetching api url for courses listing
      resp.json().then((result) => {
        result.data.map((value, key) => {
          result.data[key].answer = 0;
        });
        setRefundQuestions(result.data);
        setRefundQuestionscpoy([...result.data]);
      });
    });
  };

  const getReasons = () => {
    const requestOptionsReasons = {
      method: "GET",
      headers: {
        Authorization: "Basic " + auth_token,
      },
    };
    fetch(
      environment.ecomApiUrl + "student/refund-reasons",
      requestOptionsReasons
    ).then((resp) => {
      // fetching api url for courses listing
      resp.json().then((result) => {
        setReasons(result.data);
        //console.log("reasons", result.data);
      });
    });
  };

  /* Confrim Modal */
  function openModalFirstConfirm(
    studentId,
    studentNetFees,
    studentRefundableFees
  ) {
    setIsOpenFisrtConfirm(true);
    setStudentId(studentId);
    setNetFees(studentNetFees);
    setRefundableFees(studentRefundableFees);
  }
  /* 2nd Confrim Modal */
  function openModalConfirm() {
    setremark(" ");
    set_terms_conditions_button(true);
    setIsOpenFisrtConfirm(false);
    setIsOpenConfirm(true);
    document.querySelector(".refund-doc-upload-input-box").value = "";
    document.querySelector(".relation_with_student").value = "";
    setRefundInput(refundFromData);
    setRefundQuestions(refundQuestionsCopy);
    setIFSCCodeData({});
    setIFSCValidate(false);
    document.querySelector(".refund-remrak-pop-input").value = "  ";
  }

  function closeModalFirstConfirm() {
    setIsOpenFisrtConfirm(false);
  }

  function closeModalConfirm() {
    setremark(" ");
    setIsOpenConfirm(false);
    setRefundInput(refundFromData);
    setIFSCCodeData({});
    setIFSCValidate(false);
    document.querySelector(".relation-with-student").value = "";
    document.querySelector(".refund-doc-upload-input-box").value = "";
  }

  function afterOpenFirstModalConfirm() {
    // references are now sync'd and can be accessed.
    // subtitle?.style?.color = "#046635";
  }

  function afterOpenModalConfirm() {
    // references are now sync'd and can be accessed.
    // subtitle?.style?.color = "#046635";
  }

  /* Avail Refund popup */
  function openModal(studentId) {
    //  let valid= submitRefundData();
    //  if(!valid){
    //  return false;
    //  }
    setIsOpen(true);
    //setStudentId(studentId)
    setIsOpenConfirm(false);
    setRefundInput(refundInput);
  }

  function closeModal() {
    setRefundQuestions([]);
    setIsOpen(false);
    setremark(" ");
    set_terms_conditions_button(true);
    refundQuestionsGet();
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#046635';
  }

  /* Cancel Refund Popup */

  function openCancelModal(studentId) {
    setCancelIsOpen(true);
    setRefundId(studentId);
  }

  function closeCancelModal() {
    setCancelIsOpen(false);
  }

  function afterCancelOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#c82333";
  }

  // view popup
  function openViewModal(id) {
    refundView(id);
    setViewIsOpen(true);
    //setStudentId(studentId)
    setIsOpenConfirm(false);
  }

  function closeViewModal() {
    setViewIsOpen(false);
  }

  function afterOpenViewModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#046635';
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
      backgroundColor: "rgb(0 0 0 / 75%)",
    },
    overlay: {
      background: "rgb(0 0 0 / 75%)",
    },
  };

  const handleRating = (rate, i) => {
    let questions = [...refundQuestions];
    questions[i].answer = rate;
    setRefundQuestions(questions);
  };
  const handleEvent = (e) => {
    const { name, value } = e.target;
    if (name === "tnc") {
      setTnc(value);
      set_terms_conditions_button(!e.target.checked);
    } else if (name === "reason_id") {
      setrefundReason(value);
    } else {
      setremark(value);
    }
  };

  function myRefund() {
    if (
      refundQuestions[0].answer > 0 &&
      refundQuestions[1].answer > 0 &&
      refundQuestions[2].answer > 0 &&
      refundQuestions[3].answer > 0 &&
      refundQuestions[4].answer > 0
    ) {
      const requestOptionsRefund = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + auth_token,
        },
        body: JSON.stringify({
          ...refundInput,
          bank_city: IFSCCodeData?.CITY,
          id: studentId,
          remark: remark,
          reason_id: parseInt(refundReason),
          questions: refundQuestions,
        }),
      };
      fetch(
        environment.ecomApiUrl + "student/refund-initiate",
        requestOptionsRefund
      ).then((resp) => {
        // fetching api url for courses listing
        resp.json().then((result) => {
          if (result.status === "success") {
            document.querySelector(".close-Deductable-fee-modal").click();
            setRefundQuestions([]);
            toast.success(result.message);
            setIsFirstOpen(false);
            setIsOpen(false);
            setRefundInput(refundFromData);
            setrefundReason("0");
            refundQuestionsGet();
            setremark(" ");
            setIFSCCodeData({});
            setIFSCValidate(false);
          } else {
            if (result?.message) {
              toast.error(result?.message);
            } else {
              toast.error(result.data[0].msg);
            }

          }

          props.onchange();
        });
      });
    } else {
      toast.error("Please fill in all the required fields");
    }
  }

  function cancelRefund() {
    setCancelIsOpen(false);
    const requestOptionsRefund = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + auth_token,
      },
      body: JSON.stringify({
        refund_id: refundId,
      }),
    };
    fetch(
      environment.ecomApiUrl + "student/refund-cancel",
      requestOptionsRefund
    ).then((resp) => {
      // fetching api url for courses listing
      resp.json().then((result) => {
        if (result?.status === "failed") {
          toast.error(result.message);
        } else {
          toast.success(result.message);
        }
        props.onchange();
      });
    });
  }

  const dateFormat = (date) => {
    let today = new Date(date);
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    let admission_date = dd + "/" + mm + "/" + yyyy;
    return admission_date;
  };
  const refundView = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Basic " + auth_token,
      },
    };
    fetch(
      environment.ecomApiUrl + `student/refund-detail/${id}`,
      requestOptions
    ).then((resp) => {
      // fetching api url of profile
      resp.json().then((result1) => {
        setRefundViewData(result1?.data);
      });
    });
  };

  const imgUpload = (img) => {
    let formData = new FormData();
    formData.append("refund_docs", img);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Basic " + auth_token,
      },
      body: formData,
    };
    fetch(environment.ecomApiUrl + `student/refund_doc`, requestOptions).then(
      (resp) => {
        // fetching api url of profile
        resp
          .json()
          .then((result1) => {
            if (result1?.status === "success") {
              setRefundInput((pre) => {
                return { ...pre, ["document_path"]: result1?.data };
              });
              toast.success(result1?.message);
              setIsDocUpload(false);
            }
          })
          .catch(() => {
            setIsDocUpload(false);
            toast.error("image upload failed");
          });
      }
    );
  };

  const uploadDoc = (e) => {
    let image = e.target.files[0];
    var FileUploadPath = image.name;
    var Extension = FileUploadPath.substring(
      FileUploadPath.lastIndexOf(".") + 1
    ).toLowerCase();
    if (
      // Extension === "gif" ||
      Extension === "png" ||
      // Extension === "bmp" ||
      Extension === "jpeg" ||
      Extension === "jpg"
    ) {
      setIsDocUpload(true);
      imageConversion.compress(image, 0.5).then((res) => {
        imgUpload(res);
      });
      // The file upload is NOT an image
    } else if (Extension === "pdf") {
      setIsDocUpload(true);
      imgUpload(image);
    } else {
      e.target.value = "";
      toast.error("Documents only allows file types of  PNG, JPG, JPEG & PDF. ");
    }
  };

  useEffect(() => {
    refundQuestionsGet();
    getReasons();
  }, []);
  return (
    <>
      {
        /* Full Course Code Starts */
        <div className="" key={props.order_id} style={{ background: "#fff" }}>
          <table
            width="100%"
            border="0"
            cellSpacing="0"
            cellPadding="0"
            className="course-table m-0 text-center bg-white"
            style={{ backgroundColor: "#fff !important," }}
          >
            <tr>
              <th>S.No.</th>
              <th>Course Name</th>
              <th>Commencement Date</th>
              <th>Last Date of Refund</th>
              <th>Admission Date</th>
              <th>Fee Amount</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            {props.orderItems.map((itm, i) => {
              if (itm?.net_deposit_amount == 0) {
                feeAmountIsZero = true;
              }
              return (
                <>
                  <tr>
                    <td>{i + 1}</td>
                    <td>{itm.course_name}</td>
                    <td>{dateFormat(itm.commencement_date)}</td>
                    <td>{itm.expiry_date}</td>
                    <td>{dateFormat(itm.admission_date)}</td>
                    {/* <td>{itm.fee_mrp}</td> */}
                    {/*  */}
                    {itm.refund_status !== 381 &&
                      itm.refund_status !== 382 &&
                      itm.refund_status !== 384 &&
                      itm.allow_refund === true && itm?.admission_status === 303 ? (
                      <td className="">{"₹ " + itm.net_deposit_amount}</td>
                    ) : itm.refund_status === 381 ||
                      itm.refund_status === 382 ||
                      itm.refund_status === 384 || itm.refund_status === 386 ? (
                      <td className="tooltip-refund">{"₹ " + itm.net_deposit_amount}
                        <span className="tooltiptext-refund">
                          Total Refundable Amount : ₹ {parseInt(itm.net_deposit_amount,) - parseInt(itm.deductable_fee)}
                          <br />
                          Deductible Fees Amount : {parseInt(itm.deductable_fee)}
                        </span>
                      </td>
                    ) : itm.voucher_id !== null &&
                      itm.refund_status === 383 ? (
                      <td className="tooltip-refund">{"₹ " + itm.net_deposit_amount}
                        <span className="tooltiptext-refund">
                          Total Refundable Amount : ₹ {parseInt(itm.net_deposit_amount,) - parseInt(itm.deductable_fee)}
                          <br />
                          Deductible Fees Amount : {parseInt(itm.deductable_fee)}
                        </span>
                      </td>
                    ) : (
                      <td className="">{"₹ " + itm.net_deposit_amount}</td>
                    )}



                    {/*  */}
                    <td>
                      {itm.refund_status === 384
                        ? "Requested"
                        : itm.refund_status_title}
                    </td>
                    <td>
                      {itm.refund_status !== 381 &&
                        itm.refund_status !== 382 &&
                        itm.refund_status !== 384 &&
                        itm.allow_refund === true && itm?.admission_status === 303 && itm.net_deposit_amount != 0 ? (
                        <button
                          className="btn btn-success"
                          onClick={() =>
                            openModalFirstConfirm(
                              itm.id,
                              itm.net_deposit_amount,
                              itm.deductable_fee
                            )
                          }
                        >
                          Apply Refund
                        </button>
                      ) : itm.refund_status === 381 ||
                        itm.refund_status === 382 ||
                        itm.refund_status === 384 || itm.refund_status === 931 ? (
                        <button
                          className="btn btn-danger"
                          onClick={() => openCancelModal(itm.refund_id)}
                        >
                          Cancel Refund
                        </button>
                      ) : itm.refund_id !== null &&
                        itm.refund_status === 383 ? (
                        <button
                          className="btn btn-danger"
                          onClick={() => openViewModal(itm?.refund_id)}
                        >
                          Details
                        </button>
                      ) : (
                        "---"
                      )}
                    </td>
                  </tr>
                </>
              );
            })}

          </table>
          {feeAmountIsZero && (
            <div className="alert alert-danger" role="alert">
              You are not eligible for the refund
            </div>
          )}
          {/* Confirm Modal */}
          <Modal
            isOpen={modalIsOpenFirstConfirm}
            onAfterOpen={afterOpenFirstModalConfirm}
            onRequestClose={closeModalFirstConfirm}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <div
              style={{
                width: "515px",
                height: "190px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h3 className="text-center">Refund Request</h3>
              <h4
                // ref={(_subtitle) => (subtitle = _subtitle)}
                style={{ color: "#000 !important", fontSize: "18px" }}
              >
                Are you sure you want to apply for Refund?
              </h4>
              <div style={{ textAlign: "end" }}></div>

              <div className="">
                <div className="row justify-content-center mt-4">
                  <button
                    type="button"
                    className="btn btn-sm btn-success"
                    onClick={() => openModalConfirm()}
                    data-toggle="modal"
                    data-target="#exampleModal"
                    style={{
                      position: "relative",
                      top: "0px",
                      borderRadius: "0px",
                      marginRight: "15px",
                      width: "70px",
                      background: "none",
                      border: "solid 1px #ccc",
                      color: "#000",
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={closeModalFirstConfirm}
                    style={{
                      position: "relative",
                      top: "-0px",
                      marginLeft: "1%",
                      borderRadius: "0px",
                      width: "73px",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          {/* 2nd Confirm Modal */}

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div>
                    <div style={{ textAlign: "end" }}></div>
                    <div className="mt-3 ">
                      <div className="refund-detail-pop-from">
                        <div className="container">
                          <h5>Refund Details</h5>
                          <table className="w-100 text-justify mb-3">
                            <tr>
                              <td className="w-80">Fees Paid :</td>
                              <td>{"₹ " + netFees}</td>
                            </tr>
                            <tr>
                              <td className="w-80">Deductible Fees Amount :</td>
                              <td> {"₹ " + refundableFees}</td>
                            </tr>
                            <tr>
                              <td className="w-80">
                                Total Refundable Amount :
                              </td>
                              <td>
                                {" "}
                                ₹ {parseInt(netFees) - parseInt(refundableFees)}
                              </td>
                            </tr>
                          </table>
                          <h5>
                            Bank Account Details{" "}
                            <span>(In which you want refund)</span>
                          </h5>
                          <div className="row mb-2">
                            <div className="col-md-5">
                              <td>Account Name * </td>
                            </div>
                            <div className="col-md-7">
                              <input
                                onChange={popRefundInput}
                                value={refundInput.account_name}
                                name="account_name"
                                className="form-control"
                                type="text"
                                placeholder="Enter account holder name"
                                maxlength="80"
                              />
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-5">
                              <td>Relation with Student * </td>
                            </div>
                            <div className="col-md-7">
                              <select
                                name="relation_with_student"
                                className="form-control relation_with_student"
                                onChange={(e) => relationStudentSet(e)}
                                style={{
                                  padding: "6px !important",
                                  fontSize: "13px",
                                  height: "33px",
                                }}
                              >
                                <option value="">Please select Relation</option>
                                <option value="261">Father</option>
                                <option value="262">Mother</option>
                              </select>
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-5">
                              <td>Bank Name * </td>
                            </div>
                            <div className="col-md-7">
                              <input
                                onChange={popRefundInput}
                                value={refundInput.bank_name}
                                name="bank_name"
                                className="form-control"
                                type="text"
                                placeholder="Enter bank name"
                                maxlength="50"
                                disabled={IFSCValidate}
                              />
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-5">
                              <td>Account No * </td>
                            </div>
                            <div className="col-md-7">
                              <input
                                onChange={popRefundInput}
                                value={refundInput.account_no}
                                name="account_no"
                                className="form-control"
                                type="text"
                                placeholder="Enter account no."
                                maxlength="20"
                              />
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-5">
                              <td>Retype Account No * </td>
                            </div>
                            <div className="col-md-7">
                              <input
                                onChange={popRefundInput}
                                value={refundInput.confirm_account_no}
                                name="confirm_account_no"
                                className="form-control"
                                type="text"
                                placeholder="Retype account no."
                                maxlength="20"
                              />
                            </div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-md-5">
                              <td>Bank IFSC Code *</td>
                            </div>
                            <div className="col-md-7">
                              <div style={{
                                display: "flex",
                                alignItems: "center",
                              }}>
                                <input
                                  onChange={popRefundInput}
                                  value={refundInput.ifsc_code}
                                  name="ifsc_code"
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter bank IFSC code"
                                  maxlength="11"
                                />
                                {IFSCValidate && <i class="fa fa-check-circle  p-2" style={{ fontSize: 20, color: "#28a745" }}></i>}

                              </div>
                              {!IFSCValidate ? FirtTypeIS ? <span style={{ color: "red", fontSize: "10px" }}>Invalid IFSC Code</span> : "" : <span
                                style={{ color: "#046635", fontSize: "10px" }}
                              >
                                {IFSCCodeData?.ADDRESS}
                              </span>}

                            </div>

                          </div>
                          <div className="row mb-2">
                            <div className="col-md-5">
                              <td>Refund Reason * </td>
                            </div>
                            <div className="col-md-7">
                              <textarea
                                onChange={popRefundInput}
                                value={refundInput.refund_reason}
                                name="refund_reason"
                                className="form-control rounded-0 mt-2 refund-remrak-pop-input"
                                type="text"
                                placeholder="Enter Refund Reason"
                                maxlength="250"
                                required
                              />
                              <span
                                style={{ color: "red", fontSize: "10px" }}
                              >
                                (Maximum 250 Characters )
                              </span>
                            </div>
                          </div>
                          <div className="row mb-2 mt-3">
                            <div className="col-md-5">
                              <td>
                                Upload Passbook or Cancel Cheque * &nbsp;
                                <span
                                  style={{ color: "red", fontSize: "10px" }}
                                >
                                  (Only allows file types of PNG, JPG, JPEG &
                                  PDF. )
                                </span>
                              </td>
                            </div>
                            <div className="col-md-7 d-flex align-items-center">
                              <input
                                // onChange={popRefundInput}
                                onChange={uploadDoc}
                                name="document_path"
                                type="file"
                                placeholder="Upload your file"
                                id="file"
                                className="refund-doc-upload-input-box"
                                // accept="image/png, image/jpeg ,image/jpg,'application/pdf'"
                                maxSize="2000"
                              // value={refundInput.document_path}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 text-right">
                        {isDocUpload ? (
                          <button
                            className="btn btn-sm"
                            style={{
                              position: "relative",
                              top: "0px",
                              borderRadius: "0px",
                              marginRight: "15px",
                              width: "70px",
                              // color: "#fff",
                            }}
                          >
                            <ThreeDots
                              height="20"
                              width="10"
                              radius="0"
                              color="#4fa94d"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => submitRefundData()}
                            style={{
                              position: "relative",
                              top: "0px",
                              borderRadius: "0px",
                              marginRight: "15px",
                              width: "70px",
                              // color: "#fff",
                            }}
                          >
                            Next
                          </button>
                        )}

                        <button
                          className="btn btn-sm btn-danger"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={closeModalConfirm}
                          style={{
                            position: "relative",
                            top: "-0px",
                            marginLeft: "1%",
                            width: "70px",
                            borderRadius: "0px",
                            fontsize: "14px",
                            background: "#ff0000",
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          button
                          className="close-Deductable-fee-modal-a"
                          data-dismiss="modal"
                          aria-label="Close"
                          style={{
                            display: "none",
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Question Modal */}

          <div
            className="modal fade"
            id="exampleModalLong"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ overflow: "scroll !important" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="rating-pop-content">
                    <h3 className="text-center">
                      <b>We don’t want to Let you go!!</b>
                    </h3>
                    <p className="mb-0 text-center text-black">
                      But as you have decided, we’d really appreciate your
                      feedback on your learning partnership with
                      <br /> us and if there’s anything we could do to make it
                      better. Please give Your Feedback in Terms of Stars
                      where..
                    </p>
                    <p className="mt-2 text-center mt-4 mb-4">
                      <span className="number-shadow">5</span> Excellent &nbsp;
                      &nbsp; &nbsp; <span className="number-shadow">4</span>{" "}
                      Very good &nbsp; &nbsp; &nbsp;{" "}
                      <span className="number-shadow">3</span> Good &nbsp;
                      &nbsp; &nbsp; <span className="number-shadow">2</span>{" "}
                      Satisfactory &nbsp; &nbsp; &nbsp;
                      <span className="number-shadow">1</span> Not happy
                    </p>
                  </div>

                  <div className="container color-white-pop">
                    {refundQuestions.map((ques, q) => {
                      return (
                        <>
                          <p className="m-0  rating-question">{ques.title}</p>
                          <Rating
                            className="mb-2"
                            ratingValue={ques.answer * 20}
                            name={"questions" + ques.id}
                            // value={ques.answer * 20}
                            /* Available Props */
                            onClick={(event) => handleRating(event, q)}
                          />
                        </>
                      );
                    })}
                    {/* <br></br><br></br>
                      <label>Select Refund Reason</label><br></br>
                      <select name="reason_id" className="form-control refund-reason-select" onChange={handleEvent}>
                        <option value="0">---Select---</option>
                        {
                          (reasons?reasons:[]).map((reas,r)=>{
                            return(
                              <>
                                <option value={reas.id}>{reas.title}</option>
                              </>
                            )
                          })
                        }
                      </select> */}
                    <br></br>
                    <label className="rating-question">
                      What could have we done to make your learning experience
                      better? Comment below:
                    </label>
                    <textarea
                      rows={3}
                      cols={40}
                      className="form-control rounded-0 mt-2 refund-remrak-pop-input"
                      onChange={handleEvent}
                      name="remark"
                      maxLength="250"
                      value={remark}
                      required
                    ></textarea>
                    <p>Maximum 250 Characters</p>
                    <br></br>
                    <span className="float-left">
                      <input
                        className="refund-t-and-c-button"
                        type="checkbox"
                        name="tnc"
                        onClick={handleEvent}
                        value="1"
                        checked={!terms_conditions_button}
                      />
                    </span>
                    &nbsp; I agree all the{" "}
                    <a target="_blank" href="/t&c">
                      {" "}
                      Terms & Conditions{" "}
                    </a>
                    of
                    <a target="_blank" href="/refund-policy">
                      {" "}
                      Refund Policy.
                    </a>
                    <div className="row m-0 justify-content-end mt-3">
                      <button
                        className="btn btn-sm btn-success rounded-0"
                        onClick={() => myRefund()}
                        style={{
                          position: "relative",
                          top: "0px",
                          borderRadius: "none !important",
                          marginRight: "15px",
                        }}
                        disabled={terms_conditions_button}
                      >
                        Submit
                      </button>

                      <button
                        className="btn btn-sm btn-danger"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={closeModal}
                        style={{
                          position: "relative",
                          top: "-0px",
                          marginLeft: "1%",
                          borderRadius: "0px",
                          width: "73px",
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        button
                        className="close-Deductable-fee-modal"
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{
                          display: "none",
                        }}
                      >
                        Yes
                      </button>

                      {/* 
                <button
                  className="btn btn-sm btn-danger rounded-0 close"  data-dismiss="modal" aria-label="Close"
                  onClick={closeModal}
                  style={{
                    position: "relative",
                    top: "-0px",
                    marginLeft: "1%",
                    borderRadius: "none !important",
                    fontSize :"12px"
                  }}
                >
                  Cancel
                </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
           
          </Modal> */}

          {/* Cancelled refund Popup */}

          {/* Modal */}

          <Modal
            isOpen={modalCancelIsOpen}
            onAfterOpen={afterCancelOpenModal}
            onRequestClose={closeCancelModal}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <div
              style={{
                width: "515px",
                height: "190px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h3 className="text-center">Refund Request</h3>
              <h4
                // ref={(_subtitle) => (subtitle = _subtitle)}
                style={{ color: "#000 !important", fontSize: "18px" }}
              >
                Are you sure you want to Cancel refund
              </h4>
              <div style={{ textAlign: "end" }}></div>

              <div className="">
                <div className="row justify-content-center mt-4">
                  {/* <button type="button" className="btn btn-sm btn-success"  data-toggle="modal" data-target="#exampleModal" style={{
                      position: "relative",
                      top: "0px",
                      borderRadius: "0px",
                      marginRight: "15px",
                      width: "70px",
                      background: "none",
                      border: "solid 1px #ccc",
                      color: "#000",
                      
                    }}>
  Yes 
</button> */}
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => cancelRefund()}
                    style={{
                      position: "relative",
                      top: "0px",
                      borderRadius: "0px",
                      marginRight: "15px",
                      width: "70px",
                      background: "none",
                      border: "solid 1px #ccc",
                      color: "#000",
                    }}
                  // data-toggle="modal" data-target="#exampleModal"
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={closeCancelModal}
                    style={{
                      position: "relative",
                      top: "-0px",
                      marginLeft: "1%",
                      borderRadius: "0px",
                      width: "73px",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          {/* View Details Modal */}
          <Modal
            isOpen={modalViewIsOpen}
            onAfterOpen={afterOpenViewModal}
            onRequestClose={closeViewModal}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <div className="row">
              <div className="col-md-6">
                <h3
                  ref={(_subtitle) => (subtitle = _subtitle)}
                  style={{
                    color: "#046635 !important",
                    fontSize: "25px",
                    fontWeight: "600",
                  }}
                  className=""
                >
                  Refund Details
                </h3>
              </div>
              <div className="col-md-6">
                <div style={{ textAlign: "end" }}>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={closeViewModal}
                    style={{ position: "relative", width: "50px" }}
                  >
                    X
                  </button>
                </div>
              </div>
            </div>

            <div style={{ textAlign: "end" }}>
              <table
                width="100%"
                border="0"
                cellSpacing="0"
                cellPadding="0"
                className="course-table m-0 text-center mt-3 mb-3"
              >
                <tr>
                  <th>S.No.</th>
                  <th>Refund Date </th>
                  <th>Payment Bank Name</th>
                  <th>Transaction Ref. No.</th>
                  <th>Refunded Amount</th>
                </tr>

                <>
                  <tr>
                    <td>{1}</td>
                    <td>{dateFormat(refundViewData?.transaction_datetime)}</td>
                    <td>
                      {refundViewData?.bank_id === 1
                        ? "HDFC"
                        : refundViewData?.bank_id === 2
                          ? "CBI"
                          : "--"}
                    </td>
                    <td>{refundViewData?.transaction_reference_number}</td>
                    <td>₹ {refundViewData?.amount}</td>
                  </tr>
                </>
              </table>
            </div>

            <div className="container mt-2">
              <div className="row">
                <button
                  className="btn btn-sm btn-danger"
                  onClick={closeViewModal}
                  style={{
                    position: "relative",
                    top: "-0px",

                    borderRadius: "0px !important",
                    width: "70px",
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal>
        </div>
      }
      <button
        type="button"
        className="allen-model-open-class"
        data-toggle="modal"
        data-target="#exampleModalLong"
        style={{
          display: "none",
        }}
      >
        Yes
      </button>
    </>
  );
};

export default EcomRefundOrder;
