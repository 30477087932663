import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
// import { environment } from "../../Services/HttpData";
import Modal from "react-modal";
import CreatedDateGet from "../../components/CreatedDateGet/CreatedDateGet";
import RequestLogs from "./RequestLogs";
import "./courseBatchChange.css";
import { ThreeDots } from "react-loader-spinner";
import * as imageConversion from "image-conversion";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);
export default function MyRequest() {
  const auth_token = localStorage.getItem("apiAccessToken")
    ? localStorage.getItem("apiAccessToken")
    : "";
  const tabData = [
    { title: "Course Change", active: false, dropdown: false },
    // { title: "Batch Change", active: false, dropdown: false },
  ];
  const course_data_state = {
    currentCourse: {},
    newCourse: {},
    courseChangeFee: 0,
    differenceAmount: 0,
  };
  const [tabContainerData, SetTabContainerData] = useState(tabData);
  const [checkTerms, SetcheckTerms] = useState(true);
  const [currentCourse, SetCurrentCourse] = useState([]);
  const [newCourse, SetNewCourse] = useState([]);
  const [courseList, SetcourseList] = useState({
    currentCourse: {},
    newCourse: {},
  });
  const [courseData, SetcourseData] = useState(course_data_state);
  const [ticketLogs, SetTicketLogs] = useState([]);
  const [modalCancelIsOpen, setCancelIsOpen] = useState(false);
  {
    /* bank from start */
  }
  const refundFromData = {
    account_name: "",
    relation_with_student: "",
    bank_name: "",
    account_no: "",
    confirm_account_no: "",
    ifsc_code: "",
    document_path: "",
  };
  const [refundInput, setRefundInput] = useState(refundFromData);
  const [isDocUpload, setIsDocUpload] = useState(false);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "8px",
      backgroundColor: "rgb(0 0 0 / 75%)",
    },
    overlay: {
      background: "rgb(0 0 0 / 75%)",
    },
  };
  const popRefundInput = (e) => {
    // console.log(e);
    const { name, value } = e.target;
    setRefundInput((pre) => {
      return { ...pre, [name]: value };
    });
    // console.log(refundInput);
  };

  const relationStudentSet = (e) => {
    setRefundInput((pre) => {
      return { ...pre, ["relation_with_student"]: e.target.value };
    });
  };
  const imgUpload = (img) => {
    let formData = new FormData();
    formData.append("ticket_doc", img);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Basic " + auth_token,
      },
      body: formData,
    };
    fetch(environment.ecomApiUrl + `student/upload_course_change_doc`, requestOptions).then(
      (resp) => {
        // fetching api url of profile
        resp
          .json()
          .then((result1) => {
            if (result1?.status === "success") {
              setRefundInput((pre) => {
                return { ...pre, ["document_path"]: result1?.data };
              });
              toast.success(result1?.message);
              setIsDocUpload(false);
            }
          })
          .catch(() => {
            setIsDocUpload(false);
            toast.error("image upload failed");
          });
      }
    );
  };
  const uploadDoc = (e) => {
    let image = e.target.files[0];
    var FileUploadPath = image.name;
    var Extension = FileUploadPath.substring(
      FileUploadPath.lastIndexOf(".") + 1
    ).toLowerCase();
    if (
      // Extension === "gif" ||
      Extension === "png" ||
      // Extension === "bmp" ||
      Extension === "jpeg" ||
      Extension === "jpg"
    ) {
      setIsDocUpload(true);
      imageConversion.compress(image, 0.5).then((res) => {
        imgUpload(res);
      });
      // The file upload is NOT an image
    } else if (Extension === "pdf") {
      setIsDocUpload(true);
      imgUpload(image);
    } else {
      e.target.value = "";
      toast.error("Image only allows file types of  PNG, JPG, JPEG & PDF. ");
    }
  };
  const submitRefundData = () => {
    const validAccountNo = new RegExp("^[0-9]+$");
    const validAccountname = new RegExp("[a-zA-Z]{3,50}");
    const validBankname = new RegExp("[a-zA-Z]{3,30}");
    // const validRelation = new RegExp("[a-zA-Z]{3,30}");

    if (!validAccountname.test(refundInput?.account_name)) {
      toast.error("Invalid account holder name");
      return false;
    } else if (refundInput?.relation_with_student === "") {
      toast.error("Invalid relation detail");
      return false;
    } else if (!validBankname.test(refundInput?.bank_name)) {
      toast.error("Invalid bank name");
      return false;
    } else if (
      !validAccountNo.test(refundInput?.account_no) ||
      refundInput?.account_no.split("+").length > 1 ||
      refundInput?.account_no.split("-").length > 1 ||
      refundInput?.account_no.split(".").length > 1 ||
      refundInput?.account_no.length < 5 ||
      refundInput?.account_no.length > 20
    ) {
      toast.error("Invalid account number");
      return false;
    } else if (refundInput.account_no !== refundInput.confirm_account_no) {
      toast.error("Account number mismatch");
      return false;
    } else if (refundInput?.ifsc_code.length < 9) {
      toast.error("Invalid IFSC Code");
      return false;
    } else if (refundInput?.document_path === "") {
      toast.error("Please upload file");
      return false;
    } {
      // toast.success("Detail Submitted");
      // document.querySelector(".close-Deductable-fee-modal-a").click();
      // document.querySelector(".allen-model-open-class").click();
      return true
    }
  };

  {
    /* bank from End */
  }
  const ticketStutas = {
    761: "Open",
    762: "In-process",
    763: "Close",
    764: "Cancelled By Student",
  };
  const tabChange = (e) => {
    let tabArray = tabData.map((val, ind) => {
      if (e.target?.value === val.title) {
        return { ...val, active: true };
      } else {
        return { ...val, active: false };
      }
    });
    SetTabContainerData(tabArray);
  };

  const buttonDisable = (e) => {
    SetcheckTerms(!e.target.checked);
  };

  const getNewCourse = (course_id) => {
    var raw = JSON.stringify({
      center_course_id: course_id,
    });
    const requestOptions = {
      method: "POST",

      headers: {
        Authorization: "Bearer " + auth_token,
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    try {
      fetch(
        environment.ecomApiUrl + "product/changeable-course-list",
        requestOptions
      )
        .then((resp) => {
          // fetching api url for courses listing
          resp.json().then((result) => {
            if (result?.status === "failed") {
              toast.error(result?.message);
            }
            SetNewCourse(result?.data);
            let currentCourseList = {};
            result?.data?.map((val, ind) => (currentCourseList[val?.id] = val));

            SetcourseList((pre) => ({ ...pre, newCourse: currentCourseList }));
            // setLoading(true);
          });
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.error(error);
    }
  };

  const courseChange = (e, type) => {
    if (type === "Current-Course") {
      let value = courseList["currentCourse"][e.target.value];
      if (value) {
        if (value?.allow_course_change) {
          SetcourseData((pre) => ({ ...pre, currentCourse: value }));
        } else {
          SetcourseData((pre) => ({ ...pre, currentCourse: {} }));
          toast.error("Course Change is not allowed for this Course.")
        }

      } else {
        SetcourseData((pre) => ({ ...pre, currentCourse: {} }));
      }
      const courseChangeFee = value?.course_change_fee ? value?.course_change_fee : 0
      let differenceAmount =
        value?.deposit_fee - (courseData?.newCourse?.price)

      SetcourseData((pre) => ({
        ...pre,
        differenceAmount: differenceAmount,
        courseChangeFee: courseChangeFee,
      }));
      if (e.target.value !== "" && value?.allow_course_change) {
        getNewCourse(e.target.value);
      }
    } else {
      let value = courseList["newCourse"][e.target.value];
      SetcourseData((pre) => ({ ...pre, newCourse: value }));
      let differenceAmount =
        courseData?.currentCourse?.deposit_fee - value?.price;
      SetcourseData((pre) => ({ ...pre, differenceAmount: differenceAmount }));
    }
  };

  const courseChangeSubmit = (e) => {
    if (Object.keys(courseData?.currentCourse).length === 0) {
      toast.error("Select Current Course Name");
      return false;
    }
    if (Object.keys(courseData?.newCourse).length === 0) {
      toast.error("Select Select New Course");
      return false;
    }
    setCancelIsOpen(true);
    // document.querySelector(".allen-model-open-class").click();
    // return false;
    //

    //
  };

  const couresChangeInitApiCall = () => {
    setCancelIsOpen(false);
    var raw = JSON.stringify({
      admission_id: courseData?.currentCourse?.id,
      id_from: courseData.currentCourse?.course_id,
      id_to: courseData.newCourse?.id,
      remark: " ",
      ticket_doc: "",
      ticket_data: refundInput,
    });
    const requestOptions = {
      method: "POST",

      headers: {
        Authorization: "Bearer " + auth_token,
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    try {
      fetch(environment.ecomApiUrl + "student/ticket-course", requestOptions)
        .then((resp) => {
          resp.json().then((result) => {
            if (result?.status === "success") {
              getStudentTicketLog();
              toast.success(result.message);
              SetcourseData(course_data_state);
              document.querySelector("#new-course-select").selectedIndex = 0;
              document.querySelector("#old-course-select").selectedIndex = 0;
              document.querySelector(".form-check-input-terms").click();
              document.querySelector(".bank-pop-close-button").click();
              document.querySelector(".relation-with-student").value = ""
              document.querySelector(".refund-doc-upload-input-box").value = ""
            } else {
              toast.error(result.message);
            }
          });
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.error(error);
    }
  }

  // get students Ticket Logs

  const getStudentTicketLog = (e) => {
    const requestOptionsLog = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + auth_token,
      },
      redirect: "follow",
    };
    try {
      fetch(environment.ecomApiUrl + "student/ticket-logs", requestOptionsLog)
        .then((resp) => {
          resp.json().then((result) => {
            if (result.status === "success") {
              SetTicketLogs(result?.data);
            }
          });
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.error(error);
    }
  };
  function closeCancelModal() {
    setCancelIsOpen(false);
  }
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Basic " + auth_token,
      },
    };
    getStudentTicketLog();
    try {
      fetch(environment.ecomApiUrl + "student/my-courses", requestOptions)
        .then((resp) => {
          resp.json().then((result) => {
            SetCurrentCourse(result?.data?.records);
            let currentCourseList = {};
            result?.data?.records.map(
              (val, ind) => (currentCourseList[val?.course_id] = val)
            );
            SetcourseList((pre) => ({
              ...pre,
              currentCourse: currentCourseList,
            }));
          });
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.error(error);
    }
    // get logs
  }, []);

  return (
    <div className="">
      <div className="card-header">
        <h4 className="text-success text-decoration-none">
          {tabContainerData[0].active
            ? tabContainerData[0].title
            : ""
              // tabContainerData[1].active
              ? ""
              // tabContainerData[1].title
              : "Add Request"}
        </h4>
        <select
          name="add-request"
          id="add-request"
          className="course-batch-change-dropdown mt-2"
          onChange={tabChange}
        >
          <option value="auto-select" disabled selected>
            Select Request
          </option>
          {tabContainerData.map((val, ind) => (
            <option
              key={ind}
              className="dropdown-item drop-down-a"
              id="list-intro-list"
              value={val.title}
            >
              {val.title}
            </option>
          ))}
        </select>
      </div>

      {tabContainerData[0].active ? (
        <div className="course-batch-change-form">
          <div className="row mb-3 mt-3">
            <div className="col-md-2 col-form-label">
              <span>Current Course Name : </span>
            </div>

            <div className="col-md-9">
              <select
                onChange={(e) => {
                  courseChange(e, "Current-Course");
                }}
                className="form-control form-select-sm "
                aria-label=".form-select-sm example"
                id="old-course-select"
              >
                <option value="">Select Course Name</option>
                {currentCourse?.map((val, ind) => (
                  <option key={ind} value={val?.course_id}>
                    {val?.course_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mb-3 mt-3">
            <div className="col-md-2 col-form-label">
              <span>Select New Course : </span>
            </div>

            <div className="col-md-9">
              <select
                onChange={(e) => {
                  courseChange(e, "New-Course");
                }}
                id="new-course-select"
                className="form-control form-select-sm "
                aria-label=".form-select-sm example"
              >
                <option value="">Select Course Name</option>
                {newCourse?.map((val, ind) => (
                  <option key={ind} value={val?.id}>
                    {val?.title}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row mb-3 mt-3">
            <div className="col-md-3">
              <div className="row">
                <div className="col-md-auto col-form-label">
                  <span>Current Course Fee : </span>
                </div>
                <div className="col-md-auto font-weight-bold d-flex align-items-center ml-20px-desc">
                  <span>
                    ₹{" "}
                    {courseData?.currentCourse?.deposit_fee
                      ? courseData?.currentCourse?.deposit_fee
                      : 0}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-auto offset-md-1">
              <div className="row">
                <div className="col-md-auto col-form-label">
                  <span>New Course Fee : </span>
                </div>
                <div className="col-md-auto font-weight-bold d-flex align-items-center">
                  <span>
                    {" "}
                    ₹{" "}
                    {courseData?.newCourse?.price
                      ? courseData?.newCourse?.price
                      : 0}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3 mt-3">
            <div className="col-md-3">
              <div className="row">
                <div className="col-md-auto col-form-label">
                  <span>Course Change Fee : </span>
                </div>
                <div className="col-md-auto font-weight-bold d-flex align-items-center ml-20px-desc">
                  <span>₹ {courseData?.courseChangeFee}</span>
                </div>
              </div>
            </div>

            <div className="col-md-auto offset-md-1">
              <div className="row">
                <div className="col-md-auto col-form-label">
                  <span> Difference Amount : </span>
                </div>
                <div className="col-md-auto font-weight-bold d-flex align-items-center">
                  ₹{" "}
                  <span>
                    {courseData?.differenceAmount < 0
                      ? Math.abs(courseData?.differenceAmount) +
                      "Payable amount"
                      : courseData?.differenceAmount
                        ? Math.abs(courseData?.differenceAmount) +
                        " Refundable amount"
                        : 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="form-check mt-3">
            <input
              onChange={buttonDisable}
              className="form-check-input form-check-input-terms"
              type="checkbox"
              id="flexCheckDefault"
              checked={!checkTerms}
            />
            <label
              className="form-check-label ml-2 mt-3px "
              htmlFor="flexCheckDefault"
            >
              &nbsp; I accept{" "}
              <a href="t&c" target="_blank">
                Terms & Conditions{" "}
              </a>
              and{" "}
              <a href="policies" target="_blank">
                Privacy Policy
              </a>
            </label>
          </div>

          <button
            type="button"
            className="btn btn-success mt-4"
            disabled={checkTerms}
            onClick={courseChangeSubmit}
          >
            Proceed
          </button>
        </div>
      ) : null}
      <RequestLogs
        ticketLogs={ticketLogs}
        getStudentTicketLog={getStudentTicketLog}
        CreatedDateGet={CreatedDateGet}
        ticketStutas={ticketStutas}
      />
      {/* <ModalPop/> */}
      {/* bank from start */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div>
                <div style={{ textAlign: "end" }}></div>
                <div className="mt-3 ">
                  <div className="refund-detail-pop-from">
                    <div className="container">
                      <h5>Refund Details</h5>
                      <h5>
                        Bank Account Details{" "}
                        <span>(In which you want refund)</span>
                      </h5>
                      <div className="row mb-2">
                        <div className="col-md-5">
                          <td>Account Name * </td>
                        </div>
                        <div className="col-md-7">
                          <input
                            onChange={popRefundInput}
                            value={refundInput.account_name}
                            name="account_name"
                            className="form-control"
                            type="text"
                            placeholder="Enter account holder name"
                            maxlength="80"
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-5">
                          <td>Relation with Student * </td>
                        </div>
                        <div className="col-md-7">
                          <select

                            name="relation_with_student"
                            className="form-control relation-with-student"
                            onChange={(e) => relationStudentSet(e)}
                            style={{
                              padding: "6px !important",
                              fontSize: "13px",
                              height: "33px",
                            }}
                          >
                            <option value="">Please select Relation</option>
                            <option value="261">Father</option>
                            <option value="262">Mother</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-5">
                          <td>Bank Name * </td>
                        </div>
                        <div className="col-md-7">
                          <input
                            onChange={popRefundInput}
                            value={refundInput.bank_name}
                            name="bank_name"
                            className="form-control"
                            type="text"
                            placeholder="Enter bank name"
                            maxlength="50"
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-5">
                          <td>Account No * </td>
                        </div>
                        <div className="col-md-7">
                          <input
                            onChange={popRefundInput}
                            value={refundInput.account_no}
                            name="account_no"
                            className="form-control"
                            type="text"
                            maxlength="20"
                            placeholder="Enter account no."
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-5">
                          <td>Retype Account No * </td>
                        </div>
                        <div className="col-md-7">
                          <input
                            onChange={popRefundInput}
                            value={refundInput.confirm_account_no}
                            name="confirm_account_no"
                            className="form-control"
                            type="text"
                            maxlength="20"
                            placeholder="Retype account no."
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-md-5">
                          <td>Bank IFSC Code *</td>
                        </div>
                        <div className="col-md-7">
                          <input
                            onChange={popRefundInput}
                            value={refundInput.ifsc_code}
                            name="ifsc_code"
                            className="form-control"
                            type="text"
                            maxlength="11"
                            placeholder="Enter bank IFSC code"
                          />
                        </div>
                      </div>
                      <div className="row mb-2 mt-3">
                        <div className="col-md-5">
                          <td>
                            Upload Passbook or Cancel Cheque * &nbsp;
                            <span style={{ color: "red", fontSize: "10px" }}>
                              (Only allows file types of PNG, JPG, JPEG & PDF. )
                            </span>
                          </td>
                        </div>
                        <div className="col-md-7 d-flex align-items-center">
                          <input
                            className="refund-doc-upload-input-box"
                            onChange={uploadDoc}
                            name="document_path"
                            type="file"
                            placeholder="Upload your file"
                            id="file"
                            // accept="image/png, image/jpeg ,image/jpg,'application/pdf'"
                            maxSize="2000"
                          // value={refundInput.document_path}
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="mt-4 text-right">
                    {isDocUpload ? (
                      <button
                        className="btn btn-sm"
                        style={{
                          position: "relative",
                          top: "0px",
                          borderRadius: "0px",
                          marginRight: "15px",
                          width: "70px",
                          // color: "#fff",
                        }}
                      >
                        <ThreeDots
                          height="20"
                          width="10"
                          radius="0"
                          color="#4fa94d"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm btn-success"
                        onClick={(e) => {
                          if (submitRefundData()) {
                            couresChangeInitApiCall(e)
                          }
                        }}
                        style={{
                          position: "relative",
                          top: "0px",
                          borderRadius: "0px",
                          marginRight: "15px",
                          width: "70px",
                          // color: "#fff",
                        }}
                      >
                        Next
                      </button>
                    )}

                    <button
                      className="btn btn-sm btn-danger bank-pop-close-button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setRefundInput(refundFromData)
                        document.querySelector(".relation-with-student").value = ""
                        document.querySelector(".refund-doc-upload-input-box").value = ""
                      }}
                      style={{
                        position: "relative",
                        top: "-0px",
                        marginLeft: "1%",
                        width: "70px",
                        borderRadius: "0px",
                        fontsize: "14px",
                        background: "#ff0000",
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* bank from End */}
      {/* confirm pop */}
      <Modal
        isOpen={modalCancelIsOpen}
        onAfterOpen={() => { }}
        onRequestClose={closeCancelModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div
          style={{
            width: "515px",
            height: "190px",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <h3 className="text-center">Course Change Request</h3>
          <h4
            // ref={(_subtitle) => (subtitle = _subtitle)}
            style={{ color: "#000 !important", fontSize: "18px" }}
          >
            Are you sure you want to Course Change
          </h4>
          <div style={{ textAlign: "end" }}></div>

          <div className="">
            <div className="row justify-content-center mt-4">
              {/* <button type="button" className="btn btn-sm btn-success"  data-toggle="modal" data-target="#exampleModal" style={{
                      position: "relative",
                      top: "0px",
                      borderRadius: "0px",
                      marginRight: "15px",
                      width: "70px",
                      background: "none",
                      border: "solid 1px #ccc",
                      color: "#000",
                      
                    }}>
  Yes 
</button> */}
              {courseData?.differenceAmount < 0
                ? (<button
                  type="button"
                  className="btn btn-success btn-sm"
                  disabled={checkTerms}
                  onClick={couresChangeInitApiCall}
                  style={{
                    position: "relative",
                    top: "-0px",
                    marginLeft: "1%",
                    borderRadius: "0px",
                    width: "73px",
                  }}
                >
                  Yes
                </button>)
                : courseData?.differenceAmount
                  ? (<button
                    type="button"
                    className="btn btn-success btn-sm"
                    disabled={checkTerms}
                    onClick={() => { setCancelIsOpen(false) }}
                    data-toggle="modal"
                    data-target="#exampleModal"
                    style={{
                      position: "relative",
                      top: "-0px",
                      marginLeft: "1%",
                      borderRadius: "0px",
                      width: "73px",
                    }}

                  >
                    Yes
                  </button>)
                  : (<button
                    type="button"
                    className="btn btn-success btn-sm"
                    disabled={checkTerms}
                    onClick={couresChangeInitApiCall}
                    style={{
                      position: "relative",
                      top: "-0px",
                      marginLeft: "1%",
                      borderRadius: "0px",
                      width: "73px",
                    }}
                  >
                    Yes
                  </button>)}
              <button
                className="btn btn-sm btn-danger"
                onClick={closeCancelModal}
                style={{
                  position: "relative",
                  top: "-0px",
                  marginLeft: "1%",
                  borderRadius: "0px",
                  width: "73px",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/*  */}
    </div>
  );
}
