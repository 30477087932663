import React, { useEffect, useState } from "react";
// import { environment } from "../../Services/HttpData";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import "../../css/ecom/ecomStyle.css";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
const Success = () => {
  const { courseId } = useParams();
  const [courseData, setcourseData] = useState();
  const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : '';
  const wid_data = window.localStorage.getItem("IS_DASHBOARD_ICON")
    ? window.localStorage.getItem("IS_DASHBOARD_ICON")
    : false;
  useEffect(() => {

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': 'Basic ' + auth_token
      }
    };
    fetch(environment.ecomApiUrl + `propelled/get-course/id/?course_id=${courseId}`, requestOptions).then((resp) => { // fetching api url for courses listing
      resp.json().then((result) => {
        setcourseData(result.data)
      })
    })
  }, []);
  if (!wid_data) {
    window.location.href = "/";
  }
  return (
    <>
      <div className="container pr-0 mt-5">
        <div className="col-md-12">

          <div className="success-cotent-top text-center">
            <i className="fa-solid fa-circle-check"></i>
            <h4> Your Loan Request Has Been Submitted!</h4>
            {/*  <span className="order-id mt-5"> order Id : {orderId} </span><br/> */}
            <span className="order-id mt-5"> An email with the details of the next steps has been sent to your registered email ID. </span><br />

            <div className="mt-5 col-md-9" style={{ marginLeft: '13%' }}>
              {
                courseData?.map((course) => {
                  return (
                    <>
                      <div className="d-flex flex-row mobile-flex-column card px-2 py-2 cardfor-propelld">
                        <div className="col-md-3">
                          <img
                            // className="padding-b-t"
                            src={course.center_course_image}
                            alt="course Img"
                            width="100%"
                          />
                        </div>

                        <div className="col-md-6">
                          <p className="cart-list-title">
                            {course.course_display_name}
                          </p>

                          <p className="cart-list-price-order p-0 m-0">
                            Price : {"₹ " + course.fee_plan_total_fee} <br />
                          </p>

                        </div>
                      </div>
                    </>
                  )
                })
              }
            </div>
            <div className="success-bottom-btn mt-4">
              <Link className="btn my-font order-history-btn" to="/">Go To Home</Link>
              {/*  <button className="btn my-font order-history-btn2">Start Learning</button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Success;
