import React, { useEffect, useState } from "react";
// import { environment } from "../../Services/HttpData";
import Modal from "react-modal";
import "../../css/ecom/ecomStyle.css";
import propelldlogo from "../../images/propelled-logo.png";
import { useParams } from "react-router";
// import EcomShowAddress from "../propelld/EcomShowAddressCopy";
import EcomShowAddress from "../../components/Ecom/EcomShowAddress";
import EcomAddUserAddress from "../propelld/EcomAddUserAddressCopy";
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import EcomCheckoutPayment from "../propelld/EcomCheckoutPyamentCopy";
import CheckoutInstallment from "../propelld/CheckoutInstallmentcopy";
import EcomEditProfile from "../../components/Ecom/EcomEditProfile";
import EcomProfileDetails from "../../components/Ecom/EcomProfileDetails";
import propelldCheckout from "./propelldCheckout";
import { validationFunction } from "../Ecom/ValidationHelper";
import MixpanelInit from "../Mixpanel/MixpanelInit";
import { useHistory } from "react-router";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);
const EcomCheckout = () => {
	//*** below order id means course_id ****
	const history = useHistory();
	var phoneno = /^\+?([6-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/;
	const { orderId } = useParams();

	const { cartId } = useParams();
	const [showInstallment, setShowInstallment] = useState(orderId ? true : false);
	const [loading, setLoading] = useState(true);
	const [addressObject, setAddressObject] = useState({});
	const [addressDetails, setAddressDetails] = useState({});
	/* const [orderId,setorderId] = useState(""); */
	const [checkoutinstallment, setCheckoutinstallment] = useState([]);
	const [response, setResponse] = useState("");
	const [checkoutDetails, setCheckoutDetails] = useState([]);
	const [profileDetails, setProfileDetails] = useState([]);
	//const [installmentValue,setInstallmentValue] = useState(0);
	let [totalProductPrice, setProductPrice] = useState(0);
	const [productDiscount, setProductDiscount] = useState({
		coupon_id: "",
		coupon_title: "",
		discount: 0,
	});
	const [totalCartPrice, setCartPrice] = useState(0);
	const [cartDiscount, setCartDiscount] = useState({
		coupon_id: "",
		coupon_title: "",
		discount: 0,
	});
	const [initialCartItems, setInitialCartItems] = useState({});
	const [cartDetails, setCartDetails] = useState({
		value: null,
		currencySign: "INR",
	});
	const [courseData, setcourseData] = useState();
	const [loan, setloan] = useState();
	const [isDisabled, setisDisabled] = useState(false);
	const [mobileInputBox, setmobileInputBox] = useState(true);
	const [emailInputBox, setEmailInputBox] = useState(true);
	const [gstin, setGstin] = useState("");
	const [gstinselected, setGstinselected] = useState(false);
	const [gstVerfy_is, SetgstVerfy_is] = useState({});
	// console.log(loan);
	let cartItems;
	let userDetails;

	const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "";
	const wid_data = localStorage.getItem("userWID") ? JSON.parse(localStorage.getItem("userWID")) : null;
	let wid = null;
	if (wid_data) {
		if (wid_data.results) {
			if (wid_data.results.length) {
				if (wid_data.results[0].wid) {
					wid = wid_data.results[0].wid;
				}
			}
		}
	}

	//update by dinesh

	const handleevent = e => {
		const { name, value } = e.target;
		if (!/\s/g.test(value)) {
			setGstin(value);
		}
	};

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			width: "30%",
			height: "100% !important",
		},
	};
	const [getOpen, setOpen] = useState(false);
	const [isMobileOTPSent, setMobileOTPSent] = useState(false);
	const [buttonmobileName, setbuttonmobileName] = useState("Send OTP");
	const [getTimeInSec, setTimeInSec] = useState("");
	const [getTimeInSecEmail, setTimeInSecEmail] = useState("");
	const [isMobileOtp, setMobileOtp] = useState("");
	const [isMobileOTPReSent, setMobileOTPReSent] = useState(false);

	const [isEmailOTPSent, setEmailOTPSent] = useState(false);
	const [buttonEmailName, setbuttonEmailName] = useState("Send OTP");
	const [getEmailTimeInSec, setEmailTimeInSec] = useState("");
	const [isEmailOtp, setEmailOtp] = useState("");
	const [isEmailOTPReSent, setEmailOTPReSent] = useState(false);
	const [getAction, setAction] = useState("");

	const clearMobileEmailOTPData = () => {
		setOpen(false);
		setMobileOTPSent(false);
		setbuttonmobileName("Send OTP");
		setTimeInSec("");
		setMobileOtp("");
		setTimeInSecEmail("");
		setMobileOTPReSent(false);

		setEmailOTPSent(false);
		setbuttonEmailName("Send OTP");
		setEmailTimeInSec("");
		setEmailOtp("");
		setEmailOTPReSent(false);
		setAction("");
	};

	const handleMobileOtp = e => {
		const { name, value } = e.target;
		if (name === "student_mobile_no" &&
			/^[0-9]*$/.test(value) &&
			!value.includes('e') &&
			!value.includes('.') &&
			!value.includes('-') &&
			!value.includes('+')) {
			setMobileOtp(value);
		}

	};

	const handleEmailOtp = e => {
		const { name, value } = e.target;

		if (name === "student_mobile_no" &&
			/^[0-9]*$/.test(value) &&
			!value.includes('e') &&
			!value.includes('.') &&
			!value.includes('-') &&
			!value.includes('+')) {
			setEmailOtp(value);
		}

	};

	//update by dinesh
	var a = 60;
	const setTime = () => {
		a = +a - 1;
		setTimeout(() => {
			if (a >= 0) {
				setTimeInSec(`Resend OTP in ${a} sec`);
				setTime();
			} else {
				setMobileOTPReSent(true);
			}
		}, 1000);
	};

	var b = 60;
	const setTimeEmail = () => {
		b = +b - 1;
		setTimeout(() => {
			if (b >= 0) {
				setTimeInSecEmail(`Resend OTP in ${b} sec`);
				setTimeEmail();
			} else {
				setEmailOTPReSent(true);
			}
		}, 1000);
	};

	const sendOTP = e => {
		if (isMobile.trim().length < 10) {
			toast.error("Please Enter Valid Mobile Number");
			return false;
		}
		if (isMobile.trim().match(phoneno) === null || isMobile[0] === "+") {
			toast.error("Please Enter Valid Mobile Number");
			return false;
		}
		setmobileInputBox(true);
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({}),
		};
		var reqURL;
		reqURL = process.env.REACT_APP_ECOM_URL + "otp/otp_generate";
		requestOptions.body = JSON.stringify({ mobile: isMobile, country_code: "+91", lead_type: 913 });
		fetch(reqURL, requestOptions)
			.then(async response => {
				MixpanelInit("Track", "otp_requested", { phone_number: isMobile });
				const data = await response.json();
				// check for error response
				// //console.log(response);
				//console.log(data);
				// if (!response.ok) {
				//   // get error message from body or default to response status
				//   const error = (data && data.message) || response.status;
				//   return Promise.reject(error);
				// }
				if (data.replyCode === "success") {
					a = 60;
					toast.success(data.replyMsg);
					setTime();
					setMobileOTPSent(true);
					setMobileOTPReSent(false);
					setbuttonmobileName("Verify OTP");
				} else {
					toast.error(data.replyMsg);
				}
			})
			.catch(error => {
				this?.setState({ errorMessage: error.toString() });
				console.error("There was an error!", error);
			});
	};

	const editmobile = e => {
		const { value, name } = e.target;
		setMobile(value);
	};
	const editEmail = e => {
		const { value, name } = e.target;
		setSendEmail(value?.trim());
	};

	const verifyMobileOtp = () => {

		if (isMobileOtp.length !== 6) {
			toast.error("Please Enter Valid OTP");
			return
		}

		fetch(process.env.REACT_APP_ECOM_URL + "otp/otp_verify", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ mobile: isMobile, otp: isMobileOtp, lead_type: 913 }),
		}).then(async response => {
			const data = await response.json();
			//console.log(data);
			if (data.replyCode === "success") {
				// toast.success(data.replyMsg);
				MixpanelInit("Track", "otp_verified", { phone_number: isMobile });
				toast.success("Mobile OTP Verified");
				setbuttonmobileName("Verified");
				setMobileOTPSent(false);
			} else {
				toast.error(data.replyMsg);
			}
		});
	};

	const sendEmailOTP = () => {
		if (sendOtpEmail.trim().length < 1) {
			toast.error("Please Enter Valid Email Address");
			return false;
		} else if (!validationFunction?.validateUserFields("email", sendOtpEmail.trim())) {
			toast.error("Please Enter Valid Email Address");
			return false;
		}
		var reqURL;
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({}),
		};
		reqURL = process.env.REACT_APP_ECOM_URL + "otp/otp_generate_email";
		requestOptions.body = JSON.stringify({ email: sendOtpEmail, lead_type: 913 });
		fetch(reqURL, requestOptions)
			.then(async response => {
				const data = await response.json();
				// check for error response
				// //console.log(response);
				//console.log(data);
				// if (!response.ok) {
				//   // get error message from body or default to response status
				//   const error = (data && data.message) || response.status;
				//   return Promise.reject(error);
				// }
				if (data.replyCode === "success") {
					b = 60;
					setTimeEmail();
					setEmailOTPSent(true);
					setEmailOTPReSent(false);
					setbuttonEmailName("Verify OTP");
					toast.success(data.replyMsg);
				} else {
					toast.error(data.replyMsg ? data.replyMsg : data.message);
				}
			})
			.catch(error => {
				this?.setState({ errorMessage: error.toString() });
				console.error("There was an error!", error);
			});
	};

	const verifyEmailOtp = e => {



		if (isEmailOtp.length !== 6) {
			toast.error("Please Enter Valid OTP");
			return
		}
		fetch(process.env.REACT_APP_ECOM_URL + "otp/otp_verify_email", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				// 'Authorization': 'Bearer ' + user.token,
			},
			body: JSON.stringify({ email: sendOtpEmail, otp: isEmailOtp, lead_type: 913 }),
		}).then(async response => {
			const data = await response.json();
			if (data.replyCode === "success") {
				// toast.success(data.replyMsg);
				toast.success("Email OTP Verified");
				setbuttonEmailName("Verified");
				setEmailOTPSent(false);
			} else {
				toast.error(data.replyMsg);
			}
		});
	};

	// const getStudentAddress = () => {
	// 	const requestOptions = {
	// 		method: "GET",
	// 		headers: {
	// 			Authorization: "Basic " + auth_token,
	// 		},
	// 	};
	// 	fetch(environment.ecomApiUrl + "user/address", requestOptions).then(resp => {
	// 		// fetching api url for courses listing
	// 		resp.json().then(result => {
	// 			if (result?.status !== "failed") {
	// 				setCheckoutDetails(result?.data);
	// 			} else {
	// 				setCheckoutDetails([]);
	// 			}

	// 			//console.log(checkoutDetails);
	// 		});
	// 	});
	// };
	// console.log(checkoutDetails);
	function loadScript(src) {
		return new Promise(resolve => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = () => {
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};
			document.body.appendChild(script);
		});
	}

	function selectUserAddress() {
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Basic " + auth_token,
			},
		};
		fetch(environment.ecomApiUrl + "user/address", requestOptions).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				setCheckoutDetails(result.data);
				setAddressDetails({});
				////console.log(checkoutDetails);
			});
		});
	}

	function myFunction() {
		var x = document.getElementById("myDIV");
		if (x.style.display === "block") {
			x.style.display = "none";
		} else {
			x.style.display = "block";
		}
	}

	const getcoursedetails = () => {
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Basic " + auth_token,
			},
		};
		fetch(environment.ecomApiUrl + `propelled/get-course/id/?course_id=${orderId}`, requestOptions).then(resp => {
			resp.json().then(result => {

				if (result?.data[0]?.is_already_admission) {
					history.push({ pathname: "/course-infromation", state: { course_name: result?.data[0]?.course_name } });
				}
				setcourseData(result?.data);
			});
		});
	};

	const [text, setText] = useState("");
	const [isMobile, setMobile] = useState("");
	const [sendOtpEmail, setSendEmail] = useState("");
	const proceedCheckOut = async (cartId, type, address) => {

		if (type === 'submit') {

		} else {
			setText(address);
			let mo = profileDetails?.student_mobile_no?.split("-").pop();
			setMobile(mo);
			setSendEmail(profileDetails?.student_email);
		}

		// if (address?.father_name === "") {
		// 	toast.error("Please enter Father name in edit address");
		// 	return;
		// }

		// if (isDisabled) {
		// 	toast.error("Please enter a valid loan amount");
		// 	return false;
		// }





		if (gstinselected && gstin.length < 1) {
			toast.warning("Please Enter GSTIN");
			return;
		}
		if (gstinselected && !gstVerfy_is?.status) {
			toast.warning("Please verify GSTIN.");
			return;
		}
		if (gstinselected && gstVerfy_is?.status == "failed") {
			toast.warning("Please verify GSTIN.");
			return;
		}

		if (profileDetails?.student_dob === null || profileDetails?.student_dob === "") {
			toast.error("Please fill out the complete Profile Information DOB");
			return;
		}
		if (profileDetails?.student_gender_id === null || profileDetails?.student_gender_id === "") {
			toast.error("Please fill out the complete Profile Information Gender");
			return;
		}
		if (profileDetails?.student_email === null || profileDetails?.student_email === "") {
			toast.error("Please fill out the complete Profile Information Email");
			return;
		}
		if (profileDetails?.father_name === null || profileDetails?.father_name === "") {
			toast.error("Please fill out the complete Profile Information Father Name");
			return;
		}

		if (address[0]?.student_address_details_id && address[1]?.student_address_details_id) {
			if (buttonmobileName !== "Verified" && buttonEmailName !== "Verified") {
				setOpen(true);
				if (type === 'submit') {
					toast.error("Please verify the mobile number and email id first");
				}
				return;
			}

			if (buttonmobileName !== "Verified" || buttonEmailName !== "Verified") {
				setOpen(true);
			}

			if (buttonmobileName !== "Verified") {
				toast.error("Mobile Number is not verified");
				return false;
			} else if (buttonEmailName !== "Verified") {
				toast.error("Email ID is not verified");
				return false;
			} else
				if (!orderId) {
					setLoading(false);
					const requestOptionsCart = {
						method: "GET",
						headers: {
							Authorization: "Basic " + auth_token,
						},
					};
					//let isEmi = false;
					await fetch(environment.ecomApiUrl + "cart/list", requestOptionsCart).then(resp => {
						// fetching api url for courses listing
						resp.json().then(result => {
							//setCartItems(result.data.items);  // set result in state this will set current/updated value of setProduct to product
							cartItems = result.data.items;
							//totalProductPrice = cartItems[0].mrp - cartItems[0].discount;
							////console.log(cartItems);
						});
					});
				} else {
					setLoading(false);
					const requestOptions = {
						method: "GET",
						headers: {
							Authorization: "Basic " + auth_token,
						},
					};
					// await fetch(environment.ecomApiUrl + 'order/get-emi/'+orderId,requestOptions).then((resp)=>{ // fetching api url for courses listing
					//     resp.json().then((result)=>{
					//       installmentChk(true,result.data.emi[0])
					//       setCheckoutinstallment(result.data.emi);
					//      // setShowInstallment(true);
					//       if(result.data.details){
					//         if(result.data.details.items){
					//           if(result.data.details.items.length){
					//             cartItems = result.data.details.items[0]
					//             //console.log("get-emi",cartItems)
					//           }
					//         }
					//       }

					//       //console.log(result.data.emi);
					//     })
					// })
				}
			const requestOptionsUser = {
				method: "GET",
				headers: {
					Authorization: "Basic " + auth_token,
				},
			};

			await fetch(environment.ecomApiUrl + "user/detail", requestOptionsUser).then(resp => {
				// fetching api url for courses listing
				resp.json().then(result => {
					//setCartItems(result.data.items);  // set result in state this will set current/updated value of setProduct to product
					userDetails = result.data;
					//console.log(userDetails);
				});
			});

			const requestOptions1 = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Basic " + auth_token,
				},
				body: JSON.stringify({
					address: { billing: address[0], shipping: address[1] },
					student_detail: { student_mobile_no: isMobile, student_email: sendOtpEmail },
					center_course_ids: [courseData[0]?.center_course_id],
					loan_amount: loan,
					gstNo: gstin,
				}),
			};

			// console.log('Payload check',JSON.stringify({
			// 	address: { billing:address[0],shipping:address[1]},
			// 	student_detail:{student_mobile_no: isMobile,student_email:sendOtpEmail},
			// 	center_course_ids: [courseData[0]?.center_course_id],
			// 	loan_amount: loan,
			// }))
			// return
			fetch(environment.ecomApiUrl + "propelled/quote-creation", requestOptions1).then(resp => {
				// fetching api url for courses listing
				resp.json().then(result1 => {
					if (result1.status == "success") {
						toast.success(result1.message);
						window.location = "/propelld/success/" + courseData[0]?.center_course_id;
						clearMobileEmailOTPData();
					} else {
						setLoading(true);
						toast.error(result1.message);
						clearMobileEmailOTPData();
					}
				});
			});

			/*  if (localStorage.getItem("userWID")) { */
			// const requestOptions = {
			//   method: 'POST',
			//   headers: {
			//     'Content-Type': 'application/json',
			//     'Authorization': 'Basic '+auth_token
			//    },
			//   body: JSON.stringify({
			//     "cart_id" : cartId,
			//     "type" : type,
			//     "address":address,
			//     "order_id" : orderId,
			//     "pay_emi":installments.length
			// })
			// };
			const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
			// fetch(environment.ecomApiUrl + 'order/checkout',requestOptions).then((resp)=>{ // fetching api url for courses listing
			//     resp.json().then((result)=>{
			//         //setorderId(result.data.order_id);
			//         if( result.data.gateway_responce ){
			//           setResponse(result.data.gateway_responce);
			//           if(type == "razorpay"){

			//             let options = {
			//               "key": environment.razorpayKey,
			//               //"amount": 100.00,
			//               "currency": "INR",
			//               "name": cartItems.product_title,
			//               "description": cartItems.product_description,
			//               "image": `${environment?.baseUrlImg}attachment-1632935528953.png`,
			//               "order_id": result.data.gateway_responce.id,
			//               "handler": function (response){
			//                 //console.log(response);
			//                   const requestOptions1 = {
			//                     method: 'POST',
			//                     headers : {
			//                       'Content-Type' : 'application/json',
			//                       'Authorization': 'Basic '+auth_token
			//                     },
			//                     body: JSON.stringify({
			//                       "order_id" : result.data.order_id,
			//                       "gateway_order_id" : response.razorpay_order_id,
			//                       "gateway_payment_id" : response.razorpay_payment_id,
			//                       "gateway_signature" : response.razorpay_signature
			//                   })
			//                   };
			//                   fetch(environment.ecomApiUrl + 'order/razorpay-verify',requestOptions1).then((resp)=>{ // fetching api url for courses listing
			//                       resp.json().then((result1)=>{
			//                         //console.log(result1);
			//                         /* if(result1.status == "success"){ */
			//                           //toast.success(result1.message);
			//                           if(typeof orderId == 'undefined'){
			//                             window.location = "/ecom/order/status/"+result.data.order_id;
			//                           }
			//                           else{
			//                             window.location = "/ecom/order-installment/status/"+result.data.installment_id;
			//                           }
			//                         /* }else{
			//                           toast.error(result1.message);
			//                         } */
			//                       })
			//                   })
			//                // toast.success("This step of Payment Succeeded");
			//               },
			//               "prefill": {
			//                 "contact":userDetails.student_mobile_no,
			//                 "name": userDetails.student_first_name,
			//                 "email": userDetails.student_email
			//               },
			//               "notes" : {
			//                 "description":cartItems.product_description,
			//                 "name":cartItems.product_title
			//               },
			//               "theme": {
			//                 "color": "#2300a3"
			//               },
			//               "modal": {
			//                 "ondismiss": function(){
			//                     //console.log('Checkout form closed');
			//                     if(typeof orderId == 'undefined'){
			//                       window.location = "/ecom/order/status/"+result.data.order_id;
			//                     }else{
			//                       setLoading(true);
			//                     }

			//                 }
			//             }
			//             };
			//             let razorpayObject = new window.Razorpay(options);

			//             //console.log(razorpayObject);
			//             razorpayObject.on('payment.failed', function (response){
			//                 //console.log(response);
			//                 toast.success("This step of Payment Failed");
			//             });
			//             razorpayObject.open();
			//           }

			//           else{
			//             let f = document.createElement('form');
			//             f.action=environment.ccavenue;
			//             f.method='POST';

			//             let i=document.createElement('input');
			//             i.type='hidden';
			//             i.name='encRequest';
			//             i.value= result.data.gateway_responce;
			//             f.appendChild(i);

			//             let j=document.createElement('input');
			//             j.type='hidden';
			//             j.name='access_code';
			//             j.value= environment.hdfcKey;
			//             f.appendChild(j);

			//             document.body.appendChild(f);
			//             f.submit();
			//           }

			//         }
			//         else{
			//           if(typeof orderId == 'undefined'){
			//             window.location = "/ecom/order/status/"+result.data.order_id;
			//           }
			//           else{
			//             window.location = "/ecom/order-installment/status/"+result.data.installment_id;
			//           }
			//         }
			//         //console.log(result.data);
			//     })
			// })
			/*  }else{
		doLogin();
	  } */
		} else {
			toast.error("Please Add Billing and Shipping Address");
		}
	};


	const editProfile = obj => {
		var x = document.getElementById("profileUpdateForm");
		if (x.style.display === "block") {
			x.style.display = "none";
		} else {
			x.style.display = "block";
		}

		//addbillinghide()
	};

	const selectAddress = object => {
		// console.log(object)
		setAddressObject(object);
		//console.log(object);
	};
	/* Update Address */
	const updateAddress = obj => {
		setAddressDetails({ ...obj });
		// console.log(obj);
	};

	let sum = 0;
	const [installments, setInstallments] = useState([]);
	//console.log(installments)
	//console.log('object-chk-1',installments)
	const installmentChk = (chk, object) => {
		if (chk) {
			let findTmp = false;
			for (let i = 0; i < installments.length; i += 1) {
				if (object.installment_id == installments[i].installment_id) {
					findTmp = true;
				}
			}
			//console.log('object-chk-0.0',findTmp)
			if (!findTmp) {
				//console.log('object-chk-0.1')
				let nerval = setInstallments(installments => [...installments, object]);
				//console.log('object-chk-0.2',nerval)
			}
		} else {
			setInstallments(installments => {
				const copyItems = [...installments];
				const finalItems = [];
				for (let i = 0; i < copyItems.length; i += 1) {
					if (object.installment_id != copyItems[i].installment_id) {
						finalItems.push(copyItems[i]);
					}
				}
				return finalItems;
			});
		}

		//console.log('object-chk-0',chk)

		const newcopyItems = [...installments];
		//console.log('object-chk-2',newcopyItems)
		//console.log('object-chk-3',object)
	};

	const installmentValue = () => {
		let sum = 0;
		//console.log(installments)
		for (let i = 0; i < installments.length; i += 1) {
			if (installments[i]) {
				sum += Number(installments[i].installment_amount) - Number(installments[i].discount);
			}
		}
		//console.log('sum',sum)
		return sum;
	};

	const loanAmount = loanAmount => {
		setloan(loanAmount);
	};

	const disabled = disabled => {
		setisDisabled(disabled);
	};

	const getprofileDetails = () => {
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Basic " + auth_token,
			},
		};
		fetch(environment.ecomApiUrl + "address/profile-details", requestOptions).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				if (result?.status !== "failed") {
					// console.log(result?.data);
					setProfileDetails(result?.data);
				} else {
					setProfileDetails([]);
				}

				//console.log(checkoutDetails);
			});
		});
	};

	const gstinform = () => {
		if (gstinselected == false) {
			setGstinselected(true);
		} else {
			setGstinselected(false);
		}

		var x = document.getElementById("gstininput");
		if (x.style.display == "none") {
			x.style.display = "block";
		} else {
			x.style.display = "none";
		}
	};

	const getAddress = () => {

		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Basic " + auth_token,
			},
		};
		fetch(environment.ecomApiUrl + "address/list", requestOptions).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				if (result?.data) {

					setAddressObject(result?.data);
					setCheckoutDetails(result?.data)
				} else {
					setAddressObject([]);
					setCheckoutDetails([])
				}
			});
		});
	};
	const gstVerify = e => {
		if (gstinselected && gstin.length <= 14) {
			toast.warning("Please enter valid GSTIN");
			return;
		}
		const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "";
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + auth_token);

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};
		fetch(process.env?.REACT_APP_AUTH_URL + "validate/" + gstin, requestOptions)
			.then(res => res.json())
			.then(resp => {
				if (resp?.status == "success") {
					toast.success(resp?.message);
					SetgstVerfy_is(resp);
				} else {
					toast?.error(resp?.message);
				}
			});
	};


	useEffect(() => {
		getAddress();
		getprofileDetails();
		getcoursedetails();
		//getStudentAddress();
	}, []);

	// useEffect(() => {

	// 	getAddress();
	// }, []);

	return (
		<>
			<div className='container mt-5'>
				<div className='row'>
					<div className='col-md-9'>

						{/* {courseData?.map(course => {
							return (
								<>
									<div className='d-flex flex-row mobile-flex-column card px-2 py-2 mb-4 cardfor-propelld'>
										<div className='col-md-3'>
											<img
												
												src={course.center_course_image}
												alt='course Img'
												width='100%'
											/>
										</div>

										<div className='col-md-6'>
											<p className='cart-list-title'>{course.course_display_name}</p>

											<p className='cart-list-price-order p-0 m-0'>
												Price : {"₹ " + course.fee_plan_total_fee} <br />
											</p>
										</div>
									</div>
								</>
							);
						})} */}


						<div className='side-profile-box'>
							<div className='checkout-address-detail'>
								<div className='address-text-box' style={{ display: "flex", justifyContent: "space-between" }}>
									<b>Profile Info</b>{" "}
									<b onClick={() => editProfile(profileDetails[0])} style={{ color: "green", cursor: "pointer" }}>
										Edit
									</b>
								</div>
							</div>
							<div className='address-and-btns mt-3'>{<EcomProfileDetails profileDetails={profileDetails} />}</div>
							<EcomEditProfile profileDetails={profileDetails} onChange={getprofileDetails.bind()} />
						</div>

						<div className='side-address-box'>
							{/* <div className='checkout-address-detail'>
								<div className='address-text-box'>
									<b>Address Info</b>
             
								</div>
							</div> */}
							<div className='address-and-btns mt-3'>
								{
									<EcomShowAddress
										checkoutDetails={checkoutDetails}
										onChange={getAddress.bind()}
										onChangeUpdateAddress={updateAddress.bind()}
									/>
								}
							</div>
							{/* <EcomAddUserAddress
								onChange={selectUserAddress.bind()}
								checkoutDetails={checkoutDetails}
								addressDetails={addressDetails}
								onChangeUpdateAddress={selectAddress.bind()}
							/> */}
						</div>

						<div className='side-gst-box'>
							<input type='checkbox' style={{ display: "inline-block" }} id='gstincheck' onClick={gstinform}></input>{" "}
							Have GSTIN(Optional) <br></br>
							<input
								type='text'
								className='form-control col-6 mt-2'
								id='gstininput'
								placeholder='Enter GSTIN'
								style={{ display: "none" }}
								name='gstin'
								value={gstin ? gstin : ""}
								maxLength={15}
								onChange={handleevent}></input>
							{gstVerfy_is?.status == "success" && gstinselected && (
								<>
									<span style={{ color: "#046635", fontSize: "10px" }}>{gstVerfy_is?.data?.name}</span>
									<br></br>
									<button
										onClick={e => {
											SetgstVerfy_is({});
										}}>
										Update GSTIN
									</button>
									<br />
								</>
							)}
							<br />
							{(gstinselected && gstVerfy_is?.status !== "success") && (
								<button disabled={gstVerfy_is?.status == "success"} onClick={gstVerify}>
									Verify GSTIN
								</button>
							)}
						</div>
					</div>
					<div className='col-md-3'>
						<div className='payment-side-bar'>
							<EcomCheckoutPayment
								courseData={courseData}
								initialCartItems={initialCartItems}
								cartDetails={cartDetails}
								onChange={proceedCheckOut.bind()}
								showInstallment={showInstallment}
								installmentCal={() => installmentValue()}
								loanAmount={loanAmount}
								disabled={disabled}
							/>
							{loading ? (
								<div className='pay-btn text-center mt-3'>
									<button
										className='continue-with-propelld mt-2'
										onClick={() => proceedCheckOut(cartDetails.cart_id, "propelld", checkoutDetails)}>
										<span> Continue With</span> <img src={propelldlogo}></img>
									</button>
								</div>
							) : (
								<div className='pay-btn text-center mt-3'>
									<TailSpin color='#00BFFF' height={30} width={30} />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			<Modal
				appElement={document.getElementById("root")}
				isOpen={getOpen}
				// onAfterOpen={afterEditOpenModal}
				// onRequestClose={() => CourierSetup('')}
				style={customStyles}
				contentLabel='Example Modal'
				id='propelldotpverify'>
				<div style={{ textAlign: "end" }}>
					<button className='btn btn-sm btn-danger mb-2' onClick={() => clearMobileEmailOTPData()}>
						X
					</button>
				</div>

				<div>
					{/* <div className="form-group row"> */}
					<label htmlFor='pincode' className=' col-form-label col-lg-12 p-0'>
						Mobile No. *{" "}
					</label>
					<div className='col-lg-12 p-0' style={{ display: "flex", alignItems: "center", gap: "15px" }}>
						<input
							required
							type='text'
							className='form-control'
							id='mobile_number'
							name='student_mobile_no'
							maxLength={14}
							disabled={mobileInputBox}
							value={isMobile}
							placeholder=''
							onChange={editmobile}
						/>
						{!isMobileOTPSent && <i className='fas fa-edit' onClick={e => setmobileInputBox(false)}></i>}{" "}
					</div>

					{/* </div> */}
					<div className='form-group row justify-content-center m-0 p-0 mt-2 col-lg-12'>
						{isMobileOTPSent ? (
							<>
								<div className=' col-lg-8 mob_num_otp p-0'>
									<input
										required
										type='text'
										className='form-control otp-input'
										id='mobile_number'
										name='student_mobile_no'
										maxLength={6}
										value={isMobileOtp}
										placeholder='OTP'
										onChange={handleMobileOtp}
									/>
								</div>
							</>
						) : null}

						<button
							type='button'
							className='btn btn-primary btn-save-add btn-verify col-lg-4'
							onClick={buttonmobileName === "Send OTP" ? sendOTP : verifyMobileOtp}>
							{buttonmobileName}
						</button>
					</div>

					{isMobileOTPSent ? (
						<>
							<div className='form-group row justify-content-center'>
								<div>
									{isMobileOTPReSent ? (
										<>
											<span
												className='resendotppropelld'
												type='button'
												// className="btn btn-primary btn-save-add "
												onClick={sendOTP}>
												Re-Send OTP
											</span>
										</>
									) : (
										<span className='resendotppropelld'>{getTimeInSec}</span>
									)}
								</div>
							</div>
						</>
					) : null}

					{/* <br></br> */}

					{/* <div className="form-group row"> */}
					<label htmlFor='pincode' className=' col-form-label col-lg-12 p-0'>
						Email Id *{" "}
					</label>
					<div className='col-lg-12 p-0' style={{ display: "flex", alignItems: "center", gap: "15px" }}>
						<input
							type='text'
							className='form-control'
							id='email'
							name='student_email'
							value={sendOtpEmail}
							disabled={emailInputBox}
							placeholder=''
							onChange={editEmail}
							required
						/>
						{!isEmailOTPSent && <i className='fas fa-edit' onClick={e => setEmailInputBox(false)}></i>}
					</div>

					{/* </div> */}

					<div className='form-group row justify-content-center m-0 p-0 mt-2 col-lg-12'>
						{isEmailOTPSent ? (
							<>
								<div className=' col-lg-8 mob_num_otp p-0'>
									<input
										required
										type='text'
										className='form-control otp-input'
										id='mobile_number'
										name='student_mobile_no'
										maxLength={6}
										value={isEmailOtp}
										placeholder='OTP'
										onChange={handleEmailOtp}></input>
								</div>
							</>
						) : null}

						<button
							type='button'
							className='btn btn-primary btn-save-add btn-verify col-lg-4'
							onClick={buttonEmailName === "Send OTP" ? sendEmailOTP : verifyEmailOtp}>
							{buttonEmailName}
						</button>
					</div>

					{isEmailOTPSent ? (
						<>
							<div className='form-group row justify-content-center'>
								<div>
									{isEmailOTPReSent ? (
										<>
											<span
												className='resendotppropelld'
												type='button'
												// className="btn btn-primary btn-save-add "
												onClick={sendEmailOTP}>
												Re-Send OTP
											</span>
										</>
									) : (
										<span className='resendotppropelld'>{getTimeInSecEmail}</span>
									)}
								</div>
							</div>
						</>
					) : null}
					{/* <br></br> */}
					<button type='button' className='btn btn-primary btn-save-add' onClick={() => proceedCheckOut("", "submit", text)}>
						Submit
					</button>
				</div>
			</Modal>
		</>
	);
};

export default EcomCheckout;
