import React from "react";
import ShopingCartlist from "./../components/Cart/ShopingCartlist";
import CartDetails from "./../components/Cart/CartDetails";
// import { environment } from "../Services/HttpData";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cartItems: [],
      cartDetails: {value:null,currencySign:"INR"},
    };
  }
  async componentDidMount() {
    await this.getCartItems();
  }
  async getCartItems() {
    const skvDetails = JSON.parse(sessionStorage.getItem("skavaTokenDetails"));
    // //console.log(skvDetails.sessionId);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apiKey: skvDetails.sessionId,
      },
    };
    fetch(environment.baseUrl + "getCartListSkSite", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data = /* isJson && */ await response.json();
        // check for error response
        // //console.log(response);
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        if (response.ok) {
            if(response.status===200)
            this.setState({cartItems:data.items,cartDetails:{value:data.value,currencySign:data.currencySign}})
        //   //console.log(data);
        }
      })
      .catch((error) => {
        this.setState({ errorMessage: error.toString() });
        console.error("There was an error!", error);
      });
  }
  handleChildClick(value) {
    //console.log(value);
    if(value==="reload"){
      this.props.onChange("reloadCart");
      this.getCartItems();
    }
  }

  render() {
    return (
      <>
        <div className="container-fluid pr-0">
          <div className="row mx-0">
            <ShopingCartlist
              handelEvent={{cartItems:this.state.cartItems,currency:this.state.cartDetails.currencySign}}
              onChange={this.handleChildClick.bind(this)}
            />
            <CartDetails
              handelEvent={this.state.cartDetails} />
          </div>
        </div>
      </>
    );
  }
}

export default Cart;
