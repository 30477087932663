import React from "react";
import $ from "jquery";
import "bootstrap";
import { Link } from "react-router-dom";
import { encode as base64_encode } from "base-64";
// import { environment } from "../../Services/HttpData";
import { toast } from "react-toastify";
import userImage from "../../images/Ellipse 25.png";

const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class AllArticles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHideGrid: true,
      showHideList: false,
      listItems: [],
      slug:null
    };
    this.viewlist = this.viewlist.bind(this);
  }
  componentDidMount() {
  }
  componentWillReceiveProps(props) {
    ////console.log(props.handelEvent);
    this.setState({
      listItems: props.handelEvent,
    });
  }

  viewlist(name) {
    switch (name) {
      case "grid":
        this.setState({ showHideGrid: true, showHideList: false });
        break;
      case "list":
        this.setState({ showHideGrid: false, showHideList: true });
        break;
      default:
    }
    //////console.log('asdasd');
  }
  render() {
    const { showHideGrid, showHideList, listItems } = this.state;
    return (
      <>
        <main
          role="main"
          className=" col-lg-9 col-12 col-sm-12 px-4 pt-4 all-articles"
        >
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2">
            <h3 className="h3 mb-0">Articles</h3>
            <div className="row m-0 align-items-center">
              <p className="mb-0 sort-by-label">Sort By:</p>
              <select name="" id="" className="sort-list" defaultValue={""}>
                <option defaultValue="">Recent</option>
                <option value="">Latest</option>
                <option value="">New</option>
              </select>
              <i
                className={
                  showHideGrid
                    ? "active fas fa-th changeview mx-3"
                    : "fas fa-th changeview m-3"
                }
                onClick={() => this.viewlist("grid")}
              ></i>
              <i
                className={
                  !showHideGrid
                    ? "active fas fa-list changeview mr-3"
                    : "fas fa-list changeview mr-3"
                }
                onClick={() => this.viewlist("list")}
              ></i>
            </div>
          </div>
          {showHideGrid && (
            <div className="row m-0">
              {listItems.map((item) => {
                return (
                  <div
                    className=" col-xl-4 col-lg-4  my-2 col-12 col-md-6 p-2"
                    key={item.id}
                  >
                    <div
                      className="card boder-card m-0 hover-card gridbox"
                      data-toggle="popover"
                      data-trigger="hover"
                      data-placement="right"
                      data-html="true"
                      to="#login"
                    >
                      <div className="img">
                        <Link
                          to={`/article-details/${
                            item?.slug}`}
                        >
                          <img
                            src={environment.baseUrlImg +item?.featured_images}
                            alt=""
                            className="img-fluid mb-2"
                          />
                        </Link>
                      </div>
                      <div className="card-content p-0">
                        <Link
                          to={`/article-details/${
                            item?.slug}`}
                          className="h6 font-weight-bold"
                        >
                          {item?.title}
                        </Link>
                        <p  dangerouslySetInnerHTML={{
                        __html: item?.description.length > 200 ? 
                        item?.description.substring(0, 200 - 3) + "..." : 
                        item?.description,
                      }}></p>
                        <div className="row align-items-center">
                          <div className="col-3 pr-0">
                          <img
                            src={userImage}
                            alt=""
                            className="img-fluid mb-2 owner-img"
                          />
                          </div>
                          <div className="col-9">
                            <h5 className="owner-name">by - Rakesh Agarwal</h5>
                            <p className="created-on">on July 25, 2021</p>
                          </div>
                        </div>
                      </div>

                      <div
                        id="login"
                        className=" hovercard col-lg-4 p-3 m-0 col-12 mb-2 col-md-6 hover-card-content"
                      >
                        <Link
                          to="/article-details"
                          className="h6 font-weight-bold"
                        >
                          JEE Main 2021 (February Attempt) Paper Analysis
                          (Physics, Chemistry,
                        </Link>
                        <div className="row m-0 p-0">
                          <p className="mr-1 col-4 p-0">No. of Tests</p>
                          <p className="col-7 p-0 ml-auto">
                            19 Total Tests * (1st Year: 10 Unit + 4 Review + 5
                            Major, 2nd Year: Schedule in April 2022)
                          </p>
                        </div>
                        <div className="row m-0">
                          <p className="mr-1 col-4 p-0">Target Year</p>
                          <p className="col-7 p-0 ml-auto text-left">2023</p>
                        </div>
                        <div className="row m-0">
                          <p className="mr-1 col-4 p-0">Eligibility</p>
                          <p className="col-7 p-0 ml-auto text-left">
                            XI ( Studying)
                          </p>
                        </div>
                        <div className="row m-0">
                          <p className="mr-1 col-4 p-0">Language</p>
                          <p className="col-7 p-0 ml-auto text-left">
                            English / Hindi
                          </p>
                        </div>
                        <div className="row m-0">
                          <p className="mr-1 col-4 p-0">Stream</p>
                          <p className="col-7 p-0 ml-auto text-left">Science</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {showHideList && (
            <div>
              {/* {listItems.map((item) => {
                return (
                  <div
                    className="card p-3 mb-3 course-card"
                    key={item.productId}
                  >
                    <div className="row m-0 align-items-center">
                      <div className="col-12 col-lg-4 p-0">
                        <img src={item?.appIcon} alt="" width="100%" />
                        <Link to="#" className="bookmark-btn">
                          <i className="far fa-bookmark"></i>
                        </Link>
                      </div>
                      <div className="card-content col-12 col-lg-8">
                        <div className="d-flex flex-row flex-wrap mb-2">
                          <div>
                            <h6 className="mr-2">{item?.title}</h6>
                          </div>
                          <div className="row ml-auto">
                            <img
                              className="mr-2"
                              src="images/save-icon.png"
                              alt=""
                              width="15px"
                              height="15px"
                            />
                            <Link className="mr-2" to="#">
                              <p>Add to wishlist</p>
                            </Link>
                            <img
                              src="images/share-icon.png"
                              alt=""
                              width="15px"
                              height="15px"
                            />
                          </div>
                        </div>
                        <div className="row m-0 justify-content-between">
                          <div className="mr-3 mb-2">
                            <p className="f-12 text-muted mb-1">
                              <img
                                src="images/document.png"
                                className="mr-1"
                                alt=""
                                width="12px"
                              />
                              No. of Tests
                            </p>
                            <p className="mr-1 font-size18 ">Live Class</p>
                            <p className="mr-1 font-size18">Test Series</p>
                            <p className="mr-1 font-size18">Online Test</p>
                          </div>
                          <div className="mr-3 mb-2">
                            <p className="f-12 text-muted mb-1">
                              <img
                                src="images/document-blank.png"
                                className="mr-1"
                                alt=""
                                width="12px"
                              />
                              Stream
                            </p>
                            <p className="mb-0 f-12">Science</p>
                          </div>
                          <div className="mr-3 mb-2">
                            <p className="f-12 text-muted mb-1">
                              <img
                                src="images/language.png"
                                className="mr-1"
                                alt=""
                                width="12px"
                              />
                              Language
                            </p>
                            <p className="mb-0 f-12">English / Hindi</p>
                          </div>
                          <div className="mr-3 mb-2">
                            <p className="f-12 text-muted mb-1">
                              <img
                                src="images/reliability.png"
                                className="mr-1"
                                alt=""
                                width="12px"
                              />
                              Eligibility
                            </p>
                            <p className="mb-0 f-12">XI Studying</p>
                          </div>
                          <div className="mr-3 mb-2">
                            <p className="f-12 text-muted mb-1">
                              <img
                                src="images/target.png"
                                className="mr-1"
                                alt=""
                                width="12px"
                              />
                              Target Year
                            </p>
                            <p className="mb-0 f-12">2023</p>
                          </div>
                        </div>
                        <div className="row mx-0  justify-content-between">
                          <div>
                            <div className="row m-0">
                              <h5 className="font-weight-bold mr-3 mb-0">
                                {item?.salesPrice?.currency === "INR"
                                  ? "₹"
                                  : item?.salesPrice?.currency}
                                {item?.salesPrice?.value}
                              </h5>
                              <h5 className="font-weight-bold text-danger mb-0">
                                <s>
                                  {item?.price?.currency === "INR"
                                    ? "₹"
                                    : item?.price?.currency}
                                  {item?.price?.value}
                                </s>
                              </h5>
                            </div>
                          </div>
                          <div className="row mx-0 mb-2">
                            <Link
                              type="button"
                              className="btn btn-outline-green-low-radius mr-3 mb-1"
                              to="/cart"
                            >
                              Buy Now
                            </Link>
                            <button
                              type="button"
                              className="btn btn-green-low-radius mb-1"
                              onClick={() =>this.addToCart(item)}
                            >
                              Add to Bag
                            </button>
                          </div>
                        </div>
                        {item?.price?.value > item?.salesPrice?.value && (
                          <p
                            className="mb-0"
                            style={{
                              color: "#046635",
                              marginTop: "-28px",
                              color: "#429B00",
                              fontSize: "16px",
                            }}
                          >
                            Save
                            {item?.price?.currency === "INR"
                              ? "₹"
                              : item?.price?.currency}
                            {item?.price?.value - item?.salesPrice?.value} (
                            {this.getPercantage(
                              item?.salesPrice?.value,
                              item?.price?.value
                            ).toFixed(2)}
                            % OFF)
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })} */}
            </div>
          )}
        </main>
      </>
    );
  }
}

export default AllArticles;
