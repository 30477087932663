import React from "react";
import JobImg1 from "../../images/jobs-img.png";
import JobImgCard1 from "../../images/job-card-1.png";
import JobImgCard2 from "../../images/job-card-2.png";
import JobImgCard3 from "../../images/job-card-3.png";
import JobImgWork1 from "../../images/job-work-1.png";
import JobImgWork2 from "../../images/job-work-2.png";
import JobImgWork3 from "../../images/job-work-3.png";
// import { environment } from '../../Services/HttpData';
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);
class JobsContent extends React.Component {
    constructor(props) {
        super(props);
        //   //console.log(this.props);
        this.state = {
            PageContent1: [],
        };
    }
    componentWillReceiveProps(props) {
        //console.log('jobs Details:', props.handelEvent.content);
        let job1 = {};
        //   job1.title=props.handelEvent.title;
        //   job1.content=[];
        //   props.handelEvent.content.map((item) => {
        //       if (item.id === 132) job1.content.push(item);
        //     });
        //     //console.log('job1',job1);
        let sections = {};
        props.handelEvent.content.map((item) => {
            switch (item.id) {
                case 132:
                    sections.join = item;
                    break;
                case 133:
                    sections.learn = item;
                    break;
                case 134:
                    sections.care = item;
                    break;
                case 135:
                    sections.share = item;
                    break;
                case 413:
                    sections.allen = item;
                    break;
                case 414:
                    sections.flexible = item;
                    break;
                case 415:
                    sections.sociable = item;
                    break;
                case 416:
                    sections.modern = item;
                    break;
            }
        });
        this.setState({ PageContent: sections });
        // //console.log(this.state.PageContent?.join)
    }
    render() {
        return (
            <>
                <div className="container company  mt-5 mb-5">
                    <div className="d-flex flex-row flex-wrap">
                        <div className="col-12 col-lg-6 rectangle">
                            <h3>{this.state.PageContent?.join?.title}</h3>
                            <p className="jobs-color-correction">
                                {this.state.PageContent?.join?.description}
                            </p>
                        </div>
                        <div className="col-12 col-lg-6 p-0 d-flex justify-content-center">
                            <img
                                src={
                                    this.state.PageContent?.join?.image
                                        ? environment.baseUrlImg +
                                        this.state.PageContent?.join?.image
                                        : JobImg1
                                }
                                alt=""
                                className="img-fluid"
                                style={{ maxWidth: "100%", height: "auto" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="why-allen-digital">
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-12 col-md-4 ">
                                <div className="card  p-0">
                                    <img
                                        src={
                                            this.state.PageContent?.join?.image
                                                ? environment.baseUrlImg +
                                                this.state.PageContent?.learn?.image
                                                : JobImgCard1
                                        }
                                        alt=""
                                        className="img-fluid"
                                    />
                                    <div className="card-content p-3 card-digital-content">
                                        <h5>{this.state.PageContent?.learn?.title}</h5>
                                        <p>{this.state.PageContent?.learn?.description}</p>
                                    </div>
                                </div>
                                <br></br>
                            </div>
                            <div className="col-12 col-md-4  ">
                                <div className="card  p-0">
                                    <img
                                        src={
                                            this.state.PageContent?.care?.image
                                                ? environment.baseUrlImg +
                                                this.state.PageContent?.care?.image
                                                : JobImgCard2
                                        }
                                        alt=""
                                        className="img-fluid"
                                    />
                                    <div className="card-content p-3">
                                        <h5>{this.state.PageContent?.care?.title}</h5>
                                        <p>{this.state.PageContent?.care?.description}</p>
                                    </div>
                                </div>
                                <br></br>
                            </div>
                            <div className="col-12 col-md-4  ">
                                <div className="card  p-0">
                                    <img
                                        src={
                                            this.state.PageContent?.share?.image
                                                ? environment.baseUrlImg +
                                                this.state.PageContent?.share?.image
                                                : JobImgCard3
                                        }
                                        alt=""
                                        className="img-fluid"
                                    />
                                    <div className="card-content p-3">
                                        <h5>{this.state.PageContent?.share?.title}</h5>
                                        <p>{this.state.PageContent?.share?.description}</p>
                                    </div>
                                </div>
                                <br></br>
                            </div>
                        </div>

                        <div className="mt-5 text-center container digital-content  ">
                            <h3>{this.state.PageContent?.allen?.title}</h3>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: this.state.PageContent?.allen?.description,
                                }}
                            ></p>
                        </div>
                    </div>
                </div>
                <div className="container love-your-work ">
                    <div className="col-12 ">
                        <h1> Love Your Work</h1>
                    </div>
                    <div className="row">
                        <div className="d-flex flex-row flex-wrap justify-content-center  ">
                            <div className="col-12 col-md-4 p-3">
                                <img
                                    src={
                                        this.state.PageContent?.flexible?.image
                                            ? environment.baseUrlImg +
                                            this.state.PageContent?.flexible?.image
                                            : JobImgWork1
                                    }
                                    alt=""
                                    className="img-fluid"
                                />
                                <h5 className="mt-4">
                                    {this.state.PageContent?.flexible?.title}
                                </h5>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.PageContent?.flexible?.description,
                                    }}
                                ></div>
                            </div>
                            <div className="col-12 col-md-4 p-3">
                                <img
                                    src={
                                        this.state.PageContent?.sociable?.image
                                            ? environment.baseUrlImg +
                                            this.state.PageContent?.sociable?.image
                                            : JobImgWork2
                                    }
                                    alt=""
                                    className="img-fluid"
                                />
                                <h5 className="mt-4">
                                    {this.state.PageContent?.sociable?.title}
                                </h5>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.PageContent?.sociable?.description,
                                    }}
                                ></div>
                            </div>
                            <div className="col-12 col-md-4 p-3">
                                <img
                                    src={
                                        this.state.PageContent?.modern?.image
                                            ? environment.baseUrlImg +
                                            this.state.PageContent?.modern?.image
                                            : JobImgWork3
                                    }
                                    alt=""
                                    className="img-fluid"
                                />
                                <h5 className="mt-4">
                                    {this.state.PageContent?.modern?.title}
                                </h5>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.PageContent?.modern?.description,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="text-center mt-3">
            <button type="button" className="btn btn-primary nav-btn">View Current Openings</button>
        </div>  */}
                </div>
            </>
        );
    }
}

export default JobsContent;
