import React from "react";
import { Helmet } from "react-helmet";
// import { environment } from "../Services/HttpData";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);
const PreviousYearPapers = () => {
  return (
    <>
      <Helmet>
        {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
        {
          <link
            rel="canonical"
            href={window.location.origin + window.location.pathname}
          />
        }
        <title>
          Previous Year Question Papers for JEE & NEET | ALLEN Digital
        </title>
        <meta
          name="description"
          content="Complement your JEE and NEET preparation with free previous years' question papers. Download the last 5 year’s question papers for every subject Paper-wise."
        />
        <meta
          property="og:title"
          content="Previous Year Question Papers for JEE & NEET | ALLEN Digital"
        />
        <meta property="og:site_name" content="ALLEN Digital" />
        <meta
          property="og:url"
          content="https://digital.allen.ac.in/previous-year-papers"
        />
        <meta
          property="og:description"
          content="Complement your JEE and NEET preparation with free previous years' question papers. Download the last 5 year’s question papers for every subject Paper-wise."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://digital.allen.ac.in/static/media/logo-new-r.4a9d1fde3fd2a6868a621b27352bda3a.svg"
        />
      </Helmet>
      <div className="container-fluid">
        <div className="banner-3" style={{ paddingTop: "150px" }}>
          <div className="container banner-content">
            <h4>Previous Year Question Papers</h4>
          </div>
        </div>
      </div>
      <div className="container mt-5 privacy-policy">
        <p>
          Click here to download JEE Main, JEE Advanced, and NEET-UG previous
          years' question papers with answer keys and solutions. Solving these
          questions papers will help you enhance your problem-solving skills,
          develop real-time exam temperament, and discover areas of improvement.
        </p>
      </div>
      <div>
        {/* <div className="text-center mt-5">
          <a
            target="_blank"
            href="/free-resource/pyp-jee-main-2022"
            className="btn btn-success mb-2"
          >
            &nbsp; &nbsp; Jee Main Papers 2022 &nbsp; &nbsp;{" "}
          </a>
        </div> */}
        <div className="text-center mt-1 p-3">
          <a
            target="_blank"
            href="/free-resource/neet-ug-manipur-papers-2023"
            className="btn btn-success mb-2"
          >
            NEET (UG) Manipur Paper 2023{" "}
            <img
              src="https://www.allen.ac.in/images/new_icon_blink.gif"
              alt="neet paper analysis"
              style={{ width: "22px", height: "9px", float: "none" }}
              className="mCS_img_loaded"
            />
          </a>{" "}
          &nbsp; &nbsp;
          <a
            target="_blank"
            href="/free-resource/pyp-jee-main-april-2023"
            className="btn btn-success mb-2"
          >
            JEE Main April 2023{" "}
            <img
              src="https://www.allen.ac.in/images/new_icon_blink.gif"
              alt="neet paper analysis"
              style={{ width: "22px", height: "9px", float: "none" }}
              className="mCS_img_loaded"
            />
          </a>{" "}
          &nbsp; &nbsp;
          <a
            target="_blank"
            href="/free-resource/pyp-jee-main-january-2024"
            className="btn btn-success mb-2"
          >
            JEE Main January 2024{" "}
            <img
              src="https://www.allen.ac.in/images/new_icon_blink.gif"
              alt="neet paper analysis"
              style={{ width: "22px", height: "9px", float: "none" }}
              className="mCS_img_loaded"
            />
          </a>{" "}
          &nbsp; &nbsp;
          <a
            target="_blank"
            href="/free-resource/pyp-jee-main-2023"
            className="btn btn-success mb-2"
          >
            JEE Main January 2023
          </a>{" "}
          &nbsp; &nbsp;
          <a
            target="_blank"
            href="/free-resource/pyp-jee-advanced-2022"
            className="btn btn-success mb-2"
          >
            JEE Advanced 2022{" "}
          </a>{" "}
          &nbsp; &nbsp;
          <a
            target="_blank"
            href="/free-resource/neet-papers-2023"
            className="btn btn-success mb-2"
          >
            NEET (UG) Paper 2023{" "}
          </a>{" "}
          &nbsp; &nbsp;
          <a
            target="_blank"
            href="/free-resource/pyp-jee-main-2022"
            className="btn btn-success mb-2"
          >
            JEE Main 2022{" "}
          </a>{" "}
          &nbsp; &nbsp;
          <a
            target="_blank"
            href="/free-resource/pyp-neet"
            className="btn btn-success mb-2"
          >
            NEET Previous Year Paper{" "}
          </a>{" "}
          &nbsp; &nbsp;
          <a
            target="_blank"
            href="/free-resource/pyp-jee-main"
            className="btn btn-success mb-2"
          >
            JEE Main Previous Year Paper
          </a>
          &nbsp; &nbsp;
          <a
            target="_blank"
            href="/free-resource/pyp-jee-advanced"
            className="btn btn-success mb-2"
          >
            JEE Advanced Previous Year Paper
          </a>
        </div>
      </div>
    </>
  );
};

export default PreviousYearPapers;
