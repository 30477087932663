import React from 'react'
class ViewProfile extends React.Component {
    render() { 
        return <>

<div className="container-fluid bg-banner">

</div>
<div className="container mt-5">
        <div className="row">
            <div className="col-12 col-md-4">
                <div className="card course-card p-0 mb-4" style={{marginTop: "-100px"}}>
                     <div className="img-holder col-6 mx-auto">
                      <img className="profile-image" src="https://demo.nparoco.com/Vuexy/app-assets/images/profile/user-uploads/user-13.jpg" alt="" width="100%" />
                     </div> 
                     
                     <div className="card-body p-2">
                        <div className="text-center">
                            <h6 style={{fontWeight: "900"}}>Shikha Rajput</h6>
                            <p className="text-muted mb-1">Registration No. 1023542233</p>
                        </div>
                        <div className="col  mt-3">
                            <h6 className="text-green my-3" style={{fontWeight: "900"}}>Student Details</h6>
                            <div className="px-0">
                              <div className="row m-0 justify-content-start">
                                  <div className="col-4 col-lg-5 px-0">
                                      <p className="text-muted mb-1">Mobile No.</p>
                                      <p className="text-muted mb-1">Email</p>
                                      <p className="text-muted mb-1">DOB</p>
                                      <p className="text-muted mb-1">Class</p>
                                  </div>
                                  <div className="col-4 col-lg-2 px-0">
                                      <p className="text-muted mb-1">:</p>
                                      <p className="text-muted mb-1">:</p>
                                      <p className="text-muted mb-1">:</p>
                                      <p className="text-muted mb-1">:</p>
                                  </div>
                                  <div className="col-4 col-lg-5 px-0">
                                      <p className="mb-1 ">+91-123456789</p>
                                      <p className="mb-1 ">dummy@mail.com</p>
                                      <p className="mb-1 ">24 Nov 1991</p>
                                      <p className="mb-1 ">XII</p>
                                  </div>  
                              </div>   
                            </div>
                        </div>

                        <div className="col mt-3">
                            <h6 className="text-green my-3" style={{fontWeight: "900"}}>Targeted Exam</h6>
                            <div className="px-0">
                              <div className="row m-0 justify-content-start">
                                  <div className="col-4 col-lg-6 px-0">
                                      <p className="text-muted mb-1">JEE Mains JAN 22</p>
                                      <p className="text-muted mb-1">JEE Mains MAR 22</p>
                                  </div>
                                  <div className="col-4 col-lg-1 px-0">
                                      <p className="text-muted mb-1">:</p>
                                      <p className="text-muted mb-1">:</p>
                                  </div>
                                  <div className="col-4 col-lg-5 px-0">
                                      <p className="mb-1 ">125489CFHCG678</p>
                                      <p className="mb-1 ">125489CFHCG678</p>
                                  </div>  
                              </div>   
                            </div>
                        </div>
                     </div>
                     <div className="card-footer">
                        <ul className="nav justify-content-between">
                            <p className="mb-1" style={{fontSize: "13px"}}>5 Courses Enrolled</p>
                            <p className="mb-1" style={{fontSize: "13px"}}>5 Courses Completed</p>
                        </ul>
                     </div>
                </div>
            </div>

            <div className="col-12 col-md-8">
                <div className="row mx-0 align-items-center border-bottom">
                    <ul className="nav" id="myProfileTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <a className="nav-link active font-weight-bold" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">My Profile</a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a className="nav-link font-weight-bold" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">My Watchlist</a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a className="nav-link font-weight-bold" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">My Order Details</a>
                        </li>
                    </ul>
                    <ul className="nav ml-auto">
                        <a href="/edit-profile" className="btn btn-outline-green mr-3 mb-2">Edit</a>
                        <button type="button" className="btn btn-outline-green mb-2">Settings</button>
                    </ul>
                </div>
                <div className="tab-content" id="myTabContent">
                      {/*   <!-- First Tab  --> */}
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="accordion" id="accordionExample">
                            <div className="col mt-3">
                              <h6 className="text-green my-3" style={{fontWeight: "900"}}>Personal Info</h6>
                              <div className="px-0">
                                <div className="row m-0 justify-content-start">
                                    <div className="col-4 px-0">
                                        <p className="text-muted mb-1">Father's Name</p>
                                        <p className="text-muted mb-1">Mother's Name</p>
                                        <p className="text-muted mb-1">Category</p>
                                        <p className="text-muted mb-1">Gender</p>
                                        <p className="text-muted mb-1">Sibling</p>
                                    </div>
                                    <div className="col-2 px-0">
                                        <p className="text-muted mb-1">:</p>
                                        <p className="text-muted mb-1">:</p>
                                        <p className="text-muted mb-1">:</p>
                                        <p className="text-muted mb-1">:</p>
                                        <p className="text-muted mb-1">:</p>
                                    </div>
                                    <div className="col-4 px-0">
                                        <p className="mb-1 ">Chandan Sharma</p>
                                        <p className="mb-1 ">Shubhi Sharma</p>
                                        <p className="mb-1 ">General</p>
                                        <p className="mb-1 ">Male</p>
                                        <p className="mb-1 ">Ramesh</p>
                                    </div>  
                                </div>   
                              </div>
                            </div>
                            
                            <div className="col mt-3">
                                <h6 className="text-green my-3" style={{fontWeight: "900"}}>Academic Info</h6>
                                <div className="px-0">
                                  <div className="row m-0 justify-content-start">
                                      <div className="col-4 px-0">
                                          <p className="text-muted mb-1">Education Board</p>
                                          <p className="text-muted mb-1">Education Medium</p>
                                          <p className="text-muted mb-1">NTSE Scholar</p>
                                          <p className="text-muted mb-1">Year of Classification</p>
                                          <p className="text-muted mb-1">NTSE Roll Number</p>
                                          <p className="text-muted mb-1">Institute Name</p>
                                      </div>
                                      <div className="col-2 px-0">
                                          <p className="text-muted mb-1">:</p>
                                          <p className="text-muted mb-1">:</p>
                                          <p className="text-muted mb-1">:</p>
                                          <p className="text-muted mb-1">:</p>
                                          <p className="text-muted mb-1">:</p>
                                          <p className="text-muted mb-1">:</p>
                                      </div>
                                      <div className="col-4 px-0">
                                          <p className="mb-1 ">Rajasthan</p>
                                          <p className="mb-1 ">English</p>
                                          <p className="mb-1 ">Yes</p>
                                          <p className="mb-1 ">2008</p>
                                          <p className="mb-1 ">123456789</p>
                                          <p className="mb-1 ">Sai Public School</p>
                                      </div>  
                                  </div>   
                                </div>
                            </div>
                            
                            <div className="col mt-3">
                                <h6 className="text-green my-3" style={{fontWeight: "900"}}>Communication Info</h6>
                                <div className="px-0">
                                  <div className="row m-0 justify-content-start">
                                      <div className="col-4 px-0">
                                          <p className="text-muted mb-1">Alternate Mobile No.</p>
                                          <p className="text-muted mb-1">Preferred Language</p>
                                          <p className="text-muted mb-1">Address</p>
                                          <p className="text-muted mb-1">Pin</p>
                                          <p className="text-muted mb-1">District</p>
                                          <p className="text-muted mb-1">City</p>
                                          <p className="text-muted mb-1">State/Province/Region</p>
                                          <p className="text-muted mb-1">Country</p>
                                      </div>
                                      <div className="col-2 px-0">
                                          <p className="text-muted mb-1">:</p>
                                          <p className="text-muted mb-1">:</p>
                                          <p className="text-muted mb-1">:</p>
                                          <p className="text-muted mb-1">:</p>
                                          <p className="text-muted mb-1">:</p>
                                          <p className="text-muted mb-1">:</p>
                                          <p className="text-muted mb-1">:</p>
                                          <p className="text-muted mb-1">:</p>
                                      </div>
                                      <div className="col-4 px-0">
                                          <p className="mb-1 ">+91-123456789</p>
                                          <p className="mb-1 ">English</p>
                                          <p className="mb-1 ">MN3, Near Mahima Medical</p>
                                          <p className="mb-1 ">325204</p>
                                          <p className="mb-1 ">Kota</p>
                                          <p className="mb-1 ">Kota</p>
                                          <p className="mb-1 ">Rajasthan</p>
                                          <p className="mb-1 ">India</p>
                                      </div>  
                                  </div>   
                                </div>
                            </div>
                        </div>
                    </div>
              {/*       <!-- Second Tab --> */}
                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div className="accordion" id="accordionExample">
                            <div className="row mx-0 mt-4">
                                <div className="card col-md col-12 p-0 course-card">
                                    <div className="">
                                        <img src="http://blog.deekshaclasses.com/wp-content/uploads/2019/11/Capsule-Course-scaled.jpg" alt="" width="100%"/>
                                        <a href="#"><img src="images/bookmark-btn.png" alt="" width="25px" className="bookmark-btn" /></a>
                                    </div>
                                    <div className="card-content p-2">
                                        <p>JEE Main 2021 (February Attempt) Paper Analysis (Physics, Chemistry,</p>
                                         
                                        <div className="d-flex justify-content-start">
                                            <div>
                                                <h5>&#8377; 455</h5>
                                            </div>
                                            <div className="ml-2">
                                                <h5 className="text-danger"><s>&#8377; 1455</s></h5>
                                            </div>
                                        </div>
                                        <p className="my-0" style={{color: "#046635"}}>Save Rs. 300 (20% OFF)</p>
                                        <div className="row mx-0 mt-2 justify-content-between">
                                            <p className="mr-1">Live Class</p>
                                            <p className="mr-1">Test Series</p>
                                            <p className="mr-1">Online Test</p>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <a className="nav-link p-0 mb-2" href="#"><button type="button"
                                                    className="btn btn-outline-primary nav-btn-outline"
                                                    style={{maxWidth:" 150px",fontSize:"13px"}}>Buy Now</button></a>
                                            <a className="nav-link p-0 mb-2" href="#"><button type="button"
                                                    className="btn btn-primary nav-btn" style={{maxWidth:" 150px",fontSize:"13px"}}>Add
                                                    to Bag</button></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="card col-md col-12 p-0 course-card">
                                    <div className="">
                                        <img src="http://blog.deekshaclasses.com/wp-content/uploads/2019/11/Capsule-Course-scaled.jpg" alt="" width="100%" />
                                        <a href="#"><img src="images/bookmark-btn.png" alt="" width="25px" className="bookmark-btn" /></a>
                                    </div>
                                    <div className="card-content p-2">
                                        <p>JEE Main 2021 (February Attempt) Paper Analysis (Physics, Chemistry,</p>
                                         
                                        <div className="d-flex justify-content-start">
                                            <div>
                                                <h5>&#8377; 455</h5>
                                            </div>
                                            <div className="ml-2">
                                                <h5 className="text-danger"><s>&#8377; 1455</s></h5>
                                            </div>
                                        </div>
                                        <p className="my-0" style={{color: "#046635"}}>Save Rs. 300 (20% OFF)</p>
                                        <div className="row mx-0 mt-2 justify-content-between">
                                            <p className="mr-1">Live Class</p>
                                            <p className="mr-1">Test Series</p>
                                            <p className="mr-1">Online Test</p>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <a className="nav-link p-0 mb-2" href="#"><button type="button"
                                                    className="btn btn-outline-primary nav-btn-outline"
                                                    style={{maxWidth:" 150px",fontSize:"13px"}}>Buy Now</button></a>
                                            <a className="nav-link p-0 mb-2" href="#"><button type="button"
                                                    className="btn btn-primary nav-btn" style={{maxWidth:" 150px",fontSize:"13px"}}>Add
                                                    to Bag</button></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="card col-md col-12 p-0 course-card">
                                    <div className="">
                                        <img src="http://blog.deekshaclasses.com/wp-content/uploads/2019/11/Capsule-Course-scaled.jpg" alt="" width="100%" />
                                        <a href="#"><img src="images/bookmark-btn.png" alt="" width="25px" className="bookmark-btn"/></a>
                                    </div>
                                    <div className="card-content p-2">
                                        <p>JEE Main 2021 (February Attempt) Paper Analysis (Physics, Chemistry,</p>
                                         
                                        <div className="d-flex justify-content-start">
                                            <div>
                                                <h5>&#8377; 455</h5>
                                            </div>
                                            <div className="ml-2">
                                                <h5 className="text-danger"><s>&#8377; 1455</s></h5>
                                            </div>
                                        </div>
                                        <p className="my-0" style={{color: "#046635"}}>Save Rs. 300 (20% OFF)</p>
                                        <div className="row mx-0 mt-2 justify-content-between">
                                            <p className="mr-1">Live Class</p>
                                            <p className="mr-1">Test Series</p>
                                            <p className="mr-1">Online Test</p>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <a className="nav-link p-0 mb-2" href="#"><button type="button"
                                                    className="btn btn-outline-primary nav-btn-outline"
                                                    style={{maxWidth:" 150px",fontSize:"13px"}}>Buy Now</button></a>
                                            <a className="nav-link p-0 mb-2" href="#"><button type="button"
                                                    className="btn btn-primary nav-btn" style={{maxWidth:" 150px",fontSize:"13px"}}>Add
                                                    to Bag</button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row mx-0 mb-3">
                                <div className="card col-md col-12 p-0 course-card">
                                    <div className="">
                                        <img src="http://blog.deekshaclasses.com/wp-content/uploads/2019/11/Capsule-Course-scaled.jpg" alt="" width="100%"/>
                                        <a href="#"><img src="images/bookmark-btn.png" alt="" width="25px" className="bookmark-btn"/></a>
                                    </div>
                                    <div className="card-content p-2">
                                        <p>JEE Main 2021 (February Attempt) Paper Analysis (Physics, Chemistry,</p>
                                         
                                        <div className="d-flex justify-content-start">
                                            <div>
                                                <h5>&#8377; 455</h5>
                                            </div>
                                            <div className="ml-2">
                                                <h5 className="text-danger"><s>&#8377; 1455</s></h5 >
                                            </div>
                                        </div>
                                        <p className="my-0" style={{color: "#046635"}}>Save Rs. 300 (20% OFF)</p>
                                        <div className="row mx-0 mt-2 justify-content-between">
                                            <p className="mr-1">Live Class</p>
                                            <p className="mr-1">Test Series</p>
                                            <p className="mr-1">Online Test</p>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <a className="nav-link p-0 mb-2" href="#"><button type="button"
                                                    className="btn btn-outline-primary nav-btn-outline"
                                                    style={{maxWidth:" 150px",fontSize:"13px"}}>Buy Now</button></a>
                                            <a className="nav-link p-0 mb-2" href="#"><button type="button"
                                                    className="btn btn-primary nav-btn" style={{maxWidth:" 150px",fontSize:"13px"}}>Add
                                                    to Bag</button></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="card col-md col-12 p-0 course-card">
                                    <div className="">
                                        <img src="http://blog.deekshaclasses.com/wp-content/uploads/2019/11/Capsule-Course-scaled.jpg" alt="" width="100%"/>
                                        <a href="#"><img src="images/bookmark-btn.png" alt="" width="25px" className="bookmark-btn"/></a>
                                    </div>
                                    <div className="card-content p-2">
                                        <p>JEE Main 2021 (February Attempt) Paper Analysis (Physics, Chemistry,</p>
                                         
                                        <div className="d-flex justify-content-start">
                                            <div>
                                                <h5>&#8377; 455</h5>
                                            </div>
                                            <div className="ml-2">
                                                <h5 className="text-danger"><s>&#8377; 1455</s></h5>
                                            </div>
                                        </div>
                                        <p className="my-0" style={{color: "#046635"}}>Save Rs. 300 (20% OFF)</p>
                                        <div className="row mx-0 mt-2 justify-content-between">
                                            <p className="mr-1">Live Class</p>
                                            <p className="mr-1">Test Series</p>
                                            <p className="mr-1">Online Test</p>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <a className="nav-link p-0 mb-2" href="#"><button type="button"
                                                    className="btn btn-outline-primary nav-btn-outline"
                                                    style={{maxWidth:" 150px",fontSize:"13px"}}>Buy Now</button></a>
                                            <a className="nav-link p-0 mb-2" href="#"><button type="button"
                                                    className="btn btn-primary nav-btn" style={{maxWidth:" 150px",fontSize:"13px"}}>Add
                                                    to Bag</button></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="card col-md col-12 p-0 course-card">
                                    <div className="">
                                        <img src="http://blog.deekshaclasses.com/wp-content/uploads/2019/11/Capsule-Course-scaled.jpg" alt="" width="100%" />
                                        <a href="#"><img src="images/bookmark-btn.png" alt="" width="25px" className="bookmark-btn"/></a>
                                    </div>
                                    <div className="card-content p-2">
                                        <p>JEE Main 2021 (February Attempt) Paper Analysis (Physics, Chemistry,</p>
                                         
                                        <div className="d-flex justify-content-start">
                                            <div>
                                                <h5>&#8377; 455</h5>
                                            </div>
                                            <div className="ml-2">
                                                <h5 className="text-danger"><s>&#8377; 1455</s></h5>
                                            </div>
                                        </div>
                                        <p className="my-0" style={{color: "#046635"}}>Save Rs. 300 (20% OFF)</p>
                                        <div className="row mx-0 mt-2 justify-content-between">
                                            <p className="mr-1">Live Class</p>
                                            <p className="mr-1">Test Series</p>
                                            <p className="mr-1">Online Test</p>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <a className="nav-link p-0 mb-2" href="#"><button type="button"
                                                    className="btn btn-outline-primary nav-btn-outline"
                                                    style={{maxWidth:" 150px",fontSize:"13px"}}>Buy Now</button></a>
                                            <a className="nav-link p-0 mb-2" href="#"><button type="button"
                                                    className="btn btn-primary nav-btn" style={{maxWidth:" 150px",fontSize:"13px"}}>Add
                                                    to Bag</button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Third Tab --> */}
                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <div className="accordion" id="accordionExample">
                            <div>
                                <div className="card p-3 my-3 course-card">
                                    <div className="row m-0">
                                        <div className="col-12 col-lg-4 p-0">
                                            <img src="http://blog.deekshaclasses.com/wp-content/uploads/2019/11/Capsule-Course-scaled.jpg" alt="" width="100%"/>
                                            <a href="#"><img src="images/bookmark-btn.png" alt="" width="25px" className="bookmark-btn"/></a>
                                        </div>
                                        <div className="card-content col-12 col-lg-8">
                                            <div className="d-flex flex-row flex-wrap align-items-center mb-2">
                                                <div>
                                                    <p className="mb-0">JEE Main 2021 (February Attempt) Paper Analysis</p>
                                                </div>
                                                
                                                <div className="ml-auto" >
                                                    <button type="button" className="btn btn-outline-green-profile ">Invoice</button>
                                                </div>
                                            </div>
                                            <div className="row m-0 justify-content-start">
                                                <div className=" mr-3">
                                                    <p className="text-small mb-1 mr-1">Purchased on : 20 Jun 2021</p>
                                                </div>
                                                
                                                <div className="">
                                                    <p className="text-small mb-1">Valit till : 20 Jul 2021</p>
                                                </div>
                                            </div>
                                            <div className="row m-0 justify-content-between">
                                                <div className="">
                                                    <p className="text-small mb-1 mr-2">Class : XII</p>
                                                </div>
                                                
                                                <div className="">
                                                    <p className="text-small mb-1 mr-2">Target Exam : IIT JEE</p>
                                                </div>
                                                
                                                <div className="">
                                                    <p className="text-small mb-1 mr-2">Duration : 20 Mins</p>
                                                </div>

                                                <div className="">
                                                    <p className="text-small mb-1">Status : Enrolled</p>
                                                </div>
                                            </div>
                                            <div className="row mx-0  mt-2 justify-content-end align-items-center">
                                                <div className="row mx-0">
                                                    <button type="button" className="btn btn-outline-green-profile mr-3 mb-1">Continue Reading</button>
                                                    <button type="button" className="btn btn-green-profile mb-1">Rate this Course</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="card p-3 mb-3 course-card">
                                    <div className="row m-0">
                                        <div className="col-12 col-lg-4 p-0">
                                            <img src="http://blog.deekshaclasses.com/wp-content/uploads/2019/11/Capsule-Course-scaled.jpg" alt="" width="100%"/>
                                            <a href="#"><img src="images/bookmark-btn.png" alt="" width="25px" className="bookmark-btn"/></a>
                                        </div>
                                        <div className="card-content col-12 col-lg-8">
                                            <div className="d-flex flex-row flex-wrap align-items-center mb-2">
                                                <div>
                                                    <p className="mb-0">JEE Main 2021 (February Attempt) Paper Analysis</p>
                                                </div>
                                                
                                                <div className="ml-auto" >
                                                    <button type="button" className="btn btn-outline-green-profile ">Invoice</button>
                                                </div>
                                            </div>
                                            <div className="row m-0 justify-content-start">
                                                <div className=" mr-3">
                                                    <p className="text-small mb-1 mr-1">Purchased on : 20 Jun 2021</p>
                                                </div>
                                                
                                                <div className="">
                                                    <p className="text-small mb-1">Valit till : 20 Jul 2021</p>
                                                </div>
                                            </div>
                                            <div className="row m-0 justify-content-between">
                                                <div className="">
                                                    <p className="text-small mb-1 mr-2">Class : XII</p>
                                                </div>
                                                
                                                <div className="">
                                                    <p className="text-small mb-1 mr-2">Target Exam : IIT JEE</p>
                                                </div>
                                                
                                                <div className="">
                                                    <p className="text-small mb-1 mr-2">Duration : 20 Mins</p>
                                                </div>

                                                <div className="">
                                                    <p className="text-small mb-1">Status : Enrolled</p>
                                                </div>
                                            </div>
                                            <div className="row mx-0  mt-2 justify-content-end align-items-center">
                                                <div className="row mx-0">
                                                    <button type="button" className="btn btn-outline-green-profile mr-3 mb-1">Continue Reading</button>
                                                    <button type="button" className="btn btn-green-profile mb-1">Rate this Course</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card p-3 mb-3 course-card">
                                    <div className="row m-0">
                                        <div className="col-12 col-lg-4 p-0">
                                            <img src="http://blog.deekshaclasses.com/wp-content/uploads/2019/11/Capsule-Course-scaled.jpg" alt="" width="100%"/>
                                            <a href="#"><img src="images/bookmark-btn.png" alt="" width="25px" className="bookmark-btn"/></a>
                                        </div>
                                        <div className="card-content col-12 col-lg-8">
                                            <div className="d-flex flex-row flex-wrap align-items-center mb-2">
                                                <div>
                                                    <p className="mb-0">JEE Main 2021 (February Attempt) Paper Analysis</p>
                                                </div>
                                                
                                                <div className="ml-auto" >
                                                    <button type="button" className="btn btn-outline-green-profile ">Invoice</button>
                                                </div>
                                            </div>
                                            <div className="row m-0 justify-content-start">
                                                <div className=" mr-3">
                                                    <p className="text-small mb-1 mr-1">Purchased on : 20 Jun 2021</p>
                                                </div>
                                                
                                                <div className="">
                                                    <p className="text-small mb-1">Valit till : 20 Jul 2021</p>
                                                </div>
                                            </div>
                                            <div className="row m-0 justify-content-between">
                                                <div className="">
                                                    <p className="text-small mb-1 mr-2">Class : XII</p>
                                                </div>
                                                
                                                <div className="">
                                                    <p className="text-small mb-1 mr-2">Target Exam : IIT JEE</p>
                                                </div>
                                                
                                                <div className="">
                                                    <p className="text-small mb-1 mr-2">Duration : 20 Mins</p>
                                                </div>

                                                <div className="">
                                                    <p className="text-small mb-1">Status : Enrolled</p>
                                                </div>
                                            </div>
                                            <div className="row mx-0  mt-2 justify-content-end align-items-center">
                                                <div className="row mx-0">
                                                    <button type="button" className="btn btn-outline-green-profile mr-3 mb-1">Continue Reading</button>
                                                    <button type="button" className="btn btn-green-profile mb-1">Rate this Course</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
        
        
        
        </>;
    }
}
 
export default ViewProfile;