import React, { useEffect, useState } from "react";
// import { environment } from "../../Services/HttpData";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import "../../css/ecom/ecomStyle.css";
import { useHistory } from "react-router";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);

const EcomSuccess = (props) => {
  const history = useHistory()
  // console.log(history?.location?.state?.student_email)
  const wid_data = window.localStorage.getItem("IS_DASHBOARD_ICON")
    ? window.localStorage.getItem("IS_DASHBOARD_ICON")
    : false;
  const { orderId } = useParams();
  const [orderStatus, setOrderStatus] = useState([]);
  const [success, setSuccess] = useState("none");
  const [failed, setFailed] = useState("none");
  const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "";
  const [loading, setLoading] = useState(false);
  const [apirespons, setapirespons] = useState({});
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Basic " + auth_token,
      },
    };
    fetch(environment.ecomApiUrl + "order/status/" + orderId, requestOptions).then(resp => {
      // fetching api url for courses listing
      resp.json().then(result => {
        setOrderStatus(result.data.order_status); // set result in state this will set current/updated value of setProduct to product
        //console.log("orderstatus "+result.data.order_status);
        setLoading(true);
        if (result.data.order_status === "complete") {
          setSuccess("block");
          window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
          window.dataLayer.push({
            event: "purchase",
            ecommerce: {
              transaction_id: result.data?.order_id,
              value: result.data?.order_amount,
              currency: "INR",
              coupon: result?.data?.coupon_title,
              items: [
                {
                  item_id: result?.data?.products?.sku_id,
                  item_name: result?.data?.products?.title,
                  coupon: result?.data?.coupon_title,
                  discount: result?.data?.coupon_discount,
                  item_brand: "Allen",
                  item_category: result?.data?.course_category_name,
                  price: result?.data?.products?.net_amount,
                  quantity: 1
                },
              ],
            },
            enhanced_conversion_data: { email: history?.location?.state?.student_email }

          });
        } else {
          setFailed("block");
        }
        if (result.data.order_status === "pending") {
          setapirespons(result.data);
        }
      });
    });
  }, []);
  if (!wid_data) {
    window.location.href = "/";
  }
  return (
    <>
      <div className='container pr-0 mt-5'>
        <div className='row'>
          <div className='col-md-12'>
            {orderStatus === "complete" ? (
              <>
                <div className='success-cotent-top text-center' style={{ display: success }}>
                  <i className='fa-solid fa-circle-check'></i>
                  <h4> Your order has been placed successfully !!</h4>
                  {/*  <span className="order-id mt-5"> order Id : {orderId} </span><br/> */}
                  <span className='order-id mt-5'> You will receive an email confirmation shortly </span>
                  <br />

                  <div className='success-bottom-btn mt-4'>
                    <Link className='btn my-font order-history-btn btn btn-success' to='/profile'>
                      Order History
                    </Link>
                    {/*  <button className="btn my-font order-history-btn2">Start Learning</button> */}
                  </div>
                </div>
              </>
            ) : orderStatus === "pending" ? (
              <div className='col-12'>
                <div className='order-success-wrap jee-scholarship text-center'>
                  <div className='title color-green'>
                    Your order has been created successfully for {apirespons?.course_name}!
                  </div>
                  <div className='mt-2'>
                    <b>Kindly make fee payment on following bank account details.</b>
                  </div>
                  <div className='table-responsive mt-4'>
                    <table className='table table-bordered jee-scholarship-table white-space-normal table-scroll'>
                      <thead>
                        <tr className='border-top'>
                          <th>Beneficiary Name</th>
                          <th>Bank Name</th>
                          <th>Virtual Bank Account Number</th>
                          <th>IFSC Code</th>
                          <th>Branch Name</th>
                          <th>Payable Fees (₹)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{apirespons?.beneficiary_name}</td>
                          <td>{apirespons?.bank_name}</td>
                          <td>{apirespons?.account_no}</td>
                          <td>{apirespons?.ifsc_code}</td>
                          <td>{apirespons?.branch_name}</td>
                          <td>{apirespons?.order_amount}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='mt-2'>
                    <b> Your course will be activated within 24 to 48 hours of the payment realization.</b>
                  </div>
                  <div className='mt-2'>
                    Kindly, pay the course fee within <b>5 days</b> otherwise your order will be cancelled.
                  </div>
                  <div className='success-bottom-btn mt-4'>
                    <Link className='btn my-font order-history-btn btn btn-success' to='/profile'>
                      Order History
                    </Link>
                    {/*  <button className="btn my-font order-history-btn2">Start Learning</button> */}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className='failed-cotent-top text-center' style={{ display: failed }}>
                  <i className='fa-solid fa-circle-xmark'></i>
                  <h4> Your order has been failed !!</h4>
                  {/* <span className="order-id mt-5"> order Id : {orderId} </span><br/> */}

                  <div className='success-bottom-btn mt-4'>
                    <Link className='btn my-font order-history-btn btn btn-success' to='/profile'>
                      Order History
                    </Link>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Successful Message */}

          {/* Successful Message End */}
        </div>
      </div>
    </>
  );
};

export default EcomSuccess;
