import React from 'react';
import videoTestimonials from '../../images/about-banner.jpg';
import playbutton from '../../images/play-button-inside-a-circle.png';
import testimonialreview from '../../images/Ellipse 247.png';
// import { environment } from '../../Services/HttpData';
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class VIdeoTestimonials extends React.Component {
    constructor(props) {
        super(props);
        //   //console.log(this.props);
        this.state = {
            PageContent: [],
        };
    }
    componentWillReceiveProps(props) {
        this.setState({ PageContent: props.handelEvent });
    }
    render() {
        return <>
            <div className="video-testimonials">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 ">
                            <h1>Video Testimonials</h1>
                        </div>
                    </div>
                    <div className="row testimonials-clean">{this.state.PageContent.map((item) => {
                        // item.averageRating=5
                        if (item.video !== "") {
                            return (<div className="col-md-4 " key={item.id}>
                                <div className="card-imge">
                                    {/* <img src={videoTestimonials} alt="" width="100%" />
                                    <div className="playbutton"><img src={playbutton} alt="" /></div> */}
                                    <video className='img-fluid' src={environment.baseUrlImg +item.video} controls></video>
                                </div>
                                <div className="cantante" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                                <div className="d-flex my-4">
                                    <div className="col-2 test-col">
                                        <div className="testimonialreview">
                                            <img src={testimonialreview} alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <p className="mb-0  name" >{item.person_name}</p>
                                        <p className="mb-0  catagary" >Student</p>
                                    </div>
                                </div>
                            </div>)
                        }
                        else {
                            return (<div className=" col-lg-4 item" key={item.id}>
                                <div className="box">
                                    <p className="description" dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                                </div>
                                <div className="d-flex my-4">
                                    <div className="col-2 test-col">
                                        <div className="testimonialreview">
                                            <img src={
                                                item?.image
                                                    ? environment.baseUrlImg + item?.image
                                                    : videoTestimonials
                                            } alt="" className="img-fluid" />
                                        </div>
                                    </div>

                                    <div className="col-10">
                                        <p className="mb-0  name" >{item.person_name}</p>
                                        <p className="mb-0  catagary" >Student</p>
                                    </div>
                                </div>
                            </div>)
                        }
                    }
                    )}
                        {/* <div className="col-md-4 ">
                            <div className="card-imge">
                                <img src={videoTestimonials} alt="" width="100%" />
                                <div className="playbutton"><img src={playbutton} alt="" />   </div>
                            </div>
                            <div className="cantante">
                                “Parent to Parent, I would definitely recommend this learning program! Give those online classes a break, and give your child the real preschool experience with ALLEN Digital
                            </div>

                            <div className="d-flex my-4">

                                <div className="col-2 test-col">
                                    <div className="testimonialreview">
                                        <img src={testimonialreview} alt="" className="img-fluid" />
                                    </div>
                                </div>

                                <div className="col-10 test-col">
                                    <p className="mb-0  name" >Aman Bansal</p>
                                    <p className="mb-0  catagary" >Student</p>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-4 ">
                            <div className="card-imge">
                                <img src={videoTestimonials} alt="" width="100%" />
                                <div className="playbutton"><img src={playbutton} alt="" />   </div>
                            </div>
                            <div className="cantante">
                                “Parent to Parent, I would definitely recommend this learning program! Give those online classes a break, and give your child the real preschool experience with ALLEN Digital
                            </div>

                            <div className="d-flex my-4">

                                <div className="col-2 test-col">
                                    <div className="testimonialreview">
                                        <img src={testimonialreview} alt="" className="img-fluid" />
                                    </div>
                                </div>

                                <div className="col-10 test-col">
                                    <p className="mb-0  name" >Aman Bansal</p>
                                    <p className="mb-0  catagary" >Student</p>
                                </div>
                            </div>

                        </div> */}

                    </div>
                </div>
            </div>
            {/*  <div className="testimonials-clean">
                <div className="container-fluid">

                    <div className="row people">
                        <div className=" col-lg-4 item">
                            <div className="box">
                                <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique</p>
                            </div>
                            <div className="d-flex my-4">

                                <div className="col-2 test-col">
                                    <div className="testimonialreview">
                                        <img src={testimonialreview} alt="" className="img-fluid" />
                                    </div>
                                </div>

                                <div className="col-10 test-col">
                                    <p className="mb-0  name" >Aman Bansal</p>
                                    <p className="mb-0  catagary" >Student</p>
                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-4 item">
                            <div className="box">
                                <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique</p>
                            </div>
                            <div className="d-flex my-4">

                                <div className="col-2 test-col">
                                    <div className="testimonialreview">
                                        <img src={testimonialreview} alt="" className="img-fluid" />
                                    </div>
                                </div>

                                <div className="col-10 test-col">
                                    <p className="mb-0  name" >Aman Bansal</p>
                                    <p className="mb-0  catagary" >Student</p>
                                </div>
                            </div>
                        </div><div className=" col-lg-4 item">
                            <div className="box">
                                <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique</p>
                            </div>
                            <div className="d-flex my-4">

                                <div className="col-2 test-col">
                                    <div className="testimonialreview">
                                        <img src={testimonialreview} alt="" className="img-fluid" />
                                    </div>
                                </div>

                                <div className="col-10 test-col">
                                    <p className="mb-0  name" >Aman Bansal</p>
                                    <p className="mb-0  catagary" >Student</p>
                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-4 item">
                            <div className="box">
                                <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique</p>
                            </div>
                            <div className="d-flex my-4">

                                <div className="col-2 test-col">
                                    <div className="testimonialreview">
                                        <img src={testimonialreview} alt="" className="img-fluid" />
                                    </div>
                                </div>

                                <div className="col-10 test-col">
                                    <p className="mb-0  name" >Aman Bansal</p>
                                    <p className="mb-0  catagary" >Student</p>
                                </div>
                            </div>
                        </div><div className=" col-lg-4 item">
                            <div className="box">
                                <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique</p>
                            </div>
                            <div className="d-flex my-4">

                                <div className="col-2 test-col">
                                    <div className="testimonialreview">
                                        <img src={testimonialreview} alt="" className="img-fluid" />
                                    </div>
                                </div>

                                <div className="col-10 test-col">
                                    <p className="mb-0  name" >Aman Bansal</p>
                                    <p className="mb-0  catagary" >Student</p>
                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-4 item">
                            <div className="box">
                                <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique</p>
                            </div>
                            <div className="d-flex my-4">

                                <div className="col-2 test-col">
                                    <div className="testimonialreview">
                                        <img src={testimonialreview} alt="" className="img-fluid" />
                                    </div>
                                </div>

                                <div className="col-10 test-col">
                                    <p className="mb-0  name" >Aman Bansal</p>
                                    <p className="mb-0  catagary" >Student</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


        </>;
    }
}

export default VIdeoTestimonials;