import React from "react";
import { toast } from 'react-toastify';
import AllArticles from "../components/Articles/AllArticles";
import ArticlesFilter from "../components/Articles/ArticlesFilter";
// import { environment } from "../Services/HttpData";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class Articles extends React.Component {
  constructor(props) {
    super(props);
    // //console.log(this.props);
    this.state = {
      articlesCategories: [],
      articlesList: [],
    };
  }
  /* componentWillReceiveProps(props) {
    //console.log(this.props.match.params.slug);
  } */

  async componentDidMount() {
    // //console.log(this.props.match.params.slug);
    this.getArticles();
  }
  async getArticles() {
    // POST request using fetch with async/await
    const requestOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({"category_id":""}),
    };
    fetch(environment.baseUrl + "user_blog_list", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data = /* isJson && */ await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        if (data.replyCode === "success") {
          //console.log(data.data);
          this.setState({articlesList:data.data})
        } else {
          console.error(data.body.message);
          return null;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  render() {
    return (
      <>
        <div className="container">
          <div className="row">
            <ArticlesFilter/>
            <AllArticles
              handelEvent={this.state.articlesList}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Articles;
