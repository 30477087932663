import React from "react";
import { toast } from "react-toastify";
// import { environment } from "../../Services/HttpData";
import iit from '../../images/iit-main.jpg';
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
const EcomProfile = (props) => {
 
    return (
      <>
        <div className="col-12 col-md-3 p-4 order-summary pt-5">
          {/* <h6 className="font-weight-bold cart-summery">Profile</h6> */}
          
        </div>
      </>
    );
  }


export default EcomProfile;
