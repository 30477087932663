import React, { Component } from 'react';
import ImgBoard1 from "../../images/Board-1.gif";
import ImgBoard2 from "../../images/Board-2.gif";
import ImgBoard3 from "../../images/Board-3.gif";
import ImgBoard4 from "../../images/Board-4.gif";
// import { environment } from '../../Services/HttpData';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { encode as base64_encode } from "base-64";
import CourseGrid from "../Courses/CourseGrid";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class HomeUpcomingCourses extends React.Component {

    constructor(props) {
        super(props);
        this.courses = [];
      }
      componentWillReceiveProps(props){
        this.courses = props.courses;
        //console.log("homecourse",this.courses)
      }

     
    getPercantage(price, total) {
        return 100 - (price / total) * 100;
      }

    /* Get Course By slug / Id */
    async getCoureByFilterId(setFilters, extraFilter = null,loadMore=false) {
       
      var element = document.getElementById("all-course-"+setFilters);
          //var allcourse = document.getElementById(filterId);
         

         let elements = document.querySelectorAll('.custom-nav');

  // add class to all chosen elements
  for (var i=0; i<elements.length; i++) {
    elements[i].classList.remove('nav-btn');
  }


          element.classList.add("nav-btn");

        this.props.onChange("",null,false,setFilters)
      }

    render(props) { 
        return <>
        <div className="container">
        <div className="trending">
            <h2 className="text-center mt-5 font-weight-bold">Our Programmes</h2>
           {/* <!-- <p className="text-center mt-5">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Totam doloremque officiis tenetur repellendus explicabo! Temporibus nemo facere voluptatem sequi illum voluptate odio, deserunt cumque, alias quod a culpa, mollitia eum!</p>
            --> */}
            <div className="d-flex flex-row flex-wrap justify-content-around my-md-5 my-3 w-75 ml-auto mr-auto">
                <div>
                    <a className='pointer'><h6 className="custom-nav nav-btn" id="all-course" style={{padding: "10px"}} onClick={()=>this.getCoureByFilterId("")}>All Courses</h6></a>
                </div>
                
               
                <div>
                    <a className='pointer'><h6 className="custom-nav" onClick={()=>this.getCoureByFilterId("neet")} id={"all-course-"+"neet"} style={{padding: "10px"}}>NEET</h6></a>
                </div>
                <div>
                    <a className='pointer'><h6 className="custom-nav" onClick={()=>this.getCoureByFilterId("jee")} id={"all-course-"+"jee"} style={{padding: "10px"}}>JEE</h6></a>
                </div>
                <div>
                    <a className='pointer'><h6 className="custom-nav" onClick={()=>this.getCoureByFilterId("pncf")} id={"all-course-"+"pncf"} style={{padding: "10px"}}>Class 9, 10 </h6></a>
                </div>
                <div>
                    <a className='pointer'><h6 className="custom-nav" onClick={()=>this.getCoureByFilterId("cma")} id={"all-course-"+"cma"} style={{padding: "10px"}}>CMA</h6></a>
                </div>
                <div>
                    <a className='pointer'><h6 className="custom-nav" onClick={()=>this.getCoureByFilterId("ras")} id={"all-course-"+"ras"} style={{padding: "10px"}}>RAS</h6></a>
                </div>
            </div>

            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner  w-100 ">
            <div>
            { 
            (this.courses?this.courses:[]).map((item,i) => {
                                return (
                                  <div className={i==0?"carousel-item col-12 col-sm-6 col-md-4 col-lg-3 active":"carousel-item col-12 col-sm-6 col-md-4 col-lg-3"}>
                                  <div
                                    className="col-xl-12 col-lg-12  col-12 col-md-12 col-sm-12 "
                                    key={item.productId}
                                  > 
                                     <CourseGrid item =  {item} changeLoading = {this.props.changeLoading}/>
                                  </div>
                                  </div>
                                );
                              })}
                
            </div>


</div>
            <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <i className="fa-solid fa-angle-left"></i>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <i className="fa-solid fa-angle-right"></i>
                <span className="sr-only">Next</span>
              </a>

        </div>
        
    </div>
    </div>
        </>;
    }
}
 
export default HomeUpcomingCourses;