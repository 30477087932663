import React, { useState, useEffect } from "react";
// import { environment } from "../../Services/HttpData";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
const CheckoutInstallment = (props) => {
    //console.log(props)
   // const [checkoutinstallment,setCheckoutInstallment] = useState();
    
    
    
    let dt = new Date(); // Now
    let date;
    let check;

    const handleEvent = (e,i,object) =>{
        let chk = false;
        if(e.target.checked || !i){
            chk = true;
            props.onChange(true,object);
        }else{
          props.onChange(false,object);
        }
        
        if(chk){
          for(let i in props.checkoutinstallment){
            if (!document.querySelector('#customCheck'+i).checked) {
              props.onChange(true,props.checkoutinstallment[i]);
            }
            if(props.checkoutinstallment[i].installment_id == object.installment_id){
              break;
            }
          }
        }
        else{
          let tmpFind=false;
          for(let i in props.checkoutinstallment){
            if(tmpFind){
              props.onChange(false,props.checkoutinstallment[i]);
            }
            if(props.checkoutinstallment[i].installment_id == object.installment_id){
              tmpFind=true;
            }
          }
        }
      
        //props.onChange(data);
       // //console.log(e.target.value);
    }

    const checkIsSelected = (object)=>{
      let find = false;  
      for(let i in props.selectedInstallment){
        if(props.selectedInstallment[i].installment_id == object.installment_id){
          find = true;
        }
      }
      return find
    }

    return(
        <>
            <div className="instalment-box-over-address  mb-4">
              <div className="row border-bottom-solid">

                <div className="col-md-12 text-center p-0 background-green">
                  <h5 className="my-font p-0 m-0 course-instalment-text"> <b>IIT Jee Instalments</b></h5>
                </div>

              </div>


              <table className="table table-borderless table-resposnvie my-font installment-table">
                <thead>
                  <tr>
                    <th scope="col">Choose Instalment</th>
                    <th scope="col">Payment Date</th>
                  </tr>
                </thead>
                <tbody>
                    {
                        
                        (props.checkoutinstallment?props.checkoutinstallment:[]).map((itm,i)=>{
                          
                            if(i===0){
                              //console.log(i);
                            //  props.onChange(itm.installment_amount);
                            }
                          
                             dt.setDate(dt.getDate() + itm.installment_days);
                             date = dt.getDate()+"/"+(dt.getMonth()+1)+"/"+dt.getFullYear();
                             
                            return(
                                <>
                                    <tr className="border-bottom">
                                        <th>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input " id={"customCheck"+i} name="selectEmi" value={itm.installment_amount} onChange={(e)=>{handleEvent(e,i,itm)}} checked={i === 0 ? "disabled checked":checkIsSelected(itm) }
                                            ></input>
                                            <label className="custom-control-label" htmlFor={"customCheck"+i}>{itm.installment_amount - itm.discount}/-</label>
                                        </div>
                                        </th>
                                        <td>{date}</td>
                                    </tr>
                                </>
                            )
                        })
                    }
                </tbody>
              </table>

            </div>
        </>
    )
}

export default CheckoutInstallment;