import React from "react";
import cartImg from "../images/cart-img-new.png";
import starFill from "../images/star-fill.png";
import greenCheckIcon from "../images/green-correct-icon.png";
import plaIconGreen from "../images/play-store-green.png";
import appIconGreen from "../images/app-store-green.png";
import formMan from "../images/form-man.png";
import videoProgram from "../images/video-program.png";
import digitalFeartueBg from "../images/digital-feature-bg-img.png";
import ellipseImg from "../images/ellipse-310.png";
import keyFeaturesImg from "../images/key-features-img.png";
import { Tabs } from "react-bootstrap";
import { Tab } from "bootstrap";
import { toast } from "react-toastify";
// import { environment } from "../Services/HttpData";
import IntlTelInput from "react-intl-tel-input";
import country from "../js/countries+states.json"
import ResendOtp from "../components/Resendotp/ResendOtp";
import { Helmet } from "react-helmet";
import MixpanelInit from "../components/Mixpanel/MixpanelInit";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);

class DigitalFeatures extends React.Component {
  constructor(props) {
    super(props);
    //console.log(country);
    // //console.log("Contact Form Called");
    this.formDefultValue = {
      FirstName: "",
      LastName: "",
      MiddleName: "",
      MobilePhone: "",
      Email: "",
      Home_Phone__c: "",
      State: "",
      Country: "India",
      City: "",
      Class__c: "",
      OTP_verification__c: false,
      Father_Name__c: "",
      Mother_Name__c: "",
      DOB__c: null,
      Gender__c: "",
      Category__c: "",
      LeadSource: "Website",
      Preffered_Language__c: "",
      Institute_Name__c: "",
      Tick_Agree_to_T_C__c: false,
      Form_Id__c: "app-link",
      Form_URL__c: `${environment.REACT_APP_BASE_URL + "/app-features"}`,
      Utm_Medium__c: "",
      utm_device__c: "",
      utm_Campaign__c: "",
      utm_source__c: "",
      Description: "",
      access_token: null,
      country_code: "91"
    }
    this.state = {
      isMobileOTPSent: false,
      isMobileEditable: true,
      isEmailEditable: true,
      mOTP: "",
      isEmailOTPSent: false,
      mailOTP: "",
      isFormSubmited: false,
      pageContent: [],
      defaultValues: {
        FirstName: "",
        LastName: "",
        MiddleName: "",
        MobilePhone: "",
        Email: "",
        Home_Phone__c: "",
        State: "",
        Country: "India",
        City: "",
        Class__c: "",
        OTP_verification__c: false,
        Father_Name__c: "",
        Mother_Name__c: "",
        DOB__c: null,
        Gender__c: "",
        Category__c: "",
        LeadSource: "Website",
        Preffered_Language__c: "",
        Institute_Name__c: "",
        Tick_Agree_to_T_C__c: false,
        Form_Id__c: "app-link",
        Form_URL__c: `${environment.REACT_APP_BASE_URL + "/app-features"}`,
        Utm_Medium__c: "",
        utm_device__c: "",
        utm_Campaign__c: "",
        utm_source__c: "",
        Description: "",
        access_token: null,
        country_code: "91",
        email_verified: false
      },
      contactForm: this.formDefultValue,
      statesList: [],
      submitButton: false
    };
  }

  componentWillMount() {
    this.setState({ statesList: country.filter(item => item.iso2 === "IN")[0].states });
  }

  validateForm() {
    //console.log(this.state.contactForm);
    if (this.state.contactForm.FirstName === "") {
      toast.error("First Name is Required");
      return false;
    }
    else if (this.state.contactForm.LastName === "") {
      toast.error("Last Name is Required");
      return false;
    }
    else if (this.state.contactForm.MobilePhone === "" && !this.state.contactForm.MobilePhone.length !== 10) {
      toast.error("Mobile is Required or Invalid");
      return false;
    }
    else if (this.state.contactForm.State === "") {
      toast.error("State is Required");
      return false;
    }
    /* else if (this.state.contactForm.Description === "") {
      toast.error("Message is required");
      return false;
    } */
    else if (!this.state.contactForm.Tick_Agree_to_T_C__c) {
      toast.error("Please accept Terms first");
      return false;
    }
    else if (!this.state.contactForm.OTP_verification__c) {
      toast.error("Please Verify mobile first");
      return false;
    }
    else if (this.state.contactForm.Email !== "" && this.state.isEmailEditable) {
      toast.error("Please Verify Email first");
      return false;
    }
    else {
      return true;
    }
  }
  doSubmitEnqForm(e) {
    // //console.log(this.state.defaultValues);
    if (!this.validateForm()) {
      e.preventDefault();
      return;
    }
    const formData = this.state.contactForm;
    formData.MobilePhone = "+" + formData.country_code + "-" + formData.MobilePhone;
    this.setState({ submitButton: true })
    fetch(process.env.REACT_APP_AUTH_URL + 'sales-force/create_allen_lead_prod', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + user.token,
      },
      body: JSON.stringify(formData)
    })
      .then(async response => {
        const data = await response.json();

        if (data.status === "success") {
          toast.success('Form Submited');
          // if (data?.data.success) {
          this.setState({ isFormSubmited: true, isEmailEditable: true, isMobileEditable: true });
          this.setState({ contactForm: this.state.defaultValues });
          // document.getElementById("enq-form").reset();
          document.querySelector("#contactForm\\.MobilePhone").value = "";
          document.querySelector("#Description").value = "";
          document.querySelector("#State").value = '';
          // }
          //console.log("Submited");
          // toast(data.replyMsg, { position: toast.POSITION.TOP_CENTER });
          // sessionStorage.removeItem('cart');
          // sessionStorage.removeItem('productDetails');
          // window.location.reload()
        } else {
          toast.error('Form Submission Failed');
        }
        this.setState({ submitButton: false })
      }).catch((error) => {
        this.setState({ submitButton: false })
      })
  }
  componentWillReceiveProps(props) {
    // //console.log(props.handelEvent);
    this.setState({ pageContent: props.handelEvent });
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    var value = e.target.value;
    // //console.log(name, value);
    if (name === 'Tick_Agree_to_T_C__c' && value === 'true') {
      value = false;
    }
    else if (name === 'Tick_Agree_to_T_C__c' && value === 'false') {
      value = true;
    }
    /* if(name ==="Email")
    this.sendOTP(name,value); */
    // //console.log(name, value);
    // return;
    var contactForm = { ...this.state.contactForm }
    contactForm[name] = value;
    /* () => {
      this.validateField(name, value);
    } */
    this.setState({ contactForm: contactForm });
    // //console.log(this.state);
  };
  handleOTPInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({ [name]: value });
  };
  handleUserMobile = (fieldName, validate, value, countryData) => {
    //console.log(validate, value, countryData);
    // //console.log(name, value);
    // return;
    if (fieldName === "MobilePhone") {
      this.setState({ statesList: country.filter(item => item.iso2 === countryData.iso2.toUpperCase())[0].states });
    }
    if (validate && value.length === 10) {
      var contactForm = { ...this.state.contactForm }
      contactForm[fieldName] = value;
      contactForm['country_code'] = countryData.dialCode;
      this.setState({ contactForm: contactForm });
      // this.sendOTP(fieldName,value,countryData.dialCode);
    }
    else {
      toast.error("Please Enter Valid mobile number.");
    }
    // //console.log(this.state);
  };
  verifyEmailOtp(e) {

    if (this.state.mailOTP?.length < 6) {
      toast.warning("Please Enter valid OTP number");
      return false;
    }
    fetch(process.env.REACT_APP_ECOM_URL + 'otp/otp_verify_email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + user.token,
      },
      body: JSON.stringify({
        email: this.state.contactForm.Email,
        otp: this.state.mailOTP,
        lead_type: 912,
      }),
    }).then(async (response) => {
      const data = await response.json();
      //console.log(data);
      if (data.replyCode === "success") {
        this.setState({ isEmailOTPSent: false, isEmailEditable: false });
        var contactForm = { ...this.state.contactForm }
        contactForm.email_verified = true;
        this.setState({ contactForm: contactForm });
        //console.log("Email OTP Verified");
      } else {
        toast.error(data.replyMsg);
      }
    });
  }
  verifyMobileOtp(e) {
    if (this.state.mOTP?.length < 6) {
      toast.warning("Please Enter valid OTP number");
      return false;
    }
    fetch(process.env.REACT_APP_ECOM_URL + 'otp/otp_verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + user.token,
      },
      body: JSON.stringify({
        mobile: this.state.contactForm.MobilePhone,
        otp: this.state.mOTP,
        lead_type: 912,
      }),
    }).then(async (response) => {
      const data = await response.json();
      //console.log(data);
      if (data.replyCode === "success") {
        MixpanelInit("Track", "otp_verified", { "phone_number": this.state.contactForm.MobilePhone })
        this.setState({ isMobileOTPSent: false, isMobileEditable: false });
        var contactForm = { ...this.state.contactForm };
        contactForm.OTP_verification__c = true;
        this.setState({ contactForm: contactForm });
        //console.log("Mobie OTP Verified");
      } else {
        toast.error(data.replyMsg);
      }
    });
  }
  async sendOTP(fieldName, value, country_code = null) {
    //console.log(fieldName, value, country_code);
    // return ;
    // POST request using fetch with async/await
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({})
    };
    var reqURL;
    if (fieldName === "MobilePhone" && value !== "") {
      if (value.length === 10) {
        reqURL = process.env.REACT_APP_ECOM_URL + "otp/otp_generate"
        requestOptions.body = JSON.stringify({ "mobile": value, "country_code": country_code, lead_type: 912 })
        fetch(reqURL, requestOptions)
          .then(async response => {
            const data = await response.json();
            // check for error response
            // //console.log(response);
            //console.log(data);
            if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
            if (data.replyCode === "success" && fieldName === "MobilePhone") {
              MixpanelInit("Track", "otp_requested", { "phone_number": value })
              toast?.success(data.replyMsg);
              this?.setState({ isMobileOTPSent: true });


            }
          })
          .catch(error => {
            this?.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
          });
      }
      else {
        toast.error("Please Enter Valid mobile number.");
      }
    }
    else if (fieldName === "MobilePhone" && value === "") {
      toast.error("Please Enter Valid mobile number.");
    }
    else if (fieldName === "Email") {
      reqURL = process.env.REACT_APP_ECOM_URL + "otp/otp_generate_email";
      if (value.length >= 13) {
        requestOptions.body = JSON.stringify({ "email": value, lead_type: 912 })
        fetch(reqURL, requestOptions)
          .then(async response => {
            const data = await response.json();
            // check for error response
            // //console.log(response);
            //console.log(data);
            if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
            if (data.replyCode === "success" && fieldName === "Email") {
              toast?.success(data.replyMsg);
              this?.setState({ isEmailOTPSent: true });

            }
          })
          .catch(error => {
            this?.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
          });
      }
      else {
        toast.error("Please enter valid Email")
      }
    }
    //console.log(reqURL, requestOptions);
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let mobileValid = this.state.mobileValid;
    let passwordValid = this.state.passwordValid;

    switch (fieldName) {
      case "mobile":
        mobileValid = value.match(/^\d{10}$/);
        // mobileValid = value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
        fieldValidationErrors.mobile = mobileValid ? "" : " is invalid";
        break;
      case "password":
        passwordValid = value !== "";
        fieldValidationErrors.password = passwordValid ? "" : " is required";
        break;
    }
    this.setState(
      {
        mobileValid: mobileValid,
        passwordValid: passwordValid,
      },
      this.validateForm
    );
  }
  render() {
    const formSbmit = {
      width: "150px",
    };
    return (
      <>
        <Helmet>
          {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
          {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
          <title>ALLEN Digital App | Personalised Learning Experience</title>
          <meta
            name="description"
            content="Download ALLEN Digital App now and enter the whole new word of learning. We offer a personalised learning experience that makes studying interesting." />
          <meta property="og:title" content="ALLEN Digital App | Personalised Learning Experience" />
          <meta property="og:site_name" content="ALLEN Digital" />
          <meta property="og:url" content="https://digital.allen.ac.in/app-features" />
          <meta property="og:description" content="Download ALLEN Digital App now and enter the whole new word  of learning. We offer a personalised learning experience that makes studying interesting." />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://digital.allen.ac.in/static/media/logo-new-r.4a9d1fde3fd2a6868a621b27352bda3a.svg" />
        </Helmet>
        {/*     <div className="container-fluid bg-blue">
          <div className="container py-5">
            <h3 className="font-weight-bold text-center pb-5 AvenirLTStd-Black digital-features-title">
              Our Amazing Features
            </h3>
            <div className="row mx-0">
              <div className="col-12 col-lg-6">
                <div className="row">
                <div className="digital-features-banner-content">
                    <div className="row mx-0 align-items-center mb-5">
                      <div className="col-2 pr-0">
                        <img src={greenCheckIcon} width="48px" />
                      </div>
                      <div className="col-10 pl-0">
                        <h5 className="font-weight-bold mb-0 digital-features-banner-title">
                          Study
                        </h5>
                        <h6><strong>Live Interactive Classes</strong></h6>
                        <p className="mb-0 digital-features-banner-p cut-text-digital-feature" 
                        title="ALLEN's Digital's instructor-led Live Classes provide a real-time and personalized learning experience to the students. Live classes enable learners to interact with experienced faculty members, get customized study plans, and on-the-spot clarification of their doubts."
                        >
                          ALLEN's Digital's instructor-led Live Classes provide a real-time and personalized learning experience to the students. Live classes enable learners to interact with experienced faculty members, get customized study plans, and on-the-spot clarification of their doubts.
                        </p><br/>
                        <h6><strong>Recorded Video Lectures</strong></h6>
                        <p className="mb-0 digital-features-banner-p cut-text-digital-feature" 
                        title="ALLEN Digital's video lectures present top-class, ready learning resources that give subject-wise explanations of each topic. Our self-paced video lectures are enriched with appealing 2D and 3D effects, graphics, and animations to make them more engaging. They can be accessed anytime, anywhere, and cover all live session learning. This way, the students do not have to worry if they have missed any live lessons as they can access them easily without any difficulty."
                        >
                          ALLEN Digital's video lectures present top-class, ready learning resources that give subject-wise explanations of each topic. Our self-paced video lectures are enriched with appealing 2D and 3D effects, graphics, and animations to make them more engaging. They can be accessed anytime, anywhere, and cover all live session learning. This way, the students do not have to worry if they have missed any live lessons as they can access them easily without any difficulty.
                        </p>
                      </div>
                    </div>
                    <div className="row mx-0 align-items-center mb-5">
                      <div className="col-2 pr-0">
                        <img src={greenCheckIcon} width="48px" />
                      </div>
                      <div className="col-10 pl-0">
                        <h5 className="font-weight-bold mb-0 digital-features-banner-title">
                          Revise
                        </h5>
                        <h6><strong>Quality Study Material</strong></h6>
                        <p className="mb-0 digital-features-banner-p cut-text-digital-feature"
                        title="ALLEN Digital learners can access notes, modules, quizzes, RACE, and DPPs, scientifically curated by ALLEN's top-rated faculty members. Every topic is simplified and precise to ensure the best conceptual learning and information retention."
                        >
                          ALLEN Digital learners can access notes, modules, quizzes, RACE, and DPPs, scientifically curated by ALLEN's top-rated faculty members. Every topic is simplified and precise to ensure the best conceptual learning and information retention.
                        </p><br />
                        <h6><strong>Doubt Forum</strong></h6>
                        <p className="mb-0 digital-features-banner-p cut-text-digital-feature"
                        title="ALLEN Digital Doubt Forum provides an effective and efficient channel to connect students with the faculty members for asking doubts and getting them solved. The doubt forum is open for students 24/7. When in doubt, please post your queries and get them solved by ALLEN faculty members. "
                        >
                          ALLEN Digital Doubt Forum provides an effective and efficient channel to connect students with the faculty members for asking doubts and getting them solved. The doubt forum is open for students 24/7. When in doubt, please post your queries and get them solved by ALLEN faculty members. 
                        </p>
                      </div>
                    </div>
                    <div className="row mx-0 align-items-center mb-5">
                      <div className="col-2 pr-0">
                        <img src={greenCheckIcon} width="48px" />
                      </div>
                      <div className="col-10 pl-0">
                        <h5 className="font-weight-bold mb-0 digital-features-banner-title">
                         Check Performance
                        </h5>
                        <h6><strong>Consistent Performance Evaluation</strong></h6>
                        <p className="mb-0 digital-features-banner-p cut-text-digital-feature"
                        title="ALLEN Digital's fun quizzes, chapter-wise exercises, and practice problems help students evaluate their level of learning; further, the student can create an improvement plan for themselves. Periodic tests are conducted to track students' performance and benchmark it against their peers' performances."
                        >
                          ALLEN Digital's fun quizzes, chapter-wise exercises, and practice problems help students evaluate their level of learning; further, the student can create an improvement plan for themselves. Periodic tests are conducted to track students' performance and benchmark it against their peers' performances.
                        </p>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-6 px-5 align-items-center d-flex">
                <img src={digitalFeartueBg} width="100%" />
              </div>
            </div>
          </div>
        </div> */}

        <div className="container mb-0 mt-5 bg-light px-0  digital-from">
          <div className="row mx-0">
            <div className="col-12 col-lg-6 form-background p-2-all">
              <h3 className="font-weight-bold text-center text-green mb-4 Avenir-black">
                GET APP LINK NOW!
              </h3>
              <form method="POST" id="enq-form" ref={(el) => this.myFormRef = el} className="btn-colors">
                {this.state.isFormSubmited && <div className="alert alert-success alert-dismissible fade show" role="alert">
                  <strong>Success</strong> Form Submited successfully.
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>}
                <input
                  type="hidden"
                  name="LeadSource"
                  id="LeadSource"
                  onChange={(e) => this.handleUserInput(e)}
                  value="Website"
                  className="form-control"
                />
                <input
                  type="hidden"
                  name="Form_Id__c"
                  id="Form_Id__c"
                  value="app-link"
                  className="form-control"
                />
                <input
                  type="hidden"
                  name="Form_URL__c"
                  id="Form_URL__c"
                  value={environment.REACT_APP_BASE_URL + "/app-features"}
                  className="form-control"
                />
                <p
                  id="formSuccess"
                  className="text-white mb-0 text-small"
                ></p>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      {/*  onKeyUp="validateField('firstName',3)" */}
                      <input
                        type="text"
                        id="FirstName"
                        name="FirstName"
                        onChange={(e) => this.handleUserInput(e)}
                        value={this.state.contactForm.FirstName}
                        className="form-control"
                        placeholder="First Name"
                        autoComplete="none"
                      />
                      <p
                        className="text-danger mb-0 text-small error"
                        id="fnameError"
                      ></p>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            id="middleName"
                            name="MiddleName"
                            onChange={(e) => this.handleUserInput(e)}
                            value={this.state.contactForm.MiddleName}
                            className="form-control"
                            placeholder="Middle Name"
                          />
                          <p
                            className="text-danger mb-0 text-small error"
                            id="fnameError"
                          ></p>
                        </div>
                      </div> */}
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="LastName"
                        name="LastName"
                        onChange={(e) => this.handleUserInput(e)}
                        value={this.state.contactForm.LastName}
                        className="form-control"
                        placeholder="Last Name"
                        autoComplete="none"
                      />
                      <p
                        className="text-danger mb-0 text-small error"
                        id="fnameError"
                      ></p>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            id="Father_Name__c"
                            name="Father_Name__c"
                            onChange={(e) => this.handleUserInput(e)}
                            value={this.state.contactForm.Father_Name__c}
                            className="form-control"
                            placeholder="Father Name"
                          />
                          <p
                            className="text-danger mb-0 text-small error"
                            id="Father_Name__cError"
                          ></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            id="Mother_Name__c"
                            name="Mother_Name__c"
                            onChange={(e) => this.handleUserInput(e)}
                            value={this.state.contactForm.Mother_Name__c}
                            className="form-control"
                            placeholder="Mother Name"
                          />
                          <p
                            className="text-danger mb-0 text-small error"
                            id="Mother_Name__cError"
                          ></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="date"
                            id="DOB__c"
                            name="DOB__c"
                            onChange={(e) => this.handleUserInput(e)}
                            value={this.state.contactForm.DOB__c}
                            className="form-control"
                            placeholder="DOB"
                          />
                          <p
                            className="text-danger mb-0 text-small error"
                            id="DOB__cError"
                          ></p>
                        </div>
                      </div> */}
                  <div className="col-md-5">
                    <div className="form-group">
                      <IntlTelInput
                        containerClassName="intl-tel-input w-100"
                        inputClassName="form-control"
                        fieldName="contactForm.MobilePhone"
                        fieldId="contactForm.MobilePhone"
                        onPhoneNumberBlur={(validate, value, countryData) => this.handleUserMobile("MobilePhone", validate, value, countryData)}
                        preferredCountries={["in", "us"]}
                        placeholder="Contact No."
                        disabled={!this.state.isMobileEditable}
                        autoComplete="none"
                      />
                      {/* <input
                            type="tel"
                            name="MobilePhone"
                            id="MobilePhone"
                            className="form-control"
                            placeholder="Contact Number"
                          /> 
                          <p
                            className="text-danger mb-0 text-small error"
                            id="mobileError"
                          ></p>
                          {this.state.isMobileOTPSent && (
                            <div className="row">
                              <div className="col-md-7">
                                <input
                                  type="number"
                                  name="mOTP"
                                  id="mOTP"
                                  // onKeyUp={this.validateField('Email',10)}
                                  onChange={(e) => this.handleOTPInput(e)}
                                  value={this.state.mOTP}
                                  className="form-control"
                                  placeholder="Enter Mobile OTP"
                                  autoComplete={"off"}
                                />

                              </div>
                              <div className="col-md-5">
                                <button className="btn btn-link btn-block text-white" type="button" onClick={(e) => { this.verifyMobileOtp(e) }}>Verify OTP</button>
                              </div>

                            </div>
                          )}*/}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="form-group">
                      {(!this.state.isMobileOTPSent && this.state.isMobileEditable) && (<button type="button" className="btn btn-link text-white" onClick={(e) => { this.sendOTP("MobilePhone", this.state.contactForm['MobilePhone'], this.state.contactForm['country_code']) }}>Send OTP</button>)}

                      {(this.state.contactForm.OTP_verification__c) && (<button type="button" className="btn btn-link text-white">Verified</button>)}
                      {/* <p
                            className="text-danger mb-0 text-small error"
                            id="mobileError"
                          ></p> */}
                      {this.state.isMobileOTPSent && (
                        <div className="row">
                          <div className="col-md-7">
                            <input
                              type="number"
                              name="mOTP"
                              id="mOTP"
                              // onKeyUp={this.validateField('Email',10)}
                              onChange={(e) => this.handleOTPInput(e)}
                              value={this.state.mOTP}
                              className="form-control"
                              placeholder="Enter OTP"
                              autoComplete={"none"}
                            />

                            <ResendOtp
                              fieldName={"MobilePhone"}
                              value={this.state.contactForm['MobilePhone'] ? this.state.contactForm['MobilePhone'] : ""}
                              country_code={this.state.contactForm['country_code']}
                              resendotpFunction={this.sendOTP}
                            />                              </div>
                          <div className="col-md-5 ">
                            <button className="btn btn-link btn-block text-white" type="button" onClick={(e) => { this.verifyMobileOtp(e) }}>Verify OTP</button>
                          </div>

                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group">
                      <input
                        type="email"
                        name="Email"
                        id="Email"
                        // onKeyUp={this.validateField('Email',10)}
                        onChange={(e) => this.handleUserInput(e)}
                        value={this.state.contactForm.Email}
                        className="form-control"
                        placeholder="Email"
                        readOnly={!this.state.isEmailEditable}
                        autoComplete={"none"}
                      />
                      {/* <p
                            className="text-danger mb-0 text-small error"
                            id="emailError"
                          ></p>
                          {this.state.isEmailOTPSent && (
                            <div className="row">
                              <div className="col-md-7">
                                <input
                                  type="number"
                                  name="mailOTP"
                                  id="mailOTP"
                                  // onKeyUp={this.validateField('Email',10)}
                                  onChange={(e) => this.handleOTPInput(e)}
                                  value={this.state.mailOTP}
                                  className="form-control"
                                  placeholder="Enter Email OTP"
                                  autoComplete={"off"}
                                />

                              </div>
                              <div className="col-md-5">
                                <button className="btn btn-link btn-block text-white" type="button"
                                  onClick={(e) => { this.verifyEmailOtp(e) }}>Verify OTP</button>
                              </div>

                            </div>
                          )} */}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="form-group">
                      {(!this.state.isEmailOTPSent && this.state.isEmailEditable) && (<button type="button" className="btn btn-link text-white" onClick={(e) => { this.sendOTP("Email", this.state.contactForm.Email) }}>Send OTP</button>)}
                      {(!this.state.isEmailOTPSent && !this.state.isEmailEditable) && (<button type="button" className="btn btn-link text-white">Verified</button>)}
                      {this.state.isEmailOTPSent && (
                        <div className="row">
                          <div className="col-md-7">
                            <input
                              type="number"
                              name="mailOTP"
                              id="mailOTP"
                              // onKeyUp={this.validateField('Email',10)}
                              onChange={(e) => this.handleOTPInput(e)}
                              value={this.state.mailOTP}
                              className="form-control"
                              placeholder="Enter OTP"
                              autoComplete={"none"}
                            />

                            <ResendOtp
                              fieldName={"Email"}
                              value={this.state?.contactForm?.Email ? this.state?.contactForm?.Email : ""}
                              resendotpFunction={this.sendOTP}
                            />
                          </div>
                          <div className="col-md-5">
                            <button className="btn btn-link btn-block text-white" type="button"
                              onClick={(e) => { this.verifyEmailOtp(e) }}>Verify OTP</button>
                          </div>

                        </div>
                      )}
                    </div>
                  </div>
                  {/*<div className="col-md-6">
                        <div className="form-group">
                          <IntlTelInput
                            containerClassName="intl-tel-input"
                            inputClassName="form-control"
                            fieldName="contactForm.Home_Phone__c"
                            fieldId="contactForm.Home_Phone__c"
                            onPhoneNumberBlur={(validate, value, countryData) => this.handleUserMobile("Home_Phone__c", validate, value, countryData)}
                            preferredCountries={["in", "us"]}
                            placeholder="Home Phone"
                          />
                          <p
                            className="text-danger mb-0 text-small error"
                            id="emailError"
                          ></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="City"
                            id="City"
                            onChange={(e) => this.handleUserInput(e)}
                            value={this.state.contactForm.City}
                            className="form-control"
                            placeholder="City"
                          />
                          <p
                            className="text-danger mb-0 text-small error"
                            id="CityError"
                          ></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <select
                            name="Category__c"
                            className="form-control"
                            id="Category__c"
                            onChange={(e) => this.handleUserInput(e)}
                            defaultValue={''}
                          >
                            <option value="" disabled>Category</option>
                            <option value="General">General</option>
                            <option value="OBC">OBC</option>
                            <option value="SC/ST">SC/ST</option>
                            <option value="other">Other</option>
                          </select>
                          <p
                            className="text-danger mb-0 text-small error"
                            id="ClassError"
                          ></p>
                        </div>
                      </div>
                       <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="Institute_Name__c"
                            id="Institute_Name__c"
                            onChange={(e) => this.handleUserInput(e)}
                            value={this.state.contactForm.Institute_Name__c}
                            className="form-control"
                            placeholder="Institute Name"
                          />
                          <p
                            className="text-danger mb-0 text-small error"
                            id="CityError"
                          ></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <select
                            name="Preffered_Language__c"
                            className="form-control"
                            id="Preffered_Language__c"
                            onChange={(e) => this.handleUserInput(e)}
                            defaultValue={''}
                          >
                            <option value="" disabled>Select Language</option>
                            <option value="hindi">Hindi</option>
                            <option value="english">English</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <select
                            name="Gender__c"
                            className="form-control"
                            id="Gender__c"
                            onChange={(e) => this.handleUserInput(e)}
                            defaultValue={''}
                          >
                            <option value="" disabled>Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                      </div> 
                      <div className="col-md-6">
                        <div className="form-group">
                          <select
                            name="Class__c"
                            className="form-control"
                            id="Class__c"
                            onChange={(e) => this.handleUserInput(e)}
                            defaultValue={''}
                          >
                            <option value="" disabled>Class</option>
                            <option value="1st">1st</option>
                            <option value="2nd">2nd</option>
                            <option value="3rd">3rd</option>
                            <option value="4th">4th</option>
                            <option value="5th">5th</option>
                            <option value="6th">6th</option>
                            <option value="7th">7th</option>
                            <option value="8th">8th</option>
                            <option value="9th">9th</option>
                            <option value="10th">10th</option>
                            <option value="11th">11th</option>
                            <option value="12th">12th</option>
                          </select>
                        </div>
                      </div> */}
                  <div className="col-md-12">
                    <div className="form-group">
                      <select
                        className="form-control"
                        name="State"
                        id="State"
                        onChange={(e) => this.handleUserInput(e)}
                        defaultValue={''}
                        autoComplete={"off"}
                      >
                        <option value="">
                          Select State
                        </option>
                        {this.state.statesList.map((option) => (
                          <option key={option.id} value={option.name}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            name="Description"
                            id="Description"
                            className="form-control"
                            rows="5"
                            placeholder="Message"
                            onChange={(e) => this.handleUserInput(e)}
                            value={this.state.contactForm.Description}
                          ></textarea>
                        </div>
                        <p
                          className="text-danger mb-0 text-small error"
                          id="msgError"
                        ></p>
                      </div> */}
                </div>
                <div className="">
                  <label className="d-flex">
                    <div className=" form-check mr-2">
                      <input
                        className="form-check-input position-static"
                        type="checkbox"
                        name="Tick_Agree_to_T_C__c"
                        onChange={(e) => this.handleUserInput(e)}
                        value={this.state.contactForm.Tick_Agree_to_T_C__c}
                        id="Tick_Agree_to_T_C__c"
                      />
                    </div>
                    <p className="terms">
                      I accept
                      <a href="t&c" target="_blank"> Terms & Conditions</a> and <a href="policies" target="_blank">
                        Privacy Policy
                      </a>
                    </p>
                  </label>
                  <p
                    className="text-danger mb-2 text-small error"
                    id="agreeError"
                  ></p>
                </div>
                <div className="d-flex flex-wrap">
                  <div className="">
                    <button
                      style={formSbmit}
                      type="button"
                      className="btn btn-primary nav-btn"
                      onClick={(e) => { this.doSubmitEnqForm(e) }}
                      disabled={this.state.submitButton}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
              <div className="row  justify-content-center mb-4 mt-4">
                <a
                  href="https://play.google.com/store/apps/details?id=com.allen.allenPlus"
                >
                  <img src={plaIconGreen} alt="" className="img-fluid mr-3" />
                </a>
                <a href="https://apps.apple.com/in/app/allen-plus/id1556252239"><img src={appIconGreen} alt="" /></a>
              </div>
            </div>
            <div className="col-12 col-lg-6 d-none d-lg-block">
              <img src={formMan} alt="" width="100%" />
            </div>
          </div>
        </div>
        <div className="success-stories">
          <div className="container-fluid">


            {/* <div className="">
                <h3 className="text-center pt-5 font-weight-bold Avenir-Black digital-feature-success-story">Success Stories</h3>
                
               
            </div> */}

            {/* <div className="d-flex flex-row flex-wrap justify-content-center my-2 w-50 ml-auto mr-auto ">
<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
  <li className="nav-item">
    <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">ALL</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">JEE Mains</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">JEE Advance</a>
  </li>
</ul>
</div> */}

            <div className="tab-content" id="pills-tabContent">
              {/* <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
  <section className="pb-5">
                <div className="container">
                    <div className="owl-carousel owl-theme">
                        <div>
                            <div className="card">
                                <img src={videoProgram} alt="" width="100%"/>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia nesciunt id enim ratione error, eos laudantium maiores unde ipsum magnam nobis fuga voluptas reiciendis odio rerum quam ab iure. Minima.</p>
                                <div className="card-footer bg-white pl-0">
                                    <h4 className="font-weight-bold">Manoj Modi</h4>
                                    <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card">
                                
                                <div className="card-body comment-bg">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue.</p>
                                </div>
                                <div className="card-footer bg-white pl-0">
                                    <h4 className="font-weight-bold">Manoj Modi</h4>
                                    <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card">
                                <img src={ellipseImg} alt="" className="mr-3 w-25 mb-2"/>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique sit.</p>
                                <div className="card-footer bg-white pl-0">
                                    <h4 className="font-weight-bold">Manoj Modi</h4>
                                    <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card">
                                <img src={videoProgram} alt="" width="100%"/>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis </p>
                                <div className="card-footer bg-white pl-0">
                                    <h4 className="font-weight-bold">Manoj Modi</h4>
                                    <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card">
                                <img src={videoProgram} alt="" width="100%"/>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia nesciunt id enim ratione error, eos laudantium maiores unde ipsum magnam nobis fuga voluptas reiciendis odio rerum quam ab iure. Minima.</p>
                                <div className="card-footer bg-white pl-0">
                                    <h4 className="font-weight-bold">Manoj Modi</h4>
                                    <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card">
                                <img src={videoProgram} alt="" width="100%"/>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia nesciunt id enim ratione error, eos laudantium maiores unde ipsum magnam nobis fuga voluptas reiciendis odio rerum quam ab iure. Minima.</p>
                                <div className="card-footer bg-white pl-0">
                                    <h4 className="font-weight-bold">Manoj Modi</h4>
                                    <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card">
                                <img src={videoProgram} alt="" width="100%"/>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia nesciunt id enim ratione error, eos laudantium maiores unde ipsum magnam nobis fuga voluptas reiciendis odio rerum quam ab iure. Minima.</p>
                                <div className="card-footer bg-white pl-0">
                                    <h4 className="font-weight-bold">Manoj Modi</h4>
                                    <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <button type="button" className="btn btn-green-profile font-weight-bold px-5 py-2">Read All Success Stories</button>
                </div>
            </section>



  </div> */}
              {/* App Features Starts*/}
              <div className=" app-features">
                <div className="container py-5">
                  <div className="">
                    <h2 className="font-weight-bold text-center mb-3">ALLEN Digital App Features</h2>
                    <div className="card py-5 course-card">
                      <div className="row">


                        <div className="col-12 col-lg-12">

                          <p className="mb-2">
                            Learn from a pool of ALLEN's hallmark expert faculty members via live interactive classes and recorded videos on the ALLEN Digital app. Get access to study material, practice exercises, DPPs, and quizzes curated by subject experts.
                          </p>
                          <ul className="green-ul-digital align-items-center">
                            {/* <li>
                      <strong>24/7 Access</strong><br />
                      <span>ALLEN Digital App gives learners the liberty to learn anytime, anywhere, and on any device. It enables offline access to downloadable educational content like study modules, daily practice papers, and recorded video lectures.</span>
                    </li> */}
                            <li>
                              <strong>Advanced Live Classes</strong><br />
                              <span>ALLEN Digital Live Classroom features like video & audio conferencing, shared notes, digital whiteboard, instant polls/surveys, and breakout rooms enrich the online learning experience by enabling one-on-one interactions and doubt solving.</span>
                            </li>
                            <li>
                              <strong>Regular Engagement Insights</strong><br />
                              <span>ALLEN Digital provides the progress reminder students need to stay on the defined track and schedule! Learners can review engagement insights like hours spent on the App, lectures viewed, and quizzes attempted to make strategic study plans.</span>
                            </li>
                            <li>
                              <strong>Personalized Learning Experience</strong><br />
                              <span>ALLEN Digital App is integrated with AI/ML, and hence makes intelligent content recommendations based on learners' interests and past usage. It also enables them to set short-term and long-term goals while preparing for their target examination.</span>
                            </li>
                            <li>
                              <strong>Discussion Forum/Collective Learning</strong><br />
                              <span>ALLEN Digital Discussion Forum provides space for learners to indulge in collective learning by sharing knowledge nuggets, posting engagement insights to motivate other learners, and initiating discussions on syllabus topics.</span>
                            </li>
                            <li>
                              <strong>Learning Playlists</strong><br />
                              <span>Students can create their unique learning playlists using the educational content available on the ALLEN Digital app. They can also share these playlists or study plans with their fellow learners. Learning Playlists play a very vital role during curriculum revision.</span>
                            </li>
                            <li>
                              <strong>Doubt Solving</strong><br />
                              <span>Using the ALLEN Digital App Cohorts feature, learners can communicate directly with educators and co-learners over call and email. They can also use the Doubt Forum to get their problems solved by expert faculty and other learners.</span>
                            </li>
                            <li>
                              <strong>Schedule Tracking</strong><br />
                              <span>ALLEN Digital App Calendar keeps an updated record of all live classes and assessment tests. Learners are notified when there is a change in schedule; they have missed a lecture or have an upcoming live class/test.</span>
                            </li>
                            <li>
                              <strong>Assessment Quizzes</strong><br />
                              <span>ALLEN Digital App empowers learners to take chapter-wise and section-wise quizzes to evaluate their learning and identify their strong and weak topics. These quizzes include MCQs, Fill in the blanks, Assessments Slider, Drag & Drop, Hotspot questions, and so on.</span>
                            </li>
                            <li>
                              <strong>Seamless Experience</strong><br />
                              <span>ALLEN Digital provides a seamless experience by constantly removing technical glitches and avoiding other learning barriers on the App. It offers fast app loading speed and easy navigation to ensure uninterrupted learning.</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* App Feature Ends */}
              <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">  <section className="pb-5">
                <div className="container">
                  <div className="owl-carousel owl-theme">
                    <div>
                      <div className="card">
                        <img src={videoProgram} alt="" width="100%" />
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia nesciunt id enim ratione error, eos laudantium maiores unde ipsum magnam nobis fuga voluptas reiciendis odio rerum quam ab iure. Minima.</p>
                        <div className="card-footer bg-white pl-0">
                          <h4 className="font-weight-bold">Manoj Modi</h4>
                          <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        {/* <!-- <img src={videoProgram} alt="" width="100%"> --> */}
                        <div className="card-body comment-bg">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue.</p>
                        </div>
                        <div className="card-footer bg-white pl-0">
                          <h4 className="font-weight-bold">Manoj Modi</h4>
                          <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <img src={ellipseImg} alt="" className="mr-3 w-25 mb-2" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique sit.</p>
                        <div className="card-footer bg-white pl-0">
                          <h4 className="font-weight-bold">Manoj Modi</h4>
                          <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <img src={videoProgram} alt="" width="100%" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis </p>
                        <div className="card-footer bg-white pl-0">
                          <h4 className="font-weight-bold">Manoj Modi</h4>
                          <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <img src={videoProgram} alt="" width="100%" />
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia nesciunt id enim ratione error, eos laudantium maiores unde ipsum magnam nobis fuga voluptas reiciendis odio rerum quam ab iure. Minima.</p>
                        <div className="card-footer bg-white pl-0">
                          <h4 className="font-weight-bold">Manoj Modi</h4>
                          <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <img src={videoProgram} alt="" width="100%" />
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia nesciunt id enim ratione error, eos laudantium maiores unde ipsum magnam nobis fuga voluptas reiciendis odio rerum quam ab iure. Minima.</p>
                        <div className="card-footer bg-white pl-0">
                          <h4 className="font-weight-bold">Manoj Modi</h4>
                          <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <img src={videoProgram} alt="" width="100%" />
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia nesciunt id enim ratione error, eos laudantium maiores unde ipsum magnam nobis fuga voluptas reiciendis odio rerum quam ab iure. Minima.</p>
                        <div className="card-footer bg-white pl-0">
                          <h4 className="font-weight-bold">Manoj Modi</h4>
                          <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button type="button" className="btn btn-green-profile font-weight-bold px-5 py-2">Read All Success Stories</button>
                </div>
              </section></div>
              <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">  <section className="pb-5">
                <div className="container">
                  <div className="owl-carousel owl-theme">
                    <div>
                      <div className="card">
                        <img src={videoProgram} alt="" width="100%" />
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia nesciunt id enim ratione error, eos laudantium maiores unde ipsum magnam nobis fuga voluptas reiciendis odio rerum quam ab iure. Minima.</p>
                        <div className="card-footer bg-white pl-0">
                          <h4 className="font-weight-bold">Manoj Modi</h4>
                          <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        {/* <!-- <img src={videoProgram} alt="" width="100%"> --> */}
                        <div className="card-body comment-bg">
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue.</p>
                        </div>
                        <div className="card-footer bg-white pl-0">
                          <h4 className="font-weight-bold">Manoj Modi</h4>
                          <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <img src={ellipseImg} alt="" className="mr-3 w-25 mb-2" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique sit.</p>
                        <div className="card-footer bg-white pl-0">
                          <h4 className="font-weight-bold">Manoj Modi</h4>
                          <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <img src={videoProgram} alt="" width="100%" />
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis accumsan congue. Quisque blandit dui Pellentesque habitant morbi tristique Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ex  turpis </p>
                        <div className="card-footer bg-white pl-0">
                          <h4 className="font-weight-bold">Manoj Modi</h4>
                          <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <img src={videoProgram} alt="" width="100%" />
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia nesciunt id enim ratione error, eos laudantium maiores unde ipsum magnam nobis fuga voluptas reiciendis odio rerum quam ab iure. Minima.</p>
                        <div className="card-footer bg-white pl-0">
                          <h4 className="font-weight-bold">Manoj Modi</h4>
                          <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <img src={videoProgram} alt="" width="100%" />
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia nesciunt id enim ratione error, eos laudantium maiores unde ipsum magnam nobis fuga voluptas reiciendis odio rerum quam ab iure. Minima.</p>
                        <div className="card-footer bg-white pl-0">
                          <h4 className="font-weight-bold">Manoj Modi</h4>
                          <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="card">
                        <img src={videoProgram} alt="" width="100%" />
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia nesciunt id enim ratione error, eos laudantium maiores unde ipsum magnam nobis fuga voluptas reiciendis odio rerum quam ab iure. Minima.</p>
                        <div className="card-footer bg-white pl-0">
                          <h4 className="font-weight-bold">Manoj Modi</h4>
                          <p className="text-green mb-0">AIR 2 JEE Mains 2021</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button type="button" className="btn btn-green-profile font-weight-bold px-5 py-2">Read All Success Stories</button>
                </div>
              </section></div>
            </div>



          </div>
        </div>
        {/* <div className=" key-features">
        <div className="container py-5">
          <div className="">
            <h2 className="font-weight-bold text-center mb-3">Key Features</h2>
            <div className="card py-5 course-card">
              <div className="row">
                <div className="col-12 col-lg-6 pl-0">
                  <img src={keyFeaturesImg} width="100%" />
                </div>

                <div className="col-12 col-lg-6">
                  <h4 className="font-weight-normal mb-3">Live Class</h4>
                  <p className="mb-2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum sit amet ante laoreet, lobortis ex sed, placerat
                    nisi. Nulla sollicitudin lacinia ultricies. Praesent a nisl
                    pharetra, eleifend tortor vitae, fermentum neque. Phasellus
                    euismod eget odio eu tincidunt. Etiam posuere ultricies
                    tellus. Duis gravida rhoncus justo, et tincidunt purus
                    lobortis a. Suspendisse maximus malesuada odio a faucibus.
                  </p>
                  <ul className="green-ul-digital align-items-center">
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vestibulum
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div> */}
        <div className="offered-program">
          <div className="container-fluid bg-light">
            {/*  <div className="container">
            <h3 className="text-center pt-5 font-weight-bold">
              Offered Programs
            </h3>
            <p className="text-center">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec est
              dui, laoreet id maximus eget, tincidunt ut felis. Sed lectus
              turpis, cursus sit amet tincidunt et, convallis id orci. Sed a
              felis vel sapien egestas feugiat eget et mauris. Aenean eget massa
              velit.s
            </p>
            <div className="d-flex flex-row flex-wrap justify-content-center my-md-4 my-2 w-50 ml-auto mr-auto">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
  <li className="nav-item">
    <a className="nav-link active" id="ills-home-tab" data-toggle="pill" href="#pills-hom" role="tab" aria-controls="pills-hom" aria-selected="true">Recorded Courses</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" id="pills-profile-ta" data-toggle="pill" href="#pills-profil" role="tab" aria-controls="pills-profil" aria-selected="false">Profile</a>
  </li>

</ul>
            </div>
        </div> */}


            {/* <div className="tab-content" id="pills-tabContent">
  <div className="tab-pane fade show active" id="pills-hom" role="tabpanel" aria-labelledby="ills-home-tab">  <section className="">
            <div className="container">
              <div className="owl-carousel ">
                <div>
                  <div className="card col-12 col-md course-card">
                    <div className="">
                      <img src={{ cartImg }} alt="" width="100%" />
                    </div>
                    <div className="card-content p-2">
                      <p>
                        JEE Main 2021 (February Attempt) Paper Analysis
                        (Physics, Chemistry,
                      </p>
                      <div className="row mx-0 justify-content-between">
                        <div>
                          <p>4.5/5</p>
                        </div>
                        <div className="nav ">
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                        </div>
                        <div className="ml-2">
                          <p>
                            <s>(3220)</s>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-start">
                        <div>
                          <p>&#8377; 455</p>
                        </div>
                        <div className="ml-3">
                          <p>
                            <s>&#8377; 1455</s>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="card col-12 col-md course-card">
                    <div className="">
                      <img src={{ cartImg }} alt="" width="100%" />
                    </div>
                    <div className="card-content p-2">
                      <p>
                        JEE Main 2021 (February Attempt) Paper Analysis
                        (Physics, Chemistry,
                      </p>

                      <div className="row mx-0 justify-content-between">
                        <div>
                          <p>4.5/5</p>
                        </div>
                        <div className="nav ">
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                        </div>
                        <div className="ml-2">
                          <p>
                            <s>(3220)</s>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-start">
                        <div>
                          <p>&#8377; 455</p>
                        </div>
                        <div className="ml-3">
                          <p>
                            <s>&#8377; 1455</s>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="card col-12 col-md course-card">
                    <div className="">
                      <img src={{ cartImg }} alt="" width="100%" />
                    </div>
                    <div className="card-content p-2">
                      <p>
                        JEE Main 2021 (February Attempt) Paper Analysis
                        (Physics, Chemistry,
                      </p>

                      <div className="row mx-0 justify-content-between">
                        <div>
                          <p>4.5/5</p>
                        </div>
                        <div className="nav">
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                        </div>
                        <div className="ml-2">
                          <p>
                            <s>(3220)</s>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-start">
                        <div>
                          <p>&#8377; 455</p>
                        </div>
                        <div className="ml-3">
                          <p>
                            <s>&#8377; 1455</s>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="card col-12 col-md course-card">
                    <div className="">
                      <img src={{ cartImg }} alt="" width="100%" />
                    </div>
                    <div className="card-content p-2">
                      <p>
                        JEE Main 2021 (February Attempt) Paper Analysis
                        (Physics, Chemistry,
                      </p>

                      <div className="row mx-0 justify-content-between">
                        <div>
                          <p>4.5/5</p>
                        </div>
                        <div className="nav ">
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                        </div>
                        <div className="ml-2">
                          <p>
                            <s>(3220)</s>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-start">
                        <div>
                          <p>&#8377; 455</p>
                        </div>
                        <div className="ml-3">
                          <p>
                            <s>&#8377; 1455</s>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="card col-12 col-md course-card">
                    <div className="">
                      <img src={{ cartImg }} alt="" width="100%" />
                    </div>
                    <div className="card-content p-2">
                      <p>
                        JEE Main 2021 (February Attempt) Paper Analysis
                        (Physics, Chemistry,
                      </p>

                      <div className="row mx-0 justify-content-between">
                        <div>
                          <p>4.5/5</p>
                        </div>
                        <div className="nav ">
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                        </div>
                        <div className="ml-2">
                          <p>
                            <s>(3220)</s>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-start">
                        <div>
                          <p>&#8377; 455</p>
                        </div>
                        <div className="ml-3">
                          <p>
                            <s>&#8377; 1455</s>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="card col-12 col-md course-card">
                    <div className="">
                      <img src={{ cartImg }} alt="" width="100%" />
                    </div>
                    <div className="card-content p-2">
                      <p>
                        JEE Main 2021 (February Attempt) Paper Analysis
                        (Physics, Chemistry,
                      </p>

                      <div className="row mx-0 justify-content-between">
                        <div>
                          <p>4.5/5</p>
                        </div>
                        <div className="nav ">
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                        </div>
                        <div className="ml-3">
                          <p>
                            <s>(3220)</s>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-start">
                        <div>
                          <p>&#8377; 455</p>
                        </div>
                        <div className="ml-2">
                          <p>
                            <s>&#8377; 1455</s>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="card col-12 col-md course-card">
                    <div className="">
                      <img src={{ cartImg }} alt="" width="100%" />
                    </div>
                    <div className="card-content p-2">
                      <p>
                        JEE Main 2021 (February Attempt) Paper Analysis
                        (Physics, Chemistry,
                      </p>

                      <div className="row mx-0 justify-content-between">
                        <div>
                          <p>4.5/5</p>
                        </div>
                        <div className="nav ">
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                        </div>
                        <div className="ml-2">
                          <p>
                            <s>(3220)</s>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-start">
                        <div>
                          <p>&#8377; 455</p>
                        </div>
                        <div className="ml-3">
                          <p>
                            <s>&#8377; 1455</s>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-green-profile font-weight-bold px-5 py-2 my-3"
              >
                Explore All Programs
              </button>
            </div>
          </section></div>
  <div className="tab-pane fade" id="pills-profil" role="tabpanel" aria-labelledby="pills-profile-ta">  <section className="">
            <div className="container">
              <div className="owl-carousel ">
                <div>
                  <div className="card col-12 col-md course-card">
                    <div className="">
                      <img src={{ cartImg }} alt="" width="100%" />
                    </div>
                    <div className="card-content p-2">
                      <p>
                        JEE Main 2021 (February Attempt) Paper Analysis
                        (Physics, Chemistry,
                      </p>
                      <div className="row mx-0 justify-content-between">
                        <div>
                          <p>4.5/5</p>
                        </div>
                        <div className="nav ">
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                        </div>
                        <div className="ml-2">
                          <p>
                            <s>(3220)</s>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-start">
                        <div>
                          <p>&#8377; 455</p>
                        </div>
                        <div className="ml-3">
                          <p>
                            <s>&#8377; 1455</s>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="card col-12 col-md course-card">
                    <div className="">
                      <img src={{ cartImg }} alt="" width="100%" />
                    </div>
                    <div className="card-content p-2">
                      <p>
                        JEE Main 2021 (February Attempt) Paper Analysis
                        (Physics, Chemistry,
                      </p>

                      <div className="row mx-0 justify-content-between">
                        <div>
                          <p>4.5/5</p>
                        </div>
                        <div className="nav ">
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                        </div>
                        <div className="ml-2">
                          <p>
                            <s>(3220)</s>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-start">
                        <div>
                          <p>&#8377; 455</p>
                        </div>
                        <div className="ml-3">
                          <p>
                            <s>&#8377; 1455</s>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="card col-12 col-md course-card">
                    <div className="">
                      <img src={{ cartImg }} alt="" width="100%" />
                    </div>
                    <div className="card-content p-2">
                      <p>
                        JEE Main 2021 (February Attempt) Paper Analysis
                        (Physics, Chemistry,
                      </p>

                      <div className="row mx-0 justify-content-between">
                        <div>
                          <p>4.5/5</p>
                        </div>
                        <div className="nav">
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                        </div>
                        <div className="ml-2">
                          <p>
                            <s>(3220)</s>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-start">
                        <div>
                          <p>&#8377; 455</p>
                        </div>
                        <div className="ml-3">
                          <p>
                            <s>&#8377; 1455</s>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="card col-12 col-md course-card">
                    <div className="">
                      <img src={{ cartImg }} alt="" width="100%" />
                    </div>
                    <div className="card-content p-2">
                      <p>
                        JEE Main 2021 (February Attempt) Paper Analysis
                        (Physics, Chemistry,
                      </p>

                      <div className="row mx-0 justify-content-between">
                        <div>
                          <p>4.5/5</p>
                        </div>
                        <div className="nav ">
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                        </div>
                        <div className="ml-2">
                          <p>
                            <s>(3220)</s>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-start">
                        <div>
                          <p>&#8377; 455</p>
                        </div>
                        <div className="ml-3">
                          <p>
                            <s>&#8377; 1455</s>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="card col-12 col-md course-card">
                    <div className="">
                      <img src={{ cartImg }} alt="" width="100%" />
                    </div>
                    <div className="card-content p-2">
                      <p>
                        JEE Main 2021 (February Attempt) Paper Analysis
                        (Physics, Chemistry,
                      </p>

                      <div className="row mx-0 justify-content-between">
                        <div>
                          <p>4.5/5</p>
                        </div>
                        <div className="nav ">
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                        </div>
                        <div className="ml-2">
                          <p>
                            <s>(3220)</s>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-start">
                        <div>
                          <p>&#8377; 455</p>
                        </div>
                        <div className="ml-3">
                          <p>
                            <s>&#8377; 1455</s>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="card col-12 col-md course-card">
                    <div className="">
                      <img src={{ cartImg }} alt="" width="100%" />
                    </div>
                    <div className="card-content p-2">
                      <p>
                        JEE Main 2021 (February Attempt) Paper Analysis
                        (Physics, Chemistry,
                      </p>

                      <div className="row mx-0 justify-content-between">
                        <div>
                          <p>4.5/5</p>
                        </div>
                        <div className="nav ">
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                        </div>
                        <div className="ml-3">
                          <p>
                            <s>(3220)</s>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-start">
                        <div>
                          <p>&#8377; 455</p>
                        </div>
                        <div className="ml-2">
                          <p>
                            <s>&#8377; 1455</s>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="card col-12 col-md course-card">
                    <div className="">
                      <img src={{ cartImg }} alt="" width="100%" />
                    </div>
                    <div className="card-content p-2">
                      <p>
                        JEE Main 2021 (February Attempt) Paper Analysis
                        (Physics, Chemistry,
                      </p>

                      <div className="row mx-0 justify-content-between">
                        <div>
                          <p>4.5/5</p>
                        </div>
                        <div className="nav ">
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                          <img src={starFill} className="star" />
                        </div>
                        <div className="ml-2">
                          <p>
                            <s>(3220)</s>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-start">
                        <div>
                          <p>&#8377; 455</p>
                        </div>
                        <div className="ml-3">
                          <p>
                            <s>&#8377; 1455</s>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-green-profile font-weight-bold px-5 py-2 my-3"
              >
                Explore All Programs
              </button>
            </div>
          </section></div>
  
</div> */}

          </div></div>
      </>
    );
  }
}

export default DigitalFeatures;
