import React from "react";
import AboutUsBanner from "../components/AboutUs/AoutusBanner";
const PypjeeMain2023 = () => {
  var jeeMain2023 = [
    {
      date: "24 January 2023",
      shift: "Shift 1 (9:00 AM – 12:00 PM)",
      physics:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/24-JAN-2023_SHIFT-1_JM_PHYSICS_PAPER_SOLUTION.pdf",
      chemistry:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/24-JAN-2023_SHIFT-1_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
      math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/24-JAN-2023_SHIFT-1_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
    },
    {
      shift: "Shift 2 (3:00 PM – 06:00 PM)",
      physics:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/24-JAN-2023_SHIFT-2_JM_PHYSICS_PAPER_SOLUTION.pdf",
      chemistry:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/24-JAN-2023_SHIFT-2_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
      math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/24-JAN-2023_SHIFT-2_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
    },
    {
      date: "25 January 2023",
      shift: "Shift 1 (9:00 AM – 12:00 PM)",
      physics:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/25-JAN-2023_SHIFT-1_JM_PHYSICS_PAPER_SOLUTION.pdf",
      chemistry:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/25-JAN-2023_SHIFT-1_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
      math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/25-JAN-2023_SHIFT-1_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
    },
    {
      shift: "Shift 2 (3:00 PM – 06:00 PM)",
      physics:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/25-JAN-2023_SHIFT-2_JM_PHYSICS_PAPER_SOLUTION.pdf",
      chemistry:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/25-JAN-2023_SHIFT-2_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
      math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/25-JAN-2023_SHIFT-2_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
    },
    {
      date: "29 January 2023",
      shift: "Shift 1 (9:00 AM – 12:00 PM)",
      physics:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/29-JAN-2023_SHIFT-1_JM_PHYSICS_PAPER_SOLUTION.pdf",
      chemistry:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/29-JAN-2023_SHIFT-1_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
      math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/29-JAN-2023_SHIFT-1_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
    },
    {
      shift: "Shift 2 (3:00 PM – 06:00 PM)",
      physics:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/29-JAN-2023_SHIFT-2_JM_PHYSICS_PAPER_SOLUTION.pdf",
      chemistry:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/29-JAN-2023_SHIFT-2_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
      math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/29-JAN-2023_SHIFT-2_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
    },
    {
      date: "30 January 2023",
      shift: "Shift 1 (9:00 AM – 12:00 PM)",
      physics:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/30-JAN-2023_SHIFT-1_JM_PHYSICS_PAPER_SOLUTION.pdf",
      chemistry:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/30-JAN-2023_SHIFT-1_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
      math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/30-JAN-2023_SHIFT-1_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
    },
    {
      shift: "Shift 2 (3:00 PM – 06:00 PM)",
      physics:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/30-JAN-2023_SHIFT-2_JM_PHYSICS_PAPER_SOLUTION.pdf",
      chemistry:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/30-JAN-2023_SHIFT-2_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
      math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/30-JAN-2023_SHIFT-2_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
    },
    {
      date: "31 January 2023",
      shift: "Shift 1 (9:00 AM – 12:00 PM)",
      physics:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/31-JAN-2023_SHIFT-1_JM_PHYSICS_PAPER_SOLUTION.pdf",
      chemistry:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/31-JAN-2023_SHIFT-1_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
      math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/31-JAN-2023_SHIFT-1_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
    },
    {
      shift: "Shift 2 (3:00 PM – 06:00 PM)",
      physics:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/31-JAN-2023_SHIFT-2_JM_PHYSICS_PAPER_SOLUTION.pdf",
      chemistry:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/31-JAN-2023_SHIFT-2_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
      math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/31-JAN-2023_SHIFT-2_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
    },
    {
      date: "01 February 2023",
      shift: "Shift 1 (9:00 AM – 12:00 PM)",
      physics:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/01-FEB-2023_SHIFT-1_JM_PHYSICS_PAPER_SOLUTION.pdf",
      chemistry:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/1-FEB-2023_SHIFT-1_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
      math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/01-FEB-2023_SHIFT-1_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
    },
    {
      shift: "Shift 2 (3:00 PM – 06:00 PM)",
      physics:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Physics/01-FEB-2023_SHIFT-2_JM_PHYSICS_PAPER_SOLUTION.pdf",
      chemistry:
        "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Chem/1-FEB-2023_SHIFT-2_JM_CHEMISTRY_PAPER_SOLUTION.pdf",
      math: "https://cdn.allendigital.in/fe-bsite/free_resources/jee_main_2023/Maths/01-FEB-2023_SHIFT-2_JM_MATHEMATICS_PAPER_SOLUTION.pdf",
    },
  ];

  return (
    <>
      <AboutUsBanner handelEvent={() => { }} slug="JEE Main January 2023" />
      {
        /* wid? */
        <>
          <div className="tab-content" id="pills-tabContent">
            <div className=" app-features">
              <div className="container py-5">
                <div className="">
                  <h2 className="font-weight-bold text-center mb-3"></h2>
                  <div className="card py-5 course-card">
                    <div className="row">
                      <div className="col-12 col-lg-12">
                        <section class="bg-1">
                          <h2>
                            JEE Main 2023 Question Papers with Solutions PDF
                            (English)
                          </h2>
                          <table
                            class="table ace-table table-responsive d-flex justify-content-md-center"
                            width="100%"
                          >
                            <tbody>
                              <tr class="table-first-heading">
                                <th>Date</th>
                                <th>Shift </th>
                                <th>Physics</th>
                                <th>Chemistry</th>
                                <th>Maths</th>
                              </tr>
                              {jeeMain2023?.map((data) => {
                                return (
                                  <>
                                    <tr>
                                      {data?.date ? (
                                        <td rowspan="2">{data?.date}</td>
                                      ) : (
                                        ""
                                      )}

                                      <td>{data.shift} </td>
                                      <td>
                                        {" "}
                                        <i class="fa-solid fa-download"></i>{" "}
                                        <a target="_blank" href={data?.physics}>
                                          Download
                                        </a>
                                      </td>

                                      <td>
                                        {" "}
                                        <i class="fa-solid fa-download"></i>{" "}
                                        <a
                                          target="_blank"
                                          href={data?.chemistry}
                                        >
                                          Download
                                        </a>
                                      </td>

                                      <td>
                                        {" "}
                                        <i class="fa-solid fa-download"></i>{" "}
                                        <a target="_blank" href={data?.math}>
                                          Download
                                        </a>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default PypjeeMain2023;
