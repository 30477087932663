import React from "react";
import { toast, ToastContainer } from "react-toastify";
import Logo from "../../images/logo-new-r.svg";
import profileicon from "../../images/add-profile.png";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import { useHistory } from "react-router-dom";
import MixpanelInit from "../Mixpanel/MixpanelInit";

export default function ProfileData(props) {
	const history = useHistory();
	const ProfileDataGet = props?.location?.state ? props?.location?.state : "";
	if (ProfileDataGet === "") {
		history.push({ pathname: "/" });
		return false
	}
	const ProfileArrayData = ProfileDataGet?.data[0]?.master_students ? ProfileDataGet?.data[0]?.master_students : ProfileDataGet?.data
	const GetProfileData = (profile_id, form_no) => {
		var myHeaders = new Headers();
		myHeaders.append(
			"Authorization",
			"Bearer " + ProfileDataGet?.token
		);
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			profile_id: profile_id,
		});

		MixpanelInit("Track", "profile_selected", { form_id: form_no })

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		fetch(process.env.REACT_APP_LOGIN_URL + "auth/login", requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result?.status === "success") {
					// MixpanelInit("Identify", form_no)
					// MixpanelInit("Identify", "$identify",result?.data?.form_no)
					MixpanelInit("Track", "login_successful", "")
					MixpanelInit("Identify", "$identify", ProfileDataGet?.mobile_no)
					MixpanelInit("People_Set_Once", "login_successful", { phone_number: ProfileDataGet?.mobile_no })
					window.localStorage.setItem("apiAccessToken", result?.data?.token)
					window.localStorage.setItem("IS_DASHBOARD_ICON", true)
					window.localStorage.setItem("FORM_NO", ProfileDataGet?.mobile_no)
					window.localStorage.setItem("FORM_ID", result?.data?.form_no)
					window.location = props?.location?.state?.previous_Path ? props?.location?.state?.previous_Path == "/login" ? "/" : props?.location?.state?.previous_Path == "/user-details" ? "/" : props?.location?.state?.previous_Path : "/";
				} else {
					toast.error(result?.message)
				}
			})
			.catch(error => console.log("error", error));
	};
	const AddNewUser = () => {
		MixpanelInit("Track", "add_new_profile_selected", "")
		history.push({ pathname: "/sign-up", state: { data: "", token: ProfileDataGet?.token, mobile_no: ProfileDataGet?.mobile_no, previous_Path: props?.location?.state?.previous_Path ? props?.location?.state?.previous_Path : "/" } });
	};
	return (
		<div className='container h-100 plus-container-padding login-container-wrapper'>
			<div className='row justify-content-center align-items-center width-100'>
				<div className='col-12 col-xl-7 mx-auto'>
					<div className='profile-box'>
						<Link className='m-auto' to='/'>
							<img src={Logo} alt='' className='img-fluid' />
						</Link>
						<div className='profile-heading'>
							Select Profile
						</div>
						<div className="row justify-content-center">

							{ProfileArrayData?.map(val => (
								<div className='col-12 col-md-6 col-lg-4 text-center mt-4 mt-md-4'>
									<Link className='profile-wrap' onClick={e => GetProfileData(val?.profile_id, val?.form_no)}>
										<div style={{ display: "inline-flex", justifyContent: "center", position: "relative" }}>
											{val?.is_login == true && <i className="profile-active" aria-hidden="true"></i>}
											<div className="avtar-border"><Avatar size='60' round={true} name={val?.student_name} /></div>
										</div>
										<div>
											<div className="profile-name">{val?.student_name}</div>
											<div className="profile-mobile">{val?.form_no}</div>
											{/* {val?.is_login == false && <button className="btn btn-outline-green-header mt-2 mx-0" style={{minWidth:"155px" }} onClick={e => GetProfileData(val?.profile_id)}>Enter Profile</button>}
											{val?.is_login == false || val?.is_login == true ? " " : <button style={{minWidth:"155px" }} className="btn btn-outline-green-header mt-2 mx-0" onClick={e => GetProfileData(val?.profile_id)}>Enter Profile</button>} */}
										</div>
									</Link>
								</div>
							))}

							{ProfileArrayData?.length < 4 && (<div className='col-12 col-md-6 col-lg-4 text-center mt-4 mt-md-4'>
								<Link className='profile-wrap' onClick={AddNewUser}>
									<div style={{ display: "flex", justifyContent: "center" }}>
										{/* <Avatar size='60' round={true} name={"Add New Profile"} /> */}
										<img src={profileicon} alt='' className='img-fluid' />
									</div>
									<div>
										<div className="profile-name">Add New Profile</div>
										{/* <button className="btn btn-green-header mt-1" style={{minWidth:"160px" }} onClick={AddNewUser}>Add New Profile</button> */}
									</div>
								</Link>
							</div>)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
