import React from "react";
import { Link } from "react-router-dom";
import { encode as base64_encode } from "base-64";
// import { environment } from "../../Services/HttpData";
import { toast } from "react-toastify";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
const CourseList = (props) =>{

  const addToCart = (item, buyNow = false) => {
    //console.log(item);
    const skvDetails = JSON.parse(sessionStorage.getItem("skavaTokenDetails"));

    const wid_data =  localStorage.getItem("userWID") ? JSON.parse( localStorage.getItem("userWID") ) : null;
  let wid =   null;
  if(wid_data){
      if(wid_data.results){
          if(wid_data.results.length){
              if(wid_data.results[0].wid){
                  wid = wid_data.results[0].wid;
              }
          }
      }
  }
    // //console.log(skvDetails.sessionId);
    if (skvDetails !== null && skvDetails.sessionId !== undefined && wid) {
      props.changeLoading(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          apiKey: skvDetails.sessionId,
        },
        body: JSON.stringify({
          "cartItem": [
            {
              "quantity": 1,
              "skus": [
                {
                  "productId": item.productId,
                  "skuId": item.productId,
                  "type": "DEFAULT"
                }
              ],
              "properties": {
                "imageURL": item.appIcon,
                "name": item.name,
                "link": `${process.env.REACT_APP_LMS_URL}en/app/toc/${item.identifier}/overview`,
                "fulfillmentType": "DIGITAL"
              }
            }
          ],
          "properties": {
            "enquiryId": "",
            "callerRef": "business"
          }
        })
      };
      fetch(environment.baseUrl + "addToCartSkSite", requestOptions)
        .then(async (response) => {
          // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
          const data = /* isJson && */ await response.json();
          // check for error response
          // //console.log(response);
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
          setTimeout(() => {
          props.changeLoading(false);            
          }, 500);
          if (response.ok) {
            if (response.status === 201) {
              toast.success("Item Added to Cart");
              //this.props.onChange("reloadCart");
              if (buyNow){
                window.location = environment.skavaBaseUrl + "?return_url=" + environment.REACT_APP_BASE_URL
              }
              else{
                props.handleCartCount('reloadCarts');
              }
            }
          else if (response.status === 200 && buyNow)  {
            
              if( data.purchased ){
                toast.error(data.message);
              }
              else{
                window.location = environment.skavaBaseUrl + "?return_url=" + environment.REACT_APP_BASE_URL
              }
            }
            else {
              toast.error(data.message);
            }
            // this.setState({cartItems:data.items,cartDetails:{value:data.value,currencySign:data.currencySign}})
            //console.log(data);
          }
        })
        .catch((error) => {
          props.changeLoading(false);
          toast.error("There was an error!");
         // this.setState({ errorMessage: error.toString() });
          console.error("There was an error!", error);
        });
    }
    else {
      toast.warning("Please login first.");
    }
  }

    const getPercantage  = (price, total)=> {
        return 100 - (price / total) * 100;
      }

    const item = props.item;

    return(
        <>
            <div className="row m-0 align-items-center">
                      <div className="col-12 col-lg-4 p-0">
                        <Link
                          to={`/courses-details/${base64_encode(
                            item?.identifier
                          )}`}
                        >
                          <img src={item?.appIcon} alt="" width="100%" />
                        </Link>
                        {/* <Link to="#" className="bookmark-btn">
                          <i className="far fa-bookmark"></i>
                        </Link> */}
                      </div>
                      <div className="card-content col-12 col-lg-8">
                        <div className="d-flex flex-row flex-wrap mb-2">
                          <div>
                            <Link
                              to={`/courses-details/${base64_encode(
                                item?.identifier
                              )}`}
                            >
                              <h6 className="mr-2">{item?.name}</h6>
                            </Link>
                          </div>
                          <p className="cut-text-allcourse">{item.description}</p>
                        </div>
                       {/*  <div className="row m-0 justify-content-between">
                          <div className="mr-3 mb-2">
                            <p className="f-12 text-muted mb-1">
                              <img
                                src="images/document.png"
                                className="mr-1"
                                alt=""
                                width="12px"
                              />
                              No. of Tests
                            </p>
                          {item?.complexityLevel && (<p className="mr-1 mb-0 font-size18">{item?.complexityLevel}</p>)}
                          {item?.learningMode && (<p className="mr-1 mb-0 font-size18">{item?.learningMode}</p>)}
                          {item?.contentType && (<p className="mr-1 mb-0 font-size18">{item?.contentType}</p>)}
                          </div>
                          <div className="mr-3 mb-2">
                            <p className="f-12 text-muted mb-1">
                              <img
                                src="images/document-blank.png"
                                className="mr-1"
                                alt=""
                                width="12px"
                              />
                              Stream
                            </p>
                            <p className="mb-0 f-12">Science</p>
                          </div>
                          <div className="mr-3 mb-2">
                            <p className="f-12 text-muted mb-1">
                              <img
                                src="images/language.png"
                                className="mr-1"
                                alt=""
                                width="12px"
                              />
                              Language
                            </p>
                            <p className="mb-0 f-12">English / Hindi</p>
                          </div>
                          <div className="mr-3 mb-2">
                            <p className="f-12 text-muted mb-1">
                              <img
                                src="images/reliability.png"
                                className="mr-1"
                                alt=""
                                width="12px"
                              />
                              Eligibility
                            </p>
                            <p className="mb-0 f-12">XI Studying</p>
                          </div>
                          <div className="mr-3 mb-2">
                            <p className="f-12 text-muted mb-1">
                              <img
                                src="images/target.png"
                                className="mr-1"
                                alt=""
                                width="12px"
                              />
                              Target Year
                            </p>
                            <p className="mb-0 f-12">2023</p>
                          </div>
                        </div> */}
                        <div className="row mx-0  justify-content-between">
                          <div>
                            <div className="row m-0">
                              <h5 className="font-weight-bold mr-3 mb-0">
                                {item?.salesPrice?.currency === "INR"
                                  ? "₹"
                                  : item?.salesPrice?.currency}
                                {item?.salesPrice?.value}
                              </h5>
                              {/* <h5 className="font-weight-bold text-danger mb-0">
                                <s>
                                  {item?.price?.currency === "INR"
                                    ? "₹"
                                    : item?.price?.currency}
                                  {item?.price?.value}
                                </s>
                              </h5> */}
                              {item?.price?.value > item?.salesPrice?.value && (
                            <div className="ml-2">
                              <h5 className="text-danger">
                                <s>
                                  {item?.price?.currency === "INR"
                                    ? "₹"
                                    : item?.price?.currency}
                                  {item?.price?.value}
                                </s>
                              </h5>
                            </div>
                          )}
                            </div>
                          </div>
                          {item?.price?.value > item?.salesPrice?.value ? (
                          <p
                            className="my-0 font-weight-bold save-price"
                            
                          >
                            Save
                            {item?.price?.currency === "INR"
                              ? "₹"
                              : item?.price?.currency}
                            {item?.price?.value - item?.salesPrice?.value} (
                            {getPercantage(
                              item?.salesPrice?.value,
                              item?.price?.value
                            ).toFixed(2)}
                            % OFF)
                          </p>
                        ):(
                          <>
                            <p className="save-price-blank d-none"></p>
                          </>
                        ) }
                          <div className="row mx-0">
                            <button
                              type="button"
                              className="btn btn-outline-green-low-radius mr-3 mb-1"
                              onClick={() => addToCart(item, true)}
                            >
                              Buy Now
                            </button>
                            <button
                              type="button"
                              className="btn btn-green-low-radius mb-1"
                              onClick={() => addToCart(item)}
                            >
                              Add to Bag
                            </button>
                          </div>
                        </div>
                        {/* {item?.price?.value > item?.salesPrice?.value && (
                          <p
                            className="mb-0"
                            style={{
                              color: "#046635",
                              marginTop: "-28px",
                              color: "#429B00",
                              fontSize: "16px",
                            }}
                          >
                            Save
                            {item?.price?.currency === "INR"
                              ? "₹"
                              : item?.price?.currency}
                            {item?.price?.value - item?.salesPrice?.value} (
                            {getPercantage(
                              item?.salesPrice?.value,
                              item?.price?.value
                            ).toFixed(2)}
                            % OFF)
                          </p>
                        )} */}
                      </div>
                    </div>
        </>
    )
}

export default CourseList;