import React from "react";
import Details from "./../components/CoursesDetails/Details";
import ContentDetails from "./../components/CoursesDetails/ContentDetails";
import CourseFaqDetails from "./../components/CoursesDetails/CourseFaqDetails";
import LearningCycle from "./../components/CoursesDetails/LearningCycle";
import SimilarCourses from "./../components/CoursesDetails/SimilarCourses";
import RightSidenav from "./../components/CoursesDetails/RightSidenav";
import { decode as base64_decode } from "base-64";
// import { environment } from "../Services/HttpData";
import { toast } from 'react-toastify';

const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)

class CoursesDetails extends React.Component {
  constructor(props) {
    super(props);
    // //console.log(this.props);
    
    this.apiAccessTokenLms = sessionStorage.getItem("apiAccessTokenLms");
    this.state = {
      courseDetails: null,
      tableContent : []
    };
  }
  async fetchToken() {
    // POST request using fetch with async/await
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({}),
    };
    fetch(environment.baseUrl + "genrateAuthTokentBusinessSite", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data = /* isJson && */ await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        if (data.status === 200) {
          this.apiAccessTokenLms = data.body.access_token;
          sessionStorage.setItem("apiAccessTokenLms", data.body.access_token);
           this.getContent();
          return Promise.resolve(this.apiAccessTokenLms);
          // this.getCourses();
        } else {
          console.error(data.body.message);
          return null;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  componentDidMount() {
    // //console.log(this.props);
    //const slug = this.props.match.params.slug;
    const id = this.props.match.params.id;
    // //console.log(slug, base64_decode(id));
    if(id!==null || id!==undefined){
      this.getCoursDetails(base64_decode(id));
      this.getContent(base64_decode(id));
      this.fetchToken();
    }
    
  }
  async getCoursDetails(id) {
    this.props.changeLoading(true);
    // POST request using fetch with async/await
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.apiAccessTokenLms,
        wid:environment.WID
        /* "apiKey":process.env.API_KEY,
              "rootOrg":process.env.ROOT_ORG */
      },
      body: JSON.stringify({
        url: `${process.env.REACT_APP_LMS_URL}api-gw/external-consumers/allendigital-business/content-hierarchy/hierarchy/${id}`,
      }),
    };
    fetch(environment.baseUrl + "callBusinessSiteWithWID", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data = /* isJson && */ await response.json();
        // check for error response
        //console.log(response);
        this.props.changeLoading(false);
        if (!response.ok) {
          if (data.status === 401) {
            await this.fetchToken();
            await this.getCoursDetails(id);
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          } else {
            //console.log("ERROR Come");
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
        }
        if (data.status === 200) {
          //console.log(data.body);
          this.setState({ courseDetails: data.body});
        //   localStorage.setItem("courseDetails", JSON.stringify(data.body));
        } else {
          toast.error(data.body.message);
        }
        // //console.log(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  async getContent(id) {
    // POST request using fetch with async/await
    // //console.log(setFilters,extraFilter);
    //alert()
    if(id){
    const requestOptions1 = {
      method: "POST",
      headers: {
        "Content-Type":"application/json"
       
      },
      body: JSON.stringify({
        "access_token":this.apiAccessTokenLms,
        "lex_id":id
    }
    )
    };
     fetch(environment.baseUrl+"getCourseToc",requestOptions1).then((resp)=>{ // fetching api url to filter courses
      resp.json().then((result1)=>{
       this.setState({tableContent : result1.body})
       //console.log("tbcontent",this.state.tableContent)
      })
    })
  }
  }
  
  handleCartCount(status){
    this.props.onChange(status);
  }
  render() {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <main
              role="main"
              className="col-md-9 ml-sm-auto col-lg-9 px-3 py-5"
            >
              <Details handelEvent={this.state.courseDetails} 
              onChange={this.handleCartCount.bind(this)} changeLoading = {this.props.changeLoading} handleCartCount={this.props.handleCartCount}/>
              <ContentDetails handelEvent={this.state.courseDetails} tableContent={this.state.tableContent}/>
              {/* <FaqDetails handelEvent={this.state.courseDetails} /> */}
             {/*  <CourseFaqDetails/> */}
            </main>
            <RightSidenav handelEvent={this.state.courseDetails} />
            <LearningCycle handelEvent={this.state.courseDetails} />
            <SimilarCourses handelEvent={base64_decode(this.props.match.params.id)} />
          </div>
        </div>
      </>
    );
  }
}

export default CoursesDetails;
