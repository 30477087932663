import React from "react";
import arrowDown from "../../images/dropdown-arrow.png";
class CoursesFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filtersData: [],
        };
    }
    componentWillReceiveProps(props) {
        //console.log(props.handelEvent);
        this.setState({ filtersData: props.handelEvent });
    }

    onChangeItem = (pindex, cindex, myevent) => {
        //   //console.log(event,pindex,cindex,this.state.filtersData[pindex].content[cindex].isChecked);
        /* this.setState((state, props) => {
          state.filtersData[pindex].content[cindex].isChecked = !state.filtersData[pindex].content[cindex].isChecked;
          return {
            filtersData: state.filtersData
          }
        }) */
        this.props.onChange(pindex, cindex, myevent)
       // //console.log(event, pindex, cindex, this.state.filtersData[pindex].content[cindex].isChecked);
    }
    render() {
        return (
            <>
                {/* <nav className=" col-lg-2 col-md-3 d-none d-md-block sidebar filter">
            <div className="sidebar-sticky">
              <ul className="nav flex-column p-2 filter-ul">
                <li className="nav-item">
                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center pt-5 pb-2">
                        <h5 className="mr-2 mb-0">Filters</h5>
                        <a>
                            <button type="button" className="btn btn-green-no-width px-4 py-1">Apply</button>
                        </a>
                        <a href="#">
                            <p className="mb-0">Clear All</p>
                        </a>
                    </div>
                </li>
                <li className="nav-item mb-3">
                    <h5 style={{borderBottom: "1px solid grey"}}>
                        <button className="btn btn-link filter-collapse-link dropdown-toggle" data-toggle="collapse" data-target="#collapseOne"
                            aria-expanded="true" aria-controls="collapseOne">
                            Target Exam
                        </button>
                    </h5>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                        data-parent="#accordion">
                        <div className="card-body col" style={{padding: "0"}}>
                            <div className="row m-0 align-items-center">
                                <input className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <p className="ml-1">12th Bio</p>
                                <p className="ml-auto">(1332)</p>
                            </div>
                            <div className="row m-0 align-items-center ">
                                <input className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <p className="ml-1">11th Bio</p>
                                <p className="ml-auto">(232)</p>
                            </div>
                            <div className="row m-0 align-items-center ">
                                <input className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <p className="ml-1">12th Maths</p>
                                <p className="ml-auto">(122)</p>
                            </div>
                            <div className="row m-0 align-items-center ">
                                <input className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <p className="ml-1">11th Maths</p>
                                <p className="ml-auto">(1355)</p>
                            </div>
                            <div className="row m-0 align-items-center ">
                                <input className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <p className="ml-1">IIT-JEE Main</p>
                                <p className="ml-auto">(1532)</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="nav-item mb-3">
                    <h5 style={{borderBottom: "1px solid grey"}}>
                        <button className="btn btn-link filter-collapse-link dropdown-toggle" data-toggle="collapse" data-target="#collapseTwo"
                            aria-expanded="true" aria-controls="collapseOne">
                            Target Year
                        </button>
                    </h5>
                    <div id="collapseTwo" className="collapse show" aria-labelledby="headingOne"
                        data-parent="#accordion">
                        <div className="card-body col" style={{padding: "0"}}>
                            <div className="row m-0 align-items-center">
                                <input className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <p className="ml-1">2021</p>
                                <p className="ml-auto">(1332)</p>
                            </div>
                            <div className="row m-0 align-items-center ">
                                <input className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <p className="ml-1">2022</p>
                                <p className="ml-auto">(232)</p>
                            </div>
                            <div className="row m-0 align-items-center ">
                                <input className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <p className="ml-1">2023</p>
                                <p className="ml-auto">(122)</p>
                            </div>
                            <div className="row m-0 align-items-center ">
                                <input className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <p className="ml-1">2024</p>
                                <p className="ml-auto">(1355)</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="nav-item mb-3">
                    <h5 style={{borderBottom: "1px solid grey"}}>
                        <button className="btn btn-link filter-collapse-link dropdown-toggle" data-toggle="collapse" data-target="#collapseThree"
                            aria-expanded="true" aria-controls="collapseOne">
                            Subjects
                        </button>
                    </h5>
                    <div id="collapseThree" className="collapse show" aria-labelledby="headingOne"
                        data-parent="#accordion">
                        <div className="card-body col" style={{padding: "0"}}>
                            <div className="row m-0 align-items-center">
                                <input className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <p className="ml-1">Physics</p>
                                <p className="ml-auto">(1332)</p>
                            </div>
                            <div className="row m-0 align-items-center ">
                                <input className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <p className="ml-1">Chemistry</p>
                                <p className="ml-auto">(232)</p>
                            </div>
                            <div className="row m-0 align-items-center ">
                                <input className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <p className="ml-1">Maths</p>
                                <p className="ml-auto">(122)</p>
                            </div>
                            <div className="row m-0 align-items-center ">
                                <input className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <p className="ml-1">English</p>
                                <p className="ml-auto">(1355)</p>
                            </div>
                            <div className="row m-0 align-items-center ">
                                <input className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <p className="ml-1">Hindi</p>
                            </div>
                            <div className="row m-0 align-items-center ">
                                <input className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <p className="ml-1">Computer Science</p>
                            </div>
                        </div>
                    </div>
                </li>
              </ul>
            </div>
          </nav> */}

                <nav className=" col-lg-3 col-md-3 d-lg-block sidebar filter-fluid  filters">
                    <div className="sidebar-sticky">
                        <ul className="nav flex-column p-2 filter-ul">
                            <li className="nav-item">
                                <div className="d-flex flex-row flex-wrap justify-content-between align-items-center pt-5 pb-2">
                                    <h5 className="mr-1 mb-0  ">Filters</h5>
                                    {/* <a href="#">
                    <button
                      type="button"
                      className="btn btn-green-no-width  py-1"
                    >
                      Apply
                    </button>
                  </a> */}
                                    <button onClick={()=>this.props.clearAll()} className="btn text-success">Reset</button>
                                </div>
                            </li>
                            {this.state.filtersData && this.state.filtersData.map((item, indx) => (
                                <li className="nav-item mb-3" key={indx}>
                                    <div
                                        className="row m-0 align-items-center mb-2 cursor-pointer"
                                        style={{ borderBottom: "1px solid grey" }}
                                        data-toggle="collapse"
                                        data-target={`#${item?.type + indx}`}
                                        aria-expanded="true"
                                        aria-controls={item?.type + indx}
                                    >
                                        <h5 className="p-0 my-auto">
                                            <button className="btn btn-link filter-collapse-link p-0">
                                                {item?.displayName}
                                            </button>
                                        </h5>
                                        <img
                                            src={arrowDown}
                                            className="ml-auto"
                                            width="16px"
                                            height="10px"
                                        />
                                    </div>
                                    <div
                                        id={item?.type + indx}
                                        className="collapse show"
                                        aria-labelledby="headingOne"
                                        data-parent="#accordion"
                                    >
                                        {
                                            item.displayName === "Exam"?
                                            <div className="card-body col" style={{ padding: "0" }}>
                                            {item.content.map((itm, i) => (
                                                 (itm.children).map((chld,ch)=>{
                                                    return(
                                                        <>
                                                        <div
                                                    className="row m-0 align-items-center custom-checkbox"
                                                    data-target={chld.type + indx + ch}
                                                    key={ch}
                                                >
                                                    <input
                                                        id={chld.type + indx + ch}
                                                        className="filter-check"
                                                        type="checkbox"
                                                        aria-label="Checkbox for following text input"
                                                        checked={chld.isChecked}
                                                        onChange={(e)=>this.onChangeItem(indx, i,e)}
                                                        value={chld.type}
                                                    />
                                                    <label htmlFor={chld.type + indx + ch} className="ml-1 mb-0">
                                                        {
                                                            chld.displayName
                                                           
                                                            
                                                        }
                                                    </label>
                                                    <p className="ml-auto">({chld?.count})</p>
                                                </div>
                                                            
                                                        </>
                                                    )
                                                })
                                                
                                            ))}
                                            {/* <div className="row m-0 align-items-center custom-checkbox ">
                                <input id="11th-Bio" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="11th-Bio" className="ml-1 mb-0">11th Bio</label>
                                <p className="ml-auto">(232)</p>
                            </div>
                            <div className="row m-0 align-items-center custom-checkbox ">
                                <input id="12th-Maths" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="12th-Maths" className="ml-1 mb-0">12th Maths</label>
                                <p className="ml-auto">(122)</p>
                            </div>
                            <div className="row m-0 align-items-center custom-checkbox ">
                                <input id="11th-Maths" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="11th-Maths" className="ml-1 mb-0">11th Maths</label>
                                <p className="ml-auto">(1355)</p>
                            </div>
                            <div className="row m-0 align-items-center custom-checkbox ">
                                <input id="IIT-JEE" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="IIT-JEE" className="ml-1 mb-0">IIT-JEE Main</label>
                                <p className="ml-auto">(1532)</p>
                            </div> */}
                                        </div>
                                            :
                                            <div className="card-body col" style={{ padding: "0" }}>
                                            {item.content.map((itm, i) => (
                                                <div
                                                    className="row m-0 align-items-center custom-checkbox"
                                                    data-target={itm.type + indx + i}
                                                    key={i}
                                                >
                                                    <input
                                                        id={itm.type + indx + i}
                                                        className="filter-check"
                                                        type="checkbox"
                                                        aria-label="Checkbox for following text input"
                                                        checked={itm.isChecked}
                                                        onChange={(e)=>this.onChangeItem(indx, i,e)}
                                                        value={itm.type}
                                                    />
                                                    <label htmlFor={itm.type + indx + i} className="ml-1 mb-0">
                                                        {
                                                            (itm.displayName === "Instructor-Led")?"Live"
                                                            :
                                                            itm.displayName === "Self-Paced"?"Recorded":itm.displayName
                                                        }
                                                    </label>
                                                    <p className="ml-auto">({itm?.count})</p>
                                                </div>
                                            ))}
                                            {/* <div className="row m-0 align-items-center custom-checkbox ">
                                <input id="11th-Bio" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="11th-Bio" className="ml-1 mb-0">11th Bio</label>
                                <p className="ml-auto">(232)</p>
                            </div>
                            <div className="row m-0 align-items-center custom-checkbox ">
                                <input id="12th-Maths" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="12th-Maths" className="ml-1 mb-0">12th Maths</label>
                                <p className="ml-auto">(122)</p>
                            </div>
                            <div className="row m-0 align-items-center custom-checkbox ">
                                <input id="11th-Maths" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="11th-Maths" className="ml-1 mb-0">11th Maths</label>
                                <p className="ml-auto">(1355)</p>
                            </div>
                            <div className="row m-0 align-items-center custom-checkbox ">
                                <input id="IIT-JEE" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="IIT-JEE" className="ml-1 mb-0">IIT-JEE Main</label>
                                <p className="ml-auto">(1532)</p>
                            </div> */}
                                        </div>
                                        }
                                        
                                    </div>
                                </li>
                            ))}
                            {/* <li className="nav-item mb-3">
                    <div className="row m-0 align-items-center mb-2" style={{borderBottom: "1px solid grey"}}>
                        <h5 className="p-0 my-auto">
                            <button className="btn btn-link filter-collapse-link p-0" data-toggle="collapse" data-target="#collapseTwo"
                                aria-expanded="true" aria-controls="collapseOne">
                                Target Year
                            </button>
                        </h5>
                        <img src={arrowDown} className="ml-auto" width="16px" height="10px" />
                    </div>
                    <div id="collapseTwo" className="collapse show" aria-labelledby="headingOne"
                        data-parent="#accordion">
                        <div className="card-body col" style={{padding:"0"}}>
                            <div className="row m-0 align-items-center custom-checkbox">
                                <input id="2021" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="2021" className="ml-1 mb-0">2021</label>
                                <p className="ml-auto">(1332)</p>
                            </div>
                            <div className="row m-0 align-items-center custom-checkbox ">
                                <input id="2022" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="2022" className="ml-1 mb-0">2022</label>
                                <p className="ml-auto">(232)</p>
                            </div>
                            <div className="row m-0 align-items-center custom-checkbox ">
                                <input id="2023" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="2023" className="ml-1 mb-0">2023</label>
                                <p className="ml-auto">(122)</p>
                            </div>
                            <div className="row m-0 align-items-center custom-checkbox ">
                                <input id="2024" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="2024" className="ml-1 mb-0">2024</label>
                                <p className="ml-auto">(1355)</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="nav-item mb-3">
                    <div className="row m-0 align-items-center mb-2" style={{borderBottom: "1px solid grey"}}>
                        <h5 className="p-0 my-auto">
                            <button className="btn btn-link filter-collapse-link p-0" data-toggle="collapse" data-target="#collapseThree"
                                aria-expanded="true" aria-controls="collapseOne">
                                Subjects
                            </button>
                        </h5>
                        <img src={arrowDown} className="ml-auto" width="16px" height="10px" />
                    </div>
                    
                    <div id="collapseThree" className="collapse show" aria-labelledby="headingOne"
                        data-parent="#accordion">
                        <div className="card-body col" style={{padding:"0"}}>
                            <div className="row m-0 align-items-center custom-checkbox">
                                <input id="physics" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="physics" className="ml-1 mb-0">Physics</label>
                                <p className="ml-auto">(1332)</p>
                            </div>
                            <div className="row m-0 align-items-center custom-checkbox">
                                <input id="chemistry" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="chemistry" className="ml-1 mb-0">Chemistry</label>
                                <p className="ml-auto">(232)</p>
                            </div>
                            <div className="row m-0 align-items-center custom-checkbox">
                                <input id="maths" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="maths" className="ml-1 mb-0">Maths</label>
                                <p className="ml-auto">(122)</p>
                            </div>
                            <div className="row m-0 align-items-center custom-checkbox">
                                <input id="english" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="english" className="ml-1 mb-0">English</label>
                                <p className="ml-auto">(1355)</p>
                            </div>
                            <div className="row m-0 align-items-center custom-checkbox">
                                <input id="hindi" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="hindi" className="ml-1 mb-0">Hindi</label>
                            </div>
                            <div className="row m-0 align-items-center custom-checkbox">
                                <input id="computer" className="filter-check" type="checkbox"
                                    aria-label="Checkbox for following text input"/>
                                <label htmlFor="computer" className="ml-1 mb-0">Computer Science</label>
                            </div>
                        </div>
                    </div>
                </li> */}
                        </ul>
                    </div>
                </nav>
            </>
        );
    }
}

export default CoursesFilter;
