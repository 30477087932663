import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
export default function JeeMain2ResultPage() {
  const [resultData, set_ResultData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(16);
  const [totalPages, setTotalPages] = useState(0);
  function getResultData() {
    if (window?.AllenJeeMainResultSessionTo) {
      // set data
      set_ResultData(window?.AllenJeeMainResultSessionTo);
    } else {
      setTimeout(() => {
        getResultData();
      }, 100);
    }
  }
  useEffect(() => {
    getResultData();
  }, []);
  useEffect(() => {
    const totalPages = Math.ceil(resultData.length / resultsPerPage);
    setTotalPages(totalPages);
  }, [resultData, resultsPerPage]);
  const sharetab = (e) => {
    document
      .querySelector(
        "#root > div > div > div:nth-child(3) > div > div.share-wrapper > i"
      )
      .classList.toggle("active");
    document
      .querySelector(
        "#root > div > div > div:nth-child(3) > div > div.share-wrapper > ul"
      )
      .classList.toggle("active");
  };
  const toppers = {
    autoplay: true,
    loop: true,
    infinite: false,
    nav: true,
    dots: true,
    autoplayTimeout: 4000,
    autoplayHoverPause: true,
    //navText: ["Prev", "Next"],
    smartSpeed: 800,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
        nav: true,
        slideBy: 3,
      },
      992: {
        margin: 30,
        items: 3,
        nav: true,
        slideBy: 3,
      },
    },
  };
  const testimonials = {
    margin: 30,
    autoplay: false,
    loop: true,
    infinite: false,
    nav: false,
    dots: true,
    autoplayTimeout: 4000,
    autoplayHoverPause: true,
    //navText: ["Prev", "Next"],
    smartSpeed: 800,
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      768: {
        items: 2,
        nav: true,
      },
      1200: {
        items: 3,
        nav: true,
      },
    },
  };
  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = resultData.slice(
    indexOfFirstResult,
    indexOfLastResult
  );
  const paginate = (pageNumber) => {
    if (pageNumber === "...") {
      setCurrentPage((prevPage) => prevPage + 2);
    } else {
      setCurrentPage(pageNumber);
    }
  };
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  const getPaginationRange = () => {
    const maxDisplayedPages = 3; // Number of pagination buttons to display
    const ellipsisThreshold = 2; // Number of pages after which to show ellipsis
    const rangeStart = 1;
    if (totalPages <= maxDisplayedPages) {
      return Array.from({ length: totalPages }, (_, i) => rangeStart + i);
    }
    let rangeEnd = Math.min(rangeStart + maxDisplayedPages - 1, totalPages);
    if (currentPage >= ellipsisThreshold) {
      rangeEnd = Math.min(currentPage + 2, totalPages);
    }
    const paginationRange = Array.from(
      { length: rangeEnd - rangeStart + 1 },
      (_, i) => rangeStart + i
    );
    if (rangeEnd < totalPages) {
      paginationRange.push("...");
      paginationRange.push(totalPages);
    }
    return paginationRange;
  };
  return (
    <div>
      <div className="share-wrapper">
        <i className="fa fa-share-alt share" onClick={sharetab}></i>
        <ul className="social">
          <li id="desktopwhatsappicon">
            <a
              href={`https://web.whatsapp.com/send?text=${window.location.href}`}
              target="_blank"
              title="whatsapp"
            >
              <i className="fa fa-whatsapp whatsapp"></i>
            </a>
          </li>
          <li id="mobilewhatsappicon">
            <a
              href={`https://web.whatsapp.com/send?text=${window.location.href}`}
              target="_blank"
              title="whatsapp"
            >
              <i className="fa fa-whatsapp whatsapp"></i>
            </a>
          </li>
          <li>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
              target="_blank"
              title="facebook"
            >
              <i className="fa fa-facebook-official facebook"></i>
            </a>
          </li>
          <li>
            <a
              href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
              target="_blank"
              title="twitter"
            >
              <i className="fa fa-twitter twitter"></i>
            </a>
          </li>
          <li>
            <a
              href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`}
              target="_blank"
              title="linkedin"
            >
              <i className="fa fa-linkedin linkedin"></i>
            </a>
          </li>
          {/* 
         <li><a href="#" target="_blank" title="google"><i className="fa fa-google-plus google"></i></a></li>
         */}
          {/* 
         <li><a href="#" target="_blank" title="instagram"><i className="fa fa-instagram instagram"></i></a></li>
         */}
        </ul>
      </div>
      <Helmet>
        {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
        <title>JEE Main Result 2023 Session 2 | ALLEN Digital</title>
        <meta name="description" content="ALLEN Digital has produced amazing JEE Main Results 2023 in Session 2. Witness the remarkable performance of our top-performing students." />
        <meta property="og:title" content="JEE Main Result 2023 Session 2 | ALLEN Digital" />
        <meta property="og:site_name" content="ALLEN Digital" />
        <meta property="og:url" content="https://digital.allen.ac.in/results/jee-main-session-2/2023" />
        <meta property="og:description" content="ALLEN Digital has produced amazing JEE Main Results 2023 in Session 2. Witness the remarkable performance of our top-performing students." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://digital.allen.ac.in/static/media/logo-new-r.4a9d1fde3fd2a6868a621b27352bda3a.svg" />
        {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
      </Helmet>
      <div className="result-page-container">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="result-page-title">
                Champions of JEE Main 2023 (Session-2)
              </div>
            </div>
          </div>
          <div className="row row-cols-3 row-cols-md-3 row-cols-lg-5 result-highlight-gap justify-content-center">
            <div className="col text-center padding-right5">
              <div className="result-star">
                <div className="data">259</div>
              </div>
              <div className="result-highlight-point">
                Students scored 99%ile and above.
              </div>
            </div>
            <div className="col text-center padding-left0 padding-right5">
              <div className="result-star">
                <div className="data">18</div>
              </div>
              <div className="result-highlight-point">
                Students scored <br className="d-md-none" />100%ile in Physics.
              </div>
            </div>
            <div className="col text-center padding-left0">
              <div className="result-star">
                <div className="data">2</div>
              </div>
              <div className="result-highlight-point">
                Students Scored 100%ile in Chemistry.
              </div>
            </div>
            <div className="col text-center padding-right5 col-width">
              <div className="result-star">
                <div className="data">2</div>
              </div>
              <div className="result-highlight-point">
                Students Scored<br className="d-md-none" /> 100%ile in Mathematics.
              </div>
            </div>
            <div className="col text-center padding-left5 col-width">
              <div className="result-star">
                <div className="data">3</div>
              </div>
              <div className="result-highlight-point">
                Students Scored Above 99.99%ile.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="result-topper-container">
                <div className="toppers-heading col-12 col-md-6 mx-auto col-lg-12 p-0"><img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/Toppers_Heading.png" className="img-fluid d-block mx-auto"></img></div>
                <div className="container">
                  <OwlCarousel {...toppers}>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/student/21659364.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">OLTS</div>
                        <div className="student-percentages">100 %ile</div>
                        <div className="studentnames">MAYANK SONI</div>
                      </div>
                    </div>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/student/21672692.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">OLTS</div>
                        <div className="student-percentages">99.9947517 %ile</div>
                        <div className="studentnames">RAMKRISHNA GENA</div>
                      </div>
                    </div>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/student/1111133534.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">OLTS</div>
                        <div className="student-percentages">99.9898302 %ile</div>
                        <div className="studentnames">SOUHARDYA DANDAPAT</div>
                      </div>
                    </div>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/student/21669003.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">OLTS</div>
                        <div className="student-percentages">99.973613 %ile</div>
                        <div className="studentnames">DEV ARORA</div>
                      </div>
                    </div>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/student/1111157687.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">OLTS</div>
                        <div className="student-percentages">99.9694905 %ile</div>
                        <div className="studentnames">PRAKHAR AGRAWAL</div>
                      </div>
                    </div>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/student/21351932.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">OLTS</div>
                        <div className="student-percentages">99.9680377 %ile</div>
                        <div className="studentnames">ARNAV GUPTA</div>
                      </div>
                    </div>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/student/21665342.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">OLTS</div>
                        <div className="student-percentages">99.9661084 %ile</div>
                        <div className="studentnames">ANUPAM JAKHAR</div>
                      </div>
                    </div>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/student/21659262.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">OLTS</div>
                        <div className="student-percentages">99.9643181 %ile</div>
                        <div className="studentnames">SRIYANSH SURYADEVARA</div>
                      </div>
                    </div>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/student/21666277.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">OLTS</div>
                        <div className="student-percentages">99.9627218 %ile</div>
                        <div className="studentnames">ARYA SAMEER JOSHI</div>
                      </div>
                    </div>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/student/1111173962.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">OLTS</div>
                        <div className="student-percentages">99.9624464 %ile</div>
                        <div className="studentnames">NITHIL KAVIN M</div>
                      </div>
                    </div>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/student/21354639.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">OLTS</div>
                        <div className="student-percentages">99.9606569 %ile</div>
                        <div className="studentnames">SUBHAM JAIN</div>
                      </div>
                    </div>
                    <div class="item">
                      <div className="toppers-student">
                        <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/student/21671855.png" className="img-fluid d-block mx-auto"></img>
                        <div className="olts-data">OLTS</div>
                        <div className="student-percentages">99.9534023 %ile</div>
                        <div className="studentnames">KETAN SUMAN</div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="result-student-container">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="student-data-title">
                <div className="title">259</div>
                <div className="subtitle">STUDENTS</div>
              </div>
              <div className="student-subitle">
                from Live Classes & OLTS Course scored overall <br className="d-md-none" />99%ile &
                above in the JEE Main 2023 (Session 2)
              </div>
            </div>
          </div>
          <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4">
            {currentResults.map((data) => {
              return (
                <>
                  <div
                    className="col text-center student-updated-data"
                    key={data.id}
                  >
                    <div className="student-picture-wrap">
                      <img
                        src={
                          data.is_Img === "no"
                            ? "https://cdn.allendigital.in/fe-bsite/results/NO_IMAGE_FOUND.png"
                            : `https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/student/${data["fno"]}.png`
                        }
                        alt="temp"
                        className="student-picture"
                      />
                    </div>
                    <div className="olts">{data.Type}</div>
                    <div className="student-percentage">
                      {data.final_Total1} %ile
                    </div>
                    <div className="studentname">{data.cname1}</div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="pagination">
                <button onClick={prevPage} disabled={currentPage === 1}>
                  <span className="d-md-none" style={{ fontSize: "24px", lineHeight: "0px", paddingBottom: "5px" }}>{"‹"}</span> <span className="d-none d-md-flex">PREVIOUS</span>
                </button>
                {getPaginationRange().map((pageNumber, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(pageNumber)}
                    className={currentPage === pageNumber ? "active" : ""}
                  >
                    {pageNumber}
                  </button>
                ))}
                <button
                  onClick={nextPage}
                  disabled={currentPage === totalPages}
                >
                  <span className="d-md-none" style={{ fontSize: "24px", lineHeight: "0px", paddingBottom: "5px" }}>{"›"}</span> <span className="d-none d-md-flex">NEXT</span>
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="talk-to-expert">
                <img
                  src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/jee-main-2023-session-2/talk-to-expert.png"
                  className="img-fluid"
                />
                <div className="div">
                  <div className="title">Talk To Our Expert</div>
                  <div className="connect-wrap">
                    <a href="tel:+ 919513736499" className="connect-btn">
                      <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/jee-main-2023-session-2/call-now.png" />{" "}
                      Call Now
                    </a>
                    <a href="/contact-us" className="connect-btn">
                      <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/jee-main-2023-session-2/message-now.png" />{" "}
                      Drop us a message
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="student-testimonials-container">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div class="result-page-title">
                What Students Say About ALLEN?
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <OwlCarousel {...testimonials}>
                <div class="item testimonials-item">
                  <img
                    src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/testimonial/Swapnil_Chakraborty.png"
                    className="testimonials-picture"
                  />
                  <div className="div">
                    <div className="testimonials-name">Swapnil Chakraborty</div>
                    <div className="testimonials-course-name">
                      96.658%ile
                    </div>
                  </div>
                  <div className="testimonials-content">
                    I am Swapnil Chakraborty, studying with ALLEN Digital was a wonderful experience for me. Faculties did not only provide me with a clear understanding of concepts but also helped me develop problem-solving skills through rigorous practice. They were always available to address any academic doubts. I am immensely grateful to ALLEN Digital for their unwavering support and guidance. I express my sincere gratitude for the invaluable impact ALLEN has had on my academic journey.
                  </div>
                  <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/jee-main-2023-session-2/quote.png" className="testimonials-quote"></img>
                </div>
                <div class="item testimonials-item">
                  <img
                    src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/testimonial/Allam_Ansari.png"
                    className="testimonials-picture"
                  />
                  <div className="div">
                    <div className="testimonials-name">MD Allam Ansari</div>
                    <div className="testimonials-course-name">
                      99.079%ile
                    </div>
                  </div>
                  <div className="testimonials-content">
                    I am very satisfied with my experience with ALLEN Digital over the past year. The teachers were extremely supportive and always available to address our academic queries and consistently motivated us throughout our journey. The test series offered a valuable simulation of the actual exam conditions. Overall, my experience with ALLEN Digital has been highly satisfactory and provided me with the necessary resources, guidance, and preparation for my academic journey.
                  </div>
                  <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/jee-main-2023-session-2/quote.png" className="testimonials-quote"></img>
                </div>
                <div class="item testimonials-item">
                  <img
                    src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/testimonial/Mehul_Sharma.png"
                    className="testimonials-picture"
                  />
                  <div className="div">
                    <div className="testimonials-name">Mehul Sharma</div>
                    <div className="testimonials-course-name">
                      99.761%ile
                    </div>
                  </div>
                  <div className="testimonials-content">
                    My name is Mehul Sharma and I joined ALLEN Digital during Class 12th with the ambition of cracking the JEE exam, I am incredibly grateful to the dedicated faculty at ALLEN Digital who consistently guided me towards this goal. Thanks to their valuable support through which I achieved a remarkable rank in JEE Main 2023. I consider myself fortunate to have received timely guidance from the best faculty members at the institute.<br className="d-none d-lg-block" /><br className="d-none d-lg-block" />
                  </div>
                  <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/jee-main-2023-session-2/quote.png" className="testimonials-quote"></img>
                </div>
                <div class="item testimonials-item">
                  <img
                    src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/images/session_2/testimonial/Anmol_Yadav.png"
                    className="testimonials-picture"
                  />
                  <div className="div">
                    <div className="testimonials-name">Anmol Yadav</div>
                    <div className="testimonials-course-name">
                      99.596%ile
                    </div>
                  </div>
                  <div className="testimonials-content">
                    I would like to express my sincere gratitude, so I am sharing my JEE preparation experience with ALLEN Digital. Without any doubt, they exceeded my expectations and fulfilled all of my needs. I never felt isolated at home because all of my teachers and the entire faculty gave their utmost effort. I extend my heartfelt thanks to the entire system at ALLEN Digital and, in particular, to my teachers for making my journey smoother and unforgettable.<br className="d-none d-lg-block" /><br className="d-none d-lg-block" />
                  </div>
                  <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/jee-main-2023-session-2/quote.png" className="testimonials-quote"></img>
                </div>
              </OwlCarousel>
            </div>
          </div>

          {/* <div className="row mt-5">
            <div className="col-12">
              <img
                className="img-fluid d-block mx-auto"
                src="https://cdn.allendigital.in/fe-bsite/result_page/desktop_result_banner.png"
              ></img>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
