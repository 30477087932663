import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const OltsPageSlider = () => {
  let oltsSlider = false;
  if (window.location.href.includes("online-test-series")) {
    oltsSlider = true;
  } else {
    oltsSlider = false;
  }

  return (
    <div>
      {oltsSlider ? (
        <>
          <OwlCarousel
            id="Desktopbannerslider"
            items="1"
            autoplay="true"
            loop="true"
            autoplayTimeout="3000"
            autoplayHoverPause="true"
            item="1"
            dots="false"
          >
            <div className="bannerslider">
              <a href="https://allen.in/online-test-series">
                <img
                  src="https://cdn.allendigital.in/fe-bsite/olts_page_slider/OLTS_SLIDER_JEE_3.png"
                  alt="banner"
                ></img>
              </a>
            </div>
            {/* <div className='bannerslider'>
                                <a href='/online-test-series/?qTjSkUwI=--a/a--a/a--/e-e////e--e////e-e/filters/e-e////e--e////e-e/://c---c////c---c///e-e////e--e////e-e/cfa4920e8d54af8d443e1e0adedab0da/e-e////e--e////e-e////d-d///,//c---c///e-e////e--e////e-e/9e96a27920df31ca1dbba9f3e638db40/e-e////e--e////e-e////d-d//////d-d///,/e-e////e--e////e-e/sort/e-e////e--e////e-e/:/e-e////e--e////e-e//e-e////e--e////e-e//b-b/b-b/'>
                                    <img src="https://cdn.allendigital.in/fe-bsite/olts_page_slider/OLTS_SLIDER_NEET_3.png" alt='banner'></img>
                                </a>
                            </div> */}
            {/* <div className='bannerslider'>
                            <a href='/online-test-series/?qTjSkUwI=--a/a--a/a--/e-e////e--e////e-e/filters/e-e////e--e////e-e/://c---c////c---c///e-e////e--e////e-e/cfa4920e8d54af8d443e1e0adedab0da/e-e////e--e////e-e////d-d///,//c---c///e-e////e--e////e-e/f7b69b50934c41b85901960dbe46d049/e-e////e--e////e-e/,/e-e////e--e////e-e/c3d2d5cef423752d9711787418c75c47/e-e////e--e////e-e/,/e-e////e--e////e-e/82c0317fe4017ab6c4a6d3a0eb3681cf/e-e////e--e////e-e/,/e-e////e--e////e-e/4321209ca12ee81a1ad6a69c26b3776e/e-e////e--e////e-e/,/e-e////e--e////e-e/9da1037efcd71c1b24ac57225d1b2785/e-e////e--e////e-e////d-d//////d-d///,/e-e////e--e////e-e/sort/e-e////e--e////e-e/:/e-e////e--e////e-e//e-e////e--e////e-e//b-b/b-b/'>
                                <img src="https://cdn.allendigital.in/fe-bsite/olts_page_slider/OLTS_SLIDER_PNCF_3.png"  alt='banner'></img>
                            </a>
                        </div> */}
          </OwlCarousel>

          <OwlCarousel
            id="Tabbannerslider"
            items="1"
            autoplay="true"
            loop="true"
            autoplayTimeout="3000"
            autoplayHoverPause="true"
            item="1"
            dots="true"
          >
            <div className="bannerslider">
              <a href="https://allen.in/online-test-series">
                <img
                  src="https://cdn.allendigital.in/fe-bsite/olts_page_slider/OLTS_SLIDER_JEE_2.png"
                  alt="banner"
                ></img>
              </a>
            </div>
            {/* <div className='bannerslider'>
                                <a href='/online-test-series/?qTjSkUwI=--a/a--a/a--/e-e////e--e////e-e/filters/e-e////e--e////e-e/://c---c////c---c///e-e////e--e////e-e/cfa4920e8d54af8d443e1e0adedab0da/e-e////e--e////e-e////d-d///,//c---c///e-e////e--e////e-e/9e96a27920df31ca1dbba9f3e638db40/e-e////e--e////e-e////d-d//////d-d///,/e-e////e--e////e-e/sort/e-e////e--e////e-e/:/e-e////e--e////e-e//e-e////e--e////e-e//b-b/b-b/'>
                                    <img src="https://cdn.allendigital.in/fe-bsite/olts_page_slider/OLTS_SLIDER_NEET_2.png" alt='banner'></img>
                                </a>
                            </div> */}
            {/* <div className='bannerslider'>
                            <a href='/online-test-series/?qTjSkUwI=--a/a--a/a--/e-e////e--e////e-e/filters/e-e////e--e////e-e/://c---c////c---c///e-e////e--e////e-e/cfa4920e8d54af8d443e1e0adedab0da/e-e////e--e////e-e////d-d///,//c---c///e-e////e--e////e-e/f7b69b50934c41b85901960dbe46d049/e-e////e--e////e-e/,/e-e////e--e////e-e/c3d2d5cef423752d9711787418c75c47/e-e////e--e////e-e/,/e-e////e--e////e-e/82c0317fe4017ab6c4a6d3a0eb3681cf/e-e////e--e////e-e/,/e-e////e--e////e-e/4321209ca12ee81a1ad6a69c26b3776e/e-e////e--e////e-e/,/e-e////e--e////e-e/9da1037efcd71c1b24ac57225d1b2785/e-e////e--e////e-e////d-d//////d-d///,/e-e////e--e////e-e/sort/e-e////e--e////e-e/:/e-e////e--e////e-e//e-e////e--e////e-e//b-b/b-b/'>
                                <img src="https://cdn.allendigital.in/fe-bsite/olts_page_slider/OLTS_SLIDER_PNCF_2.png"  alt='banner'></img>
                            </a>
                        </div> */}
          </OwlCarousel>

          <OwlCarousel
            id="Mobilebannerslider"
            items="1"
            autoplay="true"
            loop="true"
            autoplayTimeout="3000"
            autoplayHoverPause="true"
            item="1"
            dots="true"
          >
            <div className="bannerslider">
              <a href="https://allen.in/online-test-series">
                <img
                  src="https://cdn.allendigital.in/fe-bsite/olts_page_slider/OLTS_SLIDER_JEE_1.png"
                  alt="banner"
                ></img>
              </a>
            </div>
            {/* <div className='bannerslider'>
                                <a href='/online-test-series/?qTjSkUwI=--a/a--a/a--/e-e////e--e////e-e/filters/e-e////e--e////e-e/://c---c////c---c///e-e////e--e////e-e/cfa4920e8d54af8d443e1e0adedab0da/e-e////e--e////e-e////d-d///,//c---c///e-e////e--e////e-e/9e96a27920df31ca1dbba9f3e638db40/e-e////e--e////e-e////d-d//////d-d///,/e-e////e--e////e-e/sort/e-e////e--e////e-e/:/e-e////e--e////e-e//e-e////e--e////e-e//b-b/b-b/'>
                                    <img src="https://cdn.allendigital.in/fe-bsite/olts_page_slider/OLTS_SLIDER_NEET_1.png" alt='banner'></img>
                                </a>
                            </div> */}
            {/* <div className='bannerslider'>
                            <a href='/online-test-series/?qTjSkUwI=--a/a--a/a--/e-e////e--e////e-e/filters/e-e////e--e////e-e/://c---c////c---c///e-e////e--e////e-e/cfa4920e8d54af8d443e1e0adedab0da/e-e////e--e////e-e////d-d///,//c---c///e-e////e--e////e-e/f7b69b50934c41b85901960dbe46d049/e-e////e--e////e-e/,/e-e////e--e////e-e/c3d2d5cef423752d9711787418c75c47/e-e////e--e////e-e/,/e-e////e--e////e-e/82c0317fe4017ab6c4a6d3a0eb3681cf/e-e////e--e////e-e/,/e-e////e--e////e-e/4321209ca12ee81a1ad6a69c26b3776e/e-e////e--e////e-e/,/e-e////e--e////e-e/9da1037efcd71c1b24ac57225d1b2785/e-e////e--e////e-e////d-d//////d-d///,/e-e////e--e////e-e/sort/e-e////e--e////e-e/:/e-e////e--e////e-e//e-e////e--e////e-e//b-b/b-b/'>
                                <img src="https://cdn.allendigital.in/fe-bsite/olts_page_slider/OLTS_SLIDER_PNCF_1.png"  alt='banner'></img>
                            </a>
                        </div> */}
          </OwlCarousel>
        </>
      ) : (
        <>
          <OwlCarousel
            id="Desktopbannerslider"
            items="1"
            autoplay="true"
            loop="true"
            autoplayTimeout="3000"
            autoplayHoverPause="true"
            item="1"
            dots="false"
          >
            <div className="bannerslider">
              <a href="https://allen.in/ultimate-program-live-courses?utm_source=digitalweb&utm_medium=buycourseslider&utm_campaign=onlinecourse">
                <img
                  src="https://cdn.digital.allen.ac.in/fe-bsite/olts_page_slider/your-dream-desktop.jpg"
                  alt="banner"
                ></img>
              </a>
            </div>
          </OwlCarousel>

          <OwlCarousel
            id="Tabbannerslider"
            items="1"
            autoplay="true"
            loop="true"
            autoplayTimeout="3000"
            autoplayHoverPause="true"
            item="1"
            dots="true"
          >
            <div className="bannerslider">
              <a href="https://allen.in/ultimate-program-live-courses?utm_source=digitalweb&utm_medium=buycourseslider&utm_campaign=onlinecourse">
                <img
                  src="https://cdn.digital.allen.ac.in/fe-bsite/olts_page_slider/your-dream-tablet.jpg"
                  alt="banner"
                ></img>
              </a>
            </div>
          </OwlCarousel>

          <OwlCarousel
            id="Mobilebannerslider"
            items="1"
            autoplay="true"
            loop="true"
            autoplayTimeout="3000"
            autoplayHoverPause="true"
            item="1"
            dots="true"
          >
            <div className="bannerslider">
              <a href="https://allen.in/ultimate-program-live-courses?utm_source=digitalweb&utm_medium=buycourseslider&utm_campaign=onlinecourse">
                <img
                  src="https://cdn.digital.allen.ac.in/fe-bsite/olts_page_slider/your-dream-mobile.jpg"
                  alt="banner"
                ></img>
              </a>
            </div>
          </OwlCarousel>
        </>
      )}
    </div>
  );
};

export default OltsPageSlider;
