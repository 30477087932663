import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import highlighticon from '../../images/highlighticon.png'

const NsoResult = () => {
    const [resultData, set_ResultData] = useState([])
    const [resultDataLevel2, set_ResultDataLevel2] = useState([])
    function getResultData() {
        if (window?.NSO) {
            // set data
            set_ResultData(window?.NSO)
            set_ResultDataLevel2(window?.NSOLEVEL2)
        } else {
            setTimeout(() => {
                getResultData();
            }, 100)
        }
    }
    useEffect(() => {
        getResultData()
    }, [])

    const sharetab = (e) => {
        document.querySelector("#root > div > div > div:nth-child(3) > div > div.share-wrapper > i").classList.toggle("active")
        document.querySelector("#root > div > div > div:nth-child(3) > div > div.share-wrapper > ul").classList.toggle("active")
    }

    return (
        <div>
            <div className="share-wrapper">
                <i className="fa fa-share-alt share" onClick={sharetab}></i>
                <ul className="social">
                    <li id="desktopwhatsappicon"><a href={`https://web.whatsapp.com/send?text=${window.location.href}`} target="_blank" title="whatsapp"><i className="fa fa-whatsapp whatsapp"></i></a></li>
                    <li id="mobilewhatsappicon"><a href={`https://web.whatsapp.com/send?text=${window.location.href}`} target="_blank" title="whatsapp"><i className="fa fa-whatsapp whatsapp"></i></a></li>
                    <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" title="facebook"><i className="fa fa-facebook-official facebook"></i></a></li>
                    <li><a href={`https://twitter.com/intent/tweet?url=${window.location.href}`} target="_blank" title="twitter"><i className="fa fa-twitter twitter"></i></a></li>
                    <li><a href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`} target="_blank" title="linkedin"><i className="fa fa-linkedin linkedin"></i></a></li>
                    {/* <li><a href="#" target="_blank" title="google"><i className="fa fa-google-plus google"></i></a></li> */}
                    {/* <li><a href="#" target="_blank" title="instagram"><i className="fa fa-instagram instagram"></i></a></li> */}

                </ul>
            </div>
            <Helmet>
                {document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
                <title>NSO (National Science Olympiad) Result | ALLEN Digital</title>
                <meta name="description" content="ALLEN Digital has produced amazing NSO (National Science Olympiad) results. Witness the remarkable performance of our top-performing students." />
                <meta property="og:title" content="NSO (National Science Olympiad) Result | ALLEN Digital" />
                <meta property="og:site_name" content="ALLEN Digital" />
                <meta property="og:url" content="https://digital.allen.ac.in/results/olympiad/nso" />
                <meta property="og:description" content="ALLEN Digital has produced amazing NSO (National Science Olympiad) results. Witness the remarkable performance of our top-performing students." />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://digital.allen.ac.in/static/media/logo-new-r.4a9d1fde3fd2a6868a621b27352bda3a.svg" />
                {<link rel="canonical" href={window.location.origin + window.location.pathname} />}
            </Helmet>
            <div className='container'>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "4%" }}>
                    <img src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/resultheading11.png" className="resultheadingimg" style={{ margin: "auto", width: "35%" }} alt="resultHeading"></img>
                </div>
                <h2 className="text-center font-weight-bold mb-4 resultmaintextcentered">Meet our ALLEN Digital Champions of<br></br> <strong style={{ color: "#046635" }}>Olympiad</strong> in the Very First Year</h2>
                <div className="text-center result-tabs">
                    <ul class="nav nav-tabs mb-4 d-inline-flex" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="nsoone-tab" data-toggle="tab" data-target="#nsoone" type="button" role="tab" aria-controls="nsoone" aria-selected="true">Level-1</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="nsotwo-tab" data-toggle="tab" data-target="#nsotwo" type="button" role="tab" aria-controls="nsotwo" aria-selected="false">Level-2</button>
                        </li>
                    </ul>
                </div>
                <div class="tab-content" id="myTabContent" style={{ background: "transparent", padding: "0px", boxShadow: "none" }}>
                    <div class="tab-pane fade show active" id="nsoone" role="tabpanel" aria-labelledby="nsoone-tab">
                        <div className='highlightstabular'>
                            <div className='highlightheading'>

                                <span style={{ color: "#f5eca3" }}>NSO (National Science Olympiad) Level-1 Conducted by SOF</span>

                            </div>
                            <div className='highlightcontent'>

                                <div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'> 14 Top 30 Ranks</span></div>
                                <div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'> 30 Top 50 Ranks</span></div>
                                <div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'> 54 Top 100 Ranks</span></div>
                                <div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'> 215 Students Qualified for Level-2</span></div>

                            </div>
                        </div>
                        <div className='card-main'>
                            {
                                resultData.map((data) => {
                                    return (
                                        <>
                                            <div className='result-card'>

                                                <img src={`https://cdn.digital.allen.ac.in/fe-bsite/results/olympiad/nso/${data.Form_no}.png`} alt='temp' className='resultimages' style={{ borderRadius: "20px" }}></img>
                                                <div>
                                                    <span className='percentage'>IR {data.Ir}</span><br />
                                                    <span className='resstudentname'>{data.Student_Name}</span><br />
                                                    <span className='adfno'>(Class {data.Class})</span>
                                                </div>
                                            </div>

                                        </>
                                    )
                                })
                            }


                        </div>
                    </div>
                    <div class="tab-pane fade" id="nsotwo" role="tabpanel" aria-labelledby="nsotwo-tab">
                        <div className='highlightstabular'>
                            <div className='highlightheading'>

                                <span style={{ color: "#f5eca3" }}>NSO (National Science Olympiad) Level-2 Conducted by SOF </span>

                            </div>
                            <div className='highlightcontent'>

                                <div className='highlightsdata'><i className='highlightsdataicon'><img src={highlighticon}></img></i><span className='highlightsdaratext'>4 Top 100 International Ranks</span></div>

                            </div>
                        </div>
                        <div className='card-main'>
                            {
                                resultDataLevel2.map((data) => {
                                    return (
                                        <>
                                            <div className='result-card'>

                                                <img src={`https://cdn.digital.allen.ac.in/fe-bsite/results/olympiad/nsolevel2/${data.Form_no}.png`} alt='temp' className='resultimages' style={{ borderRadius: "20px" }}></img>
                                                <div>
                                                    <span className='percentage'>IR {data.Ir}</span><br />
                                                    <span className='resstudentname'>{data.Student_Name}</span><br />
                                                    <span className='adfno'>(Class {data.Class})</span>
                                                </div>
                                            </div>

                                        </>
                                    )
                                })
                            }


                        </div>
                    </div>
                </div>

                {/* <img className='mainresultimagebig' src={gg}></img> */}
                {/* <div>
                    <img className='jeemainresultfooter' src="https://cdn.digital.allen.ac.in/fe-bsite/result_page/desktop_result_banner.png" style={{width:"100%", marginTop:"50px"}}></img>
                </div> */}
            </div>
        </div>
    );
};

export default NsoResult;