import React, { useState } from "react";
import $ from "jquery";
import "bootstrap";
import { Link } from "react-router-dom";
import { encode as base64_encode } from "base-64";
// import { environment } from "../../Services/HttpData";
import { toast } from "react-toastify";
import iit from "../../images/iit-main.jpg";
import CourseCardGrid from "./CourseCardGrid";
import CourseCardList from "./CourseCardList";
import lowToHigh from "../../images/arrow_upward_black_24dp.svg";
import nocourse from "../../images/no-course.png";
import searchImg from "../../images/search_white_24dp.svg";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);
const AllCourses = (props) => {
  ////console.log(props.handelEvent.data.records);
  const [showHideGrid, setshowHideGrid] = useState(true);
  const [showHideList, setshowHideList] = useState(false);
  const [searchName, setSearchName] = useState();
  const listItems = props.handelEvent.data.records
    ? props.handelEvent.data.records
    : [];
  const componentDidMount = (props) => {
    $(function () {
      $('[data-toggle="popover"]').popover({
        html: true,
        content: function () {
          return $(".hovercard").html();
        },
      });
    });
  };
  const componentWillReceiveProps = (props) => {
    // //console.log(props.handelEvent.data);
    this.setState({
      listItems: props.handelEvent.data,
      filtersUsed: props.handelEvent.filtersUsed,
      slug: props.handelEvent.slug,
    });
  };

  const viewlist = (name) => {
    switch (name) {
      case "grid":
        setshowHideGrid(true);
        setshowHideList(false);
        break;
      case "list":
        setshowHideGrid(false);
        setshowHideList(true);
        break;
      default:
    }
    ////console.log('asdasd');
  };
  const getPercantage = (price, total) => {
    return 100 - (price / total) * 100;
  };
  const addToCart = (productId) => {
    //alert(productId);
    const auth_token = localStorage.getItem("apiAccessToken")
      ? localStorage.getItem("apiAccessToken")
      : "";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + auth_token,
      },
      body: JSON.stringify({ product_id: productId }),
    };
    fetch(environment.ecomApiUrl + "cart/item/add", requestOptions).then(
      (resp) => {
        // fetching api url for courses listing
        resp.json().then((result) => {
          //setProduct(result.data);  // set result in state this will set current/updated value of setProduct to product
          ////console.log(product);
          window.location = "/ecom/cart";
        });
      }
    );
  };

  const filterStatus = (e) => {
    const sorting = e.target.value;
    // alert(sorting);
    // console.log(props?.filters?.filters, "test")
    let filtersArray = [];
    props?.filters?.filters?.map((val, ind) => {
      filtersArray = [...filtersArray, ...val]
    })
    props.onChange({
      filters: props?.filters?.filters ? filtersArray : [],
      sort: sorting,
      /* "page" : 1,
      "limit" : 2 */
    });
  };
  /* Handle Event */
  const handleEvent = (e) => {
    const courseName = e.target.value;
    setSearchName(courseName);
  };
  /* Search Course */
  const searchCourse = (e) => {
    e.preventDefault();
    props.onSearch(searchName);
  };

  /* const { showHideGrid, showHideList, listItems, filtersUsed } = this.state; */
  return (
    <>
      <main
        role="main"
        className=" col-lg-9 col-md-12 col-sm-12 px-4 pt-4 all-courses"
      >
        {/* <div className="row mx-0 mt-3">
            {filtersUsed.map((item) => {
              return (
                <button
                  type="button"
                  className="btn btn-outline-green-no-width px-4 mr-3 py-1 font-weight-bold"
                  key={item.type}
                >
                  {item?.title}
                  <span className="badge"></span>
                </button>
              );
            })}
          </div> */}
        <div className="d-flex justify-content-between flex-md-nowrap align-items-center pt-2 pb-2">
          {/*  <h3 className="h3 mb-0">All {this.state.slug==="live-courses"?"Live Courses":this.state.slug==="recoded-lectures"?"Recoded Lectures":"Courses"}</h3> */}
          <div className="m-0 align-items-center sortfunctionality">
            <p className="mb-0 sort-by-label">Sort By:</p>
            <select
              name=""
              id=""
              className="sort-list filters-clear-btn"
              defaultValue={""}
              onChange={filterStatus}
            >
              <option value="">Recent</option>
              <option value="price-lowtohigh">Price Low To High</option>
              <option value="price-hightolow">Price High To Low</option>
            </select>
            <i
              className={
                showHideGrid
                  ? "active fas fa-th changeview mx-3"
                  : "fas fa-th changeview m-3"
              }
              onClick={() => viewlist("grid")}
            ></i>
            <i
              className={
                !showHideGrid
                  ? "active fas fa-list changeview mr-3"
                  : "fas fa-list changeview mr-3"
              }
              onClick={() => viewlist("list")}
            ></i>
          </div>
          <form className="search-filter-form" onSubmit={searchCourse}>
            <input
              type="text"
              className="sort-list"
              name="searchName"
              onChange={handleEvent}
              value={searchName}
            />
            <span onClick={searchCourse} style={{ cursor: "pointer" }}>
              <i className="fa-solid fa-magnifying-glass"></i>
            </span>
          </form>
        </div>

        {showHideGrid && (
          <div className="row m-0">

            {listItems.length ? (
              listItems.map((item) => {
                return (
                  <div className=" col-xl-4 col-lg-4  my-2 col-12 col-md-6 p-2">
                    <CourseCardGrid
                      key={item.id}
                      productId={item.id}
                      image={item.image}
                      slug={item.slug}
                      title={item.title}
                      price={item.price}
                      display_price={item.display_price}
                      description={item.description}
                      is_freemium={item.is_freemium}
                      commencement_date={item.commencement_date}
                      courseData={item}
                    />
                  </div>
                );
              })
            ) : (
              <div
                className="justify-content-center d-flex mx-auto"
                style={{ margin: "auto" }}
              >
                <div className="text-center">
                  <img src={nocourse} className=""></img>
                  <p className="m-1">
                    <b>No Course Found</b>
                  </p>
                </div>
              </div>
            )}
          </div>
        )}

        {showHideList && (
          <div>
            {listItems.map((item) => {
              return (
                <CourseCardList
                  key={item.id}
                  productId={item.id}
                  image={item.image}
                  slug={item.slug}
                  title={item.title}
                  price={item.price}
                  display_price={item.display_price}
                  description={item.description}
                  is_freemium={item.is_freemium}
                  commencement_date={item.commencement_date}
                />
              );
            })}
          </div>
        )}
      </main>
    </>
  );
};

export default AllCourses;
