import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import EcomCourseBox from "./EcomCourseBox";
// import { environment } from "../../Services/HttpData";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);

const EcomMyCourseList = () => {
	const [myCourseMap, setMyCourseMap] = useState([]);
	const [loader, setLoader] = useState(true);

	const getMyCourse = async () => {
		const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "";
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Basic " + auth_token,
			},
		};
		fetch(environment.ecomApiUrl + "student/my-admissions", requestOptions).then(response => {
			response.json().then(result => {
				setMyCourseMap(result.data.records);

				setLoader(false);
			});
		});
	};

	useEffect(() => {
		getMyCourse();
	}, []);

	return (
		<>
			<div className='loader-wrapper'>
				<TailSpin
					height='120'
					width='120'
					color='#4fa94d'
					ariaLabel='tail-spin-loading'
					radius='1'
					wrapperStyle={{}}
					wrapperClass=''
					visible={loader}
				/>
			</div>

			{myCourseMap.map(value => {
				return (
					<>
						<EcomCourseBox
							courseName={value.course_name}
							netAmount={value.net_deposit_amount}
							date={value?.commencement_date}
							key={value.id}
							img={value.image}
							course_slug={value.slug}
						/>
					</>
				);
			})}
		</>
	);
};

export default EcomMyCourseList;
