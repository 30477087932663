import React, { PureComponent } from 'react';
import ImageComingSoon from "../../images/coming-soon.jpg";
import ImageComingSoonMb from "../../images/coming-soon-mobile.jpg";
import ImgBus from "../../images/bus.png";
import ImgLR from "../../images/screenshot.png";
import ImgNU from "../../images/asdasd.png";
// import { environment } from '../../Services/HttpData';
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class HomeArticals extends React.Component {

    constructor(props) {
        super(props);
      //   //console.log(this.props);
        this.state = {
          PageContent: [],
        };
      }
      componentWillReceiveProps(props){
        //   //console.log(props.handelEvent);
          let article={}
          article.title=props.handelEvent.title;
          article.content=[];
          props.handelEvent.content.map((item) => {
              if (item.status === 1) article.content.push(item);
            });
            // //console.log('Article',article);
        this.setState({PageContent: article});
      }

    render() { 
        return <>
        <div className="container articles d-none">
        <h2 className="text-center mt-5 font-weight-bold">{this.state.PageContent?.title}</h2>
        <div className="light-content">
        <p className="text-center mt-5">{this.state.PageContent?.subtitle}</p>

        <div className="row my-5">
        {this.state.PageContent?.content?.length !== 0 &&
                  this.state.PageContent?.content?.map((item) => (
            <div key={item.id} className="col-12 col-lg-4">
                <div className="card mb-4" style={{backgroundColor: "#F5F2E9"}}>
                    <img src={item?.image
                          ? environment.baseUrlImg +
                            item?.image : ImgBus} alt="" className="img-fluid"/>
                    <div className="p-2">
                        <h5>{item.title}</h5>
                        <p>{item.description}</p>
                        <a className="read-more-link" href="#">&gt;&gt; Read More</a>
                    </div>
                </div>
            </div>
            ))}
            {/* <div className="col-12 col-lg-4">
                <div className="card mb-4">
                    <img src={ImgLR} alt="" className="img-fluid"/>
                    <div className="p-2">
                        <h5>Learning Resources</h5>
                        <p>Getting involved matters. Crucial causes to the if ever, come from passive action.</p>
                        <a className="read-more-link" href="#">&gt;&gt; Read More</a>
                    </div>                    
                </div>
            </div>
            <div className="col-12 col-lg-4">
                <div className="card mb-4" style={{backgroundColor: "#F5F2E9"}}>
                    <img src={ImgNU} alt="" className="img-fluid"/>
                    <div className="p-2">
                        <h5>News & Updates</h5>
                        <p>Getting involved matters. Crucial causes to the if ever, come from passive action.</p>
                        <a className="read-more-link" href="#">&gt;&gt; Read More</a>
                    </div>
                </div>
            </div> */}
        </div>

        <div className="text-center">
            <button style={{width: "250px"}} type="button" className="btn btn-primary nav-btn">View All Resources</button>
        </div>
        <img src={ImageComingSoon} className="img-fluid coming-soon" alt=""/>
        <img src={ImageComingSoonMb} className="img-fluid coming-soon-mobile" alt=""/>
        </div>
    </div>
        </>;
    }
}
 
export default HomeArticals;