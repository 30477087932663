import React, { useState } from "react";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ResendOtp from "../Resendotp/ResendOtp";
import { toast, ToastContainer } from "react-toastify";
import Logo from "../../images/logo-new-r.svg";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import MixpanelInit from "../Mixpanel/MixpanelInit";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function Login(props) {
	const history = useHistory();
	const [mobileNo, setMobileNo] = useState({ country_code: "", mobile: "", lead_type: 911, value: "" });
	const [OTPNo, setOTPNo] = useState("");
	const [isSendOtp, setIsSendOtp] = useState(true);
	const [isCheck, setIsCheck] = useState(false);
	const [isOTPButtonDisable, setOTPButtonDisable] = useState(true);
	const [IsOTPScreen, setIsOTPScreen] = useState(false);
	const [IsOtpSendButton, setOtpSendButton] = useState(false)
	const previous_Path = history?.location?.state?.from ? history?.location?.state?.from : "/"

	const sendOtpApi = (fieldName, value, country_code) => {
		if (!value) {
			setOtpSendButton(true)
		}
		if (!isCheck) {
			toast.warning("Please accept Terms & Conditions and Privacy Policy");
			setOtpSendButton(false)
			return false;
		}
		if (mobileNo.mobile.length < 10 || !mobileNo) {
			toast.warning("Please Enter your valid mobile number");
			setOtpSendButton(false)
			return false;
		}
		//
		CallOTPApi(value);

	};
	const CallOTPApi = (value) => {

		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var requestOptions = {
			method: "POST",
			body: JSON.stringify(mobileNo),
			headers: myHeaders,
		};
		fetch(process.env.REACT_APP_ECOM_URL + "otp/otp_generate", requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result?.replyCode === "success") {
					if (!value) {
						MixpanelInit("Track", "otp_requested", { "phone_number": mobileNo?.mobile })
					} else {
						MixpanelInit("Track", "otp_resent", { "phone_number": mobileNo?.mobile })
					}
					if (isSendOtp && IsOTPScreen) setIsSendOtp(false);
					if (!IsOTPScreen) setIsOTPScreen(true);
					toast.success(result?.replyMsg);
				}
				setOtpSendButton(false)
			})
			.catch(error => {
				setOtpSendButton(false)
				console.error("error", error)
			});
	};
	const OTPVerifyApiCall = () => {
		setOTPButtonDisable(true);
		if (OTPNo?.length < 6) {
			toast.warning("Please Enter valid OTP number");
			setOTPButtonDisable(false);
			return false;
		}
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var requestOptions = {
			method: "POST",
			body: JSON.stringify({
				student_mobile_number: mobileNo?.mobile,
				student_otp: OTPNo,
			}),
			redirect: "follow",
			headers: myHeaders,
		};

		fetch(process.env.REACT_APP_LOGIN_URL + "auth/auth-plus", requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result?.status !== "failed") {
					MixpanelInit("Track", "otp_verified", { "phone_number": mobileNo?.mobile })
					if (result?.data?.key === "profile_pending") {
						toast.warning(result?.message);
					} else {
						toast.success(result?.message);
					}
					setOTPButtonDisable(false);
					if (result?.data?.key === "sign_up" || result?.data?.key === "profile_pending") {
						history.push({ pathname: "/sign-up", state: { data: mobileNo, token: result?.data?.token, previous_Path: previous_Path, mobile_no: mobileNo?.mobile } });
					} else {
						history.push({ pathname: "/user-details", state: { data: result?.data?.data, token: result?.data?.token, previous_Path: previous_Path, mobile_no: mobileNo?.mobile } });
					}

				} else {
					MixpanelInit("Track", "otp_failed", { "error_message": result?.message })
					toast.error(result?.message);
					setOTPButtonDisable(false);
				}

			})
			.catch(error => {
				console.log("error", error);
				setOTPButtonDisable(false);
			});
	};

	useEffect(() => {
		MixpanelInit("Track", "login_initiated", "")
	}, [])

	return (
		<>

			<Helmet>
				{document.querySelectorAll("meta[type=homePage]")[0]?.remove()}
				<link rel="canonical" href={window.location.origin + window.location.pathname} />
				<title>Login/ Sign Up | ALLEN Digital</title>
				<meta name='description' content="Login or Sign Up with ALLEN Digital!" />
				<meta property="og:title" content="Login/ Sign Up | ALLEN Digital" />
				<meta property="og:site_name" content="ALLEN Digital" />
				<meta property="og:url" content="https://digital.allen.ac.in/login" />
				<meta property="og:description" content="Login or Sign Up with ALLEN Digital!" />
				<meta property="og:type" content="website" />
				<meta property="og:image" content="https://digital.allen.ac.in/static/media/logo-new-r.4a9d1fde3fd2a6868a621b27352bda3a.svg" />
			</Helmet>
			<div className='container h-100 plus-container-padding login-container-wrapper'>
				<div className='row justify-content-center align-items-center width-100'>
					<div className='col col-sm-9 col-md-7 col-lg-5 col-xl-4 card p-4 m-0'>
						<Link className='m-auto' to='/'>
							<img src={Logo} alt='' className='img-fluid' />
						</Link>
						<div className='m-auto pt-3'></div>
						{!IsOTPScreen ? (
							<div className='pt-3'>
								<div>
									<h6>Enter your mobile number</h6>
									<PhoneInput
										inputStyle={{ color: "#046635", width: "100%" }}
										buttonStyle={{}}
										country={"in"}
										onlyCountries={["in"]}
										countryCodeEditable={false}
										value={mobileNo?.value}
										onChange={(e, t) => {
											setMobileNo({
												country_code: t?.dialCode,
												mobile: e?.slice(t?.dialCode?.length),
												lead_type: 911,
												value: e,
											});
										}}
									/>
								</div>
								<div>
									<p className="terms">
										<input
											type='Checkbox'
											name='t&C'
											value={isCheck}
											onChange={e => setIsCheck(e.target.checked)}
											style={{
												float: "left",
												margin: "7.5px",
												height: "13px",
												width: "13px",
											}}
										/>
										I accept {" "}
										<a href='t&c' target='_blank'>Terms & Conditions</a>
										{" "}
										and{" "}
										<a href='policies' target='_blank'>Privacy Policy</a>
									</p>
								</div>
								<button disabled={IsOtpSendButton} className='btn btn-success form-control mt-3' onClick={sendOtpApi}>
									Submit
								</button>


							</div>
						) : (
							<>
								<div className='m-auto'>
									<h6>Enter OTP</h6>
								</div>
								<div className='m-auto'>
									<p style={{ fontSize: "10px !important" }}>OTP has been sent to your mobile Number</p>
								</div>
								<div className='m-auto text-success'>
									<h6>
										+{mobileNo.value[0]}
										{mobileNo.value[1]} *******{mobileNo[9]}
										{mobileNo.value[10]}
										{mobileNo.value[11]}
									</h6>
								</div>
								<div className='m-auto'>
									<OtpInput
										value={OTPNo}
										onChange={e => {
											setOTPNo(e);
											if (e?.length > 5) {
												setOTPButtonDisable(false);
											} else {
												setOTPButtonDisable(true);
											}

										}}
										numInputs={6}
										separator={<span style={{ width: "8px" }}></span>}
										isInputNum={true}
										shouldAutoFocus={true}
										inputStyle={{
											border: "1px solid #00582a",
											borderRadius: "8px",
											width: "35px",
											height: "35px",
											fontSize: "12px",
											color: "#00582a",
											fontWeight: "400",
											caretColor: "#00582a",
										}}
										focusStyle={{
											border: "1px solid #00582a",
											// outline: "none",
										}}
									/>
								</div>
								<div className='m-auto'></div>
								<button
									className='btn btn-success form-control mt-3'
									disabled={isOTPButtonDisable}
									onClick={OTPVerifyApiCall}>
									Submit
								</button>
								<div className='mt-2'>
									{isSendOtp ? (
										<div className='m-auto'>
											<span className='pt-3'>
												Don't receive OTP ?{" "}
												<span className='text-success cursor-pointer' style={{ cursor: "pointer" }} onClick={CallOTPApi}>
													Resend
												</span>
											</span>
										</div>
									) : (
										<ResendOtp
											fieldName={"phone"}
											value={mobileNo}
											country_code={""}
											resendotpFunction={sendOtpApi}
											TypeButton={"Login"}
										/>
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
