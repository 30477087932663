import React from "react";
import BannerImage from "../../images/banner-img.png";
// import { environment } from "../../Services/HttpData";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class HomeBanner extends React.Component {
  constructor(props) {
    super(props);
    // //console.log(this.props);
    this.state = {
      PageContent: {},
      examsList:[]
    };
  }
  componentWillReceiveProps(props){
      // //console.log(props);
      this.setState({PageContent: props.handelEvent,examsList:props.exams});
  }
  render() {
    // //console.log(this.state.PageContent);
    // //console.log(environment.baseUrl);
    return (
      <>
        <div className="main-banner">
          <div className="container d-sm-flex flex-wrap align-items-center py-5">
            <div className="row align-items-center">
              <div className="col-12 col-sm-12 col-md-7">
                <h1 className="mb-2 font-weight-bold home-banner-title">
                  {/* {this.state.PageContent?.title} */}
                  India Ka Sabse Favourite Career Coach.
                  <span style={{position: "absolute", fontSize: "initial"}}>™</span>
                </h1>
                 <h6 className="mb-4" style={{fontSize: "20px"}}>{this.state.PageContent?.subtitle}</h6> 
                {/* <h6 className="mb-4  home-banner-title">Career Oriented Academic  Excellence <br/ >  now at a click of button</h6> */}
                <div className="d-flex justify-content-start mb-4 home-banner-courses">
                {
                  this.state.examsList?.map((item,i) => (
                  <>
                  {
                    i == 0 ?null:<span className="ml-1 mr-2">|</span>
                  }
                  <a key={item.id} className="mr-1 banner-courses" href={"/exam/"+item.slug}>
                    {item.title}
                  </a>
                    
                  </>
                  )
                )}
                  {/* <a className="mr-3" href="#">
                    NEET
                  </a>
                  <a className="mr-3" href="#">
                    Class 9, 10
                  </a>
                  <a className="mr-3" href="#">
                    CA Foundation
                  </a>
                  <a className="mr-3" href="#">
                    CMA
                  </a>
                  <a className="mr-3" href="#">
                    RAS
                  </a> */}
                </div>
                <a className="btn btn-primary nav-btn" href="#home-drop-message-form">
                  Show Your Interest
                </a>
              </div>
              <div className="col col-sm-12 col-md-5">
                <img src={this.state.PageContent?.admin_image
                          ? environment.baseUrlImg +
                            this.state.PageContent?.admin_image
                          :BannerImage} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HomeBanner;
