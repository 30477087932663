import React from "react";

export default function HomeVideo() {
  return (
    <div className="home-video-section">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-5 d-flex align-items-center">
            <div className="text-container text-center text-lg-left">
              <p className="title">Watch this video to Know</p>
              <hr className="divider" />
              <h2 className="content">
                How <span>ALLEN App</span> helped Aashna to become
                <span>
                  <span> </span> "Dr. Aashna"
                </span>
              </h2>
            </div>
          </div>
          <div className="col-12 col-lg-7 align-items-center">
            <div className="video-section">
              <div class="embed-responsive embed-responsive-16by9">
                <iframe
                  class="embed-responsive-item "
                  src="https://www.youtube.com/embed/5_pTamFpGbQ?si=NO8jICArJqD01buK"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
