import React from 'react'
import { Link } from 'react-router-dom'

export default function CourseInfromation(props) {

  const course_name = props?.location?.state?.course_name ? props?.location?.state?.course_name : "";
  return (
    <>
      <div className="container mt-5 course-information-container">
        <div className="row align-items-center course-popup p-0 w-100">
          <div className="col-6 col-md-4 col-xl-4 mx-auto"><img className="img-fluid d-block mx-auto" src="https://cdn.digital-staging-internal.allen.ac.in/fe-bsite/coursepopup.png" alt="First slide" /></div>
          <div className="col-12 col-md-8 col-xl-8 mt-3 mt-xl-0">
            <p>Dear Student, <br />You are actively enrolled on the course <b>{course_name}</b><br /><br />For details on any other courses, please get in touch <br className="d-none d-md-block d-xl-none" /> with our <br className="d-none d-xl-block" /> support team by calling <b><a href="tel:+91-9513736499" className="popup-link">+91-9513736499 </a></b>or by <br className="d-none d-md-block d-xl-none" />email at <b><a href="mailto:wecare@allendigital.in" className="popup-link">wecare@allendigital.in</a></b></p>

            <div className="mt-4">
              <Link to='/' className="btn btn-outline-green-no-width mr-2 padding-6-40">Go To Home</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
