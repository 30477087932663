import React from 'react'
import RQCode from "../images/QR/onelinkto_ad-ws-22-23.png"

function AppQR() {
  return (
    <div className="container mt-4">
  <div className="" style={{textAlign: "center"}}>
              <h5>Scan QR code to Download the Allen Digital 22-23 App from your mobile device</h5>
              <img src={RQCode} />
              
          </div>
          <div style={{textAlign: "center"}} className="mt-4">
          <a href='https://drive.google.com/file/d/1UpqyGfKc_mhMw7ZQcwDH51ssJko4N1Yf/view'><button className='btn btn-success'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
</svg>Download</button></a>
          </div>
</div>
  )
}

export default AppQR
