import React, { useEffect, useState } from "react";
import ShopingCartlist from "../../components/Ecom/EcomShopingCartList";
import CartDetails from "../../components/Ecom/EcomCartDetails";
// import { environment } from "../../Services/HttpData";
import { Link } from "react-router-dom";
import nocart from "../../images/no-cart.png";
import EcomProfile from "../../components/Ecom/EcomProfile";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);

const EcomCart = () => {
	const wid_data = window.localStorage.getItem("IS_DASHBOARD_ICON")
		? window.localStorage.getItem("IS_DASHBOARD_ICON")
		: false;
	const [cartItems, setCartItems] = useState([]);
	const [cartDetails, setCartDetails] = useState();
	const [refresh, setRefresh] = useState(false);
	const [productTotalAmount, setProductTotalAmount] = useState(0);
	const [emi, setEmi] = useState([]);
	const [productDetails, setProductDetails] = useState({});
	/* let productTotalAmount ; */
	const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "";

	// fetch url for products/courses listing
	useEffect(() => {
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Basic " + auth_token,
			},
		};
		fetch(environment.ecomApiUrl + "cart/list", requestOptions).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				if (result.status == "success") {
					setCartItems(result.data.items); // set result in state this will set current/updated value of setProduct to product
					setCartDetails(result.data.cart);

					//console.log(result.data.items);
					if (result.data.items) {
						getProductData(result?.data?.items[0]?.product_slug);
						setProductTotalAmount(result.data.cart.cart_amount);
						setEmi(result.data.items[0].emi);
						////console.log("emi-i",emi);
					}
					//console.log(productTotalAmount);
				}else if(result.status == "failed" && result.message == "Cart was Expired"){
					window.location = '/Linkexpired';
				}
			});
		});
	}, []);

	const sumCartPrice = ProductAmount => {
		/* productTotalAmount = ProductAmount; */
		//console.log("old Product Price " +productTotalAmount);

		setProductTotalAmount(ProductAmount);
		////console.log(cartDetails)
		//setCartDetails(cartDetails)
		const requestOptions = {
			method: "GET",
			headers: {
				Authorization: "Basic " + auth_token,
			},
		};
		fetch(environment.ecomApiUrl + "cart/list", requestOptions).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				setCartItems(result.data.items); // set result in state this will set current/updated value of setProduct to product
				setCartDetails(result.data.cart);
				setEmi(result.data.items[0].emi);
				getProductData(result?.data?.items[0]?.product_slug);
				//setProductTotalAmount(result.data.cart.cart_amount);
				////console.log("emi",result.data.items[0].emi);
				//console.log("newcart",cartItems);
			});
		});
		setRefresh(!refresh);
		//console.log("Product Price " +ProductAmount);
	};

	const getProductData = slug => {
		const requestOptions = {
			method: "GET",
			headers: { "Content-Type": "application/json", Authorization: "Basic " + auth_token },
		};
		fetch(environment.ecomApiUrl + "product/" + slug, requestOptions).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				if (result?.data.center_course_status === 101) {
					setProductDetails(result?.data); // set result in state this will set current/updated value of setProduct to product
				} else if (result?.data.center_course_status === 102) {
					if (window.location.href.indexOf("?preview") < 0) {
						// window.location = environment.REACT_APP_BASE_URL
					} else {
						setProductDetails(result.data);
					}
				} else {
					// window.location = environment.REACT_APP_BASE_URL
				}
				//console.log(productDetails);
			});
		});
	};
	if (!wid_data) {
		window.location = "/";
	}
	// console.log("emiupsdata",emi);
	//console.log("emiups",emi.length);
	//console.log("newcart",cartItems);
	////console.log('mylog',cartDetails)

	return (
		<>
			<div className='container-fluid'>
				<div className='row mx-0 justify-content-center'>
					<div className='col-12 col-md-8 mt-5 shopingcart'>
						<div className='row m-0 aline d-flex aling-items-center justify-content-between flex-wrap'>
							<h4 className='font-weight-bold cart-title mb-0'>Your Shopping Cart</h4>
							<Link to='/ecom/courses/' className='ml-auto text-success cart-shop-link line-height-normal'>
								Continue Shopping
							</Link>
						</div>
						{cartItems ? (
							(cartItems ? cartItems : []).map((itm, i) => {
								// alert(itm.batch_name);
								// alert(itm.cart_item_id);
								return (
									<ShopingCartlist
										key={itm.cart_item_id}
										quantity={itm.quantity}
										mrp={itm.mrp}
										cart_item_id={itm.cart_item_id}
										net_amount={itm.net_amount}
										cart_coupon_id={itm.cart_coupon_id}
										product_title={itm.product_title}
										product_description={itm.product_description}
										product_slug={itm.product_slug}
										coupon_title={itm.coupon_title}
										discount={itm.discount}
										onChange={sumCartPrice.bind()}
										batch_id={itm.batch_id}
										product_type={itm.product_type}
										batch={itm.batch}
										batch_name={itm.batch_name}
										batch_start_date={itm.batch_start_date}
										plan_type={itm.plan_type}
										emi={itm.emi}
										product_id={itm.product_id}
										image={itm.image}
										productDetails={productDetails}
									/* onChange={this.handleChildClick.bind(this)} */
									/>
								);
							})
						) : (
							<>
								<div className='not-found-img-box'>
									<div className='text-center'>
										<img src={nocart} className='no-cart-img'></img>
										<p className='m-1'>
											<b>No course available</b>
										</p>
										<p>Their is nothing in your bag. Let's add some course</p>
										<a href='/ecom/courses' className='btn btn-green-header'>
											Continue Shopping
										</a>
									</div>
								</div>
								<div className='col-12 col-md-3 p-4 order-summary pt-5'>
									{/* <h6 className="font-weight-bold cart-summery">Profile</h6> */}
								</div>
							</>
						)}
					</div>
					{/*  <span>{productTotalAmount} </span> */}
					{cartDetails ? (
						<CartDetails
							productDetails={productDetails}
							cart_amount={productTotalAmount}
							cart_coupon_id={cartDetails.cart_coupon_id}
							cart_discount_amount={cartDetails.cart_discount_amount}
							cart_pay_amount={cartDetails.cart_pay_amount}
							product_total_amount={productTotalAmount}
							gst_rate={cartDetails.gst_rate}
							final_pay_amount={cartDetails.final_pay_amount}
							cart_id={cartDetails.cart_id}
							cart_item_id ={cartItems[0].cart_item_id}
							gst_amount={cartDetails.gst_amount}
							coupon_title={cartDetails.coupon_title}
							onChange={sumCartPrice.bind()}
						/>
					) : null}
				</div>
			</div>
		</>
	);
};

export default EcomCart;
