import React from 'react';
class ArticlesContent extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
        return (
            <>
                {(this.props.data?.title && this.props.data?.title!=="") && <h2 className='article-content-heading'>{this.props.data?.title}</h2>}
                {/* <h4 className='article-content-sub-heading'>NCERT Solutions for Class 12 Maths – Free PDF Download</h4> */}
                {(this.props.data?.description && this.props.data?.description!=="") && (<p className='article-content-description' dangerouslySetInnerHTML={{
                        __html: this.props.data?.description}}></p>)}
            </>
        );
    }
}

export default ArticlesContent;