import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import { useHistory } from "react-router";

const EcomCheckoutPayment = (props) => {
  const userWid = window.localStorage.getItem("IS_DASHBOARD_ICON")
		? window.localStorage.getItem("IS_DASHBOARD_ICON")
		: false;
  const history = useHistory()
  const [loading, setLoading] = useState(true);
  const [loanamount, setloanamount] = useState(0);
  const [enterAmount,setEnterAmount] = useState()
  const cartItem = props.initialCartItems;
  const cartDetails = props.cartDetails;
  const finalAmount = cartItem.net_amount - cartDetails.cart_discount_amount;
  const productDiscountPer = (cartItem.discount / cartItem.mrp) * 100;
  const cartDiscountPer =
    (cartDetails.cart_discount_amount / cartItem.net_amount) * 100;
  const courseData = props.courseData === undefined ? [] : props.courseData;
  const minloanAmount =
    courseData.length > 0 ? courseData[0]?.min_loan_amount : 0;
  const maxloanAmount =
    courseData.length > 0 ? courseData[0]?.max_loan_amount : 0;
  const totalAmount =
    courseData.length > 0 ? courseData[0]?.fee_plan_total_fee : 0;

  // if (loanamount >= minloanAmount && loanamount <= maxloanAmount) {
  //   props.disabled(false);
  // } else {
  //   props.disabled(true);
  // }

  /* Installment Calculation */
  const installmentValue = props.installmentCal();
  let cartGSt = cartDetails.gst_rate ? cartDetails.gst_rate : "18";
  let gst = 0;
  cartDetails.gst_rate = 0;
  let proceedToCheckout;
  if (cartDetails.gst_rate === 0) {
    gst = cartDetails.gst_rate;
    proceedToCheckout = installmentValue + gst;
  } else {
    gst = 0;
    proceedToCheckout = installmentValue + gst;
    /* gst = (cartGSt/100)*installmentValue;
   proceedToCheckout = installmentValue + gst; */
  }

  /* const [instTotal,setInstTotal] = useState(cartDetails.cart_amount);
const [gstAmount,setGstAmount] = useState(gst);
const [proceedAmount,setProceedAount] = useState(proceedToCheckout); */

  ////console.log("newValue "+installmentValue);

  function myFunctionn() {
    var x = document.getElementById("myDIVV");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  function myFunction() {
    var x = document.getElementById("myDIVDiscount");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  const calcDownpayment = (e) => {
    const amount = new RegExp("^[0-9]+$");
    if (
      e.target.value?.includes("+") ||
      e.target.value?.includes("-") ||
      e.target.value?.includes("e") ||
      e.target.value?.includes("E") ||
      e.target.value?.includes(".")||
      !amount.test(e.target.value)
    ) {
      if (e.target.value.length === 0) {
        setEnterAmount("")
      }
      return false;
    }
    // setEnterAmount(e.target.value)
    // setloanamount(e.target.value);
    // props.loanAmount(e.target.value);
  };

  function showvalidation() {
    props.loanAmount(loanamount);
    if (loanamount < minloanAmount) {
      document.getElementById(
        "downpaymentvalidate"
      ).innerText = `Enter Amount Between ${minloanAmount}-${maxloanAmount}`;
    } else if (loanamount > maxloanAmount) {
      document.getElementById(
        "downpaymentvalidate"
      ).innerText = `Enter Amount Between ${minloanAmount}-${maxloanAmount}`;
    } else {
      document.getElementById("downpaymentvalidate").innerText = null;
    }
  }

  const handleEvent = (e) => {
    // props.onChange(e.target.value);
    // //console.log(e.target.value);
  };

  useEffect(()=>{
     setEnterAmount(courseData[0]?.max_loan_amount)
  setloanamount(courseData[0]?.max_loan_amount);
  props.loanAmount(courseData[0]?.max_loan_amount);
  },[courseData[0]?.max_loan_amount])
  
  if (!userWid) {
    toast.warning("Please login first.");
    history.push("/courses/ZSJ58TTQIc")
  }


 
  return (
    <>
    
      {!props.showInstallment ? (
        <>
          <div className="row"></div>
          <div className="address-text-box mb-2">
            <b>Payment Info</b>
          </div>

          <div className="row">
            <div className="col-md-8">
              <span className="in-payment-course-text">
                {cartItem.product_title}
              </span>
            </div>
            <div className="col-md-4">
              <span className="in-payment-price">
                ₹ {cartDetails.cart_amount}
              </span>
            </div>
          </div>
          {cartItem.discount !== 0 ? (
            <>
              <div className="row">
                <div className="col-md-8" onClick={myFunction}>
                  <span className="in-payment-discount-text">
                    Discount Applied &nbsp;
                    <i className="fa-solid fa-circle-info color-green"></i>
                  </span>
                </div>

                <div className="col-md-4">
                  <span className="in-payment-discount-price">
                    -₹ {cartItem.discount}
                  </span>
                </div>
              </div>
            </>
          ) : null}

          <div className="couopn-detail-popup" id="myDIVDiscount">
            <span className="arrow-up-couopn-popup"></span>
            <div className="row">
              <div className="col-auto">
                <span className="coupon-design">{cartItem.coupon_title}</span>
              </div>
              <div className="col-auto">
                <span className="discount-percent-design">
                  {parseInt(productDiscountPer)}% Discount
                </span>
              </div>
            </div>
            <div className="border-bottom"></div>
          </div>

          {/* <div className="row mt-2">
            <div className="col-md-8">
              <span className="in-payment-course-text">{cartItem.product_title}</span>
            </div>
            <div className="col-md-4">
              <span className="in-payment-price">₹ {cartItem.net_amount}</span>
            </div>
          </div> */}

          <div className="border-bottom"></div>
          <div className="row mt-2">
            <div className="col-md-8">
              <span className="in-payment-course-text">Total</span>
            </div>
            <div className="col-md-4">
              <span className="in-payment-price">
                ₹ {cartDetails.cart_amount}
              </span>
            </div>
          </div>

          {cartDetails.cart_discount_amount !== 0 ? (
            <>
              <div className="row">
                <div className="col-md-8" onClick={myFunctionn}>
                  <span className="in-payment-discount-text">
                    Cart Applied{" "}
                    <i className="fa-solid fa-circle-info color-green"></i>
                  </span>
                </div>
                <div className="col-md-4">
                  <span className="in-payment-discount-price">
                    -₹ {cartDetails.cart_discount_amount}
                  </span>
                </div>
              </div>
            </>
          ) : null}

          <div className="couopn-detail-popup" id="myDIVV">
            <span className="arrow-up-couopn-popup"></span>
            <div className="row">
              <div className="col-auto">
                <span className="coupon-design">
                  {cartDetails.coupon_title}
                </span>
              </div>
              <div className="col-auto">
                <span className="discount-percent-design">
                  {parseInt(cartDiscountPer)}% Discount
                </span>
              </div>
            </div>
            <div className="border-bottom"></div>
          </div>

          <div className="border-bottom"></div>

          <div className="row mt-2">
            <div className="col-md-8">
              <span className="in-payment-course-text">Payable Amount</span>
            </div>
            <div className="col-md-4">
              <span className="in-payment-price">
                ₹ {cartDetails.cart_pay_amount}
              </span>
            </div>
          </div>

          <div className="border-bottom"></div>
          {/* GST Amount */}
          {cartDetails.gst_rate == 0 ? null : (
            <>
              <div className="row mt-2">
                <div className="col-md-8">
                  <span className="in-payment-course-text">
                    Gst ({cartDetails.gst_rate + "%"})
                  </span>
                </div>
                <div className="col-md-4">
                  <span className="in-payment-price">
                    ₹ {cartDetails.gst_amount}
                  </span>
                </div>
              </div>
            </>
          )}

          <div className="border-bottom"></div>

          <div className="row mt-4">
            <div className="col-md-12">
              <h5 className="in-payment-proceedpay-text">
                <b>Proceed To Pay ₹ {cartDetails?.final_pay_amount}</b>
              </h5>
            </div>
          </div>
        </>
      ) : (
        /* Installment Code */
        <>
          <div className="row"></div>
          <div className="address-text-box mb-2">
            <b>Payment Info</b>
          </div>

          <div className="border-bottom"></div>

          <div className="row">
            <div className="col-md-8">
              <span className="in-payment-course-text">
              {courseData.length > 0
                  ? courseData[0]?.course_display_name
                  : null}
              </span>
            </div>
            <div className="col-md-4">
              <span className="in-payment-price">
              ₹{" "}
                {courseData.length > 0
                  ? courseData[0]?.fee_plan_total_fee
                  : null}
              </span>
            </div>
          </div>
           

          <div className="row">
          </div>
          <div className="row mt-2">
            <div className="col-md-8">
              <span className="in-payment-course-text">Total Amount</span>
            </div>
            <div className="col-md-4">
              <span className="in-payment-price">
                ₹{" "}
                {courseData.length > 0
                  ? courseData[0]?.fee_plan_total_fee
                  : null}
              </span>
            </div>
          </div>

          <div className="border-bottom"></div>
          {/* <div className="row mt-2">
            <div className="col-md-8">
              <span className="in-payment-course-text">Min-loan Amount</span>
            </div>
            <div className="col-md-4">
              <span className="in-payment-price">
                ₹{" "}
                {courseData.length > 0 ? courseData[0]?.min_loan_amount : null}
              </span>
            </div>
          </div> */}

          {/* <div className="row mt-2">
            <div className="col-md-8">
              <span className="in-payment-course-text">Max-loan Amount</span>
            </div>
            <div className="col-md-4">
              <span className="in-payment-price">
                ₹{" "}
                {courseData.length > 0 ? courseData[0]?.max_loan_amount : null}
              </span>
            </div>
          </div> */}
          {/* <div className="border-bottom"></div> */}

          {/* <div className="row mt-2">
            <div className="col-md-8">
              <span className="in-payment-course-text">Loan Amount</span>
            </div>
            <div className="col-md-4">
              <span className="in-payment-price">
                <input
                  type="text"
                  onChange={calcDownpayment}
                  style={{
                    border: "none",
                    borderBottom: "1px solid gray",
                    width: "115px",
                    marginLeft: "-85%",
                    textAlign: "right",
                    outline: "none",
                  }}
                  placeholder="Enter Amount"
                  // required
                  // autoFocus
                  //   dataTip="Enter a lone amount"
                    disabled
                    value={enterAmount}
                ></input>
              </span>
            </div>
          </div> */}
          {loanamount > maxloanAmount || loanamount < minloanAmount ? (
            <div className="row mt-2">
              <div className="col-md-12">
                <span
                  className="in-payment-course-text"
                  id="downpaymentvalidate"
                  style={{ color: "red" }}
                >
                  {" "}
                  Enter Amount Between {minloanAmount}-{maxloanAmount}
                </span>
              </div>
            </div>
          ) : (
            <div className="row mt-2">
              <div className="col-md-8">
                <span className="in-payment-course-text">Minimum Advance Payment</span>
              </div>
              <div className="col-md-4">
                <span className="in-payment-price" id="downpaymentamount">
                  ₹ {totalAmount - loanamount}
                </span>
              </div>
            </div>
          )}

          {/* GST Amount */}
          {cartDetails.gst_rate == 0 ? null : (
            <>
              <div className="border-bottom"></div>
              <div className="row mt-2">
                <div className="col-md-8">
                  <span className="in-payment-course-text">
                    Gst (
                    {cartDetails.gst_rate ? cartDetails.gst_rate + "%" : "18%"})
                  </span>
                </div>
                <div className="col-md-4">
                  <span className="in-payment-price">₹ {gst}</span>
                </div>
              </div>
            </>
          )}

          <div className="border-bottom"></div>

          {/* <div className="row mt-4">
                <div className="col-md-12">
                  <h5 className="in-payment-proceedpay-text"><b>Proceed To Pay ₹ {proceedToCheckout}</b></h5>
                </div>
          </div> */}
        </>
      )}
    </>
  );
};

export default EcomCheckoutPayment;
