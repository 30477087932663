import React, { Component } from 'react';
// import { environment } from '../../Services/HttpData';
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)

const AboutUsBanner = (props) => {
/* 
    constructor(props) {
        super(props);
         ////console.log(this.props);
        this.state = {
          PageContent: [],
        };
      }
      componentWillReceiveProps(props){
        this.setState({PageContent: props.handelEvent});
        ////console.log(props.handelEvent);
      } */

      var str = props.slug;
      var new_str = str.replace(/-/g, ' ');
      ////console.log(new_str);

        return <>
        <div className="">
        <div className="banner">
            <div className="container banner-content">
                <h4 style={{textTransform: "capitalize"}}>{new_str}</h4>
                {/* <!--<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis aliquam dolorem perferendis
                    quibusdam,<br>
                    assumenda perspiciatis necessitatibus deleniti consequuntur blanditiis libero minima <br>sed
                    cupiditate
                    veniam! Impedit, sunt! Fuga magni voluptate veniam.</p>--> */}
            </div>
        </div>
    </div>
        </>;
    }

 
export default AboutUsBanner;