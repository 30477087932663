import React from 'react'
import ViewProfile from '../components/Profile/ViewProfile';
class Profile extends React.Component {
    render() { 
        return <>
        
            <ViewProfile/>
        
        </>;
    }
}
 
export default Profile;