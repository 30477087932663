import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { environment } from "../../Services/HttpData";
import iit from '../../images/iit-main.jpg';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import EcomCartBatch from "./EcomCartBatch";
import EcomBatch from "./EcomBatch";
import { toast } from "react-toastify";

import { TailSpin } from 'react-loader-spinner';
import EmiTableList from "./EmiTableList";
import noproduct1 from '../../images/no-product.png';
import MixpanelInit from "../Mixpanel/MixpanelInit";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
// or less ideally
const ShopingCartlist = (props) => {
  // //console.log('yum-props',props)
  const productDetails = props?.productDetails;
  let textInput = React.createRef();
  const [productDiscount, setProductDiscount] = useState(props.discount);
  const [netAmount, setNetAmount] = useState(props.net_amount);
  const [couponList, setCouponList] = useState([]);
  const [text, setText] = useState();
  const [suggestion, setSuggestion] = useState([]);
  const [currency, setcurrency] = useState({
    value: null, currencySign: "INR"
  });
  const [showCoupon, setShowCoupon] = useState(false);
  const [inputCoupon, setInputCoupon] = useState(true);
  const [haveCoupon, setHaveCoupon] = useState(true);
  const [couponMessage, setCouponMessage] = useState(<button style={{ border: "2px solid #046635", borderRadius: "8px", display: "none", backgroundColor: "#046635", color: "#fff" }}>Coupon Applied!</button>);
  const [batchId, setBatchId] = useState(props.batch_id);
  const [couponTitle, setcouponTitle] = useState(props.coupon_title);
  const [batchName, setBatchName] = useState(props.batch_name);
  const [batchDate, setBatchDate] = useState(props.batch_start_date);
  const [cartShown, setCartShown] = useState(true);
  const toggleCoupon = () => {
    setShowCoupon(!showCoupon);
    setHaveCoupon(false);

  }
  //props.onChange(netAmount);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [removeLoadingButton, setRemoveLoadingButton] = useState(true);
  const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : '';

  const [emiDetails, setEmiDetails] = useState((props.emi ? props.emi : []));

  // Current Date 
  var cd = new Date(); // Now
  cd.setDate(cd.getDate()); // Set now + 30 days as the new date
  let current_date = cd.getDate() + "/" + (cd.getMonth() + 1) + "/" + cd.getFullYear();

  // Emi Date Calculation Starts
  var date = new Date(); // Now
  date.setDate(date.getDate() + 30); // Set now + 30 days as the new date
  let date_30 = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

  var dt = new Date(); // Now
  dt.setDate(dt.getDate() + 60); // Set now + 60 days as the new date
  let date_60 = dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();

  // Emi Date Calculation Ends
  //   useEffect(()=>{
  //     const requestOptions = {
  //        method: 'POST',
  //        headers : {
  //          'Content-Type':"application/json",
  //          'Authorization': 'Basic '+auth_token
  //        },
  //        body: JSON.stringify({
  //         "type":"product",
  //         "product_id": props.product_id
  //     })
  //      };
  //      fetch(environment.ecomApiUrl + 'coupon/list',requestOptions).then((resp)=>{ // fetching api url for courses listing
  //          resp.json().then((result)=>{
  //            if(result.status == "success"){
  //             setCouponList(result.data)
  //             setSuggestion(result.data.code)
  //             //  console.log("couponList",result.data);
  //            }

  //          })
  //      })
  //  },[]);

  const showSuggestion = () => {
    setSuggestion(couponList);
  }

  const suggestionHandler = (text) => {
    setText(text);
    setSuggestion([]);
  }

  const onChangeCoupon = (text) => {
    let matches = [];
    if (text.length > 0) {
      // //console.log(text)
      matches = couponList.filter(cpn => {
        const regex = new RegExp(`${text}`, "gi");
        //console.log(cpn.title.match(regex))
        return cpn.code.match(regex)
      })
    }
    //console.log("matches",matches)
    setSuggestion(matches)
    setText(text)
    //console.log("cpn",suggestion)
  }

  const applyCoupon = (cpn) => {

    const coupon = textInput.current.value;
    if (!coupon) {
      coupon = cpn;
    }
    // alert(coupon)
    let codeMatch = false;
    for (var i = 0; i < couponList.length; i++) {
      if (coupon == couponList[i].code) {
        codeMatch = true;
      }
    }

    if (codeMatch) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + auth_token
        },
        body: JSON.stringify({
          "coupon_code": coupon,
          "type": "product",
          "cart_item_id": props.cart_item_id
        })
      };
      fetch(environment.ecomApiUrl + 'coupon/apply', requestOptions).then((resp) => { // fetching api url for courses listing
        resp.json().then((result) => {
          //alert(result.status);
          if (result.status == "success") {
            setLoading(false);
            const requestOptionsRaCart = {
              method: 'GET',
              headers: {
                'Authorization': 'Basic ' + auth_token
              }
            };
            fetch(environment.ecomApiUrl + 'cart/list', requestOptionsRaCart).then((resp) => { // fetching api url for courses listing
              resp.json().then((result1) => {

                setEmiDetails(result1.data.items[0].emi);
                setProductDiscount(result1.data.items[0].discount);
                setNetAmount(result1.data.items[0].net_amount);
                setCouponMessage(<button style={{ border: "2px solid #046635", borderRadius: "8px", backgroundColor: "#046635", color: "#fff" }}>{result.message}</button>);
                setcouponTitle(coupon);
                props.onChange(result1.data.cart.cart_amount);
                //console.log(result1.data.cart.cart_amount);
                setInputCoupon(false);
                setHaveCoupon(false);
                toast.success(result.message);
                ////console.log(emiDetails);
              })
            })
          } else {
            setProductDiscount("0");
            setNetAmount(props.mrp);
            toast.error(result.message);
            //setcouponTitle(<span style={{color: "red"}}>Not A Valid Coupon</span>);
            props.onChange(props.mrp);
            // //console.log(result.message);



            setHaveCoupon(true);
            setShowCoupon(false);
            setInputCoupon(true);
            setCouponMessage("");
            // toast.success(result2.message);
            //toast.error("Remove Coupon");
            //console.log("Remove Coupon")
          }

        })
      })
    } else {
      toast.error("Invalid Coupon Code");
    }

  };


  const removeProductCoupon = (cart_item_id) => {
    setLoading(true);
    const requestOptionsRPC = {
      method: 'DELETE',
      headers: {
        'Authorization': 'Basic ' + auth_token
      }
    };
    fetch(environment.ecomApiUrl + 'coupon/product/remove/' + cart_item_id, requestOptionsRPC).then((resp) => { // fetching api url for courses listing
      resp.json().then((result2) => {
        if (result2.status == "success") {

          setText();
          const requestOptionsRaCartCoupon = {
            method: 'GET',
            headers: {
              'Authorization': 'Basic ' + auth_token
            }
          };
          fetch(environment.ecomApiUrl + 'cart/list', requestOptionsRaCartCoupon).then((resp) => { // fetching api url for courses listing
            resp.json().then((result3) => {
              setEmiDetails(result3.data.items[0].emi);
              // //console.log(emiDetails);
            })
          })
          ////console.log(result2.status);
          setRemoveLoadingButton(true);
          setcouponTitle("");
          setNetAmount(props.mrp);
          setProductDiscount(0);
          props.onChange(props.mrp);
          setHaveCoupon(true);
          setShowCoupon(false);
          setInputCoupon(true);
          setCouponMessage("");
          toast.error(result2.message);
        } else {
          toast.error(result2.message);
        }

      })
    })
    setText();
    setSuggestion([])
    setHaveCoupon(true);
    setShowCoupon(false);
    setInputCoupon(true);
    setCouponMessage("");
    // toast.success(result2.message);
    //toast.error("Remove Coupon");
    //console.log("Remove Coupon")

  }

  const removeCartItem = (cartItemId) => {
    MixpanelInit("Track", "removed_from_cart", {
      course_id: productDetails?.course_id,
      course_title: productDetails?.title,
      academic_session: productDetails?.academic_session,
      commencement_date: productDetails?.commencement_date,
      duration: productDetails?.duration,
      study_mode: 'Online (Live)',
      study_medium: productDetails?.medium_name,
      course_fee: productDetails?.display_price,
      refund_last_day: productDetails?.refund_last_day,
    })
    const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : '';
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + auth_token
      },
    };
    fetch(environment.ecomApiUrl + 'cart/item/delete/' + cartItemId, requestOptions).then((resp) => { // fetching api url for courses listing
      resp.json().then((result) => {

        /*  setCartShown(false);
         props.data.sumCartPrice("0"); */
        toast.error("Your Cart Is Empty");
        window.location = "/ecom/cart";
      })
    })
  }

  let selectedBatchId;
  let cart_item_id;

  const saveBatch = (batchId, cart_item_id) => {
    selectedBatchId = batchId
    cart_item_id = cart_item_id

  }

  const saveCurrentBatch = () => {

    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Basic ' + auth_token },
      body: JSON.stringify({ "item_id": cart_item_id, "batch_id": selectedBatchId })
    };
    fetch(environment.ecomApiUrl + 'cart/item/update-batch', requestOptions).then((resp) => { // fetching api url for courses listing
      resp.json().then((result) => {
        // //console.log("form submitted");
        setModalShow(false);
        setBatchId(selectedBatchId);
        setBatchName(result.data.batch_name);
        setBatchDate(result.data.batch_start_date);
        toast.success(result.message);
        // //console.log(batchName);
        /*  setLoading(false); */
      })
    })

  }

  function myFunction() {
    var x = document.getElementById("myDIV");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  /*   
    const isBatchChecked =(event) =>{
      //console.log('chk',event.target.value+'=='+selectedBatch.chkBatch);
      return (event.target.value==selectedBatch.chkBatch)
    } */

  function MyVerticallyCenteredModal(props) {
    selectedBatchId = props.selected_batch
    cart_item_id = props.cart_item_id
    // alert(cart_item_id);
    return (

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <center><h5 className="align-middle my-font">Select Your Prefered Batch</h5></center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EcomCartBatch
            selected_batch={props.selected_batch}
            batch={props.batch}
            cart_item_id={props.cart_item_id}
            onBatchSave={saveBatch.bind()} />

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} className="btn-outline-green-header">Cancel</Button>
          {
            /* loading?  */
            <Button className="btn btn-green-header" onClick={saveCurrentBatch}
            >Save</Button>
            /*   :
              <Oval color="#00BFFF" height={10} width={10} /> */
          }

        </Modal.Footer>
      </Modal>

    );
  }



  return (
    <>
      {
        cartShown ?
          <div
            className="card px-4 py-2 mb-4 course-card"
            key={props.cart_item_id}
          >
            <MyVerticallyCenteredModal
              show={modalShow}
              batch={props.batch}
              selected_batch={batchId}
              cart_item_id={props.cart_item_id}
              onHide={() => setModalShow(false)}
            />
            {/* <span>{props.product_id}</span> */}
            <div className="row  ">
              <div className="col-md-4">
                <img
                  // className="padding-b-t"
                  src={props.image === "" ? iit : props.image}
                  alt=""
                  width="30%"
                />
              </div>
              <div className="col-md-7">
                <div className="cart-content-box-right">

                  <a href={"ecom/courses-details/" + props.product_slug} className="cart-list-title">{props.product_title}</a><br />
                  <span className="cut-text" title={props.product_description}>
                    {props.product_description}
                  </span>
                  <h5 className="cart-list-price">
                    {"₹ " + props.mrp}
                  </h5>

                  {/* Ecom Batch  */}
                  {/*  {
                  props.product_type === "live" ? 
                  <EcomBatch batch_name={batchName}  batch_start_date={batchDate} onClick={()=>setModalShow(true)}/>:null
                } */}



                  {/* Have Coupon  */}
                  {/* {
                haveCoupon ? (couponTitle ? null:<label className="cart-label" onClick={toggleCoupon}>Have a Course Coupon?</label>):null
              } */}


                  {/* coupon Title shown on apply */}
                  {/* <div className="">
              <span className="">
                      {couponTitle ? 
                        
                        (
                          <>
                           
                            <div className="coupon-applied-bg">
                                <span className="coupon-design2">
                                  {couponTitle}
                                    <button 
                                    type="button"
                                    className="btn btn-apply coupon cross-btn"
                                    onClick={()=>removeProductCoupon(props.cart_item_id)}
                                    style={{color:"#dc3545"}}
                                  >
                                        Remove
                                      </button>
                            </span>
                            </div>
                          </>
                       ):
                       null
                      }
                        
               </span>
              </div> */}

                  {/* price effect by coupon */}
                  {
                    couponTitle ? (<h6 className="discout-price">
                      {/* {"Discount -₹ " + productDiscount} */}
                      <br />

                      {/* {"Final Price -₹" + netAmount} */}
                    </h6>) : null
                  }

                </div>

              </div>
              <div className="col-md-4">
                {/* data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" */}
                <div className="accordion" id="accordionExample">
                  <div className="card emi-card m-0 p-0">
                    <div className="card-header" id="headingOne">
                      {
                        props.plan_type === "Full course" ?
                          null
                          :
                          <button className="btn btn-link p-0 m-0" type="button" onClick={myFunction}>
                            Fee Installments
                          </button>
                      }
                    </div>

                    {/* <div id="collapseOne" className="collapse " aria-labelledby="headingOne" data-parent="#accordionExample"> */}
                    <div className="card-body emi-card-body" id="myDIV">
                      <span className="arrow-up"></span>
                      <table width="100%" border="0" cellspacing="0" cellPadding="0" className="course-table m-0">
                        <EmiTableList emiDetails={props.emi} currentDate={current_date} />
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {removeLoadingButton ?
                <button
                  className="btn btn-link text-success float-right"
                  onClick={() => {
                    removeCartItem(props.cart_item_id);
                  }}
                >
                  <i className="fa-solid fa-trash-can"></i>
                </button>
                : <TailSpin color="#00BFFF" height={20} width={20} />

              }
            </div>
          </div> :
          (
            <>
              <div className="not-found-img-box">
                <div className="text-center">
                  <img src={noproduct1} className="no-cart-img"></img>
                </div>
              </div>
            </>
          )

      }

    </>
  );
}


export default ShopingCartlist;
