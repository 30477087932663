import React from 'react';

import student1 from "../images/ravi-mishra.png";
import student2 from "../images/video-program.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import testimonials_list from "../js/testimonials_list"
// import { environment } from '../Services/HttpData';
import TestimonialsBanner from '../components/Testimonials/TestimonialsBanner';
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class SuccessStories extends React.Component {
    constructor(props) {
        super(props);
        // //console.log(props);
        this.state = {
            PageContent: [],
            examsList: [],
            testimonialsList: []
        };
    }
    groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }
    async fetchTestimonials() {
        // POST request using fetch with async/await
        let testimonials = testimonials_list?.data
                if (testimonials.length) {
                    let streams = this.groupBy(testimonials, 'stream_title');
                    for (let element in streams) {
                        // //console.log(streams[element]);
                        // streams[element + "all"] = streams[element];
                        let rowData=streams[element];
                        streams[element] = this.groupBy(streams[element], 'exam_title');
                        streams[element]["all"] = rowData;
                    };
                    this.setState({ testimonialsList: streams });
                    //console.log('testimonials', data.data, "streams", streams);

                }
                else {
                    this.setState({ testimonialsList: [] });

                }
    }
    async componentDidMount() {
        // POST request using fetch with async/await
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ "keyword": '' })
        // };
        // fetch(environment.baseUrl + 'view_list_all', requestOptions)
        //     .then(async response => {
        //         // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        //         const data = /* isJson && */ await response.json();
        //         // check for error response
        //         // //console.log(response);
        //         if (!response.ok) {
        //             // get error message from body or default to response status
        //             const error = (data && data.message) || response.status;
        //             return Promise.reject(error);
        //         }
        //         let index = data.data.findIndex((item) => item.slug === "success-stories");
        //         // //console.log(data.data[index]);
        //         let sections = {};
        //         data.data[index]?.sections.map((item) => {
        //             switch (item.id) {
        //                 case 132:
        //                     sections.banner = item;
        //                     break;
        //                 case 133:
        //                     sections.mainTestimonial = item;
        //                     break;
        //             }
        //         });
        //         this.setState({ PageContent: sections });
        //         // //console.log(this.state.PageContent);
        //         //   this.fetchExams();
        //         this.fetchTestimonials();
        //     })
        //     .catch(error => {
        //         this.setState({ errorMessage: error.toString() });
        //         console.error('There was an error!', error);
        //     });
        const getViewListAll = () => {
            if (!window?.AllenDigitalFEBSite) {
                setTimeout(() => {
                getViewListAll();
              }, 50);
              return false
            }
            const data = window?.AllenDigitalFEBSite
  
  
            let index = data.data.findIndex((item) => item.slug === "success-stories");
            // //console.log(data.data[index]);
            let sections = {};
            data.data[index]?.sections.map((item) => {
                switch (item.id) {
                    case 132:
                        sections.banner = item;
                        break;
                    case 133:
                        sections.mainTestimonial = item;
                        break;
                }
            });
            this.setState({ PageContent: sections });
            // //console.log(this.state.PageContent);
            //   this.fetchExams();
            this.fetchTestimonials();
          };
          getViewListAll();
    }



    render() {

        return <>
            <TestimonialsBanner handelEvent={this.state.PageContent?.banner} />



            {this.state.PageContent.mainTestimonial && (<div className="container mt-5">
                <div className="row mx-0 bg-light">
                    <div className="col-12 col-lg-4">
                        <img src={
                            this.state.PageContent.mainTestimonial?.admin_image
                                ? environment.baseUrlImg + this.state.PageContent.mainTestimonial?.admin_image
                                : student1
                        } alt="" width="100%" />
                    </div>

                    <div className="col-12 col-lg-8 py-5">
                        <div className="row mx-0 align-items-center">
                            <h4 className="font-weight-bold mb-0">{this.state.PageContent.mainTestimonial.title}</h4>
                            <h6 className="ml-2 mb-0">{this.state.PageContent.mainTestimonial.subtitle}</h6>
                        </div>
                        <p className="text-green pl-0">{this.state.PageContent.mainTestimonial.link}</p>


                        <p className="mt-5">{this.state.PageContent.mainTestimonial.meta_description}</p>
                        <div className="row mx-0 align-items-center mt-5">
                            <i className="far fa-play-circle fa-2x " style={{ color: '#0c703f', marginRight: '5px' }}></i>
                            <p className="mb-0">Watch Story</p>
                        </div>
                    </div>
                </div>
            </div>)}

            {Object.keys(this.state.testimonialsList).map((key, index) => {
                return (<div className="pre-engineering next" key={key}>
                    <h3 className="text-center mt-5 font-weight-bold">{key}</h3>
                    <div className="d-flex flex-row flex-wrap justify-content-center  my-3 w-50 ml-auto mr-auto">
                        <ul className="nav nav-pills mb-3 pre-engineering" id="pills-tab" role="tablist" >
                            <li className="nav-item">
                                <a className="nav-link active" id={"pills-home-"+index+"-tab"} data-toggle="pill" href={"#pills-home-"+index} role="tab" aria-controls={"pills-home-"+index} aria-selected="true">ALL</a>
                            </li>
                            {Object.keys(this.state.testimonialsList[key]).map((keyInner, ind) => {
                            if (keyInner !== "all")
                                return (<li className="nav-item" key={keyInner}>
                                    <a className="nav-link" id={"tab-pills-" + index + "-" + ind + "-tab"} data-toggle="pill" href={"#tab-pills-" + index + "-" + ind} role="tab" aria-controls={"tab-pills-" + index + "-" + ind} aria-selected="false">{keyInner}</a>
                                </li>
                                )
                            }
                            )}
                            {/* <li className="nav-item">
                            <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">JEE Advance</a>
                        </li> */}
                        </ul>
                    </div>
                    <div className="tab-content" id={"pills-tabContent" + index}>
                        {Object.keys(this.state.testimonialsList[key]).map((keyInner, ind) => {
                            if (keyInner === "all")
                                return (
                        <div className="tab-pane fade show active" id={"pills-home-"+ index} role="tabpanel" aria-labelledby={"pills-home-"+index+"-tab"}>
                            <section className="pb-5">
                                <div className="container">
                                            <OwlCarousel autoplay="true" autoplayTimeout="1500" className='owl-theme' loop margin={10} nav lazyContent="true">
                                                {Object.keys(this.state.testimonialsList[key][keyInner]).map((itemIndex, ind) => {
                                                    return (
                                                        <div className="item">
                                                            <div className="card">
                                                                <img src={
                                                                    this.state.testimonialsList[key][keyInner][itemIndex]?.image
                                                                        ? environment.baseUrlImg + this.state.testimonialsList[key][keyInner][itemIndex]?.image
                                                                        : student2
                                                                } alt="" width="100%" />
                                                                <p dangerouslySetInnerHTML={{ __html: this.state.testimonialsList[key][keyInner][itemIndex]?.description }}></p>
                                                                <div className="card-footer bg-white pl-0">
                                                                    <h4 className="font-weight-bold">{this.state.testimonialsList[key][keyInner][itemIndex]?.person_name}</h4>
                                                                    <p className="text-green mb-0">{this.state.testimonialsList[key][keyInner][itemIndex]?.title}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                )}
                                            </OwlCarousel>

                                </div>
                            </section>
                        </div>)}
                        )}
                        {Object.keys(this.state.testimonialsList[key]).map((keyInner, ind) => {
                            if (keyInner !== "all")
                                return (
                                    <div className="tab-pane fade" id={"tab-pills-" + index + "-" + ind} role="tabpanel" aria-labelledby={"tab-pills-" + index + "-" + ind + "-tab"}>  <section className="pb-5">
                                        <div className="container">
                                            <OwlCarousel autoplay="true" autoplayTimeout="1500" className='owl-theme' loop margin={10} nav lazyContent="true">
                                                {this.state.testimonialsList[key][keyInner].map((item, ind) => {
                                                    return (
                                                        <div className="item">
                                                            <div className="card">
                                                                <img src={
                                                                    item?.image
                                                                        ? environment.baseUrlImg + item?.image
                                                                        : student2
                                                                } alt="" width="100%" />
                                                                <p dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                                                                <div className="card-footer bg-white pl-0">
                                                                    <h4 className="font-weight-bold">{item?.person_name}</h4>
                                                                    <p className="text-green mb-0">{item?.title}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                )}
                                            </OwlCarousel>

                                        </div>
                                    </section>
                                    </div>
                                )
                        }
                        )}
                        
                    </div>
                </div>)
            }
            )}
        </>;
    }
}

export default SuccessStories;