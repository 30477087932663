import React from "react";

const OnlineTestSeriesJeeMain = () => {


    return (
      <>
        <div className="">
            <div className="banner">
                <div className="container banner-content">
                    <h4 style={{textTransform: "capitalize"}}>{"Online Test Series JEE Main"}</h4>
                    {/* <!--<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis aliquam dolorem perferendis
                        quibusdam,<br>
                        assumenda perspiciatis necessitatibus deleniti consequuntur blanditiis libero minima <br>sed
                        cupiditate
                        veniam! Impedit, sunt! Fuga magni voluptate veniam.</p>--> */}
                </div>
            </div>
        </div>
        <div className="container mt-5">
          <div className="row">
           
            {/* About us Introduction */}
            <div
              className="col-12 col-sm-9 container tab-content"
              id="nav-tabContent"
            >
               {/* Jee Main */}
               <div
               
                id="list-onlinetestseries-jeemain"
                role="tabpanel"
                aria-labelledby="onlinetestseries-jeemain-tab"
              >
                <div className="">
                  <img src="" alt="" className="img-fluid mb-3" />{" "}
                  <p>
                    Jee Main Motivated by the passion for making education accessible to
                    all sections of society, ALLEN Digital is providing
                    customized courses to students from economically weaker
                    sections of society. This initiative to remove barriers to
                    learning and promote social change and equal opportunity is
                    a joint effort by ALLEN Digital and Infosys.
                  </p>
                  <p>
                    This initiative will benefit one lakh economically weak
                    students. They will learn from India's best faculty pool for
                    JEE and NEET examinations at ALLEN.
                  </p>
                </div>
              </div>
             </div>
          </div>
        </div>
      </>
    );
  }


export default OnlineTestSeriesJeeMain;
