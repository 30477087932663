import React, { useEffect, useState } from "react";
import EcomOrderList from "../../components/Ecom/EcomOrderList";
import EcomProfile from "../../components/Ecom/EcomProfile";
import Modal from "react-modal";
// import { environment } from "../../Services/HttpData";
import { Link } from "react-router-dom";

import { Oval } from "react-loader-spinner";
import noOrder from "../../images/no-order.png";
import TabContainer from "../../components/TabContainer/TabContainer";
import MyRequest from "../MyRequest/MyRequest";
import EcomMyCourseList from "./EcomMyCourseList";
import EcomUserAddress from "../../components/Ecom/EcomUserAddress";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import EcomCheckoutPayment from "../../components/Ecom/EcomShowAddress";
import { validationFunction } from "../../components/Ecom/ValidationHelper";
import MixpanelInit from "../../components/Mixpanel/MixpanelInit";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);
const EcomOrder = () => {

	//update by dinesh

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			width: "30%",
			height: "100% !important",
		},
	};




	const history = useHistory();
	const [OrderItems, setOrderItems] = useState([]);
	const [loading, setLoading] = useState(false);
	const [currentDate, setCurrentDate] = useState("-");
	const [swichProfileButton, setswichProfileButton] = useState(false);

	const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : "";

	const [profile, setProfile] = useState();

	const wid_data = window.localStorage.getItem("IS_DASHBOARD_ICON")
		? window.localStorage.getItem("IS_DASHBOARD_ICON")
		: false;

	let authToken = "";

	let profile_data = {
		profile_id: "",
		form_no: "",
		student_name: "",
		is_login: false
	}

	const [getOpen, setOpen] = useState(false);
	const [isMobileOTPSent, setMobileOTPSent] = useState(false);
	const [buttonmobileName, setbuttonmobileName] = useState("Send OTP");
	const [getTimeInSec, setTimeInSec] = useState("");
	const [getTimeInSecEmail, setTimeInSecEmail] = useState("");
	const [isMobileOtp, setMobileOtp] = useState("");
	const [isMobileOTPReSent, setMobileOTPReSent] = useState(false);

	// const [isEmailOTPSent, setEmailOTPSent] = useState(false);
	// const [buttonEmailName, setbuttonEmailName] = useState("Send OTP");
	// const [getEmailTimeInSec, setEmailTimeInSec] = useState("");
	// const [isEmailOtp, setEmailOtp] = useState("");
	// const [isEmailOTPReSent, setEmailOTPReSent] = useState(false);
	// const [getAction, setAction] = useState("");
	const [isMobile, setMobile] = useState("");
	const [mobileInputBox, setmobileInputBox] = useState(true);
	const [isProfileData, setProfileData] = useState(profile_data);
	const [isProfileCount, setProfileCount] = useState({ count: 0, selectProfile: 0 });
	const [getResultData, setResultData] = useState("");


	var phoneno = /^\+?([6-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/;

	const clearMobileEmailOTPData = () => {
		setOpen(false);
		setMobileOTPSent(false);
		setbuttonmobileName("Send OTP");
		setTimeInSec("");
		setMobileOtp("");
		setTimeInSecEmail("");
		setMobileOTPReSent(false);

		//setEmailOTPSent(false);
		//setbuttonEmailName("Send OTP");
		//setEmailTimeInSec("");
		//setEmailOtp("");
		//setEmailOTPReSent(false);
		//setAction("");
	};

	const handleMobileOtp = e => {
		const { name, value } = e.target;
		setMobileOtp(value);
	};


	//update by dinesh
	var a = 60;
	const setTime = () => {
		a = +a - 1;
		setTimeout(() => {
			if (a >= 0) {
				setTimeInSec(`Resend OTP in ${a} sec`);
				setTime();
			} else {
				setMobileOTPReSent(true);
			}
		}, 1000);
	};

	// var b = 60;
	// const setTimeEmail = () => {
	//  b = +b - 1;
	//  setTimeout(() => {
	//      if (b >= 0) {
	//          setTimeInSecEmail(`Resend OTP in ${b} sec`);
	//          setTimeEmail();
	//      } else {
	//          setEmailOTPReSent(true);
	//      }
	//  }, 1000);
	// };

	const sendOTP = e => {
		if (isMobile.trim().length < 10) {
			toast.error("Please Enter Valid Mobile Number");
			return false;
		}
		if (isMobile.trim().match(phoneno) === null || isMobile[0] === "+") {
			toast.error("Please Enter Valid Mobile Number");
			return false;
		}
		setmobileInputBox(true);
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({}),
		};
		var reqURL;
		reqURL = process.env.REACT_APP_ECOM_URL + "otp/otp_generate";
		requestOptions.body = JSON.stringify({ mobile: isMobile, country_code: "+91", lead_type: 914 });
		fetch(reqURL, requestOptions)
			.then(async response => {
				const data = await response.json();

				if (data.replyCode === "success") {
					a = 60;
					toast.success("OTP Sent Successfully on Registered Mobile Number");
					setTime();
					setMobileOTPSent(true);
					setMobileOTPReSent(false);
					setbuttonmobileName("Verify OTP");
				} else {
					toast.error(data.replyMsg);
				}
			})
			.catch(error => {
				this?.setState({ errorMessage: error.toString() });
				console.error("There was an error!", error);
			});
	};

	const editmobile = e => {
		const { value, name } = e.target;
		setMobile(value);
	};


	const verifyMobileOtp = () => {
		if (isMobileOtp?.length < 6) {
			toast.warning("Please Enter valid OTP number");
			return false;
		}
		fetch(process.env.REACT_APP_ECOM_URL + "otp/otp_verify", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ mobile: isMobile, otp: isMobileOtp, lead_type: 914 }),
		}).then(async response => {
			const data = await response.json();
			//console.log(data);
			if (data.replyCode === "success") {
				// toast.success(data.replyMsg);

				toast.success("Mobile No. Verified Successfully");
				setbuttonmobileName("Verified");
				setMobileOTPSent(false);
			} else {
				toast.error(data.replyMsg);
			}
		});
	};


	const deleteUserProfile = e => {
		if (buttonmobileName === "Verified") {
			fetch(process.env.REACT_APP_LOGIN_URL + `delete/profile/${isProfileData.profile_id}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": 'Bearer ' + auth_token,
				},
			}).then(async response => {
				setOpen(false)
				const data = await response.json();
				if (data?.status === "success") {
					toast.success("Student Profile Deleted Successfully");
					setTimeout(() => {
						getResultData?.data?.splice(isProfileCount?.selectProfile, 1)
						localStorage.removeItem("apiAccessToken");
						localStorage.removeItem("IS_DASHBOARD_ICON");
						localStorage.removeItem("FORM_NO");
						localStorage.removeItem("FORM_ID");
						MixpanelInit("Reset", "", "")
						window.location = "/";

						clearMobileEmailOTPData()
					}, 3000);
				} else {
					toast.error(data.message);
					clearMobileEmailOTPData()
				}
			});

		} else {
			toast.error('Please verify your mobile number first');
		}



	};

	// fetch url of user Profile
	useEffect(() => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + auth_token);

		var raw = "";

		var requestOptions = {
			method: "GET",
			headers: myHeaders,
		};

		fetch(process.env.REACT_APP_LOGIN_URL + "profile/details", requestOptions)
			.then(response => response.json())
			.then(result => {
				if (result?.status === "success") {
					setProfile(result?.data);
				}
			})
			.catch(error => console.log("error", error));
		// Auth token fetch api
		// const requestOptionsAuth = {
		//   method: "POST",
		//   headers: { "Content-Type": "application/json" },
		// };
		// fetch(
		//   environment.baseUrl + "genrateAuthTokentBusinessSite",
		//   requestOptionsAuth
		// ).then((resp) => {
		//   // fetching api url of profile
		//   resp.json().then((result) => {
		//     authToken = result.body.access_token;
		//     ////console.log("acc",authToken);
		//     // User Profile Fetch
		//     const requestOptions = {
		//       method: "POST",
		//       headers: { "Content-Type": "application/json" },
		//       body: JSON.stringify({
		//         wid: "",
		//         access_token: authToken,
		//       }),
		//     };
		//     fetch(environment.baseUrl + "profile_details", requestOptions).then(
		//       (resp) => {
		//         // fetching api url of profile
		//         resp.json().then((result1) => {
		//           setProfile(result1.data);

		//           // set result in state this will set current/updated value of profile
		//           ////console.log("profile",result1.data);
		//           if (result1.data.dob) {
		//             var cd = new Date(result1.data.dob); // Now
		//             cd.setDate(cd.getDate()); // Set now + 30 days as the new date
		//             let current_date =
		//               cd.getDate() +
		//               "/" +
		//               (cd.getMonth() + 1) +
		//               "/" +
		//               cd.getFullYear();
		//             setCurrentDate(current_date);
		//           }

		//           ////console.log("tdob",current_date);
		//         });
		//       }
		//     );
		//   });
		// });
	}, []);

	// fetch url for products/courses listing
	useEffect(() => {
		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: "Basic " + auth_token,
			},
		};
		fetch(environment.ecomApiUrl + "order/list", requestOptions).then(resp => {
			// fetching api url for courses listing
			resp.json().then(result => {
				setOrderItems(result.data);

				// set result in state this will set current/updated value of setProduct to product
				//console.log(OrderItems);
				setLoading(true);
			});
		});
	}, []);

	const tabData = [
		{ title: "My Orders", active: true, dropdown: false },
		{ title: "My Address", active: false, dropdown: false },
		{ title: "My Courses", active: false, dropdown: false },
		{ title: "My Request", active: false, dropdown: true },
	];
	const [tabContainerData, SetTabContainerData] = useState(tabData);
	const tabChange = e => {
		let tabArray = tabData.map((val, ind) => {
			if (e.target.textContent === val.title) {
				return { ...val, active: true };
			} else {
				return { ...val, active: false };
			}
		});
		SetTabContainerData(tabArray);
	};

	const getProfileListData = (check) => {
		if (check !== "") {
			setMobile(profile?.mobile_no?.split("-").length > 1 ? profile?.mobile_no?.split("-")[1] : profile?.mobile_no)
		} else {
			setswichProfileButton(true);

		}

		let mobileNo = profile?.mobile_no?.split("-").length > 1 ? profile?.mobile_no?.split("-")[1] : profile?.mobile_no;
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + auth_token);



		var requestOptions = {
			method: "GET",
			headers: myHeaders,
		};

		fetch(process.env.REACT_APP_LOGIN_URL + "profile/profile-list", requestOptions)
			.then(response => response.json())
			.then(result => {
				if (check !== "") {
					//console.log("result", result?.data)
					setOpen(true)

					setResultData(result?.data)
					result?.data?.data?.map((item, index) => {
						if (item.is_login) {
							setProfileCount({ count: result?.data?.data?.length, selectProfile: index })
							setProfileData(item)
						}
					})

				} else {
					if (result?.status === "success") {
						setswichProfileButton(false);
						history.push({ pathname: "/user-details", state: { data: result?.data?.data, token: result?.data?.token, previous_Path: window.location.pathname, mobile_no: mobileNo } });
					} else {
						setswichProfileButton(false);
						toast.error(result?.message);
					}
				}

			})
			.catch(error => {
				setswichProfileButton(false);
				console.log("error", error);
			});
	};

	if (!wid_data) {
		history.push("/");
	}


	return (
		<>
			<div className='container'>
				<div className='row'>
					<div className='col-12 col-md-12 mt-3 mt-md-5 shopingcart'>
						<div className='align d-flex aling-items-center justify-content-between flex-wrap'>
							<h4 className='font-weight-bold cart-title mb-0'>My Profile</h4>
							<Link to='/courses/' className='ml-auto text-success cart-shop-link line-height-normal'>
								Continue Shopping
							</Link>
						</div>

						<div className='student-profile-box mb-3 mt-2 mt-md0'>
							<div className="row">
								<div className="col-12">
									<div className="d-flex align-items-center justify-content-between flex-wrap">
										<h4 className='text-success text-decoration-none text-nowrap mr-2 mb-0'>Student Details</h4>

										<li className='list-inline-item'>
											<button
												className='btn btn-green text-white read-reciept-btn'
												type='button'
												data-toggle='tooltip'
												data-placement='top'
												title='Switch Profile'
												disabled={swichProfileButton}
												onClick={() => getProfileListData('')}>
												<i class='fa fa-edit mr-2'> </i>
												Switch Profile
											</button>
										</li>
									</div>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-6 col-12'>
									<div className='student-detail-left'>
										<div className='row'>
											<div className='col-md-5 col-4 col-lg-4 pr-0'>
												<ul className='m-0 p-0 list-lable'>
													<li>Name :</li>
													<li>Mobile No. :</li>
													<li>Registration No. :</li>
												</ul>
											</div>
											<div className='col-md-7 col-8 col-lg-8'>
												<ul className='m-0 p-0 list-detail'>
													{profile ? (
														<>
															<li>
																{profile.student_name ? profile.student_name : "-"}
																{profile.middle_name ? " " + profile.middle_name : " "}
																{profile.last_name ? " " + profile.last_name : " "}
															</li>
															<li>{profile.mobile_no ? profile.mobile_no : "-"}</li>
															<li>{profile?.form_no ? profile?.form_no : "-"}</li>
														</>
													) : null}
												</ul>
											</div>
										</div>
									</div>
								</div>

								<div className='col-md-6 col-12'>
									<div className='student-detail-right'>
										<div className='row'>
											<div className='col-md-4 col-4 pr-0'>
												<ul className='m-0 p-0 list-lable'>
													<li>Date of Birth :</li>
													<li>Email :</li>
												</ul>
											</div>
											<div className='col-md-8 col-8'>
												<ul className='m-0 p-0 list-detail'>
													{profile ? (
														<>
															<li>{profile?.dob ? profile?.dob : "-"}<br /></li>

															<li>{profile.email ? profile.email : "-"}</li>
														</>
													) : null}
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<TabContainer contant={tabContainerData} tabChange={tabChange} />

						{/* <span className="border-bottom-green my-order-text">My Orders</span> */}
						{/* Tab 1 */}
						<>{tabContainerData[0].active ? <>

							{(
								loading ? (
									OrderItems.length ?
										(
											OrderItems.map((itm, i) => {
												return (
													<EcomOrderList
														key={itm.order_id}
														product_title={itm.product_title}
														divId={i + 1}
														order_amount={itm.order_amount}
														mrp={itm.mrp}
														order_id={itm.order_id}
														order_invoice_no={itm.order_invoice_no}
														order_date={itm.order_date}
														product_slug={itm.product_slug}
														order_status={itm.order_status}
														is_download_invoice={itm.is_download_invoice}
														plan_type={itm.plan_type}
														emi={itm.emi}
														reciepts={itm.reciepts}
														pending_due={itm.pending_due}
														product_description={itm.product_description}
														image={itm.product_image}
														is_propelld={itm.is_propelld}
														loan_status={itm.loan_status}
														loan_applied={itm.loan_applied}
														loan_sanction={itm.loan_sanction}
														down_payment={itm.down_payment}
														course_fee={itm.course_fee}
														payment_gateway_data={itm}
														form_no={profile?.form_no}
													/* onChange={this.handleChildClick.bind(this)} */
													/>
												);
											})
										) : (
											<div className='text-center'>
												<img src={noOrder} className='no-order mt-5'></img>
												<p className='m-1'>
													<b>No Order Found</b>
												</p>
												<p>Looks Like! You haven't made your order yet.</p>
											</div>
										)
								) : (
									<Oval color='#00BFFF' height={80} width={80} />
								)
							)}
							<div className="text-right"><button type='button' className='btn btn-green text-white read-reciept-btn mt-3 w-auto' onClick={() =>
								getProfileListData('delete profile')}>
								Delete User Profile
							</button></div>

							<Modal
								appElement={document.getElementById("root")}
								isOpen={getOpen}
								// onAfterOpen={afterEditOpenModal}
								// onRequestClose={() => CourierSetup('')}
								style={customStyles}
								contentLabel='Example Modal'
								id='propelldotpverify'>
								<div style={{ textAlign: "end" }}>
									<button className='btn btn-sm btn-danger mb-2' onClick={() => clearMobileEmailOTPData()}>
										X
									</button>
								</div>

								<div>
									{/* <div className="form-group row"> */}
									<label htmlFor='pincode' className=' col-form-label col-lg-12 p-0'>
										Mobile No. *{" "}
									</label>
									<div className='col-lg-12 p-0' style={{ display: "flex", alignItems: "center", gap: "15px" }}>
										<input
											required
											type='text'
											className='form-control'
											id='mobile_number'
											name='student_mobile_no'
											maxLength={14}
											disabled={mobileInputBox}
											value={isMobile}
											placeholder=''
											onChange={editmobile}
										/>
										{/* {!isMobileOTPSent && <i className='fas fa-edit' onClick={e => setmobileInputBox(false)}></i>}{" "} */}
									</div>

									{/* </div> */}
									<div className='form-group row justify-content-center m-0 p-0 mt-2 col-lg-12'>
										{isMobileOTPSent ? (
											<>
												<div className=' col-lg-8 mob_num_otp p-0'>
													<input
														required
														type='number'
														className='form-control otp-input'
														id='mobile_number'
														name='student_mobile_no'
														maxLength={6}
														value={isMobileOtp}
														placeholder='OTP'
														onChange={handleMobileOtp}
													/>
												</div>
											</>
										) : null}

										<button
											type='button'
											className='btn btn-primary btn-save-add btn-verify col-lg-4'
											onClick={buttonmobileName === "Send OTP" ? sendOTP : verifyMobileOtp}>
											{buttonmobileName}
										</button>
									</div>

									{isMobileOTPSent ? (
										<>
											<div className='form-group row justify-content-center'>
												<div>
													{isMobileOTPReSent ? (
														<>
															<span
																className='resendotppropelld'
																type='button'
																// className="btn btn-primary btn-save-add "
																onClick={sendOTP}>
																Re-Send OTP
															</span>
														</>
													) : (
														<span className='resendotppropelld'>{getTimeInSec}</span>
													)}
												</div>
											</div>
										</>
									) : null}

									<br />
									{isProfileCount?.count == 1 && (
										<div className="alert alert-warning" role="alert">
											This is Your Last Profile, Do You Really Want to Delete?
										</div>
									)}
									<br />
									<button type='button' className='btn btn-primary btn-save-add' onClick={() => deleteUserProfile()}>
										Delete
									</button>
								</div>
							</Modal>
						</>
							: null}</>
						{tabContainerData[1]?.active ? <EcomCheckoutPayment /> : null}
						{/* {tabContainerData[1]?.active ? <EcomUserAddress /> : null} */}
						{/* Tab2 */}
						{/* {tabContainerData[1]?.active ? <EcomMyCourseList/>  : null} */}

						{/* Tab3 */}
						{tabContainerData[2]?.active ? <EcomMyCourseList /> : null}
						{tabContainerData[3]?.active ? <MyRequest /> : null}
					</div>
					<EcomProfile />
				</div>
			</div>
		</>
	);
};

export default EcomOrder;