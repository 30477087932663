import React, { useEffect, useState } from "react";
// import { environment } from "../../Services/HttpData";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import "../../css/ecom/ecomStyle.css";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
const ThankyouPage = () => {
  const { orderId } = useParams();
  const [orderStatus, setOrderStatus] = useState([]);
  const [success, setSuccess] = useState("none");
  const [failed, setFailed] = useState("none");
  const auth_token = localStorage.getItem("apiAccessToken") ? localStorage.getItem("apiAccessToken") : '';
  const [loading, setLoading] = useState(false);
  const wid_data = window.localStorage.getItem("IS_DASHBOARD_ICON")
    ? window.localStorage.getItem("IS_DASHBOARD_ICON")
    : false;
  useEffect(() => {

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': 'Basic ' + auth_token
      }
    };
    fetch(environment.ecomApiUrl + 'order/status/' + orderId, requestOptions).then((resp) => { // fetching api url for courses listing
      resp.json().then((result) => {
        setOrderStatus(result.data.order_status);  // set result in state this will set current/updated value of setProduct to product
        //console.log("orderstatus "+result.data.order_status);
        setLoading(true);
        if (result.data.order_status === "complete") {
          setSuccess("block");
        } else {
          setFailed("block");
        }
      })
    })
  }, []);

  if (!wid_data) {
    window.location.href = "/";
  }
  return (
    <>
      <div className="container pr-0 mt-5" >
        <div className="col-md-12">
          {
            orderStatus === "complete" ?
              (
                <>

                  <div className="success-cotent-top text-center" >
                    <i className="fa-solid fa-circle-check"></i>
                    <h4> Thank You !!</h4>
                    {/*  <span className="order-id mt-5"> order Id : {orderId} </span><br/> */}
                    <span className="order-id mt-5"> Payment received successfully</span><br />

                    <div className="mt-5"></div>
                    <div className="success-bottom-btn mt-4">
                      <Link className="btn my-font order-history-btn" to="/">Go To Home</Link>
                      {/*  <button className="btn my-font order-history-btn2">Start Learning</button> */}
                    </div>
                  </div>

                </>
              ) :
              (
                <>

                  <div className="failed-cotent-top text-center">
                    <i className="fa-solid fa-circle-xmark"></i>
                    <h4>  Payment has been failed !!</h4>
                    {/* <span className="order-id mt-5"> order Id : {orderId} </span><br/> */}

                    <div className="mt-5"></div>

                    <div className="success-bottom-btn mt-4">
                      <Link className="btn my-font order-history-btn" to="/profile">Go To Profile</Link>
                    </div>
                  </div>

                </>
              )
          }

        </div>
      </div>



    </>
  );
}

export default ThankyouPage;
