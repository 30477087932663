import React from "react";
import { toast } from 'react-toastify';
import ArticleHeading from "../components/Articles/ArticleHeading";
// import { environment } from "../Services/HttpData";
import ArticleParaghraph from "../components/Articles/ArticleParaghraph";
import ArticleImage from "../components/Articles/ArticleImage";
import ArticlePdfDownload from "../components/Articles/ArticlePdfDownload";
import ArticlesContent from "../components/Articles/ArticlesContent";
import ArticleChapters from "../components/Articles/ArticleChapters";
import FaqDetails from "../components/CoursesDetails/FaqDetails";
import ArticleChapterLink from "../components/Articles/ArticleChapterLink";
import ArticlesFilter from "../components/Articles/ArticlesFilter";
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)

class ArticleDetails extends React.Component {
  constructor(props) {
    super(props);
    // //console.log(this.props);
    this.apiAccessToken = sessionStorage.getItem("apiAccessToken");
    this.state = {
      articleDetailsData: {},
      navigationsList: [],
      faqsList: [],
    };
  }
  componentWillReceiveProps(props) {
    //console.log(props.match.params.slug);
    const slug = props.match.params.slug;
    this.fetchArticleDetails(slug);
  }

  async componentDidMount() {
    // //console.log(this.props.match.params.slug);
    const slug = this.props.match.params.slug;
    this.fetchArticleDetails(slug);
  }
  async fetchArticleDetails(slug) {
    // POST request using fetch with async/await
    const requestOptions = {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ slug: slug }),
    };
    fetch(environment.baseUrl + "blog_details", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data = /* isJson && */ await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        if (data.replyCode === "success") {
          // this.fetchArticleSubNavigations(data.data.category_id);
          data.data.blog_section.map((item)=>{
            if(item.type==="faq"){
              this.fetchArticleFAQs(item.id)
            }})
          data.data.blog_section.sort((a, b) => a.priority > b.priority ? 1 : -1);
          //console.log(data.data);
          this.setState({ articleDetailsData: data.data })
          // this.getCourses();
        } else {
          console.error(data.body.message);
          return null;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }
  
  async fetchArticleFAQs(section_id) {
    // POST request using fetch with async/await
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ section_id: section_id.toString() }),
    };
    fetch(environment.baseUrl + "blog_section_faq_list", requestOptions)
      .then(async (response) => {
        // const isJson = response.headers.get('content-type')?.includes('text/html; charset=utf-8');
        const data = /* isJson && */ await response.json();
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        if (data.replyCode === "success") {
          //console.log(data.data);
          this.setState({ faqsList: data.data })
          // this.getCourses();
        } else {
          console.error(data.body.message);
          return null;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });

  }
  handleChildClick(pindx, cindex) {
    /* //console.log(
      "The Child button data is: ",
      pindx,
      cindex,
      this.state.courses.filters[pindx].type
    ); */
    // return;
    let courses = this.state.courses;
    var filtersData = courses?.filters.map((item, pindex) => {
      item.content = item.content.map((itm, cidx) => {
        if (pindx === pindex && cidx === cindex) {
          if (!itm.isChecked) {
            let selectedFilters = this.state.selectedFilters;
            if (selectedFilters[this.state.courses.filters[pindx].type] === undefined) {
              // //console.log("pushed");
              selectedFilters[this.state.courses.filters[pindx].type] = [];
            }
            selectedFilters[this.state.courses.filters[pindx].type].push(itm.type);
            this.setState({ selectedFilters: selectedFilters });
          } else {
            let selectedIndex = this.state.selectedFilters[this.state.courses.filters[pindx].type].findIndex(
              (filter) => filter === itm.type
            );
            let selectedFilters = this.state.selectedFilters;
            selectedFilters[this.state.courses.filters[pindx].type].splice(selectedIndex, 1);
            this.setState({ selectedFilters: selectedFilters });
          }
          itm.isChecked = !itm.isChecked;
        }
        return itm;
      });
      return item;
    });
    // //console.log(this.state.selectedFilters);
    this.getCourses(false);
    courses.filters = filtersData;
    this.setState({ courses: courses });
  }
  handleCartCount(status) {
    this.props.onChange(status);
  }
  render() {
    return (
      <>
        <div className="container pt-4">
          <div className="row">
            {/* <nav className=" col-lg-3 col-md-3 d-none d-sm-block sidebar filters"> */}
              <ArticlesFilter />
            {/* </nav> */}
            <article className="col-lg-9 col-md-9 d-none d-sm-block">
              <ArticleHeading data={this.state.articleDetailsData?.title} />
              <ArticleParaghraph data={this.state.articleDetailsData?.description} />
              {(this.state.articleDetailsData?.featured_images !== "" && this.state.articleDetailsData?.featured_images !== undefined) && (<ArticleImage data={this.state.articleDetailsData?.featured_images} />)}
              {this.state.articleDetailsData?.blog_section?.length > 0 && this.state.articleDetailsData?.blog_section.map((section, index) => {
                return (<div key={section.id+index}>
                  {(section.type === "description" && section.description !== "") && <ArticlesContent data={section} />}
                  {(section.type === "download_pdf") && <ArticlePdfDownload data={section} />}
                  {(section.type === "navigation_grid") && (<>
                    {section.title!=="" && section.title!==undefined && <div className="col-12"><ArticleHeading data={section.title} /></div>}
                    {(this.state.navigationsList.length>0 && (this.state.navigationsList[0]?.sub_category!==undefined && this.state.navigationsList[0]?.sub_category.length>0)) && this.state.navigationsList[0]?.sub_category.map((nav) => {
                      return <ArticleChapters key={nav.id} data={nav} />
                    })}
                    </>
                    )
                  }
                  {(section.type === "navigation_list") && (<div className="row">
                    {section.title!=="" && section.title!==undefined && <div className="col-12"><ArticleHeading data={section.title} /></div>}
                    {(this.state.navigationsList.length>0 && this.state.navigationsList[0].sub_category.length>0) && this.state.navigationsList[0]?.sub_category.map((nav) => {
                      return <div className="col-md-6" key={nav.id}><ArticleChapterLink data={nav} /></div>
                    })}
                  </div>
                  )}
                  {(section.type === "faq") && <FaqDetails data={this.state.faqsList} />}
                </div>
                )
              })
              }

              {/* <ArticlePdfDownload /> */}
              {/* <ArticlesContent />
              <ArticlesContent /> 
              <ArticleChapters />
              <ArticleChapters />
              <ArticleChapters />
              <ArticleChapters />
              <ArticleChapters />
              <ArticleChapters />
              <ArticleChapters />
              <ArticleChapters />
              <FaqDetails />
              <ArticlesContent />
              <div className="row">
                <div className="col-md-6">
                  <ArticleChapterLink />
                  <ArticleChapterLink />
                  <ArticleChapterLink />
                  <ArticleChapterLink />
                </div>
                <div className="col-md-6">
                  <ArticleChapterLink />
                  <ArticleChapterLink />
                  <ArticleChapterLink />
                  <ArticleChapterLink />
                </div>
              </div>
              <ArticlesContent />
              <div className="row">
                <div className="col-md-6">
                  <ArticleChapterLink />
                  <ArticleChapterLink />
                  <ArticleChapterLink />
                  <ArticleChapterLink />
                </div>
                <div className="col-md-6">
                  <ArticleChapterLink />
                  <ArticleChapterLink />
                  <ArticleChapterLink />
                  <ArticleChapterLink />
                </div>
              </div>*/}
            </article>
          </div>
        </div>
      </>
    );
  }
}

export default ArticleDetails;
