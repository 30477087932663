import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { environment } from "../../Services/HttpData";
import iit from "../../images/iit-main.jpg";
import RazorPayApiInti from "../propelld/RazorPayApiInti";
import Modal from "react-modal";

const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT);
const EcomOrderList = (props) => {
  const [modalIsOpenFirstConfirm, setIsOpenFisrtConfirm] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [is_already_admission, set_is_already_admission] = useState({
    is_already_admission: false,
    current_name: ""
  })

  const [aciInvoice, setAciInvoice] = useState([])
  let invoiceSN = 0


  const downloadInvoice_FromAci = () => {
    const auth_token = localStorage.getItem("apiAccessToken")
      ? localStorage.getItem("apiAccessToken")
      : "";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + auth_token,
      },
      body: JSON.stringify({
        "fno": props?.form_no // 
      }),
    };
    fetch(environment.ecomApiUrl + "order/download-invoice-aci", requestOptions)
      .then((response) => response.json()).then(res => {
        if (res?.status === "success") {
          setAciInvoice(res?.data?.Table)
        }
      })

  }

  function closeModalFirstConfirm() {
    setIsOpenFisrtConfirm((pre) => !pre);
  }
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // paddingBottom: "5%",
      borderRadius: "30px !important",
      transform: "translate(-50%, -50%) !important",
      backgroundColor: "rgb(0 0 0 / 75%)",
      border: "8px solid rgb(204, 204, 204)",

    },
    overlay: {
      // padding:"20px 20px !important",
      // maxWidth:" 600px !important",
      background: "rgb(0 0 0 / 75%)",
    },
  };
  let payDue = props.pending_due;

  const downloadInvoice = (invoiceId, isInovice) => {
    const auth_token = localStorage.getItem("apiAccessToken")
      ? localStorage.getItem("apiAccessToken")
      : "";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + auth_token,
      },
      body: JSON.stringify({
        invoice_id: invoiceId,
        is_invoice: isInovice
      }),
    };
    fetch(environment.ecomApiUrl + "order/download-invoice", requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        if (isInovice) {
          link.setAttribute("download", `Invoice.pdf`);
        } else {
          link.setAttribute("download", `Acknowledgement.pdf`);
        }


        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  function readMore(slug) {
    var dots = document.getElementById("dots-" + slug);
    var moreText = document.getElementById("more-" + slug);
    var btnText = document.getElementById("readMoreBtn-" + slug);

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "";
      moreText.style.display = "inline";
    }
  }



  function myFunction(order_id) {
    var x = document.getElementById("myDIV-" + order_id);
    var y = document.getElementById("myDIV2-" + order_id);
    var z = document.getElementById("myDIVarrow-" + order_id);
    if (y) {
      y.style.display = "none";
    }
    if (x.style.display === "block") {
      x.style.display = "none";
      z.style.transform = "rotate(360deg)";
    } else {
      x.style.display = "block";
      z.style.transform = "rotate(180deg)";
    }
  }

  function myFunction2(order_id) {
    var x = document.getElementById("myDIV2-" + order_id);
    var y = document.getElementById("myDIV-" + order_id);
    var z = document.getElementById("myDIVarrow2-" + order_id);
    if (y) {
      y.style.display = "none";
    }
    if (x.style.display === "block") {
      x.style.display = "none";
      z.style.transform = "rotate(360deg)";
    } else {
      x.style.display = "block";
      z.style.transform = "rotate(180deg)";
    }
  }

  // Current Date
  var cd = new Date(); // Now
  cd.setDate(cd.getDate()); // Set now + 30 days as the new date
  let current_date =
    cd.getDate() + "/" + (cd.getMonth() + 1) + "/" + cd.getFullYear();

  var date = new Date(); // Now
  date.setDate(date.getDate() + 30); // Set now + 30 days as the new date
  let date_30 =
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

  var dt = new Date(); // Now
  dt.setDate(dt.getDate() + 60); // Set now + 60 days as the new date
  let date_60 =
    dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();

  let orderdt = new Date(props.order_date);
  let order_date =
    orderdt.getDate() +
    "/" +
    (orderdt.getMonth() + 1) +
    "/" +
    orderdt.getFullYear();
  // //console.log(order_date);

  useEffect(() => {
    downloadInvoice_FromAci()
  }, [])

  const convertBS64ToPDF = (bas64) => {
    const binaryData = atob(bas64);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: "application/pdf" });
    let pdfUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = pdfUrl;
    downloadLink.download = "ADPLInvoice.pdf"; // Specify the filename
    downloadLink.textContent = "Download PDF";
    downloadLink.click()
  }


  return (
    <>
      {props?.plan_type === "Emi" ? (
        <div className="card px-4 py-2 mb-4 course-card" key={props.order_id}>
          <div className="row  ">
            <div className="col-md-4">
              <img
                src={props.image === null ? iit : props.image}
                alt=""
                width="100%"
              />
            </div>
            <div className="col-md-4">
              <a
                href={"ecom/courses-details/" + props.product_slug}
                className="cart-list-title"
              >
                {props.product_title}
              </a>
              <span className="cut-text" title={props.product_description}>
                {props.product_description}Receipt
              </span>
              <p className="cart-list-desc p-0 m-0">{"Date : " + order_date}</p>

              {payDue ? (
                <>
                  <div className="success-bottom-btn mt-4">
                    <Link
                      className="btn btn-success"
                      to={"/ecom/checkout/" + props.order_id}
                    >
                      Pay Due
                    </Link>
                  </div>
                </>
              ) : null}
            </div>
            <div className="col-md-4 relative">
              <div className="third-box-cnt">
                {props.order_status == "Paid" ? (
                  <>
                    <div className="d-flex justify-content-end">
                      <span
                        className="full-course-order-list"
                        type="button"
                        onClick={() => myFunction(props.order_id)}
                      >
                        Installment{" "}
                        <i
                          className="fa-solid fa-chevron-down"
                          id={"myDIVarrow-" + props.order_id}
                        ></i>
                      </span>
                      <span
                        className="full-course-order-list2"
                        type="button"
                        onClick={() => myFunction2(props.order_id)}
                        style={{
                          display: !(props.reciepts ? props.reciepts : [])
                            .length
                            ? "none"
                            : "",
                        }}
                      >
                        Receipt{" "}
                        <i
                          className="fa-solid fa-chevron-down"
                          id={"myDIVarrow2-" + props.order_id}
                        ></i>
                      </span>
                    </div>
                  </>
                ) : null}

                <div
                  className="card-body emi-card-body"
                  id={"myDIV-" + props.order_id}
                  style={{ display: "none" }}
                >
                  <span className="arrow-up-order-list"></span>
                  <table
                    width="100%"
                    border="0"
                    cellSpacing="0"
                    cellPadding="0"
                    className="course-table m-0 text-center"
                  >
                    <tr>
                      <th>SN</th>
                      <th>Price</th>
                      <th>Pay Date</th>
                      <th>Payment Status</th>
                    </tr>
                    {props.emi.map((itm, i) => {
                      return (
                        <>
                          <tr>
                            <td>{i + 1}</td>
                            <td>{itm.installment_amount}</td>
                            <td>
                              {itm.installment_days === 0 ? current_date : null}
                              {itm.installment_days === 30 ? date_30 : null}
                              {itm.installment_days === 60 ? date_60 : null}
                            </td>
                            <td>{itm.payment_status}</td>
                          </tr>
                        </>
                      );
                    })}
                  </table>
                </div>
                {/* Receipt Starts*/}
                <div
                  className="card-body emi-card-body absolute-right"
                  id={"myDIV2-" + props.order_id}
                  style={{ display: "none" }}
                >
                  <span className="arrow-up-order-list2"></span>
                  <div className="table-responsive">
                    <table
                      width="100%"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      className="course-table m-0 text-center"
                    >
                      <tr>
                        {/* <th>S.No.</th> */}
                        <th>Date</th>
                        <th>Fee Type</th>
                        <th>Payment Mode</th>
                        <th>Amount With Tax</th>

                        {
                          aciInvoice?.length !== 0 && <th>Invoice Password</th>
                        }
                        <th>View Invoice</th>
                        <th>View Acknowledgement</th>
                      </tr>
                      {(props.reciepts ? props.reciepts : []).map((rcp, ir) => {

                        let receiptdt = new Date(rcp.date);
                        let receipt_date =
                          receiptdt.getDate() +
                          "/" +
                          (receiptdt.getMonth() + 1) +
                          "/" +
                          receiptdt.getFullYear();
                        //console.log(receipt_date);
                        invoiceSN = ir + 1
                        return (
                          <>
                            <tr>
                              {/* <td>{ir + 1}</td> */}
                              <td>{receipt_date}</td>
                              <td>{rcp?.fee_type}</td>
                              <td>{rcp?.payment_mode}</td>
                              <td>{rcp.amount}</td>
                              {aciInvoice?.length !== 0 && <td>--</td>}
                              <td className="text-center">
                                {rcp?.invoice_id !== null ?
                                  (<a
                                    href={"javascript:;"}
                                    onClick={() => downloadInvoice(rcp.invoice_id, true)}
                                  >
                                    <i className="fa-solid fa-circle-arrow-down"></i>
                                  </a>) : "--"
                                }
                              </td>
                              <td className="text-center">
                                {rcp?.acknowledgement_id !== null ?
                                  (<a
                                    href={"javascript:;"}
                                    onClick={() => downloadInvoice(rcp.acknowledgement_id, false)}
                                  >
                                    <i className="fa-solid fa-circle-arrow-down"></i>
                                  </a>) : "--"
                                }
                              </td>
                            </tr>
                          </>
                        );
                      })}
                      {aciInvoice?.forEach((val, ind) => {
                        invoiceSN += 1
                        return (
                          <>
                            <tr>
                              {/* <td>{invoiceSN}</td> */}
                              <td>{val?.paydate}</td>
                              <td>{"Course Fee"}</td>
                              <td>{'Offline'}</td>
                              <td>{val?.amount}</td>
                              <td className="text-center">
                                {val?.invoice_base64 !== null ?
                                  (<a
                                    href={"javascript:;"}
                                    onClick={() => convertBS64ToPDF(val?.invoice_base64)}
                                  >
                                    <i className="fa-solid fa-circle-arrow-down"></i>
                                  </a>) : "--"
                                }
                              </td>
                              <td className="text-center">
                                --
                              </td>
                            </tr>
                          </>
                        )
                      })}
                    </table>
                  </div>
                </div>
                {/* Receipt Ends*/}
              </div>
            </div>
          </div>
        </div>
      ) : props?.is_propelld ? (
        /* Full Course Code Starts */
        <div className="card px-4 py-2 mb-4 course-card" key={props.order_id}>
          <div className="row  ">
            <div className="col-md-4">
              <img
                // className="padding-b-t"
                src={props.image === null ? iit : props.image}
                alt=""
                width="100%"
              />
            </div>
            <div className="col-md-8">
              <a
                href={"ecom/courses-details/" + props.product_slug}
                className="cart-list-title"
              >
                {props.product_title}
              </a>

              <p className="lessPtag" title={props.product_description}>
                <span id={"more-" + props.order_id} className="default-hide">
                  {props.product_description}
                </span>
                <span id={"dots-" + props.order_id} className="">
                  {props.product_description}...
                </span>
                <span
                  className="readMoreBtn"
                  onClick={() => readMore(props.order_id)}
                  id={"readMoreBtn-" + props.order_id}
                >
                  Read more
                </span>
              </p>

              {/* <span className="cut-text" title={props.product_description}>{props.product_description}</span> */}
              <div className="d-flex flex-wrap justify-content-between">
                <div>
                  <p className="cart-list-desc p-0 m-0">
                    {"Date : " + order_date}
                  </p>

                  <p className="cart-list-price-order p-0 m-0">
                    Loan applied : {"₹ " + props.course_fee}
                    <br />
                    {/* Loan applied : {"₹ " + props.loan_applied} <br /> */}
                    {props.loan_status == "Ready_for_Disbursal" ? (
                      <>
                        Loan approved : <b>{"₹ " + props.loan_sanction}</b>{" "}
                        <br />
                      </>
                    ) : null}
                    {props.loan_status !== "Ready_for_Disbursal" &&
                      props.loan_status !== 747 ? (
                      <>
                        Loan Status : {props.loan_status}
                        <br />
                      </>
                    ) : null}
                    {props.loan_status == 747 ? (
                      <>
                        Loan Status : {<b>Waiting for Propelld Approval</b>}
                        <br />
                      </>
                    ) : null}
                    Minimum Advance Payment :{"₹ " + props.down_payment}
                    <br />
                  </p>
                </div>
                <div className="d-flex align-items-end">
                  <div className="third-box-cnt">
                    {/* button for pay downpayment  */}
                    {/* {
                    props.loan_status == "approved"? 
                  } */}
                    {props.loan_status == "Ready_for_Disbursal" &&
                      props.order_status !== "Paid" ? (
                      <>
                        <div className="d-flex justify-content-end">
                          {/* <span className="full-course-order-list">
                          Full Course
                        </span> */}
                          <span
                            className="full-course-order-list2  mt-2"
                            type="button"
                          >
                            <button
                              className="btn btn-green text-white read-reciept-btn"
                              data-toggle="modal"
                              onClick={(e) => {

                                if (props?.payment_gateway_data?.is_already_admission) {
                                  set_is_already_admission({
                                    is_already_admission: true,
                                    current_name: props?.payment_gateway_data?.course_name
                                  })
                                } else {
                                  closeModalFirstConfirm()
                                }
                              }}
                            >
                              PayNow
                            </button>{" "}
                            <i id={"myDIVarrow2-" + props.order_id + "-1"}></i>
                          </span>
                        </div>
                      </>
                    ) : props.order_status == "Paid" &&
                      props.loan_status == "Ready_for_Disbursal" ? (
                      <>
                        <div className="d-flex justify-content-end">
                          {/* <span className="full-course-order-list">
                        Full Course
                      </span> */}
                          <span
                            className="full-course-order-list2 mt-2"
                            type="button"
                          >
                            <button
                              className="btn btn-green text-white read-reciept-btn"
                              data-toggle="modal"
                              data-target={"#exampleModalView-" + props?.divId}
                            >
                              View Receipt
                            </button>{" "}
                            <i id={"myDIVarrow2-" + props.order_id + "-1"}></i>
                          </span>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>

                {/* Receipt Starts*/}
                <div
                  id={"myDIV-" + props.order_id + props.order_id + "-1"}
                  style={{ display: "none" }}
                ></div>

                {/* Receipt Ends*/}
              </div>
              {payDue ? (
                <>
                  {/* <div className="success-bottom-btn mt-4">
                    <Link
                      className="btn btn-success"
                      to={"/ecom/checkout/" + props.order_id}
                    >
                      Pay Due
                    </Link>
                  </div> */}
                </>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        /* Full Course Code Starts */
        <div className="card px-4 py-2 mb-4 course-card" key={props.order_id}>
          <div className="row  ">
            <div className="col-md-4">
              <img
                // className="padding-b-t"
                src={props.image === null ? iit : props.image}
                alt=""
                width="100%"
              />
            </div>
            <div className="col-md-8">
              <a
                href={"ecom/courses-details/" + props.product_slug}
                className="cart-list-title"
              >
                {props.product_title}
              </a>

              <p className="lessPtag" title={props.product_description}>
                <span id={"more-" + props.order_id} className="default-hide">
                  {props.product_description}
                </span>
                <span id={"dots-" + props.order_id} className="">
                  {props.product_description}...
                </span>
                <span
                  className="readMoreBtn"
                  onClick={() => readMore(props.order_id)}
                  id={"readMoreBtn-" + props.order_id}
                >
                  Read more
                </span>
              </p>

              {/* <span className="cut-text" title={props.product_description}>{props.product_description}</span> */}
              <p className="cart-list-desc p-0 m-0">{"Date : " + order_date}</p>

              <p className="cart-list-price-order p-0 m-0">
                Price : {"₹ " + props.order_amount} <br />
                Status : {props.order_status}
              </p>
              <div className="third-box-cnt">
                {props.order_status == "Paid" ? (
                  <>
                    <div className="d-flex justify-content-end">
                      {/* <span className="full-course-order-list">
                      Full Course
                    </span> */}
                      <span className="full-course-order-list2" type="button">
                        <button
                          className="btn btn-green text-white read-reciept-btn"
                          data-toggle="modal"
                          data-target={"#exampleModalView-" + props?.divId}
                        >
                          View Receipt
                        </button>{" "}
                        <i id={"myDIVarrow2-" + props.order_id + "-1"}></i>
                      </span>
                    </div>
                  </>
                ) : null}

                {/* Receipt Starts*/}
                <div
                  id={"myDIV-" + props.order_id + props.order_id + "-1"}
                  style={{ display: "none" }}
                ></div>

                {/* Receipt Ends*/}
              </div>
              {payDue ? (
                <>
                  {/* <div className="success-bottom-btn mt-4">
                    <Link
                      className="btn btn-success"
                      to={"/ecom/checkout/" + props.order_id}
                    >
                      Pay Due
                    </Link>
                  </div> */}
                </>
              ) : null}
            </div>
          </div>
        </div>
      )}

      <div
        className="modal fade"
        id={"exampleModalView-" + props?.divId}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered pop-center"
          role="document"
        >
          <div className="modal-content view-reciept-popup table-responsive">
            <table
              width="100%"
              border="0"
              cellSpacing="0"
              cellPadding="0"
              className="course-table m-0 text-center"
            >
              <tr>
                {/* <th>S.No.</th> */}
                <th>Date</th>
                <th>Fee Type</th>
                <th>Payment Mode</th>
                <th>Amount With Tax</th>
                {
                  aciInvoice?.length !== 0 && <th>Invoice Password</th>
                }
                <th>View Invoice</th>
                <th>View Acknowledgement</th>
              </tr>
              {(props.reciepts ? props.reciepts : []).map((rcp, ir) => {
                let receiptdt = new Date(rcp.date);
                let receipt_date =
                  receiptdt.getDate() +
                  "/" +
                  (receiptdt.getMonth() + 1) +
                  "/" +
                  receiptdt.getFullYear();
                //console.log(receipt_date);
                invoiceSN = ir + 1
                return (
                  <>
                    <tr>
                      {/* <td>{ir + 1}</td> */}
                      <td>{receipt_date}</td>
                      <td>{rcp?.fee_type}</td>
                      <td>{rcp?.payment_mode}</td>
                      <td>₹{rcp.amount}</td>
                      {aciInvoice?.length !== 0 && <td>--</td>}
                      <td className="text-center">
                        {rcp?.invoice_id !== null ?
                          (<a
                            href={"javascript:;"}
                            onClick={() => downloadInvoice(rcp.invoice_id, true)}
                          >
                            <i className="fa-solid fa-circle-arrow-down"></i>
                          </a>) : "--"
                        }
                      </td>
                      <td className="text-center">
                        {rcp?.acknowledgement_id !== null ?
                          (<a
                            href={"javascript:;"}
                            onClick={() => downloadInvoice(rcp.acknowledgement_id, false)}
                          >
                            <i className="fa-solid fa-circle-arrow-down"></i>
                          </a>) : "--"
                        }
                      </td>
                    </tr>
                  </>
                );
              })}
              {aciInvoice?.map((val, ind) => {
                invoiceSN += invoiceSN + 1
                // setInvoiceSN((pre) => {
                //   SN += 1
                //   return (pre + 1)
                // })
                return (
                  <>
                    <tr>
                      {/* <td>{invoiceSN}</td> */}
                      <td>{val?.paydate}</td>
                      <td>{"Course Fee"}</td>
                      <td>{'Offline'}</td>
                      <td>{val?.amount}</td>
                      <td>{val?.pdfpassword}</td>
                      <td className="text-center">
                        {val?.invoice_base64 !== null ?
                          (<a
                            href={"javascript:;"}
                            onClick={() => convertBS64ToPDF(val?.invoice_base64)}
                          >
                            <i className="fa-solid fa-circle-arrow-down"></i>
                          </a>) : "--"
                        }
                      </td>
                      <td className="text-center">
                        --
                      </td>
                    </tr>
                  </>
                )
              })}
            </table>
            <div
              className="modal-body padding-none"
              style={{ padding: "0px" }}
            ></div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpenFirstConfirm}
        onAfterOpen={() => { }}
        onRequestClose={closeModalFirstConfirm}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div style={{ textAlign: "end" }}>
          <br></br>
          <button
            className="btn btn-sm btn-danger"
            onClick={closeModalFirstConfirm}
            style={{ position: "relative", top: "-33px" }}
          >
            X
          </button>
        </div>
        <div
          style={{
            display: "flex",

            justifyContent: "center",
            padding: "inherit",
          }}
        >
          {/* <h5>Choose payment method</h5> */}
        </div>
        <div className="container">
          <div className="row">
            {/* <div className="col-md-12"> */}

            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
                padding: "inherit",
              }}
            >
              <span onClick={closeModalFirstConfirm}>
                <RazorPayApiInti
                  payment_gateway_data={props?.payment_gateway_data}
                  type="razorpay"
                  setLoading={setLoading}
                />
              </span>
              <span onClick={closeModalFirstConfirm}>
                {" "}
                <RazorPayApiInti
                  payment_gateway_data={props?.payment_gateway_data}
                  type="hdfc"
                  setLoading={setLoading}
                />
              </span>
            </div>
          </div>
        </div>
      </Modal>
      {/*yoooo */}
      <Modal
        isOpen={is_already_admission?.is_already_admission}
        onAfterOpen={() => { }}
        onRequestClose={(e) => set_is_already_admission({ is_already_admission: false, current_name: "" })}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      //  className={"test-pop-hu-me"}
      >
        <div style={{ textAlign: "end" }}>
          <button
            className="btn btn-sm btn-danger"
            onClick={(e) => set_is_already_admission({ is_already_admission: false, current_name: "" })}
            style={{ position: "relative" }}
          >
            X
          </button>
        </div>
        <div className='course-popup p-0'>
          <div className='row align-items-center'>
            <div className='col-6 col-md-4 col-xl-4 mx-auto'>
              <img
                className='img-fluid d-block mx-auto'
                src={process.env.REACT_APP_CDN_URL + "fe-bsite/coursepopup.png"}
                alt='First slide'
              />
            </div>
            <div className='col-12 col-md-12 col-xl-8 mt-3 mt-xl-0'>
              <p>
                Dear Student, <br />
                You are actively enrolled on the course <b>{is_already_admission?.current_name}</b>
                <br />
                <br />
                For details on any other courses, please get in touch <br className='d-none d-md-block d-xl-none' />{" "}
                with our <br className='d-none d-xl-block' /> support team by calling{" "}
                <b>
                  <a href='tel:+91-9513736499' className='popup-link'>
                    +91-9513736499{" "}
                  </a>
                </b>
                or by <br className='d-none d-md-block d-xl-none' />
                email at{" "}
                <b>
                  <a href='mailto:wecare@allendigital.in' className='popup-link'>
                    wecare@allendigital.in
                  </a>
                </b>
              </p>
            </div>
          </div>
          {/* <img
                onClick={props.onHide}
                className='img-fluid d-block mx-auto closecross'
                src={process.env.REACT_APP_CDN_URL + "fe-bsite/cross.png"}
                alt='First slide'></img> */}
        </div>
      </Modal>
      {isLoading && <div className="loading">Loading&#8230;</div>}
    </>
  );
};

export default EcomOrderList;
