import React, { Component } from "react";
import CompanyAws from "../../images/companies/aws-logo.png";
import CompanyAwsHover from "../../images/companies/aws-logo-hover.png";
import CompanyInfosys from "../../images/companies/infosys-logo.png";
import CompanyInfosysHover from "../../images/companies/infosys-logo-hover.png";
import CompanySalesforce from "../../images/companies/salesforce-logo.png";
import CompanySalesforceHover from "../../images/companies/salesforce-logo-hover.png";
import CompanyZoho from "../../images/companies/zoho-logo.png";
import CompanyZohoHover from "../../images/companies/zoho-logo-hover.png";
// import { environment } from '../../Services/HttpData';
const environment = window.JSON.parse(process.env.REACT_APP_ENVIRONMENT)
class AboutUsValuChain extends React.Component {
  constructor(props) {
    super(props);
    //   ////console.log(this.props);
    this.state = {
      PageContent: [],
    };
  }
  componentWillReceiveProps(props) {
    this.setState({ PageContent: props.handelEvent });
    ////console.log(props.handelEvent);
  }
  render() {
    return (
      <>
        <div className="container my-3">
          <h2 className="text-center mt-5 font-weight-bold text-uppercase">
            {this.state.PageContent?.title}
          </h2>
          <div className="row justify-content-center">
                {this.state.PageContent?.content?.length !== 0 &&
                  this.state.PageContent?.content?.map((item) => (
                    <div className="col-md-1 company-box" key={item?.id}>
              <img src={item?.image
                          ? environment.baseUrlImg +
                            item?.image
                          :CompanyAws} className="img-fluid company-logo" alt="" />
              <img
                src={item?.media[0]?.image
                  ? environment.baseUrlImg +
                    item?.media[0]?.image
                  :CompanyAwsHover}
                className="img-fluid company-logo-hover"
                alt=""
              />
            </div>
                      // <li className="nav-item" key={item?.id}>
                      //   <a className="nav-link" key={item?.id} href={item?.slug}>
                      //     {item?.title}
                      //   </a>
                      // </li>
                  ))}
            
            {/* <div className="col-md-1 company-box">
              <img
                src={CompanyInfosys}
                className="img-fluid company-logo"
                alt=""
              />
              <img
                src={CompanyInfosysHover}
                className="img-fluid company-logo-hover"
                alt=""
              />
            </div>
            <div className="col-md-1 company-box">
              <img
                src={CompanySalesforce}
                className="img-fluid company-logo"
                alt=""
              />
              <img
                src={CompanySalesforceHover}
                className="img-fluid company-logo-hover"
                alt=""
              />
            </div>
            <div className="col-md-1 company-box">
              <img
                src={CompanyZoho}
                className="img-fluid company-logo"
                alt=""
              />
              <img
                src={CompanyZohoHover}
                className="img-fluid company-logo-hover"
                alt=""
              />
            </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default AboutUsValuChain;
