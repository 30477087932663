import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function NEETScholarship(props) {
  const [utmSource, setUtmSource] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");

  const getprams = () => {
    const searchParams = new URLSearchParams(document.location.search);
    let utm_source = searchParams.get("utm_source");
    if (utm_source !== null) {
      setUtmSource(utm_source);
    }
    let utm_medium = searchParams.get("utm_medium");
    if (utm_medium !== null) {
      setUtmMedium(utm_medium);
    }
    let utm_campaign = searchParams.get("utm_campaign");
    if (utm_campaign !== null) {
      setUtmCampaign(utm_campaign);
    }
  };

  useEffect(() => {
    getprams();
  }, []);
  return (
    <>
      <div className="container mt-5 jee-scholarship">
        <div className="row">
          <div class="col-12 col-md-12">
            <div className="title">
              How to Apply{" "}
              <span className="color-green">for Scholarships </span>
            </div>
            <ol type="1" className="jee-scholarship-listing mt-2">
              <li>Scholarship Eligibility Criteria are mentioned below.</li>
              <li>
                Scholarship requests made verbally or through phone / email /
                fax shall not be entertained in any case.
              </li>
              <li>
                To avail the scholarship, students have to submit required
                Documents (as mentioned below).
              </li>
            </ol>
          </div>
          <div class="col-12 col-md-12 mt-4">
            <div className="title">
              Important <span className="color-green">Information:</span>
            </div>
            <ol type="1" className="jee-scholarship-listing mt-2">
              {/* <li>Students whose Result of NEET(UG) 2023 is awaited at the time of Admission can also Apply for Scholarship at a later stage (before last date) with the below mentioned documents, if eligible.</li>
<li>In case of Applying for Scholarship after taking Admission you can avail scholarship by applying for Scholarship Disbursement.</li>
<li>Disbursement of Scholarship will be credited in Father / Mother / Student Bank Account by <b className='star-red'>31 August'23</b> or 1 month from the date of Application (whichever is later) through NEFT / RTGS only. For this you have to submit a cancelled cheque or bank passbook first page copy along with required Documents.</li> */}
              <li>
                Scholarship provided on the basis of NEET (UG) 2023 will be
                provided only in the Current Academic Session (2023-24) only.
              </li>
            </ol>
            <p className="mt-4 mb-0">
              <b>
                A student is eligible for only one scholarship at a time, No two
                criteria can be clubbed to avail deduction in Fee at ALLEN.
              </b>
            </p>
          </div>
          <div class="col-12 col-md-12 mt-4">
            <div className="jee-scholarship-table-title text-center">
              Based on NEET (UG)-2023 All India Rank - LIVE
            </div>
            <div className="table-responsive">
              <table class="table table-bordered jee-scholarship-table">
                <thead>
                  <tr>
                    <th>NEET (UG)- 2023 Marks</th>
                    <th>Scholarship</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-weight-500">600 & Above</td>
                    <td>50%</td>
                  </tr>
                  <tr>
                    <td className="font-weight-500">550 - 599</td>
                    <td>25%</td>
                  </tr>
                  <tr>
                    <td className="font-weight-500">500 - 549</td>
                    <td>15%</td>
                  </tr>
                  <tr>
                    <td className="font-weight-500">400 - 499</td>
                    <td>10%</td>
                  </tr>

                  {/* <tr>
                     <td colSpan="4" className='text-center color-green'><b>Last date to avail scholarship: 31 August'23</b></td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>

          {/* <div class="col-12 col-md-12 mt-5">
            <div className="jeescholarship-live">
              <div className="text-center">
                <div className="title-head">
                  <i>NEET(UG)</i>
                </div>
                <div className="title-bg mt-2">
                  ACHIEVER PLUS (LIVE + RECORDED)
                </div>
                <div className="jeescholarship-live-first row-direction justify-content-center">
                  <div className="phase-wrap-container border-mobile-show">
                    <div className="phase">
                      <span className="font-weight-500">Medium</span>
                      <i> : English + Hindi</i>
                    </div>
                    <div className="pahse-data">
                      <div className="phase-wrap">
                        <div className="phase">Phase 3 </div>
                        <div className="pahsedate">06 SEPTEMBER'23</div>
                      </div>
                    </div>
                  </div>

                  <div className="phase-wrap-container">
                    <div className="phase">
                      <span className="font-weight-500">Medium</span>
                      <i> : English + Hindi</i>
                    </div>
                    <div className="pahse-data">
                      <div className="phase-wrap">
                        <div className="phase">Phase 4 </div>
                        <div className="pahsedate">20 SEPTEMBER'23</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div class="col-12 col-md-12 mt-5"></div>
          <div className="col-12 mt-4">
            <div className="jeescholarship-list-wrap">
              <div className="jeescholarship-list">
                <img src="https://cdn.digital.allen.ac.in/fe-bsite/images/check-mark.png" />
                Learn from comfort of home
              </div>
              <div className="jeescholarship-list">
                <img src="https://cdn.digital.allen.ac.in/fe-bsite/images/check-mark.png" />
                ALLEN's proven pedagogy
              </div>
              <div className="jeescholarship-list">
                <img src="https://cdn.digital.allen.ac.in/fe-bsite/images/check-mark.png" />
                Best academics with best technology
              </div>
            </div>
          </div>
          <div className="col-12 mt-4 mt-md-5 text-center">
            <a
              href={`https://cloud.engage.allendigital.in/neetscholarships?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`}
              class="request-call-back"
            >
              Request a Call Back
            </a>
          </div>
          <div className="col-12 mt-2 text-center pahsedate">Or</div>
          <div className="col-12 mt-2 text-center call-text">
            Call: <a href="tel:+91-95137-36491">+919513736491</a>
          </div>
          <div className="col-12 mt-4">
            <div className="neet-footer text-center">
              <div className="heading">DOCUMENTS REQUIRED FOR SCHOLARSHIP</div>
              <div className="neet-footer-flex">
                <div className="document-flex">
                  <div className="icon">
                    <img src="https://cdn.digital.allen.ac.in/fe-bsite/images/student-id-icon.png"></img>
                  </div>
                  <div className="content">
                    Photo ID Of <br className="d-none d-lg-block" /> Student
                  </div>
                </div>
                <div className="document-flex borders-right borders-left">
                  <div className="icon">
                    <img src="https://cdn.digital.allen.ac.in/fe-bsite/images/admit-icon.png"></img>
                  </div>
                  <div className="content">
                    Photo Copy Of
                    <br className="d-none d-lg-block" /> NEET(UG)-2023{" "}
                    <br className="d-none d-lg-block" />
                    Admit Card
                  </div>
                </div>
                <div className="document-flex borders-right">
                  <div className="icon">
                    <img src="https://cdn.digital.allen.ac.in/fe-bsite/images/admit-icon.png"></img>
                  </div>
                  <div className="content">
                    NEET(UG)-2023
                    <br className="d-none d-lg-block" /> Result Card
                  </div>
                </div>
                <div className="document-flex">
                  <div className="icon">
                    <img src="https://cdn.digital.allen.ac.in/fe-bsite/images/student-id-icon.png"></img>
                  </div>
                  <div className="content">
                    Ex-ALLEN <br className="d-none d-md-block d-lg-none" />
                    I-Card
                    <br className="d-none d-lg-block" /> if Applicable
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
