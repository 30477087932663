export const validationFunction = {
    validateUserFields: (type, value) => {
      switch (type) {
        case "name":
          // eslint-disable-next-line
          return !(/(\s{2,})|[^a-zA-Z, ,.]/.test(value));
        case "space":
          // eslint-disable-next-line
          return !((/(\s{2,})|[^a-zA-Z, ,.,@,#,-?,&,^0-9]/.test(value)));
        case "spaceonly":
            // eslint-disable-next-line
          return !((/(\s{2,})/.test(value)));
        case "email":
          // eslint-disable-next-line
          return ((value==='')||(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)));
        case "address":
          // eslint-disable-next-line
          return ((value==='')||(!(/[,#-\/\s\!\@\$.....]/gi.test(value))));
        case "number":
          // eslint-disable-next-line
          return ((value.length===10)&&(/^\+?(0|[1-9]\d*)$/.test(value)));
          case "number_empty":
          // eslint-disable-next-line
          return ((value==='')||((value.length===10)&&(/^\+?(0|[1-9]\d*)$/.test(value))));
        case "pancard":
          // eslint-disable-next-line
          return ((value==='')||(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(value)));
        case "gstin":
          // eslint-disable-next-line
          return ((value==='')||(/^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/.test(value)));
        case "ifsc_code":
          // eslint-disable-next-line
          return ((value==='')||(/^[A-Za-z]{4}\d{7}$/.test(value)));
        case "acc_no":
          // eslint-disable-next-line
          return ((value==='')||(/^\d{9,18}$/.test(value)));
        default:
      }
    }
  };